// Empêche la persistance des encarts de l'écran de navigation
const reset_navbar = (Variables, setGlobalVariableValue) => {
  setGlobalVariableValue({
    key: 'display_notifs',
    value: false,
  });

  setGlobalVariableValue({
    key: 'display_profil',
    value: false,
  });
};

export default reset_navbar;
