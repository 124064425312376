import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AlimenterMonLivretScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [bouton_actif, setBouton_actif] = React.useState(false);
  const [select_CB, setSelect_CB] = React.useState(false);
  const [select_virement, setSelect_virement] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            justifyContent: 'space-between',
            maxHeight: [
              { minWidth: Breakpoints.BigScreen, value: dimensions.height },
              { minWidth: Breakpoints.Desktop, value: dimensions.height },
              { minWidth: Breakpoints.Laptop, value: dimensions.height },
              { minWidth: Breakpoints.Mobile, value: dimensions.height },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              gap: { minWidth: Breakpoints.Laptop, value: 20 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* mon_header */}
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { paddingLeft: null }
              ),
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                    .style,
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icone_retour_ordi'].props,
                    dimensions.width
                  )}
                  name={'AntDesign/arrowleft'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icone_retour_ordi'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['texte_retour_ordi'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['texte_retour_ordi'].style,
                    dimensions.width
                  )}
                >
                  {'Alimenter mon livret Flolend'}
                </Text>
              </View>
            </Pressable>
          </View>
          {/* vue horizontale */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                gap: 10,
              },
              dimensions.width
            )}
          >
            {/* Livret */}
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '1%' },
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              <Touchable>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App['Flolend 1'],
                      borderRadius: 12,
                      height: 220,
                      justifyContent: 'space-between',
                      paddingBottom: 24,
                      paddingLeft: 24,
                      paddingRight: 24,
                      paddingTop: 24,
                    },
                    dimensions.width
                  )}
                >
                  {/* Fetch client */}
                  <BaseXanoApi.FetchAuthGET>
                    {({ loading, error, data, refetchAuth }) => {
                      const fetchClientData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'livret_flolend_titre'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_titre'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Livret Flolend'}
                            </Text>
                            <Image
                              resizeMode={'contain'}
                              source={imageSource(Images['LogoWireframeLight'])}
                              style={StyleSheet.applyWidth(
                                { height: 40, width: 40 },
                                dimensions.width
                              )}
                            />
                          </View>

                          <View>
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'livret_flolend_solde_titre'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_solde_titre'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Solde'}
                            </Text>

                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  flexDirection: 'row',
                                  marginTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_solde'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'livret_flolend_solde'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {format_nombre(
                                  conversion_solde_wallet(
                                    fetchClientData?.wallet_lemonway
                                      ?.compte_client?.balance
                                  ),
                                  2
                                )}
                                {' €'}
                              </Text>
                            </View>
                          </View>
                        </>
                      );
                    }}
                  </BaseXanoApi.FetchAuthGET>
                </View>
              </Touchable>
            </View>
            {/* Options de paiement */}
            <View
              style={StyleSheet.applyWidth(
                {
                  gap: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '1%' },
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              {/* Touchable carte */}
              <Touchable
                onPress={() => {
                  try {
                    setSelect_CB(true);
                    setSelect_virement(false);
                    setBouton_actif(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Vue générale */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: select_CB
                        ? palettes.App['Flolend 4']
                        : palettes.App.gris_fonce_flolend,
                      borderRadius: 12,
                      borderWidth: select_CB ? 2 : 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'Montserrat_500Medium',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                              { minWidth: Breakpoints.Mobile, value: 14 },
                            ],
                            lineHeight: 18,
                            marginBottom: 5,
                            opacity: 1,
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          },
                          dimensions.width
                        )}
                      >
                        {'Carte bancaire'}
                      </Text>

                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['texte_simple']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['texte_simple'].style,
                          dimensions.width
                        )}
                      >
                        {'Versements sous 1 000 €.'}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', width: '25%' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={
                          select_CB
                            ? palettes.App['Flolend 4']
                            : palettes.App.gris_fonce_flolend
                        }
                        name={'AntDesign/creditcard'}
                        size={
                          (dimensions.width >= Breakpoints.Laptop
                            ? dimensions.width >= Breakpoints.Desktop
                              ? dimensions.width >= Breakpoints.BigScreen
                                ? 80
                                : 70
                              : 60
                            : 40) ?? 40
                        }
                      />
                    </View>
                  </View>
                </View>
              </Touchable>
              {/* Touchable virement */}
              <Touchable
                onPress={() => {
                  try {
                    setSelect_CB(false);
                    setSelect_virement(true);
                    setBouton_actif(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Vue virement */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: select_virement
                        ? palettes.App['Flolend 4']
                        : palettes.App.gris_fonce_flolend,
                      borderRadius: 12,
                      borderWidth: select_virement ? 2 : 1,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '75%' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'Montserrat_500Medium',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 14 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            lineHeight: 18,
                            marginBottom: 5,
                            opacity: 1,
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          },
                          dimensions.width
                        )}
                      >
                        {'Virement bancaire'}
                      </Text>

                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['texte_simple']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['texte_simple'].style,
                          dimensions.width
                        )}
                      >
                        {'Versements pour tous montants.'}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', width: '25%' },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={
                          select_virement
                            ? palettes.App['Flolend 4']
                            : palettes.App.gris_fonce_flolend
                        }
                        name={'MaterialCommunityIcons/bank-transfer-out'}
                        size={
                          (dimensions.width >= Breakpoints.Laptop
                            ? dimensions.width >= Breakpoints.Desktop
                              ? dimensions.width >= Breakpoints.BigScreen
                                ? 80
                                : 70
                              : 60
                            : 40) ?? 40
                        }
                      />
                    </View>
                  </View>
                </View>
              </Touchable>
            </View>
          </View>
          {/* Navigation ordinateur */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: '100%' },
                  dimensions.width
                )}
              >
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={
                    (bouton_actif
                      ? palettes.App['Flolend 2']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 2']
                  }
                  color2={
                    (bouton_actif
                      ? palettes.App['Flolend 4']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 4']
                  }
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: [
                          { minWidth: Breakpoints.Mobile, value: null },
                          { minWidth: Breakpoints.Tablet, value: null },
                        ],
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 60 },
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                        ],
                        justifyContent: 'center',
                        marginLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                        marginRight: { minWidth: Breakpoints.Laptop, value: 0 },
                        marginTop: null,
                        width: { minWidth: Breakpoints.Tablet, value: '50%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Bouton continuer */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        if (!bouton_actif) {
                          return;
                        }
                        if (select_virement) {
                          navigation.navigate('IBANClientScreen');
                        }
                        if (select_CB) {
                          navigation.navigate('AlimentationParCarteScreen');
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .style,
                      dimensions.width
                    )}
                    title={'Continuer'}
                  />
                </LinearGradient>
              </View>
            )}
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      {/* Navigation mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                bottom: '2%',
                paddingLeft: '5%',
                paddingRight: '5%',
                position: 'absolute',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={
                (bouton_actif
                  ? palettes.App['Flolend 2']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 2']
              }
              color2={
                (bouton_actif
                  ? palettes.App['Flolend 4']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 4']
              }
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    flex: [
                      { minWidth: Breakpoints.Mobile, value: null },
                      { minWidth: Breakpoints.Tablet, value: null },
                    ],
                    justifyContent: 'center',
                    marginLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                    marginRight: { minWidth: Breakpoints.Laptop, value: 0 },
                    marginTop: null,
                    width: { minWidth: Breakpoints.Tablet, value: '50%' },
                  }
                ),
                dimensions.width
              )}
            >
              {/* Bouton continuer */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (!bouton_actif) {
                      return;
                    }
                    if (select_virement) {
                      navigation.navigate('IBANClientScreen');
                    }
                    if (select_CB) {
                      navigation.navigate('AlimentationParCarteScreen');
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                  dimensions.width
                )}
                title={'Continuer'}
              />
            </LinearGradient>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(AlimenterMonLivretScreen);
