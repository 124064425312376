import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const QuiSommesNousScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', paddingLeft: 20 },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Qui sommes-nous ?'}
            </Text>
          </View>
        )}
      </>
      {/* Scroll Content Frame */}
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: dimensions.height },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'] + 20,
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'] + 20,
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'] + 10,
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* A propos */}
        <View
          style={StyleSheet.applyWidth(
            {
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['titre_magistral'].style,
              dimensions.width
            )}
          >
            {'A propos de Flolend'}
          </Text>
          {/* Encart */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.flolend_gris_nuance_encart,
                borderRadius: 24,
                gap: 15,
                padding: 10,
              },
              dimensions.width
            )}
          >
            {/* Photo */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.gris_encart_int_flolend,
                  borderRadius: 24,
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Text 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                  dimensions.width
                )}
              >
                {
                  "Flolend est une plateforme de financement participatif dédiée aux projets d'énergies renouvelables. Nous mettons en relation des porteurs de projets avec des investisseurs souhaitant soutenir la transition énergétique."
                }
              </Text>
            </View>
          </View>
        </View>
        {/* Les fondateurs */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: 50,
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['titre_magistral'].style,
              dimensions.width
            )}
          >
            {'Les fondateurs'}
          </Text>
          {/* Victor */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
          >
            {/* Encart */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.flolend_gris_nuance_encart,
                  borderRadius: 24,
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  gap: 15,
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  padding: 10,
                },
                dimensions.width
              )}
            >
              {/* Photo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App.gris_encart_int_flolend,
                    borderRadius: 24,
                    height: 250,
                    width: { minWidth: Breakpoints.Laptop, value: '49%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(
                    Images['_6645d011fb4873d0d2d087e3victorflolendp500']
                  )}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: '100%', width: '100%' }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* groupe droite desktop */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    gap: [
                      { minWidth: Breakpoints.Laptop, value: 10 },
                      { minWidth: Breakpoints.Mobile, value: 10 },
                    ],
                    width: { minWidth: Breakpoints.Laptop, value: '49%' },
                  },
                  dimensions.width
                )}
              >
                {/* Texte */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App.gris_encart_int_flolend,
                      borderRadius: 24,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre1_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Victor Berrios'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Cofondateur et CEO'}
                  </Text>
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                      dimensions.width
                    )}
                  >
                    {
                      'Ingénieur en efficacité énergétique, Victor a travaillé sur le financement de nombreux projets d’infrastructures à travers le monde. Après un parcours académique en école d’ingénieur et en école de commerce, il a aussi développé des compétences en gestion des risques lors de ses expériences dans le secteur des services financiers.'
                    }
                  </Text>
                </View>
                {/* Contacts */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* mail */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.gris_encart_int_flolend,
                        borderRadius: 24,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                          {
                            color: palettes.App['Flolend 2'],
                            fontFamily: 'PTSans_700Bold',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'victor@flolend.com'}
                    </Text>
                  </View>
                  {/* Linkedin */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.gris_encart_int_flolend,
                        borderRadius: 24,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.linkedin.com/in/victor-berrios/'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Entypo/linkedin'}
                        size={18}
                      />
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Tiago */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 10 }, dimensions.width)}
          >
            {/* Encart */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.flolend_gris_nuance_encart,
                  borderRadius: 24,
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  gap: 15,
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  padding: 10,
                },
                dimensions.width
              )}
            >
              {/* Photo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: palettes.App.gris_encart_int_flolend,
                    borderRadius: 24,
                    height: 250,
                    width: { minWidth: Breakpoints.Laptop, value: '49%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(
                    Images['_6645cf92d583579cf9e710d4tiagoflolendp500']
                  )}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: '100%', width: '100%' }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* groupe droite desktop 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    gap: [
                      { minWidth: Breakpoints.Laptop, value: 10 },
                      { minWidth: Breakpoints.Mobile, value: 10 },
                    ],
                    width: { minWidth: Breakpoints.Laptop, value: '49%' },
                  },
                  dimensions.width
                )}
              >
                {/* Texte */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App.gris_encart_int_flolend,
                      borderRadius: 24,
                      padding: 10,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre1_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Tiago de Vassal'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Cofondateur et COO'}
                  </Text>
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                      dimensions.width
                    )}
                  >
                    {
                      "Diplômé d'HEC Paris et de la FGV (São Paulo), Tiago a développé son expérience du secteur de la banque-assurance chez AXA et à l'Inspection Générale de la Société Générale, où il a notamment travaillé sur les activités de banque privée du groupe. Passionné par le secteur de l'énergie, il met en œuvre son expérience du secteur digital pour faciliter le financement de la transition énergétique par des investissement rentables."
                    }
                  </Text>
                </View>
                {/* Contacts */}
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-between' },
                    dimensions.width
                  )}
                >
                  {/* mail */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.gris_encart_int_flolend,
                        borderRadius: 24,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                          {
                            color: palettes.App['Flolend 2'],
                            fontFamily: 'PTSans_700Bold',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'tiago@flolend.com'}
                    </Text>
                  </View>
                  {/* Linkedin */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: palettes.App.gris_encart_int_flolend,
                        borderRadius: 24,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.linkedin.com/in/tiago-de-vassal/'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Entypo/linkedin'}
                        size={18}
                      />
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(QuiSommesNousScreen);
