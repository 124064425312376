import React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Icon,
  LinearGradient,
  MultiSelectPicker,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as MagicLinkXanoApi from '../apis/MagicLinkXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import isEqualTo from '../global-functions/isEqualTo';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const Inscription2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [Pays_naissance, setPays_naissance] = React.useState('');
  const [activation_chargement, setActivation_chargement] =
    React.useState(false);
  const [adresse, setAdresse] = React.useState('');
  const [code_com_naiss, setCode_com_naiss] = React.useState('');
  const [code_postal, setCode_postal] = React.useState('');
  const [commune, setCommune] = React.useState('');
  const [dateNaissValue, setDateNaissValue] = React.useState('');
  const [departement_naissance, setDepartement_naissance] = React.useState('');
  const [mada_mons, setMada_mons] = React.useState('');
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [nationalites, setNationalites] = React.useState([]);
  const [nom, setNom] = React.useState('');
  const [num_tel, setNum_tel] = React.useState('');
  const [numero_residence, setNumero_residence] = React.useState('');
  const [pays_de_residence, setPays_de_residence] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [prenom, setPrenom] = React.useState('');
  const [stepperValue, setStepperValue] = React.useState('');
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  // Renvoie true si la personne déclare être française et false sinon.
  const controle_nationalite = nationalites => {
    return nationalites.includes('FRA');
  };

  // Renvoie la date du jour moins 18 ans.
  const maintenantMoins18Ans = () => {
    var maintenant = new Date(); // Obtenir la date actuelle
    var anneeMoins18 = maintenant.getFullYear() - 18; // Soustraire 18 ans de l'année actuelle
    var dateMoins18Ans = new Date(
      anneeMoins18,
      maintenant.getMonth(),
      maintenant.getDate()
    ); // Créer une nouvelle date avec l'année soustraite

    return dateMoins18Ans;
  };

  // Associe les valeurs de la base aux encarts
  const setup_encarts = (
    civilite,
    prenom,
    nom,
    numTel,
    numero_adresse,
    rue_adresse,
    code_postal,
    commune,
    pays_residence,
    date_naissance,
    nationalite,
    dep_naiss,
    commune_naiss
  ) => {
    setMada_mons(civilite);
    setPrenom(prenom);
    setNom(nom);
    setNum_tel(numTel);
    setNumero_residence(numero_adresse);
    setAdresse(rue_adresse);
    setCode_postal(code_postal);
    setCommune(commune);
    setDateNaissValue(date_naissance);
    setDepartement_naissance(dep_naiss);

    if (dep_naiss === '99') {
      setPays_naiss(commune_naiss);
    } else {
      setCode_com_naiss(commune_naiss);
    }

    if (Array.isArray(nationalite) && nationalite.length === 0) {
      setNationalites(['FRA']);
    } else {
      setNationalites(nationalite);
    }

    if (pays_residence === '') {
      setPays_de_residence('FRA');
    } else {
      setPays_de_residence(pays_residence);
    }
  };

  // Supprime les espaces du numéro entré
  const supprimerEspacesNumeroTelephone = numero => {
    // Vérifier si l'entrée est null
    if (numero === null) {
      return null;
    }

    // Supprimer tous les espaces de la chaîne de caractères
    var numeroSansEspaces = numero.replace(/\s/g, '');

    return numeroSansEspaces;
  };

  // Vérifie le correct remplissage du questionnaire.
  const verifierInformations = (
    nom,
    prenom,
    telephone,
    civilite,
    date_naissance,
    adresse,
    numero_residence,
    code_postal,
    commune_residence,
    pays_residence,
    nationalite,
    departement_naiss,
    code_com_naiss,
    pays_naiss
  ) => {
    // Vérification de chaque paramètre
    if (!nom) {
      return "Le champ 'nom' est vide.";
    }
    if (!prenom) {
      return "Le champ 'prénom' est vide.";
    }
    if (!telephone) {
      return "Le champ 'téléphone' est vide.";
    }
    // Vérification du format du numéro de téléphone
    if (!/^(?:\+|0)[1-9][0-9]{8,}$/.test(telephone)) {
      return "Le champ 'téléphone' n'est pas au bon format.";
    }
    if (!civilite) {
      return "Le champ 'civilite' est vide.";
    }
    if (!date_naissance) {
      return "Le champ 'date de naissance' est vide.";
    }
    if (!adresse) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!numero_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!code_postal) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!commune_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!pays_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!nationalite) {
      return "Le champ 'nationalité' est vide.";
    }
    if (!nationalites.includes('FRA')) {
      return 'Vous devez avoir la nationalité Française pour investir sur Flolend.';
    }
    if (!departement_naiss) {
      return "Le champ 'département de naissance' est vide.";
    }
    if (!code_com_naiss && !pays_naiss) {
      return "Le champ 'lieu de naissance' est vide.";
    }
    if (code_com_naiss && !code_com_naiss.includes(departement_naiss)) {
      return 'Votre code postal de naissance ne correspond pas à votre département de naissance.';
    }

    // Si aucun paramètre n'est vide et le format du numéro de téléphone est correct
    return true;
  };
  const baseXanoAjoutHubspotPOST = BaseXanoApi.useAjoutHubspotPOST();
  const baseXanoFinOnbaordingCgpPATCH = BaseXanoApi.useFinOnbaordingCgpPATCH();
  const baseXanoEditInfosClientPATCH = BaseXanoApi.useEditInfosClientPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const retour_api = (await BaseXanoApi.authGET(Constants))?.json;
        setup_encarts(
          retour_api?.Civilite,
          retour_api?.Prenom,
          retour_api?.Nom,
          retour_api?.telephone,
          retour_api?.numero_residence,
          retour_api?.nom_rue_residence,
          retour_api?.code_postal,
          retour_api?.commune,
          retour_api?.pays_residence,
          retour_api?.Date_de_naissance,
          retour_api?.Nationalites,
          retour_api?.Departement_naissance,
          retour_api?.Commune_naissance
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      {/* Vue générale */}
      <View
        style={StyleSheet.applyWidth(
          { flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' } },
          dimensions.width
        )}
      >
        <SimpleStyleKeyboardAwareScrollView
          enableAutomaticScroll={false}
          enableResetScrollToCoords={false}
          keyboardShouldPersistTaps={'never'}
          viewIsInsideTabBar={false}
          enableOnAndroid={true}
          extraScrollHeight={72}
          showsVerticalScrollIndicator={false}
          style={StyleSheet.applyWidth(
            {
              height: { minWidth: Breakpoints.Laptop, value: '100%' },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '2%' },
              maxHeight: dimensions.height,
              paddingBottom: 36,
              paddingLeft: 24,
              paddingRight: 24,
            },
            dimensions.width
          )}
        >
          {/* Vue questionnaire */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                marginBottom: { minWidth: Breakpoints.Laptop, value: '5%' },
                marginLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '15%' },
                marginTop: { minWidth: Breakpoints.Laptop, value: '5%' },
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
              },
              dimensions.width
            )}
          >
            {/* En-tête */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  gap: 5,
                  paddingBottom: 5,
                  paddingTop: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Laptop, value: 0 },
                  ],
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                  dimensions.width
                )}
              >
                {'Bienvenue !'}
              </Text>
              {/* Titre */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {'Vos informations'}
              </Text>
            </View>
            {/* Civilité */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Civilité'}
              </Text>
              {/* Madame/Monsieur */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginTop: 4,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Madame vert */}
                <>
                  {!(mada_mons === 'Madame') ? null : (
                    <Touchable
                      style={StyleSheet.applyWidth(
                        { width: '45%' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderColor: palettes.App['Flolend 4'],
                            borderRadius: 15,
                            borderWidth: 1,
                            height: 55,
                            justifyContent: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'PTSans_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 16 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                                { minWidth: Breakpoints.Desktop, value: 18 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              textTransform: 'capitalize',
                            },
                            dimensions.width
                          )}
                        >
                          {'Madame'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
                {/* Madame gris */}
                <>
                  {!(mada_mons !== 'Madame' || '') ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          setMada_mons('Madame');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { width: '45%' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderColor: palettes.App.Peoplebit_Light_Gray,
                            borderRadius: 15,
                            borderWidth: 1,
                            height: 55,
                            justifyContent: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.gris_fonce_flolend,
                              fontFamily: 'PTSans_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 16 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Desktop, value: 18 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                              ],
                              textTransform: 'capitalize',
                            },
                            dimensions.width
                          )}
                        >
                          {'Madame'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
                {/* Monsieur vert */}
                <>
                  {!(mada_mons === 'Monsieur') ? null : (
                    <Touchable
                      style={StyleSheet.applyWidth(
                        { width: '45%' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderColor: palettes.App['Flolend 4'],
                            borderRadius: 15,
                            borderWidth: 1,
                            height: 55,
                            justifyContent: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'PTSans_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 16 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Desktop, value: 18 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                              ],
                              textTransform: 'capitalize',
                            },
                            dimensions.width
                          )}
                        >
                          {'Monsieur'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
                {/* Monsieur gris */}
                <>
                  {!(mada_mons !== 'Monsieur' || '') ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          setMada_mons('Monsieur');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        { width: '45%' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderColor: palettes.App.Peoplebit_Light_Gray,
                            borderRadius: 15,
                            borderWidth: 1,
                            height: 55,
                            justifyContent: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.gris_fonce_flolend,
                              fontFamily: 'PTSans_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 16 },
                                { minWidth: Breakpoints.Tablet, value: 18 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Desktop, value: 18 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                              ],
                              textTransform: 'capitalize',
                            },
                            dimensions.width
                          )}
                        >
                          {'Monsieur'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
              </View>
            </View>
            {/* Prenom */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Prénom'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .style,
                  dimensions.width
                )}
              >
                {/* prenom */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newPrenomValue => {
                    try {
                      setPrenom(newPrenomValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)[
                    'encart_input_inscription2'
                  ].props}
                  autoComplete={'name'}
                  keyboardType={'email-address'}
                  placeholder={'Votre prénom' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)[
                        'encart_input_inscription2'
                      ].style,
                      {
                        fontFamily: {
                          minWidth: Breakpoints.Tablet,
                          value: 'PTSans_400Regular',
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 14 },
                          { minWidth: Breakpoints.BigScreen, value: 22 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                  value={prenom}
                />
              </View>
            </View>
            {/* Nom */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Nom'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .style,
                  dimensions.width
                )}
              >
                {/* nom */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newNomValue => {
                    try {
                      setNom(newNomValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)[
                    'encart_input_inscription2'
                  ].props}
                  autoComplete={'family-name'}
                  keyboardType={'email-address'}
                  placeholder={'Votre nom de famille' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)[
                      'encart_input_inscription2'
                    ].style,
                    dimensions.width
                  )}
                  value={nom}
                />
              </View>
            </View>
            {/* Téléphone */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Numéro de téléphone'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .style,
                  dimensions.width
                )}
              >
                {/* telephone */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTelephoneValue => {
                    try {
                      setNum_tel(newTelephoneValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)[
                    'encart_input_inscription2'
                  ].props}
                  keyboardType={'phone-pad'}
                  placeholder={'Mobile ou fixe' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)[
                      'encart_input_inscription2'
                    ].style,
                    dimensions.width
                  )}
                  value={num_tel}
                />
              </View>
            </View>
            {/* Adresse */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Adresse de résidence fiscale'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .style,
                  dimensions.width
                )}
              >
                {/* Numéro */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newNumRoValue => {
                    try {
                      setNumero_residence(newNumRoValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)[
                    'encart_input_inscription2'
                  ].props}
                  keyboardType={'email-address'}
                  placeholder={'Numéro' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)[
                      'encart_input_inscription2'
                    ].style,
                    dimensions.width
                  )}
                  value={numero_residence}
                />
              </View>
              {/* Field 2 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .style,
                  dimensions.width
                )}
              >
                {/* Adresse */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newAdresseValue => {
                    try {
                      setAdresse(newAdresseValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)[
                    'encart_input_inscription2'
                  ].props}
                  keyboardType={'email-address'}
                  placeholder={'Rue, avenue, boulevard' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)[
                      'encart_input_inscription2'
                    ].style,
                    dimensions.width
                  )}
                  value={adresse}
                />
              </View>
              {/* Vue horizontale */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', justifyContent: 'space-between' },
                  dimensions.width
                )}
              >
                {/* Field 3 */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                        .style,
                      { width: '49%' }
                    ),
                    dimensions.width
                  )}
                >
                  {/* code_postal */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newCodePostalValue => {
                      try {
                        setCode_postal(newCodePostalValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)[
                      'encart_input_inscription2'
                    ].props}
                    keyboardType={'email-address'}
                    placeholder={'Code postal' ?? 'you@domain.tld'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)[
                        'encart_input_inscription2'
                      ].style,
                      dimensions.width
                    )}
                    value={code_postal}
                  />
                </View>
                {/* Field 4 */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                        .style,
                      { width: '49%' }
                    ),
                    dimensions.width
                  )}
                >
                  {/* commune */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newCommuneValue => {
                      try {
                        setCommune(newCommuneValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)[
                      'encart_input_inscription2'
                    ].props}
                    keyboardType={'email-address'}
                    placeholder={'Commune' ?? 'you@domain.tld'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)[
                        'encart_input_inscription2'
                      ].style,
                      dimensions.width
                    )}
                    value={commune}
                  />
                </View>
              </View>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  const pickerValue = newPickerValue;
                  try {
                    setPays_de_residence(newPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  { label: 'Afghanistan', value: 'AFG' },
                  { label: 'Afrique du Sud', value: 'ZAF' },
                  { label: 'Albanie', value: 'ALB' },
                  { label: 'Algérie', value: 'DZA' },
                  { label: 'Allemagne', value: 'DEU' },
                  { label: 'Andorre', value: 'AND' },
                  { label: 'Angola', value: 'AGO' },
                  { label: 'Antigua-et-Barbuda', value: 'ATG' },
                  { label: 'Arabie saoudite', value: 'SAU' },
                  { label: 'Argentine', value: 'ARG' },
                  { label: 'Arménie', value: 'ARM' },
                  { label: 'Australie', value: 'AUS' },
                  { label: 'Autriche', value: 'AUT' },
                  { label: 'Azerbaïdjan', value: 'AZE' },
                  { label: 'Bahamas', value: 'BHS' },
                  { label: 'Bahreïn', value: 'BHR' },
                  { label: 'Bangladesh', value: 'BGD' },
                  { label: 'Barbade', value: 'BRB' },
                  { label: 'Belgique', value: 'BEL' },
                  { label: 'Belize', value: 'BLZ' },
                  { label: 'Bénin', value: 'BEN' },
                  { label: 'Bhoutan', value: 'BTN' },
                  { label: 'Biélorussie', value: 'BLR' },
                  { label: 'Birmanie', value: 'MMR' },
                  { label: 'Bolivie', value: 'BOL' },
                  { label: 'Bosnie-Herzégovine', value: 'BIH' },
                  { label: 'Botswana', value: 'BWA' },
                  { label: 'Brésil', value: 'BRA' },
                  { label: 'Brunei', value: 'BRN' },
                  { label: 'Bulgarie', value: 'BGR' },
                  { label: 'Burkina Faso', value: 'BFA' },
                  { label: 'Burundi', value: 'BDI' },
                  { label: 'Cambodge', value: 'KHM' },
                  { label: 'Cameroun', value: 'CMR' },
                  { label: 'Canada', value: 'CAN' },
                  { label: 'Cap-Vert', value: 'CPV' },
                  { label: 'République centrafricaine', value: 'CAF' },
                  { label: 'Chili', value: 'CHL' },
                  { label: 'Chine', value: 'CHN' },
                  { label: 'Chypre', value: 'CYP' },
                  { label: 'Colombie', value: 'COL' },
                  { label: 'Comores', value: 'COM' },
                  { label: 'République du Congo', value: 'COG' },
                  { label: 'République démocratique du Congo', value: 'COD' },
                  { label: 'Corée du Nord', value: 'PRK' },
                  { label: 'Corée du Sud', value: 'KOR' },
                  { label: 'Costa Rica', value: 'CRI' },
                  { label: "Côte d'Ivoire", value: 'CIV' },
                  { label: 'Croatie', value: 'HRV' },
                  { label: 'Cuba', value: 'CUB' },
                  { label: 'Danemark', value: 'DNK' },
                  { label: 'Djibouti', value: 'DJI' },
                  { label: 'Dominique', value: 'DMA' },
                  { label: 'Égypte', value: 'EGY' },
                  { label: 'Émirats arabes unis', value: 'ARE' },
                  { label: 'Équateur', value: 'ECU' },
                  { label: 'Érythrée', value: 'ERI' },
                  { label: 'Espagne', value: 'ESP' },
                  { label: 'Estonie', value: 'EST' },
                  { label: 'Eswatini', value: 'SWZ' },
                  { label: 'États-Unis', value: 'USA' },
                  { label: 'Éthiopie', value: 'ETH' },
                  { label: 'Fidji', value: 'FJI' },
                  { label: 'Finlande', value: 'FIN' },
                  { label: 'France', value: 'FRA' },
                  { label: 'Gabon', value: 'GAB' },
                  { label: 'Gambie', value: 'GMB' },
                  { label: 'Géorgie', value: 'GEO' },
                  { label: 'Ghana', value: 'GHA' },
                  { label: 'Grèce', value: 'GRC' },
                  { label: 'Grenade', value: 'GRD' },
                  { label: 'Guatemala', value: 'GTM' },
                  { label: 'Guinée', value: 'GIN' },
                  { label: 'Guinée-Bissau', value: 'GNB' },
                  { label: 'Guinée équatoriale', value: 'GNQ' },
                  { label: 'Guyana', value: 'GUY' },
                  { label: 'Haïti', value: 'HTI' },
                  { label: 'Honduras', value: 'HND' },
                  { label: 'Hongrie', value: 'HUN' },
                  { label: 'Inde', value: 'IND' },
                  { label: 'Indonésie', value: 'IDN' },
                  { label: 'Irak', value: 'IRQ' },
                  { label: 'Iran', value: 'IRN' },
                  { label: 'Irlande', value: 'IRL' },
                  { label: 'Islande', value: 'ISL' },
                  { label: 'Israël', value: 'ISR' },
                  { label: 'Italie', value: 'ITA' },
                  { label: 'Jamaïque', value: 'JAM' },
                  { label: 'Japon', value: 'JPN' },
                  { label: 'Jordanie', value: 'JOR' },
                  { label: 'Kazakhstan', value: 'KAZ' },
                  { label: 'Kenya', value: 'KEN' },
                  { label: 'Kirghizistan', value: 'KGZ' },
                  { label: 'Kiribati', value: 'KIR' },
                  { label: 'Koweït', value: 'KWT' },
                  { label: 'Laos', value: 'LAO' },
                  { label: 'Lesotho', value: 'LSO' },
                  { label: 'Lettonie', value: 'LVA' },
                  { label: 'Liban', value: 'LBN' },
                  { label: 'Liberia', value: 'LBR' },
                  { label: 'Libye', value: 'LBY' },
                  { label: 'Liechtenstein', value: 'LIE' },
                  { label: 'Lituanie', value: 'LTU' },
                  { label: 'Luxembourg', value: 'LUX' },
                  { label: 'Macédoine du Nord', value: 'MKD' },
                  { label: 'Madagascar', value: 'MDG' },
                  { label: 'Malaisie', value: 'MYS' },
                  { label: 'Malawi', value: 'MWI' },
                  { label: 'Maldives', value: 'MDV' },
                  { label: 'Mali', value: 'MLI' },
                  { label: 'Malte', value: 'MLT' },
                  { label: 'Maroc', value: 'MAR' },
                  { label: 'Îles Marshall', value: 'MHL' },
                  { label: 'Maurice', value: 'MUS' },
                  { label: 'Mauritanie', value: 'MRT' },
                  { label: 'Mexique', value: 'MEX' },
                  { label: 'Micronésie', value: 'FSM' },
                  { label: 'Moldavie', value: 'MDA' },
                  { label: 'Monaco', value: 'MCO' },
                  { label: 'Mongolie', value: 'MNG' },
                  { label: 'Monténégro', value: 'MNE' },
                  { label: 'Mozambique', value: 'MOZ' },
                  { label: 'Namibie', value: 'NAM' },
                  { label: 'Nauru', value: 'NRU' },
                  { label: 'Népal', value: 'NPL' },
                  { label: 'Nicaragua', value: 'NIC' },
                  { label: 'Niger', value: 'NER' },
                  { label: 'Nigeria', value: 'NGA' },
                  { label: 'Norvège', value: 'NOR' },
                  { label: 'Nouvelle-Zélande', value: 'NZL' },
                  { label: 'Oman', value: 'OMN' },
                  { label: 'Ouganda', value: 'UGA' },
                  { label: 'Ouzbékistan', value: 'UZB' },
                  { label: 'Pakistan', value: 'PAK' },
                  { label: 'Palaos', value: 'PLW' },
                  { label: 'Panama', value: 'PAN' },
                  { label: 'Papouasie-Nouvelle-Guinée', value: 'PNG' },
                  { label: 'Paraguay', value: 'PRY' },
                  { label: 'Pays-Bas', value: 'NLD' },
                  { label: 'Pérou', value: 'PER' },
                  { label: 'Philippines', value: 'PHL' },
                  { label: 'Pologne', value: 'POL' },
                  { label: 'Portugal', value: 'PRT' },
                  { label: 'Qatar', value: 'QAT' },
                  { label: 'Roumanie', value: 'ROU' },
                  { label: 'Royaume-Uni', value: 'GBR' },
                  { label: 'Russie', value: 'RUS' },
                  { label: 'Rwanda', value: 'RWA' },
                  { label: 'Saint-Christophe-et-Niévès', value: 'KNA' },
                  { label: 'Saint-Marin', value: 'SMR' },
                  { label: 'Saint-Vincent-et-les-Grenadines', value: 'VCT' },
                  { label: 'Sainte-Lucie', value: 'LCA' },
                  { label: 'Salomon', value: 'SLB' },
                  { label: 'Salvador', value: 'SLV' },
                  { label: 'Samoa', value: 'WSM' },
                  { label: 'São Tomé-et-Principe', value: 'STP' },
                  { label: 'Sénégal', value: 'SEN' },
                  { label: 'Serbie', value: 'SRB' },
                  { label: 'Seychelles', value: 'SYC' },
                  { label: 'Sierra Leone', value: 'SLE' },
                  { label: 'Singapour', value: 'SGP' },
                  { label: 'Slovaquie', value: 'SVK' },
                  { label: 'Slovénie', value: 'SVN' },
                  { label: 'Somalie', value: 'SOM' },
                  { label: 'Soudan', value: 'SDN' },
                  { label: 'Soudan du Sud', value: 'SSD' },
                  { label: 'Sri Lanka', value: 'LKA' },
                  { label: 'Suède', value: 'SWE' },
                  { label: 'Suisse', value: 'CHE' },
                  { label: 'Suriname', value: 'SUR' },
                  { label: 'Syrie', value: 'SYR' },
                  { label: 'Tadjikistan', value: 'TJK' },
                  { label: 'Tanzanie', value: 'TZA' },
                  { label: 'Tchad', value: 'TCD' },
                  { label: 'République tchèque', value: 'CZE' },
                  { label: 'Thaïlande', value: 'THA' },
                  { label: 'Timor oriental', value: 'TLS' },
                  { label: 'Togo', value: 'TGO' },
                  { label: 'Tonga', value: 'TON' },
                  { label: 'Trinité-et-Tobago', value: 'TTO' },
                  { label: 'Tunisie', value: 'TUN' },
                  { label: 'Turkménistan', value: 'TKM' },
                  { label: 'Turquie', value: 'TUR' },
                  { label: 'Tuvalu', value: 'TUV' },
                  { label: 'Ukraine', value: 'UKR' },
                  { label: 'Uruguay', value: 'URY' },
                  { label: 'Vanuatu', value: 'VUT' },
                  { label: 'Vatican', value: 'VAT' },
                  { label: 'Venezuela', value: 'VEN' },
                  { label: 'Viêt Nam', value: 'VNM' },
                  { label: 'Yémen', value: 'YEM' },
                  { label: 'Zambie', value: 'ZMB' },
                  { label: 'Zimbabwe', value: 'ZWE' },
                ]}
                placeholder={'Pays de résidence'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={pays_de_residence}
              />
            </View>
            {/* Label */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                dimensions.width
              )}
            >
              {'Nationalités'}
            </Text>
            <MultiSelectPicker
              autoDismissKeyboard={true}
              dropDownBorderColor={theme.colors.border.brand}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              iconSize={24}
              leftIconMode={'inset'}
              onValueChange={newMultiSelectPickerValue => {
                const pickerValue = newMultiSelectPickerValue;
                try {
                  setNationalites(newMultiSelectPickerValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              selectedIconColor={theme.colors.text.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              dropDownBackgroundColor={palettes.App.gris_encart_int_flolend}
              dropDownTextColor={palettes.App.blanc_flolend}
              dropdownOverlayColor={palettes.App.blanc_flolend}
              options={[
                { label: 'Afghane', value: 'AFG' },
                { label: 'Sud-Africaine', value: 'ZAF' },
                { label: 'Albanaise', value: 'ALB' },
                { label: 'Algérienne', value: 'DZA' },
                { label: 'Allemande', value: 'DEU' },
                { label: 'Andorrane', value: 'AND' },
                { label: 'Angolaise', value: 'AGO' },
                { label: 'Antiguaise-et-Barbudienne', value: 'ATG' },
                { label: 'Saoudienne', value: 'SAU' },
                { label: 'Argentine', value: 'ARG' },
                { label: 'Arménienne', value: 'ARM' },
                { label: 'Australienne', value: 'AUS' },
                { label: 'Autrichienne', value: 'AUT' },
                { label: 'Azerbaïdjanaise', value: 'AZE' },
                { label: 'Bahaméenne', value: 'BHS' },
                { label: 'Bahreïnienne', value: 'BHR' },
                { label: 'Bangladaise', value: 'BGD' },
                { label: 'Barbadienne', value: 'BRB' },
                { label: 'Belge', value: 'BEL' },
                { label: 'Bélizienne', value: 'BLZ' },
                { label: 'Béninoise', value: 'BEN' },
                { label: 'Bhoutanaise', value: 'BTN' },
                { label: 'Biélorusse', value: 'BLR' },
                { label: 'Birmane', value: 'MMR' },
                { label: 'Bolivienne', value: 'BOL' },
                { label: 'Bosnienne-Herzégovinienne', value: 'BIH' },
                { label: 'Botswanaise', value: 'BWA' },
                { label: 'Brésilienne', value: 'BRA' },
                { label: 'Brunéienne', value: 'BRN' },
                { label: 'Bulgare', value: 'BGR' },
                { label: 'Burkinabée', value: 'BFA' },
                { label: 'Burundaise', value: 'BDI' },
                { label: 'Cambodgienne', value: 'KHM' },
                { label: 'Camerounaise', value: 'CMR' },
                { label: 'Canadienne', value: 'CAN' },
                { label: 'Cap-Verdienne', value: 'CPV' },
                { label: 'Centrafricaine', value: 'CAF' },
                { label: 'Chilienne', value: 'CHL' },
                { label: 'Chinoise', value: 'CHN' },
                { label: 'Chypriote', value: 'CYP' },
                { label: 'Colombienne', value: 'COL' },
                { label: 'Comorienne', value: 'COM' },
                { label: 'Congolaise', value: 'COG' },
                { label: 'Congolaise', value: 'COD' },
                { label: 'Nord-Coréenne', value: 'PRK' },
                { label: 'Sud-Coréenne', value: 'KOR' },
                { label: 'Costaricienne', value: 'CRI' },
                { label: 'Ivoirienne', value: 'CIV' },
                { label: 'Croate', value: 'HRV' },
                { label: 'Cubaine', value: 'CUB' },
                { label: 'Danoise', value: 'DNK' },
                { label: 'Djiboutienne', value: 'DJI' },
                { label: 'Dominiquaise', value: 'DMA' },
                { label: 'Égyptienne', value: 'EGY' },
                { label: 'Émirienne', value: 'ARE' },
                { label: 'Équatorienne', value: 'ECU' },
                { label: 'Érythréenne', value: 'ERI' },
                { label: 'Espagnole', value: 'ESP' },
                { label: 'Estonienne', value: 'EST' },
                { label: 'Swazie', value: 'SWZ' },
                { label: 'Américaine', value: 'USA' },
                { label: 'Éthiopienne', value: 'ETH' },
                { label: 'Fidjienne', value: 'FJI' },
                { label: 'Finlandaise', value: 'FIN' },
                { label: 'Française', value: 'FRA' },
                { label: 'Gabonaise', value: 'GAB' },
                { label: 'Gambienne', value: 'GMB' },
                { label: 'Géorgienne', value: 'GEO' },
                { label: 'Ghanéenne', value: 'GHA' },
                { label: 'Grecque', value: 'GRC' },
                { label: 'Grenadienne', value: 'GRD' },
                { label: 'Guatémaltèque', value: 'GTM' },
                { label: 'Guinéenne', value: 'GIN' },
                { label: 'Bissau-Guinéenne', value: 'GNB' },
                { label: 'Équatoguinéenne', value: 'GNQ' },
                { label: 'Guyanienne', value: 'GUY' },
                { label: 'Haïtienne', value: 'HTI' },
                { label: 'Hondurienne', value: 'HND' },
                { label: 'Hongroise', value: 'HUN' },
                { label: 'Indienne', value: 'IND' },
                { label: 'Indonésienne', value: 'IDN' },
                { label: 'Irakienne', value: 'IRQ' },
                { label: 'Iranienne', value: 'IRN' },
                { label: 'Irlandaise', value: 'IRL' },
                { label: 'Islandaise', value: 'ISL' },
                { label: 'Israélienne', value: 'ISR' },
                { label: 'Italienne', value: 'ITA' },
                { label: 'Jamaïcaine', value: 'JAM' },
                { label: 'Japonaise', value: 'JPN' },
                { label: 'Jordanienne', value: 'JOR' },
                { label: 'Kazakhstanaise', value: 'KAZ' },
                { label: 'Kenyane', value: 'KEN' },
                { label: 'Kirghize', value: 'KGZ' },
                { label: 'Kiribatienne', value: 'KIR' },
                { label: 'Koweïtienne', value: 'KWT' },
                { label: 'Laotienne', value: 'LAO' },
                { label: 'Lesothane', value: 'LSO' },
                { label: 'Lettone', value: 'LVA' },
                { label: 'Libanaise', value: 'LBN' },
                { label: 'Libérienne', value: 'LBR' },
                { label: 'Libyenne', value: 'LBY' },
                { label: 'Liechtensteinoise', value: 'LIE' },
                { label: 'Lituanienne', value: 'LTU' },
                { label: 'Luxembourgeoise', value: 'LUX' },
                { label: 'Macédonienne du Nord', value: 'MKD' },
                { label: 'Malgache', value: 'MDG' },
                { label: 'Malaisienne', value: 'MYS' },
                { label: 'Malawienne', value: 'MWI' },
                { label: 'Maldivienne', value: 'MDV' },
                { label: 'Malienne', value: 'MLI' },
                { label: 'Maltaise', value: 'MLT' },
                { label: 'Marocaine', value: 'MAR' },
                { label: 'Marshallaise', value: 'MHL' },
                { label: 'Mauricienne', value: 'MUS' },
                { label: 'Mauritanienne', value: 'MRT' },
                { label: 'Mexicaine', value: 'MEX' },
                { label: 'Micronésienne', value: 'FSM' },
                { label: 'Moldave', value: 'MDA' },
                { label: 'Monégasque', value: 'MCO' },
                { label: 'Mongole', value: 'MNG' },
                { label: 'Monténégrine', value: 'MNE' },
                { label: 'Mozambicaine', value: 'MOZ' },
                { label: 'Namibienne', value: 'NAM' },
                { label: 'Nauruane', value: 'NRU' },
                { label: 'Népalaise', value: 'NPL' },
                { label: 'Nicaraguayenne', value: 'NIC' },
                { label: 'Nigériane', value: 'NGA' },
                { label: 'Norvégienne', value: 'NOR' },
                { label: 'Néo-Zélandaise', value: 'NZL' },
                { label: 'Omanaise', value: 'OMN' },
                { label: 'Ougandaise', value: 'UGA' },
                { label: 'Ouzbèke', value: 'UZB' },
                { label: 'Pakistanaise', value: 'PAK' },
                { label: 'Palaosienne', value: 'PLW' },
                { label: 'Panaméenne', value: 'PAN' },
                { label: 'Papouane-Néo-Guinéenne', value: 'PNG' },
                { label: 'Paraguayenne', value: 'PRY' },
                { label: 'Néerlandaise', value: 'NLD' },
                { label: 'Péruvienne', value: 'PER' },
                { label: 'Philippine', value: 'PHL' },
                { label: 'Polonaise', value: 'POL' },
                { label: 'Portugaise', value: 'PRT' },
                { label: 'Qatarienne', value: 'QAT' },
                { label: 'Roumaine', value: 'ROU' },
                { label: 'Britannique', value: 'GBR' },
                { label: 'Russe', value: 'RUS' },
                { label: 'Rwandaise', value: 'RWA' },
                { label: 'Saint-Christophienne-et-Niévicienne', value: 'KNA' },
                { label: 'Saint-Marinaise', value: 'SMR' },
                { label: 'Saint-Vincentaise-et-Grenadine', value: 'VCT' },
                { label: 'Saint-Lucienne', value: 'LCA' },
                { label: 'Salomonaise', value: 'SLB' },
                { label: 'Salvadorienne', value: 'SLV' },
                { label: 'Samoane', value: 'WSM' },
                { label: 'Santoméenne-et-Principe', value: 'STP' },
                { label: 'Sénégalaise', value: 'SEN' },
                { label: 'Serbe', value: 'SRB' },
                { label: 'Seychelloise', value: 'SYC' },
                { label: 'Sierra-Léonaise', value: 'SLE' },
                { label: 'Singapourienne', value: 'SGP' },
                { label: 'Slovaque', value: 'SVK' },
                { label: 'Slovène', value: 'SVN' },
                { label: 'Somalienne', value: 'SOM' },
                { label: 'Soudanaise', value: 'SDN' },
                { label: 'Sud-Soudanaise', value: 'SSD' },
                { label: 'Sri-Lankaise', value: 'LKA' },
                { label: 'Suédoise', value: 'SWE' },
                { label: 'Suisse', value: 'CHE' },
                { label: 'Surinamaise', value: 'SUR' },
                { label: 'Syrienne', value: 'SYR' },
                { label: 'Tadjike', value: 'TJK' },
                { label: 'Tanzanienne', value: 'TZA' },
                { label: 'Tchadienne', value: 'TCD' },
                { label: 'Tchèque', value: 'CZE' },
                { label: 'Thaïlandaise', value: 'THA' },
                { label: 'Timoraise', value: 'TLS' },
                { label: 'Togolaise', value: 'TGO' },
                { label: 'Tonguienne', value: 'TON' },
                { label: 'Trinidadienne-et-Tobagienne', value: 'TTO' },
                { label: 'Tunisienne', value: 'TUN' },
                { label: 'Turkmène', value: 'TKM' },
                { label: 'Turque', value: 'TUR' },
                { label: 'Tuvaluane', value: 'TUV' },
                { label: 'Ukrainienne', value: 'UKR' },
                { label: 'Uruguayenne', value: 'URY' },
                { label: 'Vanuatuane', value: 'VUT' },
                { label: 'Vaticane', value: 'VAT' },
                { label: 'Vénézuélienne', value: 'VEN' },
                { label: 'Vietnamienne', value: 'VNM' },
                { label: 'Yéménite', value: 'YEM' },
                { label: 'Zambienne', value: 'ZMB' },
                { label: 'Zimbabwéenne', value: 'ZWE' },
              ]}
              placeholder={'Nationalités'}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.gris_encart_int_flolend,
                  borderColor: palettes.App.Peoplebit_Light_Gray,
                  color: palettes.App.blanc_flolend,
                  fontFamily: 'PTSans_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 16 },
                    { minWidth: Breakpoints.Laptop, value: 14 },
                    { minWidth: Breakpoints.Desktop, value: 18 },
                    { minWidth: Breakpoints.BigScreen, value: 22 },
                  ],
                },
                dimensions.width
              )}
              value={nationalites}
            />
            {/* Date de naissance */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 24 }, dimensions.width)}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Date de naissance'}
              </Text>
              {/* Field */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 1 },
                  dimensions.width
                )}
              >
                {/* date_naiss */}
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  inline={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDateNaissValue => {
                    try {
                      setDateNaissValue(newDateNaissValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  date={dateNaissValue}
                  format={'dd/mm/yyyy'}
                  leftIconName={''}
                  maximumDate={maintenantMoins18Ans()}
                  minimumDate={'01/01/1933'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: palettes.App.gris_fonce_flolend,
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'PTSans_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Tablet, value: 18 },
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                        { minWidth: Breakpoints.BigScreen, value: 22 },
                      ],
                    },
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* Département de naissance */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 24 }, dimensions.width)}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Département de naissance'}
              </Text>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  try {
                    setDepartement_naissance(newPickerValue);
                    setCode_com_naiss('');
                    setPays_naissance('');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  { label: '1 : Ain', value: '1' },
                  { label: '2 : Aisne', value: '2' },
                  { label: '3 : Allier', value: '3' },
                  { label: '4 : Alpes-de-Haute-Provence', value: '4' },
                  { label: '5 : Hautes-Alpes', value: '5' },
                  { label: '6 : Alpes-Maritimes', value: '6' },
                  { label: '7 : Ardèche', value: '7' },
                  { label: '8 : Ardennes', value: '8' },
                  { label: '9 : Ariège', value: '9' },
                  { label: '10 : Aube', value: '10' },
                  { label: '11 : Aude', value: '11' },
                  { label: '12 : Aveyron', value: '12' },
                  { label: '13 : Bouches-du-Rhône', value: '13' },
                  { label: '14 : Calvados', value: '14' },
                  { label: '15 : Cantal', value: '15' },
                  { label: '16 : Charente', value: '16' },
                  { label: '17 : Charente-Maritime', value: '17' },
                  { label: '18 : Cher', value: '18' },
                  { label: '19 : Corrèze', value: '19' },
                  { label: "21 : Côte-d'Or", value: '21' },
                  { label: "22 : Côtes-d'Armor", value: '22' },
                  { label: '23 : Creuse', value: '23' },
                  { label: '24 : Dordogne', value: '24' },
                  { label: '25 : Doubs', value: '25' },
                  { label: '26 : Drôme', value: '26' },
                  { label: '27 : Eure', value: '27' },
                  { label: '28 : Eure-et-Loir', value: '28' },
                  { label: '29 : Finistère', value: '29' },
                  { label: '2A : Corse-du-Sud', value: '2A' },
                  { label: '2B : Haute-Corse', value: '2B' },
                  { label: '30 : Gard', value: '30' },
                  { label: '31 : Haute-Garonne', value: '31' },
                  { label: '32 : Gers', value: '32' },
                  { label: '33 : Gironde', value: '33' },
                  { label: '34 : Hérault', value: '34' },
                  { label: '35 : Ille-et-Vilaine', value: '35' },
                  { label: '36 : Indre', value: '36' },
                  { label: '37 : Indre-et-Loire', value: '37' },
                  { label: '38 : Isère', value: '38' },
                  { label: '39 : Jura', value: '39' },
                  { label: '40 : Landes', value: '40' },
                  { label: '41 : Loir-et-Cher', value: '41' },
                  { label: '42 : Loire', value: '42' },
                  { label: '43 : Haute-Loire', value: '43' },
                  { label: '44 : Loire-Atlantique', value: '44' },
                  { label: '45 : Loiret', value: '45' },
                  { label: '46 : Lot', value: '46' },
                  { label: '47 : Lot-et-Garonne', value: '47' },
                  { label: '48 : Lozère', value: '48' },
                  { label: '49 : Maine-et-Loire', value: '49' },
                  { label: '50 : Manche', value: '50' },
                  { label: '51 : Marne', value: '51' },
                  { label: '52 : Haute-Marne', value: '52' },
                  { label: '53 : Mayenne', value: '53' },
                  { label: '54 : Meurthe-et-Moselle', value: '54' },
                  { label: '55 : Meuse', value: '55' },
                  { label: '56 : Morbihan', value: '56' },
                  { label: '57 : Moselle', value: '57' },
                  { label: '58 : Nièvre', value: '58' },
                  { label: '59 : Nord', value: '59' },
                  { label: '60 : Oise', value: '60' },
                  { label: '61 : Orne', value: '61' },
                  { label: '62 : Pas-de-Calais', value: '62' },
                  { label: '63 : Puy-de-Dôme', value: '63' },
                  { label: '64 : Pyrénées-Atlantiques', value: '64' },
                  { label: '65 : Hautes-Pyrénées', value: '65' },
                  { label: '66 : Pyrénées-Orientales', value: '66' },
                  { label: '67 : Bas-Rhin', value: '67' },
                  { label: '68 : Haut-Rhin', value: '68' },
                  { label: '69 : Rhône', value: '69' },
                  { label: '70 : Haute-Saône', value: '70' },
                  { label: '71 : Saône-et-Loire', value: '71' },
                  { label: '72 : Sarthe', value: '72' },
                  { label: '73 : Savoie', value: '73' },
                  { label: '74 : Haute-Savoie', value: '74' },
                  { label: '75 : Paris', value: '75' },
                  { label: '76 : Seine-Maritime', value: '76' },
                  { label: '77 : Seine-et-Marne', value: '77' },
                  { label: '78 : Yvelines', value: '78' },
                  { label: '79 : Deux-Sèvres', value: '79' },
                  { label: '80 : Somme', value: '80' },
                  { label: '81 : Tarn', value: '81' },
                  { label: '82 : Tarn-et-Garonne', value: '82' },
                  { label: '83 : Var', value: '83' },
                  { label: '84 : Vaucluse', value: '84' },
                  { label: '85 : Vendée', value: '85' },
                  { label: '86 : Vienne', value: '86' },
                  { label: '87 : Haute-Vienne', value: '87' },
                  { label: '88 : Vosges', value: '88' },
                  { label: '89 : Yonne', value: '89' },
                  { label: '90 : Territoire de Belfort', value: '90' },
                  { label: '91 : Essonne', value: '91' },
                  { label: '92 : Hauts-de-Seine', value: '92' },
                  { label: '93 : Seine-Saint-Denis', value: '93' },
                  { label: '94 : Val-de-Marne', value: '94' },
                  { label: "95 : Val-d'Oise", value: '95' },
                  { label: '971 : Guadeloupe', value: '971' },
                  { label: '972 : Martinique', value: '972' },
                  { label: '973 : Guyane', value: '973' },
                  { label: '974 : La Réunion', value: '974' },
                  { label: '976 : Mayotte', value: '976' },
                  { label: '99 : Étranger', value: '99' },
                ]}
                placeholder={'Département de naissance'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={departement_naissance}
              />
            </View>
            {/* Code postal naissance */}
            <>
              {departement_naissance === '99' ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 24, marginTop: 24 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Commune de naissance (code postal)'}
                  </Text>
                  {/* Field */}
                  <View
                    {...GlobalStyles.ViewStyles(theme)[
                      'vue_entree_inscription2'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                        .style,
                      dimensions.width
                    )}
                  >
                    {/* com_naiss */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newComNaissValue => {
                        try {
                          setCode_com_naiss(newComNaissValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)[
                        'encart_input_inscription2'
                      ].props}
                      autoComplete={'family-name'}
                      keyboardType={'email-address'}
                      placeholder={'Code postal' ?? 'you@domain.tld'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextInputStyles(theme)[
                          'encart_input_inscription2'
                        ].style,
                        dimensions.width
                      )}
                      value={code_com_naiss}
                    />
                  </View>
                </View>
              )}
            </>
            {/* Pays de naissance */}
            <>
              {!(departement_naissance === '99') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 24, marginTop: 24 },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Pays de naissance'}
                  </Text>
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        setPays_naissance(newPickerValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)[
                      'picker_test_connaissances'
                    ].props}
                    options={[
                      { label: 'Afghanistan', value: '99100' },
                      { label: 'Albanie', value: '99101' },
                      { label: 'Algérie', value: '99102' },
                      { label: 'Allemagne', value: '99103' },
                      { label: 'Andorre', value: '99104' },
                      { label: 'Angola', value: '99105' },
                      { label: 'Antigua-et-Barbuda', value: '99106' },
                      { label: 'Arabie Saoudite', value: '99107' },
                      { label: 'Argentine', value: '99108' },
                      { label: 'Arménie', value: '99109' },
                      { label: 'Australie', value: '99110' },
                      { label: 'Autriche', value: '99111' },
                      { label: 'Azerbaïdjan', value: '99112' },
                      { label: 'Bahamas', value: '99113' },
                      { label: 'Bahreïn', value: '99114' },
                      { label: 'Bangladesh', value: '99115' },
                      { label: 'Barbade', value: '99116' },
                      { label: 'Belgique', value: '99117' },
                      { label: 'Belize', value: '99118' },
                      { label: 'Bénin', value: '99119' },
                      { label: 'Bhoutan', value: '99120' },
                      { label: 'Biélorussie', value: '99121' },
                      { label: 'Birmanie', value: '99122' },
                      { label: 'Bolivie', value: '99123' },
                      { label: 'Bosnie-Herzégovine', value: '99124' },
                      { label: 'Botswana', value: '99125' },
                      { label: 'Brésil', value: '99126' },
                      { label: 'Brunéi', value: '99127' },
                      { label: 'Bulgarie', value: '99128' },
                      { label: 'Burkina Faso', value: '99129' },
                      { label: 'Burundi', value: '99130' },
                      { label: 'Cabo Verde', value: '99131' },
                      { label: 'Cambodge', value: '99132' },
                      { label: 'Cameroun', value: '99133' },
                      { label: 'Canada', value: '99134' },
                      { label: 'Chili', value: '99135' },
                      { label: 'Chine', value: '99136' },
                      { label: 'Chypre', value: '99137' },
                      { label: 'Colombie', value: '99138' },
                      { label: 'Comores', value: '99139' },
                      { label: 'Congo-Brazzaville', value: '99140' },
                      { label: 'Congo-Kinshasa', value: '99141' },
                      { label: 'Corée du Nord', value: '99142' },
                      { label: 'Corée du Sud', value: '99143' },
                      { label: 'Costa Rica', value: '99144' },
                      { label: "Côte d'Ivoire", value: '99145' },
                      { label: 'Croatie', value: '99146' },
                      { label: 'Cuba', value: '99147' },
                      { label: 'Danemark', value: '99148' },
                      { label: 'Djibouti', value: '99149' },
                      { label: 'Dominique', value: '99150' },
                      { label: 'Égypte', value: '99151' },
                      { label: 'Émirats arabes unis', value: '99152' },
                      { label: 'Équateur', value: '99153' },
                      { label: 'Érythrée', value: '99154' },
                      { label: 'Espagne', value: '99155' },
                      { label: 'Estonie', value: '99156' },
                      { label: 'Eswatini', value: '99157' },
                      { label: 'États-Unis', value: '99158' },
                      { label: 'Éthiopie', value: '99159' },
                      { label: 'Fidji', value: '99160' },
                      { label: 'Finlande', value: '99161' },
                      { label: 'Gabon', value: '99163' },
                      { label: 'Gambie', value: '99164' },
                      { label: 'Géorgie', value: '99165' },
                      { label: 'Ghana', value: '99166' },
                      { label: 'Grèce', value: '99167' },
                      { label: 'Grenade', value: '99168' },
                      { label: 'Guatemala', value: '99169' },
                      { label: 'Guinée', value: '99170' },
                      { label: 'Guinée-Bissau', value: '99171' },
                      { label: 'Guinée équatoriale', value: '99172' },
                      { label: 'Guyana', value: '99173' },
                      { label: 'Haïti', value: '99174' },
                      { label: 'Honduras', value: '99175' },
                      { label: 'Hongrie', value: '99176' },
                      { label: 'Inde', value: '99177' },
                      { label: 'Indonésie', value: '99178' },
                      { label: 'Irak', value: '99179' },
                      { label: 'Iran', value: '99180' },
                      { label: 'Irlande', value: '99181' },
                      { label: 'Islande', value: '99182' },
                      { label: 'Israël', value: '99183' },
                      { label: 'Italie', value: '99184' },
                      { label: 'Jamaïque', value: '99185' },
                      { label: 'Japon', value: '99186' },
                      { label: 'Jordanie', value: '99187' },
                      { label: 'Kazakhstan', value: '99188' },
                      { label: 'Kenya', value: '99189' },
                      { label: 'Kirghizistan', value: '99190' },
                      { label: 'Kiribati', value: '99191' },
                      { label: 'Kosovo', value: '99192' },
                      { label: 'Koweït', value: '99193' },
                      { label: 'Laos', value: '99194' },
                      { label: 'Lesotho', value: '99195' },
                      { label: 'Lettonie', value: '99196' },
                      { label: 'Liban', value: '99197' },
                      { label: 'Liberia', value: '99198' },
                      { label: 'Libye', value: '99199' },
                      { label: 'Liechtenstein', value: '99200' },
                      { label: 'Lituanie', value: '99201' },
                      { label: 'Luxembourg', value: '99202' },
                      { label: 'Malaisie', value: '99203' },
                      { label: 'Malawi', value: '99204' },
                      { label: 'Maldives', value: '99205' },
                      { label: 'Mali', value: '99206' },
                      { label: 'Malte', value: '99207' },
                      { label: 'Maroc', value: '99208' },
                      { label: 'Maurice', value: '99209' },
                      { label: 'Mauritanie', value: '99210' },
                      { label: 'Mexique', value: '99211' },
                      { label: 'Micronésie', value: '99212' },
                      { label: 'Moldavie', value: '99213' },
                      { label: 'Monaco', value: '99214' },
                      { label: 'Mongolie', value: '99215' },
                      { label: 'Mozambique', value: '99216' },
                      { label: 'Namibie', value: '99217' },
                      { label: 'Nauru', value: '99218' },
                      { label: 'Népal', value: '99219' },
                      { label: 'Nicaragua', value: '99220' },
                      { label: 'Niger', value: '99221' },
                      { label: 'Nigeria', value: '99222' },
                      { label: 'Norvège', value: '99223' },
                      { label: 'Nouvelle-Zélande', value: '99224' },
                      { label: 'Oman', value: '99225' },
                      { label: 'Pakistan', value: '99226' },
                      { label: 'Palaos', value: '99227' },
                      { label: 'Panama', value: '99228' },
                      { label: 'Papouasie-Nouvelle-Guinée', value: '99229' },
                      { label: 'Paraguay', value: '99230' },
                      { label: 'Pays-Bas', value: '99231' },
                      { label: 'Perou', value: '99232' },
                      { label: 'Philippines', value: '99233' },
                      { label: 'Pologne', value: '99234' },
                      { label: 'Portugal', value: '99235' },
                      { label: 'Qatar', value: '99236' },
                      { label: 'République Centrafricaine', value: '99237' },
                      {
                        label: 'République Démocratique du Congo',
                        value: '99238',
                      },
                      { label: 'République Dominicaine', value: '99239' },
                      { label: 'République du Congo', value: '99240' },
                      { label: 'République tchèque', value: '99241' },
                      { label: 'Roumanie', value: '99242' },
                      { label: 'Royaume-Uni', value: '99243' },
                      { label: 'Russie', value: '99244' },
                      { label: 'Rwanda', value: '99245' },
                      { label: 'Saint-Kitts-et-Nevis', value: '99246' },
                      {
                        label: 'Saint-Vincent-et-les-Grenadines',
                        value: '99247',
                      },
                      { label: 'Salomon', value: '99248' },
                      { label: 'Samoa', value: '99249' },
                      { label: 'Sénégal', value: '99250' },
                      { label: 'Serbie', value: '99251' },
                      { label: 'Seychelles', value: '99252' },
                      { label: 'Sierra Leone', value: '99253' },
                      { label: 'Singapour', value: '99254' },
                      { label: 'Slovénie', value: '99255' },
                      { label: 'Slovaquie', value: '99256' },
                      { label: 'Somalie', value: '99257' },
                      { label: 'Soudan', value: '99258' },
                      { label: 'Soudan du Sud', value: '99259' },
                      { label: 'Sri Lanka', value: '99260' },
                      { label: 'Suède', value: '99261' },
                      { label: 'Suisse', value: '99262' },
                      { label: 'Syrie', value: '99263' },
                      { label: 'Tadjikistan', value: '99264' },
                      { label: 'Tanzanie', value: '99265' },
                      { label: 'Tchad', value: '99266' },
                      { label: 'Thailande', value: '99267' },
                      { label: 'Timor oriental', value: '99268' },
                      { label: 'Togo', value: '99269' },
                      { label: 'Tonga', value: '99270' },
                      { label: 'Trinité-et-Tobago', value: '99271' },
                      { label: 'Tunisie', value: '99272' },
                      { label: 'Turkménistan', value: '99273' },
                      { label: 'Turquie', value: '99274' },
                      { label: 'Tuvalu', value: '99275' },
                      { label: 'Ukraine', value: '99276' },
                      { label: 'Uruguay', value: '99277' },
                      { label: 'Vanuatu', value: '99278' },
                      { label: 'Vatican', value: '99279' },
                      { label: 'Venezuela', value: '99280' },
                      { label: 'Viêt Nam', value: '99281' },
                      { label: 'Yémen', value: '99282' },
                      { label: 'Zambie', value: '99283' },
                      { label: 'Zimbabwe', value: '99284' },
                    ]}
                    placeholder={'Pays de naissance'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)[
                        'picker_test_connaissances'
                      ].style,
                      dimensions.width
                    )}
                    value={Pays_naissance}
                  />
                </View>
              )}
            </>
            {/* Conditions générales d'utilisation */}
            <View>
              {/* Fetch cgu */}
              <BaseXanoApi.FetchCguEtRgpdGET bool_inclure_rgpd={true}>
                {({ loading, error, data, refetchCguEtRgpd }) => {
                  const fetchCguData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Titre */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {
                          "Conditions générales d'utilisation et charte de confidentialité"
                        }
                      </Text>
                      {/* Vue téléchargement CGU */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue sous-titre */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          {/* Nom */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 14 },
                                    { minWidth: Breakpoints.Tablet, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 18,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 22,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              "Conditions générales d'utilisation (CGU) de Flolend"
                            }
                          </Text>
                        </View>
                        {/* Vue boutons */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'flex-end',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                              width: '30%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* partager */}
                          <>
                            {!(
                              !(dimensions.width >= Breakpoints.Laptop) &&
                              !(Platform.OS === 'web')
                            ) ? null : (
                              <Button
                                accessible={true}
                                iconPosition={'left'}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await openShareUtil(
                                        `${
                                          fetchCguData &&
                                          fetchCguData[1].Fichier.url
                                        }`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.ButtonStyles(theme)['Button']
                                  .props}
                                icon={'MaterialIcons/mobile-screen-share'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button']
                                      .style,
                                    {
                                      backgroundColor: 'rgba(0, 0, 0, 0)',
                                      color: palettes.App['Flolend 2'],
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 18,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 22,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={''}
                              />
                            )}
                          </>
                          {/* Vue télécharger */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                ],
                                justifyContent: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Press */}
                            <Pressable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${
                                        fetchCguData &&
                                        fetchCguData[1].Fichier.url
                                      }`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'FontAwesome/download'}
                                size={
                                  !(dimensions.width >= Breakpoints.Laptop)
                                    ? 18
                                    : !(dimensions.width >= Breakpoints.Desktop)
                                    ? 25
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 35
                                    : 30
                                }
                              />
                            </Pressable>
                          </View>
                        </View>
                      </View>
                      {/* Vue téléchargement PSP */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 5,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue sous-titre */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          {/* Nom */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 14 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                    { minWidth: Breakpoints.Tablet, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 18,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 22,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              "Conditions générales d'utilisation (CGU) du prestataire de services de paiement (PSP)"
                            }
                          </Text>
                        </View>
                        {/* Vue boutons */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                              ],
                              width: '30%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* partager */}
                          <>
                            {!(
                              !(dimensions.width >= Breakpoints.Laptop) &&
                              !(Platform.OS === 'web')
                            ) ? null : (
                              <Button
                                accessible={true}
                                iconPosition={'left'}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await openShareUtil(
                                        `${
                                          fetchCguData &&
                                          fetchCguData[0].Fichier.url
                                        }`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.ButtonStyles(theme)['Button']
                                  .props}
                                icon={'MaterialIcons/mobile-screen-share'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button']
                                      .style,
                                    {
                                      backgroundColor: 'rgba(0, 0, 0, 0)',
                                      color: palettes.App['Flolend 2'],
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 18,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 22,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={''}
                              />
                            )}
                          </>
                          {/* Vue télécharger */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                ],
                                justifyContent: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Press */}
                            <Pressable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${
                                        fetchCguData &&
                                        fetchCguData[0].Fichier.url
                                      }`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'FontAwesome/download'}
                                size={
                                  !(dimensions.width >= Breakpoints.Laptop)
                                    ? 18
                                    : !(dimensions.width >= Breakpoints.Desktop)
                                    ? 25
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 35
                                    : 30
                                }
                              />
                            </Pressable>
                          </View>
                        </View>
                      </View>
                      {/* Vue téléchargement charte */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 5,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue sous-titre */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          {/* Nom */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 14 },
                                    { minWidth: Breakpoints.Laptop, value: 14 },
                                    { minWidth: Breakpoints.Tablet, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 18,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 22,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Charte de confidentialité de Flolend'}
                          </Text>
                        </View>
                        {/* Vue boutons */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: 'row',
                              justifyContent: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                              ],
                              width: '30%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* partager */}
                          <>
                            {!(
                              !(dimensions.width >= Breakpoints.Laptop) &&
                              !(Platform.OS === 'web')
                            ) ? null : (
                              <Button
                                accessible={true}
                                iconPosition={'left'}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await openShareUtil(
                                        `${
                                          fetchCguData &&
                                          fetchCguData[2].Fichier.url
                                        }`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.ButtonStyles(theme)['Button']
                                  .props}
                                icon={'MaterialIcons/mobile-screen-share'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button']
                                      .style,
                                    {
                                      backgroundColor: 'rgba(0, 0, 0, 0)',
                                      color: palettes.App['Flolend 2'],
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 18,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 22,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={''}
                              />
                            )}
                          </>
                          {/* Vue télécharger */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                ],
                                justifyContent: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Press */}
                            <Pressable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await WebBrowser.openBrowserAsync(
                                      `${
                                        fetchCguData &&
                                        fetchCguData[2].Fichier.url
                                      }`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'FontAwesome/download'}
                                size={
                                  !(dimensions.width >= Breakpoints.Laptop)
                                    ? 18
                                    : !(dimensions.width >= Breakpoints.Desktop)
                                    ? 25
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 35
                                    : 30
                                }
                              />
                            </Pressable>
                          </View>
                        </View>
                      </View>
                      {/* Vue acceptation */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 18,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 22,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              "J'accepte les CGU du PSP, les CGU de Flolend et la charte de confidentialité de Flolend."
                            }
                          </Text>
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '30%' },
                            dimensions.width
                          )}
                        >
                          {/* Switch hors iOS */}
                          <>
                            {Platform.OS === 'ios' ? null : (
                              <Switch
                                onValueChange={newSwitchHorsIOSValue => {
                                  try {
                                    setSwitchValue(newSwitchHorsIOSValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                activeThumbColor={palettes.App.blanc_flolend}
                                activeTrackColor={palettes.App['Flolend 4']}
                                inactiveThumbColor={palettes.App.blanc_flolend}
                                inactiveTrackColor={
                                  palettes.App.gris_fonce_flolend
                                }
                                style={StyleSheet.applyWidth(
                                  {
                                    height: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 25,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 35,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 45,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                                value={switchValue}
                              />
                            )}
                          </>
                          {/* Checkbox iOS */}
                          <>
                            {!(Platform.OS === 'ios') ? null : (
                              <Checkbox
                                onPress={newCheckboxIOSValue => {
                                  try {
                                    setSwitchValue(newCheckboxIOSValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                {...GlobalStyles.CheckboxStyles(theme)[
                                  'checkbox_card_affiche'
                                ].props}
                                size={24}
                                status={switchValue}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.CheckboxStyles(theme)[
                                    'checkbox_card_affiche'
                                  ].style,
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    </>
                  );
                }}
              </BaseXanoApi.FetchCguEtRgpdGET>
            </View>
            <Divider
              {...GlobalStyles.DividerStyles(theme)['Divider'].props}
              color={palettes.App.blanc_flolend}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  { marginBottom: 10, marginTop: 10 }
                ),
                dimensions.width
              )}
            />
            {/* Message */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 20 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['explications'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['explications'].style,
                  dimensions.width
                )}
              >
                {
                  "Avant de pouvoir financer vos premiers projets, la loi impose que nous agrégions des informations pour vous connaître avec précision afin de vous aiguiller dans votre processus d'investissement.\n\nNous vous dirigerons donc vers les formulaires adéquats lors de votre première souscription. Vous pouvez aussi les remplir dès maintenant dans votre espace personnel.\n\nEn attendant, découvrez l'application en appuyant sur le bouton ci-dessous. Bienvenue !"
                }
              </Text>
            </View>
            {/* Chargement */}
            <>
              {!activation_chargement ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    animating={true}
                    color={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.blanc_flolend,
                          fontFamily: 'PTSans_700Bold',
                          paddingLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Chargement en cours...'}
                  </Text>
                </View>
              )}
            </>
            <>
              {activation_chargement ? null : (
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={palettes.App['Flolend 2']}
                  color2={palettes.App['Flolend 4']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: null,
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        width: [
                          { minWidth: Breakpoints.Desktop, value: '60%' },
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                          { minWidth: Breakpoints.Tablet, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Bouton suivant */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const verification = verifierInformations(
                            nom,
                            prenom,
                            supprimerEspacesNumeroTelephone(num_tel),
                            mada_mons,
                            dateNaissValue,
                            adresse,
                            numero_residence,
                            code_postal,
                            commune,
                            pays_de_residence,
                            nationalites,
                            departement_naissance,
                            code_com_naiss,
                            Pays_naissance
                          );
                          if (verification === true) {
                            if (switchValue) {
                              setActivation_chargement(true);
                              const retour_API = (
                                await baseXanoEditInfosClientPATCH.mutateAsync({
                                  civilite: mada_mons,
                                  code_com_naissance:
                                    departement_naissance === '99'
                                      ? Pays_naissance
                                      : code_com_naiss,
                                  code_postal: code_postal,
                                  commune: commune,
                                  date_naissance: dateNaissValue,
                                  dep_naiss: departement_naissance,
                                  nationalites: nationalites,
                                  nom: nom,
                                  nom_rue: adresse,
                                  num_residence: numero_residence,
                                  pays_residence: pays_de_residence,
                                  prenom: prenom,
                                  telephone:
                                    supprimerEspacesNumeroTelephone(num_tel),
                                })
                              )?.json;
                              if (!retour_API?.success) {
                                setActivation_chargement(false);
                                setMessage_erreur(
                                  'Erreur de chargement - veuillez recompléter le questionnaire'
                                );
                              } else {
                                if (
                                  retour_API?.infos_client?.creation_par_cgp
                                ) {
                                  (
                                    await baseXanoFinOnbaordingCgpPATCH.mutateAsync()
                                  )?.json;
                                  if (navigation.canGoBack()) {
                                    navigation.popToTop();
                                  }
                                  navigation.replace('BottomTabNavigator', {
                                    screen: 'EventailProjetsScreen',
                                  });
                                } else {
                                  (
                                    await baseXanoAjoutHubspotPOST.mutateAsync({
                                      email:
                                        retour_API?.infos_client?.Identifiant,
                                      nom: prenom + (' ' + nom),
                                    })
                                  )?.json;
                                  (
                                    await MagicLinkXanoApi.generateMagicLinkGET(
                                      Constants
                                    )
                                  )?.json;
                                  navigation.navigate('Inscription3Screen', {
                                    arrivee_inscription2: true,
                                    arrivee_connexion: false,
                                  });
                                }
                              }
                            } else {
                              setMessage_erreur(
                                'Veuillez accepter la documentation susmentionnée'
                              );
                            }
                          } else {
                            setMessage_erreur(verification);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          borderColor: null,
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: null,
                          },
                          borderWidth: 0,
                          fontSize: { minWidth: Breakpoints.Tablet, value: 20 },
                          marginBottom: 0,
                          marginTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Découvrir les projets'}
                  />
                </LinearGradient>
              )}
            </>
            {/* Message d'erreur */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['message_erreur'].style,
                dimensions.width
              )}
            >
              {message_erreur}
            </Text>
          </View>
        </SimpleStyleKeyboardAwareScrollView>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(Inscription2Screen);
