import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  Picker,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const TestDeConnaissances3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_bouton, setAffichage_bouton] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [pickerValueAction, setPickerValueAction] = React.useState('');
  const [pickerValueIlliq, setPickerValueIlliq] = React.useState('');
  const [pickerValueObligation, setPickerValueObligation] = React.useState('');
  const [pickerValuePerteCap, setPickerValuePerteCap] = React.useState('');
  const [radio_group_creancier, setRadio_group_creancier] = React.useState('');
  const [radio_group_def_capital, setRadio_group_def_capital] =
    React.useState('');
  const [radio_group_prime_risque, setRadio_group_prime_risque] =
    React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] =
    React.useState(undefined);
  // Associe les données récupérées dans la base Airtable aux variables d'écran.
  const init_var_scr3 = (
    actions,
    obligations,
    risque_perte_capital,
    illiquide,
    risque_rendement,
    def_creancier,
    def_capital_pret
  ) => {
    //Actions
    setPickerValueAction(actions);

    //Obligations
    setPickerValueObligation(obligations);

    //Risque perte capital
    setPickerValuePerteCap(risque_perte_capital);

    //Risque illiquidité
    setPickerValueIlliq(illiquide);

    //Prime risque
    setRadio_group_prime_risque(risque_rendement);

    //Def créancier
    setRadio_group_creancier(def_creancier);

    //Prime risque
    setRadio_group_def_capital(def_capital_pret);
  };

  // Renvoie TRUE si une information n'est pas renseignée dans le formulaire
  const vérifier_completude = (
    info1,
    info2,
    info3,
    info4,
    info5,
    info6,
    info7,
    infoCheckbox
  ) => {
    return (
      info1 === '' ||
      info2 === '' ||
      info3 === '' ||
      info4 === '' ||
      info5 === '' ||
      info6 === '' ||
      info7 === '' ||
      infoCheckbox === false ||
      info1 === null ||
      info2 === null ||
      info3 === null ||
      info4 === null ||
      info5 === null ||
      info6 === null ||
      info7 === null
    );
  };
  const baseXanoDossInvestMajEcr3PATCH =
    BaseXanoApi.useDossInvestMajEcr3PATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const dossier_invest = (await BaseXanoApi.dossierInvestGET(Constants))
          ?.json;
        init_var_scr3(
          dossier_invest?.def_action,
          dossier_invest?.def_obligation,
          dossier_invest?.question_perte_totale,
          dossier_invest?.question_risque_illiquidite,
          dossier_invest?.prime_risque,
          dossier_invest?.def_creancier,
          dossier_invest?.capital_pret
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            marginBottom: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* En-tête */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'column' },
            dimensions.width
          )}
        >
          {/* entete */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: [
                  { minWidth: Breakpoints.Mobile, value: 5 },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: '2%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '80%' }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)[
                  'titre_pipe_test_connaissances'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)[
                    'titre_pipe_test_connaissances'
                  ].style,
                  dimensions.width
                )}
              >
                {'Test de connaissances'}
              </Text>
            </View>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '20%',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DossierInvestisseurScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      color={palettes.App.gris_fonce_flolend}
                      name={'Ionicons/exit-outline'}
                      size={18}
                    />
                  </Touchable>
                </View>
              )}
            </>
          </View>
          {/* frise */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App.gris_encart_int_flolend,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                ],
                justifyContent: 'space-evenly',
                paddingBottom: 5,
                paddingTop: 5,
              },
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['frise_test_titre_etape']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['frise_test_titre_etape'].style,
                dimensions.width
              )}
            >
              {'Etape 3/3 : Compréhension des risques'}
            </Text>
            <LinearProgress
              animationDuration={500}
              indeterminate={false}
              isAnimated={true}
              lineCap={'round'}
              showTrack={true}
              thickness={10}
              trackLineCap={'round'}
              color={palettes.App['Flolend 4']}
              style={StyleSheet.applyWidth(
                { alignSelf: 'center', width: '95%' },
                dimensions.width
              )}
              trackColor={palettes.App.gris_fonce_flolend}
              value={95}
            />
          </View>
        </View>
        {/*  Compréhension des risques */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: '5%',
              marginRight: '5%',
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: '2%' },
                { minWidth: Breakpoints.Laptop, value: 15 },
              ],
            },
            dimensions.width
          )}
        >
          {/* masterview */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                marginLeft: [
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                ],
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
              },
              dimensions.width
            )}
          >
            {/* Action */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Une action correspond à :'}
              </Text>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  try {
                    const valueBaDK1zrq = newPickerValue;
                    setPickerValueAction(valueBaDK1zrq);
                    const sortie = valueBaDK1zrq;
                    const resultat = vérifier_completude(
                      pickerValueObligation,
                      pickerValuePerteCap,
                      pickerValueIlliq,
                      radio_group_prime_risque,
                      sortie,
                      radio_group_creancier,
                      radio_group_def_capital,
                      checkboxValue
                    );
                    if (!resultat) {
                      setAffichage_bouton(true);
                    }
                    if (resultat) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  {
                    label: "Une part du capital d'une entreprise",
                    value: "Une part du capital d'une entreprise",
                  },
                  {
                    label: "Une part d'emprunt émis par une entreprise",
                    value: "Une part d'emprunt émis par une entreprise",
                  },
                  { label: 'Je ne sais pas', value: 'Je ne sais pas' },
                ]}
                placeholder={'Sélectionnez une option'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={pickerValueAction}
              />
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Obligation */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Une obligation correspond à :'}
              </Text>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  try {
                    const value8911WS3a = newPickerValue;
                    setPickerValueObligation(value8911WS3a);
                    const sortie = value8911WS3a;
                    const result = vérifier_completude(
                      sortie,
                      pickerValuePerteCap,
                      pickerValueIlliq,
                      pickerValueAction,
                      radio_group_prime_risque,
                      radio_group_creancier,
                      radio_group_def_capital,
                      checkboxValue
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if ('result') {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  {
                    label: "Une part du capital d'une entreprise",
                    value: "Une part du capital d'une entreprise",
                  },
                  {
                    label: "Une part d'emprunt émis par une entreprise",
                    value: "Une part d'emprunt émis par une entreprise",
                  },
                  { label: 'Je ne sais pas', value: 'Je ne sais pas' },
                ]}
                placeholder={'Sélectionnez une option'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={pickerValueObligation}
              />
              {/* Divider 9 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Risque perte capital */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {
                  'L’investissement dans des sociétés non cotées et/ou dans un service de financement participatif présente-t-il un risque de perte en capital totale ou partielle ?'
                }
              </Text>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  try {
                    const valueXxYtcEKa = newPickerValue;
                    setPickerValuePerteCap(valueXxYtcEKa);
                    const sortie = valueXxYtcEKa;
                    const result = vérifier_completude(
                      sortie,
                      pickerValueObligation,
                      pickerValueIlliq,
                      pickerValueAction,
                      radio_group_prime_risque,
                      radio_group_creancier,
                      radio_group_def_capital,
                      checkboxValue
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if (result) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  { label: 'Oui', value: 'Oui' },
                  { label: 'Non', value: 'Non' },
                  { label: 'Je ne sais pas', value: 'Je ne sais pas' },
                ]}
                placeholder={'Sélectionnez une option'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={pickerValuePerteCap}
              />
              {/* Divider 11 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Risque illiquidité */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {
                  'L’investissement dans des titres non cotés et/ou dans un service de financement participatif présente-t-il un risque d’illiquidité (revente ou remboursement non garanti) ?'
                }
              </Text>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  try {
                    const valuePA8MdZuS = newPickerValue;
                    setPickerValueIlliq(valuePA8MdZuS);
                    const sortie = valuePA8MdZuS;
                    const result = vérifier_completude(
                      sortie,
                      pickerValueObligation,
                      pickerValuePerteCap,
                      pickerValueAction,
                      radio_group_prime_risque,
                      radio_group_creancier,
                      radio_group_def_capital,
                      checkboxValue
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if (result) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  { label: 'Oui', value: 'Oui' },
                  { label: 'Non', value: 'Non' },
                  { label: 'Je ne sais pas', value: 'Je ne sais pas' },
                ]}
                placeholder={'Sélectionnez une option'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={pickerValueIlliq}
              />
              {/* Divider 10 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Prime risque */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                    { marginBottom: 8 }
                  ),
                  dimensions.width
                )}
              >
                {"Qu'est-ce que la prime de risque ?"}
              </Text>

              <RadioButtonGroup
                onValueChange={newRadioButtonGroupValue => {
                  const radioButtonGroupValue = newRadioButtonGroupValue;
                  try {
                    const valueM5ZyJYjM = newRadioButtonGroupValue;
                    setRadio_group_prime_risque(valueM5ZyJYjM);
                    const sortie = valueM5ZyJYjM;
                    const result = vérifier_completude(
                      sortie,
                      pickerValueObligation,
                      pickerValuePerteCap,
                      pickerValueIlliq,
                      pickerValueAction,
                      radio_group_creancier,
                      radio_group_def_capital,
                      checkboxValue
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if (result) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={radio_group_prime_risque}
              >
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={
                    "Le rendement supplémentaire attendu par l'investisseur pour accepter un risque supplémentaire"
                  }
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={
                    "Le rendement supplémentaire attendu par l'investisseur pour accepter un risque supplémentaire"
                  }
                />
                {/* Radio Button Row 2 */}
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Le rendement minimum garanti par un investissement'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Le rendement minimum garanti par un investissement'}
                />
              </RadioButtonGroup>
              {/* Divider 13 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* def creancier */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {
                  "Une personne qui prête de l'argent et qui a droit à un remboursement est :"
                }
              </Text>

              <RadioButtonGroup
                onValueChange={newRadioButtonGroupValue => {
                  try {
                    const valuenaaH1nFa = newRadioButtonGroupValue;
                    setRadio_group_creancier(valuenaaH1nFa);
                    const sortie = valuenaaH1nFa;
                    const result = vérifier_completude(
                      sortie,
                      pickerValueObligation,
                      pickerValuePerteCap,
                      pickerValueIlliq,
                      pickerValueAction,
                      radio_group_prime_risque,
                      radio_group_def_capital,
                      checkboxValue
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if (result) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={radio_group_creancier}
              >
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Le créancier'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Le créancier'}
                />
                {/* Radio Button Row 2 */}
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Le débiteur'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Le débiteur'}
                />
              </RadioButtonGroup>
              {/* Divider 12 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* def capital */}
            <View>
              {/* Label 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {"Dans un prêt, qu'est-ce que le capital ?"}
              </Text>

              <RadioButtonGroup
                onValueChange={newRadioButtonGroupValue => {
                  try {
                    const value5ynEB6w2 = newRadioButtonGroupValue;
                    setRadio_group_def_capital(value5ynEB6w2);
                    const sortie = value5ynEB6w2;
                    const result = vérifier_completude(
                      sortie,
                      pickerValueObligation,
                      pickerValuePerteCap,
                      pickerValueIlliq,
                      pickerValueAction,
                      radio_group_prime_risque,
                      radio_group_creancier,
                      checkboxValue
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if (result) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                value={radio_group_def_capital}
              >
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={
                    'Le montant du prêt accordé par le créancier au débiteur'
                  }
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={
                    'Le montant du prêt accordé par le créancier au débiteur'
                  }
                />
                {/* Radio Button Row 2 */}
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'La rémunération que reçoit le créancier'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'La rémunération que reçoit le créancier'}
                />
              </RadioButtonGroup>
              {/* Divider 14 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Compréhension */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  flexDirection: 'row',
                  gap: 5,
                  maxWidth: [
                    { minWidth: Breakpoints.Mobile, value: 310 },
                    { minWidth: Breakpoints.Laptop, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              <Checkbox
                onPress={newCheckboxValue => {
                  const checkboxValue = newCheckboxValue;
                  try {
                    const valueEK71Bw1b = newCheckboxValue;
                    setCheckboxValue(valueEK71Bw1b);
                    const sortie = valueEK71Bw1b;
                    const result = vérifier_completude(
                      pickerValueObligation,
                      pickerValuePerteCap,
                      pickerValueIlliq,
                      pickerValueAction,
                      radio_group_prime_risque,
                      radio_group_creancier,
                      radio_group_def_capital,
                      sortie
                    );
                    if (!result) {
                      setAffichage_bouton(true);
                    }
                    if (result) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                color={palettes.App['Flolend 4']}
                status={checkboxValue}
                uncheckedColor={palettes.App.gris_fonce_flolend}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['question_simulation'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['question_simulation'].style,
                  dimensions.width
                )}
              >
                {
                  "Je confirme avoir bien compris les risques inhérents aux services proposés par Flolend, à savoir le risque de perte en capital totale ou partielle et le risque d'illiquidité."
                }
              </Text>
              {/* Divider 6 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>

            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={
                (affichage_bouton
                  ? palettes.App['Flolend 1']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 1']
              }
              color2={
                (affichage_bouton
                  ? palettes.App['Flolend 2']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 2']
              }
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    alignSelf: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                    flex: { minWidth: Breakpoints.Tablet, value: null },
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 60 },
                      { minWidth: Breakpoints.Desktop, value: 65 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                      { minWidth: Breakpoints.Tablet, value: null },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    marginTop: '2%',
                    overflow: 'hidden',
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '75%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* Bouton étape suivante */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      if (!affichage_bouton) {
                        return;
                      }
                      const retour_api = (
                        await baseXanoDossInvestMajEcr3PATCH.mutateAsync({
                          def_action: pickerValueAction,
                          def_capital_pret: radio_group_def_capital,
                          def_creancier: radio_group_creancier,
                          def_obligation: pickerValueObligation,
                          illiquidite: pickerValueIlliq,
                          perte_totale: pickerValuePerteCap,
                          prime_risque: radio_group_prime_risque,
                        })
                      )?.json;
                      if (retour_api?.pas_experimente) {
                        navigation.navigate('TestDeConnaissances3bisScreen', {
                          retour_api_test: retour_api,
                        });
                      } else {
                        if (!retour_api?.simulation_validee) {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('Simulation1Screen');
                        }
                        if (retour_api?.dossier_complet) {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('DossierCompletScreen');
                        } else {
                          if (!retour_api?.verif_manuelle_wallet) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('ValiderMonKYC1Screen');
                          }
                          if (retour_api?.verif_manuelle_wallet) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('DossierInvestisseurScreen');
                          }
                        }
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      borderRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: null,
                      },
                      fontSize: [
                        { minWidth: Breakpoints.Desktop, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 24 },
                      ],
                      height: [
                        { minWidth: Breakpoints.Desktop, value: 60 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={'Etape suivante'}
              />
            </LinearGradient>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(TestDeConnaissances3Screen);
