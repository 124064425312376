import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Surface,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const CGUScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
          minHeight: {
            minWidth: Breakpoints.Desktop,
            value: dimensions.height,
          },
        },
        dimensions.width
      )}
    >
      {/* Vue mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 20,
                  paddingLeft: 20,
                },
                dimensions.width
              )}
            >
              {/* Back btn */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 44,
                      justifyContent: 'center',
                      width: 44,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App.gris_fonce_flolend}
                    name={'AntDesign/arrowleft'}
                    size={18}
                  />
                </View>
              </Touchable>
              {/* Screen Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['screen_heading'].style,
                  dimensions.width
                )}
              >
                {"Conditions générales d'utilisation"}
              </Text>
            </View>

            <SimpleStyleScrollView
              bounces={true}
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              style={StyleSheet.applyWidth(
                {
                  marginLeft: { minWidth: Breakpoints.Tablet, value: '10%' },
                  marginRight: { minWidth: Breakpoints.Tablet, value: '10%' },
                },
                dimensions.width
              )}
            >
              {/* Vue tableau documents */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '15%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '15%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Fetch documents */}
                <BaseXanoApi.FetchCguEtRgpdGET bool_inclure_rgpd={false}>
                  {({ loading, error, data, refetchCguEtRgpd }) => {
                    const fetchDocumentsData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <SimpleStyleFlatList
                        data={fetchDocumentsData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listData, index) =>
                          listData?.id ??
                          listData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'ubauLavb'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <Surface
                              {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                .props}
                              elevation={3}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SurfaceStyles(theme)['Surface']
                                    .style,
                                  {
                                    backgroundColor:
                                      palettes.App.flolend_gris_nuance_encart,
                                    borderRadius: 24,
                                    gap: 10,
                                    minHeight: 120,
                                    padding: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* icônes de clic */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flexDirection: 'row', gap: 5 },
                                  dimensions.width
                                )}
                              >
                                {/* download */}
                                <Pressable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        await WebBrowser.openBrowserAsync(
                                          `${listData?.Fichier?.url}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App.gris_encart_int_flolend,
                                        borderRadius: 50,
                                        padding: 5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App.blanc_flolend}
                                      name={'FontAwesome/download'}
                                      size={18}
                                    />
                                  </View>
                                </Pressable>
                                {/* share */}
                                <>
                                  {Platform.OS === 'web' ? null : (
                                    <Pressable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await openShareUtil(
                                              `${listData?.Fichier?.url}`
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              palettes.App
                                                .gris_encart_int_flolend,
                                            borderRadius: 50,
                                            padding: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Icon
                                          color={palettes.App.blanc_flolend}
                                          name={
                                            'MaterialIcons/mobile-screen-share'
                                          }
                                          size={18}
                                        />
                                      </View>
                                    </Pressable>
                                  )}
                                </>
                              </View>
                              {/* titre_doc */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_tableau'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_tableau'
                                    ].style,
                                    { textAlign: 'left' }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.Nom}
                              </Text>
                            </Surface>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        style={StyleSheet.applyWidth(
                          { gap: 20 },
                          dimensions.width
                        )}
                      />
                    );
                  }}
                </BaseXanoApi.FetchCguEtRgpdGET>
              </View>
            </SimpleStyleScrollView>
          </View>
        )}
      </>
      {/* Vue desktop */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
              dimensions.width
            )}
          >
            <NavbarBlock />
            {/* Scroll View 2 */}
            <SimpleStyleScrollView
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              bounces={false}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  height: { minWidth: Breakpoints.Desktop, value: '100%' },
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'space-between' },
                    { minWidth: Breakpoints.Laptop, value: 'space-between' },
                  ],
                  paddingTop: [
                    {
                      minWidth: Breakpoints.BigScreen,
                      value: Constants['hauteur_navbar_big_screen'],
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: Constants['hauteur_navbar_desktop'],
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: Constants['hauteur_navbar_laptop'],
                    },
                  ],
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: [
                      { minWidth: Breakpoints.Laptop, value: 10 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                    marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                    marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                    marginTop: [
                      { minWidth: Breakpoints.BigScreen, value: '2%' },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Fetch video */}
                <BaseXanoApi.FetchArrierePlanDemarrageGET>
                  {({ loading, error, data, refetchArrierePlanDemarrage }) => {
                    const fetchVideoData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <Surface
                        {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                            {
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              minHeight: [
                                { minWidth: Breakpoints.Desktop, value: 500 },
                                { minWidth: Breakpoints.BigScreen, value: 750 },
                              ],
                              overflow: {
                                minWidth: Breakpoints.Laptop,
                                value: 'hidden',
                              },
                              width: [
                                { minWidth: Breakpoints.Laptop, value: '40%' },
                                { minWidth: Breakpoints.Desktop, value: '60%' },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        <VideoPlayer
                          posterResizeMode={'cover'}
                          rate={1}
                          resizeMode={'cover'}
                          volume={0.5}
                          {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                            .props}
                          isLooping={true}
                          isMuted={true}
                          shouldPlay={true}
                          source={imageSource(`${fetchVideoData}`)}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.VideoPlayerStyles(theme)['Video']
                                .style,
                              {
                                height: [
                                  { minWidth: Breakpoints.Laptop, value: 400 },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '100%',
                                  },
                                ],
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '100%',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                          useNativeControls={false}
                          usePoster={false}
                        />
                      </Surface>
                    );
                  }}
                </BaseXanoApi.FetchArrierePlanDemarrageGET>
                {/* Vue tableau documents */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      width: [
                        { minWidth: Breakpoints.Desktop, value: '40%' },
                        { minWidth: Breakpoints.Laptop, value: '50%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Fetch documents */}
                  <BaseXanoApi.FetchCguEtRgpdGET bool_inclure_rgpd={false}>
                    {({ loading, error, data, refetchCguEtRgpd }) => {
                      const fetchDocumentsData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <SimpleStyleFlatList
                          data={fetchDocumentsData}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'42MNKJ6J'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={3}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      backgroundColor:
                                        palettes.App.flolend_gris_nuance_encart,
                                      borderRadius: 24,
                                      gap: 10,
                                      minHeight: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 120,
                                      },
                                      padding: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* icônes de clic */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'row', gap: 5 },
                                    dimensions.width
                                  )}
                                >
                                  {/* download */}
                                  <Pressable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            `${listData?.Fichier?.url}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App
                                              .gris_encart_int_flolend,
                                          borderRadius: 50,
                                          padding: 5,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Icon
                                        color={palettes.App.blanc_flolend}
                                        name={'FontAwesome/download'}
                                        size={
                                          (!(
                                            dimensions.width >=
                                            Breakpoints.Desktop
                                          )
                                            ? 18
                                            : dimensions.width >=
                                              Breakpoints.BigScreen
                                            ? 30
                                            : 25) ?? 18
                                        }
                                      />
                                    </View>
                                  </Pressable>
                                </View>
                                {/* titre_doc */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_tableau'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].style,
                                      { textAlign: 'left' }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.Nom}
                                </Text>
                              </Surface>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          style={StyleSheet.applyWidth(
                            { gap: 20 },
                            dimensions.width
                          )}
                        />
                      );
                    }}
                  </BaseXanoApi.FetchCguEtRgpdGET>
                </View>
              </View>
              <FooterDesktopBlock />
            </SimpleStyleScrollView>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(CGUScreen);
