import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { capacite_endettement: null, patrimoine_net: null };

const Simulation3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activation_bouton, setActivation_bouton] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState(false);
  const [checkboxValue3, setCheckboxValue3] = React.useState(false);
  const baseXanoDossInvestMajSimulPATCH =
    BaseXanoApi.useDossInvestMajSimulPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Linear Gradient mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
              .props}
            color1={
              (activation_bouton
                ? palettes.App['Flolend 1']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 1']
            }
            color2={
              (activation_bouton
                ? palettes.App['Flolend 2']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 2']
            }
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                  .style,
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  alignSelf: { minWidth: Breakpoints.Tablet, value: 'center' },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                  bottom: 20,
                  flex: null,
                  height: [
                    { minWidth: Breakpoints.Desktop, value: 65 },
                    { minWidth: Breakpoints.Laptop, value: 60 },
                    { minWidth: Breakpoints.BigScreen, value: 70 },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                  marginTop: '2%',
                  overflow: 'hidden',
                  position: 'absolute',
                  width: [
                    { minWidth: Breakpoints.Desktop, value: '60%' },
                    { minWidth: Breakpoints.BigScreen, value: '50%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                    { minWidth: Breakpoints.Laptop, value: '75%' },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {/* Bouton étape suivante */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (!activation_bouton) {
                      return;
                    }
                    const retour_api = (
                      await baseXanoDossInvestMajSimulPATCH.mutateAsync({
                        simul_capac_endettement:
                          props.route?.params?.capacite_endettement ??
                          defaultProps.capacite_endettement,
                        simul_pat_net:
                          props.route?.params?.patrimoine_net ??
                          defaultProps.patrimoine_net,
                      })
                    )?.json;
                    if (retour_api?.test_valide) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('TestDeConnaissances1Screen');
                    }
                    if (!retour_api?.test_valide) {
                      return;
                    }
                    if (retour_api?.dossier_complet) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('DossierCompletScreen');
                    } else {
                      if (!retour_api?.verif_manuelle_wallet) {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('ValiderMonKYC1Screen');
                      }
                      if (retour_api?.verif_manuelle_wallet) {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('DossierInvestisseurScreen');
                      }
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                  {
                    height: [
                      { minWidth: Breakpoints.Desktop, value: 60 },
                      { minWidth: Breakpoints.BigScreen, value: 75 },
                    ],
                  }
                ),
                dimensions.width
              )}
              title={"Je comprends et j'accepte les risques."}
            />
          </LinearGradient>
        )}
      </>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Masterview */}
        <View>
          {/* En-tête */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'column',
                marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
              },
              dimensions.width
            )}
          >
            {/* entete */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '2%',
                  marginLeft: [
                    { minWidth: Breakpoints.Laptop, value: '2%' },
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                  ],
                  marginRight: '5%',
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: '2%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '80%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_pipe_simulation']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_pipe_simulation']
                      .style,
                    dimensions.width
                  )}
                >
                  {'Simulation de la capacité à supporter des pertes'}
                </Text>
              </View>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('DossierInvestisseurScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Ionicons/exit-outline'}
                        size={18}
                      />
                    </Touchable>
                  </View>
                )}
              </>
            </View>
            {/* frise */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.gris_encart_int_flolend,
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.flolend_gris_nuance_encart,
                    },
                  ],
                  justifyContent: 'space-evenly',
                  marginBottom: '2%',
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['frise_simul_titre_etape']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['frise_simul_titre_etape']
                    .style,
                  dimensions.width
                )}
              >
                {'Etape 3/3 : Rappel des risques'}
              </Text>
              <LinearProgress
                animationDuration={500}
                indeterminate={false}
                isAnimated={true}
                lineCap={'round'}
                showTrack={true}
                thickness={10}
                trackLineCap={'round'}
                color={palettes.App['Flolend 4']}
                style={StyleSheet.applyWidth(
                  { alignSelf: 'center', width: '95%' },
                  dimensions.width
                )}
                trackColor={palettes.App.gris_fonce_flolend}
                value={95}
              />
            </View>
          </View>
          {/* Vue générale */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                marginLeft: [
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                minHeight: [
                  { minWidth: Breakpoints.Laptop, value: '30%' },
                  { minWidth: Breakpoints.BigScreen, value: '50%' },
                ],
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
              },
              dimensions.width
            )}
          >
            {/* Vue rappel */}
            <View>
              {/* Rappel des risques */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Label 5 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                    dimensions.width
                  )}
                >
                  {'Rappel des risques'}
                </Text>
                {/* Prise de connaissance */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: 5,
                      marginTop: 5,
                      maxWidth: [
                        { minWidth: Breakpoints.Mobile, value: 310 },
                        { minWidth: Breakpoints.Laptop, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <Checkbox
                    onPress={newCheckboxValue => {
                      try {
                        const value7bga6nwg = newCheckboxValue;
                        setCheckboxValue(value7bga6nwg);
                        const sortie = value7bga6nwg;
                        if (sortie && checkboxValue2 && checkboxValue3) {
                          setActivation_bouton(true);
                        }
                        if (!(sortie && checkboxValue2 && checkboxValue3)) {
                          setActivation_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Flolend 4']}
                    status={checkboxValue}
                    uncheckedColor={palettes.App.gris_fonce_flolend}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['question_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['question_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {
                      'Je déclare avoir pris connaissance des résultats énoncés.'
                    }
                  </Text>
                </View>
                {/* Divider 3 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
                {/* Risque de perte en capital */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: 5,
                      maxWidth: [
                        { minWidth: Breakpoints.Mobile, value: 310 },
                        { minWidth: Breakpoints.Laptop, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <Checkbox
                    onPress={newCheckboxValue => {
                      try {
                        const value2uSr9pwT = newCheckboxValue;
                        setCheckboxValue2(value2uSr9pwT);
                        const sortie = value2uSr9pwT;
                        if (sortie && checkboxValue && checkboxValue3) {
                          setActivation_bouton(true);
                        }
                        if (!(sortie && checkboxValue && checkboxValue3)) {
                          setActivation_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Flolend 4']}
                    status={checkboxValue2}
                    uncheckedColor={palettes.App.gris_fonce_flolend}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['question_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['question_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {
                      "J'ai conscience que l'investissement dans les opérations de financement participatif présente un risque de perte totale ou partielle du capital investi."
                    }
                  </Text>
                </View>
                {/* Divider 5 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
                {/* Immobilisation épargne */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      gap: 5,
                      maxWidth: [
                        { minWidth: Breakpoints.Mobile, value: 310 },
                        { minWidth: Breakpoints.Laptop, value: '100%' },
                        { minWidth: Breakpoints.Tablet, value: '100%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <Checkbox
                    onPress={newCheckboxValue => {
                      try {
                        const value4mQ5OFau = newCheckboxValue;
                        setCheckboxValue3(value4mQ5OFau);
                        const sortie = value4mQ5OFau;
                        if (sortie && checkboxValue && checkboxValue2) {
                          setActivation_bouton(true);
                        }
                        if (!(sortie && checkboxValue && checkboxValue2)) {
                          setActivation_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Flolend 4']}
                    status={checkboxValue3}
                    uncheckedColor={palettes.App.gris_fonce_flolend}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['question_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['question_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {
                      "J'ai conscience que l'investissement dans les opérations de financement participatif nécessite l'immobilisation de mon épargne."
                    }
                  </Text>
                </View>
                {/* Divider 6 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
              </View>
              {/* Linear Gradient ordi */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <LinearGradient
                    endX={100}
                    endY={100}
                    startX={0}
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'gradient_bouton'
                    ].props}
                    color1={
                      (activation_bouton
                        ? palettes.App['Flolend 1']
                        : palettes.App.gris_fonce_flolend) ??
                      palettes.App['Flolend 1']
                    }
                    color2={
                      (activation_bouton
                        ? palettes.App['Flolend 2']
                        : palettes.App.gris_fonce_flolend) ??
                      palettes.App['Flolend 2']
                    }
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].style,
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          alignSelf: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 15,
                          },
                          flex: null,
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 65 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                          justifyContent: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          marginLeft: [
                            { minWidth: Breakpoints.Mobile, value: '5%' },
                            { minWidth: Breakpoints.Laptop, value: '0%' },
                          ],
                          marginRight: [
                            { minWidth: Breakpoints.Mobile, value: '5%' },
                            { minWidth: Breakpoints.Laptop, value: '0%' },
                          ],
                          marginTop: '2%',
                          overflow: 'hidden',
                          width: [
                            { minWidth: Breakpoints.BigScreen, value: '50%' },
                            { minWidth: Breakpoints.Tablet, value: '50%' },
                            { minWidth: Breakpoints.Laptop, value: '75%' },
                            { minWidth: Breakpoints.Desktop, value: '75%' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Bouton étape suivante */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (!activation_bouton) {
                              return;
                            }
                            const retour_api = (
                              await baseXanoDossInvestMajSimulPATCH.mutateAsync(
                                {
                                  simul_capac_endettement:
                                    props.route?.params?.capacite_endettement ??
                                    defaultProps.capacite_endettement,
                                  simul_pat_net:
                                    props.route?.params?.patrimoine_net ??
                                    defaultProps.patrimoine_net,
                                }
                              )
                            )?.json;
                            if (retour_api?.test_valide) {
                              if (navigation.canGoBack()) {
                                navigation.popToTop();
                              }
                              navigation.replace('TestDeConnaissances1Screen');
                            }
                            if (!retour_api?.test_valide) {
                              return;
                            }
                            if (retour_api?.dossier_complet) {
                              if (navigation.canGoBack()) {
                                navigation.popToTop();
                              }
                              navigation.replace('DossierCompletScreen');
                            } else {
                              if (!retour_api?.verif_manuelle_wallet) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('ValiderMonKYC1Screen');
                              }
                              if (retour_api?.verif_manuelle_wallet) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('DossierInvestisseurScreen');
                              }
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                            .style,
                          {
                            height: [
                              { minWidth: Breakpoints.Desktop, value: 60 },
                              { minWidth: Breakpoints.BigScreen, value: 75 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                      title={"Je comprends et j'accepte les risques."}
                    />
                  </LinearGradient>
                )}
              </>
            </View>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(Simulation3Screen);
