import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  code_IBAN: 'FR76945693845',
  id_iban_lemonway: 3,
  label_iban: 'Mon compte Bourso',
  montant_a_virer_euros: 50.12,
};

const MoneyOut4RecapTransfertScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [bool_erreur, setBool_erreur] = React.useState(false);
  // Renvoie la différence entre solde et retrait
  const difference = (solde, retrait) => {
    return solde - retrait;
  };

  // Ajoute un espace tous les 4 caractères dans la chaine de caractères fournie en entrée
  const espaces_iban = str => {
    return str.replace(/(.{4})/g, '$1 ');
  };
  const baseXanoMoneyOutClientPOST = BaseXanoApi.useMoneyOutClientPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['header_classique'].style,
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Votre retrait'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        scrollEnabled={true}
        style={StyleSheet.applyWidth(
          {
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.Tablet, value: '15%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.Tablet, value: '15%' },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Livret Flolend */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                marginLeft: 20,
                marginRight: 20,
                width: [
                  { minWidth: Breakpoints.Laptop, value: '60%' },
                  { minWidth: Breakpoints.Desktop, value: '50%' },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Flolend 1'],
                  borderRadius: 12,
                  height: 220,
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 24,
                },
                dimensions.width
              )}
            >
              {/* Fetch client */}
              <BaseXanoApi.FetchAuthGET>
                {({ loading, error, data, refetchAuth }) => {
                  const fetchClientData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.Brand.Surface,
                              fontFamily: 'Montserrat_500Medium',
                              fontSize: 16,
                            },
                            dimensions.width
                          )}
                        >
                          {'Livret Flolend'}
                        </Text>
                        <Image
                          resizeMode={'contain'}
                          source={imageSource(Images['LogoWireframeLight'])}
                          style={StyleSheet.applyWidth(
                            { height: 30, width: 30 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.Brand.Surface,
                              fontFamily: 'PTSans_400Regular',
                            },
                            dimensions.width
                          )}
                        >
                          {'Solde'}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: 5,
                              marginTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.Brand.Surface,
                                fontFamily: 'PTSans_700Bold',
                                fontSize: 24,
                                lineHeight: 26,
                                marginLeft: 4,
                              },
                              dimensions.width
                            )}
                          >
                            {format_nombre(
                              conversion_solde_wallet(
                                fetchClientData?.wallet_lemonway?.compte_client
                                  ?.balance
                              ),
                              2
                            )}
                            {' €'}
                          </Text>
                          <Icon
                            color={theme.colors.background.brand}
                            name={'AntDesign/arrowright'}
                            size={15}
                          />
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            style={StyleSheet.applyWidth(
                              {
                                color: palettes.Brand.Surface,
                                fontFamily: 'PTSans_700Bold',
                                fontSize: 20,
                                lineHeight: 26,
                              },
                              dimensions.width
                            )}
                          >
                            {format_nombre(
                              difference(
                                conversion_solde_wallet(
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.balance
                                ),
                                props.route?.params?.montant_a_virer_euros ??
                                  defaultProps.montant_a_virer_euros
                              ),
                              2
                            )}
                            {' €'}
                          </Text>
                        </View>
                      </View>
                    </>
                  );
                }}
              </BaseXanoApi.FetchAuthGET>
            </View>
          </View>
          {/* Virements */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'center',
                paddingBottom: 32,
                paddingLeft: 24,
                paddingRight: 24,
                paddingTop: 32,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flexDirection: 'row', gap: 10 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'PTSans_400Regular',
                      fontSize: 24,
                    }
                  ),
                  dimensions.width
                )}
              >
                {format_nombre(
                  props.route?.params?.montant_a_virer_euros ??
                    defaultProps.montant_a_virer_euros,
                  2
                )}
                {' €'}
              </Text>
              <Icon
                color={palettes.App.blanc_flolend}
                name={'Feather/arrow-down'}
                size={26}
              />
            </View>
          </View>
          {/* Compte client */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                gap: 10,
                marginLeft: 20,
                marginRight: 20,
                width: [
                  { minWidth: Breakpoints.Laptop, value: '60%' },
                  { minWidth: Breakpoints.Desktop, value: '50%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* IBAN */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Flolend 2'],
                  borderRadius: 12,
                  height: 220,
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 24,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.Brand.Surface,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Votre IBAN'}
                </Text>
                <Icon
                  color={theme.colors.background.brand}
                  name={'Ionicons/person-circle'}
                  size={30}
                />
              </View>

              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'PTSans_400Regular',
                    },
                    dimensions.width
                  )}
                >
                  {espaces_iban(
                    props.route?.params?.code_IBAN ?? defaultProps.code_IBAN
                  )}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'PTSans_700Bold',
                        fontSize: 24,
                        lineHeight: 26,
                        marginLeft: 4,
                      },
                      dimensions.width
                    )}
                  >
                    {props.route?.params?.label_iban ?? defaultProps.label_iban}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* Valider le transfert */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                gap: 10,
                padding: 20,
              },
              dimensions.width
            )}
          >
            <>
              {affichage_chargement ? null : (
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={palettes.App['Flolend 1']}
                  color2={palettes.App['Flolend 2']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: [
                          { minWidth: Breakpoints.BigScreen, value: null },
                          { minWidth: Breakpoints.Laptop, value: null },
                        ],
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        marginTop: null,
                        overflow: {
                          minWidth: Breakpoints.Laptop,
                          value: 'hidden',
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setAffichage_chargement(true);
                          const retour_api = (
                            await baseXanoMoneyOutClientPOST.mutateAsync({
                              id_iban_sortie:
                                props.route?.params?.id_iban_lemonway ??
                                defaultProps.id_iban_lemonway,
                              montant_virement_euros:
                                props.route?.params?.montant_a_virer_euros ??
                                defaultProps.montant_a_virer_euros,
                            })
                          )?.json;
                          if (retour_api?.success) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace(
                              'MoneyOut5ValidationVirementScreen'
                            );
                          }
                          if (!retour_api?.success) {
                            setBool_erreur(true);
                          }
                          setAffichage_chargement(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .style,
                      dimensions.width
                    )}
                    title={'Confirmer le virement'}
                  />
                </LinearGradient>
              )}
            </>
            {/* Chargement en cours 2 */}
            <>
              {!affichage_chargement ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: 10,
                      paddingLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    color={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                      dimensions.width
                    )}
                  >
                    {'Chargement en cours, veuillez patienter.'}
                  </Text>
                </View>
              )}
            </>
          </View>
          {/* Message d'erreur */}
          <>
            {!bool_erreur ? null : (
              <View>
                {/* Message erreur */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['message_erreur'].style,
                    dimensions.width
                  )}
                >
                  {"Une erreur s'est produite, veuillez réessayer."}
                </Text>
              </View>
            )}
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(MoneyOut4RecapTransfertScreen);
