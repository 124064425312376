import React from 'react';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  Checkbox,
  CircleImage,
  CircularProgress,
  Icon,
  LinearGradient,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Surface,
  Switch,
  Table,
  TableCell,
  TableRow,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import ConvertionDate from '../global-functions/ConvertionDate';
import Convertion_pourcentage from '../global-functions/Convertion_pourcentage';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const EspacePersonnelScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Filtre_tableau, setFiltre_tableau] = React.useState('Montant investi');
  const [tab_financier_actif, setTab_financier_actif] = React.useState(true);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  // Renvoie VRAI si l'objet en entrée est un array vide
  const check_array_vide = array => {
    return Array.isArray(array) && array.length === 0;
  };

  // Conditionne l'apparition du message pour les soucriptions annulables
  const conditionnement_annulable = (bool_annulable, statut_souscription) => {
    const statutsValides = [
      'Souscription à régler',
      'Souscription validée mais annulable',
    ];
    return statutsValides.includes(statut_souscription) && bool_annulable;
  };

  // Prend un timestamp en entrée et renvoie NA s'il correspond à la date du jour. A défaut, renvoie la date du jour au format désiré.
  const formaterDate = timestamp => {
    var date = new Date(timestamp);
    var aujourdHui = new Date();

    // Vérifier si la date correspond à la date actuelle
    if (
      date.getDate() === aujourdHui.getDate() &&
      date.getMonth() === aujourdHui.getMonth() &&
      date.getFullYear() === aujourdHui.getFullYear()
    ) {
      return 'NA';
    } else {
      // Tableau des mois pour la conversion du numéro de mois en nom de mois
      var mois = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ];

      // Formater la date
      var jour = date.getDate();
      var moisEnLettres = mois[date.getMonth()];
      var annee = date.getFullYear();

      return jour + ' ' + moisEnLettres + ' ' + annee;
    }
  };

  // Trie la liste en entrée par la clé created_at.
  const trierParDate = listeObjets => {
    // Utilisation de la méthode sort() avec une fonction de comparaison pour trier les objets
    listeObjets.sort(function (a, b) {
      // Comparaison des dates en ordre décroissant
      return b.created_at - a.created_at;
    });

    return listeObjets;
  };
  const baseXanoMajVuesPublicationPUT = BaseXanoApi.useMajVuesPublicationPUT();
  const baseXanoMajLikesPUT = BaseXanoApi.useMajLikesPUT();
  const baseXanoMajVuesRessourcePUT = BaseXanoApi.useMajVuesRessourcePUT();
  const baseXanoMajLikesRessourcesPUT = BaseXanoApi.useMajLikesRessourcesPUT();
  const baseXanoMajBookmarkedPUT = BaseXanoApi.useMajBookmarkedPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Fetch client */}
      <BaseXanoApi.FetchAuthGET>
        {({ loading, error, data, refetchAuth }) => {
          const fetchClientData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* En-tête espace perso */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: 8,
                        paddingBottom: 8,
                        paddingLeft: 16,
                        paddingRight: 16,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Section */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'flex-start', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'Montserrat_500Medium',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Tablet, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 20 },
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 24 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Bonjour '}
                        {fetchClientData?.Prenom}
                        {' !'}
                      </Text>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 3,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App['Flolend 2']}
                          name={'Entypo/wallet'}
                          size={15}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'Montserrat_500Medium',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 15 },
                                { minWidth: Breakpoints.Tablet, value: 17 },
                                { minWidth: Breakpoints.Laptop, value: 19 },
                                { minWidth: Breakpoints.Desktop, value: 21 },
                                { minWidth: Breakpoints.BigScreen, value: 23 },
                              ],
                              paddingLeft: 8,
                              paddingRight: 5,
                              textTransform: 'capitalize',
                            },
                            dimensions.width
                          )}
                        >
                          {format_nombre(
                            fetchClientData?.verification_email
                              ? conversion_solde_wallet(
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.balance
                                )
                              : 0,
                            2
                          )}
                          {' €'}
                        </Text>
                      </View>
                    </View>
                    {/* Right Section */}
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('NotificationsScreen');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: 48,
                                  justifyContent: 'center',
                                  width: 48,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App.gris_fonce_flolend}
                                name={'Ionicons/notifications'}
                                size={30}
                              />
                              {/* Notifs à lire */}
                              <>
                                {!(
                                  fetchClientData?.statut_notif === 'A_lire'
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 4'],
                                        borderBottomWidth: 3,
                                        borderColor:
                                          palettes.App['Custom #ffffff'],
                                        borderLeftWidth: 3,
                                        borderRadius: 7,
                                        borderRightWidth: 3,
                                        borderTopWidth: 3,
                                        height: 14,
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        width: 14,
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          </Touchable>

                          <Touchable
                            onPress={() => {
                              try {
                                if (fetchClientData?.verification_email) {
                                  navigation.navigate('ProfilScreen');
                                }
                                if (!fetchClientData?.verification_email) {
                                  navigation.navigate('Inscription3Screen');
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              { marginLeft: 10 },
                              dimensions.width
                            )}
                          >
                            <Surface
                              elevation={3}
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  borderColor: palettes.App['Flolend 4'],
                                  borderRadius: 45,
                                  borderWidth: 1,
                                  height: 40,
                                  justifyContent: 'center',
                                  minHeight: 40,
                                  overflow: 'hidden',
                                  width: 40,
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'MaterialIcons/person'}
                                size={36}
                                style={StyleSheet.applyWidth(
                                  { backgroundColor: 'rgba(0, 0, 0, 0)' },
                                  dimensions.width
                                )}
                              />
                            </Surface>
                          </Touchable>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              <SimpleStyleScrollView
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                bounces={false}
                scrollEnabled={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={StyleSheet.applyWidth(
                  {
                    paddingTop: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: Constants['hauteur_navbar_laptop'],
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: Constants['hauteur_navbar_desktop'],
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: Constants['hauteur_navbar_big_screen'],
                      },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Vue d'ensemble */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginTop: { minWidth: Breakpoints.Laptop, value: 10 } },
                    dimensions.width
                  )}
                >
                  {/* En cours de souscription */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginBottom: [
                          { minWidth: Breakpoints.Laptop, value: 0 },
                          { minWidth: Breakpoints.Mobile, value: 10 },
                        ],
                        marginLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '10%',
                        },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '10%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Fetch souscriptions */}
                    <BaseXanoApi.FetchSouscriptionsClientGET>
                      {({
                        loading,
                        error,
                        data,
                        refetchSouscriptionsClient,
                      }) => {
                        const fetchSouscriptionsData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Header 2 */}
                            <>
                              {!fetchSouscriptionsData?.[0] ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      marginBottom: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 10,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '1%',
                                        },
                                      ],
                                      paddingLeft: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0,
                                        },
                                      ],
                                      paddingRight: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0,
                                        },
                                      ],
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Heading */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'titre1_generique'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'En cours de souscription'}
                                  </Text>
                                </View>
                              )}
                            </>
                            {/* Vue liste */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  maxHeight: !(
                                    dimensions.width >= Breakpoints.Laptop
                                  )
                                    ? 300
                                    : undefined,
                                  paddingLeft: 20,
                                  paddingRight: 20,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Liste verticale mobile */}
                              <>
                                {dimensions.width >=
                                Breakpoints.Laptop ? null : (
                                  <SimpleStyleFlatList
                                    data={fetchSouscriptionsData}
                                    inverted={false}
                                    keyExtractor={(
                                      listeVerticaleMobileData,
                                      index
                                    ) =>
                                      listeVerticaleMobileData?.id ??
                                      listeVerticaleMobileData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listeVerticaleMobileData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={'A8gIztq2'}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listeVerticaleMobileData = item;
                                      return (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              margin: {
                                                minWidth: Breakpoints.Tablet,
                                                value: 5,
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Touchable
                                            onPress={() => {
                                              try {
                                                navigation.navigate(
                                                  'GererMaSouscriptionScreen',
                                                  {
                                                    id_sous:
                                                      listeVerticaleMobileData?.id,
                                                  }
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            }}
                                            style={StyleSheet.applyWidth(
                                              {
                                                width: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 350,
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Surface
                                              elevation={3}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    palettes.App
                                                      .gris_encart_int_flolend,
                                                  borderRadius: 12,
                                                  flexDirection: 'row',
                                                  gap: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 10,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 10,
                                                    },
                                                  ],
                                                  minHeight: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 120,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 130,
                                                    },
                                                  ],
                                                  minWidth: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 350,
                                                  },
                                                  overflow: 'hidden',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* partie gauche */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    gap: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 5,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 5,
                                                      },
                                                    ],
                                                    padding: 10,
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: '70%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '70%',
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['sous_elmt_table'].props}
                                                  ellipsizeMode={'tail'}
                                                  numberOfLines={1}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['sous_elmt_table'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeVerticaleMobileData
                                                      ?.projets?.nom_operateur
                                                      ?.Nom
                                                  }
                                                </Text>
                                                {/* titre_projet */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre1_generique'].props}
                                                  ellipsizeMode={'tail'}
                                                  numberOfLines={2}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['titre1_generique']
                                                        .style,
                                                      {
                                                        marginBottom: null,
                                                        textAlign: 'auto',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeVerticaleMobileData
                                                      ?.projets?.Name
                                                  }
                                                </Text>

                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  ellipsizeMode={'tail'}
                                                  numberOfLines={1}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        palettes.App
                                                          .blanc_flolend,
                                                      fontFamily:
                                                        'PTSans_400Regular',
                                                      fontSize: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 14,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 18,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: 22,
                                                        },
                                                      ],
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {format_nombre(
                                                    listeVerticaleMobileData?.montant_souscrit_euros,
                                                    2
                                                  )}
                                                  {' €'}
                                                </Text>

                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* icone */}
                                                  <Icon
                                                    color={
                                                      palettes.App
                                                        .gris_fonce_flolend
                                                    }
                                                    name={
                                                      'MaterialCommunityIcons/clock-time-two-outline'
                                                    }
                                                    size={
                                                      (!(
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                      )
                                                        ? 18
                                                        : !(
                                                            dimensions.width >=
                                                            Breakpoints.Desktop
                                                          )
                                                        ? 25
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 45
                                                        : 35) ?? 18
                                                    }
                                                  />
                                                  {/* Message à signer */}
                                                  <>
                                                    {!(
                                                      listeVerticaleMobileData?.statut ===
                                                      'Bulletin à signer'
                                                    ) ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'statut_sous_en_cours'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'A signer avant le '}
                                                        {ConvertionDate(
                                                          listeVerticaleMobileData?.limite_signature
                                                        )}
                                                      </Text>
                                                    )}
                                                  </>
                                                  {/* Message à régler */}
                                                  <>
                                                    {!(
                                                      listeVerticaleMobileData?.statut ===
                                                        'Souscription à régler' &&
                                                      !listeVerticaleMobileData?.annulable
                                                    ) ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'statut_sous_en_cours'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'A régler avant le '}
                                                        {ConvertionDate(
                                                          listeVerticaleMobileData?.limite_reglement
                                                        )}
                                                      </Text>
                                                    )}
                                                  </>
                                                  {/* Message annulable */}
                                                  <>
                                                    {!conditionnement_annulable(
                                                      listeVerticaleMobileData?.annulable,
                                                      listeVerticaleMobileData?.statut
                                                    ) ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'statut_sous_en_cours'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {"Annulable jusqu'au "}
                                                        {ConvertionDate(
                                                          listeVerticaleMobileData?.limite_annulation
                                                        )}
                                                      </Text>
                                                    )}
                                                  </>
                                                  {/* Message liste attente */}
                                                  <>
                                                    {!(
                                                      listeVerticaleMobileData?.statut ===
                                                      "Liste d'attente"
                                                    ) ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'statut_sous_en_cours'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {"En attente jusqu'au "}
                                                        {ConvertionDate(
                                                          listeVerticaleMobileData
                                                            ?.projets
                                                            ?.date_fin_liste_attente
                                                        )}
                                                      </Text>
                                                    )}
                                                  </>
                                                  {/* Message pré-souscription */}
                                                  <>
                                                    {!(
                                                      listeVerticaleMobileData?.statut ===
                                                      'Pré-souscription'
                                                    ) ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'statut_sous_en_cours'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {"En attente jusqu'au "}
                                                        {ConvertionDate(
                                                          listeVerticaleMobileData
                                                            ?.projets
                                                            ?.date_fin_pre_souscriptions
                                                        )}
                                                      </Text>
                                                    )}
                                                  </>
                                                  {/* Message paiement suspens */}
                                                  <>
                                                    {!(
                                                      listeVerticaleMobileData?.statut ===
                                                      'Règlement en suspens'
                                                    ) ? null : (
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'statut_sous_en_cours'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          'En attente de la réception des fonds'
                                                        }
                                                      </Text>
                                                    )}
                                                  </>
                                                </View>
                                              </View>
                                              {/* partie droite */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    height: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: '100%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '100%',
                                                      },
                                                    ],
                                                    justifyContent:
                                                      'space-between',
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: '30%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '30%',
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  source={imageSource(
                                                    `${listeVerticaleMobileData?.projets?.Image_couverture?.url}`
                                                  )}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: '100%',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            </Surface>
                                          </Touchable>
                                        </View>
                                      );
                                    }}
                                    horizontal={false}
                                    nestedScrollEnabled={true}
                                    numColumns={
                                      dimensions.width >= Breakpoints.Tablet
                                        ? 2
                                        : 1
                                    }
                                    scrollEnabled={true}
                                    showsHorizontalScrollIndicator={false}
                                    showsVerticalScrollIndicator={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'center',
                                        },
                                        flexDirection: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'row',
                                        },
                                        gap: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 10,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 10,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 0,
                                          },
                                        ],
                                        paddingLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0,
                                        },
                                        paddingRight: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0,
                                        },
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Liste horizontale ordi */}
                              <>
                                {!(
                                  dimensions.width >= Breakpoints.Laptop
                                ) ? null : (
                                  <SimpleStyleFlatList
                                    data={fetchSouscriptionsData}
                                    inverted={false}
                                    keyExtractor={(
                                      listeHorizontaleOrdiData,
                                      index
                                    ) =>
                                      listeHorizontaleOrdiData?.id ??
                                      listeHorizontaleOrdiData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listeHorizontaleOrdiData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={'zT3V63ji'}
                                    nestedScrollEnabled={false}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listeHorizontaleOrdiData = item;
                                      return (
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'GererMaSouscriptionScreen',
                                                {
                                                  id_sous:
                                                    listeHorizontaleOrdiData?.id,
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <Surface
                                            elevation={3}
                                            style={StyleSheet.applyWidth(
                                              {
                                                backgroundColor:
                                                  palettes.App
                                                    .gris_encart_int_flolend,
                                                borderRadius: 12,
                                                flexDirection: 'row',
                                                gap: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 10,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 10,
                                                  },
                                                ],
                                                minHeight: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 120,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 150,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 170,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 200,
                                                  },
                                                ],
                                                minWidth: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 350,
                                                },
                                                overflow: 'hidden',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* partie gauche */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  flex: 1,
                                                  gap: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 5,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 5,
                                                    },
                                                  ],
                                                  justifyContent: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 'space-around',
                                                  },
                                                  padding: 10,
                                                  width: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: '70%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '70%',
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['sous_elmt_table'].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={1}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['sous_elmt_table'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  listeHorizontaleOrdiData
                                                    ?.projets?.nom_operateur
                                                    ?.Nom
                                                }
                                              </Text>

                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['titre1_generique'].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre1_generique'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  listeHorizontaleOrdiData
                                                    ?.projets?.Name
                                                }
                                              </Text>

                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={1}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App
                                                        .blanc_flolend,
                                                    fontFamily:
                                                      'PTSans_400Regular',
                                                    fontSize: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 14,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 18,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 22,
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {format_nombre(
                                                  listeHorizontaleOrdiData?.montant_souscrit_euros,
                                                  2
                                                )}
                                                {' €'}
                                              </Text>

                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* icone */}
                                                <Icon
                                                  color={
                                                    palettes.App
                                                      .gris_fonce_flolend
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/clock-time-two-outline'
                                                  }
                                                  size={
                                                    (!(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 45
                                                      : 35) ?? 18
                                                  }
                                                />
                                                {/* Message à signer */}
                                                <>
                                                  {!(
                                                    listeHorizontaleOrdiData?.statut ===
                                                    'Bulletin à signer'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['statut_sous_en_cours']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'A signer avant le '}
                                                      {ConvertionDate(
                                                        listeHorizontaleOrdiData?.limite_signature
                                                      )}
                                                    </Text>
                                                  )}
                                                </>
                                                {/* Message à régler */}
                                                <>
                                                  {!(
                                                    listeHorizontaleOrdiData?.statut ===
                                                      'Souscription à régler' &&
                                                    !listeHorizontaleOrdiData?.annulable
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['statut_sous_en_cours']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'A régler avant le '}
                                                      {ConvertionDate(
                                                        listeHorizontaleOrdiData?.limite_reglement
                                                      )}
                                                    </Text>
                                                  )}
                                                </>
                                                {/* Message annulable */}
                                                <>
                                                  {!conditionnement_annulable(
                                                    listeHorizontaleOrdiData?.annulable,
                                                    listeHorizontaleOrdiData?.statut
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['statut_sous_en_cours']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {"Annulable jusqu'au "}
                                                      {ConvertionDate(
                                                        listeHorizontaleOrdiData?.limite_annulation
                                                      )}
                                                    </Text>
                                                  )}
                                                </>
                                                {/* Message liste attente */}
                                                <>
                                                  {!(
                                                    listeHorizontaleOrdiData?.statut ===
                                                    "Liste d'attente"
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['statut_sous_en_cours']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {"En attente jusqu'au "}
                                                      {ConvertionDate(
                                                        listeHorizontaleOrdiData
                                                          ?.projets
                                                          ?.date_fin_liste_attente
                                                      )}
                                                    </Text>
                                                  )}
                                                </>
                                                {/* Message pré-souscription */}
                                                <>
                                                  {!(
                                                    listeHorizontaleOrdiData?.statut ===
                                                    'Pré-souscription'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['statut_sous_en_cours']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {"En attente jusqu'au "}
                                                      {ConvertionDate(
                                                        listeHorizontaleOrdiData
                                                          ?.projets
                                                          ?.date_fin_pre_souscriptions
                                                      )}
                                                    </Text>
                                                  )}
                                                </>
                                                {/* Message paiement suspens */}
                                                <>
                                                  {!(
                                                    listeHorizontaleOrdiData?.statut ===
                                                    'Règlement en suspens'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['statut_sous_en_cours']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'statut_sous_en_cours'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        'En attente de la réception des fonds'
                                                      }
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                            {/* partie droite */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  height: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: '100%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '100%',
                                                    },
                                                  ],
                                                  justifyContent:
                                                    'space-between',
                                                  width: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: '30%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '30%',
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Image
                                                resizeMode={'cover'}
                                                source={imageSource(
                                                  `${listeHorizontaleOrdiData?.projets?.Image_couverture?.url}`
                                                )}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    height: '100%',
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            </View>
                                          </Surface>
                                        </Touchable>
                                      );
                                    }}
                                    horizontal={true}
                                    numColumns={1}
                                    scrollEnabled={true}
                                    showsHorizontalScrollIndicator={false}
                                    showsVerticalScrollIndicator={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'row',
                                        },
                                        gap: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 5,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 10,
                                          },
                                        ],
                                        paddingLeft: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 24,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0,
                                          },
                                        ],
                                        paddingRight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 24,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                            </View>
                          </>
                        );
                      }}
                    </BaseXanoApi.FetchSouscriptionsClientGET>
                  </View>
                  {/* Bandeau Mes projets */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginBottom: [
                          { minWidth: Breakpoints.Mobile, value: '2%' },
                          { minWidth: Breakpoints.Laptop, value: '0%' },
                        ],
                        marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Bandeau mes projets avec invest. */}
                    <>
                      {!fetchClientData?.souscriptions_du_client?.[0] ? null : (
                        <View>
                          {/* Header */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor:
                                  palettes.App.flolend_gris_nuance_encart,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingLeft: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  { minWidth: Breakpoints.Mobile, value: '5%' },
                                ],
                                paddingRight: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  { minWidth: Breakpoints.Mobile, value: '5%' },
                                ],
                                paddingTop: [
                                  { minWidth: Breakpoints.Laptop, value: '1%' },
                                  { minWidth: Breakpoints.Mobile, value: '1%' },
                                ],
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Heading */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Mes projets'}
                            </Text>
                            {/* Link 2 */}
                            <Link
                              accessible={true}
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'DetailsInvestissementsScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectable={false}
                              {...GlobalStyles.LinkStyles(theme)[
                                'bouton_voir_tout'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.LinkStyles(theme)[
                                  'bouton_voir_tout'
                                ].style,
                                dimensions.width
                              )}
                              title={'Voir tout'}
                            />
                          </View>
                          {/* Carrousel */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App.flolend_gris_nuance_encart,
                                flexDirection: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'row',
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'space-between',
                                },
                                maxWidth: dimensions.width,
                                paddingBottom: [
                                  { minWidth: Breakpoints.Desktop, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                ],
                                paddingLeft: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  { minWidth: Breakpoints.Mobile, value: '5%' },
                                ],
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '10%',
                                },
                                paddingTop: [
                                  { minWidth: Breakpoints.Desktop, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            <SimpleStyleScrollView
                              keyboardShouldPersistTaps={'never'}
                              nestedScrollEnabled={false}
                              bounces={false}
                              horizontal={true}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  flexDirection: 'row',
                                  maxWidth: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '50%',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: dimensions.width,
                                    },
                                  ],
                                  minWidth: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '0%',
                                    },
                                  ],
                                  paddingRight: 20,
                                },
                                dimensions.width
                              )}
                            >
                              <SimpleStyleFlatList
                                data={fetchClientData?.souscriptions_du_client}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'cyg2nKWB'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <>
                                      {/* Record */}
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'ProjetScreen',
                                              {
                                                id_transfere:
                                                  listData?.projets?.id,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              overflow: 'hidden',
                                              paddingBottom: [
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: '0%',
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: '0%',
                                                },
                                              ],
                                              paddingTop: [
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: '0%',
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: '0%',
                                                },
                                              ],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderRadius: 12,
                                                overflow: 'hidden',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Image
                                              resizeMode={'cover'}
                                              {...GlobalStyles.ImageStyles(
                                                theme
                                              )['band_proj_images'].props}
                                              source={imageSource(
                                                `${listData?.projets?.Image_couverture?.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'band_proj_images'
                                                ].style,
                                                dimensions.width
                                              )}
                                            />
                                          </View>
                                          {/* Details */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'details_encart_caroussel_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'details_encart_caroussel_projet'
                                                ].style,
                                                {
                                                  maxWidth: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 135,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Desktop,
                                                      value: 190,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 220,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 170,
                                                    },
                                                  ],
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {/* Name */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['band_proj_nom'].props}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={1}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'band_proj_nom'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.projets?.Name}
                                            </Text>
                                            {/* Typologie */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['band_proj_type'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'band_proj_type'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listData?.projets
                                                  ?.Categorie_projet
                                              }
                                            </Text>

                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['vue_roue_caroussel_projet']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'vue_roue_caroussel_projet'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['band_proj_date'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['band_proj_date'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Fin le '}
                                                {ConvertionDate(
                                                  listData?.projets?.date_de_fin
                                                )}
                                              </Text>
                                              {/* Circular mobile et laptop */}
                                              <CircularProgress
                                                animationDuration={500}
                                                indeterminate={false}
                                                isAnimated={true}
                                                lineCap={'round'}
                                                showTrack={true}
                                                startPosition={'top'}
                                                trackLineCap={'round'}
                                                color={
                                                  palettes.App['Flolend 4']
                                                }
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    height: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 20,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 25,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 30,
                                                      },
                                                    ],
                                                    width: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 20,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 25,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 30,
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                                thickness={5}
                                                trackColor={
                                                  palettes.App
                                                    .gris_fonce_flolend
                                                }
                                                value={Convertion_pourcentage(
                                                  listData?.projets
                                                    ?.Part_collectee
                                                )}
                                              />
                                            </View>

                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['band_proj_montant'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'band_proj_montant'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {format_nombre(
                                                listData?.montant_souscrit_euros,
                                                undefined
                                              )}
                                              {' € investis'}
                                            </Text>
                                          </View>
                                        </View>
                                      </Touchable>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                horizontal={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    flex: 1,
                                    flexDirection: 'row',
                                    gap: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 25,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 35,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 10,
                                      },
                                    ],
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              />
                            </SimpleStyleScrollView>
                            {/* Image laptop */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                      justifyContent: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'center',
                                      },
                                      paddingLeft: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '1%',
                                      },
                                      width: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '50%',
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Vue carte */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderRadius: 10,
                                        bottom: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 2,
                                        },
                                        height: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 240,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '100%',
                                          },
                                        ],
                                        overflow: 'hidden',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <MapView
                                      apiKey={
                                        'AIzaSyDjye57xkL_-0UANi1wfaLujHnserggE1M'
                                      }
                                      autoClusterMarkers={false}
                                      autoClusterMarkersDistanceMeters={10000}
                                      customMapStyle={
                                        'Lorem ipsum dolor sit amet'
                                      }
                                      keyExtractor={(mapViewData, index) =>
                                        mapViewData?.id ??
                                        mapViewData?.uuid ??
                                        index?.toString() ??
                                        JSON.stringify(mapViewData)
                                      }
                                      listKey={'W7HZIvLL'}
                                      loadingEnabled={true}
                                      markersData={
                                        fetchClientData?.localisation_projets
                                      }
                                      moveOnMarkerPress={true}
                                      renderItem={({ item, index }) => {
                                        const mapViewData = item;
                                        return (
                                          <MapMarker
                                            androidUseDefaultIconImplementation={
                                              false
                                            }
                                            flat={false}
                                            pinImageSize={50}
                                            tracksViewChanges={true}
                                            latitude={mapViewData?.latitude}
                                            longitude={mapViewData?.longitude}
                                            pinColor={palettes.App['Flolend 2']}
                                            pinImage={''}
                                            title={mapViewData?.nom}
                                          />
                                        );
                                      }}
                                      rotateEnabled={true}
                                      scrollEnabled={true}
                                      showsCompass={false}
                                      showsPointsOfInterest={true}
                                      showsUserLocation={false}
                                      zoomEnabled={true}
                                      latitude={47}
                                      longitude={2}
                                      mapType={'standard'}
                                      provider={'google'}
                                      style={StyleSheet.applyWidth(
                                        { flex: 1 },
                                        dimensions.width
                                      )}
                                      zoom={5 ?? 16}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Bandeau Mes projets sans invest */}
                    <>
                      {fetchClientData?.souscriptions_du_client?.[0] ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              maxWidth: {
                                minWidth: Breakpoints.Desktop,
                                value: dimensions.width,
                              },
                              paddingLeft: {
                                minWidth: Breakpoints.Laptop,
                                value: '10%',
                              },
                              paddingRight: {
                                minWidth: Breakpoints.Laptop,
                                value: '10%',
                              },
                              paddingTop: {
                                minWidth: Breakpoints.Laptop,
                                value: 10,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Header */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                paddingLeft: [
                                  { minWidth: Breakpoints.Mobile, value: '5%' },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                paddingRight: [
                                  { minWidth: Breakpoints.Mobile, value: '5%' },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                paddingTop: [
                                  { minWidth: Breakpoints.Mobile, value: '1%' },
                                  { minWidth: Breakpoints.Laptop, value: 0 },
                                ],
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Heading */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Mes projets'}
                              </Text>
                              {/* Link 2 */}
                              <Link
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)[
                                  'bouton_voir_tout'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.LinkStyles(theme)[
                                    'bouton_voir_tout'
                                  ].style,
                                  dimensions.width
                                )}
                                title={'Voir tout'}
                              />
                            </View>

                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['explications']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['explications']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {
                                'Vous retrouverez les projets auxquels vous avez souscrit dans cette section !'
                              }
                            </Text>
                          </View>
                          {/* Carrousel */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'row',
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'space-between',
                                },
                                paddingBottom: [
                                  { minWidth: Breakpoints.Desktop, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                ],
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '0%',
                                },
                                paddingTop: [
                                  { minWidth: Breakpoints.Desktop, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                ],
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <SimpleStyleScrollView
                              keyboardShouldPersistTaps={'never'}
                              nestedScrollEnabled={false}
                              bounces={false}
                              horizontal={true}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  gap: [
                                    { minWidth: Breakpoints.Mobile, value: 15 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 25,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 35,
                                    },
                                  ],
                                  paddingLeft: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '5%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '0%',
                                    },
                                  ],
                                  paddingRight: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '5%',
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 0 },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '50%',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Projet 1 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    paddingBottom: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                    paddingTop: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 12, overflow: 'hidden' },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'band_proj_images'
                                    ].props}
                                    source={imageSource(Images['Image3'])}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ImageStyles(theme)[
                                        'band_proj_images'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* Details */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'details_encart_caroussel_projet'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)[
                                      'details_encart_caroussel_projet'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Name */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_nom'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_nom'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Mon projet'}
                                  </Text>
                                  {/* Typologie */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_type'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_type'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Photovoltaïque'}
                                  </Text>

                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'vue_roue_caroussel_projet'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'vue_roue_caroussel_projet'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'band_proj_date'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'band_proj_date'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Fin en déc. 25'}
                                    </Text>
                                    <CircularProgress
                                      animationDuration={500}
                                      indeterminate={false}
                                      isAnimated={true}
                                      lineCap={'round'}
                                      showTrack={true}
                                      startPosition={'top'}
                                      trackLineCap={'round'}
                                      color={palettes.App['Flolend 4']}
                                      style={StyleSheet.applyWidth(
                                        { height: 20, width: 20 },
                                        dimensions.width
                                      )}
                                      thickness={5}
                                      trackColor={
                                        palettes.App.gris_fonce_flolend
                                      }
                                      value={75}
                                    />
                                  </View>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_montant'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_montant'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'500 € investis'}
                                  </Text>
                                </View>
                              </View>
                              {/* Projet 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    paddingBottom: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                    paddingTop: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 12, overflow: 'hidden' },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'band_proj_images'
                                    ].props}
                                    source={imageSource(Images['Image5'])}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ImageStyles(theme)[
                                        'band_proj_images'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* Details */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'details_encart_caroussel_projet'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)[
                                      'details_encart_caroussel_projet'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Name */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_nom'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_nom'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Mon projet'}
                                  </Text>
                                  {/* Typologie */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_type'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_type'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Eolien'}
                                  </Text>
                                  {/* vue roue */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        gap: 10,
                                        justifyContent: 'space-evenly',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'band_proj_date'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'band_proj_date'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Fin en mar. 26'}
                                    </Text>
                                    <CircularProgress
                                      animationDuration={500}
                                      indeterminate={false}
                                      isAnimated={true}
                                      lineCap={'round'}
                                      showTrack={true}
                                      startPosition={'top'}
                                      trackLineCap={'round'}
                                      color={palettes.App['Flolend 4']}
                                      style={StyleSheet.applyWidth(
                                        { height: 20, width: 20 },
                                        dimensions.width
                                      )}
                                      thickness={5}
                                      trackColor={
                                        palettes.App.gris_fonce_flolend
                                      }
                                      value={50}
                                    />
                                  </View>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_montant'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_montant'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'1 500 € investis'}
                                  </Text>
                                </View>
                              </View>
                              {/* Projet 3 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    paddingBottom: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                    paddingTop: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 12, overflow: 'hidden' },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'band_proj_images'
                                    ].props}
                                    source={imageSource(
                                      Images['WindEnergy73421771280']
                                    )}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ImageStyles(theme)[
                                        'band_proj_images'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* Details */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'details_encart_caroussel_projet'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)[
                                      'details_encart_caroussel_projet'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Name */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_nom'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_nom'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Mon projet'}
                                  </Text>
                                  {/* Typologie */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_type'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_type'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Eolien'}
                                  </Text>

                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'vue_roue_caroussel_projet'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'vue_roue_caroussel_projet'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'band_proj_date'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'band_proj_date'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Fin en mai. 25'}
                                    </Text>
                                    <CircularProgress
                                      animationDuration={500}
                                      indeterminate={false}
                                      isAnimated={true}
                                      lineCap={'round'}
                                      showTrack={true}
                                      startPosition={'top'}
                                      trackLineCap={'round'}
                                      color={palettes.App['Flolend 4']}
                                      style={StyleSheet.applyWidth(
                                        { height: 20, width: 20 },
                                        dimensions.width
                                      )}
                                      thickness={5}
                                      trackColor={
                                        palettes.App.gris_fonce_flolend
                                      }
                                      value={90}
                                    />
                                  </View>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_montant'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_montant'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'10 € investis'}
                                  </Text>
                                </View>
                              </View>
                              {/* Projet 4 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    paddingBottom: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                    paddingTop: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 12, overflow: 'hidden' },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'band_proj_images'
                                    ].props}
                                    source={imageSource(
                                      Images[
                                        '_2c933e096b808cb57df8aab19ba06f93'
                                      ]
                                    )}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ImageStyles(theme)[
                                        'band_proj_images'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* Details */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'details_encart_caroussel_projet'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)[
                                      'details_encart_caroussel_projet'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Name */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_nom'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_nom'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Mon projet'}
                                  </Text>
                                  {/* Typologie */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_type'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_type'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Photovoltaïque'}
                                  </Text>

                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'vue_roue_caroussel_projet'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'vue_roue_caroussel_projet'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'band_proj_date'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'band_proj_date'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Fin en mai. 24'}
                                    </Text>
                                    <CircularProgress
                                      animationDuration={500}
                                      indeterminate={false}
                                      isAnimated={true}
                                      lineCap={'round'}
                                      showTrack={true}
                                      startPosition={'top'}
                                      trackLineCap={'round'}
                                      color={palettes.App['Flolend 4']}
                                      style={StyleSheet.applyWidth(
                                        { height: 20, width: 20 },
                                        dimensions.width
                                      )}
                                      thickness={5}
                                      trackColor={
                                        palettes.App.gris_fonce_flolend
                                      }
                                      value={90}
                                    />
                                  </View>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_montant'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_montant'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'93 € investis'}
                                  </Text>
                                </View>
                              </View>
                              {/* Projet 5 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    paddingBottom: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                    paddingTop: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '0%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '0%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { borderRadius: 12, overflow: 'hidden' },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    resizeMode={'cover'}
                                    {...GlobalStyles.ImageStyles(theme)[
                                      'band_proj_images'
                                    ].props}
                                    source={imageSource(Images['H2air'])}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ImageStyles(theme)[
                                        'band_proj_images'
                                      ].style,
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* Details */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'details_encart_caroussel_projet'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)[
                                      'details_encart_caroussel_projet'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Name */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_nom'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_nom'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Mon projet'}
                                  </Text>
                                  {/* Typologie */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_type'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_type'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Eolien'}
                                  </Text>

                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'vue_roue_caroussel_projet'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'vue_roue_caroussel_projet'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'band_proj_date'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'band_proj_date'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Fin en avr. 24'}
                                    </Text>
                                    <CircularProgress
                                      animationDuration={500}
                                      indeterminate={false}
                                      isAnimated={true}
                                      lineCap={'round'}
                                      showTrack={true}
                                      startPosition={'top'}
                                      trackLineCap={'round'}
                                      color={palettes.App['Flolend 4']}
                                      style={StyleSheet.applyWidth(
                                        { height: 20, width: 20 },
                                        dimensions.width
                                      )}
                                      thickness={5}
                                      trackColor={
                                        palettes.App.gris_fonce_flolend
                                      }
                                      value={90}
                                    />
                                  </View>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'band_proj_montant'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'band_proj_montant'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'15 € investis'}
                                  </Text>
                                </View>
                              </View>
                            </SimpleStyleScrollView>
                            {/* vue droite ordi */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                      justifyContent: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'center',
                                      },
                                      paddingLeft: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '2%',
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: '1%',
                                        },
                                      ],
                                      width: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '50%',
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Vue carte */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        borderRadius: 10,
                                        bottom: {
                                          minWidth: Breakpoints.Desktop,
                                          value: 2,
                                        },
                                        height: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 240,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '100%',
                                          },
                                        ],
                                        overflow: 'hidden',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <MapView
                                      apiKey={
                                        'AIzaSyDjye57xkL_-0UANi1wfaLujHnserggE1M'
                                      }
                                      autoClusterMarkers={false}
                                      autoClusterMarkersDistanceMeters={10000}
                                      customMapStyle={
                                        'Lorem ipsum dolor sit amet'
                                      }
                                      loadingEnabled={true}
                                      moveOnMarkerPress={true}
                                      rotateEnabled={true}
                                      scrollEnabled={true}
                                      showsCompass={false}
                                      showsPointsOfInterest={true}
                                      showsUserLocation={false}
                                      zoomEnabled={true}
                                      latitude={47}
                                      longitude={2}
                                      mapType={'standard'}
                                      provider={'google'}
                                      style={StyleSheet.applyWidth(
                                        { flex: 1 },
                                        dimensions.width
                                      )}
                                      zoom={5 ?? 16}
                                    >
                                      <MapMarker
                                        androidUseDefaultIconImplementation={
                                          false
                                        }
                                        flat={false}
                                        pinImageSize={50}
                                        tracksViewChanges={true}
                                        latitude={47}
                                        longitude={2}
                                        pinColor={palettes.App['Flolend 2']}
                                        pinImage={''}
                                        title={'Retrouvez vos projets ici'}
                                      />
                                    </MapView>
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                  {/* selecteur_en_tabs */}
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Pressable Financier */}
                        <Pressable
                          onPress={() => {
                            try {
                              setTab_financier_actif(!tab_financier_actif);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          {/* bouton */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 2,
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App['Flolend 4'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: tab_financier_actif
                                      ? palettes.App['Flolend 4']
                                      : palettes.App.gris_fonce_flolend,
                                  },
                                ],
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                (tab_financier_actif
                                  ? palettes.App['Flolend 4']
                                  : palettes.App.gris_fonce_flolend) ??
                                palettes.App['Flolend 1']
                              }
                              name={'Foundation/graph-bar'}
                            />
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 1'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: tab_financier_actif
                                          ? palettes.App['Flolend 4']
                                          : palettes.App.gris_fonce_flolend,
                                      },
                                    ],
                                    fontFamily: 'Montserrat_400Regular',
                                    fontSize: 13,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Financier'}
                            </Text>
                          </View>
                        </Pressable>
                        {/* Pressable Actus */}
                        <Pressable
                          onPress={() => {
                            try {
                              setTab_financier_actif(!tab_financier_actif);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          {/* bouton */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 2,
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App['Flolend 4'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: tab_financier_actif
                                      ? palettes.App.gris_fonce_flolend
                                      : palettes.App['Flolend 4'],
                                  },
                                ],
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                (tab_financier_actif
                                  ? palettes.App.gris_fonce_flolend
                                  : palettes.App['Flolend 4']) ??
                                palettes.App['Flolend 1']
                              }
                              name={'Entypo/camera'}
                            />
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 1'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: tab_financier_actif
                                          ? palettes.App.gris_fonce_flolend
                                          : palettes.App['Flolend 4'],
                                      },
                                    ],
                                    fontFamily: 'Montserrat_400Regular',
                                    fontSize: 13,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Actus'}
                            </Text>
                          </View>
                        </Pressable>
                      </View>
                    )}
                  </>
                  {/* Vue en onglets */}
                  <View>
                    {/* Tab financier */}
                    <>
                      {!tab_financier_actif ? null : (
                        <View>
                          {/* Aperçu gen. + éch + livret */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'row',
                                },
                                justifyContent: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'space-between',
                                },
                                marginLeft: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '15%',
                                  },
                                ],
                                marginRight: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '15%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Aperçu général */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginTop: 15,
                                  paddingRight: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '5%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '1%',
                                    },
                                  ],
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '50%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Aperçu général avec invest */}
                              <>
                                {!fetchClientData
                                  ?.souscriptions_du_client?.[0] ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                        ],
                                        marginRight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                        ],
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Aperçu général de mes projets en cours'}
                                    </Text>
                                    {/* Nombre dashboard */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flex: 1 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Capital investi'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 42,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 50,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {format_nombre(
                                            fetchClientData
                                              ?.metriques_clients_projets_en_cours
                                              ?.montant_investi_euros,
                                            2
                                          )}
                                          {' €'}
                                        </Text>
                                        {/* Text 2 */}
                                        <>
                                          {fetchClientData
                                            ?.metriques_clients_projets_en_cours
                                            ?.montant_investi_euros ===
                                          fetchClientData
                                            ?.metriques_clients_projets_en_cours
                                            ?.montant_investi_avec_en_cours_sous ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['explications'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'explications'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                'Avec projets en cours de souscription : '
                                              }
                                              {format_nombre(
                                                fetchClientData
                                                  ?.metriques_clients_projets_en_cours
                                                  ?.montant_investi_avec_en_cours_sous,
                                                2
                                              )}
                                              {' €'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                    {/* Nombre dashboard 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flex: 1 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Intérêts et dividendes perçus'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {format_nombre(
                                            fetchClientData
                                              ?.metriques_clients_projets_en_cours
                                              ?.interets_percus_euros,
                                            2
                                          )}
                                          {' €'}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* Nombre dashboard 3 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            flex: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Intérêts et dividendes à percevoir'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {format_nombre(
                                            fetchClientData
                                              ?.metriques_clients_projets_en_cours
                                              ?.interets_a_percevoir_euros,
                                            2
                                          )}
                                          {' €'}
                                        </Text>
                                        {/* Text 3 */}
                                        <>
                                          {fetchClientData
                                            ?.metriques_clients_projets_en_cours
                                            ?.interets_a_percevoir_euros ===
                                          fetchClientData
                                            ?.metriques_clients_projets_en_cours
                                            ?.interets_futurs_avec_en_cours ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['explications'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'explications'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                'Avec projets en cours de souscription : '
                                              }
                                              {format_nombre(
                                                fetchClientData
                                                  ?.metriques_clients_projets_en_cours
                                                  ?.interets_futurs_avec_en_cours,
                                                2
                                              )}
                                              {' €'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                    {/* Nombre dashboard 4 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            flex: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Prochain versement'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {formaterDate(
                                            fetchClientData
                                              ?.metriques_clients_projets_en_cours
                                              ?.prochaine_echeance_client
                                          )}
                                        </Text>
                                        {/* Text 4 */}
                                        <>
                                          {fetchClientData
                                            ?.metriques_clients_projets_en_cours
                                            ?.prochaine_echeance_client ===
                                          fetchClientData
                                            ?.metriques_clients_projets_en_cours
                                            ?.proch_ech_avec_en_cours ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['explications'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'explications'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                'Avec projets en cours de souscription : '
                                              }
                                              {formaterDate(
                                                fetchClientData
                                                  ?.metriques_clients_projets_en_cours
                                                  ?.proch_ech_avec_en_cours
                                              )}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                    </View>
                                  </View>
                                )}
                              </>
                              {/* Aperçu général sans invest */}
                              <>
                                {fetchClientData
                                  ?.souscriptions_du_client?.[0] ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                        ],
                                        marginRight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                        ],
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '1%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Aperçu général de mes projets en cours'}
                                    </Text>
                                    {/* Nombre dashboard */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flex: 1 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Capital investi'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'0 €'}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* Nombre dashboard 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flex: 1 },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Intérêts et dividendes perçus'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'0 €'}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* Nombre dashboard 3 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          marginBottom: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            flex: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Intérêts et dividendes à percevoir'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'0 €'}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* Nombre dashboard 4 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flexDirection: 'row' },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderColor: 'rgba(0, 0, 0, 0)',
                                            flex: 1,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_2_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_2_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Prochain versement'}
                                        </Text>

                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'metrique_apercu_gen'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'metrique_apercu_gen'
                                              ].style,
                                              {
                                                fontSize: {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'-'}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                )}
                              </>
                            </View>
                            {/* Échéancier général et livret */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                  flexDirection: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'column',
                                  },
                                  gap: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 20,
                                  },
                                  justifyContent: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'space-around',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'flex-start',
                                    },
                                  ],
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '0%',
                                  },
                                  marginRight: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '0%',
                                  },
                                  paddingLeft: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '5%',
                                    },
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '1%',
                                    },
                                  ],
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '50%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Vue échéancier général */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: {
                                      minWidth: Breakpoints.Tablet,
                                      value: '0%',
                                    },
                                    marginLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '5%',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '0%',
                                      },
                                    ],
                                    marginRight: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '5%',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '0%',
                                      },
                                    ],
                                    marginTop: 15,
                                    paddingRight: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '0%',
                                    },
                                    width: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '100%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Text 3 */}
                                <>
                                  {!check_array_vide(
                                    fetchClientData?.souscriptions_du_client
                                  ) ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'explications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'explications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        'Vous aurez ici accès à un échéancier général des projets dans lesquels vous aurez investi !'
                                      }
                                    </Text>
                                  )}
                                </>
                                {/* Flex Frame for Touchable */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'center',
                                      },
                                      justifyContent: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'center',
                                      },
                                      width: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: '100%',
                                        },
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: '50%',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '100%',
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        if (
                                          !fetchClientData
                                            ?.souscriptions_du_client?.[0]
                                        ) {
                                          return;
                                        }
                                        navigation.navigate(
                                          'EcheancierClientScreen'
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    <LinearGradient
                                      endX={100}
                                      endY={100}
                                      startX={0}
                                      startY={0}
                                      {...GlobalStyles.LinearGradientStyles(
                                        theme
                                      )['gradient_bouton'].props}
                                      color1={palettes.App['Flolend 1']}
                                      color2={palettes.App['Flolend 2']}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].style,
                                          {
                                            borderRadius: 64,
                                            flex: null,
                                            height: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 75,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 100,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 125,
                                              },
                                            ],
                                            justifyContent: 'center',
                                            marginTop: 0,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Button Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(0, 0, 0, 0)',
                                            borderColor:
                                              palettes.App['Flolend 1'],
                                            flexDirection: 'row',
                                            flexGrow: 1,
                                            flexShrink: 0,
                                            gap: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 10,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 30,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 10,
                                              },
                                            ],
                                            paddingLeft: [
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: '5%',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '5%',
                                              },
                                            ],
                                            paddingRight: [
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: '5%',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '5%',
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Flex Frame for Icons */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: [
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 'center',
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 'center',
                                                },
                                              ],
                                              backgroundColor: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value:
                                                    theme.colors.background
                                                      .brand,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value:
                                                    theme.colors.background
                                                      .brand,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    theme.colors.background
                                                      .brand,
                                                },
                                              ],
                                              borderRadius: [
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 100,
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 100,
                                                },
                                              ],
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              height: [
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 70,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 80,
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 50,
                                                },
                                              ],
                                              justifyContent: 'center',
                                              width: [
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 70,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 80,
                                                },
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 50,
                                                },
                                              ],
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Icon mobile et tablette */}
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <Icon
                                                size={24}
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Ionicons/calendar-sharp'}
                                              />
                                            )}
                                          </>
                                          {/* Icon laptop */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                                Breakpoints.Laptop &&
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Ionicons/calendar-sharp'}
                                                size={30}
                                              />
                                            )}
                                          </>
                                          {/* Icon desktop */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                                Breakpoints.Desktop &&
                                              !(
                                                dimensions.width >=
                                                Breakpoints.BigScreen
                                              )
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Ionicons/calendar-sharp'}
                                                size={40}
                                              />
                                            )}
                                          </>
                                          {/* Icon big screen */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.BigScreen
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Ionicons/calendar-sharp'}
                                                size={50}
                                              />
                                            )}
                                          </>
                                        </View>
                                        {/* Flex for Headline */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              flexGrow: 1,
                                              flexShrink: 0,
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Rubik Headline Style 18/24 Bold */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  theme.colors.background.brand,
                                                fontFamily:
                                                  'Montserrat_700Bold',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 15,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 20,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 24,
                                                  },
                                                ],
                                                lineHeight: 21,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Voir mon échéancier général'}
                                          </Text>
                                        </View>
                                        {/* Flex Frame for Icons */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { justifyContent: 'center' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Icon mobile et tablette */}
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Tablet ? null : (
                                              <Icon
                                                color={
                                                  theme.colors.background.brand
                                                }
                                                name={'Ionicons/caret-forward'}
                                                size={18}
                                              />
                                            )}
                                          </>
                                          {/* Icon laptop */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                                Breakpoints.Laptop &&
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  theme.colors.background.brand
                                                }
                                                name={'Ionicons/caret-forward'}
                                                size={25}
                                              />
                                            )}
                                          </>
                                          {/* Icon desktop */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                                Breakpoints.Desktop &&
                                              !(
                                                dimensions.width >=
                                                Breakpoints.BigScreen
                                              )
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  theme.colors.background.brand
                                                }
                                                name={'Ionicons/caret-forward'}
                                                size={35}
                                              />
                                            )}
                                          </>
                                          {/* Icon big screen */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.BigScreen
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  theme.colors.background.brand
                                                }
                                                name={'Ionicons/caret-forward'}
                                                size={45}
                                              />
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    </LinearGradient>
                                  </Touchable>
                                </View>
                              </View>
                              {/* Vue Mon livret */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    marginBottom: {
                                      minWidth: Breakpoints.Tablet,
                                      value: '0%',
                                    },
                                    marginLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '5%',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '0%',
                                      },
                                    ],
                                    marginRight: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '5%',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '0%',
                                      },
                                    ],
                                    marginTop: 15,
                                    paddingLeft: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '0%',
                                    },
                                    width: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: '100%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <>
                                  {dimensions.width >=
                                  Breakpoints.Tablet ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Mon livret'}
                                    </Text>
                                  )}
                                </>
                                {/* Livret */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'center',
                                      },
                                      marginTop: 10,
                                      width: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: '50%',
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '100%',
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        if (
                                          fetchClientData?.verification_email
                                        ) {
                                          navigation.navigate(
                                            'MonLivretFlolendScreen'
                                          );
                                        }
                                        if (
                                          !fetchClientData?.verification_email
                                        ) {
                                          navigation.navigate(
                                            'Inscription3Screen'
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Card Frame */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App['Flolend 1'],
                                          borderRadius: 12,
                                          height: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 150,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 150,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 175,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 200,
                                            },
                                          ],
                                          justifyContent: 'space-between',
                                          paddingBottom: '5%',
                                          paddingLeft: '5%',
                                          paddingRight: '5%',
                                          paddingTop: '5%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Top Line Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Bank Label */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'livret_flolend_titre'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'livret_flolend_titre'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Livret Flolend'}
                                        </Text>
                                        <Image
                                          resizeMode={'contain'}
                                          source={imageSource(
                                            Images['LogoWireframeLight']
                                          )}
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 20,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 24,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 28,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 30,
                                                },
                                              ],
                                              width: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 50,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 60,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 70,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 30,
                                                },
                                              ],
                                            },
                                            dimensions.width
                                          )}
                                        />
                                      </View>

                                      <View>
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'livret_flolend_solde_titre'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'livret_flolend_solde_titre'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Solde'}
                                        </Text>

                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'flex-start',
                                              flexDirection: 'row',
                                              marginTop: 6,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App.blanc_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 26,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'€'}
                                          </Text>

                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'livret_flolend_solde'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'livret_flolend_solde'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {!fetchClientData?.verification_email
                                              ? format_nombre(0, 2)
                                              : format_nombre(
                                                  conversion_solde_wallet(
                                                    fetchClientData
                                                      ?.wallet_lemonway
                                                      ?.compte_client?.balance
                                                  ),
                                                  2
                                                )}
                                          </Text>
                                        </View>
                                      </View>
                                    </View>
                                  </Touchable>
                                </View>
                              </View>
                            </View>
                          </View>
                          {/* ESG + Localisation + docs */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginLeft: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '15%',
                                  },
                                ],
                                marginRight: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '10%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '15%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Performance ESG */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginTop: [
                                    { minWidth: Breakpoints.Mobile, value: 15 },
                                    { minWidth: Breakpoints.Laptop, value: 30 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Vue Performance ESG avec invest */}
                              <>
                                {!fetchClientData
                                  ?.souscriptions_du_client?.[0] ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: {
                                          minWidth: Breakpoints.Tablet,
                                          value: '0%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Titre */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '1%',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: '2%',
                                            },
                                          ],
                                          marginLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          marginRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'Performance ESG de mes projets en cours'
                                        }
                                      </Text>
                                    </View>
                                    {/* Scroll View 2 */}
                                    <SimpleStyleScrollView
                                      bounces={true}
                                      keyboardShouldPersistTaps={'never'}
                                      nestedScrollEnabled={false}
                                      showsHorizontalScrollIndicator={true}
                                      horizontal={true}
                                      showsVerticalScrollIndicator={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          gap: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 10,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 30,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 50,
                                            },
                                          ],
                                          justifyContent: {
                                            minWidth: Breakpoints.Tablet,
                                            value: 'center',
                                          },
                                          marginBottom: {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                          marginTop: 10,
                                          paddingBottom: 5,
                                          paddingLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 20,
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          paddingRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 20,
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '2%',
                                            },
                                          ],
                                          paddingTop: 2,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Surface 3 */}
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'surface_esg'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'surface_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Environnement */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'carte_esg'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ViewStyles(theme)[
                                              'carte_esg'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Environnement'}
                                          </Text>
                                          <CircularProgress
                                            animationDuration={500}
                                            color={
                                              theme.colors.branding.primary
                                            }
                                            indeterminate={false}
                                            isAnimated={true}
                                            lineCap={'round'}
                                            showTrack={true}
                                            startPosition={'top'}
                                            thickness={10}
                                            trackColor={
                                              theme.colors.border.brand
                                            }
                                            trackLineCap={'round'}
                                            {...GlobalStyles.CircularProgressStyles(
                                              theme
                                            )['cercle_esg'].props}
                                            maximumValue={100}
                                            minimumValue={0}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              dimensions.width
                                            )}
                                            value={
                                              fetchClientData
                                                ?.metriques_clients_projets_en_cours
                                                ?.moyenne_environnement
                                            }
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'note_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'note_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Moyenne projets :\n'}
                                            {format_nombre(
                                              fetchClientData
                                                ?.metriques_clients_projets_en_cours
                                                ?.moyenne_environnement,
                                              1
                                            )}
                                            {' %'}
                                          </Text>
                                        </View>
                                      </Surface>
                                      {/* Surface 2 */}
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'surface_esg'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'surface_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Social */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'carte_esg'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'carte_esg'
                                              ].style,
                                              {
                                                borderWidth: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: null,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Social'}
                                          </Text>
                                          <CircularProgress
                                            animationDuration={500}
                                            indeterminate={false}
                                            isAnimated={true}
                                            lineCap={'round'}
                                            showTrack={true}
                                            startPosition={'top'}
                                            thickness={10}
                                            trackColor={
                                              theme.colors.border.brand
                                            }
                                            trackLineCap={'round'}
                                            {...GlobalStyles.CircularProgressStyles(
                                              theme
                                            )['cercle_esg'].props}
                                            color={palettes.App['Flolend 2']}
                                            maximumValue={100}
                                            minimumValue={0}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              dimensions.width
                                            )}
                                            value={
                                              fetchClientData
                                                ?.metriques_clients_projets_en_cours
                                                ?.moyenne_social
                                            }
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'note_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'note_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Moyenne projets :\n'}
                                            {format_nombre(
                                              fetchClientData
                                                ?.metriques_clients_projets_en_cours
                                                ?.moyenne_social,
                                              1
                                            )}
                                            {' %'}
                                          </Text>
                                        </View>
                                      </Surface>

                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'surface_esg'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'surface_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Gouvernance */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'carte_esg'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'carte_esg'
                                              ].style,
                                              {
                                                borderWidth: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: null,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Gouvernance'}
                                          </Text>
                                          <CircularProgress
                                            animationDuration={500}
                                            indeterminate={false}
                                            isAnimated={true}
                                            lineCap={'round'}
                                            showTrack={true}
                                            startPosition={'top'}
                                            thickness={10}
                                            trackColor={
                                              theme.colors.border.brand
                                            }
                                            trackLineCap={'round'}
                                            {...GlobalStyles.CircularProgressStyles(
                                              theme
                                            )['cercle_esg'].props}
                                            color={palettes.App['Flolend 1']}
                                            maximumValue={100}
                                            minimumValue={0}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              dimensions.width
                                            )}
                                            value={
                                              fetchClientData
                                                ?.metriques_clients_projets_en_cours
                                                ?.moyenne_gouvernance
                                            }
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'note_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'note_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Moyenne projets :\n'}
                                            {format_nombre(
                                              fetchClientData
                                                ?.metriques_clients_projets_en_cours
                                                ?.moyenne_gouvernance,
                                              1
                                            )}
                                            {' %'}
                                          </Text>
                                        </View>
                                      </Surface>
                                    </SimpleStyleScrollView>
                                  </View>
                                )}
                              </>
                              {/* Vue Performance ESG sans invest */}
                              <>
                                {fetchClientData
                                  ?.souscriptions_du_client?.[0] ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: {
                                          minWidth: Breakpoints.Tablet,
                                          value: '0%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Titre 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginBottom: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '1%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          marginLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          marginRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'Performance ESG de mes projets en cours'
                                        }
                                      </Text>
                                    </View>
                                    {/* Scroll View 2 */}
                                    <SimpleStyleScrollView
                                      bounces={true}
                                      keyboardShouldPersistTaps={'never'}
                                      nestedScrollEnabled={false}
                                      showsHorizontalScrollIndicator={true}
                                      horizontal={true}
                                      showsVerticalScrollIndicator={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          gap: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 10,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 30,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 50,
                                            },
                                          ],
                                          justifyContent: {
                                            minWidth: Breakpoints.Tablet,
                                            value: 'center',
                                          },
                                          marginBottom: {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                          marginLeft: {
                                            minWidth: Breakpoints.Tablet,
                                            value: '0%',
                                          },
                                          marginTop: 10,
                                          paddingBottom: 5,
                                          paddingLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          paddingRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '2%',
                                            },
                                          ],
                                          paddingTop: 5,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Surface 6 */}
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'surface_esg'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'surface_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Environnement */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'carte_esg'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ViewStyles(theme)[
                                              'carte_esg'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Environnement'}
                                          </Text>
                                          <CircularProgress
                                            animationDuration={500}
                                            color={
                                              theme.colors.branding.primary
                                            }
                                            indeterminate={false}
                                            isAnimated={true}
                                            lineCap={'round'}
                                            showTrack={true}
                                            startPosition={'top'}
                                            thickness={10}
                                            trackColor={
                                              theme.colors.border.brand
                                            }
                                            trackLineCap={'round'}
                                            {...GlobalStyles.CircularProgressStyles(
                                              theme
                                            )['cercle_esg'].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              dimensions.width
                                            )}
                                            value={85}
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'explications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'explications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Moyenne de vos projets'}
                                          </Text>
                                        </View>
                                      </Surface>
                                      {/* Surface 5 */}
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'surface_esg'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'surface_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Social */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'carte_esg'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ViewStyles(theme)[
                                              'carte_esg'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Social'}
                                          </Text>
                                          <CircularProgress
                                            animationDuration={500}
                                            indeterminate={false}
                                            isAnimated={true}
                                            lineCap={'round'}
                                            showTrack={true}
                                            startPosition={'top'}
                                            thickness={10}
                                            trackColor={
                                              theme.colors.border.brand
                                            }
                                            trackLineCap={'round'}
                                            {...GlobalStyles.CircularProgressStyles(
                                              theme
                                            )['cercle_esg'].props}
                                            color={palettes.App['Flolend 2']}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              dimensions.width
                                            )}
                                            value={90}
                                          />
                                          {/* Text 2 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'explications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'explications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Moyenne de vos projets'}
                                          </Text>
                                        </View>
                                      </Surface>
                                      {/* Surface 4 */}
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'surface_esg'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'surface_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Gouvernance */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'carte_esg'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ViewStyles(theme)[
                                              'carte_esg'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_carte_esg'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_carte_esg'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Gouvernance'}
                                          </Text>
                                          <CircularProgress
                                            animationDuration={500}
                                            indeterminate={false}
                                            isAnimated={true}
                                            lineCap={'round'}
                                            showTrack={true}
                                            startPosition={'top'}
                                            thickness={10}
                                            trackColor={
                                              theme.colors.border.brand
                                            }
                                            trackLineCap={'round'}
                                            {...GlobalStyles.CircularProgressStyles(
                                              theme
                                            )['cercle_esg'].props}
                                            color={palettes.App['Flolend 1']}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              dimensions.width
                                            )}
                                            value={80}
                                          />
                                          {/* Text 3 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'explications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'explications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Moyenne de vos projets'}
                                          </Text>
                                        </View>
                                      </Surface>
                                    </SimpleStyleScrollView>
                                    {/* Message développement durable */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'explications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'explications'
                                          ].style,
                                          {
                                            alignSelf: 'center',
                                            marginLeft: '5%',
                                            marginRight: '5%',
                                            marginTop: 10,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        "Vous pourrez voir ici l'impact de vos investissements sur votre empreinte carbone !"
                                      }
                                    </Text>
                                  </View>
                                )}
                              </>
                            </View>
                            {/* Localisation */}
                            <>
                              {dimensions.width >= Breakpoints.Laptop ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginTop: 15 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Vue Localisation avec invest */}
                                  <>
                                    {!fetchClientData
                                      ?.souscriptions_du_client?.[0] ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                            justifyContent: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'space-between',
                                            },
                                            marginLeft: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '5%',
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: '0%',
                                              },
                                            ],
                                            marginRight: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '5%',
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: '0%',
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Titre */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre1_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            'Localisation de mes projets en cours'
                                          }
                                        </Text>
                                        {/* Vue carte */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderRadius: 10,
                                              bottom: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 2,
                                              },
                                              height: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 240,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 230,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 300,
                                                },
                                              ],
                                              marginTop: 10,
                                              overflow: 'hidden',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <MapView
                                            apiKey={
                                              'AIzaSyDjye57xkL_-0UANi1wfaLujHnserggE1M'
                                            }
                                            autoClusterMarkers={false}
                                            autoClusterMarkersDistanceMeters={
                                              10000
                                            }
                                            customMapStyle={
                                              'Lorem ipsum dolor sit amet'
                                            }
                                            keyExtractor={(
                                              mapViewData,
                                              index
                                            ) =>
                                              mapViewData?.id ??
                                              mapViewData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(mapViewData)
                                            }
                                            listKey={'ZfKfiXYb'}
                                            loadingEnabled={true}
                                            markersData={
                                              fetchClientData?.localisation_projets
                                            }
                                            moveOnMarkerPress={true}
                                            renderItem={({ item, index }) => {
                                              const mapViewData = item;
                                              return (
                                                <MapMarker
                                                  androidUseDefaultIconImplementation={
                                                    false
                                                  }
                                                  flat={false}
                                                  pinImageSize={50}
                                                  tracksViewChanges={true}
                                                  latitude={
                                                    mapViewData?.latitude
                                                  }
                                                  longitude={
                                                    mapViewData?.longitude
                                                  }
                                                  pinColor={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  pinImage={''}
                                                  title={mapViewData?.nom}
                                                />
                                              );
                                            }}
                                            rotateEnabled={true}
                                            scrollEnabled={true}
                                            showsCompass={false}
                                            showsPointsOfInterest={true}
                                            showsUserLocation={false}
                                            zoomEnabled={true}
                                            latitude={47}
                                            longitude={2}
                                            mapType={'standard'}
                                            provider={'google'}
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                            zoom={5 ?? 16}
                                          />
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* Vue Localisation sans invest */}
                                  <>
                                    {fetchClientData
                                      ?.souscriptions_du_client?.[0] ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            borderRadius: 16,
                                            height: {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                            marginLeft: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '5%',
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: '0%',
                                              },
                                            ],
                                            marginRight: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: '5%',
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: '0%',
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Titre */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre1_generique'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            'Localisation de mes projets en cours'
                                          }
                                        </Text>
                                        {/* Text 4 */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'explications'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'explications'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            'Vous retrouverez tous vos projets sur la carte ci-dessous.'
                                          }
                                        </Text>
                                        {/* Vue carte */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              borderRadius: 10,
                                              height: 240,
                                              marginTop: 10,
                                              overflow: 'hidden',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <MapView
                                            apiKey={
                                              'AIzaSyDjye57xkL_-0UANi1wfaLujHnserggE1M'
                                            }
                                            autoClusterMarkers={false}
                                            autoClusterMarkersDistanceMeters={
                                              10000
                                            }
                                            customMapStyle={
                                              'Lorem ipsum dolor sit amet'
                                            }
                                            loadingEnabled={true}
                                            moveOnMarkerPress={true}
                                            rotateEnabled={true}
                                            scrollEnabled={true}
                                            showsCompass={false}
                                            showsPointsOfInterest={true}
                                            showsUserLocation={false}
                                            zoomEnabled={true}
                                            latitude={47}
                                            longitude={2}
                                            mapType={'standard'}
                                            provider={'google'}
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                            zoom={5 ?? 16}
                                          >
                                            <MapMarker
                                              androidUseDefaultIconImplementation={
                                                false
                                              }
                                              flat={false}
                                              pinImageSize={50}
                                              tracksViewChanges={true}
                                              latitude={47}
                                              longitude={2}
                                              pinColor={
                                                palettes.App['Flolend 2']
                                              }
                                              pinImage={''}
                                              title={
                                                'Retrouvez vos projets ici'
                                              }
                                            />
                                          </MapView>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                            {/* Documents */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '5%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '0%',
                                    },
                                  ],
                                  marginRight: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '5%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '0%',
                                    },
                                  ],
                                  marginTop: [
                                    { minWidth: Breakpoints.Mobile, value: 15 },
                                    { minWidth: Breakpoints.Laptop, value: 30 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Banque de documents avec invest */}
                              <>
                                {!fetchClientData
                                  ?.souscriptions_du_client?.[0] ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        height: 450,
                                        marginBottom: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        marginLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        marginRight: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        marginTop: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignContent: 'flex-start',
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          marginBottom: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 10,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: '2%',
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 4 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Mes documents'}
                                      </Text>
                                      <Link
                                        accessible={true}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'BanqueDocumentsScreen'
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        selectable={false}
                                        {...GlobalStyles.LinkStyles(theme)[
                                          'bouton_voir_tout'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.LinkStyles(theme)[
                                            'bouton_voir_tout'
                                          ].style,
                                          dimensions.width
                                        )}
                                        title={'Voir tout'}
                                      />
                                    </View>
                                    <Table
                                      borderColor={theme.colors.border.brand}
                                      borderStyle={'solid'}
                                      borderWidth={1}
                                      cellHorizontalPadding={10}
                                      cellVerticalPadding={10}
                                      data={trierParDate(
                                        fetchClientData?.documents_du_client
                                      )}
                                      drawBottomBorder={false}
                                      drawEndBorder={false}
                                      drawStartBorder={false}
                                      keyExtractor={(tableData, index) =>
                                        tableData?.id ??
                                        tableData?.uuid ??
                                        index?.toString() ??
                                        JSON.stringify(tableData)
                                      }
                                      listKey={'j2msCQQe'}
                                      renderItem={({ item, index }) => {
                                        const tableData = item;
                                        return (
                                          <>
                                            {/* En-tête */}
                                            <TableRow
                                              drawEndBorder={false}
                                              drawTopBorder={false}
                                              borderColor={
                                                palettes.App.gris_fonce_flolend
                                              }
                                              borderWidth={2}
                                              drawBottomBorder={true}
                                              drawStartBorder={false}
                                              isTableHeader={true}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    palettes.App['Flolend 1'],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <TableCell
                                                drawBottomBorder={false}
                                                drawStartBorder={false}
                                                drawTopBorder={false}
                                                {...GlobalStyles.TableCellStyles(
                                                  theme
                                                )['Table Cell'].props}
                                                drawEndBorder={false}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TableCellStyles(
                                                      theme
                                                    )['Table Cell'].style,
                                                    {
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre_tableau'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['titre_tableau'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Projet'}
                                                </Text>
                                              </TableCell>
                                              {/* Table Cell 2 */}
                                              <TableCell
                                                drawBottomBorder={false}
                                                drawStartBorder={false}
                                                drawTopBorder={false}
                                                {...GlobalStyles.TableCellStyles(
                                                  theme
                                                )['Table Cell'].props}
                                                drawEndBorder={false}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TableCellStyles(
                                                      theme
                                                    )['Table Cell'].style,
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Flolend 1'
                                                        ],
                                                      justifyContent: 'center',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre_tableau'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['titre_tableau'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Document'}
                                                </Text>
                                              </TableCell>
                                              {/* Table Cell 4 */}
                                              <TableCell
                                                drawBottomBorder={false}
                                                drawStartBorder={false}
                                                drawTopBorder={false}
                                                {...GlobalStyles.TableCellStyles(
                                                  theme
                                                )['Table Cell'].props}
                                                drawEndBorder={false}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TableCellStyles(
                                                      theme
                                                    )['Table Cell'].style,
                                                    {
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre_tableau'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['titre_tableau'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Action'}
                                                </Text>
                                              </TableCell>
                                            </TableRow>
                                            {/* Données */}
                                            <TableRow
                                              drawBottomBorder={true}
                                              drawEndBorder={false}
                                              drawTopBorder={false}
                                              isTableHeader={false}
                                              borderColor={
                                                palettes.App.gris_fonce_flolend
                                              }
                                              borderWidth={1}
                                              drawStartBorder={false}
                                            >
                                              <TableCell
                                                drawBottomBorder={false}
                                                drawStartBorder={false}
                                                drawTopBorder={false}
                                                {...GlobalStyles.TableCellStyles(
                                                  theme
                                                )['Table Cell'].props}
                                                drawEndBorder={false}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TableCellStyles(
                                                      theme
                                                    )['Table Cell'].style,
                                                    {
                                                      alignItems: 'flex-start',
                                                      flexDirection: 'column',
                                                      justifyContent:
                                                        'flex-start',
                                                      overflow: 'hidden',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['elmt_table'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['elmt_table'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    tableData?.liaison_projets
                                                      ?.projet_addon?.Name
                                                  }
                                                </Text>
                                                {/* Text 2 */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['sous_elmt_table'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['sous_elmt_table'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Souscrit le '}
                                                  {ConvertionDate(
                                                    tableData?.liaison_projets
                                                      ?.projet_addon
                                                      ?.addon_souscription
                                                      ?.Date_signature_bulletin
                                                  )}
                                                </Text>
                                              </TableCell>
                                              {/* Table Cell 2 */}
                                              <TableCell
                                                drawBottomBorder={false}
                                                drawStartBorder={false}
                                                drawTopBorder={false}
                                                {...GlobalStyles.TableCellStyles(
                                                  theme
                                                )['Table Cell'].props}
                                                drawEndBorder={false}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TableCellStyles(
                                                      theme
                                                    )['Table Cell'].style,
                                                    {
                                                      alignItems: 'flex-start',
                                                      flexDirection: 'column',
                                                      justifyContent:
                                                        'flex-start',
                                                      overflow: 'hidden',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Document */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['elmt_table'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['elmt_table'].style,
                                                      { textAlign: 'auto' }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {tableData?.Nom_du_doc}
                                                </Text>
                                                {/* Text 3 */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['sous_elmt_table'].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['sous_elmt_table'].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Emis le '}
                                                  {ConvertionDate(
                                                    tableData?.date_emission
                                                  )}
                                                </Text>
                                              </TableCell>
                                              {/* Table Cell 4 */}
                                              <TableCell
                                                drawBottomBorder={false}
                                                drawStartBorder={false}
                                                drawTopBorder={false}
                                                {...GlobalStyles.TableCellStyles(
                                                  theme
                                                )['Table Cell'].props}
                                                drawEndBorder={false}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TableCellStyles(
                                                      theme
                                                    )['Table Cell'].style,
                                                    {
                                                      alignItems: 'center',
                                                      justifyContent:
                                                        'space-around',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Touchable 2 */}
                                                <>
                                                  {Platform.OS ===
                                                  'web' ? null : (
                                                    <Touchable
                                                      onPress={() => {
                                                        const handler =
                                                          async () => {
                                                            try {
                                                              await openShareUtil(
                                                                `${tableData?.document?.url}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          };
                                                        handler();
                                                      }}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        color={
                                                          palettes.App[
                                                            'Flolend 2'
                                                          ]
                                                        }
                                                        name={
                                                          'MaterialIcons/mobile-screen-share'
                                                        }
                                                      />
                                                    </Touchable>
                                                  )}
                                                </>
                                                {/* Téléchargement */}
                                                <Touchable
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          await WebBrowser.openBrowserAsync(
                                                            `${tableData?.document?.url}`
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                >
                                                  {/* Icon mobile et tablette */}
                                                  <Icon
                                                    color={
                                                      palettes.App['Flolend 2']
                                                    }
                                                    name={
                                                      'FontAwesome/download'
                                                    }
                                                    size={
                                                      !(
                                                        dimensions.width >=
                                                        Breakpoints.Desktop
                                                      )
                                                        ? 18
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 30
                                                        : 25
                                                    }
                                                  />
                                                </Touchable>
                                              </TableCell>
                                            </TableRow>
                                          </>
                                        );
                                      }}
                                      showsVerticalScrollIndicator={true}
                                      {...GlobalStyles.TableStyles(theme)[
                                        'Table'
                                      ].props}
                                      drawTopBorder={false}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableStyles(theme)['Table']
                                          .style,
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                              {/* Banque de documents sans invest */}
                              <>
                                {fetchClientData
                                  ?.souscriptions_du_client?.[0] ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginBottom: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        marginLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        marginRight: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        marginTop: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '0%',
                                        },
                                        paddingLeft: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '1%',
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { marginBottom: 10 },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Mes documents'}
                                      </Text>
                                      {/* Text 3 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'explications'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'explications'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'Vous retrouverez tous vos documents dans cette section.'
                                        }
                                      </Text>
                                    </View>

                                    <Table
                                      borderColor={theme.colors.border.brand}
                                      borderStyle={'solid'}
                                      borderWidth={1}
                                      cellHorizontalPadding={10}
                                      cellVerticalPadding={10}
                                      drawBottomBorder={false}
                                      drawEndBorder={false}
                                      drawStartBorder={false}
                                      showsVerticalScrollIndicator={true}
                                      {...GlobalStyles.TableStyles(theme)[
                                        'Table'
                                      ].props}
                                      drawTopBorder={false}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TableStyles(theme)['Table']
                                          .style,
                                        dimensions.width
                                      )}
                                    >
                                      {/* En-tête */}
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={2}
                                        drawStartBorder={false}
                                        isTableHeader={true}
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_tableau'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_tableau'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Projet'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  palettes.App['Flolend 1'],
                                                justifyContent: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_tableau'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_tableau'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Document'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 4 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_tableau'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_tableau'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Action'}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                      {/* Données */}
                                      <TableRow
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawBottomBorder={true}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Mon projet'}
                                          </Text>
                                          {/* Text 2 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Souscrit en jan 2022'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: null,
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Document */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {"Planches d'analyse financière"}
                                          </Text>
                                          {/* Text 3 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Emis en jan 2022'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* icone */}
                                          <Icon
                                            color={palettes.App['Flolend 2']}
                                            name={'FontAwesome/download'}
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 18
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 30
                                                : 25
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                      {/* Données 2 */}
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Mon projet'}
                                          </Text>
                                          {/* Text 2 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Souscrit en mar 2021'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: null,
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Document */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Bulletin de souscription'}
                                          </Text>
                                          {/* Text 4 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Emis en jan 2022'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* icone */}
                                          <Icon
                                            color={palettes.App['Flolend 2']}
                                            name={'FontAwesome/download'}
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 18
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 30
                                                : 25
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                      {/* Données 3 */}
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={2}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Mon projet'}
                                          </Text>
                                          {/* Text 2 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Souscrit en fév 2023'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: null,
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Document */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Rapport annuel'}
                                          </Text>
                                          {/* Text 5 */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Emis en jan 2022'}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* icone */}
                                          <Icon
                                            color={palettes.App['Flolend 2']}
                                            name={'FontAwesome/download'}
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 18
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 30
                                                : 25
                                            }
                                          />
                                        </TableCell>
                                      </TableRow>
                                    </Table>
                                  </View>
                                )}
                              </>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Fetch contenu */}
                    <BaseXanoApi.FetchContenuClientGET
                      nombre_retours_aff={
                        dimensions.width >= Breakpoints.Tablet ? 2 : 1
                      }
                      nombre_retours_hors_aff={
                        dimensions.width >= Breakpoints.Tablet ? 3 : 2
                      }
                      nombre_retours_ressources={5}
                    >
                      {({ loading, error, data, refetchContenuClient }) => {
                        const fetchContenuData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Tab actus */}
                            <>
                              {tab_financier_actif ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: {
                                        minWidth: Breakpoints.Laptop,
                                        value:
                                          palettes.App
                                            .flolend_gris_nuance_encart,
                                      },
                                      borderRadius: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 25,
                                      },
                                      marginLeft: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '10%',
                                      },
                                      marginRight: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '10%',
                                      },
                                      marginTop: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                      paddingBottom: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                      paddingTop: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* A l'affiche */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '10%',
                                          },
                                        ],
                                        marginRight: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '10%',
                                          },
                                        ],
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 15,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0,
                                          },
                                        ],
                                        paddingLeft: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: '10%',
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: '15%',
                                          },
                                        ],
                                        paddingRight: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: '10%',
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: '15%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* A l'affiche avec invest */}
                                    <>
                                      {!fetchContenuData?.actus_affiche ? null : (
                                        <View
                                          style={StyleSheet.applyWidth(
                                            { width: '100%' },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bandeau titre */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 10,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['titre1_generique'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'titre1_generique'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {"A l'affiche"}
                                            </Text>
                                            {/* Link 2 */}
                                            <Link
                                              accessible={true}
                                              onPress={() => {
                                                try {
                                                  navigation.navigate(
                                                    'ActualitesClientScreen'
                                                  );
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              }}
                                              selectable={false}
                                              {...GlobalStyles.LinkStyles(
                                                theme
                                              )['bouton_voir_tout'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.LinkStyles(theme)[
                                                  'bouton_voir_tout'
                                                ].style,
                                                dimensions.width
                                              )}
                                              title={'Voir tout'}
                                            />
                                          </View>
                                          {/* Liste mobile et tablette */}
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <SimpleStyleFlatList
                                                data={
                                                  fetchContenuData?.actus_affiche
                                                }
                                                inverted={false}
                                                keyExtractor={(
                                                  listeMobileEtTabletteData,
                                                  index
                                                ) =>
                                                  listeMobileEtTabletteData?.id ??
                                                  listeMobileEtTabletteData?.uuid ??
                                                  index?.toString() ??
                                                  JSON.stringify(
                                                    listeMobileEtTabletteData
                                                  )
                                                }
                                                keyboardShouldPersistTaps={
                                                  'never'
                                                }
                                                listKey={'RyfeUwUA'}
                                                nestedScrollEnabled={false}
                                                numColumns={1}
                                                onEndReachedThreshold={0.5}
                                                renderItem={({
                                                  item,
                                                  index,
                                                }) => {
                                                  const listeMobileEtTabletteData =
                                                    item;
                                                  return (
                                                    <>
                                                      {/* Record Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            gap: 10,
                                                            width: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: '100%',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: '50%',
                                                              },
                                                            ],
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Touchable
                                                          onPress={() => {
                                                            const handler =
                                                              async () => {
                                                                try {
                                                                  (
                                                                    await baseXanoMajVuesPublicationPUT.mutateAsync(
                                                                      {
                                                                        id_actu:
                                                                          listeMobileEtTabletteData?.id,
                                                                      }
                                                                    )
                                                                  )?.json;
                                                                  navigation.navigate(
                                                                    'ActualiteScreen',
                                                                    {
                                                                      id_actu_airtable:
                                                                        listeMobileEtTabletteData?.id,
                                                                    }
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              };
                                                            handler();
                                                          }}
                                                        >
                                                          {/* Cadre image */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                height: 200,
                                                                width: '100%',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Image
                                                              resizeMode={
                                                                'cover'
                                                              }
                                                              source={imageSource(
                                                                `${listeMobileEtTabletteData?.couv_actu?.url}`
                                                              )}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            />
                                                          </View>
                                                        </Touchable>
                                                        {/* Annotations */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                              justifyContent:
                                                                'space-between',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Circle Image Frame */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'center',
                                                                flexDirection:
                                                                  'row',
                                                                gap: 10,
                                                                maxWidth: '60%',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {/* View 2 */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  borderRadius: 50,
                                                                  height: 50,
                                                                  overflow:
                                                                    'hidden',
                                                                  width: 50,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Image
                                                                resizeMode={
                                                                  'cover'
                                                                }
                                                                {...GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .props}
                                                                source={imageSource(
                                                                  `${listeMobileEtTabletteData?.image_auteur?.url}`
                                                                )}
                                                                style={StyleSheet.applyWidth(
                                                                  StyleSheet.compose(
                                                                    GlobalStyles.ImageStyles(
                                                                      theme
                                                                    )['Image']
                                                                      .style,
                                                                    {
                                                                      height:
                                                                        '100%',
                                                                      width:
                                                                        '100%',
                                                                    }
                                                                  ),
                                                                  dimensions.width
                                                                )}
                                                              />
                                                            </View>

                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  maxWidth:
                                                                    '65%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Auteur */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'auteur_affiche'
                                                                ].props}
                                                                ellipsizeMode={
                                                                  'tail'
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'auteur_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  listeMobileEtTabletteData?.Auteur
                                                                }
                                                              </Text>
                                                              {/* Projet */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'sous_titre_carte_affiche'
                                                                ].props}
                                                                ellipsizeMode={
                                                                  'tail'
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'sous_titre_carte_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {'pour '}
                                                                {
                                                                  listeMobileEtTabletteData
                                                                    ?.projets
                                                                    ?.Name
                                                                }
                                                              </Text>
                                                            </View>
                                                          </View>
                                                          {/* ligne likes */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexDirection:
                                                                  'row',
                                                                gap: 10,
                                                                justifyContent:
                                                                  'space-between',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {/* Action 1 Frame */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  gap: 5,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Checkbox
                                                                onPress={newCheckboxValue => {
                                                                  const handler =
                                                                    async () => {
                                                                      try {
                                                                        (
                                                                          await baseXanoMajLikesPUT.mutateAsync(
                                                                            {
                                                                              actu_id:
                                                                                listeMobileEtTabletteData?.id,
                                                                            }
                                                                          )
                                                                        )?.json;
                                                                        await refetchContenuClient();
                                                                      } catch (err) {
                                                                        console.error(
                                                                          err
                                                                        );
                                                                      }
                                                                    };
                                                                  handler();
                                                                }}
                                                                checkedIcon={
                                                                  'AntDesign/heart'
                                                                }
                                                                color={
                                                                  palettes.App[
                                                                    'Flolend 4'
                                                                  ]
                                                                }
                                                                defaultValue={
                                                                  listeMobileEtTabletteData?.client_likeur
                                                                }
                                                                size={
                                                                  (!(
                                                                    dimensions.width >=
                                                                    Breakpoints.Desktop
                                                                  )
                                                                    ? 18
                                                                    : dimensions.width >=
                                                                      Breakpoints.BigScreen
                                                                    ? 30
                                                                    : 25) ?? 18
                                                                }
                                                                uncheckedColor={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                uncheckedIcon={
                                                                  'AntDesign/hearto'
                                                                }
                                                              />
                                                              {/* Likes */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'likes_card_affiche'
                                                                ].props}
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'likes_card_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {listeMobileEtTabletteData?.Likes >=
                                                                1000
                                                                  ? `${
                                                                      listeMobileEtTabletteData?.Likes /
                                                                      1000
                                                                    }K`
                                                                  : listeMobileEtTabletteData?.Likes}
                                                              </Text>
                                                            </View>
                                                            {/* Action 3 Frame */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  gap: 5,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* checkbox */}
                                                              <Checkbox
                                                                checkedIcon={
                                                                  'AntDesign/eyeo'
                                                                }
                                                                color={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                size={
                                                                  (!(
                                                                    dimensions.width >=
                                                                    Breakpoints.Desktop
                                                                  )
                                                                    ? 18
                                                                    : dimensions.width >=
                                                                      Breakpoints.BigScreen
                                                                    ? 30
                                                                    : 25) ?? 18
                                                                }
                                                                uncheckedColor={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                uncheckedIcon={
                                                                  'AntDesign/eyeo'
                                                                }
                                                              />
                                                              {/* Vues */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'likes_card_affiche'
                                                                ].props}
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'likes_card_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {listeMobileEtTabletteData?.Vues >=
                                                                1000
                                                                  ? `${
                                                                      listeMobileEtTabletteData?.Vues /
                                                                      1000
                                                                    }K`
                                                                  : listeMobileEtTabletteData?.Vues}
                                                              </Text>
                                                            </View>
                                                            {/* Flex Frame for Touchable */}
                                                            <>
                                                              {!(
                                                                listeMobileEtTabletteData?.autorisation_partage &&
                                                                !(
                                                                  Platform.OS ===
                                                                  'web'
                                                                )
                                                              ) ? null : (
                                                                <View>
                                                                  <Touchable
                                                                    onPress={() => {
                                                                      const handler =
                                                                        async () => {
                                                                          try {
                                                                            await openShareUtil(
                                                                              `${listeMobileEtTabletteData?.lien_partage}`
                                                                            );
                                                                          } catch (err) {
                                                                            console.error(
                                                                              err
                                                                            );
                                                                          }
                                                                        };
                                                                      handler();
                                                                    }}
                                                                  >
                                                                    {/* Flex Frame for Icons */}
                                                                    <View>
                                                                      {/* icone_partage */}
                                                                      <Icon
                                                                        color={
                                                                          palettes
                                                                            .App[
                                                                            'Flolend 2'
                                                                          ]
                                                                        }
                                                                        name={
                                                                          'Ionicons/share-outline'
                                                                        }
                                                                        size={
                                                                          (!(
                                                                            dimensions.width >=
                                                                            Breakpoints.Desktop
                                                                          )
                                                                            ? 18
                                                                            : dimensions.width >=
                                                                              Breakpoints.BigScreen
                                                                            ? 30
                                                                            : 25) ??
                                                                          18
                                                                        }
                                                                      />
                                                                    </View>
                                                                  </Touchable>
                                                                </View>
                                                              )}
                                                            </>
                                                          </View>
                                                        </View>
                                                        {/* Ligne titre */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            { width: '100%' },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Top Row Frame */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexGrow: 0,
                                                                flexShrink: 0,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {/* Titre */}
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              {...GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'titre_carte_affiche'
                                                              ].props}
                                                              ellipsizeMode={
                                                                'tail'
                                                              }
                                                              numberOfLines={2}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'titre_carte_affiche'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                listeMobileEtTabletteData?.Header_contenu
                                                              }
                                                            </Text>
                                                          </View>
                                                          {/* Second Row Frame */}
                                                          <View>
                                                            {/* Titre2 */}
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              {...GlobalStyles.TextStyles(
                                                                theme
                                                              )['corps_contenu']
                                                                .props}
                                                              ellipsizeMode={
                                                                'tail'
                                                              }
                                                              numberOfLines={3}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'corps_contenu'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                listeMobileEtTabletteData?.Header2
                                                              }
                                                            </Text>
                                                          </View>
                                                        </View>
                                                      </View>
                                                    </>
                                                  );
                                                }}
                                                horizontal={false}
                                                scrollEnabled={false}
                                                showsHorizontalScrollIndicator={
                                                  false
                                                }
                                                showsVerticalScrollIndicator={
                                                  false
                                                }
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignSelf: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'center',
                                                    },
                                                    gap: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 220,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 390,
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                          {/* Liste laptop or larger */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.Laptop
                                            ) ? null : (
                                              <SimpleStyleFlatList
                                                data={
                                                  fetchContenuData?.actus_affiche
                                                }
                                                inverted={false}
                                                keyExtractor={(
                                                  listeLaptopOrLargerData,
                                                  index
                                                ) =>
                                                  listeLaptopOrLargerData?.id ??
                                                  listeLaptopOrLargerData?.uuid ??
                                                  index?.toString() ??
                                                  JSON.stringify(
                                                    listeLaptopOrLargerData
                                                  )
                                                }
                                                keyboardShouldPersistTaps={
                                                  'never'
                                                }
                                                listKey={'7ZMC7aU4'}
                                                nestedScrollEnabled={false}
                                                onEndReachedThreshold={0.5}
                                                renderItem={({
                                                  item,
                                                  index,
                                                }) => {
                                                  const listeLaptopOrLargerData =
                                                    item;
                                                  return (
                                                    <>
                                                      {/* Record Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            gap: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 10,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: 10,
                                                              },
                                                            ],
                                                            width: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: '100%',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: 350,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Desktop,
                                                                value: 420,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 500,
                                                              },
                                                            ],
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Touchable
                                                          onPress={() => {
                                                            const handler =
                                                              async () => {
                                                                try {
                                                                  (
                                                                    await baseXanoMajVuesPublicationPUT.mutateAsync(
                                                                      {
                                                                        id_actu:
                                                                          listeLaptopOrLargerData?.id,
                                                                      }
                                                                    )
                                                                  )?.json;
                                                                  navigation.navigate(
                                                                    'ActualiteScreen',
                                                                    {
                                                                      id_actu_airtable:
                                                                        listeLaptopOrLargerData?.id,
                                                                    }
                                                                  );
                                                                } catch (err) {
                                                                  console.error(
                                                                    err
                                                                  );
                                                                }
                                                              };
                                                            handler();
                                                          }}
                                                        >
                                                          {/* Cadre image */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                height: 200,
                                                                width: '100%',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Image
                                                              resizeMode={
                                                                'cover'
                                                              }
                                                              source={imageSource(
                                                                `${listeLaptopOrLargerData?.couv_actu?.url}`
                                                              )}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            />
                                                          </View>
                                                        </Touchable>
                                                        {/* Annotations */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                              justifyContent:
                                                                'space-between',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Circle Image Frame */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'center',
                                                                flexDirection:
                                                                  'row',
                                                                gap: 10,
                                                                maxWidth: '50%',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <CircleImage
                                                              {...GlobalStyles.CircleImageStyles(
                                                                theme
                                                              )[
                                                                'circle_image_card_ressource'
                                                              ].props}
                                                              size={38}
                                                              source={imageSource(
                                                                `${listeLaptopOrLargerData?.image_auteur?.url}`
                                                              )}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.CircleImageStyles(
                                                                  theme
                                                                )[
                                                                  'circle_image_card_ressource'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            />
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  maxWidth: {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      '80%',
                                                                  },
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Auteur */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'auteur_affiche'
                                                                ].props}
                                                                ellipsizeMode={
                                                                  'tail'
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'auteur_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  listeLaptopOrLargerData?.Auteur
                                                                }
                                                              </Text>
                                                              {/* projet */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'sous_titre_carte_affiche'
                                                                ].props}
                                                                ellipsizeMode={
                                                                  'tail'
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'sous_titre_carte_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {'pour '}
                                                                {
                                                                  listeLaptopOrLargerData
                                                                    ?.projets
                                                                    ?.Name
                                                                }
                                                              </Text>
                                                            </View>
                                                          </View>
                                                          {/* ligne likes */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexDirection:
                                                                  'row',
                                                                flexGrow: 0,
                                                                flexShrink: 0,
                                                                gap: 10,
                                                                justifyContent:
                                                                  'space-evenly',
                                                                width: [
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.Mobile,
                                                                    value:
                                                                      '35%',
                                                                  },
                                                                  {
                                                                    minWidth:
                                                                      Breakpoints.Laptop,
                                                                    value:
                                                                      '30%',
                                                                  },
                                                                ],
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {/* Action 1 Frame */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              <Checkbox
                                                                onPress={newCheckboxValue => {
                                                                  const handler =
                                                                    async () => {
                                                                      try {
                                                                        (
                                                                          await baseXanoMajLikesPUT.mutateAsync(
                                                                            {
                                                                              actu_id:
                                                                                listeLaptopOrLargerData?.id,
                                                                            }
                                                                          )
                                                                        )?.json;
                                                                        await refetchContenuClient();
                                                                      } catch (err) {
                                                                        console.error(
                                                                          err
                                                                        );
                                                                      }
                                                                    };
                                                                  handler();
                                                                }}
                                                                checkedIcon={
                                                                  'AntDesign/heart'
                                                                }
                                                                color={
                                                                  palettes.App[
                                                                    'Flolend 4'
                                                                  ]
                                                                }
                                                                defaultValue={
                                                                  listeLaptopOrLargerData?.client_likeur
                                                                }
                                                                size={
                                                                  (!(
                                                                    dimensions.width >=
                                                                    Breakpoints.Laptop
                                                                  )
                                                                    ? 18
                                                                    : !(
                                                                        dimensions.width >=
                                                                        Breakpoints.Desktop
                                                                      )
                                                                    ? 18
                                                                    : dimensions.width >=
                                                                      Breakpoints.BigScreen
                                                                    ? 30
                                                                    : 25) ?? 18
                                                                }
                                                                uncheckedColor={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                uncheckedIcon={
                                                                  'AntDesign/hearto'
                                                                }
                                                              />
                                                              {/* Likes */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'likes_card_affiche'
                                                                ].props}
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'likes_card_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {listeLaptopOrLargerData?.Likes >=
                                                                1000
                                                                  ? `${
                                                                      listeLaptopOrLargerData?.Likes /
                                                                      1000
                                                                    }K`
                                                                  : listeLaptopOrLargerData?.Likes}
                                                              </Text>
                                                            </View>
                                                            {/* Action 3 Frame */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'row',
                                                                  paddingLeft: 5,
                                                                  paddingRight: 5,
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* checkbox */}
                                                              <Checkbox
                                                                checkedIcon={
                                                                  'AntDesign/eyeo'
                                                                }
                                                                color={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                size={
                                                                  (!(
                                                                    dimensions.width >=
                                                                    Breakpoints.Laptop
                                                                  )
                                                                    ? 18
                                                                    : !(
                                                                        dimensions.width >=
                                                                        Breakpoints.Desktop
                                                                      )
                                                                    ? 18
                                                                    : dimensions.width >=
                                                                      Breakpoints.BigScreen
                                                                    ? 30
                                                                    : 25) ?? 18
                                                                }
                                                                uncheckedColor={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                uncheckedIcon={
                                                                  'AntDesign/eyeo'
                                                                }
                                                              />
                                                              {/* Vues */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'likes_card_affiche'
                                                                ].props}
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'likes_card_affiche'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {listeLaptopOrLargerData?.Vues >=
                                                                1000
                                                                  ? `${
                                                                      listeLaptopOrLargerData?.Vues /
                                                                      1000
                                                                    }K`
                                                                  : listeLaptopOrLargerData?.Vues}
                                                              </Text>
                                                            </View>
                                                          </View>
                                                        </View>
                                                        {/* Ligne titre */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            { width: '100%' },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Top Row Frame */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexGrow: 0,
                                                                flexShrink: 0,
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {/* Titre */}
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              {...GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'titre_carte_affiche'
                                                              ].props}
                                                              numberOfLines={2}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'titre_carte_affiche'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                listeLaptopOrLargerData?.Header_contenu
                                                              }
                                                            </Text>
                                                          </View>
                                                          {/* Second Row Frame */}
                                                          <View>
                                                            {/* Titre2 */}
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              {...GlobalStyles.TextStyles(
                                                                theme
                                                              )['corps_contenu']
                                                                .props}
                                                              ellipsizeMode={
                                                                'tail'
                                                              }
                                                              numberOfLines={3}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'corps_contenu'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                listeLaptopOrLargerData?.Header2
                                                              }
                                                            </Text>
                                                          </View>
                                                        </View>
                                                      </View>
                                                    </>
                                                  );
                                                }}
                                                horizontal={false}
                                                numColumns={1}
                                                scrollEnabled={false}
                                                showsHorizontalScrollIndicator={
                                                  false
                                                }
                                                showsVerticalScrollIndicator={
                                                  false
                                                }
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignSelf: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'center',
                                                    },
                                                    flexDirection: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'row',
                                                    },
                                                    justifyContent: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 'space-between',
                                                    },
                                                    width: {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: '100%',
                                                    },
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            )}
                                          </>
                                        </View>
                                      )}
                                    </>
                                    {/* A l'affiche sans invest */}
                                    <>
                                      {fetchContenuData?.actus_affiche ? null : (
                                        <View>
                                          {/* Bandeau titre 2 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                marginBottom: 10,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['titre1_generique'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'titre1_generique'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {"A l'affiche"}
                                            </Text>
                                          </View>
                                          {/* Encarts */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'row',
                                                },
                                                justifyContent: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'space-between',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Record Frame 2 */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  gap: 10,
                                                  width: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '100%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 350,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Desktop,
                                                      value: 420,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 500,
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Cadre image */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    height: 200,
                                                    width: '100%',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  source={imageSource(
                                                    Images['Image4']
                                                  )}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: '100%',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                              {/* Annotations */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    justifyContent:
                                                      'space-between',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Circle Image Frame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                      gap: 10,
                                                      maxWidth: '50%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <CircleImage
                                                    {...GlobalStyles.CircleImageStyles(
                                                      theme
                                                    )[
                                                      'circle_image_card_ressource'
                                                    ].props}
                                                    size={38}
                                                    source={Images.Avatar2}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.CircleImageStyles(
                                                        theme
                                                      )[
                                                        'circle_image_card_ressource'
                                                      ].style,
                                                      dimensions.width
                                                    )}
                                                  />
                                                  {/* Auteur_projet */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'sous_titre_carte_affiche'
                                                    ].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'sous_titre_carte_affiche'
                                                      ].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      'Auteur de la publication pour le projet'
                                                    }
                                                  </Text>
                                                </View>
                                                {/* ligne likes */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexDirection: 'row',
                                                      gap: 10,
                                                      justifyContent:
                                                        'space-between',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Action 1 Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        gap: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Checkbox
                                                      checkedIcon={
                                                        'AntDesign/hearto'
                                                      }
                                                      color={
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ]
                                                      }
                                                      size={
                                                        (!(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                          ? 18
                                                          : dimensions.width >=
                                                            Breakpoints.BigScreen
                                                          ? 30
                                                          : 25) ?? 18
                                                      }
                                                      uncheckedColor={
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ]
                                                      }
                                                      uncheckedIcon={
                                                        'AntDesign/hearto'
                                                      }
                                                    />
                                                    {/* Likes */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['likes_card_affiche']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['likes_card_affiche']
                                                          .style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'105'}
                                                    </Text>
                                                  </View>
                                                  {/* Action 3 Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        gap: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* checkbox */}
                                                    <Checkbox
                                                      checkedIcon={
                                                        'AntDesign/eyeo'
                                                      }
                                                      color={
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ]
                                                      }
                                                      size={
                                                        (!(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                          ? 18
                                                          : dimensions.width >=
                                                            Breakpoints.BigScreen
                                                          ? 30
                                                          : 25) ?? 18
                                                      }
                                                      uncheckedColor={
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ]
                                                      }
                                                      uncheckedIcon={
                                                        'AntDesign/eyeo'
                                                      }
                                                    />
                                                    {/* Vues */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['likes_card_affiche']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['likes_card_affiche']
                                                          .style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'203'}
                                                    </Text>
                                                  </View>
                                                  {/* Flex Frame for Touchable */}
                                                  <>
                                                    {dimensions.width >=
                                                    Breakpoints.Laptop ? null : (
                                                      <View>
                                                        {/* Flex Frame for Icons */}
                                                        <View>
                                                          {/* icone_partage */}
                                                          <Icon
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            name={
                                                              'Ionicons/share-outline'
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                          />
                                                        </View>
                                                      </View>
                                                    )}
                                                  </>
                                                </View>
                                              </View>
                                              {/* Ligne titre */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  { width: '100%' },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Top Row Frame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      flexGrow: 0,
                                                      flexShrink: 0,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Titre */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['titre_carte_affiche']
                                                      .props}
                                                    numberOfLines={2}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['titre_carte_affiche']
                                                        .style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {"Une actu à l'affiche"}
                                                  </Text>
                                                </View>
                                                {/* Second Row Frame */}
                                                <View>
                                                  {/* Titre2 */}
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    {...GlobalStyles.TextStyles(
                                                      theme
                                                    )['explications'].props}
                                                    ellipsizeMode={'tail'}
                                                    numberOfLines={3}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['explications'].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {
                                                      'Vous retrouverez ici toutes les actus des projets que vous financez : restez acteur de vos investissements !'
                                                    }
                                                  </Text>
                                                </View>
                                              </View>
                                            </View>
                                            {/* Record Frame 3 */}
                                            <>
                                              {!(
                                                dimensions.width >=
                                                Breakpoints.Laptop
                                              ) ? null : (
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      gap: 10,
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Mobile,
                                                          value: '100%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 350,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 420,
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.BigScreen,
                                                          value: 500,
                                                        },
                                                      ],
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Cadre image */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        height: 200,
                                                        width: '100%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Image
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        Images['Image2']
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  </View>
                                                  {/* Annotations */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Circle Image Frame */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                          gap: 10,
                                                          maxWidth: '50%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <CircleImage
                                                        {...GlobalStyles.CircleImageStyles(
                                                          theme
                                                        )[
                                                          'circle_image_card_ressource'
                                                        ].props}
                                                        size={38}
                                                        source={Images.Profile}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.CircleImageStyles(
                                                            theme
                                                          )[
                                                            'circle_image_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      />
                                                      {/* Auteur_projet */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'sous_titre_carte_affiche'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'sous_titre_carte_affiche'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          'Auteur de la publication pour le projet'
                                                        }
                                                      </Text>
                                                    </View>
                                                    {/* ligne likes */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                          flexGrow: 0,
                                                          flexShrink: 0,
                                                          gap: 10,
                                                          justifyContent:
                                                            'space-between',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Action 1 Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Checkbox
                                                          checkedIcon={
                                                            'AntDesign/heart'
                                                          }
                                                          color={
                                                            palettes.App[
                                                              'Flolend 4'
                                                            ]
                                                          }
                                                          size={
                                                            (!(
                                                              dimensions.width >=
                                                              Breakpoints.Desktop
                                                            )
                                                              ? 18
                                                              : dimensions.width >=
                                                                Breakpoints.BigScreen
                                                              ? 30
                                                              : 25) ?? 18
                                                          }
                                                          uncheckedColor={
                                                            palettes.App[
                                                              'Flolend 2'
                                                            ]
                                                          }
                                                          uncheckedIcon={
                                                            'AntDesign/hearto'
                                                          }
                                                        />
                                                        {/* Likes */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'likes_card_affiche'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'likes_card_affiche'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'280'}
                                                        </Text>
                                                      </View>
                                                      {/* Action 3 Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            paddingLeft: 5,
                                                            paddingRight: 5,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* checkbox */}
                                                        <Checkbox
                                                          checkedIcon={
                                                            'AntDesign/eyeo'
                                                          }
                                                          color={
                                                            palettes.App[
                                                              'Flolend 4'
                                                            ]
                                                          }
                                                          size={
                                                            (!(
                                                              dimensions.width >=
                                                              Breakpoints.Desktop
                                                            )
                                                              ? 18
                                                              : dimensions.width >=
                                                                Breakpoints.BigScreen
                                                              ? 30
                                                              : 25) ?? 18
                                                          }
                                                          uncheckedColor={
                                                            palettes.App[
                                                              'Flolend 2'
                                                            ]
                                                          }
                                                          uncheckedIcon={
                                                            'AntDesign/eyeo'
                                                          }
                                                        />
                                                        {/* Vues */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'likes_card_affiche'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'likes_card_affiche'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'364'}
                                                        </Text>
                                                      </View>
                                                      {/* Flex Frame for Touchable */}
                                                      <>
                                                        {dimensions.width >=
                                                        Breakpoints.Laptop ? null : (
                                                          <View>
                                                            {/* Flex Frame for Icons */}
                                                            <View>
                                                              {/* icone_partage */}
                                                              <Icon
                                                                color={
                                                                  palettes.App[
                                                                    'Flolend 2'
                                                                  ]
                                                                }
                                                                name={
                                                                  'Ionicons/share-outline'
                                                                }
                                                                size={
                                                                  (!(
                                                                    dimensions.width >=
                                                                    Breakpoints.Desktop
                                                                  )
                                                                    ? 18
                                                                    : dimensions.width >=
                                                                      Breakpoints.BigScreen
                                                                    ? 30
                                                                    : 25) ?? 18
                                                                }
                                                              />
                                                            </View>
                                                          </View>
                                                        )}
                                                      </>
                                                    </View>
                                                  </View>
                                                  {/* Ligne titre */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { width: '100%' },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Top Row Frame */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexGrow: 0,
                                                          flexShrink: 0,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Titre */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['titre_carte_affiche']
                                                          .props}
                                                        numberOfLines={2}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'titre_carte_affiche'
                                                            ].style,
                                                            {
                                                              fontSize: {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 25,
                                                              },
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {"Une actu à l'affiche"}
                                                      </Text>
                                                    </View>
                                                    {/* Second Row Frame */}
                                                    <View>
                                                      {/* Titre2 */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['explications'].props}
                                                        ellipsizeMode={'tail'}
                                                        numberOfLines={3}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['explications']
                                                            .style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          'Vous retrouverez ici toutes les actus des projets que vous financez : restez acteur de vos investissements !'
                                                        }
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              )}
                                            </>
                                          </View>
                                        </View>
                                      )}
                                    </>
                                  </View>
                                  {/* Publications et ressources */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: {
                                          minWidth: Breakpoints.Tablet,
                                          value: 'row',
                                        },
                                        justifyContent: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'space-between',
                                        },
                                        marginLeft: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '10%',
                                          },
                                        ],
                                        marginRight: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: '10%',
                                          },
                                        ],
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 30,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 40,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 50,
                                          },
                                        ],
                                        paddingLeft: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: '15%',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: '10%',
                                          },
                                        ],
                                        paddingRight: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: '10%',
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: '15%',
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Publications */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginLeft: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          marginRight: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: '5%',
                                            },
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '0%',
                                            },
                                          ],
                                          marginTop: 15,
                                          paddingRight: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '1%',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: '0%',
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '50%',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 350,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 420,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 500,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Publications avec invest */}
                                      <>
                                        {!fetchContenuData?.actus_hors_affiche ? null : (
                                          <View>
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignContent: 'flex-start',
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                  marginBottom: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 10,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: '1%',
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['titre1_generique'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre1_generique'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Publications'}
                                              </Text>
                                              <Link
                                                accessible={true}
                                                onPress={() => {
                                                  try {
                                                    navigation.navigate(
                                                      'ActualitesClientScreen'
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                selectable={false}
                                                {...GlobalStyles.LinkStyles(
                                                  theme
                                                )['bouton_voir_tout'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinkStyles(
                                                    theme
                                                  )['bouton_voir_tout'].style,
                                                  dimensions.width
                                                )}
                                                title={'Voir tout'}
                                              />
                                            </View>
                                            <SimpleStyleFlatList
                                              data={
                                                fetchContenuData?.actus_hors_affiche
                                              }
                                              horizontal={false}
                                              inverted={false}
                                              keyExtractor={(listData, index) =>
                                                listData?.id ??
                                                listData?.uuid ??
                                                index?.toString() ??
                                                JSON.stringify(listData)
                                              }
                                              keyboardShouldPersistTaps={
                                                'never'
                                              }
                                              listKey={'Jkmvv4Pf'}
                                              nestedScrollEnabled={false}
                                              numColumns={1}
                                              onEndReachedThreshold={0.5}
                                              renderItem={({ item, index }) => {
                                                const listData = item;
                                                return (
                                                  <Touchable
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            (
                                                              await baseXanoMajVuesPublicationPUT.mutateAsync(
                                                                {
                                                                  id_actu:
                                                                    listData?.id,
                                                                }
                                                              )
                                                            )?.json;
                                                            navigation.navigate(
                                                              'ActualiteScreen',
                                                              {
                                                                id_actu_airtable:
                                                                  listData?.id,
                                                              }
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                  >
                                                    {/* Record Frame */}
                                                    <View
                                                      {...GlobalStyles.ViewStyles(
                                                        theme
                                                      )['encart_publications']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.ViewStyles(
                                                          theme
                                                        )['encart_publications']
                                                          .style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Left Side Frame */}
                                                      <View
                                                        {...GlobalStyles.ViewStyles(
                                                          theme
                                                        )[
                                                          'left_side_publicataion'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'left_side_publicataion'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Top Row Frame */}
                                                        <View>
                                                          {/* Rubik Headline Style 18/24 Bold */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'titre_encart_publications'
                                                            ].props}
                                                            numberOfLines={2}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'titre_encart_publications'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              listData?.Header_contenu
                                                            }
                                                          </Text>
                                                        </View>
                                                        {/* Second Row Frame */}
                                                        <View>
                                                          {/* Rubik Text Style 12/18 Regular */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'corps_encart_publications'
                                                            ].props}
                                                            ellipsizeMode={
                                                              'tail'
                                                            }
                                                            numberOfLines={2}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'corps_encart_publications'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listData?.Header2}
                                                          </Text>
                                                        </View>
                                                        {/* Third Row Frame */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'baseline',
                                                              flexDirection:
                                                                'row',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Data Point */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'type_encart_publications'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'type_encart_publications'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listData?.Media}
                                                          </Text>
                                                          {/* Data Poiint */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'date_encart_publications'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'date_encart_publications'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'Le '}
                                                            {ConvertionDate(
                                                              listData?.date_publication
                                                            )}
                                                          </Text>
                                                        </View>
                                                      </View>
                                                      {/* Right Side Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            justifyContent:
                                                              'center',
                                                            width: '30%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'image_liste_publications'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'image_liste_publications'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Image
                                                            resizeMode={'cover'}
                                                            source={imageSource(
                                                              `${listData?.couv_actu?.url}`
                                                            )}
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                height: '100%',
                                                                width: '100%',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </View>
                                                      </View>
                                                    </View>
                                                  </Touchable>
                                                );
                                              }}
                                              showsHorizontalScrollIndicator={
                                                true
                                              }
                                              showsVerticalScrollIndicator={
                                                true
                                              }
                                              scrollEnabled={false}
                                            />
                                          </View>
                                        )}
                                      </>
                                      {/* Publications sans invest */}
                                      <>
                                        {fetchContenuData?.actus_hors_affiche ? null : (
                                          <View>
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['titre1_generique'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'titre1_generique'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Publications'}
                                            </Text>
                                            {/* Publication 1 */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { marginTop: 10 },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Record Frame */}
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['encart_publications'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )['encart_publications']
                                                      .style,
                                                    { flexShrink: 0 }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Left Side Frame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      justifyContent:
                                                        'space-between',
                                                      paddingBottom: '3%',
                                                      paddingLeft: '3%',
                                                      paddingRight: '3%',
                                                      paddingTop: '3%',
                                                      width: '70%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Top Row Frame */}
                                                  <View>
                                                    {/* Rubik Headline Style 18/24 Bold */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'titre_encart_publications'
                                                      ].props}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'titre_encart_publications'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Une actu hors affiche'}
                                                    </Text>
                                                  </View>
                                                  {/* Second Row Frame */}
                                                  <View>
                                                    {/* Rubik Text Style 12/18 Regular */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['explications'].props}
                                                      ellipsizeMode={'tail'}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['explications'].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        'Les publications hors affiche sont affichées ici !'
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Third Row Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'baseline',
                                                        flexDirection: 'row',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Data Point */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'type_encart_publications'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'type_encart_publications'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Article'}
                                                    </Text>
                                                    {/* Data Poiint */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'date_encart_publications'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'date_encart_publications'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Il y a 2 heures'}
                                                    </Text>
                                                  </View>
                                                </View>
                                                {/* Right Side Frame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      width: '30%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Flex for Image */}
                                                  <View
                                                    {...GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'image_liste_publications'
                                                    ].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.ViewStyles(
                                                        theme
                                                      )[
                                                        'image_liste_publications'
                                                      ].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Image
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        Images['Image3']
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                            {/* Publication 2 */}
                                            <View>
                                              {/* Record Frame */}
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['encart_publications'].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )['encart_publications']
                                                      .style,
                                                    { flexShrink: 0 }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Left Side Frame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      justifyContent:
                                                        'space-between',
                                                      paddingBottom: '3%',
                                                      paddingLeft: '3%',
                                                      paddingRight: '3%',
                                                      paddingTop: '3%',
                                                      width: '70%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Top Row Frame */}
                                                  <View>
                                                    {/* Rubik Headline Style 18/24 Bold */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'titre_encart_publications'
                                                      ].props}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'titre_encart_publications'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Une actu hors affiche'}
                                                    </Text>
                                                  </View>
                                                  {/* Second Row Frame */}
                                                  <View>
                                                    {/* Rubik Text Style 12/18 Regular */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['explications'].props}
                                                      ellipsizeMode={'tail'}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['explications'].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        'Les publications hors affiche sont affichées ici !'
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Third Row Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'baseline',
                                                        flexDirection: 'row',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Data Point */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'type_encart_publications'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'type_encart_publications'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Live Twitch'}
                                                    </Text>
                                                    {/* Data Poiint */}
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'date_encart_publications'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'date_encart_publications'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Il y a 3 jours'}
                                                    </Text>
                                                  </View>
                                                </View>
                                                {/* Right Side Frame */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      width: '30%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Flex for Image */}
                                                  <View
                                                    {...GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'image_liste_publications'
                                                    ].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.ViewStyles(
                                                        theme
                                                      )[
                                                        'image_liste_publications'
                                                      ].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Image
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        Images[
                                                          'WindEnergy73421771280'
                                                        ]
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    />
                                                  </View>
                                                </View>
                                              </View>
                                            </View>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                    {/* Ressources */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          marginTop: 15,
                                          paddingBottom: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 30,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0,
                                            },
                                          ],
                                          paddingLeft: {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                          width: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '50%',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 350,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 420,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 500,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Ressources avec invest */}
                                      <>
                                        {!fetchContenuData?.ressources_client ? null : (
                                          <View>
                                            {/* View 2 */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                  marginBottom: 10,
                                                  marginLeft: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '5%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: '0%',
                                                    },
                                                  ],
                                                  marginRight: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '5%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: '0%',
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Text 2 */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['titre1_generique'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre1_generique'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Ressources'}
                                              </Text>
                                              <Link
                                                accessible={true}
                                                onPress={() => {
                                                  try {
                                                    navigation.navigate(
                                                      'RessourcesClientScreen'
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                selectable={false}
                                                {...GlobalStyles.LinkStyles(
                                                  theme
                                                )['bouton_voir_tout'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinkStyles(
                                                    theme
                                                  )['bouton_voir_tout'].style,
                                                  dimensions.width
                                                )}
                                                title={'Voir tout'}
                                              />
                                            </View>
                                            {/* Courses */}
                                            <View>
                                              {/* Course List */}
                                              <SimpleStyleFlatList
                                                data={
                                                  fetchContenuData?.ressources_client
                                                }
                                                inverted={false}
                                                keyExtractor={(
                                                  courseListData,
                                                  index
                                                ) =>
                                                  courseListData?.id ??
                                                  courseListData?.uuid ??
                                                  index?.toString() ??
                                                  JSON.stringify(courseListData)
                                                }
                                                keyboardShouldPersistTaps={
                                                  'never'
                                                }
                                                listKey={'lCKXAvjt'}
                                                nestedScrollEnabled={false}
                                                numColumns={1}
                                                onEndReachedThreshold={0.5}
                                                renderItem={({
                                                  item,
                                                  index,
                                                }) => {
                                                  const courseListData = item;
                                                  return (
                                                    <Touchable
                                                      onPress={() => {
                                                        const handler =
                                                          async () => {
                                                            try {
                                                              (
                                                                await baseXanoMajVuesRessourcePUT.mutateAsync(
                                                                  {
                                                                    id_ressource:
                                                                      courseListData?.id,
                                                                  }
                                                                )
                                                              )?.json;
                                                              await WebBrowser.openBrowserAsync(
                                                                `${courseListData?.lien_ressource}`
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          };
                                                        handler();
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        { marginBottom: 2 },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Surface
                                                        {...GlobalStyles.SurfaceStyles(
                                                          theme
                                                        )['Surface'].props}
                                                        elevation={3}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.SurfaceStyles(
                                                              theme
                                                            )['Surface'].style,
                                                            {
                                                              backgroundColor:
                                                                'rgba(0, 0, 0, 0)',
                                                              borderRadius: 24,
                                                              height: '100%',
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Course Card */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'course_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'course_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Image Frame */}
                                                          <View
                                                            {...GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'image_frame_carte_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.ViewStyles(
                                                                theme
                                                              )[
                                                                'image_frame_carte_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <ImageBackground
                                                              resizeMode={
                                                                'cover'
                                                              }
                                                              source={imageSource(
                                                                `${courseListData?.image_couv?.url}`
                                                              )}
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  borderRadius: 0,
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Overlay */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      'center',
                                                                    flexDirection:
                                                                      'row',
                                                                    height: {
                                                                      minWidth:
                                                                        Breakpoints.Laptop,
                                                                      value:
                                                                        '40%',
                                                                    },
                                                                    justifyContent:
                                                                      'space-between',
                                                                    paddingBottom:
                                                                      [
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Mobile,
                                                                          value:
                                                                            '5%',
                                                                        },
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Laptop,
                                                                          value:
                                                                            '0%',
                                                                        },
                                                                      ],
                                                                    paddingLeft:
                                                                      '5%',
                                                                    paddingRight:
                                                                      '5%',
                                                                    paddingTop:
                                                                      [
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Mobile,
                                                                          value:
                                                                            '5%',
                                                                        },
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Laptop,
                                                                          value:
                                                                            '0%',
                                                                        },
                                                                      ],
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* rating */}
                                                                <View
                                                                  {...GlobalStyles.ViewStyles(
                                                                    theme
                                                                  )[
                                                                    'rating_card_ressource'
                                                                  ].props}
                                                                  style={StyleSheet.applyWidth(
                                                                    GlobalStyles.ViewStyles(
                                                                      theme
                                                                    )[
                                                                      'rating_card_ressource'
                                                                    ].style,
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  <Checkbox
                                                                    onPress={newCheckboxValue => {
                                                                      const handler =
                                                                        async () => {
                                                                          const checkboxValue =
                                                                            newCheckboxValue;
                                                                          try {
                                                                            (
                                                                              await baseXanoMajLikesRessourcesPUT.mutateAsync(
                                                                                {
                                                                                  id_ressource:
                                                                                    courseListData?.id,
                                                                                }
                                                                              )
                                                                            )
                                                                              ?.json;
                                                                            await refetchContenuClient();
                                                                          } catch (err) {
                                                                            console.error(
                                                                              err
                                                                            );
                                                                          }
                                                                        };
                                                                      handler();
                                                                    }}
                                                                    checkedIcon={
                                                                      'AntDesign/heart'
                                                                    }
                                                                    color={
                                                                      palettes
                                                                        .App[
                                                                        'Flolend 4'
                                                                      ]
                                                                    }
                                                                    defaultValue={
                                                                      courseListData?.client_likeur_ressource
                                                                    }
                                                                    size={
                                                                      (!(
                                                                        dimensions.width >=
                                                                        Breakpoints.Desktop
                                                                      )
                                                                        ? 18
                                                                        : dimensions.width >=
                                                                          Breakpoints.BigScreen
                                                                        ? 30
                                                                        : 25) ??
                                                                      16
                                                                    }
                                                                    uncheckedColor={
                                                                      palettes
                                                                        .App[
                                                                        'Flolend 2'
                                                                      ]
                                                                    }
                                                                    uncheckedIcon={
                                                                      'AntDesign/hearto'
                                                                    }
                                                                  />
                                                                  {/* Likes */}
                                                                  <Text
                                                                    accessible={
                                                                      true
                                                                    }
                                                                    selectable={
                                                                      false
                                                                    }
                                                                    {...GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      'item_rating_card_ressource'
                                                                    ].props}
                                                                    style={StyleSheet.applyWidth(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )[
                                                                        'item_rating_card_ressource'
                                                                      ].style,
                                                                      dimensions.width
                                                                    )}
                                                                  >
                                                                    {courseListData?.nb_likes >=
                                                                    1000
                                                                      ? `${
                                                                          courseListData?.nb_likes /
                                                                          1000
                                                                        }K`
                                                                      : courseListData?.nb_likes}
                                                                  </Text>

                                                                  <Text
                                                                    accessible={
                                                                      true
                                                                    }
                                                                    selectable={
                                                                      false
                                                                    }
                                                                    {...GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      'item_rating_card_ressource'
                                                                    ].props}
                                                                    style={StyleSheet.applyWidth(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )[
                                                                        'item_rating_card_ressource'
                                                                      ].style,
                                                                      dimensions.width
                                                                    )}
                                                                  >
                                                                    {'I'}
                                                                  </Text>
                                                                  <Icon
                                                                    color={
                                                                      palettes
                                                                        .App[
                                                                        'Flolend 2'
                                                                      ]
                                                                    }
                                                                    name={
                                                                      'AntDesign/eyeo'
                                                                    }
                                                                    size={
                                                                      (!(
                                                                        dimensions.width >=
                                                                        Breakpoints.Desktop
                                                                      )
                                                                        ? 18
                                                                        : dimensions.width >=
                                                                          Breakpoints.BigScreen
                                                                        ? 30
                                                                        : 25) ??
                                                                      18
                                                                    }
                                                                  />
                                                                  {/* Vues */}
                                                                  <Text
                                                                    accessible={
                                                                      true
                                                                    }
                                                                    selectable={
                                                                      false
                                                                    }
                                                                    {...GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      'item_rating_card_ressource'
                                                                    ].props}
                                                                    style={StyleSheet.applyWidth(
                                                                      GlobalStyles.TextStyles(
                                                                        theme
                                                                      )[
                                                                        'item_rating_card_ressource'
                                                                      ].style,
                                                                      dimensions.width
                                                                    )}
                                                                  >
                                                                    {courseListData?.nb_vues >=
                                                                    1000
                                                                      ? `${
                                                                          courseListData?.nb_vues /
                                                                          1000
                                                                        }K`
                                                                      : courseListData?.nb_vues}
                                                                  </Text>
                                                                </View>
                                                                {/* Bookmark */}
                                                                <View
                                                                  {...GlobalStyles.ViewStyles(
                                                                    theme
                                                                  )[
                                                                    'bookmark_card_ressource'
                                                                  ].props}
                                                                  style={StyleSheet.applyWidth(
                                                                    GlobalStyles.ViewStyles(
                                                                      theme
                                                                    )[
                                                                      'bookmark_card_ressource'
                                                                    ].style,
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  <Checkbox
                                                                    onPress={newCheckboxValue => {
                                                                      const handler =
                                                                        async () => {
                                                                          const checkboxValue =
                                                                            newCheckboxValue;
                                                                          try {
                                                                            (
                                                                              await baseXanoMajBookmarkedPUT.mutateAsync(
                                                                                {
                                                                                  id_ressource:
                                                                                    courseListData?.id,
                                                                                }
                                                                              )
                                                                            )
                                                                              ?.json;
                                                                            await refetchContenuClient();
                                                                          } catch (err) {
                                                                            console.error(
                                                                              err
                                                                            );
                                                                          }
                                                                        };
                                                                      handler();
                                                                    }}
                                                                    checkedIcon={
                                                                      'Ionicons/bookmark'
                                                                    }
                                                                    color={
                                                                      palettes
                                                                        .App[
                                                                        'Flolend 4'
                                                                      ]
                                                                    }
                                                                    defaultValue={
                                                                      courseListData?.bookmarked
                                                                    }
                                                                    size={
                                                                      (!(
                                                                        dimensions.width >=
                                                                        Breakpoints.Desktop
                                                                      )
                                                                        ? 18
                                                                        : dimensions.width >=
                                                                          Breakpoints.BigScreen
                                                                        ? 30
                                                                        : 25) ??
                                                                      24
                                                                    }
                                                                    uncheckedColor={
                                                                      palettes
                                                                        .App[
                                                                        'Flolend 2'
                                                                      ]
                                                                    }
                                                                    uncheckedIcon={
                                                                      'Ionicons/bookmark-outline'
                                                                    }
                                                                  />
                                                                </View>
                                                              </View>
                                                            </ImageBackground>
                                                          </View>
                                                          {/* Name */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                flexDirection:
                                                                  'row',
                                                                justifyContent:
                                                                  'space-between',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              {...GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'nom_card_ressource'
                                                              ].props}
                                                              ellipsizeMode={
                                                                'tail'
                                                              }
                                                              numberOfLines={2}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'nom_card_ressource'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                courseListData?.titre_1
                                                              }
                                                            </Text>
                                                          </View>
                                                          {/* Description */}
                                                          <View>
                                                            <Text
                                                              accessible={true}
                                                              selectable={false}
                                                              {...GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'corps_card_ressource'
                                                              ].props}
                                                              numberOfLines={3}
                                                              style={StyleSheet.applyWidth(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'corps_card_ressource'
                                                                ].style,
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {
                                                                courseListData?.Titre_2
                                                              }
                                                            </Text>
                                                          </View>
                                                          {/* Footer */}
                                                          <View
                                                            style={StyleSheet.applyWidth(
                                                              {
                                                                alignItems:
                                                                  'center',
                                                                flexDirection:
                                                                  'row',
                                                                justifyContent:
                                                                  'space-between',
                                                              },
                                                              dimensions.width
                                                            )}
                                                          >
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  flexDirection:
                                                                    'row',
                                                                  gap: 10,
                                                                  maxWidth:
                                                                    '70%',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Avatar */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    alignItems:
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Laptop,
                                                                        value:
                                                                          'center',
                                                                      },
                                                                    justifyContent:
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Laptop,
                                                                        value:
                                                                          'center',
                                                                      },
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                <View
                                                                  style={StyleSheet.applyWidth(
                                                                    {
                                                                      borderRadius: 50,
                                                                      height: [
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Mobile,
                                                                          value: 40,
                                                                        },
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Desktop,
                                                                          value: 45,
                                                                        },
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.BigScreen,
                                                                          value: 50,
                                                                        },
                                                                      ],
                                                                      overflow:
                                                                        'hidden',
                                                                      width: [
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Mobile,
                                                                          value: 40,
                                                                        },
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.Desktop,
                                                                          value: 45,
                                                                        },
                                                                        {
                                                                          minWidth:
                                                                            Breakpoints.BigScreen,
                                                                          value: 50,
                                                                        },
                                                                      ],
                                                                    },
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  <Image
                                                                    resizeMode={
                                                                      'cover'
                                                                    }
                                                                    {...GlobalStyles.ImageStyles(
                                                                      theme
                                                                    )['Image']
                                                                      .props}
                                                                    source={imageSource(
                                                                      `${courseListData?.photo_auteur?.url}`
                                                                    )}
                                                                    style={StyleSheet.applyWidth(
                                                                      StyleSheet.compose(
                                                                        GlobalStyles.ImageStyles(
                                                                          theme
                                                                        )[
                                                                          'Image'
                                                                        ].style,
                                                                        {
                                                                          height:
                                                                            '100%',
                                                                          width:
                                                                            '100%',
                                                                        }
                                                                      ),
                                                                      dimensions.width
                                                                    )}
                                                                  />
                                                                </View>
                                                              </View>
                                                              {/* Instructor */}
                                                              <View
                                                                style={StyleSheet.applyWidth(
                                                                  {
                                                                    flexWrap:
                                                                      'wrap',
                                                                    marginLeft:
                                                                      {
                                                                        minWidth:
                                                                          Breakpoints.Laptop,
                                                                        value:
                                                                          '5%',
                                                                      },
                                                                    maxWidth:
                                                                      '80%',
                                                                  },
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {/* Name */}
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  selectable={
                                                                    false
                                                                  }
                                                                  {...GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'auteur_card_ressource'
                                                                  ].props}
                                                                  ellipsizeMode={
                                                                    'tail'
                                                                  }
                                                                  numberOfLines={
                                                                    1
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      'auteur_card_ressource'
                                                                    ].style,
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    courseListData?.auteur
                                                                  }
                                                                </Text>
                                                                {/* Profession */}
                                                                <Text
                                                                  accessible={
                                                                    true
                                                                  }
                                                                  selectable={
                                                                    false
                                                                  }
                                                                  {...GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'profession_card_ressource'
                                                                  ].props}
                                                                  ellipsizeMode={
                                                                    'tail'
                                                                  }
                                                                  numberOfLines={
                                                                    1
                                                                  }
                                                                  style={StyleSheet.applyWidth(
                                                                    GlobalStyles.TextStyles(
                                                                      theme
                                                                    )[
                                                                      'profession_card_ressource'
                                                                    ].style,
                                                                    dimensions.width
                                                                  )}
                                                                >
                                                                  {
                                                                    courseListData?.fonction_auteur
                                                                  }
                                                                </Text>
                                                              </View>
                                                            </View>
                                                            {/* Details */}
                                                            <View
                                                              style={StyleSheet.applyWidth(
                                                                {
                                                                  alignItems:
                                                                    'flex-end',
                                                                  flexWrap:
                                                                    'wrap',
                                                                },
                                                                dimensions.width
                                                              )}
                                                            >
                                                              {/* Duration */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'duree_card_ressource'
                                                                ].props}
                                                                ellipsizeMode={
                                                                  'tail'
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'duree_card_ressource'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  courseListData?.duree_min
                                                                }
                                                                {'m'}
                                                              </Text>
                                                              {/* Lessons */}
                                                              <Text
                                                                accessible={
                                                                  true
                                                                }
                                                                selectable={
                                                                  false
                                                                }
                                                                {...GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'type_card_ressource'
                                                                ].props}
                                                                ellipsizeMode={
                                                                  'tail'
                                                                }
                                                                numberOfLines={
                                                                  1
                                                                }
                                                                style={StyleSheet.applyWidth(
                                                                  GlobalStyles.TextStyles(
                                                                    theme
                                                                  )[
                                                                    'type_card_ressource'
                                                                  ].style,
                                                                  dimensions.width
                                                                )}
                                                              >
                                                                {
                                                                  courseListData?.type_ressource
                                                                }
                                                              </Text>
                                                            </View>
                                                          </View>
                                                        </View>
                                                      </Surface>
                                                    </Touchable>
                                                  );
                                                }}
                                                horizontal={true}
                                                scrollEnabled={true}
                                                showsHorizontalScrollIndicator={
                                                  false
                                                }
                                                showsVerticalScrollIndicator={
                                                  false
                                                }
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    gap: 20,
                                                    paddingLeft: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '5%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: '0%',
                                                      },
                                                    ],
                                                    paddingRight: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: '5%',
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: '0%',
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            </View>
                                          </View>
                                        )}
                                      </>
                                      {/* Ressources sans invest */}
                                      <>
                                        {fetchContenuData?.ressources_client ? null : (
                                          <View>
                                            {/* Header */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  marginBottom: 10,
                                                  marginLeft: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '5%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: '0%',
                                                    },
                                                  ],
                                                  marginRight: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '5%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: '0%',
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Text 2 */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['titre1_generique'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre1_generique'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {'Ressources'}
                                              </Text>

                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )['explications'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['explications'].style,
                                                  dimensions.width
                                                )}
                                              >
                                                {
                                                  "Vous retrouverez ici un jeu de ressources pour mieux comprendre les enjeux des projets dans lesquels vous montrez de l'intérêt."
                                                }
                                              </Text>
                                            </View>
                                            {/* Scroll View 2 */}
                                            <SimpleStyleScrollView
                                              keyboardShouldPersistTaps={
                                                'never'
                                              }
                                              nestedScrollEnabled={false}
                                              bounces={false}
                                              horizontal={true}
                                              scrollEnabled={true}
                                              showsHorizontalScrollIndicator={
                                                false
                                              }
                                              showsVerticalScrollIndicator={
                                                false
                                              }
                                              style={StyleSheet.applyWidth(
                                                {
                                                  gap: 20,
                                                  marginBottom: 20,
                                                  paddingLeft: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '5%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: '0%',
                                                    },
                                                  ],
                                                  paddingRight: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: '5%',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: '0%',
                                                    },
                                                  ],
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Surface
                                                {...GlobalStyles.SurfaceStyles(
                                                  theme
                                                )['Surface'].props}
                                                elevation={3}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.SurfaceStyles(
                                                      theme
                                                    )['Surface'].style,
                                                    {
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0)',
                                                      borderRadius: 24,
                                                      bottom: 2,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Course Card */}
                                                <View
                                                  {...GlobalStyles.ViewStyles(
                                                    theme
                                                  )['course_card_ressource']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )['course_card_ressource']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Image Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        borderRadius: 16,
                                                        height: '50%',
                                                        overflow: 'hidden',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <ImageBackground
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        Images['Image2']
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          borderRadius: 0,
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Overlay */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            justifyContent:
                                                              'space-between',
                                                            paddingBottom: '5%',
                                                            paddingLeft: '5%',
                                                            paddingRight: '5%',
                                                            paddingTop: '5%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* rating */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'rating_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'rating_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            checkedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 16
                                                            }
                                                            status={
                                                              checkboxValue
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                          />
                                                          {/* Likes */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'120'}
                                                          </Text>

                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'I'}
                                                          </Text>
                                                          <Icon
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            name={
                                                              'AntDesign/eyeo'
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                          />
                                                          {/* Vues */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'200'}
                                                          </Text>
                                                        </View>
                                                        {/* Bookmark */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'bookmark_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'bookmark_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            checkedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 24
                                                            }
                                                            status={
                                                              checkboxValue
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                          />
                                                        </View>
                                                      </View>
                                                    </ImageBackground>
                                                  </View>
                                                  {/* Name */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['nom_card_ressource']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['nom_card_ressource']
                                                          .style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        "Le marché de l'électricité en France"
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Description */}
                                                  <View>
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['corps_card_ressource']
                                                        .props}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'corps_card_ressource'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        "Le marché de l'électricité en France repose sur 3 grandes catégories de tarifs. On distingue en premier lieu le tarif EDF OA..."
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Footer */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                          gap: 10,
                                                          maxWidth: '70%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Avatar */}
                                                      <View>
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              borderRadius: 50,
                                                              height: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                              overflow:
                                                                'hidden',
                                                              width: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Image
                                                            resizeMode={'cover'}
                                                            {...GlobalStyles.ImageStyles(
                                                              theme
                                                            )['Image'].props}
                                                            source={imageSource(
                                                              Images[
                                                                'CharlieGreen3JmfENcL24MUnsplash'
                                                              ]
                                                            )}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .style,
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </View>
                                                      </View>
                                                      {/* Instructor */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            marginLeft: {
                                                              minWidth:
                                                                Breakpoints.BigScreen,
                                                              value: '5%',
                                                            },
                                                            maxWidth: '80%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Name */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'auteur_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'auteur_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Tom Ghenre'}
                                                        </Text>
                                                        {/* Profession */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'profession_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'profession_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            'Consultant énergie, ENR consulting'
                                                          }
                                                        </Text>
                                                      </View>
                                                    </View>
                                                    {/* Details */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems:
                                                            'flex-end',
                                                          flexWrap: 'wrap',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Duration */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'duree_card_ressource'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'duree_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'15m'}
                                                      </Text>
                                                      {/* type */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['type_card_ressource']
                                                          .props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'type_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Entretien'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </Surface>
                                              {/* Surface 2 */}
                                              <Surface
                                                {...GlobalStyles.SurfaceStyles(
                                                  theme
                                                )['Surface'].props}
                                                elevation={3}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.SurfaceStyles(
                                                      theme
                                                    )['Surface'].style,
                                                    {
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0)',
                                                      borderRadius: 24,
                                                      bottom: 2,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Course Card */}
                                                <View
                                                  {...GlobalStyles.ViewStyles(
                                                    theme
                                                  )['course_card_ressource']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )['course_card_ressource']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Image Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        borderRadius: 16,
                                                        height: '50%',
                                                        overflow: 'hidden',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <ImageBackground
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        Images[
                                                          'WindEnergy73421771280'
                                                        ]
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          borderRadius: 0,
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Overlay */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            justifyContent:
                                                              'space-between',
                                                            paddingBottom: '5%',
                                                            paddingLeft: '5%',
                                                            paddingRight: '5%',
                                                            paddingTop: '5%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* rating */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'rating_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'rating_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            checkedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 16
                                                            }
                                                            status={
                                                              checkboxValue
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                          />
                                                          {/* Likes */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'91'}
                                                          </Text>

                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'I'}
                                                          </Text>
                                                          <Icon
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            name={
                                                              'AntDesign/eyeo'
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                          />
                                                          {/* Vues */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'180'}
                                                          </Text>
                                                        </View>
                                                        {/* Bookmark */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'bookmark_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'bookmark_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            checkedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 24
                                                            }
                                                            status={
                                                              checkboxValue
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                          />
                                                        </View>
                                                      </View>
                                                    </ImageBackground>
                                                  </View>
                                                  {/* Name */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['nom_card_ressource']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['nom_card_ressource']
                                                          .style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        'Créer du courant avec du vent : comment ça marche ?'
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Description */}
                                                  <View>
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['corps_card_ressource']
                                                        .props}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'corps_card_ressource'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        "Pour faire de l'électricité avec du vent, on combine une turbine et un alternateur montés sur mât. C'est une éolienne."
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Footer */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                          width: '50%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Avatar */}
                                                      <View>
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              borderRadius: 50,
                                                              height: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                              overflow:
                                                                'hidden',
                                                              width: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Image
                                                            resizeMode={'cover'}
                                                            {...GlobalStyles.ImageStyles(
                                                              theme
                                                            )['Image'].props}
                                                            source={imageSource(
                                                              Images['Profile']
                                                            )}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .style,
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </View>
                                                      </View>
                                                      {/* Instructor */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            marginLeft: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: '10%',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: '5%',
                                                              },
                                                            ],
                                                            width: '80%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Name */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'auteur_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'auteur_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Jean Schlesser'}
                                                        </Text>
                                                        {/* Profession */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'profession_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'profession_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            'Professeur physique'
                                                          }
                                                        </Text>
                                                      </View>
                                                    </View>
                                                    {/* Details */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems:
                                                            'flex-end',
                                                          flexWrap: 'wrap',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Duration */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'duree_card_ressource'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'duree_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'30m'}
                                                      </Text>
                                                      {/* type */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['type_card_ressource']
                                                          .props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'type_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Vidéo'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </Surface>
                                              {/* Surface 3 */}
                                              <Surface
                                                {...GlobalStyles.SurfaceStyles(
                                                  theme
                                                )['Surface'].props}
                                                elevation={3}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.SurfaceStyles(
                                                      theme
                                                    )['Surface'].style,
                                                    {
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0)',
                                                      borderRadius: 24,
                                                      bottom: 2,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Course Card */}
                                                <View
                                                  {...GlobalStyles.ViewStyles(
                                                    theme
                                                  )['course_card_ressource']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )['course_card_ressource']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Image Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        borderRadius: 16,
                                                        height: '50%',
                                                        overflow: 'hidden',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <ImageBackground
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        Images['Image6']
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          borderRadius: 0,
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Overlay */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            justifyContent:
                                                              'space-between',
                                                            paddingBottom: '5%',
                                                            paddingLeft: '5%',
                                                            paddingRight: '5%',
                                                            paddingTop: '5%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* rating */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'rating_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'rating_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            checkedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 16
                                                            }
                                                            status={
                                                              checkboxValue
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                          />
                                                          {/* Likes */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'134'}
                                                          </Text>

                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'I'}
                                                          </Text>
                                                          <Icon
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            name={
                                                              'AntDesign/eyeo'
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                          />
                                                          {/* Vues */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'256'}
                                                          </Text>
                                                        </View>
                                                        {/* Bookmark */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'bookmark_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'bookmark_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            checkedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 24
                                                            }
                                                            status={
                                                              checkboxValue
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                          />
                                                        </View>
                                                      </View>
                                                    </ImageBackground>
                                                  </View>
                                                  {/* Name */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['nom_card_ressource']
                                                        .props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )['nom_card_ressource']
                                                          .style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        "Les grands enjeux d'un projet de production d'énergie."
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Description */}
                                                  <View>
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['corps_card_ressource']
                                                        .props}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'corps_card_ressource'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        "Piloter un projet de production d'énergie requiert de prendre en compte un éventail large de facteurs qui vont influer sur..."
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Footer */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                          width: '50%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Avatar */}
                                                      <View>
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              borderRadius: 50,
                                                              height: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                              overflow:
                                                                'hidden',
                                                              width: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Image
                                                            resizeMode={'cover'}
                                                            {...GlobalStyles.ImageStyles(
                                                              theme
                                                            )['Image'].props}
                                                            source={imageSource(
                                                              Images[
                                                                'JoelMottLaK153ghdigUnsplash'
                                                              ]
                                                            )}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .style,
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </View>
                                                      </View>
                                                      {/* Instructor */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            marginLeft: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: '10%',
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: '5%',
                                                              },
                                                            ],
                                                            width: '80%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Name */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'auteur_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'auteur_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'John Morel'}
                                                        </Text>
                                                        {/* Profession */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'profession_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'profession_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            'Directeur de projet, H3R'
                                                          }
                                                        </Text>
                                                      </View>
                                                    </View>
                                                    {/* Details */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems:
                                                            'flex-end',
                                                          flexWrap: 'wrap',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Duration */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'duree_card_ressource'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'duree_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'20m'}
                                                      </Text>
                                                      {/* type */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['type_card_ressource']
                                                          .props}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'type_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {'Article'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </Surface>
                                            </SimpleStyleScrollView>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </View>
                              )}
                            </>
                          </>
                        );
                      }}
                    </BaseXanoApi.FetchContenuClientGET>
                  </View>
                </View>
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <FooterDesktopBlock />
                  )}
                </>
              </SimpleStyleScrollView>
            </>
          );
        }}
      </BaseXanoApi.FetchAuthGET>
      {/* selecteur_tab_desktop */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'flex-end' },
                bottom: { minWidth: Breakpoints.Laptop, value: '3%' },
                paddingRight: { minWidth: Breakpoints.Laptop, value: '3%' },
                position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
                zIndex: { minWidth: Breakpoints.Laptop, value: 99 },
              },
              dimensions.width
            )}
          >
            <Surface
              {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
              elevation={3}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.gris_encart_int_flolend,
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                    flexDirection: {
                      minWidth: Breakpoints.Laptop,
                      value: 'row',
                    },
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                    paddingBottom: {
                      minWidth: Breakpoints.Laptop,
                      value: '2%',
                    },
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
                    paddingRight: { minWidth: Breakpoints.Laptop, value: '2%' },
                    paddingTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                  }
                ),
                dimensions.width
              )}
            >
              {/* Financier sélectionné */}
              <>
                {!tab_financier_actif ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['selecteur_on'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['selecteur_on'].style,
                      dimensions.width
                    )}
                  >
                    {'Financier'}
                  </Text>
                )}
              </>
              {/* Financier non sélectionné */}
              <>
                {tab_financier_actif ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['selecteur_off'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['selecteur_off'].style,
                      dimensions.width
                    )}
                  >
                    {'Financier'}
                  </Text>
                )}
              </>
              <Switch
                onValueChange={newSwitchValue => {
                  try {
                    setTab_financier_actif(!tab_financier_actif);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.SwitchStyles(theme)['switch_tabs'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.SwitchStyles(theme)['switch_tabs'].style,
                  dimensions.width
                )}
                value={!tab_financier_actif}
              />
              {/* Actualités sélectionné */}
              <>
                {tab_financier_actif ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['selecteur_on'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['selecteur_on'].style,
                      dimensions.width
                    )}
                  >
                    {'Actualités'}
                  </Text>
                )}
              </>
              {/* Actualités non sélectionné */}
              <>
                {!tab_financier_actif ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['selecteur_off'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['selecteur_off'].style,
                      dimensions.width
                    )}
                  >
                    {'Actualités'}
                  </Text>
                )}
              </>
            </Surface>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(EspacePersonnelScreen);
