import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Link,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  TextInput,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { e_mail_du_client: '', mot_de_passe_du_client: '' };

const Inscription1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [email_entre, setEmail_entre] = React.useState(
    props.route?.params?.e_mail_du_client ?? defaultProps.e_mail_du_client
  );
  const [mdp_entre, setMdp_entre] = React.useState(
    props.route?.params?.mot_de_passe_du_client ??
      defaultProps.mot_de_passe_du_client
  );
  const [message_erreur, setMessage_erreur] = React.useState('');
  // Vérifie les entrées de l'écran.
  const verifierEmailEtPassword = (email, password) => {
    // Expression régulière pour vérifier le format de l'email
    var regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Vérification si l'email est vide
    if (!email) {
      return 'Veuillez saisir une adresse e-mail.';
    }

    // Vérification si le mot de passe est vide
    if (!password) {
      return 'Veuillez saisir un mot de passe.';
    }

    // Vérification du format de l'email
    if (!regexEmail.test(email)) {
      return "L'email fourni n'est pas valide.";
    }

    // Vérification de la longueur du mot de passe et de la présence d'un symbole spécial
    if (password.length < 7 || !/[ $@^&*%^\.-]/.test(password)) {
      return 'Le mot de passe doit comporter au moins 7 caractères, y compris un symbole spécial ($@^&*%^.-).';
    }

    // Si l'email est valide et le mot de passe est valide
    return true;
  };
  const baseXanoSignupPOST = BaseXanoApi.useSignupPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setEmail_entre(
        props.route?.params?.e_mail_du_client ?? defaultProps.e_mail_du_client
      );
      setMdp_entre(
        props.route?.params?.mot_de_passe_du_client ??
          defaultProps.mot_de_passe_du_client
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      {/* Gradient mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            color1={palettes.App['Flolend 2']}
            color2={palettes.App['Flolend 1']}
            style={StyleSheet.applyWidth({ height: '100%' }, dimensions.width)}
          >
            <SimpleStyleKeyboardAwareScrollView
              enableAutomaticScroll={false}
              enableOnAndroid={false}
              enableResetScrollToCoords={false}
              keyboardShouldPersistTaps={'never'}
              viewIsInsideTabBar={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  flex: 1,
                  justifyContent: 'center',
                  paddingBottom: 48,
                  paddingLeft: 48,
                  paddingRight: 48,
                  paddingTop: 48,
                },
                dimensions.width
              )}
            >
              {/* Vue Flolend */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(Images['LogoTexteBlancMilieu'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 50, width: 200 }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              <Spacer left={8} right={8} bottom={12} top={12} />
              {/* Vue barres entrées */}
              <View>
                {/* Message erreur */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App.Internal_Hot_Red,
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Tablet, value: 18 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {message_erreur}
                </Text>
                {/* Email */}
                <View>
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 2,
                        borderColor: palettes.Brand['Light Inverse'],
                        flexDirection: 'row',
                        marginTop: 8,
                        paddingBottom: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <Icon
                          color={theme.colors.background.brand}
                          name={'MaterialIcons/alternate-email'}
                          size={20}
                          style={StyleSheet.applyWidth(
                            { height: 20, width: 20 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Icon 2 */}
                    <>
                      {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                        <Icon
                          color={palettes.App.Icon_Dark_Gray}
                          name={'MaterialIcons/alternate-email'}
                          size={31}
                        />
                      )}
                    </>
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 4 },
                        dimensions.width
                      )}
                    >
                      {/* email_entre */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newEmailEntreValue => {
                          try {
                            setEmail_entre(newEmailEntreValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        keyboardType={'email-address'}
                        placeholder={
                          'Votre adresse mail' ?? 'JaneDoh@email.com'
                        }
                        placeholderTextColor={palettes.Brand['Light Inverse']}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Montserrat_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                        value={email_entre}
                      />
                    </View>
                  </View>
                </View>
                {/* Spacer 2 */}
                <Spacer left={8} right={8} bottom={12} top={12} />
                {/* Mot de passe */}
                <View>
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Mot de passe'}
                  </Text>
                  {/* Field */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomWidth: 2,
                        borderColor: palettes.Brand['Light Inverse'],
                        flexDirection: 'row',
                        marginTop: 8,
                        paddingBottom: 12,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <Icon
                          color={theme.colors.background.brand}
                          name={'MaterialIcons/lock-outline'}
                          size={20}
                          style={StyleSheet.applyWidth(
                            { height: 20, width: 20 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Icon 2 */}
                    <>
                      {!(dimensions.width >= Breakpoints.Desktop) ? null : (
                        <Icon
                          color={theme.colors.background.brand}
                          name={'MaterialIcons/lock-outline'}
                          size={20}
                          style={StyleSheet.applyWidth(
                            { height: 20, width: 20 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <View
                      style={StyleSheet.applyWidth(
                        { flex: 1, marginLeft: 4 },
                        dimensions.width
                      )}
                    >
                      {/* mdp_entre */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newMdpEntreValue => {
                          try {
                            setMdp_entre(newMdpEntreValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        keyboardType={'default'}
                        placeholder={'Votre mot de passe' ?? 'MySecretPassword'}
                        placeholderTextColor={palettes.Brand['Light Inverse']}
                        secureTextEntry={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.background.brand,
                            fontFamily: 'Montserrat_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                        value={mdp_entre}
                      />
                    </View>
                  </View>
                </View>
              </View>
              {/* Spacer 2 */}
              <Spacer left={8} right={8} bottom={32} top={32} />
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Button Solid */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const check_entrees = verifierEmailEtPassword(
                          email_entre,
                          mdp_entre
                        );
                        if (check_entrees === true) {
                          const Reponse_inscription = (
                            await baseXanoSignupPOST.mutateAsync({
                              signupEmail: email_entre,
                              signupPassword: mdp_entre,
                            })
                          )?.json;
                          const authToken = Reponse_inscription?.authToken;
                          const message = Reponse_inscription?.message;
                          if (message === 'Ce compte est déjà utilisé.') {
                            setMessage_erreur('Ce compte est déjà utilisé.');
                          } else {
                            if (!authToken) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'auth_header',
                              value: 'Bearer ' + authToken,
                            });
                            navigation.navigate('Inscription2Screen');
                          }
                        } else {
                          const value45yapUhf = check_entrees.toString();
                          setMessage_erreur(value45yapUhf);
                          const message_erreur = value45yapUhf;
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: palettes.App['Flolend 4'],
                      borderRadius: 24,
                      borderWidth: 2,
                      color: theme.colors.background.brand,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Tablet, value: 20 },
                      ],
                      paddingBottom: 16,
                      paddingTop: 16,
                      textAlign: 'center',
                      width: { minWidth: Breakpoints.Tablet, value: '50%' },
                    },
                    dimensions.width
                  )}
                  title={"Je m'inscris !"}
                />
                <Spacer left={8} right={8} bottom={12} top={12} />
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center' },
                    dimensions.width
                  )}
                >
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('ConnexionScreen', {
                          email: email_entre,
                          mdp: mdp_entre,
                          arrivee_inscription: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors.background.brand,
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Vous avez déjà un compte ? Connectez-vous !'}
                  />
                </View>
              </View>
            </SimpleStyleKeyboardAwareScrollView>
          </LinearGradient>
        )}
      </>
      {/* Scroll View ordinateur */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App['Flolend 1'],
                },
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                height: [
                  { minWidth: Breakpoints.BigScreen, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-around',
                },
              },
              dimensions.width
            )}
          >
            {/* Vue vidéo */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      height: [
                        { minWidth: Breakpoints.Desktop, value: 550 },
                        { minWidth: Breakpoints.BigScreen, value: 650 },
                        { minWidth: Breakpoints.Laptop, value: 475 },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '48%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Fetch video */}
                  <BaseXanoApi.FetchArrierePlanDemarrageGET>
                    {({
                      loading,
                      error,
                      data,
                      refetchArrierePlanDemarrage,
                    }) => {
                      const fetchVideoData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            {...GlobalStyles.SurfaceStyles(theme)['Surface']
                              .props}
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .style,
                                {
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 24,
                                  },
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                  overflow: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'hidden',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <VideoPlayer
                              posterResizeMode={'cover'}
                              rate={1}
                              resizeMode={'cover'}
                              usePoster={false}
                              volume={0.5}
                              {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                                .props}
                              isLooping={true}
                              isMuted={true}
                              shouldPlay={true}
                              source={imageSource(`${fetchVideoData}`)}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.VideoPlayerStyles(theme)['Video']
                                    .style,
                                  {
                                    height: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                    width: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                              useNativeControls={false}
                            />
                          </Surface>
                        </View>
                      );
                    }}
                  </BaseXanoApi.FetchArrierePlanDemarrageGET>
                </View>
              )}
            </>
            {/* Vue carte */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App['Custom Color_28'],
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                  height: [
                    { minWidth: Breakpoints.BigScreen, value: 650 },
                    { minWidth: Breakpoints.Desktop, value: 550 },
                    { minWidth: Breakpoints.Laptop, value: 475 },
                  ],
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 48 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: 48 },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: 48 },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 48 },
                  width: { minWidth: Breakpoints.Laptop, value: '45%' },
                },
                dimensions.width
              )}
            >
              {/* Vue logo */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 40 },
                      { minWidth: Breakpoints.Desktop, value: 60 },
                      { minWidth: Breakpoints.BigScreen, value: 80 },
                    ],
                    marginBottom: { minWidth: Breakpoints.Laptop, value: '5%' },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  },
                  dimensions.width
                )}
              >
                <Image
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  resizeMode={'contain'}
                  source={imageSource(Images['LogoTexteBlancMilieu'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                />
              </View>
              {/* Vue encarts entrées */}
              <View>
                {/* Email */}
                <View
                  style={StyleSheet.applyWidth(
                    { gap: { minWidth: Breakpoints.Laptop, value: 5 } },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Montserrat_500Medium',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Montserrat_600SemiBold',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        textTransform: {
                          minWidth: Breakpoints.Laptop,
                          value: 'uppercase',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {'Email'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Flolend 1'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 24,
                        },
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 50 },
                          { minWidth: Breakpoints.Desktop, value: 70 },
                          { minWidth: Breakpoints.BigScreen, value: 90 },
                        ],
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* email_entre */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newEmailEntreValue => {
                        try {
                          setEmail_entre(newEmailEntreValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      keyboardType={'email-address'}
                      placeholder={'Votre adresse mail' ?? 'JaneDoh@email.com'}
                      placeholderTextColor={palettes.App['Custom Color_29']}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.background.brand,
                          fontFamily: 'Montserrat_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Tablet, value: 20 },
                            { minWidth: Breakpoints.Laptop, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 20 },
                            { minWidth: Breakpoints.BigScreen, value: 24 },
                          ],
                        },
                        dimensions.width
                      )}
                      value={email_entre}
                    />
                  </View>
                </View>
                {/* Password */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: { minWidth: Breakpoints.Laptop, value: 5 },
                      marginTop: { minWidth: Breakpoints.Laptop, value: '5%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Label */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'Montserrat_500Medium',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'Montserrat_600SemiBold',
                          },
                        ],
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        textTransform: {
                          minWidth: Breakpoints.Laptop,
                          value: 'uppercase',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {'Mot de passe'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App['Flolend 1'],
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 24,
                        },
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 50 },
                          { minWidth: Breakpoints.Desktop, value: 70 },
                          { minWidth: Breakpoints.BigScreen, value: 90 },
                        ],
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* mdp_entre */}
                    <TextInput
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newMdpEntreValue => {
                        try {
                          setMdp_entre(newMdpEntreValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      keyboardType={'default'}
                      placeholder={'Votre mot de passe' ?? 'MySecretPassword'}
                      placeholderTextColor={palettes.App['Custom Color_29']}
                      secureTextEntry={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.background.brand,
                          fontFamily: 'Montserrat_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Tablet, value: 20 },
                            { minWidth: Breakpoints.Laptop, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 20 },
                            { minWidth: Breakpoints.BigScreen, value: 24 },
                          ],
                        },
                        dimensions.width
                      )}
                      value={mdp_entre}
                    />
                  </View>
                </View>
              </View>
              {/* Message d'erreur */}
              <View>
                {/* Message erreur */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['message_erreur'].style,
                      {
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 24 },
                          { minWidth: Breakpoints.BigScreen, value: 28 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {message_erreur}
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginTop: { minWidth: Breakpoints.Laptop, value: 10 } },
                  dimensions.width
                )}
              >
                {/* Bouton inscription */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const check_entrees = verifierEmailEtPassword(
                          email_entre,
                          mdp_entre
                        );
                        if (check_entrees === true) {
                          const Reponse_inscription = (
                            await baseXanoSignupPOST.mutateAsync({
                              signupEmail: email_entre,
                              signupPassword: mdp_entre,
                            })
                          )?.json;
                          const authToken = Reponse_inscription?.authToken;
                          const message = Reponse_inscription?.message;
                          if (message === 'Ce compte est déjà utilisé.') {
                            setMessage_erreur('Ce compte est déjà utilisé.');
                          } else {
                            if (!authToken) {
                              return;
                            }
                            setGlobalVariableValue({
                              key: 'auth_header',
                              value: 'Bearer ' + authToken,
                            });
                            navigation.navigate('Inscription2Screen');
                          }
                        } else {
                          const value4ZvGFfX6 = check_entrees;
                          setMessage_erreur(value4ZvGFfX6);
                          const message_erreur = value4ZvGFfX6;
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: palettes.App['Flolend 4'],
                      borderRadius: 24,
                      borderWidth: 2,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors.background.brand,
                        },
                        {
                          minWidth: Breakpoints.BigScreen,
                          value: theme.colors.background.brand,
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors.background.brand,
                        },
                      ],
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 24 },
                        { minWidth: Breakpoints.BigScreen, value: 28 },
                      ],
                      paddingBottom: 16,
                      paddingTop: 16,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={"Je m'inscris !"}
                />
                <Spacer left={8} right={8} bottom={12} top={12} />
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center' },
                    dimensions.width
                  )}
                >
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('ConnexionScreen', {
                          email: email_entre,
                          mdp: mdp_entre,
                          arrivee_inscription: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                        ],
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                        ],
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                    title={'Vous avez déjà un compte ? Connectez-vous !'}
                  />
                </View>
              </View>
            </View>
          </SimpleStyleScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(Inscription1Screen);
