// Prend un nombre en € et le convertit en k€
const Conversion_k_euros = nombre => {
  // Vérifie si l'entrée est un nombre
  if (typeof nombre === 'number') {
    // Effectue la division par 1000 et renvoie le résultat
    return nombre / 1000;
  } else {
    // Si l'entrée n'est pas un nombre, renvoie une erreur ou une valeur par défaut, selon le cas
    return "Erreur : L'entrée n'est pas un nombre";
    // Ou renvoyer une valeur par défaut comme 0
    // return 0;
  }
};

export default Conversion_k_euros;
