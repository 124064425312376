import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  Surface,
  TextInput,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ChangementMotDePasseScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activation_chargement, setActivation_chargement] =
    React.useState(false);
  const [confirmPasswordValue, setConfirmPasswordValue] = React.useState('');
  const [mdp_change, setMdp_change] = React.useState(false);
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [passwordValue, setPasswordValue] = React.useState('');
  // Renvoie vrai si le mdp respecte les règles de sécurité.
  const conditions_mdp = (password, password2) => {
    // Vérification si le mot de passe est vide
    if (!password) {
      return 'Veuillez saisir un mot de passe.';
    }

    // Vérification du fait que les mdp soient égaux
    if (password !== password2) {
      return 'Les valeurs entrées diffèrent.';
    }

    // Vérification de la longueur du mot de passe et de la présence d'un symbole spécial
    if (password.length < 7 || !/[ $@^&*%^\.-]/.test(password)) {
      return 'Le mot de passe doit comporter au moins 7 caractères, y compris un symbole spécial ($@^&*%^.-).';
    }

    // Si l'email est valide et le mot de passe est valide
    return true;
  };
  const baseXanoChgmtMdpPUT = BaseXanoApi.useChgmtMdpPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          justifyContent: {
            minWidth: Breakpoints.BigScreen,
            value: 'space-between',
          },
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              },
              dimensions.width
            )}
          >
            {/* Back */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: 48,
                  justifyContent: 'center',
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </Touchable>
            </View>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Changer mon mot de passe'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: dimensions.height },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue horizontale */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              gap: { minWidth: Breakpoints.Laptop, value: 10 },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-between',
              },
              marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
              minHeight: { minWidth: Breakpoints.Laptop, value: '50%' },
              paddingLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
              paddingRight: { minWidth: Breakpoints.Laptop, value: '2%' },
            },
            dimensions.width
          )}
        >
          {/* Encart changement mdp */}
          <View
            style={StyleSheet.applyWidth(
              {
                justifyContent: 'space-evenly',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 30 },
                  { minWidth: Breakpoints.Tablet, value: '10%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 30 },
                  { minWidth: Breakpoints.Tablet, value: '10%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                paddingTop: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                dimensions.width
              )}
            >
              {'Changement de votre mot de passe'}
            </Text>
            {/* sub heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {'Veuillez entrer votre nouveau mot de passe'}
            </Text>
            {/* Password */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newPasswordValue => {
                try {
                  setPasswordValue(newPasswordValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['input_chgmnt_mdp'].props}
              editable={true}
              placeholder={'Mot de passe'}
              secureTextEntry={true}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['input_chgmnt_mdp'].style,
                dimensions.width
              )}
              value={passwordValue}
            />
            {/* Confirm Password */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newConfirmPasswordValue => {
                try {
                  setConfirmPasswordValue(newConfirmPasswordValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['input_chgmnt_mdp'].props}
              editable={true}
              placeholder={'Confirmez votre mot de passe'}
              secureTextEntry={true}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['input_chgmnt_mdp'].style,
                dimensions.width
              )}
              value={confirmPasswordValue}
            />
            <>
              {activation_chargement ? null : (
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={palettes.App['Flolend 2']}
                  color2={palettes.App['Flolend 1']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        flex: [
                          { minWidth: Breakpoints.BigScreen, value: null },
                          { minWidth: Breakpoints.Laptop, value: null },
                        ],
                        height: { minWidth: Breakpoints.Laptop, value: null },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 30 },
                          { minWidth: Breakpoints.Laptop, value: '5%' },
                        ],
                        maxHeight: {
                          minWidth: Breakpoints.Laptop,
                          value: '10%',
                        },
                        width: { minWidth: Breakpoints.Tablet, value: '50%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Reset Password */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setMessage_erreur(null);
                          setMdp_change(false);
                          setActivation_chargement(true);
                          if (
                            conditions_mdp(
                              passwordValue,
                              confirmPasswordValue
                            ) === true
                          ) {
                            const retour_api = (
                              await baseXanoChgmtMdpPUT.mutateAsync({
                                entree_1: passwordValue,
                                entree_2: confirmPasswordValue,
                              })
                            )?.json;
                            if (retour_api?.success) {
                              setMdp_change(true);
                            } else {
                              setMessage_erreur(retour_api?.message);
                            }
                          } else {
                            setMessage_erreur(
                              conditions_mdp(
                                passwordValue,
                                confirmPasswordValue
                              )
                            );
                          }

                          setActivation_chargement(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          borderColor: null,
                          borderWidth: 0,
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 18 },
                            { minWidth: Breakpoints.Tablet, value: 20 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                          ],
                          height: [
                            { minWidth: Breakpoints.Tablet, value: null },
                            { minWidth: Breakpoints.Laptop, value: '10%' },
                          ],
                          marginBottom: 0,
                          marginTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Confirmer'}
                  />
                </LinearGradient>
              )}
            </>
            {/* Message succès */}
            <>
              {!mdp_change ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Flolend 4'],
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 16 },
                          { minWidth: Breakpoints.Tablet, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 4 },
                          { minWidth: Breakpoints.Laptop, value: '1%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Votre mot de passe a été changé avec succès.'}
                </Text>
              )}
            </>
            {/* Message erreur */}
            <>
              {mdp_change ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App.Internal_Hot_Red,
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 16 },
                          { minWidth: Breakpoints.Tablet, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 4 },
                          { minWidth: Breakpoints.Laptop, value: '1%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {message_erreur}
                </Text>
              )}
            </>
            {/* Chargement */}
            <>
              {!activation_chargement ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    animating={true}
                    color={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.blanc_flolend,
                          fontFamily: 'PTSans_700Bold',
                          paddingLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Chargement en cours...'}
                  </Text>
                </View>
              )}
            </>
          </View>
          {/* Vue vidéo */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '48%' } },
                  dimensions.width
                )}
              >
                {/* Fetch video */}
                <BaseXanoApi.FetchArrierePlanDemarrageGET>
                  {({ loading, error, data, refetchArrierePlanDemarrage }) => {
                    const fetchVideoData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Surface
                          {...GlobalStyles.SurfaceStyles(theme)['Surface']
                            .props}
                          elevation={3}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface']
                                .style,
                              {
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 24,
                                },
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '100%',
                                },
                                overflow: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'hidden',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '100%',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <VideoPlayer
                            posterResizeMode={'cover'}
                            rate={1}
                            resizeMode={'cover'}
                            usePoster={false}
                            volume={0.5}
                            {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                              .props}
                            isLooping={true}
                            isMuted={true}
                            shouldPlay={true}
                            source={imageSource(`${fetchVideoData}`)}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.VideoPlayerStyles(theme)['Video']
                                  .style,
                                {
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                            useNativeControls={false}
                          />
                        </Surface>
                      </View>
                    );
                  }}
                </BaseXanoApi.FetchArrierePlanDemarrageGET>
              </View>
            )}
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ChangementMotDePasseScreen);
