import React from 'react';
import {
  Button,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  Picker,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const TestDeConnaissances1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_bouton, setAffichage_bouton] = React.useState(false);
  const [options_q1, setOptions_q1] = React.useState([
    { label: 'Moins de 5 000 €', value: 'Moins de 5 000 €' },
    { label: 'Entre 5 000 € et 10 000 €', value: 'Entre 5 000 € et 10 000 €' },
    { label: 'Plus de 10 000 €', value: 'Plus de 10 000 €' },
  ]);
  const [options_q2, setOptions_q2] = React.useState([
    { label: 'Moins de 3 ans', value: 'Moins de 3 ans' },
    { label: 'Entre 3 et 5 ans', value: 'Entre 3 et 5 ans' },
    { label: 'Plus de 5 ans', value: 'Plus de 5 ans' },
  ]);
  const [pickerRisques, setPickerRisques] = React.useState('');
  const [pickerValueDetention, setPickerValueDetention] = React.useState('');
  const [pickerValueEnveloppe, setPickerValueEnveloppe] = React.useState('');
  const [radioButtonGroupValueIndispo, setRadioButtonGroupValueIndispo] =
    React.useState('');
  const [pickerValue, setPickerValue] = React.useState(undefined);
  // Initialise les variables d'écran avec les valeurs de la base Airtable
  const init_variables = (
    mon_enveloppe,
    ma_periode_detention,
    indispo_cash,
    risques_pertes
  ) => {
    //Enveloppe totale
    setPickerValueEnveloppe(mon_enveloppe);

    //Période de détention
    setPickerValueDetention(ma_periode_detention);

    //Indisponibilité cash
    setRadioButtonGroupValueIndispo(indispo_cash);

    //Risque de pertes en capital
    setPickerRisques(risques_pertes);
  };

  // Renvoie TRUE si une information manque.
  const verif_completude = (info1, info2, info3, info4) => {
    return (
      info1 === '' ||
      info2 === '' ||
      info3 === '' ||
      info4 === '' ||
      info1 === null ||
      info2 === null ||
      info3 === null ||
      info4 === null
    );
  };
  const baseXanoDossInvestMajEcr1PATCH =
    BaseXanoApi.useDossInvestMajEcr1PATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const dossier_invest = (await BaseXanoApi.dossierInvestGET(Constants))
          ?.json;
        init_variables(
          dossier_invest?.Enveloppe_totale,
          dossier_invest?.Horizon_detention,
          dossier_invest?.Besoin_sommes,
          dossier_invest?.Niveau_risque_perte_capital
        );
        const retour = verif_completude(
          dossier_invest?.Enveloppe_totale,
          dossier_invest?.Horizon_detention,
          dossier_invest?.Besoin_sommes,
          dossier_invest?.Niveau_risque_perte_capital
        );
        if (!retour) {
          setAffichage_bouton(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.BigScreen, value: '100%' },
            justifyContent: 'space-between',
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Masterview */}
        <View>
          {/* En-tête */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'column' },
              dimensions.width
            )}
          >
            {/* entete */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: '2%',
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '2%' },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '2%' },
                  ],
                  marginTop: '2%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '80%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'titre_pipe_test_connaissances'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'titre_pipe_test_connaissances'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Test de connaissances'}
                </Text>
              </View>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('DossierInvestisseurScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Ionicons/exit-outline'}
                        size={18}
                      />
                    </Touchable>
                  </View>
                )}
              </>
            </View>
            {/* frise */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.flolend_gris_nuance_encart,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.gris_encart_int_flolend,
                    },
                  ],
                  justifyContent: 'space-evenly',
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['frise_test_titre_etape']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['frise_test_titre_etape']
                    .style,
                  dimensions.width
                )}
              >
                {'Etape 1/3 : Vos objectifs'}
              </Text>
              <LinearProgress
                animationDuration={500}
                indeterminate={false}
                isAnimated={true}
                lineCap={'round'}
                showTrack={true}
                thickness={10}
                trackLineCap={'round'}
                color={palettes.App['Flolend 4']}
                style={StyleSheet.applyWidth(
                  { alignSelf: 'center', width: '95%' },
                  dimensions.width
                )}
                trackColor={palettes.App.gris_fonce_flolend}
                value={33.3}
              />
            </View>
          </View>
          {/* Vue générale */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                marginLeft: [
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
              },
              dimensions.width
            )}
          >
            {/* Vue questionnaire */}
            <View>
              {/* Objectifs d'investissement */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                    ],
                    marginTop: [
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                      { minWidth: Breakpoints.Mobile, value: '2%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Label 5 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                    dimensions.width
                  )}
                >
                  {"Vos objectifs d'investissement"}
                </Text>
                {/* Enveloppe */}
                <View>
                  {/* Label 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {
                      "Quelle enveloppe totale envisagez-vous d'investir sur Flolend ?"
                    }
                  </Text>
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        const value4XnzM1ho = newPickerValue;
                        setPickerValueEnveloppe(value4XnzM1ho);
                        const sortie = value4XnzM1ho;
                        const resultat = verif_completude(
                          pickerRisques,
                          sortie,
                          pickerValueDetention,
                          radioButtonGroupValueIndispo
                        );
                        if (!resultat) {
                          setAffichage_bouton(true);
                        }
                        if (resultat) {
                          setAffichage_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)[
                      'picker_test_connaissances'
                    ].props}
                    options={options_q1}
                    placeholder={'Sélectionnez une option'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)[
                        'picker_test_connaissances'
                      ].style,
                      dimensions.width
                    )}
                    value={pickerValueEnveloppe}
                  />
                </View>
                {/* Divider 2 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
                {/* Détention */}
                <View>
                  {/* Label 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {
                      'Quel est l’horizon de détention envisagé de votre portefeuille sur notre plateforme ? (en y incluant d’éventuels réinvestissements)'
                    }
                  </Text>
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      try {
                        const value50zHPkSJ = newPickerValue;
                        setPickerValueDetention(value50zHPkSJ);
                        const sortie = value50zHPkSJ;
                        const resultat = verif_completude(
                          pickerRisques,
                          pickerValueEnveloppe,
                          sortie,
                          radioButtonGroupValueIndispo
                        );
                        if (!resultat) {
                          setAffichage_bouton(true);
                        }
                        if (resultat) {
                          setAffichage_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)[
                      'picker_test_connaissances'
                    ].props}
                    options={options_q2}
                    placeholder={'Sélectionnez une option'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)[
                        'picker_test_connaissances'
                      ].style,
                      dimensions.width
                    )}
                    value={pickerValueDetention}
                  />
                </View>
                {/* Divider 3 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
                {/* Indisponibilité cash */}
                <View>
                  {/* Label 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {
                      'Êtes-vous susceptible d’avoir besoin des sommes investies sur la plateforme Flolend pendant la durée de votre investissement ?'
                    }
                  </Text>

                  <View>
                    <RadioButtonGroup
                      onValueChange={newRadioButtonGroupValue => {
                        try {
                          const valueGKpneaYz = newRadioButtonGroupValue;
                          setRadioButtonGroupValueIndispo(valueGKpneaYz);
                          const sortie = valueGKpneaYz;
                          const resultat = verif_completude(
                            pickerRisques,
                            pickerValueEnveloppe,
                            pickerValueDetention,
                            sortie
                          );
                          if (!resultat) {
                            setAffichage_bouton(true);
                          }
                          if (resultat) {
                            setAffichage_bouton(false);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      direction={'horizontal'}
                      style={StyleSheet.applyWidth(
                        {
                          marginRight: [
                            { minWidth: Breakpoints.Mobile, value: 125 },
                            { minWidth: Breakpoints.Tablet, value: '50%' },
                            { minWidth: Breakpoints.Laptop, value: '60%' },
                          ],
                        },
                        dimensions.width
                      )}
                      value={radioButtonGroupValueIndispo}
                    >
                      <RadioButtonRow
                        color={theme.colors.branding.primary}
                        unselectedColor={theme.colors.branding.primary}
                        {...GlobalStyles.RadioButtonRowStyles(theme)[
                          'questionnaire_radio_button'
                        ].props}
                        label={'Oui'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.RadioButtonRowStyles(theme)[
                            'questionnaire_radio_button'
                          ].style,
                          dimensions.width
                        )}
                        value={'Oui'}
                      />
                      {/* Radio Button Row 2 */}
                      <RadioButtonRow
                        color={theme.colors.branding.primary}
                        unselectedColor={theme.colors.branding.primary}
                        {...GlobalStyles.RadioButtonRowStyles(theme)[
                          'questionnaire_radio_button'
                        ].props}
                        label={'Non'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.RadioButtonRowStyles(theme)[
                            'questionnaire_radio_button'
                          ].style,
                          dimensions.width
                        )}
                        value={'Non'}
                      />
                    </RadioButtonGroup>
                  </View>
                </View>
                {/* Divider 5 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
                {/* Niveau de risque */}
                <View
                  style={StyleSheet.applyWidth(
                    { marginBottom: 24 },
                    dimensions.width
                  )}
                >
                  {/* Label 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                      dimensions.width
                    )}
                  >
                    {
                      'Quel niveau de risque de perte en capital êtes-vous prêt à supporter ?'
                    }
                  </Text>
                  <Picker
                    autoDismissKeyboard={true}
                    dropDownBackgroundColor={theme.colors.background.brand}
                    dropDownBorderColor={theme.colors.border.brand}
                    dropDownBorderRadius={8}
                    dropDownBorderWidth={1}
                    dropDownTextColor={theme.colors.text.strong}
                    iconSize={24}
                    leftIconMode={'inset'}
                    mode={'native'}
                    onValueChange={newPickerValue => {
                      const pickerValue = newPickerValue;
                      try {
                        const valuevqfkXXCe = newPickerValue;
                        setPickerRisques(valuevqfkXXCe);
                        const sortie = valuevqfkXXCe;
                        const resultat = verif_completude(
                          pickerValueEnveloppe,
                          pickerValueDetention,
                          sortie,
                          radioButtonGroupValueIndispo
                        );
                        if (!resultat) {
                          setAffichage_bouton(true);
                        }
                        if (resultat) {
                          setAffichage_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    selectedIconColor={theme.colors.text.strong}
                    selectedIconName={'Feather/check'}
                    selectedIconSize={20}
                    type={'solid'}
                    {...GlobalStyles.PickerStyles(theme)[
                      'picker_test_connaissances'
                    ].props}
                    options={[
                      { label: 'Aucun risque', value: 'Aucun risque' },
                      { label: 'Risque modéré', value: 'Risque modéré' },
                      { label: 'Risque élevé', value: 'Risque élevé' },
                    ]}
                    placeholder={'Sélectionnez une option'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.PickerStyles(theme)[
                        'picker_test_connaissances'
                      ].style,
                      dimensions.width
                    )}
                    value={pickerRisques}
                  />
                </View>
              </View>

              <LinearGradient
                endX={100}
                endY={100}
                startX={0}
                startY={0}
                {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                  .props}
                color1={
                  (affichage_bouton
                    ? palettes.App['Flolend 1']
                    : palettes.App.gris_fonce_flolend) ??
                  palettes.App['Flolend 1']
                }
                color2={
                  (affichage_bouton
                    ? palettes.App['Flolend 2']
                    : palettes.App.gris_fonce_flolend) ??
                  palettes.App['Flolend 2']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                      .style,
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      alignSelf: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                      flex: { minWidth: Breakpoints.Tablet, value: null },
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 60 },
                        { minWidth: Breakpoints.Desktop, value: 65 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                      justifyContent: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      marginLeft: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '0%' },
                      ],
                      marginRight: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '0%' },
                      ],
                      marginTop: '2%',
                      overflow: {
                        minWidth: Breakpoints.Laptop,
                        value: 'hidden',
                      },
                      width: [
                        { minWidth: Breakpoints.Desktop, value: '60%' },
                        { minWidth: Breakpoints.BigScreen, value: '50%' },
                        { minWidth: Breakpoints.Tablet, value: '50%' },
                        { minWidth: Breakpoints.Laptop, value: '75%' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Bouton étape suivante */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        if (!affichage_bouton) {
                          return;
                        }
                        const retour_API = (
                          await baseXanoDossInvestMajEcr1PATCH.mutateAsync({
                            besoin_sommes: radioButtonGroupValueIndispo,
                            enveloppe: pickerValueEnveloppe,
                            horizon: pickerValueDetention,
                            niv_risq_perte: pickerRisques,
                          })
                        )?.json;
                        navigation.navigate('TestDeConnaissances2Screen');
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .style,
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: null,
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 24 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 60 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Etape suivante'}
                />
              </LinearGradient>
            </View>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(TestDeConnaissances1Screen);
