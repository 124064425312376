import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SupprimerMonCompte1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [bool_suppr, setBool_suppr] = React.useState(false);
  const baseXanoSuppComptePATCH = BaseXanoApi.useSuppComptePATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', paddingLeft: 20 },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Supprimer mon compte'}
            </Text>
          </View>
        )}
      </>
      {/* Scroll Content Frame */}
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: dimensions.height },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'] + 20,
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'] + 20,
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'] + 10,
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Notice */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: 40,
              paddingLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
              paddingRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '15%' },
              ],
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['titre_magistral'].style,
              dimensions.width
            )}
          >
            {'Comment supprimer mon compte ?'}
          </Text>
          {/* Encart général */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: palettes.App.flolend_gris_nuance_encart,
                borderRadius: 24,
                gap: 15,
                padding: 10,
              },
              dimensions.width
            )}
          >
            {/* sous encart 1 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.gris_encart_int_flolend,
                  borderRadius: 24,
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Text 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                  dimensions.width
                )}
              >
                {
                  'Pour requérir la suppression de votre compte, appuyez sur le bouton ci-dessous.'
                }
              </Text>
            </View>
            {/* sous encart 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.gris_encart_int_flolend,
                  borderRadius: 24,
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Text 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                  dimensions.width
                )}
              >
                {
                  "Si vous n'avez effectué aucun investissement, et si votre livret est dûment rempli, le solde de votre livret Flolend sera alors viré directement sur votre compte bancaire.\n\nSi vous avez fait des investissements qui sont désormais terminés, nous supprimerons votre adresse mail de toutes nos communications commerciales afin que vous ne soyez plus importuné. Toutefois, du fait des obligations règlementaires applicables à Flolend en sa qualité de Prestataire Européen de Services de Financement Participatif, la durée de conservation des données à caractère personnel est de cinq (5) ans à compter de la dernière transaction. Durant cet intervalle, vous recevrez uniquement et à titre exceptionnel les notifications ayant un caractère règlementaire.\n\nAttention, votre compte ne sera pas supprimé si vous avez encore des investissements en cours."
                }
              </Text>
            </View>
          </View>
          {/* Sélection */}
          <View style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}>
            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={
                (bool_suppr
                  ? palettes.App.Transparent
                  : palettes.App['Flolend 1']) ?? palettes.App['Flolend 1']
              }
              color2={
                (bool_suppr
                  ? palettes.App.Transparent
                  : palettes.App['Flolend 1']) ?? palettes.App['Flolend 1']
              }
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    borderColor: palettes.App.blanc_flolend,
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                    borderWidth: bool_suppr ? 1 : 0,
                    flex: { minWidth: Breakpoints.Laptop, value: null },
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 60 },
                      { minWidth: Breakpoints.Desktop, value: 65 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                    ],
                    marginBottom: { minWidth: Breakpoints.Laptop, value: 0 },
                    marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                    overflow: { minWidth: Breakpoints.Laptop, value: 'hidden' },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* Bouton investir actif */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setBool_suppr(!bool_suppr);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      borderRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: null,
                      },
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 22 },
                        { minWidth: Breakpoints.BigScreen, value: 30 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={`${bool_suppr ? 'Annuler' : 'Supprimer mon compte'}`}
              />
            </LinearGradient>
            <>
              {!bool_suppr ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['message_erreur'].style,
                    dimensions.width
                  )}
                >
                  {'Etes-vous sûr ?'}
                </Text>
              )}
            </>
            {/* Bouton valider */}
            <>
              {!bool_suppr ? null : (
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const retour = (
                          await baseXanoSuppComptePATCH.mutateAsync()
                        )?.json;
                        if (retour?.success) {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('SupprimerMonCompte2Screen');
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .style,
                      {
                        borderColor: palettes.App.Internal_Hot_Red,
                        borderRadius: [
                          { minWidth: Breakpoints.Laptop, value: null },
                          { minWidth: Breakpoints.Mobile, value: 25 },
                        ],
                        borderWidth: 1,
                        color: palettes.App.Internal_Hot_Red,
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 30 },
                        ],
                        height: 46,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Supprimer mon compte'}
                />
              )}
            </>
          </View>
        </View>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(SupprimerMonCompte1Screen);
