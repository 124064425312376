import React from 'react';
import {
  Icon,
  MultiSelectPicker,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Table,
  TableCell,
  TableRow,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const BanqueDocumentsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  // Filtre l'appel d'API (result1) en fonction du picker (result2).
  const filtrerObjet = (Picker, Result1) => {
    // Vérifie que Result1 est un tableau valide avant d'appliquer le filtre
    if (!Array.isArray(Result1)) {
      console.warn("Result1 n'est pas un tableau valide.", Result1);
      return [];
    }

    // Si Picker est vide, retourne le tableau original
    if (!Array.isArray(Picker) || Picker.length === 0) {
      return Result1;
    }

    const valuesToFilter = Result1.filter(item =>
      Picker.includes(item.liaison_projets)
    );

    return valuesToFilter;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: [
            { minWidth: Breakpoints.Laptop, value: dimensions.height },
            { minWidth: Breakpoints.Mobile, value: dimensions.height },
          ],
        },
        dimensions.width
      )}
    >
      {/* Vue mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { height: '100%', width: '100%' },
              dimensions.width
            )}
          >
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: 10,
                  paddingLeft: 20,
                },
                dimensions.width
              )}
            >
              {/* Back btn */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 44,
                      justifyContent: 'center',
                      width: 44,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App.gris_fonce_flolend}
                    name={'AntDesign/arrowleft'}
                    size={
                      !(dimensions.width >= Breakpoints.Desktop)
                        ? 18
                        : dimensions.width >= Breakpoints.BigScreen
                        ? 30
                        : 25
                    }
                  />
                </View>
              </Touchable>
              {/* Screen Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['screen_heading'].style,
                  dimensions.width
                )}
              >
                {'Ma banque de documents'}
              </Text>
            </View>

            <SimpleStyleScrollView
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              bounces={false}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                { maxHeight: '100%' },
                dimensions.width
              )}
            >
              {/* Vue tableau documents */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginBottom: 20,
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '15%' },
                      { minWidth: Breakpoints.Tablet, value: '10%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '15%' },
                      { minWidth: Breakpoints.Tablet, value: '10%' },
                    ],
                    paddingLeft: {
                      minWidth: Breakpoints.Desktop,
                      value: '10%',
                    },
                    paddingRight: {
                      minWidth: Breakpoints.Desktop,
                      value: '10%',
                    },
                  },
                  dimensions.width
                )}
              >
                {/* Fetch documents */}
                <BaseXanoApi.FetchDocsDuClientGET>
                  {({ loading, error, data, refetchDocsDuClient }) => {
                    const fetchDocumentsData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <MultiSelectPicker
                          autoDismissKeyboard={true}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newMultiSelectPickerValue => {
                            try {
                              const valuehlMRcFji = newMultiSelectPickerValue;
                              setMultiSelectPickerValue(valuehlMRcFji);
                              const resultat = valuehlMRcFji;
                              console.log(resultat);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectedIconColor={theme.colors.text.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          dropDownBackgroundColor={
                            palettes.App.gris_encart_int_flolend
                          }
                          dropDownBorderColor={palettes.App.gris_fonce_flolend}
                          dropDownTextColor={palettes.App.blanc_flolend}
                          label={'Vos projets'}
                          options={fetchDocumentsData?.picker}
                          placeholder={'Tous'}
                          placeholderTextColor={palettes.App.blanc_flolend}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor:
                                palettes.App.gris_encart_int_flolend,
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'Montserrat_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 16 },
                                { minWidth: Breakpoints.Laptop, value: 20 },
                                { minWidth: Breakpoints.Desktop, value: 24 },
                                { minWidth: Breakpoints.BigScreen, value: 28 },
                              ],
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '50%',
                              },
                            },
                            dimensions.width
                          )}
                          value={multiSelectPickerValue}
                        />
                        <Spacer bottom={8} left={8} right={8} top={8} />
                        <Table
                          borderStyle={'solid'}
                          borderWidth={1}
                          cellHorizontalPadding={10}
                          cellVerticalPadding={10}
                          data={filtrerObjet(
                            multiSelectPickerValue,
                            fetchDocumentsData?.documents
                          )}
                          drawBottomBorder={false}
                          drawEndBorder={false}
                          drawStartBorder={false}
                          keyExtractor={(tableData, index) =>
                            tableData?.id ??
                            tableData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(tableData)
                          }
                          listKey={'NXEJjq4t'}
                          renderItem={({ item, index }) => {
                            const tableData = item;
                            return (
                              <>
                                {/* En-tête */}
                                <TableRow
                                  drawBottomBorder={true}
                                  drawEndBorder={false}
                                  drawTopBorder={false}
                                  borderColor={palettes.App.gris_fonce_flolend}
                                  borderWidth={2}
                                  drawStartBorder={false}
                                  isTableHeader={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Flolend 1'],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Projet'}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 2 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            palettes.App['Flolend 1'],
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          {
                                            fontSize: {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 28,
                                            },
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Document'}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 4 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Action'}
                                    </Text>
                                  </TableCell>
                                </TableRow>
                                {/* Données */}
                                <TableRow
                                  drawEndBorder={false}
                                  drawTopBorder={false}
                                  isTableHeader={false}
                                  borderColor={palettes.App.gris_fonce_flolend}
                                  borderWidth={1}
                                  drawBottomBorder={true}
                                  drawStartBorder={false}
                                >
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          justifyContent: 'space-around',
                                          overflow: 'hidden',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {tableData?.Name}
                                    </Text>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'sous_elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'sous_elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Souscrit le '}
                                      {ConvertionDate(
                                        tableData?.date_signature
                                      )}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 2 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          justifyContent: 'flex-start',
                                          overflow: 'hidden',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Document */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {tableData?.Nom_du_doc}
                                    </Text>
                                    {/* Date */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'sous_elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'sous_elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {ConvertionDate(tableData?.date_emission)}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 4 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'space-evenly',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await WebBrowser.openBrowserAsync(
                                              `${tableData?.document?.url}`
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      <Icon
                                        color={palettes.App['Flolend 2']}
                                        name={'FontAwesome/download'}
                                        size={18}
                                      />
                                    </Touchable>
                                    <>
                                      {Platform.OS === 'web' ? null : (
                                        <Spacer
                                          bottom={8}
                                          top={8}
                                          left={2}
                                          right={2}
                                        />
                                      )}
                                    </>
                                    {/* Touchable 2 */}
                                    <>
                                      {Platform.OS === 'web' ? null : (
                                        <Touchable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                await openShareUtil(
                                                  `${tableData?.document?.url}`
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                        >
                                          <Icon
                                            color={palettes.App['Flolend 2']}
                                            name={
                                              'MaterialIcons/mobile-screen-share'
                                            }
                                            size={18}
                                          />
                                        </Touchable>
                                      )}
                                    </>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          }}
                          showsVerticalScrollIndicator={true}
                          {...GlobalStyles.TableStyles(theme)['Table'].props}
                          borderColor={palettes.App.gris_fonce_flolend}
                          drawTopBorder={false}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TableStyles(theme)['Table'].style,
                            dimensions.width
                          )}
                        />
                      </>
                    );
                  }}
                </BaseXanoApi.FetchDocsDuClientGET>
              </View>
            </SimpleStyleScrollView>
          </View>
        )}
      </>
      {/* Vue desktop */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
              },
              dimensions.width
            )}
          >
            <NavbarBlock />
            <SimpleStyleScrollView
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              bounces={false}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  paddingTop: [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: Constants['hauteur_navbar_laptop'],
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: Constants['hauteur_navbar_desktop'],
                    },
                    {
                      minWidth: Breakpoints.BigScreen,
                      value: Constants['hauteur_navbar_big_screen'],
                    },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Vue tableau documents 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '15%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '15%' },
                    ],
                    marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                  },
                  dimensions.width
                )}
              >
                {/* Fetch documents */}
                <BaseXanoApi.FetchDocsDuClientGET>
                  {({ loading, error, data, refetchDocsDuClient }) => {
                    const fetchDocumentsData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        <MultiSelectPicker
                          autoDismissKeyboard={true}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          iconSize={24}
                          leftIconMode={'inset'}
                          onValueChange={newMultiSelectPickerValue => {
                            try {
                              const value3bf4uHob = newMultiSelectPickerValue;
                              setMultiSelectPickerValue(value3bf4uHob);
                              const resultat = value3bf4uHob;
                              console.log(resultat);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectedIconColor={theme.colors.text.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          dropDownBackgroundColor={
                            palettes.App.gris_encart_int_flolend
                          }
                          dropDownBorderColor={palettes.App.gris_fonce_flolend}
                          dropDownTextColor={palettes.App.blanc_flolend}
                          label={'Vos projets'}
                          options={fetchDocumentsData?.picker}
                          placeholder={'Tous'}
                          placeholderTextColor={palettes.App.blanc_flolend}
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.gris_encart_int_flolend,
                              },
                              color: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: palettes.App.blanc_flolend,
                                },
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: palettes.App['Flolend 5'],
                                },
                              ],
                              fontFamily: 'Montserrat_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Tablet, value: 16 },
                                { minWidth: Breakpoints.BigScreen, value: 20 },
                                { minWidth: Breakpoints.Desktop, value: 18 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                              ],
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '50%',
                              },
                            },
                            dimensions.width
                          )}
                          value={multiSelectPickerValue}
                        />
                        <Spacer bottom={8} left={8} right={8} top={8} />
                        <Table
                          borderColor={theme.colors.border.brand}
                          borderStyle={'solid'}
                          borderWidth={1}
                          cellHorizontalPadding={10}
                          cellVerticalPadding={10}
                          data={filtrerObjet(
                            multiSelectPickerValue,
                            fetchDocumentsData?.documents
                          )}
                          drawBottomBorder={false}
                          drawEndBorder={false}
                          drawStartBorder={false}
                          keyExtractor={(tableData, index) =>
                            tableData?.id ??
                            tableData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(tableData)
                          }
                          listKey={'TNQxDa8C'}
                          renderItem={({ item, index }) => {
                            const tableData = item;
                            return (
                              <>
                                {/* En-tête */}
                                <TableRow
                                  drawBottomBorder={true}
                                  drawEndBorder={false}
                                  drawTopBorder={false}
                                  borderColor={palettes.App.gris_fonce_flolend}
                                  borderWidth={2}
                                  drawStartBorder={false}
                                  isTableHeader={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Flolend 1'],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Projet'}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 2 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            palettes.App['Flolend 1'],
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Document'}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 3 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Date'}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 4 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Téléchargement'}
                                    </Text>
                                  </TableCell>
                                </TableRow>
                                {/* Données */}
                                <TableRow
                                  drawBottomBorder={true}
                                  drawEndBorder={false}
                                  drawTopBorder={false}
                                  isTableHeader={false}
                                  borderColor={palettes.App.gris_fonce_flolend}
                                  borderWidth={1}
                                  drawStartBorder={false}
                                >
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          justifyContent: 'space-around',
                                          overflow: 'hidden',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {tableData?.Name}
                                    </Text>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'sous_elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'sous_elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Souscrit en '}
                                      {ConvertionDate(
                                        tableData?.date_signature
                                      )}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 2 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          flexWrap: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'wrap',
                                          },
                                          justifyContent: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'center',
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 'flex-start',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'flex-start',
                                            },
                                          ],
                                          overflow: 'hidden',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Document */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {tableData?.Nom_du_doc}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 3 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          overflow: 'hidden',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {ConvertionDate(tableData?.date_emission)}
                                    </Text>
                                  </TableCell>
                                  {/* Table Cell 4 */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'space-evenly',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Touchable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await WebBrowser.openBrowserAsync(
                                              `${tableData?.document?.url}`
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      <Icon
                                        color={palettes.App['Flolend 2']}
                                        name={'FontAwesome/download'}
                                        size={
                                          !(
                                            dimensions.width >=
                                            Breakpoints.Desktop
                                          )
                                            ? 18
                                            : dimensions.width >=
                                              Breakpoints.BigScreen
                                            ? 30
                                            : 25
                                        }
                                      />
                                    </Touchable>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          }}
                          {...GlobalStyles.TableStyles(theme)['Table'].props}
                          drawTopBorder={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TableStyles(theme)['Table'].style,
                              {
                                flex: {
                                  minWidth: Breakpoints.Laptop,
                                  value: null,
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        />
                      </>
                    );
                  }}
                </BaseXanoApi.FetchDocsDuClientGET>
              </View>
              <FooterDesktopBlock />
            </SimpleStyleScrollView>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(BanqueDocumentsScreen);
