import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Table,
  TableCell,
  TableRow,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const DetailsInvestissementsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [filtre_select, setFiltre_select] = React.useState('Montant investi');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['header_classique'].style,
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Tous mes investissements'}
            </Text>
          </View>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: [
              { minWidth: Breakpoints.BigScreen, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: '100%' },
            ],
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'space-between' },
              { minWidth: Breakpoints.Laptop, value: 'space-between' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '15%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '15%' },
              marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
              padding: { minWidth: Breakpoints.Laptop, value: 20 },
            },
            dimensions.width
          )}
        >
          {/* Filtre tableau */}
          <View
            style={StyleSheet.applyWidth(
              { marginBottom: '2%' },
              dimensions.width
            )}
          >
            {/* Filtre */}
            <SimpleStyleScrollView
              bounces={true}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: 5 },
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  paddingTop: { minWidth: Breakpoints.Laptop, value: 5 },
                },
                dimensions.width
              )}
            >
              {/* Principal (actif) */}
              <>
                {!(filtre_select === 'Montant investi') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                        .style,
                      dimensions.width
                    )}
                    title={'Montant investi'}
                  />
                )}
              </>
              {/* Principal (inactif) */}
              <>
                {!(filtre_select !== 'Montant investi') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setFiltre_select('Montant investi');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)[
                      'bouton_filtre_inactif'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_filtre_inactif']
                        .style,
                      dimensions.width
                    )}
                    title={'Montant investi'}
                  />
                )}
              </>
              {/* Intérêts perçus (actif) */}
              <>
                {!(filtre_select === 'Intérêts perçus') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                        .style,
                      dimensions.width
                    )}
                    title={'Intérêts perçus'}
                  />
                )}
              </>
              {/* Intérêts perçus (inactif) */}
              <>
                {!(filtre_select !== 'Intérêts perçus') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setFiltre_select('Intérêts perçus');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)[
                      'bouton_filtre_inactif'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_filtre_inactif']
                        .style,
                      dimensions.width
                    )}
                    title={'Intérêts perçus'}
                  />
                )}
              </>
              {/* Intérêts à percevoir (actif) */}
              <>
                {!(filtre_select === 'Intérêts à percevoir') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                          .style,
                        {
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 26,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Intérêts à percevoir'}
                  />
                )}
              </>
              {/* Intérêts à percevoir (inactif) */}
              <>
                {!(filtre_select !== 'Intérêts à percevoir') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setFiltre_select('Intérêts à percevoir');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)[
                      'bouton_filtre_inactif'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ButtonStyles(theme)['bouton_filtre_inactif']
                        .style,
                      dimensions.width
                    )}
                    title={'Intérêts à percevoir'}
                  />
                )}
              </>
              {/* Date de fin (actif) */}
              <>
                {!(filtre_select === 'Date de fin') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_filtre_actif']
                          .style,
                        {
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 26,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Date de fin'}
                  />
                )}
              </>
              {/* Date de fin (inactif) */}
              <>
                {!(filtre_select !== 'Date de fin') ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        setFiltre_select('Date de fin');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)[
                      'bouton_filtre_inactif'
                    ].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_inactif'
                        ].style,
                        {
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 26,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Date de fin'}
                  />
                )}
              </>
            </SimpleStyleScrollView>
          </View>
          {/* Vue table */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Fetch investissements */}
            <BaseXanoApi.FetchSousValideeGET>
              {({ loading, error, data, refetchSousValidee }) => {
                const fetchInvestissementsData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <Table
                    borderColor={theme.colors.border.brand}
                    borderStyle={'solid'}
                    borderWidth={1}
                    cellHorizontalPadding={10}
                    cellVerticalPadding={10}
                    data={fetchInvestissementsData}
                    keyExtractor={(tableData, index) =>
                      tableData?.id ??
                      tableData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(tableData)
                    }
                    listKey={'fIJSioXt'}
                    renderItem={({ item, index }) => {
                      const tableData = item;
                      return (
                        <>
                          {/* En-tête */}
                          <TableRow
                            drawBottomBorder={true}
                            drawEndBorder={false}
                            drawTopBorder={false}
                            borderColor={palettes.App.gris_fonce_flolend}
                            borderWidth={2}
                            drawStartBorder={false}
                            isTableHeader={true}
                            style={StyleSheet.applyWidth(
                              { backgroundColor: palettes.App['Flolend 1'] },
                              dimensions.width
                            )}
                          >
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderColor={palettes.App['Flolend 4']}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_tableau'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_tableau'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Projet'}
                              </Text>
                            </TableCell>
                            {/* Table Cell 2 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderColor={palettes.App['Flolend 4']}
                              borderWidth={1}
                              drawEndBorder={false}
                              drawStartBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    backgroundColor: palettes.App['Flolend 1'],
                                    justifyContent: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_tableau'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_tableau'
                                    ].style,
                                    {
                                      color: palettes.App['Flolend 4'],
                                      textDecorationColor:
                                        palettes.App['Flolend 4'],
                                      textDecorationLine: 'none',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {filtre_select}
                              </Text>
                            </TableCell>
                            {/* Table Cell 3 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_tableau'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_tableau'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Prochaine échéance'}
                              </Text>
                            </TableCell>
                          </TableRow>
                          {/* Données */}
                          <TableRow
                            drawEndBorder={false}
                            drawTopBorder={false}
                            isTableHeader={false}
                            borderColor={palettes.App.gris_fonce_flolend}
                            borderWidth={1}
                            drawBottomBorder={true}
                            drawStartBorder={false}
                          >
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderColor={palettes.App['Flolend 4']}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['elmt_table']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['elmt_table']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {tableData?.projets?.Name}
                              </Text>
                              {/* Text 2 */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'sous_elmt_table'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'sous_elmt_table'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Souscrit le '}
                                {ConvertionDate(
                                  tableData?.Date_signature_bulletin
                                )}
                              </Text>
                            </TableCell>
                            {/* Table Cell 2 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              borderColor={palettes.App['Flolend 4']}
                              borderWidth={1}
                              drawEndBorder={false}
                              drawStartBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* Montant investi */}
                              <>
                                {!(
                                  filtre_select === 'Montant investi'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'elmt_table'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        { color: palettes.App['Flolend 4'] }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {format_nombre(
                                      tableData?.montant_souscrit_euros,
                                      2
                                    )}
                                    {' €'}
                                  </Text>
                                )}
                              </>
                              {/* Intérêts perçus */}
                              <>
                                {!(
                                  filtre_select === 'Intérêts perçus'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'elmt_table'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        { color: palettes.App['Flolend 4'] }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {format_nombre(
                                      tableData?.interets_percus,
                                      2
                                    )}
                                    {' €'}
                                  </Text>
                                )}
                              </>
                              {/* Intérêts à percevoir */}
                              <>
                                {!(
                                  filtre_select === 'Intérêts à percevoir'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'elmt_table'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        { color: palettes.App['Flolend 4'] }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {format_nombre(
                                      tableData?.interets_a_percevoir,
                                      2
                                    )}
                                    {' €'}
                                  </Text>
                                )}
                              </>
                              {/* Date de fin */}
                              <>
                                {!(filtre_select === 'Date de fin') ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'elmt_table'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        { color: palettes.App['Flolend 4'] }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {ConvertionDate(
                                      tableData?.projets?.date_de_fin
                                    )}
                                  </Text>
                                )}
                              </>
                            </TableCell>
                            {/* Table Cell 3 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { alignItems: 'center' }
                                ),
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    width: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '75%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '50%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'elmt_table'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['elmt_table']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {ConvertionDate(
                                    tableData?.projets?.Prochaine_echeance
                                  )}
                                </Text>
                              </View>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: '25%',
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '50%',
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'EcheancierProjetScreen',
                                        {
                                          montant_simul_euros:
                                            tableData?.montant_souscrit_euros,
                                          id_projet: tableData?.projets?.id,
                                        }
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <Icon
                                    color={palettes.App['Flolend 2']}
                                    name={'MaterialIcons/date-range'}
                                    size={
                                      !(dimensions.width >= Breakpoints.Desktop)
                                        ? 18
                                        : dimensions.width >=
                                          Breakpoints.BigScreen
                                        ? 30
                                        : 25
                                    }
                                  />
                                </Touchable>
                              </View>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    {...GlobalStyles.TableStyles(theme)['Table'].props}
                    drawBottomBorder={false}
                    drawEndBorder={false}
                    drawStartBorder={false}
                    drawTopBorder={false}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TableStyles(theme)['Table'].style,
                      dimensions.width
                    )}
                  />
                );
              }}
            </BaseXanoApi.FetchSousValideeGET>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(DetailsInvestissementsScreen);
