import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  NumberInput,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Table,
  TableCell,
  TableRow,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const MoneyOut1RecupererFondsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_erreur_montant, setAffichage_erreur_montant] =
    React.useState(false);
  const [entree_vide, setEntree_vide] = React.useState(false);
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  // Renvoie true si aucun IBAN n'est en attente et si la limite d'IBAN n'est pas encore atteinte. Renvoie false à défaut.
  const affichage_ajout_iban = (
    nombre_ibans_limite,
    liste_iban_valides,
    liste_iban_pending
  ) => {
    // Calculer le nombre d'éléments dans chaque liste
    let count1 = liste_iban_valides.length;
    let count2 = liste_iban_pending.length;

    // Calculer la somme des éléments des deux listes
    let totalCount = count1 + count2;

    // Vérifier si le total des éléments est inférieur à la limite et si la liste en attente est vide
    return totalCount < nombre_ibans_limite && count2 === 0;
  };

  // Renvoie vrai si l'array fourni en entrée est vide
  const array_vide = arr => {
    return arr.length === 0;
  };

  // Arrondit au centime inférieur le montant entré en euros.
  const arrondi_montant_euros = montant_euros => {
    return Math.floor(montant_euros * 100) / 100;
  };

  // Renvoie la différence entre param1 et param2
  const difference = (param1, param2) => {
    return param1 - param2;
  };

  // Renvoie VRAI si le paramètre fourni est entrée est différent de 0 ou de NaN
  const entree_completee = parametre => {
    if (parametre === '' || isNaN(parametre)) {
      return false;
    }
    return true;
  };

  // Ajoute un espace tous les 4 caractères dans la chaine de caractères fournie en entrée
  const espaces_iban = str => {
    return str.replace(/(.{4})/g, '$1 ');
  };

  // Renvoie VRAI si objet est dans list et FAUX sinon.
  const objet_dans_liste = (list, obj) => {
    return list.includes(obj);
  };

  // Récupère l'id de l'IBAN Lemonway à partir du code d'iban
  const recup_id = (code_iban, list) => {
    // Parcourt chaque objet dans la liste
    for (let i = 0; i < list.length; i++) {
      // Vérifie si l'IBAN de l'objet correspond à code_iban
      if (list[i].iban === code_iban) {
        // Retourne le label correspondant
        return list[i].id;
      }
    }
    // Retourne null si aucun IBAN ne correspond
    return null;
  };

  // Récupère le label du code iban fourni en entrée dans la listes des ibans actifs
  const recup_label = (code_iban, list) => {
    // Parcourt chaque objet dans la liste
    for (let i = 0; i < list.length; i++) {
      // Vérifie si l'IBAN de l'objet correspond à code_iban
      if (list[i].iban === code_iban) {
        // Retourne le label correspondant
        return list[i].label;
      }
    }
    // Retourne null si aucun IBAN ne correspond
    return null;
  };

  // Règle le picker à la première valeur de l'array d'ibans valides.
  const setupPicker = valeur => {
    setPickerValue(valeur);
  };

  // Transforme les arrays avec les ibans en entrée de picker.
  const transformData = inputArray => {
    return inputArray.map(item => ({
      label: item.label,
      value: item.iban,
    }));
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const retour_api = (await BaseXanoApi.authGET(Constants))?.json;
        setupPicker(
          retour_api?.iban_sortie?.ibans_sortie_actifs &&
            (retour_api?.iban_sortie?.ibans_sortie_actifs)[0].iban
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { marginBottom: 10 }
              ),
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Retirer mes fonds'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Fetch client */}
          <BaseXanoApi.FetchAuthGET>
            {({ loading, error, data, refetchAuth }) => {
              const fetchClientData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Virer mes fonds */}
                  <View>
                    {/* Encart titre */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          gap: 10,
                          paddingBottom: 20,
                          paddingLeft: 20,
                          paddingRight: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Titre */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Virer mes fonds sur mon compte bancaire'}
                      </Text>
                      {/* Notes */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['explications']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['explications'].style,
                          dimensions.width
                        )}
                      >
                        {
                          "Entrez dans l'encart ci-dessous le montant que vous souhaitez retirer de votre livret Flolend."
                        }
                      </Text>
                    </View>
                    {/* Livret Flolend */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          marginLeft: 20,
                          marginRight: 20,
                          width: { minWidth: Breakpoints.Laptop, value: '50%' },
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Flolend 1'],
                            borderRadius: 12,
                            height: 220,
                            justifyContent: 'space-between',
                            paddingBottom: 24,
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 24,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'livret_flolend_titre'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'livret_flolend_titre'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Livret Flolend'}
                          </Text>
                          <Image
                            resizeMode={'contain'}
                            source={imageSource(Images['LogoWireframeLight'])}
                            style={StyleSheet.applyWidth(
                              { height: 30, width: 30 },
                              dimensions.width
                            )}
                          />
                        </View>

                        <View>
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'livret_flolend_solde_titre'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'livret_flolend_solde_titre'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Solde'}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                marginTop: 6,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'livret_flolend_solde'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_solde'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {format_nombre(
                                conversion_solde_wallet(
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.balance
                                ),
                                2
                              )}
                              {' €'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    {/* Encart montant */}
                    <>
                      {array_vide(
                        fetchClientData?.iban_sortie?.ibans_sortie_actifs
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              justifyContent: 'center',
                              paddingBottom: 32,
                              paddingLeft: 24,
                              paddingRight: 24,
                              paddingTop: 32,
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                gap: 10,
                                height: [
                                  { minWidth: Breakpoints.Mobile, value: 46 },
                                  { minWidth: Breakpoints.Desktop, value: 50 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 60,
                                  },
                                ],
                                justifyContent: 'space-around',
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '50%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <NumberInput
                              changeTextDelay={500}
                              onChangeText={newNumberInputValue => {
                                const numberInputValue = newNumberInputValue;
                                try {
                                  setNumberInputValue(newNumberInputValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              webShowOutline={true}
                              {...GlobalStyles.NumberInputStyles(theme)[
                                'encart_input_simul'
                              ].props}
                              placeholder={'Montant à retirer'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.NumberInputStyles(theme)[
                                    'encart_input_simul'
                                  ].style,
                                  {
                                    borderColor:
                                      affichage_erreur_montant || entree_vide
                                        ? palettes.App.Internal_Hot_Red
                                        : palettes.App.gris_fonce_flolend,
                                  }
                                ),
                                dimensions.width
                              )}
                              value={numberInputValue}
                            />
                            {/* Retirer */}
                            <Pressable
                              onPress={() => {
                                try {
                                  if (!entree_completee(numberInputValue)) {
                                    setEntree_vide(true);
                                  }
                                  if (!entree_completee(numberInputValue)) {
                                    return;
                                  }
                                  if (
                                    !(
                                      difference(
                                        conversion_solde_wallet(
                                          fetchClientData?.wallet_lemonway
                                            ?.compte_client?.balance
                                        ),
                                        arrondi_montant_euros(numberInputValue)
                                      ) >= 0
                                    )
                                  ) {
                                    setAffichage_erreur_montant(true);
                                  }
                                  if (
                                    difference(
                                      conversion_solde_wallet(
                                        fetchClientData?.wallet_lemonway
                                          ?.compte_client?.balance
                                      ),
                                      arrondi_montant_euros(numberInputValue)
                                    ) >= 0
                                  ) {
                                    navigation.navigate(
                                      'MoneyOut4RecapTransfertScreen',
                                      {
                                        code_IBAN: pickerValue,
                                        label_iban: recup_label(
                                          pickerValue,
                                          fetchClientData?.iban_sortie
                                            ?.ibans_sortie_actifs
                                        ),
                                        montant_a_virer_euros:
                                          arrondi_montant_euros(
                                            numberInputValue
                                          ),
                                        id_iban_lemonway: recup_id(
                                          pickerValue,
                                          fetchClientData?.iban_sortie
                                            ?.ibans_sortie_actifs
                                        ),
                                      }
                                    );
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Retirer */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderColor: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 2'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: entree_completee(
                                          numberInputValue
                                        )
                                          ? palettes.App['Flolend 2']
                                          : palettes.App.gris_fonce_flolend,
                                      },
                                    ],
                                    borderRadius: 50,
                                    borderWidth: 1,
                                    flexDirection: 'row',
                                    gap: 5,
                                    height: '100%',
                                    padding: 10,
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  color={palettes.App.blanc_flolend}
                                  name={'Feather/arrow-down'}
                                  size={
                                    (!(dimensions.width >= Breakpoints.Desktop)
                                      ? 18
                                      : dimensions.width >=
                                        Breakpoints.BigScreen
                                      ? 30
                                      : 25) ?? 26
                                  }
                                />
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 18,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 22,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {'Retirer'}
                                </Text>
                              </View>
                            </Pressable>
                          </View>
                        </View>
                      )}
                    </>
                    {/* vue erreur */}
                    <>
                      {!affichage_erreur_montant ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { padding: 5 },
                            dimensions.width
                          )}
                        >
                          {/* message_erreur */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['message_erreur']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['message_erreur']
                                  .style,
                                { marginTop: 0 }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'Votre retrait ne peut excéder le montant de votre livret.'
                            }
                          </Text>
                        </View>
                      )}
                    </>
                    {/* Compte client */}
                    <>
                      {array_vide(
                        fetchClientData?.iban_sortie?.ibans_sortie_actifs
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: {
                                minWidth: Breakpoints.Laptop,
                                value: 'center',
                              },
                              gap: 10,
                              marginLeft: 20,
                              marginRight: 20,
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '50%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* IBAN */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['Flolend 2'],
                                borderRadius: 12,
                                height: 220,
                                justifyContent: 'space-between',
                                paddingBottom: 24,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 24,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_titre'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'livret_flolend_titre'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Votre IBAN'}
                              </Text>
                              <Icon
                                color={theme.colors.background.brand}
                                name={'Ionicons/person-circle'}
                                size={30}
                              />
                            </View>

                            <View>
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_solde'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'livret_flolend_solde'
                                    ].style,
                                    {
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 20,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {espaces_iban(pickerValue)}
                              </Text>
                            </View>
                          </View>
                          {/* Sélecteur compte */}
                          <View>
                            <Picker
                              autoDismissKeyboard={true}
                              dropDownBackgroundColor={
                                theme.colors.background.brand
                              }
                              dropDownBorderColor={theme.colors.border.brand}
                              dropDownBorderRadius={8}
                              dropDownBorderWidth={1}
                              dropDownTextColor={theme.colors.text.strong}
                              iconSize={24}
                              leftIconMode={'inset'}
                              mode={'native'}
                              onValueChange={newPickerValue => {
                                const pickerValue = newPickerValue;
                                try {
                                  setPickerValue(newPickerValue);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              selectedIconColor={theme.colors.text.strong}
                              selectedIconName={'Feather/check'}
                              selectedIconSize={20}
                              type={'solid'}
                              {...GlobalStyles.PickerStyles(theme)[
                                'picker_test_connaissances'
                              ].props}
                              options={transformData(
                                fetchClientData?.iban_sortie
                                  ?.ibans_sortie_actifs
                              )}
                              placeholder={''}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.PickerStyles(theme)[
                                  'picker_test_connaissances'
                                ].style,
                                dimensions.width
                              )}
                              value={pickerValue}
                            />
                          </View>
                        </View>
                      )}
                    </>
                  </View>
                  {/* Charger mon IBAN */}
                  <>
                    {!affichage_ajout_iban(
                      3,
                      fetchClientData?.iban_sortie?.ibans_sortie_actifs,
                      fetchClientData?.iban_sortie?.ibans_sortie_pending
                    ) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { gap: 10, padding: 20 },
                          dimensions.width
                        )}
                      >
                        {/* Titre */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['titre1_generique']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['titre1_generique']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Ajouter un nouvel IBAN'}
                        </Text>
                        {/* Notes */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['explications']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['explications']
                              .style,
                            dimensions.width
                          )}
                        >
                          {
                            "Pour virer vos fonds vers un nouveau compte, transmettez nous votre code d'IBAN et une preuve d'IBAN (par exemple un relevé d'identité bancaire). Votre IBAN sera validé dans les 48 heures par notre prestataire et vous pourrez récupérer vos fonds subséquemment. Vous pouvez ajouter jusqu'à 3 IBANs."
                          }
                        </Text>

                        <LinearGradient
                          endX={100}
                          endY={100}
                          startX={0}
                          startY={0}
                          {...GlobalStyles.LinearGradientStyles(theme)[
                            'gradient_bouton'
                          ].props}
                          color1={palettes.App['Flolend 1']}
                          color2={palettes.App['Flolend 2']}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.LinearGradientStyles(theme)[
                                'gradient_bouton'
                              ].style,
                              {
                                alignSelf: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                flex: [
                                  { minWidth: Breakpoints.Laptop, value: null },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: null,
                                  },
                                ],
                                height: [
                                  { minWidth: Breakpoints.Desktop, value: 65 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 70,
                                  },
                                  { minWidth: Breakpoints.Laptop, value: 60 },
                                ],
                                marginTop: null,
                                overflow: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'hidden',
                                },
                                width: [
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '60%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '50%',
                                  },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: '50%',
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              try {
                                navigation.navigate(
                                  'MoneyOut2UploadIBANScreen'
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.ButtonStyles(theme)[
                              'bouton_fond_blanc'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)[
                                  'bouton_fond_blanc'
                                ].style,
                                {
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: null,
                                  },
                                  height: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 60,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 70,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                            title={'Ajouter un IBAN'}
                          />
                        </LinearGradient>
                      </View>
                    )}
                  </>
                  {/* Gérer mes IBAN */}
                  <>
                    {!(
                      !array_vide(
                        fetchClientData?.iban_sortie?.ibans_sortie_actifs
                      ) ||
                      !array_vide(
                        fetchClientData?.iban_sortie?.ibans_sortie_pending
                      )
                    ) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { gap: 10, padding: 20 },
                          dimensions.width
                        )}
                      >
                        {/* Titre */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['titre1_generique']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['titre1_generique']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Gérer mes IBANs'}
                        </Text>
                        {/* Notes */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['explications']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['explications']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Retrouvez ci-dessous vos IBANs enregistrés.'}
                        </Text>
                        <Table
                          borderColor={theme.colors.border.brand}
                          borderStyle={'solid'}
                          borderWidth={1}
                          cellHorizontalPadding={10}
                          cellVerticalPadding={10}
                          data={(fetchClientData?.iban_sortie?.ibans_sortie_actifs).concat(
                            fetchClientData?.iban_sortie?.ibans_sortie_pending
                          )}
                          drawBottomBorder={false}
                          drawEndBorder={false}
                          drawStartBorder={false}
                          keyExtractor={(tableData, index) =>
                            tableData?.id ??
                            tableData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(tableData)
                          }
                          listKey={'Up8mz2IJ'}
                          renderItem={({ item, index }) => {
                            const tableData = item;
                            return (
                              <>
                                {/* En-tête */}
                                <TableRow
                                  drawBottomBorder={true}
                                  drawEndBorder={false}
                                  drawTopBorder={false}
                                  borderColor={palettes.App.gris_fonce_flolend}
                                  borderWidth={2}
                                  drawStartBorder={false}
                                  isTableHeader={true}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Flolend 1'],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Labels et numéro */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        { justifyContent: 'center' }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'IBAN'}
                                    </Text>
                                  </TableCell>
                                  {/* Statuts */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        { justifyContent: 'center' }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Statut'}
                                    </Text>
                                  </TableCell>
                                  {/* Actions */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        { justifyContent: 'center' }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_tableau'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Actions'}
                                    </Text>
                                  </TableCell>
                                </TableRow>
                                {/* Corps */}
                                <TableRow
                                  drawBottomBorder={true}
                                  drawEndBorder={false}
                                  drawTopBorder={false}
                                  isTableHeader={false}
                                  borderColor={palettes.App.gris_fonce_flolend}
                                  borderWidth={1}
                                  drawStartBorder={false}
                                >
                                  {/* Label et numéro */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Label */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {tableData?.label}
                                    </Text>
                                    {/* Numéro */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'sous_elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'sous_elmt_table'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {espaces_iban(tableData?.iban)}
                                    </Text>
                                  </TableCell>
                                  {/* Statut */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'elmt_table'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'elmt_table'
                                          ].style,
                                          { textAlign: 'center' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {objet_dans_liste(
                                        fetchClientData?.iban_sortie
                                          ?.ibans_sortie_actifs,
                                        tableData
                                      )
                                        ? 'validé'
                                        : tableData?.statut === 'valide'
                                        ? 'En cours de validation'
                                        : "Preuve d'IBAN à transmettre"}
                                    </Text>
                                  </TableCell>
                                  {/* Action */}
                                  <TableCell
                                    drawBottomBorder={false}
                                    drawStartBorder={false}
                                    drawTopBorder={false}
                                    {...GlobalStyles.TableCellStyles(theme)[
                                      'Table Cell'
                                    ].props}
                                    drawEndBorder={false}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TableCellStyles(theme)[
                                          'Table Cell'
                                        ].style,
                                        {
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <Pressable
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'MoneyOut15GererMesIBANsScreen',
                                            {
                                              label_iban: tableData?.label,
                                              code_iban: tableData?.iban,
                                              statut_iban: tableData?.statut,
                                              id_iban_lemonway: tableData?.id,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            backgroundColor:
                                              palettes.App['Flolend 2'],
                                            borderRadius: 10,
                                            padding: 10,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color:
                                                  palettes.App.blanc_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: 16,
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Modifier'}
                                        </Text>
                                      </View>
                                    </Pressable>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          }}
                          showsVerticalScrollIndicator={true}
                          {...GlobalStyles.TableStyles(theme)['Table'].props}
                          drawTopBorder={false}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TableStyles(theme)['Table'].style,
                            dimensions.width
                          )}
                        />
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </BaseXanoApi.FetchAuthGET>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(MoneyOut1RecupererFondsScreen);
