import React from 'react';
import {
  Button,
  DatePicker,
  Icon,
  LinearGradient,
  MultiSelectPicker,
  Picker,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ModifierMesInfosScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [adresse, setAdresse] = React.useState('');
  const [code_postal, setCode_postal] = React.useState('');
  const [commune, setCommune] = React.useState('');
  const [communeDeNaissance, setCommuneDeNaissance] = React.useState('');
  const [dateNaissValue, setDateNaissValue] = React.useState('');
  const [dptmNaissance, setDptmNaissance] = React.useState('');
  const [indicateur_erreur, setIndicateur_erreur] = React.useState(false);
  const [mada_mons, setMada_mons] = React.useState('');
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [nationalites, setNationalites] = React.useState([]);
  const [nom, setNom] = React.useState('');
  const [num_tel, setNum_tel] = React.useState('');
  const [numero_residence, setNumero_residence] = React.useState('');
  const [pays_de_residence, setPays_de_residence] = React.useState('');
  const [prenom, setPrenom] = React.useState('');
  const [switchRowValue, setSwitchRowValue] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [pickerValue, setPickerValue] = React.useState(undefined);
  // Cette fonction associe les données tirées du GET aux variables d'écran.
  const association_variables = (
    mad_mons,
    surname,
    le_prenom,
    birthdate,
    telephone,
    numero_adresse,
    rue_adresse,
    code_postal,
    commune,
    pays_residence
  ) => {
    setMada_mons(mad_mons);
    setNom(surname);
    setPrenom(le_prenom);
    setNumero_residence(numero_adresse);
    setAdresse(rue_adresse);
    setCode_postal(code_postal);
    setCommune(commune);
    setNum_tel(telephone);
    setDateNaissValue(birthdate);
    setDateNaissValue(birthdate);

    if (pays_residence === '') {
      setPays_de_residence('FRA');
    } else {
      setPays_de_residence(pays_residence);
    }

    //Ancien code pour setup un encart de nationalité
    //if (Array.isArray(nationalites) && nationalites.length === 0 ) {
    //    setNationalites(["FRA"]);
    //} else {
    //  setNationalites(nationalites) ;
    //}
  };

  // Convertit la date récupérée auprès de Xano en une date pouvant être interprétée par le Picker
  const conversion_date_Xano = dateString => {
    // Vérifie que la chaîne est bien dans le format attendu "AAAA-MM-JJ"
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      console.error('Le format de date est invalide.');
      return '';
    }

    // Crée un objet Date à partir de la chaîne de date
    const date = new Date(dateString);

    // Retourne la date au format ISO (ISO 8601)
    return date.toISOString();
  };

  // Vérifie que tous les champs sont remplis avant de faire le PATCH
  const verification_completude = (
    prenom,
    nom,
    telephone,
    date_naissance,
    numero_residence,
    rue_residence,
    code_postal,
    civilite,
    commune_residence,
    pays_residence
  ) => {
    // Vérification de chaque paramètre
    if (!nom) {
      return "Le champ 'nom' est vide.";
    }
    if (!prenom) {
      return "Le champ 'prénom' est vide.";
    }
    if (!telephone) {
      return "Le champ 'téléphone' est vide.";
    }
    // Vérification du format du numéro de téléphone
    if (!/^(?:\+|0)[1-9][0-9]{8,}$/.test(telephone)) {
      return "Le champ 'téléphone' n'est pas au bon format.";
    }
    if (!civilite) {
      return "Le champ 'civilite' est vide.";
    }
    if (!date_naissance) {
      return "Le champ 'date de naissance' est vide.";
    }
    if (!rue_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!numero_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!code_postal) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!commune_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    if (!pays_residence) {
      return "Le champ 'adresse' est incomplet.";
    }
    // Ancien code pour contrôler les nationalités
    //if (!nationalites) {
    //  return "Le champ 'nationalités' est vide.";
    //}

    // Si aucun paramètre n'est vide et le format du numéro de téléphone est correct
    return true;
  };
  const baseXanoEditInfosClientPATCH = BaseXanoApi.useEditInfosClientPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const retour_api = (await BaseXanoApi.authGET(Constants))?.json;
        association_variables(
          retour_api?.Civilite,
          retour_api?.Nom,
          retour_api?.Prenom,
          conversion_date_Xano(retour_api?.Date_de_naissance),
          retour_api?.telephone,
          retour_api?.numero_residence,
          retour_api?.nom_rue_residence,
          retour_api?.code_postal,
          retour_api?.commune,
          retour_api?.pays_residence
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { marginBottom: 10 }
              ),
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Modifier mes informations'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Laptop, value: 0 },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: 24 },
              { minWidth: Breakpoints.Laptop, value: 0 },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: [
                {
                  minWidth: Breakpoints.Desktop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
              ],
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginTop: [
                { minWidth: Breakpoints.BigScreen, value: 10 },
                { minWidth: Breakpoints.Laptop, value: 10 },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
              width: { minWidth: Breakpoints.Laptop, value: '50%' },
            },
            dimensions.width
          )}
        >
          {/* Vue questionnaire */}
          <View
            style={StyleSheet.applyWidth(
              {
                paddingBottom: 30,
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Civilité */}
            <View>
              {/* Label 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Civilité'}
              </Text>
              {/* Madame/Monsieur */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: [
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'space-between',
                      },
                      { minWidth: Breakpoints.Mobile, value: 'space-between' },
                    ],
                    marginBottom: 24,
                    marginTop: 4,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Madame */}
                <Touchable
                  onPress={() => {
                    try {
                      setMada_mons('Madame');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { width: '45%' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                          {
                            minWidth: Breakpoints.Desktop,
                            value: palettes.App.gris_encart_int_flolend,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.gris_encart_int_flolend,
                          },
                        ],
                        borderColor:
                          mada_mons === 'Madame'
                            ? palettes.App['Flolend 4']
                            : palettes.App.gris_fonce_flolend,
                        borderRadius: 15,
                        borderWidth: [
                          { minWidth: Breakpoints.Laptop, value: 1 },
                          { minWidth: Breakpoints.Mobile, value: 1 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 55 },
                          { minWidth: Breakpoints.Desktop, value: 60 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Laptop, value: 50 },
                        ],
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.gris_fonce_flolend,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value:
                                mada_mons === 'Madame'
                                  ? palettes.App.blanc_flolend
                                  : palettes.App.gris_fonce_flolend,
                            },
                          ],
                          fontFamily: 'PTSans_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Laptop, value: 14 },
                            { minWidth: Breakpoints.Desktop, value: 18 },
                            { minWidth: Breakpoints.BigScreen, value: 22 },
                          ],
                          textTransform: 'capitalize',
                        },
                        dimensions.width
                      )}
                    >
                      {'Madame'}
                    </Text>
                  </View>
                </Touchable>
                {/* Monsieur */}
                <Touchable
                  onPress={() => {
                    try {
                      setMada_mons('Monsieur');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { width: '45%' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.gris_encart_int_flolend,
                          },
                        ],
                        borderColor:
                          mada_mons === 'Monsieur'
                            ? palettes.App['Flolend 4']
                            : palettes.App.gris_fonce_flolend,
                        borderRadius: 15,
                        borderWidth: [
                          { minWidth: Breakpoints.Laptop, value: 1 },
                          { minWidth: Breakpoints.Mobile, value: 1 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Mobile, value: 55 },
                          { minWidth: Breakpoints.Laptop, value: 50 },
                          { minWidth: Breakpoints.Desktop, value: 60 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                        ],
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.gris_fonce_flolend,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value:
                                mada_mons === 'Monsieur'
                                  ? palettes.App.blanc_flolend
                                  : palettes.App.gris_fonce_flolend,
                            },
                          ],
                          fontFamily: 'PTSans_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 18 },
                            { minWidth: Breakpoints.BigScreen, value: 22 },
                            { minWidth: Breakpoints.Laptop, value: 14 },
                          ],
                          textTransform: 'capitalize',
                        },
                        dimensions.width
                      )}
                    >
                      {'Monsieur'}
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
            {/* Prenom */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Prénom'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                      .style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* prenom */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newPrenomValue => {
                    try {
                      setPrenom(newPrenomValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Votre prénom' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                      .style,
                    dimensions.width
                  )}
                  value={prenom}
                />
              </View>
            </View>
            {/* Nom */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Nom'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                      .style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* nom */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newNomValue => {
                    try {
                      setNom(newNomValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Votre nom' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                      .style,
                    dimensions.width
                  )}
                  value={nom}
                />
              </View>
            </View>
            {/* Téléphone */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Numéro de téléphone'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                      .style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* telephone */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTelephoneValue => {
                    try {
                      setNum_tel(newTelephoneValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                    .props}
                  keyboardType={'phone-pad'}
                  placeholder={'06 35 XX XX XX' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                      .style,
                    dimensions.width
                  )}
                  value={num_tel}
                />
              </View>
            </View>
            {/* Adresse */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Adresse de résidence fiscale'}
              </Text>
              {/* Field */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                      .style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Numéro */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newNumRoValue => {
                    try {
                      setNumero_residence(newNumRoValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Numéro' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                      .style,
                    dimensions.width
                  )}
                  value={numero_residence}
                />
              </View>
              {/* Field 2 */}
              <View
                {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                      .style,
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Adresse */}
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newAdresseValue => {
                    try {
                      setAdresse(newAdresseValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                    .props}
                  keyboardType={'email-address'}
                  placeholder={'Rue, avenue, boulevard' ?? 'you@domain.tld'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                      .style,
                    dimensions.width
                  )}
                  value={adresse}
                />
              </View>
              {/* Vue horizontale */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', justifyContent: 'space-between' },
                  dimensions.width
                )}
              >
                {/* Field 3 */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                        .style,
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.gris_encart_int_flolend,
                        },
                        width: '49%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* code_postal */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newCodePostalValue => {
                      try {
                        setCode_postal(newCodePostalValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)[
                      'encart_infos_client'
                    ].props}
                    keyboardType={'email-address'}
                    placeholder={'Code postal' ?? 'you@domain.tld'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                        .style,
                      dimensions.width
                    )}
                    value={code_postal}
                  />
                </View>
                {/* Field 4 */}
                <View
                  {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                        .style,
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.gris_encart_int_flolend,
                        },
                        width: '49%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* commune */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newCommuneValue => {
                      try {
                        setCommune(newCommuneValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)[
                      'encart_infos_client'
                    ].props}
                    keyboardType={'email-address'}
                    placeholder={'Commune' ?? 'you@domain.tld'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                        .style,
                      dimensions.width
                    )}
                    value={commune}
                  />
                </View>
              </View>
              <Picker
                autoDismissKeyboard={true}
                dropDownBackgroundColor={theme.colors.background.brand}
                dropDownBorderColor={theme.colors.border.brand}
                dropDownBorderRadius={8}
                dropDownBorderWidth={1}
                dropDownTextColor={theme.colors.text.strong}
                iconSize={24}
                leftIconMode={'inset'}
                mode={'native'}
                onValueChange={newPickerValue => {
                  const pickerValue = newPickerValue;
                  try {
                    setPays_de_residence(newPickerValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                selectedIconColor={theme.colors.text.strong}
                selectedIconName={'Feather/check'}
                selectedIconSize={20}
                type={'solid'}
                {...GlobalStyles.PickerStyles(theme)[
                  'picker_test_connaissances'
                ].props}
                options={[
                  { label: 'Afghanistan', value: 'AFG' },
                  { label: 'Afrique du Sud', value: 'ZAF' },
                  { label: 'Albanie', value: 'ALB' },
                  { label: 'Algérie', value: 'DZA' },
                  { label: 'Allemagne', value: 'DEU' },
                  { label: 'Andorre', value: 'AND' },
                  { label: 'Angola', value: 'AGO' },
                  { label: 'Antigua-et-Barbuda', value: 'ATG' },
                  { label: 'Arabie saoudite', value: 'SAU' },
                  { label: 'Argentine', value: 'ARG' },
                  { label: 'Arménie', value: 'ARM' },
                  { label: 'Australie', value: 'AUS' },
                  { label: 'Autriche', value: 'AUT' },
                  { label: 'Azerbaïdjan', value: 'AZE' },
                  { label: 'Bahamas', value: 'BHS' },
                  { label: 'Bahreïn', value: 'BHR' },
                  { label: 'Bangladesh', value: 'BGD' },
                  { label: 'Barbade', value: 'BRB' },
                  { label: 'Belgique', value: 'BEL' },
                  { label: 'Belize', value: 'BLZ' },
                  { label: 'Bénin', value: 'BEN' },
                  { label: 'Bhoutan', value: 'BTN' },
                  { label: 'Biélorussie', value: 'BLR' },
                  { label: 'Birmanie', value: 'MMR' },
                  { label: 'Bolivie', value: 'BOL' },
                  { label: 'Bosnie-Herzégovine', value: 'BIH' },
                  { label: 'Botswana', value: 'BWA' },
                  { label: 'Brésil', value: 'BRA' },
                  { label: 'Brunei', value: 'BRN' },
                  { label: 'Bulgarie', value: 'BGR' },
                  { label: 'Burkina Faso', value: 'BFA' },
                  { label: 'Burundi', value: 'BDI' },
                  { label: 'Cambodge', value: 'KHM' },
                  { label: 'Cameroun', value: 'CMR' },
                  { label: 'Canada', value: 'CAN' },
                  { label: 'Cap-Vert', value: 'CPV' },
                  { label: 'République centrafricaine', value: 'CAF' },
                  { label: 'Chili', value: 'CHL' },
                  { label: 'Chine', value: 'CHN' },
                  { label: 'Chypre', value: 'CYP' },
                  { label: 'Colombie', value: 'COL' },
                  { label: 'Comores', value: 'COM' },
                  { label: 'République du Congo', value: 'COG' },
                  { label: 'République démocratique du Congo', value: 'COD' },
                  { label: 'Corée du Nord', value: 'PRK' },
                  { label: 'Corée du Sud', value: 'KOR' },
                  { label: 'Costa Rica', value: 'CRI' },
                  { label: "Côte d'Ivoire", value: 'CIV' },
                  { label: 'Croatie', value: 'HRV' },
                  { label: 'Cuba', value: 'CUB' },
                  { label: 'Danemark', value: 'DNK' },
                  { label: 'Djibouti', value: 'DJI' },
                  { label: 'Dominique', value: 'DMA' },
                  { label: 'Égypte', value: 'EGY' },
                  { label: 'Émirats arabes unis', value: 'ARE' },
                  { label: 'Équateur', value: 'ECU' },
                  { label: 'Érythrée', value: 'ERI' },
                  { label: 'Espagne', value: 'ESP' },
                  { label: 'Estonie', value: 'EST' },
                  { label: 'Eswatini', value: 'SWZ' },
                  { label: 'États-Unis', value: 'USA' },
                  { label: 'Éthiopie', value: 'ETH' },
                  { label: 'Fidji', value: 'FJI' },
                  { label: 'Finlande', value: 'FIN' },
                  { label: 'France', value: 'FRA' },
                  { label: 'Gabon', value: 'GAB' },
                  { label: 'Gambie', value: 'GMB' },
                  { label: 'Géorgie', value: 'GEO' },
                  { label: 'Ghana', value: 'GHA' },
                  { label: 'Grèce', value: 'GRC' },
                  { label: 'Grenade', value: 'GRD' },
                  { label: 'Guatemala', value: 'GTM' },
                  { label: 'Guinée', value: 'GIN' },
                  { label: 'Guinée-Bissau', value: 'GNB' },
                  { label: 'Guinée équatoriale', value: 'GNQ' },
                  { label: 'Guyana', value: 'GUY' },
                  { label: 'Haïti', value: 'HTI' },
                  { label: 'Honduras', value: 'HND' },
                  { label: 'Hongrie', value: 'HUN' },
                  { label: 'Inde', value: 'IND' },
                  { label: 'Indonésie', value: 'IDN' },
                  { label: 'Irak', value: 'IRQ' },
                  { label: 'Iran', value: 'IRN' },
                  { label: 'Irlande', value: 'IRL' },
                  { label: 'Islande', value: 'ISL' },
                  { label: 'Israël', value: 'ISR' },
                  { label: 'Italie', value: 'ITA' },
                  { label: 'Jamaïque', value: 'JAM' },
                  { label: 'Japon', value: 'JPN' },
                  { label: 'Jordanie', value: 'JOR' },
                  { label: 'Kazakhstan', value: 'KAZ' },
                  { label: 'Kenya', value: 'KEN' },
                  { label: 'Kirghizistan', value: 'KGZ' },
                  { label: 'Kiribati', value: 'KIR' },
                  { label: 'Koweït', value: 'KWT' },
                  { label: 'Laos', value: 'LAO' },
                  { label: 'Lesotho', value: 'LSO' },
                  { label: 'Lettonie', value: 'LVA' },
                  { label: 'Liban', value: 'LBN' },
                  { label: 'Liberia', value: 'LBR' },
                  { label: 'Libye', value: 'LBY' },
                  { label: 'Liechtenstein', value: 'LIE' },
                  { label: 'Lituanie', value: 'LTU' },
                  { label: 'Luxembourg', value: 'LUX' },
                  { label: 'Macédoine du Nord', value: 'MKD' },
                  { label: 'Madagascar', value: 'MDG' },
                  { label: 'Malaisie', value: 'MYS' },
                  { label: 'Malawi', value: 'MWI' },
                  { label: 'Maldives', value: 'MDV' },
                  { label: 'Mali', value: 'MLI' },
                  { label: 'Malte', value: 'MLT' },
                  { label: 'Maroc', value: 'MAR' },
                  { label: 'Îles Marshall', value: 'MHL' },
                  { label: 'Maurice', value: 'MUS' },
                  { label: 'Mauritanie', value: 'MRT' },
                  { label: 'Mexique', value: 'MEX' },
                  { label: 'Micronésie', value: 'FSM' },
                  { label: 'Moldavie', value: 'MDA' },
                  { label: 'Monaco', value: 'MCO' },
                  { label: 'Mongolie', value: 'MNG' },
                  { label: 'Monténégro', value: 'MNE' },
                  { label: 'Mozambique', value: 'MOZ' },
                  { label: 'Namibie', value: 'NAM' },
                  { label: 'Nauru', value: 'NRU' },
                  { label: 'Népal', value: 'NPL' },
                  { label: 'Nicaragua', value: 'NIC' },
                  { label: 'Niger', value: 'NER' },
                  { label: 'Nigeria', value: 'NGA' },
                  { label: 'Norvège', value: 'NOR' },
                  { label: 'Nouvelle-Zélande', value: 'NZL' },
                  { label: 'Oman', value: 'OMN' },
                  { label: 'Ouganda', value: 'UGA' },
                  { label: 'Ouzbékistan', value: 'UZB' },
                  { label: 'Pakistan', value: 'PAK' },
                  { label: 'Palaos', value: 'PLW' },
                  { label: 'Panama', value: 'PAN' },
                  { label: 'Papouasie-Nouvelle-Guinée', value: 'PNG' },
                  { label: 'Paraguay', value: 'PRY' },
                  { label: 'Pays-Bas', value: 'NLD' },
                  { label: 'Pérou', value: 'PER' },
                  { label: 'Philippines', value: 'PHL' },
                  { label: 'Pologne', value: 'POL' },
                  { label: 'Portugal', value: 'PRT' },
                  { label: 'Qatar', value: 'QAT' },
                  { label: 'Roumanie', value: 'ROU' },
                  { label: 'Royaume-Uni', value: 'GBR' },
                  { label: 'Russie', value: 'RUS' },
                  { label: 'Rwanda', value: 'RWA' },
                  { label: 'Saint-Christophe-et-Niévès', value: 'KNA' },
                  { label: 'Saint-Marin', value: 'SMR' },
                  { label: 'Saint-Vincent-et-les-Grenadines', value: 'VCT' },
                  { label: 'Sainte-Lucie', value: 'LCA' },
                  { label: 'Salomon', value: 'SLB' },
                  { label: 'Salvador', value: 'SLV' },
                  { label: 'Samoa', value: 'WSM' },
                  { label: 'São Tomé-et-Principe', value: 'STP' },
                  { label: 'Sénégal', value: 'SEN' },
                  { label: 'Serbie', value: 'SRB' },
                  { label: 'Seychelles', value: 'SYC' },
                  { label: 'Sierra Leone', value: 'SLE' },
                  { label: 'Singapour', value: 'SGP' },
                  { label: 'Slovaquie', value: 'SVK' },
                  { label: 'Slovénie', value: 'SVN' },
                  { label: 'Somalie', value: 'SOM' },
                  { label: 'Soudan', value: 'SDN' },
                  { label: 'Soudan du Sud', value: 'SSD' },
                  { label: 'Sri Lanka', value: 'LKA' },
                  { label: 'Suède', value: 'SWE' },
                  { label: 'Suisse', value: 'CHE' },
                  { label: 'Suriname', value: 'SUR' },
                  { label: 'Syrie', value: 'SYR' },
                  { label: 'Tadjikistan', value: 'TJK' },
                  { label: 'Tanzanie', value: 'TZA' },
                  { label: 'Tchad', value: 'TCD' },
                  { label: 'République tchèque', value: 'CZE' },
                  { label: 'Thaïlande', value: 'THA' },
                  { label: 'Timor oriental', value: 'TLS' },
                  { label: 'Togo', value: 'TGO' },
                  { label: 'Tonga', value: 'TON' },
                  { label: 'Trinité-et-Tobago', value: 'TTO' },
                  { label: 'Tunisie', value: 'TUN' },
                  { label: 'Turkménistan', value: 'TKM' },
                  { label: 'Turquie', value: 'TUR' },
                  { label: 'Tuvalu', value: 'TUV' },
                  { label: 'Ukraine', value: 'UKR' },
                  { label: 'Uruguay', value: 'URY' },
                  { label: 'Vanuatu', value: 'VUT' },
                  { label: 'Vatican', value: 'VAT' },
                  { label: 'Venezuela', value: 'VEN' },
                  { label: 'Viêt Nam', value: 'VNM' },
                  { label: 'Yémen', value: 'YEM' },
                  { label: 'Zambie', value: 'ZMB' },
                  { label: 'Zimbabwe', value: 'ZWE' },
                ]}
                placeholder={'Pays de résidence'}
                style={StyleSheet.applyWidth(
                  GlobalStyles.PickerStyles(theme)['picker_test_connaissances']
                    .style,
                  dimensions.width
                )}
                value={pays_de_residence}
              />
            </View>
            {/* Date de naissance */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 24 },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                  dimensions.width
                )}
              >
                {'Date de naissance'}
              </Text>
              {/* Field */}
              <View>
                {/* date_naiss */}
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  inline={false}
                  label={'Date'}
                  leftIconMode={'inset'}
                  onDateChange={newDateNaissValue => {
                    try {
                      const value8iZupqvA = newDateNaissValue;
                      setDateNaissValue(value8iZupqvA);
                      const retour = value8iZupqvA;
                      console.log(retour);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  type={'solid'}
                  date={dateNaissValue}
                  format={'dd/mm/yyyy'}
                  hideLabel={true}
                  maximumDate={'01/01/2005'}
                  minimumDate={'01/01/1933'}
                  mode={'date'}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: palettes.App.gris_encart_int_flolend,
                      borderColor: palettes.App.gris_fonce_flolend,
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'PTSans_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.BigScreen, value: 22 },
                      ],
                      marginTop: 1,
                    },
                    dimensions.width
                  )}
                />
              </View>
            </View>
            {/* Autres informations */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App.gris_encart_int_flolend,
                  borderRadius: 24,
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* Text 3 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                  dimensions.width
                )}
              >
                {
                  "Pour modifier les informations relatives à votre lieu de naissance et à votre nationalité, merci d'envoyer un courriel à contact@flolend.com."
                }
              </Text>
            </View>

            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={palettes.App['Flolend 1']}
              color2={palettes.App['Flolend 2']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                    flex: { minWidth: Breakpoints.Tablet, value: null },
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 60 },
                      { minWidth: Breakpoints.Desktop, value: 65 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                    ],
                    marginBottom: [
                      { minWidth: Breakpoints.Laptop, value: 0 },
                      { minWidth: Breakpoints.Mobile, value: 5 },
                    ],
                    marginTop: 20,
                    overflow: 'hidden',
                    width: [
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                      { minWidth: Breakpoints.Laptop, value: '75%' },
                      { minWidth: Breakpoints.Desktop, value: '60%' },
                      { minWidth: Breakpoints.BigScreen, value: '50%' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* Bouton valider */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      const completude = verification_completude(
                        prenom,
                        nom,
                        num_tel,
                        dateNaissValue,
                        numero_residence,
                        adresse,
                        code_postal,
                        mada_mons,
                        commune,
                        pays_de_residence
                      );
                      if (completude === true) {
                        const retour_api = (
                          await baseXanoEditInfosClientPATCH.mutateAsync({
                            civilite: mada_mons,
                            code_postal: code_postal,
                            commune: commune,
                            date_naissance: dateNaissValue,
                            nationalites: null,
                            nom: nom,
                            nom_rue: adresse,
                            num_residence: numero_residence,
                            pays_residence: pays_de_residence,
                            prenom: prenom,
                            telephone: num_tel,
                          })
                        )?.json;
                        navigation.navigate('InformationsDuCompteScreen');
                      } else {
                        setIndicateur_erreur(true);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                        { minWidth: Breakpoints.BigScreen, value: 24 },
                      ],
                      marginLeft: 0,
                      marginRight: 0,
                    }
                  ),
                  dimensions.width
                )}
                title={'Valider les changements'}
              />
            </LinearGradient>
            {/* Message d'erreur */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['message_erreur'].style,
                  {
                    color: indicateur_erreur
                      ? palettes.App.Internal_Hot_Red
                      : palettes.App.Transparent,
                  }
                ),
                dimensions.width
              )}
            >
              {'Veuillez remplir tous les champs.'}
            </Text>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(ModifierMesInfosScreen);
