import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ActualitesClientScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [tab_affiche_actif, setTab_affiche_actif] = React.useState(true);
  const baseXanoMajVuesPublicationPUT = BaseXanoApi.useMajVuesPublicationPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['header_classique'].style,
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Les actualités de mes projets'}
            </Text>
          </View>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Vue sélecteur */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', width: '100%' },
              dimensions.width
            )}
          >
            {/* Pressable actu */}
            <Pressable
              onPress={() => {
                try {
                  setTab_affiche_actif(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '50%' }, dimensions.width)}
            >
              {/* vue */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Flolend 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: tab_affiche_actif
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend,
                      },
                    ],
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={
                    (tab_affiche_actif
                      ? palettes.App['Flolend 4']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 4']
                  }
                  name={'MaterialCommunityIcons/newspaper-plus'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App['Flolend 4'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: tab_affiche_actif
                              ? palettes.App['Flolend 4']
                              : palettes.App.gris_fonce_flolend,
                          },
                        ],
                        fontFamily: 'Montserrat_400Regular',
                        fontSize: 13,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Affiche'}
                </Text>
              </View>
            </Pressable>
            {/* Pressable Actus */}
            <Pressable
              onPress={() => {
                try {
                  setTab_affiche_actif(false);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '50%' }, dimensions.width)}
            >
              {/* vue */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderBottomWidth: 2,
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Flolend 4'],
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: tab_affiche_actif
                          ? palettes.App.gris_fonce_flolend
                          : palettes.App['Flolend 4'],
                      },
                    ],
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={
                    (tab_affiche_actif
                      ? palettes.App.gris_fonce_flolend
                      : palettes.App['Flolend 4']) ?? palettes.App['Flolend 4']
                  }
                  name={'MaterialCommunityIcons/newspaper'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App['Flolend 4'],
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: tab_affiche_actif
                              ? palettes.App.gris_fonce_flolend
                              : palettes.App['Flolend 4'],
                          },
                        ],
                        fontFamily: 'Montserrat_400Regular',
                        fontSize: 13,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Hors affiche'}
                </Text>
              </View>
            </Pressable>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Retour */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                      .style,
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].props,
                      dimensions.width
                    )}
                    name={'AntDesign/arrowleft'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['texte_retour_ordi']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['texte_retour_ordi'].style,
                      dimensions.width
                    )}
                  >
                    {'Retour'}
                  </Text>
                </View>
              </Pressable>
            </View>
          )}
        </>
        {/* Fetch contenu */}
        <BaseXanoApi.FetchContenuClientGET
          nombre_retours_aff={0}
          nombre_retours_hors_aff={0}
          nombre_retours_ressources={1}
        >
          {({ loading, error, data, refetchContenuClient }) => {
            const fetchContenuData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Vue générale affiche */}
                <>
                  {!(
                    tab_affiche_actif || dimensions.width >= Breakpoints.Laptop
                  ) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.flolend_gris_nuance_encart,
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 24,
                          },
                          flexDirection: {
                            minWidth: Breakpoints.Laptop,
                            value: 'row',
                          },
                          justifyContent: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: '20%',
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: '20%',
                          },
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 15,
                          },
                          padding: { minWidth: Breakpoints.Laptop, value: 30 },
                        },
                        dimensions.width
                      )}
                    >
                      {/* A l'affiche */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            marginLeft: [
                              { minWidth: Breakpoints.Mobile, value: '5%' },
                              { minWidth: Breakpoints.Laptop, value: '0%' },
                            ],
                            marginRight: [
                              { minWidth: Breakpoints.Mobile, value: '5%' },
                              { minWidth: Breakpoints.Laptop, value: '0%' },
                            ],
                            marginTop: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: '0%' },
                            ],
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: '1%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '80%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {"A l'affiche"}
                            </Text>
                          )}
                        </>
                        <SimpleStyleFlatList
                          data={fetchContenuData?.actus_affiche}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'UvNmBz0L'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* List View Frame */}
                                <View>
                                  <Pressable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await baseXanoMajVuesPublicationPUT.mutateAsync(
                                              { id_actu: listData?.id }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'ActualiteScreen',
                                            { id_actu_airtable: listData?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    {/* Record Frame */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'encart_publications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {/* Left Side Frame */}
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'left_side_publicataion'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)[
                                            'left_side_publicataion'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Top Row Frame */}
                                        <View>
                                          {/* Rubik Headline Style 18/24 Bold */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_encart_publications'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Header_contenu}
                                          </Text>
                                        </View>
                                        {/* Second Row Frame */}
                                        <View>
                                          {/* Rubik Text Style 12/18 Regular */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'corps_encart_publications'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'corps_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Header2}
                                          </Text>
                                        </View>
                                        {/* Third Row Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'baseline',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Data Point */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'type_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'type_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Media}
                                          </Text>
                                          {/* Data Poiint */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'date_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'date_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Le '}
                                            {ConvertionDate(
                                              listData?.date_publication
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Right Side Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'center',
                                            },
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            justifyContent: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                            width: {
                                              minWidth: Breakpoints.Tablet,
                                              value: '30%',
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Flex for Image */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'image_liste_publications'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'image_liste_publications'
                                              ].style,
                                              {
                                                height: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 125,
                                                },
                                                width: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 125,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Image
                                            resizeMode={'cover'}
                                            source={imageSource(
                                              `${listData?.couv_actu?.url}`
                                            )}
                                            style={StyleSheet.applyWidth(
                                              {
                                                height: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 90,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '100%',
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 102,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '100%',
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </Pressable>
                                </View>
                              </>
                            );
                          }}
                          scrollEnabled={true}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            {
                              maxHeight: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                  )}
                </>
                {/* Vue générale hors affiche */}
                <>
                  {!(
                    !tab_affiche_actif || dimensions.width >= Breakpoints.Laptop
                  ) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.flolend_gris_nuance_encart,
                          },
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 24,
                          },
                          flexDirection: {
                            minWidth: Breakpoints.Laptop,
                            value: 'row',
                          },
                          justifyContent: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: '20%',
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: '20%',
                          },
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: '2%',
                          },
                          padding: { minWidth: Breakpoints.Laptop, value: 30 },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Autres publications */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            marginLeft: [
                              { minWidth: Breakpoints.Mobile, value: '5%' },
                              { minWidth: Breakpoints.Laptop, value: '0%' },
                            ],
                            marginRight: [
                              { minWidth: Breakpoints.Mobile, value: '5%' },
                              { minWidth: Breakpoints.Laptop, value: '0%' },
                            ],
                            marginTop: [
                              { minWidth: Breakpoints.Mobile, value: 15 },
                              { minWidth: Breakpoints.Laptop, value: '0%' },
                            ],
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '80%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Autres publications'}
                            </Text>
                          )}
                        </>
                        <SimpleStyleFlatList
                          data={fetchContenuData?.actus_hors_affiche}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'MszBhVlJ'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* List View Frame */}
                                <View>
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await baseXanoMajVuesPublicationPUT.mutateAsync(
                                              { id_actu: listData?.id }
                                            )
                                          )?.json;
                                          navigation.navigate(
                                            'ActualiteScreen',
                                            { id_actu_airtable: listData?.id }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    {/* Record Frame */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'encart_publications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ViewStyles(theme)[
                                            'encart_publications'
                                          ].style,
                                          { flexGrow: 0, flexShrink: 0 }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Left Side Frame */}
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'left_side_publicataion'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ViewStyles(theme)[
                                              'left_side_publicataion'
                                            ].style,
                                            {
                                              flexGrow: 0,
                                              flexShrink: 0,
                                              justifyContent: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 'space-around',
                                              },
                                              paddingBottom: 12,
                                              paddingLeft: 12,
                                              paddingRight: 12,
                                              paddingTop: 12,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* Top Row Frame */}
                                        <View>
                                          {/* Rubik Headline Style 18/24 Bold */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_encart_publications'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Header_contenu}
                                          </Text>
                                        </View>
                                        {/* Second Row Frame */}
                                        <View>
                                          {/* Rubik Text Style 12/18 Regular */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'corps_encart_publications'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'corps_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Header2}
                                          </Text>
                                        </View>
                                        {/* Third Row Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'baseline',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Data Point */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'type_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'type_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Media}
                                          </Text>
                                          {/* Data Poiint */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'date_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'date_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Le '}
                                            {ConvertionDate(
                                              listData?.date_publication
                                            )}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Right Side Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: {
                                              minWidth: Breakpoints.Tablet,
                                              value: 'center',
                                            },
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            justifyContent: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                            width: {
                                              minWidth: Breakpoints.Tablet,
                                              value: '30%',
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Flex for Image */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'image_liste_publications'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.ViewStyles(theme)[
                                                'image_liste_publications'
                                              ].style,
                                              {
                                                height: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 125,
                                                },
                                                width: {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 125,
                                                },
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          <Image
                                            resizeMode={'cover'}
                                            source={imageSource(
                                              `${listData?.couv_actu?.url}`
                                            )}
                                            style={StyleSheet.applyWidth(
                                              {
                                                height: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 90,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '100%',
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 102,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '100%',
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </Touchable>
                                </View>
                              </>
                            );
                          }}
                          showsVerticalScrollIndicator={true}
                          scrollEnabled={true}
                          showsHorizontalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            {
                              flex: 1,
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        />
                      </View>
                    </View>
                  )}
                </>
              </>
            );
          }}
        </BaseXanoApi.FetchContenuClientGET>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ActualitesClientScreen);
