import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as packages_import from '../custom-files/packages_import';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { code_iban: '', label_iban: '' };

const MoneyOut2UploadIBANScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [bool_erreur, setBool_erreur] = React.useState(false);
  const [bouton_est_actif, setBouton_est_actif] = React.useState(false);
  const [code_iban, setCode_iban] = React.useState('');
  const [fichier, setFichier] = React.useState(null);
  const [label_iban, setLabel_iban] = React.useState('');
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  // Active le bouton
  const bouton_actif = (encart1, encart2, fichier) => {
    /**
     * Vérifie si les trois paramètres ne sont ni vides, ni null, ni indéfinis.
     * @param {*} encart1 - Premier paramètre à vérifier.
     * @param {*} encart2 - Deuxième paramètre à vérifier.
     * @param {*} fichier - Troisième paramètre à vérifier.
     * @returns {boolean} - Retourne true si tous les paramètres sont non vides, false sinon.
     */
    // Fonction qui vérifie si une valeur est considérée comme "vide"
    const estNonVide = param => {
      return (
        param !== null &&
        param !== undefined &&
        param !== '' &&
        !(Array.isArray(param) && param.length === 0)
      );
    };

    // Vérifie que chacun des paramètres est non vide
    return estNonVide(encart1) && estNonVide(encart2) && estNonVide(fichier);
  };

  // Ajoute un espace tous les 4 caractères pour la chaine fournie en entrée
  const espacement_IBAN = str => {
    return str.replace(/(.{4})/g, '$1 ');
  };

  // Renvoie un objet avec success: true si le fichier est au bon format et à la bonne taille, et false sinon.
  const fichier_valide = async (fileObject, maxPages) => {
    if (fileObject === undefined) {
      return { success: false, message: '' };
    }

    const acceptedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    const fileExtension = fileObject.name.split('.').pop().toLowerCase();

    if (!acceptedExtensions.includes(fileExtension)) {
      return { success: false, message: "L'extension du fichier est invalide" };
    }

    const base64Data = fileObject.value.split(',')[1];
    const fileSizeInBytes = packages_import.decode(base64Data).byteLength;
    const fileSizeInKB = fileSizeInBytes / 1024;

    if (fileSizeInKB > 4096) {
      return {
        success: false,
        message:
          'Le fichier est trop lourd : importez un justificatif de 4MB au maximum.',
      };
    }

    if (fileExtension === 'pdf') {
      try {
        const pdfBytes = packages_import.decode(base64Data);
        const pdfDoc = await packages_import.PDFDocument.load(pdfBytes);
        const numPages = pdfDoc.getPageCount();

        if (numPages <= maxPages) {
          return { success: true, message: 'succès' };
        } else {
          return {
            success: false,
            message: `Le fichier est trop long, veuillez importer un justificatif de ${maxPages} pages ou moins.`,
          };
        }
      } catch (error) {
        console.error('Error processing PDF:', error);
        return { success: false, message: 'PDF illisible' };
      }
    } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
      try {
        const imageUri = `data:image/${fileExtension};base64,${base64Data}`;
        const imageInfo = await packages_import.Image.getSize(imageUri);

        // Assuming no page count limit for images
        return { success: true, message: 'succès' };
      } catch (error) {
        console.error('Error processing image:', error);
        return { success: false, message: 'Image invalide.' };
      }
    }

    return { success: false, message: 'Fichier illisible.' };
  };

  // Remplit les encarts si l'utilisateur arrive de l'écran "Gérer mes IBANs".
  const setup_encarts = (code_nav, label_nav) => {
    if (label_nav && code_nav) {
      setLabel_iban(label_nav);
      setCode_iban(code_nav);
    }
  };

  // Supprime les espaces de la chaine de caractères fournie en entrée
  const suppression_espaces = str => {
    return str.replace(/\s+/g, '');
  };

  // Supprime le préfixe du buffer du doc
  const supprimer_prefixe_json = jsonData => {
    // Créer une copie du JSON pour éviter de modifier l'original directement
    let modifiedJson = { ...jsonData };

    // Liste des préfixes à supprimer
    const prefixes = [
      'data:application/pdf;base64,',
      'data:image/jpeg;base64,',
      'data:image/png;base64,',
      'data:image/jpg;base64,',
      'data:text/plain;base64,',
    ];

    // Supprimer le préfixe approprié de la clé "value"
    for (let prefix of prefixes) {
      if (modifiedJson.value.startsWith(prefix)) {
        modifiedJson.value = modifiedJson.value.substring(prefix.length);
        break; // Quitter la boucle dès qu'un préfixe est supprimé
      }
    }

    return modifiedJson;
  };
  const baseXanoUploadIbanSortiePOST = BaseXanoApi.useUploadIbanSortiePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
      setup_encarts(
        props.route?.params?.code_iban ?? defaultProps.code_iban,
        props.route?.params?.label_iban ?? defaultProps.label_iban
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            justifyContent: 'space-between',
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
            width: '100%',
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* mon_header */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
              <View
                {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header_classique'].style,
                  dimensions.width
                )}
              >
                {/* Back btn */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        height: 44,
                        justifyContent: 'center',
                        width: 44,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App.gris_fonce_flolend}
                      name={'AntDesign/arrowleft'}
                      size={18}
                    />
                  </View>
                </Touchable>
                {/* Screen Heading */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['screen_heading'].style,
                    dimensions.width
                  )}
                >
                  {'Ajouter un IBAN'}
                </Text>
              </View>
            )}
          </>
          {/* vue inférieure */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                gap: 10,
                marginLeft: 20,
                marginRight: 20,
                maxHeight: dimensions.height,
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* IBAN */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Flolend 2'],
                  borderRadius: 12,
                  height: 220,
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 24,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.Brand.Surface,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Votre IBAN'}
                </Text>
                <Icon
                  color={theme.colors.background.brand}
                  name={'Ionicons/person-circle'}
                  size={30}
                />
              </View>

              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.Brand.Surface,
                      fontFamily: 'Inter_500Medium',
                    },
                    dimensions.width
                  )}
                >
                  {code_iban === ''
                    ? "Votre n° d'IBAN"
                    : espacement_IBAN(suppression_espaces(code_iban))}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.Brand.Surface,
                        fontFamily: 'Inter_700Bold',
                        fontSize: 24,
                        lineHeight: 26,
                        marginLeft: 4,
                      },
                      dimensions.width
                    )}
                  >
                    {label_iban === '' ? 'Etiquette de votre IBAN' : label_iban}
                  </Text>
                </View>
              </View>
            </View>
            {/* Entrées */}
            <View style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}>
              {/* code_iban */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newCodeIbanValue => {
                  const textInputValue = newCodeIbanValue;
                  try {
                    const valuewikK3TbM = newCodeIbanValue;
                    setCode_iban(valuewikK3TbM);
                    const nouveau_code = valuewikK3TbM;
                    const bouton_activation = bouton_actif(
                      nouveau_code,
                      label_iban,
                      fichier
                    );
                    setBouton_est_actif(bouton_activation);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['encart_ecran_iban']
                  .props}
                placeholder={"Veuillez entrer votre n° d'IBAN ici"}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['encart_ecran_iban']
                    .style,
                  dimensions.width
                )}
                value={code_iban}
              />
              {/* nom_iban */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newNomIbanValue => {
                  const textInputValue = newNomIbanValue;
                  try {
                    const valueLWTwK2ae = newNomIbanValue;
                    setLabel_iban(valueLWTwK2ae);
                    const nouveau_label = valueLWTwK2ae;
                    const activation_bouton = bouton_actif(
                      code_iban,
                      nouveau_label,
                      fichier
                    );
                    setBouton_est_actif(activation_bouton);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['encart_ecran_iban']
                  .props}
                placeholder={"Etiquette à associer à l'IBAN"}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['encart_ecran_iban']
                    .style,
                  dimensions.width
                )}
                value={label_iban}
              />
            </View>
            {/* Message d'erreur */}
            <>
              {!bool_erreur ? null : (
                <View>
                  {/* Message erreur */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['message_erreur'].style,
                      dimensions.width
                    )}
                  >
                    {message_erreur}
                  </Text>
                </View>
              )}
            </>
            {/* Encart téléchargement */}
            <View
              style={StyleSheet.applyWidth(
                { gap: 10, width: '100%' },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['explications'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['explications'].style,
                  dimensions.width
                )}
              >
                {
                  "Chargez votre relevé d'identité bancaire ou toute autre preuve d'IBAN aux formats pdf, jpg, jpeg ou png."
                }
              </Text>
              <>
                {!(fichier === null) ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setBool_erreur(false);
                          const fichier = await selectFileUtil({
                            returnNameAndValue: true,
                            multiple: false,
                            outputBase64: true,
                          });
                          const fichier_est_valide = await fichier_valide(
                            fichier,
                            2
                          );
                          if (fichier_est_valide?.success) {
                            const valuepSlOSMJa = fichier;
                            setFichier(valuepSlOSMJa);
                            const le_fichier = valuepSlOSMJa;
                            const afficher_bouton = bouton_actif(
                              code_iban,
                              label_iban,
                              le_fichier
                            );
                            setBouton_est_actif(afficher_bouton);
                          } else {
                            setMessage_erreur(fichier_est_valide?.message);
                            setBool_erreur(true);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          borderColor: palettes.App.blanc_flolend,
                          borderRadius: [
                            { minWidth: Breakpoints.Mobile, value: 10 },
                            { minWidth: Breakpoints.Laptop, value: null },
                          ],
                          borderStyle: 'dashed',
                          borderWidth: 2,
                          color: palettes.App.blanc_flolend,
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                            { minWidth: Breakpoints.Mobile, value: 60 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                    title={"Preuve d'IBAN"}
                  />
                )}
              </>
              {/* Document chargé */}
              <>
                {fichier === null ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: 10,
                        justifyContent: 'space-around',
                        paddingLeft: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App['Flolend 4']}
                      name={'FontAwesome/check'}
                      size={18}
                    />
                    {/* Text 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                        dimensions.width
                      )}
                    >
                      {'Document chargé'}
                    </Text>
                    {/* annuler */}
                    <Pressable
                      onPress={() => {
                        try {
                          setFichier(null);
                          setBouton_est_actif(false);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Entypo/cross'}
                        size={18}
                      />
                    </Pressable>
                  </View>
                )}
              </>
            </View>
          </View>
          {/* Charger mon IBAN */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '50%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Chargement en cours 2 */}
            <>
              {!affichage_chargement ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: 10,
                      paddingLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    color={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                      dimensions.width
                    )}
                  >
                    {'Chargement en cours, veuillez patienter.'}
                  </Text>
                </View>
              )}
            </>
            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton 2']
                .props}
              color1={
                !bouton_est_actif
                  ? palettes.App.Internal_Light_Gray
                  : palettes.App['Flolend 1']
              }
              color2={
                !bouton_est_actif
                  ? palettes.App.Internal_Light_Gray
                  : palettes.App['Flolend 2']
              }
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton 2']
                    .style,
                  { width: { minWidth: Breakpoints.Laptop, value: '100%' } }
                ),
                dimensions.width
              )}
            >
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  const handler = async () => {
                    try {
                      if (
                        code_iban === '' ||
                        label_iban === '' ||
                        fichier === null
                      ) {
                        return;
                      }
                      setAffichage_chargement(true);
                      const retour_api = (
                        await baseXanoUploadIbanSortiePOST.mutateAsync({
                          code_iban: suppression_espaces(code_iban),
                          fichier: supprimer_prefixe_json(fichier),
                          label: label_iban,
                        })
                      )?.json;
                      if (!retour_api?.success) {
                        setMessage_erreur(retour_api);
                      }
                      if (!retour_api?.success) {
                        setBool_erreur(true);
                      }
                      if (!retour_api?.success) {
                        setAffichage_chargement(false);
                      }
                      if (retour_api?.success) {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('MoneyOut3IBANTransmisScreen');
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      borderRadius: [
                        { minWidth: Breakpoints.Laptop, value: null },
                        { minWidth: Breakpoints.Mobile, value: null },
                      ],
                      height: [
                        { minWidth: Breakpoints.Desktop, value: 60 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={'Charger mon IBAN'}
              />
            </LinearGradient>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(MoneyOut2UploadIBANScreen);
