import React from 'react';
import { MapMarker, MapView } from '@draftbit/maps';
import {
  AccordionGroup,
  Button,
  Checkbox,
  CircleImage,
  CircularProgress,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  Link,
  NumberInput,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  Switch,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Platform,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import ConvertionDate from '../global-functions/ConvertionDate';
import Convertion_pourcentage from '../global-functions/Convertion_pourcentage';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_transfere: 4 };

const ProjetScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_bandeau_kyc_mail, setAffichage_bandeau_kyc_mail] =
    React.useState(false);
  const [bool_dev_avis_analyste, setBool_dev_avis_analyste] =
    React.useState(false);
  const [bool_dev_esg, setBool_dev_esg] = React.useState(false);
  const [bool_dev_garanties, setBool_dev_garanties] = React.useState(false);
  const [bool_dev_porteur, setBool_dev_porteur] = React.useState(false);
  const [bool_dev_projets, setBool_dev_projets] = React.useState(false);
  const [dossier_investisseur, setDossier_investisseur] = React.useState({});
  const [simulation_projet, setSimulation_projet] = React.useState(1000);
  const [tab_financier_actif, setTab_financier_actif] = React.useState(true);
  // Calcule le montant simulé en excluant les valeurs impossibles (montant = nb titres x valeur 1 titre.
  const maSimulation = (montant_investi, valeur1titre) => {
    // Renvoie 0 si l'input est en NaN
    if (isNaN(montant_investi)) {
      return 0;
    }

    // Calcule l'arrondi à l'entier inférieur du montant que veut investir le client divisé par la valeur d'un titre
    const arrondi = Math.floor(montant_investi / valeur1titre);

    // Calcule le produit de l'arrondi et de la valeur d'un titre
    const produit = arrondi * valeur1titre;

    return produit;
  };

  // Calcule le nombre de titres souscrits en fonction du montant entré en simulation.
  const nbTitres = (montant_invest, valeurtitre) => {
    // Renvoie 0 si le montant est NaN
    if (isNaN(montant_invest)) {
      return 0;
    }

    // Calcule l'arrondi à l'entier inférieur du montant que veut investir le client divisé par la valeur d'un titre
    const arrondi = Math.floor(montant_invest / valeurtitre);

    return arrondi;
  };

  // Renvoie VRAI si le dossier investisseur est incomplet ou si l'email du client n'est pas renseigné
  const pret_a_investir = (email, dossier) => {
    // Retourner false si email ou dossier est falsy
    if (!email || !dossier) {
      return false;
    }

    // Retourner true si les deux sont présents
    return true;
  };

  // Tronque le texte au nombre de mots spécifié en entrée.
  const tronque_texte = (texte, mots) => {
    // Séparer le texte en mots en utilisant les espaces comme séparateurs
    let motsTexte = texte.split(' ');

    // Vérifier si le nombre de mots demandé est supérieur au nombre de mots dans le texte
    if (mots >= motsTexte.length) {
      return texte; // Retourner le texte original s'il n'y a pas besoin de tronquer
    }

    // Récupérer les mots demandés
    let texteTronque = motsTexte.slice(0, mots).join(' ') + ' (...)';

    return texteTronque;
  };

  // Vérifie que le statut est différent de En cours et de pré-collecte.
  const verifier_statut = statut => {
    return statut !== 'En cours' && statut !== 'Pré-collecte';
  };
  const baseXanoMajEcheancierClientPUT =
    BaseXanoApi.useMajEcheancierClientPUT();
  const baseXanoMajVuesPublicationPUT = BaseXanoApi.useMajVuesPublicationPUT();
  const baseXanoMajLikesPUT = BaseXanoApi.useMajLikesPUT();
  const baseXanoMajVuesRessourcePUT = BaseXanoApi.useMajVuesRessourcePUT();
  const baseXanoMajLikesRessourcesPUT = BaseXanoApi.useMajLikesRessourcesPUT();
  const baseXanoMajBookmarkedPUT = BaseXanoApi.useMajBookmarkedPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const retour_api = (await BaseXanoApi.dossierInvestGET(Constants))
          ?.json;
        if (
          !pret_a_investir(
            retour_api?.verification_email,
            retour_api?.dossier_invest_complet
          )
        ) {
          setAffichage_bandeau_kyc_mail(true);
        }
        setDossier_investisseur(retour_api);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Bouton retour */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { left: 20, position: 'absolute', top: 20, zIndex: 99 },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', justifyContent: 'center' },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'Ionicons/arrow-back-outline'}
                  size={30}
                />
              </View>
            </Touchable>
          </View>
        )}
      </>
      {/* Bandeau KYC_et_mail */}
      <>
        {!affichage_bandeau_kyc_mail ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App['Flolend 2'],
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
                opacity: 0.8,
                padding: 10,
                position: 'absolute',
                top: [
                  { minWidth: Breakpoints.Mobile, value: 60 },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
                width: '100%',
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                dimensions.width
              )}
            >
              {!dossier_investisseur?.verification_email
                ? 'Validez votre email pour investir.'
                : 'Complétez votre KYC pour investir'}
            </Text>

            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              {...GlobalStyles.LinearGradientStyles(theme)[
                'gradient_bouton_bleu'
              ].props}
              color1={palettes.App['Flolend 1']}
              color2={palettes.App['Flolend 1']}
              startY={0}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton_bleu'
                  ].style,
                  {
                    borderRadius: [
                      { minWidth: Breakpoints.Laptop, value: 15 },
                      { minWidth: Breakpoints.Mobile, value: 10 },
                    ],
                    flex: null,
                    height: [
                      { minWidth: Breakpoints.Desktop, value: 60 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                    ],
                    marginTop: 0,
                    overflow: { minWidth: Breakpoints.Laptop, value: 'hidden' },
                    width: [
                      { minWidth: Breakpoints.Mobile, value: '30%' },
                      { minWidth: Breakpoints.Laptop, value: '20%' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* navigation */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (dossier_investisseur?.verification_email) {
                      navigation.navigate('DossierInvestisseurScreen');
                    } else {
                      navigation.navigate('Inscription3Screen');
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      borderRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: null,
                      },
                      fontFamily: 'PTSans_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 16 },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                        { minWidth: Breakpoints.Desktop, value: 24 },
                        { minWidth: Breakpoints.BigScreen, value: 28 },
                      ],
                      marginBottom: 0,
                      marginTop: 0,
                    }
                  ),
                  dimensions.width
                )}
                title={`${
                  !dossier_investisseur?.verification_email
                    ? 'Valider email'
                    : 'KYC'
                }`}
              />
            </LinearGradient>
          </View>
        )}
      </>
      {/* Fetch projets */}
      <BaseXanoApi.FetchProjetParIdGET
        id_projet_transfere={
          props.route?.params?.id_transfere ?? defaultProps.id_transfere
        }
      >
        {({ loading, error, data, refetchProjetParId }) => {
          const fetchProjetsData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* selecteur_tab_desktop */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        bottom: { minWidth: Breakpoints.Laptop, value: '3%' },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-between',
                        },
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '3%',
                        },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '3%',
                        },
                        position: {
                          minWidth: Breakpoints.Laptop,
                          value: 'absolute',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                        zIndex: { minWidth: Breakpoints.Laptop, value: 99 },
                      },
                      dimensions.width
                    )}
                  >
                    <Surface
                      {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                      elevation={3}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.gris_encart_int_flolend,
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 24,
                            },
                            flexDirection: {
                              minWidth: Breakpoints.Laptop,
                              value: 'row',
                            },
                            gap: { minWidth: Breakpoints.Laptop, value: 10 },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                            paddingBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                            paddingTop: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Financier sélectionné */}
                      <>
                        {!tab_financier_actif ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['selecteur_on']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['selecteur_on']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Financier'}
                          </Text>
                        )}
                      </>
                      {/* Financier non sélectionné */}
                      <>
                        {tab_financier_actif ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['selecteur_off']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['selecteur_off']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Financier'}
                          </Text>
                        )}
                      </>
                      <Switch
                        onValueChange={newSwitchValue => {
                          try {
                            setTab_financier_actif(!tab_financier_actif);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.SwitchStyles(theme)['switch_tabs']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.SwitchStyles(theme)['switch_tabs'].style,
                          dimensions.width
                        )}
                        value={!tab_financier_actif}
                      />
                      {/* Actualités sélectionné */}
                      <>
                        {tab_financier_actif ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['selecteur_on']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['selecteur_on']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Actualités'}
                          </Text>
                        )}
                      </>
                      {/* Actualités non sélectionné */}
                      <>
                        {!tab_financier_actif ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['selecteur_off']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['selecteur_off']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Actualités'}
                          </Text>
                        )}
                      </>
                    </Surface>
                    {/* bouton investir ordinateur */}
                    <View>
                      {/* vue */}
                      <>
                        {!verifier_statut(!fetchProjetsData?.Statut) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                paddingRight: 15,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Bouton cond. Investir */}
                            <>
                              {!(
                                fetchProjetsData?.Statut === 'En cours'
                              ) ? null : (
                                <View>
                                  {/* Button Outline */}
                                  <Button
                                    accessible={true}
                                    iconPosition={'left'}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          const retour_api = (
                                            await BaseXanoApi.dossierInvestGET(
                                              Constants
                                            )
                                          )?.json;
                                          if (retour_api?.verification_email) {
                                            if (
                                              retour_api?.dossier_invest_complet
                                            ) {
                                              navigation.navigate(
                                                'Souscription1Screen',
                                                {
                                                  id_transfert:
                                                    props.route?.params
                                                      ?.id_transfere ??
                                                    defaultProps.id_transfere,
                                                }
                                              );
                                            } else {
                                              navigation.navigate(
                                                'DossierInvestisseurScreen'
                                              );
                                            }
                                          } else {
                                            navigation.navigate(
                                              'Inscription3Screen'
                                            );
                                          }
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App
                                            .flolend_gris_nuance_encart,
                                        borderColor: palettes.App['Flolend 4'],
                                        borderRadius: 8,
                                        borderWidth: 2,
                                        color: palettes.App.blanc_flolend,
                                        fontFamily: 'Montserrat_700Bold',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 24,
                                          },
                                        ],
                                        padding: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 15,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 20,
                                          },
                                        ],
                                        textAlign: 'center',
                                        width: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 150,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 175,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 100,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                    title={'Investir'}
                                  />
                                </View>
                              )}
                            </>
                            {/* Bouton cond. pré-souscrire */}
                            <>
                              {!(
                                fetchProjetsData?.Statut === 'Pré-collecte'
                              ) ? null : (
                                <View>
                                  {/* Button Outline */}
                                  <Button
                                    accessible={true}
                                    iconPosition={'left'}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          const retour_api = (
                                            await BaseXanoApi.dossierInvestGET(
                                              Constants
                                            )
                                          )?.json;
                                          if (retour_api?.verification_email) {
                                            if (
                                              retour_api?.dossier_invest_complet
                                            ) {
                                              navigation.navigate(
                                                'Souscription1Screen',
                                                {
                                                  id_transfert:
                                                    props.route?.params
                                                      ?.id_transfere ??
                                                    defaultProps.id_transfere,
                                                }
                                              );
                                            } else {
                                              navigation.navigate(
                                                'DossierInvestisseurScreen'
                                              );
                                            }
                                          } else {
                                            navigation.navigate(
                                              'Inscription3Screen'
                                            );
                                          }
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App
                                            .flolend_gris_nuance_encart,
                                        borderColor: palettes.App['Flolend 4'],
                                        borderRadius: 8,
                                        borderWidth: 2,
                                        color: palettes.App.blanc_flolend,
                                        fontFamily: 'Montserrat_700Bold',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 24,
                                          },
                                        ],
                                        padding: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 15,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 20,
                                          },
                                        ],
                                        textAlign: 'center',
                                        width: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 175,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 225,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 100,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 150,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                    title={'Pré-souscrire'}
                                  />
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
              {/* Bandeau investir mobile */}
              <>
                {!(
                  verifier_statut(!fetchProjetsData?.Statut) &&
                  !(dimensions.width >= Breakpoints.Laptop)
                ) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        bottom: 20,
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        paddingRight: 15,
                        position: 'absolute',
                        width: '100%',
                        zIndex: 99,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Bouton cond. Investir */}
                    <>
                      {!(fetchProjetsData?.Statut === 'En cours') ? null : (
                        <View>
                          {/* Button Outline */}
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const retour_api = (
                                    await BaseXanoApi.dossierInvestGET(
                                      Constants
                                    )
                                  )?.json;
                                  if (retour_api?.verification_email) {
                                    if (retour_api?.dossier_invest_complet) {
                                      navigation.navigate(
                                        'Souscription1Screen',
                                        {
                                          id_transfert:
                                            props.route?.params?.id_transfere ??
                                            defaultProps.id_transfere,
                                        }
                                      );
                                    } else {
                                      navigation.navigate(
                                        'DossierInvestisseurScreen'
                                      );
                                    }
                                  } else {
                                    navigation.navigate('Inscription3Screen');
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App.flolend_gris_nuance_encart,
                                borderColor: palettes.App['Flolend 4'],
                                borderRadius: 8,
                                borderWidth: 2,
                                color: palettes.App.blanc_flolend,
                                fontFamily: 'Montserrat_700Bold',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 16 },
                                  { minWidth: Breakpoints.Desktop, value: 20 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                ],
                                textAlign: 'center',
                                width: 100,
                              },
                              dimensions.width
                            )}
                            title={'Investir'}
                          />
                        </View>
                      )}
                    </>
                    {/* Bouton cond. pré-souscrire */}
                    <>
                      {!(fetchProjetsData?.Statut === 'Pré-collecte') ? null : (
                        <View>
                          {/* Button Outline */}
                          <Button
                            accessible={true}
                            iconPosition={'left'}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const retour_api = (
                                    await BaseXanoApi.dossierInvestGET(
                                      Constants
                                    )
                                  )?.json;
                                  if (retour_api?.verification_email) {
                                    if (retour_api?.dossier_invest_complet) {
                                      navigation.navigate(
                                        'Souscription1Screen',
                                        {
                                          id_transfert:
                                            props.route?.params?.id_transfere ??
                                            defaultProps.id_transfere,
                                        }
                                      );
                                    } else {
                                      navigation.navigate(
                                        'DossierInvestisseurScreen'
                                      );
                                    }
                                  } else {
                                    navigation.navigate('Inscription3Screen');
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor:
                                  palettes.App.flolend_gris_nuance_encart,
                                borderColor: palettes.App['Flolend 4'],
                                borderRadius: 8,
                                borderWidth: 2,
                                color: palettes.App.blanc_flolend,
                                fontFamily: 'Montserrat_700Bold',
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 16 },
                                  { minWidth: Breakpoints.Desktop, value: 20 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                ],
                                textAlign: 'center',
                                width: 100,
                              },
                              dimensions.width
                            )}
                            title={'Pré-souscrire'}
                          />
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
              <SimpleStyleScrollView
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                bounces={false}
                scrollEnabled={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={StyleSheet.applyWidth(
                  {
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                    paddingTop: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: Constants['hauteur_navbar_laptop'],
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: Constants['hauteur_navbar_desktop'],
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: Constants['hauteur_navbar_big_screen'],
                      },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Partie supérieure */}
                <View>
                  {/* Section supérieure */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 250 },
                          { minWidth: Breakpoints.Mobile, value: 420 },
                          { minWidth: Breakpoints.Desktop, value: 300 },
                          { minWidth: Breakpoints.BigScreen, value: 350 },
                        ],
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <ImageBackground
                      resizeMode={'cover'}
                      source={imageSource(
                        `${fetchProjetsData?.Image_couverture?.url}`
                      )}
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: {
                            minWidth: Breakpoints.Laptop,
                            value: 'flex-end',
                          },
                          height: '100%',
                          justifyContent: 'flex-end',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Bandeau investir */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingBottom: 24,
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 24,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['Flolend 1'],
                              borderRadius: 8,
                              flexDirection: 'row',
                              gap: { minWidth: Breakpoints.Laptop, value: 10 },
                              minHeight: 40,
                              paddingBottom: 6,
                              paddingLeft: 12,
                              paddingRight: 8,
                              paddingTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Type produit */}
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1 },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'Montserrat_500Medium',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 20,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 24,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {fetchProjetsData?.Type_produit}
                            </Text>
                          </View>
                          {/* Bouton cond. Investir */}
                          <>
                            {!(
                              fetchProjetsData?.Statut === 'En cours'
                            ) ? null : (
                              <View>
                                {/* Button Outline */}
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        const retour_api = (
                                          await BaseXanoApi.dossierInvestGET(
                                            Constants
                                          )
                                        )?.json;
                                        if (retour_api?.verification_email) {
                                          if (
                                            retour_api?.dossier_invest_complet
                                          ) {
                                            navigation.navigate(
                                              'Souscription1Screen',
                                              {
                                                id_transfert:
                                                  props.route?.params
                                                    ?.id_transfere ??
                                                  defaultProps.id_transfere,
                                              }
                                            );
                                          } else {
                                            navigation.navigate(
                                              'DossierInvestisseurScreen'
                                            );
                                          }
                                        } else {
                                          navigation.navigate(
                                            'Inscription3Screen'
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: 'rgba(0, 0, 0, 0)',
                                      borderBottomWidth: 2,
                                      borderColor: palettes.App['Flolend 4'],
                                      borderLeftWidth: 2,
                                      borderRadius: 8,
                                      borderRightWidth: 2,
                                      borderTopWidth: 2,
                                      borderWidth: 1,
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 24,
                                        },
                                      ],
                                      textAlign: 'center',
                                    },
                                    dimensions.width
                                  )}
                                  title={'Investir'}
                                />
                              </View>
                            )}
                          </>
                          {/* Bouton cond. pré-souscrire */}
                          <>
                            {!(
                              fetchProjetsData?.Statut === 'Pré-collecte'
                            ) ? null : (
                              <View>
                                {/* Button Outline */}
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        const retour_api = (
                                          await BaseXanoApi.dossierInvestGET(
                                            Constants
                                          )
                                        )?.json;
                                        if (retour_api?.verification_email) {
                                          if (
                                            retour_api?.dossier_invest_complet
                                          ) {
                                            navigation.navigate(
                                              'Souscription1Screen',
                                              {
                                                id_transfert:
                                                  props.route?.params
                                                    ?.id_transfere ??
                                                  defaultProps.id_transfere,
                                              }
                                            );
                                          } else {
                                            navigation.navigate(
                                              'DossierInvestisseurScreen'
                                            );
                                          }
                                        } else {
                                          navigation.navigate(
                                            'Inscription3Screen'
                                          );
                                        }
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor: 'rgba(0, 0, 0, 0)',
                                      borderBottomWidth: 2,
                                      borderColor: palettes.App['Flolend 4'],
                                      borderLeftWidth: 2,
                                      borderRadius: 8,
                                      borderRightWidth: 2,
                                      borderTopWidth: 2,
                                      borderWidth: 1,
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 24,
                                        },
                                      ],
                                      textAlign: 'center',
                                    },
                                    dimensions.width
                                  )}
                                  title={'Pré-souscrire'}
                                />
                              </View>
                            )}
                          </>
                          {/* Mention conditionnelle autre */}
                          <>
                            {!verifier_statut(
                              fetchProjetsData?.Statut
                            ) ? null : (
                              <View>
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App.blanc_flolend,
                                        fontFamily:
                                          'Montserrat_400Regular_Italic',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 24,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {fetchProjetsData?.Statut}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                    </ImageBackground>
                  </View>
                  {/* Section inférieure */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        paddingBottom: 10,
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingTop: 24,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Descriptif 1 ligne */}
                    <Text
                      accessible={true}
                      selectable={false}
                      ellipsizeMode={'tail'}
                      numberOfLines={1}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App.gris_fonce_flolend,
                          fontFamily: 'PTSans_400Regular',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 14 },
                            { minWidth: Breakpoints.Laptop, value: 16 },
                            { minWidth: Breakpoints.Desktop, value: 20 },
                            { minWidth: Breakpoints.BigScreen, value: 24 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {fetchProjetsData?.Descriptif_1_ligne}
                    </Text>
                    {/* Nom projet */}
                    <Text
                      accessible={true}
                      selectable={false}
                      ellipsizeMode={'tail'}
                      numberOfLines={2}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App.blanc_flolend,
                          fontFamily: 'Montserrat_600SemiBold',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 22 },
                            { minWidth: Breakpoints.Laptop, value: 26 },
                            { minWidth: Breakpoints.Desktop, value: 30 },
                            { minWidth: Breakpoints.BigScreen, value: 34 },
                          ],
                        },
                        dimensions.width
                      )}
                      textBreakStrategy={'highQuality'}
                    >
                      {fetchProjetsData?.Name}
                    </Text>
                    <Spacer bottom={8} left={8} right={8} top={8} />
                    {/* Métriques */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          height: { minWidth: Breakpoints.Laptop, value: 75 },
                          justifyContent: 'space-between',
                          paddingLeft: {
                            minWidth: Breakpoints.Desktop,
                            value: '10%',
                          },
                          paddingRight: {
                            minWidth: Breakpoints.Desktop,
                            value: '10%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Montant recherché */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor:
                              palettes.App.flolend_gris_nuance_encart,
                            borderColor: palettes.App['Flolend 2'],
                            borderRadius: 8,
                            borderWidth: 1,
                            flexDirection: 'row',
                            width: '30%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue icones */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '35%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icone */}
                          <Icon
                            color={palettes.App['Flolend 2']}
                            name={'Feather/target'}
                            size={
                              !(dimensions.width >= Breakpoints.Laptop)
                                ? 25
                                : !(dimensions.width >= Breakpoints.Desktop)
                                ? 35
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 45
                                : 40
                            }
                          />
                        </View>
                        {/* Vue texte */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'titre_encart_metrique_clef_projet'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'titre_encart_metrique_clef_projet'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Montant levé'}
                          </Text>
                          {/* Text 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'metrique_clef_encart_projet'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'metrique_clef_encart_projet'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchProjetsData?.Montant_a_collecter_euros >=
                            1000000
                              ? format_nombre(
                                  fetchProjetsData?.Montant_a_collecter_euros /
                                    1000000,
                                  2
                                )
                              : format_nombre(
                                  fetchProjetsData?.Montant_a_collecter_euros /
                                    1000,
                                  0
                                )}{' '}
                            {fetchProjetsData?.Montant_a_collecter_euros >=
                            1000000
                              ? 'M'
                              : 'k'}
                            {'€'}
                          </Text>
                        </View>
                      </View>
                      {/* Durée */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor:
                              palettes.App.flolend_gris_nuance_encart,
                            borderColor: palettes.App['Flolend 2'],
                            borderRadius: 8,
                            borderWidth: 1,
                            flexDirection: 'row',
                            width: '30%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue icone */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '35%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icone */}
                          <Icon
                            color={palettes.App['Flolend 2']}
                            name={'Feather/clock'}
                            size={
                              !(dimensions.width >= Breakpoints.Laptop)
                                ? 25
                                : !(dimensions.width >= Breakpoints.Desktop)
                                ? 35
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 45
                                : 40
                            }
                          />
                        </View>
                        {/* Vue texte */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          {/* Maturité */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'titre_encart_metrique_clef_projet'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'titre_encart_metrique_clef_projet'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Durée totale'}
                          </Text>
                          {/* Maturité 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'metrique_clef_encart_projet'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'metrique_clef_encart_projet'
                                ].style,
                                {
                                  fontSize: [
                                    { minWidth: Breakpoints.Laptop, value: 18 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 26,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchProjetsData?.Duree}
                          </Text>
                        </View>
                      </View>
                      {/* Rendement */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor:
                              palettes.App.flolend_gris_nuance_encart,
                            borderColor: palettes.App['Flolend 2'],
                            borderRadius: 8,
                            borderWidth: 1,
                            flexDirection: 'row',
                            width: '30%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue icones */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '35%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icone */}
                          <Icon
                            color={palettes.App['Flolend 2']}
                            name={'MaterialCommunityIcons/cash-multiple'}
                            size={
                              !(dimensions.width >= Breakpoints.Laptop)
                                ? 25
                                : !(dimensions.width >= Breakpoints.Desktop)
                                ? 35
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 45
                                : 40
                            }
                          />
                        </View>
                        {/* Vue texte */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '65%' },
                            dimensions.width
                          )}
                        >
                          {/* Rendement */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'titre_encart_metrique_clef_projet'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'titre_encart_metrique_clef_projet'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Retour annuel'}
                          </Text>
                          {/* Rendement 2 */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'metrique_clef_encart_projet'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'metrique_clef_encart_projet'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {fetchProjetsData?.Taux}
                            {'%'}
                          </Text>
                        </View>
                      </View>
                    </View>
                    {/* Spacer 2 */}
                    <Spacer bottom={8} left={8} right={8} top={8} />
                    {/* Progression */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          gap: 10,
                          maxWidth: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <LinearProgress
                        animationDuration={500}
                        indeterminate={false}
                        isAnimated={true}
                        lineCap={'round'}
                        showTrack={true}
                        trackLineCap={'round'}
                        color={palettes.App['Flolend 4']}
                        style={StyleSheet.applyWidth(
                          {
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '80%' },
                              { minWidth: Breakpoints.Tablet, value: '90%' },
                            ],
                          },
                          dimensions.width
                        )}
                        thickness={15}
                        trackColor={palettes.App.gris_fonce_flolend}
                        value={Convertion_pourcentage(
                          fetchProjetsData?.Part_collectee
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.gris_fonce_flolend,
                              fontFamily: 'Montserrat_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 10 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Desktop, value: 18 },
                                { minWidth: Breakpoints.BigScreen, value: 22 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {Convertion_pourcentage(
                          fetchProjetsData?.Part_collectee
                        )}
                        {'% collectés'}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* Bandeau conditionnel levées locales */}
                <>
                  {!fetchProjetsData?.levee_locale ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { paddingLeft: 20, paddingRight: 20 },
                        dimensions.width
                      )}
                    >
                      {/* message levée locale */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['explications']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['explications']
                              .style,
                            { fontFamily: 'PTSans_700Bold_Italic' }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchProjetsData?.descriptif_levee_locale}
                      </Text>
                    </View>
                  )}
                </>
                {/* Vue en onglets */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginBottom: 30,
                      marginLeft: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: '10%' },
                      ],
                      marginRight: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: '10%' },
                      ],
                      paddingBottom: [
                        { minWidth: Breakpoints.Mobile, value: 50 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Vue sélecteur */}
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row', width: '100%' },
                          dimensions.width
                        )}
                      >
                        {/* Pressable Financier */}
                        <Pressable
                          onPress={() => {
                            try {
                              setTab_financier_actif(true);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          {/* Financier */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 2,
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App['Flolend 4'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: tab_financier_actif
                                      ? palettes.App['Flolend 4']
                                      : palettes.App.gris_fonce_flolend,
                                  },
                                ],
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                (tab_financier_actif
                                  ? palettes.App['Flolend 4']
                                  : palettes.App.gris_fonce_flolend) ??
                                palettes.App['Flolend 4']
                              }
                              name={'Foundation/graph-bar'}
                            />
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 4'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: tab_financier_actif
                                          ? palettes.App['Flolend 4']
                                          : palettes.App.gris_fonce_flolend,
                                      },
                                    ],
                                    fontFamily: 'Montserrat_400Regular',
                                    fontSize: 13,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Financier'}
                            </Text>
                          </View>
                        </Pressable>
                        {/* Pressable Actus */}
                        <Pressable
                          onPress={() => {
                            try {
                              setTab_financier_actif(false);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          {/* Actus */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 2,
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: palettes.App['Flolend 4'],
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: tab_financier_actif
                                      ? palettes.App.gris_fonce_flolend
                                      : palettes.App['Flolend 4'],
                                  },
                                ],
                                justifyContent: 'center',
                                paddingBottom: 10,
                                paddingTop: 10,
                              },
                              dimensions.width
                            )}
                          >
                            <Icon
                              size={24}
                              color={
                                (tab_financier_actif
                                  ? palettes.App.gris_fonce_flolend
                                  : palettes.App['Flolend 4']) ??
                                palettes.App['Flolend 4']
                              }
                              name={'Entypo/camera'}
                            />
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 4'],
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: tab_financier_actif
                                          ? palettes.App.gris_fonce_flolend
                                          : palettes.App['Flolend 4'],
                                      },
                                    ],
                                    fontFamily: 'Montserrat_400Regular',
                                    fontSize: 13,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Actus'}
                            </Text>
                          </View>
                        </Pressable>
                      </View>
                    )}
                  </>
                  {/* Tab Financier */}
                  <>
                    {!tab_financier_actif ? null : (
                      <View>
                        {/* HTML 1 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[0]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view1}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Vue Le projet */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.flolend_gris_nuance_encart,
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              gap: 10,
                              marginTop: 15,
                              overflow: 'hidden',
                              padding: {
                                minWidth: Breakpoints.Laptop,
                                value: 30,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Titre */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'titre1_generique'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['titre1_generique']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Le projet'}
                          </Text>

                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['corps_contenu']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['corps_contenu']
                                .style,
                              dimensions.width
                            )}
                          >
                            {bool_dev_projets
                              ? fetchProjetsData?.Description_projet
                              : tronque_texte(
                                  fetchProjetsData?.Description_projet,
                                  100
                                )}
                          </Text>
                          {/* Développer/réduire */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                setBool_dev_projets(!bool_dev_projets);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            selectable={false}
                            {...GlobalStyles.LinkStyles(theme)[
                              'bouton_voir_tout'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)[
                                  'bouton_voir_tout'
                                ].style,
                                { alignSelf: 'flex-end' }
                              ),
                              dimensions.width
                            )}
                            title={`${
                              bool_dev_projets ? 'Réduire' : 'Développer'
                            }`}
                          />
                        </View>
                        {/* HTML 2 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[1]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view2}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Vue Le porteur */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.flolend_gris_nuance_encart,
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              gap: 10,
                              marginTop: 15,
                              padding: {
                                minWidth: Breakpoints.Laptop,
                                value: 30,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Titre */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'titre1_generique'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['titre1_generique']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Le porteur'}
                          </Text>

                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['corps_contenu']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['corps_contenu']
                                .style,
                              dimensions.width
                            )}
                          >
                            {bool_dev_porteur
                              ? fetchProjetsData?.Description_porteur
                              : tronque_texte(
                                  fetchProjetsData?.Description_porteur,
                                  100
                                )}
                          </Text>
                          {/* Développer/réduire */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                setBool_dev_porteur(!bool_dev_porteur);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            selectable={false}
                            {...GlobalStyles.LinkStyles(theme)[
                              'bouton_voir_tout'
                            ].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)[
                                  'bouton_voir_tout'
                                ].style,
                                { alignSelf: 'flex-end' }
                              ),
                              dimensions.width
                            )}
                            title={`${
                              bool_dev_porteur ? 'Réduire' : 'Développer'
                            }`}
                          />
                        </View>
                        {/* HTML 3 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[2]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view3}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Détails & échéancier */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-start',
                              },
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.flolend_gris_nuance_encart,
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              gap: [
                                { minWidth: Breakpoints.Mobile, value: 15 },
                                { minWidth: Breakpoints.Laptop, value: 0 },
                              ],
                              marginTop: 15,
                              padding: {
                                minWidth: Breakpoints.Laptop,
                                value: 30,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Détail de la collecte */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginBottom: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '1%',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '50%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* titre */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 10 },
                                dimensions.width
                              )}
                            >
                              {/* titre */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Détails de la collecte'}
                              </Text>
                            </View>
                            {/* table */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  backgroundColor: {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.gris_encart_int_flolend,
                                  },
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 25,
                                  },
                                  padding: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 20,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Taux annuel */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Entrée table */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {"Taux d'intérêt annuel"}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 25 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Métrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchProjetsData?.Taux}
                                    {'% par an'}
                                  </Text>
                                </View>
                              </View>
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Maturité */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* Entree */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Durée du prêt'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 25 },
                                    dimensions.width
                                  )}
                                >
                                  {/* metrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchProjetsData?.Duree}
                                  </Text>
                                </View>
                              </View>
                              {/* Divider 3 */}
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Montant collecte */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* entree */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Montant à collecter'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 25 },
                                    dimensions.width
                                  )}
                                >
                                  {/* metrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {format_nombre(
                                      fetchProjetsData?.Montant_a_collecter_euros,
                                      0
                                    )}
                                    {' €'}
                                  </Text>
                                </View>
                              </View>
                              {/* Divider 2 */}
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Investissement min */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Investissement minimum'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      justifyContent: 'center',
                                      marginLeft: 25,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {format_nombre(
                                      fetchProjetsData?.Investissement_minimum,
                                      0
                                    )}
                                    {' €'}
                                  </Text>
                                </View>
                              </View>
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Mode remboursement */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* entree */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Remboursement'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 25 },
                                    dimensions.width
                                  )}
                                >
                                  {/* metrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchProjetsData?.Mode_de_remboursement}
                                  </Text>
                                </View>
                              </View>
                              {/* Divider 4 */}
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Versement intérêts */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* entree */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Versement des intérêts'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 25 },
                                    dimensions.width
                                  )}
                                >
                                  {/* metrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {fetchProjetsData?.Versement_des_int_r_ts}
                                  </Text>
                                </View>
                              </View>
                              {/* Divider 5 */}
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Rang produit */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* entree */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Rang du financement participatif'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      justifyContent: 'center',
                                      marginLeft: 25,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* metrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      fetchProjetsData?.Rang_du_financement_participatif
                                    }
                                  </Text>
                                </View>
                              </View>
                              {/* Divider 6 */}
                              <Divider
                                color={StyleSheet.getWidthValue(
                                  [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: 'theme.colors.lightest',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: palettes.App['Flolend 4'],
                                    },
                                  ],
                                  dimensions.width
                                )}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: 1,
                                    marginBottom: 12,
                                    marginTop: 12,
                                  },
                                  dimensions.width
                                )}
                              />
                              {/* Nb participants */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: '50%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* entree */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'entree_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'entree_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Nombre de participants'}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1, marginLeft: 25 },
                                    dimensions.width
                                  )}
                                >
                                  {/* metrique */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'item_tableau'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'item_tableau'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {format_nombre(
                                      fetchProjetsData?.Participants_nombre,
                                      0
                                    )}
                                    {' participants'}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          {/* Vue Echéancier */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'row',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'column',
                                  },
                                ],
                                marginBottom: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '1%',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '50%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  paddingRight: {
                                    minWidth: Breakpoints.Tablet,
                                    value: '1%',
                                  },
                                  width: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: '50%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* titre */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { marginBottom: 10 },
                                  dimensions.width
                                )}
                              >
                                {/* Titre */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre1_generique'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Echéancier'}
                                </Text>
                              </View>
                              {/* Encart d'entrée */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-around',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      height: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 45,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 50,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 55,
                                        },
                                      ],
                                      width: 175,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <NumberInput
                                    changeTextDelay={500}
                                    onChangeText={newNumberInputValue => {
                                      try {
                                        setSimulation_projet(
                                          newNumberInputValue
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    webShowOutline={true}
                                    {...GlobalStyles.NumberInputStyles(theme)[
                                      'encart_input_simul'
                                    ].props}
                                    maxLength={6}
                                    placeholder={'Entrez un montant'}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.NumberInputStyles(theme)[
                                        'encart_input_simul'
                                      ].style,
                                      dimensions.width
                                    )}
                                    value={simulation_projet}
                                  />
                                </View>

                                <LinearGradient
                                  endX={100}
                                  endY={100}
                                  startX={0}
                                  startY={0}
                                  {...GlobalStyles.LinearGradientStyles(theme)[
                                    'gradient_bouton_bleu'
                                  ].props}
                                  color1={palettes.App['Flolend 1']}
                                  color2={palettes.App['Flolend 2']}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.LinearGradientStyles(theme)[
                                        'gradient_bouton_bleu'
                                      ].style,
                                      {
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 15,
                                        },
                                        flex: null,
                                        height: [
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 55,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 45,
                                          },
                                        ],
                                        marginTop: 0,
                                        overflow: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'hidden',
                                        },
                                        width: '40%',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* bouton_echeancier */}
                                  <Button
                                    accessible={true}
                                    iconPosition={'left'}
                                    onPress={() => {
                                      try {
                                        /* hidden 'API Request' action */
                                        navigation.navigate(
                                          'EcheancierProjetScreen',
                                          {
                                            montant_simul_euros: maSimulation(
                                              simulation_projet,
                                              fetchProjetsData?.valeur_un_titre_euros
                                            ),
                                            id_projet:
                                              props.route?.params
                                                ?.id_transfere ??
                                              defaultProps.id_transfere,
                                          }
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    {...GlobalStyles.ButtonStyles(theme)[
                                      'bouton_fond_blanc'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'bouton_fond_blanc'
                                        ].style,
                                        {
                                          borderRadius: {
                                            minWidth: Breakpoints.Laptop,
                                            value: null,
                                          },
                                          fontFamily: 'PTSans_400Regular',
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 16,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 20,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 24,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 28,
                                            },
                                          ],
                                          marginBottom: 0,
                                          marginTop: 0,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Simulation'}
                                  />
                                </LinearGradient>
                              </View>
                              {/* Montant investi (hors prêts) */}
                              <>
                                {fetchProjetsData?.Type_produit ===
                                'Prêts rémunérés' ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignSelf: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        backgroundColor: {
                                          minWidth: Breakpoints.Laptop,
                                          value:
                                            palettes.App
                                              .gris_encart_int_flolend,
                                        },
                                        borderColor: {
                                          minWidth: Breakpoints.Laptop,
                                          value: palettes.App['Flolend 1'],
                                        },
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 25,
                                        },
                                        borderWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        marginTop: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                        padding: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'row',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'column',
                                            },
                                          ],
                                          justifyContent: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'space-between',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'flex-start',
                                            },
                                          ],
                                          marginTop: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 5,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 0,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { gap: 5, justifyContent: 'center' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Valeur 1 titre */}
                                        <>
                                          {!(
                                            fetchProjetsData?.Type_produit !==
                                            'Prêts rémunérés'
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['montants_simul_ecr_projet']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'montants_simul_ecr_projet'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {"Valeur d'un titre : "}
                                              {format_nombre(
                                                fetchProjetsData?.valeur_un_titre_euros,
                                                2
                                              )}
                                              {' €'}
                                            </Text>
                                          )}
                                        </>
                                        {/* Nombre de titres */}
                                        <>
                                          {!(
                                            fetchProjetsData?.Type_produit !==
                                            'Prêts rémunérés'
                                          ) ? null : (
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['montants_simul_ecr_projet']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'montants_simul_ecr_projet'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Titres souscrits : '}
                                              {nbTitres(
                                                simulation_projet,
                                                fetchProjetsData?.valeur_un_titre_euros
                                              )}
                                              {' titres'}
                                            </Text>
                                          )}
                                        </>
                                      </View>
                                      {/* Consignes */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-start',
                                            gap: 5,
                                            justifyContent: 'space-evenly',
                                            marginBottom: 5,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Montant minimum */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'montants_simul_ecr_projet'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'montants_simul_ecr_projet'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Minimum : '}
                                          {format_nombre(
                                            fetchProjetsData?.Investissement_minimum,
                                            2
                                          )}
                                          {' €'}
                                        </Text>
                                        {/* Montant maximum */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'montants_simul_ecr_projet'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'montants_simul_ecr_projet'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'Maximum : '}
                                          {format_nombre(
                                            fetchProjetsData?.montant_max_investissable,
                                            2
                                          )}
                                          {' €'}
                                        </Text>
                                      </View>
                                    </View>
                                    {/* Ma simulation */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.App['Flolend 2'],
                                            fontFamily: 'PTSans_700Bold',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 15,
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 14,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 22,
                                              },
                                            ],
                                            marginTop: 5,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Ma simulation : '}
                                      {format_nombre(
                                        maSimulation(
                                          simulation_projet,
                                          fetchProjetsData?.valeur_un_titre_euros
                                        ),
                                        2
                                      )}
                                      {' €'}
                                    </Text>
                                  </View>
                                )}
                              </>
                              {/* Montant investi (prêts) */}
                              <>
                                {!(
                                  fetchProjetsData?.Type_produit ===
                                  'Prêts rémunérés'
                                ) ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'center',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'flex-start',
                                          },
                                        ],
                                        alignSelf: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        backgroundColor: {
                                          minWidth: Breakpoints.Laptop,
                                          value:
                                            palettes.App
                                              .gris_encart_int_flolend,
                                        },
                                        borderColor: {
                                          minWidth: Breakpoints.Laptop,
                                          value: palettes.App['Flolend 1'],
                                        },
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 25,
                                        },
                                        borderWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 1,
                                        },
                                        flexDirection: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'row',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'column',
                                          },
                                        ],
                                        gap: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 10,
                                        },
                                        justifyContent: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 'space-between',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'flex-start',
                                          },
                                        ],
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 5,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 20,
                                          },
                                        ],
                                        padding: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'center' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Ma simulation */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: palettes.App['Flolend 2'],
                                              fontFamily: 'PTSans_700Bold',
                                              fontSize: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 15,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 14,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 18,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 22,
                                                },
                                              ],
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Ma simulation : '}
                                        {format_nombre(
                                          maSimulation(
                                            simulation_projet,
                                            fetchProjetsData?.valeur_un_titre_euros
                                          ),
                                          2
                                        )}
                                        {' €'}
                                      </Text>
                                    </View>
                                    {/* Consignes */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          gap: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 5,
                                          },
                                          justifyContent: 'space-evenly',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Montant minimum */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'montants_simul_ecr_projet'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'montants_simul_ecr_projet'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Minimum : '}
                                        {format_nombre(
                                          fetchProjetsData?.Investissement_minimum,
                                          2
                                        )}
                                        {' €'}
                                      </Text>
                                      {/* Montant maximum */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'montants_simul_ecr_projet'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'montants_simul_ecr_projet'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Maximum : '}
                                        {format_nombre(
                                          fetchProjetsData?.montant_max_investissable,
                                          2
                                        )}
                                        {' €'}
                                      </Text>
                                    </View>
                                  </View>
                                )}
                              </>
                            </View>
                            {/* Image tablette */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Tablet ===
                                  true &&
                                dimensions.width >= Breakpoints.Laptop === false
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderRadius: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 24,
                                      },
                                      height: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 200,
                                      },
                                      overflow: {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'hidden',
                                      },
                                      width: {
                                        minWidth: Breakpoints.Tablet,
                                        value: '50%',
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Image
                                    {...GlobalStyles.ImageStyles(theme)['Image']
                                      .props}
                                    resizeMode={'contain'}
                                    source={imageSource(
                                      Images['LogoTexteBlancMilieu']
                                    )}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)['Image']
                                          .style,
                                        {
                                          height: {
                                            minWidth: Breakpoints.Tablet,
                                            value: '100%',
                                          },
                                          width: {
                                            minWidth: Breakpoints.Tablet,
                                            value: '100%',
                                          },
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                </View>
                              )}
                            </>
                          </View>
                        </View>
                        {/* HTML 4 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[3]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view4}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Vue Analyse des risques */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.flolend_gris_nuance_encart,
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              marginTop: 15,
                              padding: {
                                minWidth: Breakpoints.Laptop,
                                value: 30,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* titre */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 2 },
                              dimensions.width
                            )}
                          >
                            {/* Titre */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Analyse des risques'}
                            </Text>
                          </View>
                          {/* Risques et garanties */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                gap: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 20,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* Risques */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  paddingRight: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '1%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Titre 2 */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_2_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    { marginBottom: 10 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Aperçu des risques'}
                              </Text>
                              <SimpleStyleFlatList
                                data={fetchProjetsData?.risques_du_projet}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'jECxPlz0'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <View>
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'Surface'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.SurfaceStyles(theme)[
                                              'Surface'
                                            ].style,
                                            {
                                              backgroundColor: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value:
                                                    palettes.App
                                                      .flolend_gris_nuance_encart,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App
                                                      .gris_encart_int_flolend,
                                                },
                                              ],
                                              borderColor: {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  palettes.App['Flolend 1'],
                                              },
                                              borderRadius: 8,
                                              borderWidth: {
                                                minWidth: Breakpoints.Laptop,
                                                value: 1,
                                              },
                                              bottom: 2,
                                              height: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 200,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 210,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 250,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 300,
                                                },
                                              ],
                                              paddingBottom: '5%',
                                              paddingLeft: '5%',
                                              paddingRight: '5%',
                                              paddingTop: '5%',
                                              top: 2,
                                              width: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 150,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 175,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 180,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 250,
                                                },
                                              ],
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/* Nom */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'titre_cartes_risques'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'titre_cartes_risques'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.Nom_du_risque}
                                        </Text>
                                        {/* Description */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'contenu_carte_risques'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={5}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'contenu_carte_risques'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.Description_risque}
                                        </Text>
                                      </Surface>
                                    </View>
                                  );
                                }}
                                horizontal={true}
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    gap: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 10,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 20,
                                      },
                                    ],
                                    paddingBottom: 10,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Garanties */}
                            <View>
                              {/* Titre */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_2_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    { marginBottom: 10 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Garanties'}
                              </Text>
                              {/* Description */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'corps_contenu'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'corps_contenu'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {bool_dev_garanties
                                  ? fetchProjetsData?.Description_garanties
                                  : tronque_texte(
                                      fetchProjetsData?.Description_garanties,
                                      100
                                    )}
                              </Text>
                              {/* Développer/réduire */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    setBool_dev_garanties(!bool_dev_garanties);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)[
                                  'bouton_voir_tout'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)[
                                      'bouton_voir_tout'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                                title={`${
                                  bool_dev_garanties ? 'Réduire' : 'Développer'
                                }`}
                              />
                            </View>
                            {/* Avis de l'analyste */}
                            <View>
                              {/* Titre */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_2_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    { marginBottom: 10 }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {"Avis de l'analyste"}
                              </Text>
                              {/* Avis */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'corps_contenu'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].style,
                                    {
                                      fontSize: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 26,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {bool_dev_avis_analyste
                                  ? fetchProjetsData?.Avis_de_l_analyste
                                  : tronque_texte(
                                      fetchProjetsData?.Avis_de_l_analyste,
                                      100
                                    )}
                              </Text>
                              {/* Développer/réduire */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  try {
                                    setBool_dev_avis_analyste(
                                      !bool_dev_avis_analyste
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)[
                                  'bouton_voir_tout'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)[
                                      'bouton_voir_tout'
                                    ].style,
                                    { alignSelf: 'flex-end' }
                                  ),
                                  dimensions.width
                                )}
                                title={`${
                                  bool_dev_avis_analyste
                                    ? 'Réduire'
                                    : 'Développer'
                                }`}
                              />
                            </View>
                          </View>
                        </View>
                        {/* HTML 5 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[4]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view5}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* Vue Localisation */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.flolend_gris_nuance_encart,
                              },
                              borderRadius: [
                                { minWidth: Breakpoints.Mobile, value: 16 },
                                { minWidth: Breakpoints.Laptop, value: 24 },
                              ],
                              marginTop: 15,
                              padding: {
                                minWidth: Breakpoints.Laptop,
                                value: 30,
                              },
                              paddingBottom: [
                                { minWidth: Breakpoints.Mobile, value: 8 },
                                { minWidth: Breakpoints.Laptop, value: 30 },
                              ],
                              paddingTop: [
                                { minWidth: Breakpoints.Mobile, value: 8 },
                                { minWidth: Breakpoints.Laptop, value: 30 },
                              ],
                            },
                            dimensions.width
                          )}
                        >
                          {/* titre */}
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 10 },
                              dimensions.width
                            )}
                          >
                            {/* Titre */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Localisation'}
                            </Text>
                          </View>
                          {/* Vue carte */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: 10,
                                height: [
                                  { minWidth: Breakpoints.Mobile, value: 240 },
                                  { minWidth: Breakpoints.Laptop, value: 232 },
                                  { minWidth: Breakpoints.Desktop, value: 267 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 302,
                                  },
                                ],
                                overflow: 'hidden',
                              },
                              dimensions.width
                            )}
                          >
                            <MapView
                              apiKey={'AIzaSyDjye57xkL_-0UANi1wfaLujHnserggE1M'}
                              autoClusterMarkers={false}
                              autoClusterMarkersDistanceMeters={10000}
                              customMapStyle={'Lorem ipsum dolor sit amet'}
                              loadingEnabled={true}
                              moveOnMarkerPress={true}
                              rotateEnabled={true}
                              scrollEnabled={true}
                              showsCompass={false}
                              showsPointsOfInterest={true}
                              showsUserLocation={false}
                              zoomEnabled={true}
                              latitude={fetchProjetsData?.Latitude}
                              longitude={fetchProjetsData?.Longitude}
                              mapType={'standard'}
                              provider={'google'}
                              style={StyleSheet.applyWidth(
                                { flex: 1 },
                                dimensions.width
                              )}
                              zoom={5 ?? 16}
                            >
                              <MapMarker
                                androidUseDefaultIconImplementation={false}
                                flat={false}
                                pinImageSize={50}
                                tracksViewChanges={true}
                                latitude={fetchProjetsData?.Latitude}
                                longitude={fetchProjetsData?.Longitude}
                                pinColor={palettes.App['Flolend 2']}
                                pinImage={''}
                                title={fetchProjetsData?.Name}
                              />
                            </MapView>
                          </View>
                        </View>
                        {/* HTML 6 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[5]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view6}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        {/* ESG + docs */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flexDirection: {
                                minWidth: Breakpoints.Laptop,
                                value: 'row',
                              },
                              justifyContent: {
                                minWidth: Breakpoints.Laptop,
                                value: 'space-between',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Vue Performance ESG */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 24,
                                },
                                marginTop: 15,
                                padding: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 30,
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '49%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* titre */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignSelf: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'flex-start',
                                  },
                                  marginBottom: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Performance ESG'}
                              </Text>
                            </View>
                            {/* Scroll View mobile */}
                            <>
                              {dimensions.width >= Breakpoints.Laptop ? null : (
                                <SimpleStyleScrollView
                                  bounces={true}
                                  keyboardShouldPersistTaps={'never'}
                                  nestedScrollEnabled={false}
                                  showsHorizontalScrollIndicator={true}
                                  horizontal={true}
                                  showsVerticalScrollIndicator={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      gap: 10,
                                      marginBottom: 20,
                                      paddingLeft: '2%',
                                      paddingRight: '2%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Surface
                                    {...GlobalStyles.SurfaceStyles(theme)[
                                      'surface_esg'
                                    ].props}
                                    elevation={3}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'surface_esg'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {/* Environnement */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'carte_esg'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'carte_esg'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_carte_esg'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_carte_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Environnement'}
                                      </Text>
                                      <CircularProgress
                                        animationDuration={500}
                                        indeterminate={false}
                                        isAnimated={true}
                                        lineCap={'round'}
                                        showTrack={true}
                                        startPosition={'top'}
                                        thickness={10}
                                        trackLineCap={'round'}
                                        color={palettes.App['Flolend 4']}
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'center',
                                            marginBottom: 8,
                                            width: 125,
                                          },
                                          dimensions.width
                                        )}
                                        trackColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        value={
                                          fetchProjetsData?.Note_environnement
                                        }
                                      />
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'note_carte_esg'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'note_carte_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Note : '}
                                        {fetchProjetsData?.Note_environnement}
                                        {' %'}
                                      </Text>
                                    </View>
                                  </Surface>
                                  {/* Surface 2 */}
                                  <Surface
                                    {...GlobalStyles.SurfaceStyles(theme)[
                                      'surface_esg'
                                    ].props}
                                    elevation={3}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'surface_esg'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {/* Social */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'carte_esg'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'carte_esg'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_carte_esg'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_carte_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Social'}
                                      </Text>
                                      <CircularProgress
                                        animationDuration={500}
                                        indeterminate={false}
                                        isAnimated={true}
                                        lineCap={'round'}
                                        showTrack={true}
                                        startPosition={'top'}
                                        thickness={10}
                                        trackLineCap={'round'}
                                        color={palettes.App['Flolend 2']}
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'center',
                                            marginBottom: 8,
                                            width: 125,
                                          },
                                          dimensions.width
                                        )}
                                        trackColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        value={fetchProjetsData?.Note_social}
                                      />
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'note_carte_esg'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'note_carte_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Note : '}
                                        {fetchProjetsData?.Note_social}
                                        {' %'}
                                      </Text>
                                    </View>
                                  </Surface>
                                  {/* Surface 3 */}
                                  <Surface
                                    {...GlobalStyles.SurfaceStyles(theme)[
                                      'surface_esg'
                                    ].props}
                                    elevation={3}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.SurfaceStyles(theme)[
                                        'surface_esg'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {/* Gouvernance */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'carte_esg'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'carte_esg'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_carte_esg'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_carte_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Gouvernance'}
                                      </Text>
                                      <CircularProgress
                                        animationDuration={500}
                                        indeterminate={false}
                                        isAnimated={true}
                                        lineCap={'round'}
                                        showTrack={true}
                                        startPosition={'top'}
                                        thickness={10}
                                        trackLineCap={'round'}
                                        color={palettes.App['Flolend 1']}
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignSelf: 'center',
                                            marginBottom: 8,
                                            width: 125,
                                          },
                                          dimensions.width
                                        )}
                                        trackColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        value={
                                          fetchProjetsData?.Note_gouvernance
                                        }
                                      />
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'note_carte_esg'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'note_carte_esg'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Note : '}
                                        {fetchProjetsData?.Note_gouvernance}
                                        {' %'}
                                      </Text>
                                    </View>
                                  </Surface>
                                </SimpleStyleScrollView>
                              )}
                            </>
                            {/* métriques ordi */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'flex-start',
                                      },
                                      gap: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        gap: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                        maxWidth: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 300,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Environnement */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'center',
                                          },
                                          flexDirection: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'row',
                                          },
                                          gap: [
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 10,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 12,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 15,
                                            },
                                          ],
                                          justifyContent: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'space-between',
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            gap: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 5,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 7,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 10,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App.blanc_flolend,
                                                },
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'PTSans_700Bold',
                                                },
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 22,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'ENVIRONNEMENT'}
                                        </Text>
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App.blanc_flolend,
                                                },
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'PTSans_400Regular',
                                                },
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 26,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 30,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {fetchProjetsData?.Note_environnement}
                                          {' %'}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                            justifyContent: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <CircularProgress
                                          animationDuration={500}
                                          color={theme.colors.branding.primary}
                                          indeterminate={false}
                                          isAnimated={true}
                                          lineCap={'round'}
                                          showTrack={true}
                                          startPosition={'top'}
                                          thickness={10}
                                          trackColor={theme.colors.border.brand}
                                          trackLineCap={'round'}
                                          {...GlobalStyles.CircularProgressStyles(
                                            theme
                                          )['cercle_esg'].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              {
                                                height: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 50,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 60,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 70,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 50,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 60,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 70,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          thickness={StyleSheet.getWidthValue(
                                            [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 7,
                                              },
                                            ],
                                            dimensions.width
                                          )}
                                          value={
                                            fetchProjetsData?.Note_environnement
                                          }
                                        />
                                      </View>
                                    </View>
                                    {/* Social */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'center',
                                          },
                                          flexDirection: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'row',
                                          },
                                          gap: [
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 12,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 15,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 10,
                                            },
                                          ],
                                          justifyContent: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'space-between',
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            gap: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 5,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 7,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 10,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App.blanc_flolend,
                                                },
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'PTSans_700Bold',
                                                },
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 22,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'SOCIAL'}
                                        </Text>
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App.blanc_flolend,
                                                },
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'PTSans_400Regular',
                                                },
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 26,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 30,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {fetchProjetsData?.Note_social}
                                          {' %'}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                            justifyContent: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <CircularProgress
                                          animationDuration={500}
                                          color={theme.colors.branding.primary}
                                          indeterminate={false}
                                          isAnimated={true}
                                          lineCap={'round'}
                                          showTrack={true}
                                          startPosition={'top'}
                                          thickness={10}
                                          trackColor={theme.colors.border.brand}
                                          trackLineCap={'round'}
                                          {...GlobalStyles.CircularProgressStyles(
                                            theme
                                          )['cercle_esg'].props}
                                          color={StyleSheet.getWidthValue(
                                            [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  palettes.App['Flolend 2'],
                                              },
                                            ],
                                            dimensions.width
                                          )}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              {
                                                height: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 50,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 60,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 70,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 50,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 60,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 70,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          thickness={StyleSheet.getWidthValue(
                                            [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 7,
                                              },
                                            ],
                                            dimensions.width
                                          )}
                                          value={fetchProjetsData?.Note_social}
                                        />
                                      </View>
                                    </View>
                                    {/* Gouvernance */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'center',
                                          },
                                          flexDirection: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'row',
                                          },
                                          gap: [
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 12,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 15,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 10,
                                            },
                                          ],
                                          justifyContent: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'space-between',
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            gap: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 5,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 7,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 10,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App.blanc_flolend,
                                                },
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'PTSans_700Bold',
                                                },
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 22,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'GOUVERNANCE'}
                                        </Text>
                                        {/* Text 2 */}
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value:
                                                    palettes.App.blanc_flolend,
                                                },
                                                fontFamily: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'PTSans_400Regular',
                                                },
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 26,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 30,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {fetchProjetsData?.Note_gouvernance}
                                          {' %'}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                            justifyContent: {
                                              minWidth: Breakpoints.Laptop,
                                              value: 'center',
                                            },
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <CircularProgress
                                          animationDuration={500}
                                          color={theme.colors.branding.primary}
                                          indeterminate={false}
                                          isAnimated={true}
                                          lineCap={'round'}
                                          showTrack={true}
                                          startPosition={'top'}
                                          thickness={10}
                                          trackColor={theme.colors.border.brand}
                                          trackLineCap={'round'}
                                          {...GlobalStyles.CircularProgressStyles(
                                            theme
                                          )['cercle_esg'].props}
                                          color={StyleSheet.getWidthValue(
                                            [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  palettes.App['Flolend 1'],
                                              },
                                            ],
                                            dimensions.width
                                          )}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.CircularProgressStyles(
                                                theme
                                              )['cercle_esg'].style,
                                              {
                                                height: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 50,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 60,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 70,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 50,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 60,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 70,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                          thickness={StyleSheet.getWidthValue(
                                            [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 7,
                                              },
                                            ],
                                            dimensions.width
                                          )}
                                          value={
                                            fetchProjetsData?.Note_gouvernance
                                          }
                                        />
                                      </View>
                                    </View>
                                  </View>
                                  {/* Synthèse */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor: {
                                          minWidth: Breakpoints.Laptop,
                                          value:
                                            palettes.App
                                              .gris_encart_int_flolend,
                                        },
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 25,
                                        },
                                        gap: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 10,
                                        },
                                        padding: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 20,
                                        },
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Synthèse ESG */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {bool_dev_esg
                                        ? fetchProjetsData?.Commentaire_ESG
                                        : tronque_texte(
                                            fetchProjetsData?.Commentaire_ESG,
                                            100
                                          )}
                                    </Text>
                                    {/* Développer/réduire */}
                                    <Link
                                      accessible={true}
                                      onPress={() => {
                                        try {
                                          setBool_dev_esg(!bool_dev_esg);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectable={false}
                                      {...GlobalStyles.LinkStyles(theme)[
                                        'bouton_voir_tout'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.LinkStyles(theme)[
                                            'bouton_voir_tout'
                                          ].style,
                                          { alignSelf: 'flex-end' }
                                        ),
                                        dimensions.width
                                      )}
                                      title={`${
                                        bool_dev_esg ? 'Réduire' : 'Développer'
                                      }`}
                                    />
                                  </View>
                                </View>
                              )}
                            </>
                          </View>
                          {/* HTML 7 */}
                          <>
                            {!(
                              fetchProjetsData?.vues_html_page_projet &&
                              (fetchProjetsData?.vues_html_page_projet)[6]
                            ) ? null : (
                              <WebView
                                allowFileAccessFromFileURLs={false}
                                allowUniversalAccessFromFileURLs={false}
                                cacheEnabled={true}
                                incognito={false}
                                javaScriptCanOpenWindowsAutomatically={false}
                                javaScriptEnabled={true}
                                mediaPlaybackRequiresUserAction={false}
                                startInLoadingState={false}
                                {...GlobalStyles.WebViewStyles(theme)[
                                  'HTML View'
                                ].props}
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                                source={{
                                  html: `${fetchProjetsData?.code_html_view7}`,
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.WebViewStyles(theme)[
                                      'HTML View'
                                    ].style,
                                    {
                                      borderColor: palettes.App.Transparent,
                                      width: '100%',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          {/* Vue Banque de documents */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 24,
                                },
                                marginTop: 15,
                                padding: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 30,
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '49%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* titre */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginBottom: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Titre */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Banque de documents'}
                              </Text>
                            </View>
                            {/* Vue tableau */}
                            <View>
                              <SimpleStyleFlatList
                                data={
                                  fetchProjetsData?.documents_publics_du_projet
                                }
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listData, index) =>
                                  listData?.id ??
                                  listData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'XTaBbkXd'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listData = item;
                                  return (
                                    <>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexDirection: 'column' },
                                          dimensions.width
                                        )}
                                      >
                                        {/* View 3 */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <View>
                                            {/* Nom */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['elmt_table'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'elmt_table'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {listData?.Nom_du_doc}
                                            </Text>
                                          </View>
                                          {/* Vue boutons */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flexDirection: 'row',
                                                gap: 10,
                                                paddingRight: 20,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Télécharger */}
                                            <View>
                                              <Pressable
                                                onPress={() => {
                                                  const handler = async () => {
                                                    try {
                                                      await WebBrowser.openBrowserAsync(
                                                        `${listData?.document?.url}`
                                                      );
                                                    } catch (err) {
                                                      console.error(err);
                                                    }
                                                  };
                                                  handler();
                                                }}
                                              >
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'FontAwesome/download'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                              </Pressable>
                                            </View>
                                            {/* Partager */}
                                            <>
                                              {Platform.OS === 'web' ? null : (
                                                <View>
                                                  <Pressable
                                                    onPress={() => {
                                                      const handler =
                                                        async () => {
                                                          try {
                                                            await openShareUtil(
                                                              `${listData?.document?.url}`
                                                            );
                                                          } catch (err) {
                                                            console.error(err);
                                                          }
                                                        };
                                                      handler();
                                                    }}
                                                  >
                                                    <Icon
                                                      color={
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ]
                                                      }
                                                      name={
                                                        'MaterialIcons/mobile-screen-share'
                                                      }
                                                      size={
                                                        !(
                                                          dimensions.width >=
                                                          Breakpoints.Laptop
                                                        )
                                                          ? 18
                                                          : !(
                                                              dimensions.width >=
                                                              Breakpoints.Desktop
                                                            )
                                                          ? 25
                                                          : dimensions.width >=
                                                            Breakpoints.BigScreen
                                                          ? 35
                                                          : 30
                                                      }
                                                    />
                                                  </Pressable>
                                                </View>
                                              )}
                                            </>
                                          </View>
                                        </View>
                                        {/* Divider 7 */}
                                        <Divider
                                          color={StyleSheet.getWidthValue(
                                            [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'theme.colors.lightest',
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  palettes.App
                                                    .gris_encart_int_flolend,
                                              },
                                            ],
                                            dimensions.width
                                          )}
                                          style={StyleSheet.applyWidth(
                                            { marginBottom: 5, marginTop: 5 },
                                            dimensions.width
                                          )}
                                        />
                                      </View>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                              />
                            </View>
                          </View>
                          {/* HTML 8 */}
                          <>
                            {!(
                              fetchProjetsData?.vues_html_page_projet &&
                              (fetchProjetsData?.vues_html_page_projet)[7]
                            ) ? null : (
                              <WebView
                                allowFileAccessFromFileURLs={false}
                                allowUniversalAccessFromFileURLs={false}
                                cacheEnabled={true}
                                incognito={false}
                                javaScriptCanOpenWindowsAutomatically={false}
                                javaScriptEnabled={true}
                                mediaPlaybackRequiresUserAction={false}
                                startInLoadingState={false}
                                {...GlobalStyles.WebViewStyles(theme)[
                                  'HTML View'
                                ].props}
                                showsHorizontalScrollIndicator={false}
                                showsVerticalScrollIndicator={false}
                                source={{
                                  html: `${fetchProjetsData?.code_html_view8}`,
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.WebViewStyles(theme)[
                                      'HTML View'
                                    ].style,
                                    {
                                      borderColor: palettes.App.Transparent,
                                      width: '100%',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                        {/* Mentions réglementaires */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.flolend_gris_nuance_encart,
                              },
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 24,
                              },
                              marginTop: 15,
                              padding: {
                                minWidth: Breakpoints.Laptop,
                                value: 30,
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { marginBottom: 10 },
                              dimensions.width
                            )}
                          >
                            {/* Titre */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Mentions réglementaires'}
                            </Text>
                          </View>
                          {/* Vue horizontale */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'row',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* gauche */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  paddingRight: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '1%',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '50%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Accordion illiquidité */}
                              <AccordionGroup
                                iconSize={24}
                                {...GlobalStyles.AccordionGroupStyles(theme)[
                                  'Accordion'
                                ].props}
                                caretColor={palettes.App['Flolend 2']}
                                caretSize={
                                  dimensions.width >= Breakpoints.Laptop
                                    ? dimensions.width >= Breakpoints.Desktop
                                      ? dimensions.width >=
                                        Breakpoints.BigScreen
                                        ? 35
                                        : 30
                                      : 25
                                    : 18
                                }
                                closedColor={palettes.App.blanc_flolend}
                                label={"Illiquidité de l'investissement"}
                                openColor={palettes.App.blanc_flolend}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.AccordionGroupStyles(theme)[
                                      'Accordion'
                                    ].style,
                                    {
                                      alignSelf: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'center',
                                      },
                                      color: palettes.App['Flolend 5'],
                                      fontFamily: 'PTSans_400Regular',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 18,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 22,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* Mention */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'explications'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'explications'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    "Le produit dont il est question ici porte un risque d'illiquidité. Vous pourriez ne pas être en mesure de vendre les instruments d’investissement au moment où vous le souhaitez. Si vous êtes en mesure de les vendre, vous risquez néanmoins de subir des pertes."
                                  }
                                </Text>
                              </AccordionGroup>
                            </View>
                            {/* droite */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  paddingLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '1%',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '50%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Accordion délai réflexion */}
                              <>
                                {!(
                                  dossier_investisseur?.type_investisseur !==
                                  'averti'
                                ) ? null : (
                                  <AccordionGroup
                                    iconSize={24}
                                    {...GlobalStyles.AccordionGroupStyles(
                                      theme
                                    )['Accordion'].props}
                                    caretColor={palettes.App['Flolend 2']}
                                    caretSize={
                                      dimensions.width >= Breakpoints.Laptop
                                        ? dimensions.width >=
                                          Breakpoints.Desktop
                                          ? dimensions.width >=
                                            Breakpoints.BigScreen
                                            ? 35
                                            : 30
                                          : 25
                                        : 18
                                    }
                                    closedColor={palettes.App.blanc_flolend}
                                    label={'Délai de réflexion'}
                                    openColor={palettes.App.blanc_flolend}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.AccordionGroupStyles(
                                          theme
                                        )['Accordion'].style,
                                        {
                                          alignSelf: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 'center',
                                          },
                                          color: palettes.App['Flolend 5'],
                                          fontFamily: 'PTSans_400Regular',
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 14,
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: 14,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 18,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 22,
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {/* Mention */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'explications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'explications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        'Vous pouvez annuler votre souscription sans frais dans les 4 jours suivant la signature de votre bulletin de souscription.'
                                      }
                                    </Text>
                                  </AccordionGroup>
                                )}
                              </>
                            </View>
                          </View>
                        </View>
                        {/* HTML 9 */}
                        <>
                          {!(
                            fetchProjetsData?.vues_html_page_projet &&
                            (fetchProjetsData?.vues_html_page_projet)[8]
                          ) ? null : (
                            <WebView
                              allowFileAccessFromFileURLs={false}
                              allowUniversalAccessFromFileURLs={false}
                              cacheEnabled={true}
                              incognito={false}
                              javaScriptCanOpenWindowsAutomatically={false}
                              javaScriptEnabled={true}
                              mediaPlaybackRequiresUserAction={false}
                              startInLoadingState={false}
                              {...GlobalStyles.WebViewStyles(theme)['HTML View']
                                .props}
                              showsHorizontalScrollIndicator={false}
                              showsVerticalScrollIndicator={false}
                              source={{
                                html: `${fetchProjetsData?.code_html_view9}`,
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.WebViewStyles(theme)['HTML View']
                                    .style,
                                  {
                                    borderColor: palettes.App.Transparent,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </View>
                    )}
                  </>
                  {/* Fetch à l'affiche */}
                  <BaseXanoApi.FetchActusEtRessourcesParProjetGET
                    id_projet={
                      props.route?.params?.id_transfere ??
                      defaultProps.id_transfere
                    }
                    nombre_actus={0}
                    nombre_actus_affiche={
                      dimensions.width >= Breakpoints.Tablet ? 2 : 1
                    }
                    nombre_actus_hors_affiche={
                      dimensions.width >= Breakpoints.Laptop ? 3 : 2
                    }
                    nombre_ressources={10}
                  >
                    {({
                      loading,
                      error,
                      data,
                      refetchActusEtRessourcesParProjet,
                    }) => {
                      const fetchLAfficheData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Tab Actus */}
                          <>
                            {tab_financier_actif ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: {
                                      minWidth: Breakpoints.Laptop,
                                      value:
                                        palettes.App.flolend_gris_nuance_encart,
                                    },
                                    borderRadius: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 25,
                                    },
                                    marginTop: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 20,
                                    },
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '5%',
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '10%',
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '15%',
                                      },
                                    ],
                                    paddingRight: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '5%',
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: '15%',
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: '10%',
                                      },
                                    ],
                                    width: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* A l'affiche */}
                                <View>
                                  {/* Encart titre */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: 10,
                                        marginTop: 15,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Titre */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {"A l'affiche"}
                                    </Text>
                                    {/* Voir tout */}
                                    <Link
                                      accessible={true}
                                      onPress={() => {
                                        try {
                                          navigation.navigate(
                                            'ActualitesProjetScreen',
                                            {
                                              id_projet:
                                                props.route?.params
                                                  ?.id_transfere ??
                                                defaultProps.id_transfere,
                                            }
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      selectable={false}
                                      {...GlobalStyles.LinkStyles(theme)[
                                        'bouton_voir_tout'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.LinkStyles(theme)[
                                          'bouton_voir_tout'
                                        ].style,
                                        dimensions.width
                                      )}
                                      title={'Voir tout'}
                                    />
                                  </View>
                                  {/* A l'affiche */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                        ],
                                        marginRight: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '5%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '0%',
                                          },
                                        ],
                                        marginTop: '2%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Masterview */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { width: '100%' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Liste mobile et tablette */}
                                      <>
                                        {dimensions.width >=
                                        Breakpoints.Laptop ? null : (
                                          <SimpleStyleFlatList
                                            data={
                                              fetchLAfficheData
                                                ?.actus_et_ressources
                                                ?.actus_a_l_affiche
                                            }
                                            inverted={false}
                                            keyExtractor={(
                                              listeMobileEtTabletteData,
                                              index
                                            ) =>
                                              listeMobileEtTabletteData?.id ??
                                              listeMobileEtTabletteData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(
                                                listeMobileEtTabletteData
                                              )
                                            }
                                            keyboardShouldPersistTaps={'never'}
                                            listKey={'Y5mLwDpW'}
                                            nestedScrollEnabled={false}
                                            numColumns={1}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item, index }) => {
                                              const listeMobileEtTabletteData =
                                                item;
                                              return (
                                                <>
                                                  {/* Record Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        gap: 10,
                                                        width: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: '100%',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: '50%',
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Touchable
                                                      onPress={() => {
                                                        const handler =
                                                          async () => {
                                                            try {
                                                              (
                                                                await baseXanoMajVuesPublicationPUT.mutateAsync(
                                                                  {
                                                                    id_actu:
                                                                      listeMobileEtTabletteData?.id,
                                                                  }
                                                                )
                                                              )?.json;
                                                              navigation.navigate(
                                                                'ActualiteScreen',
                                                                {
                                                                  id_actu_airtable:
                                                                    listeMobileEtTabletteData?.id,
                                                                }
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          };
                                                        handler();
                                                      }}
                                                      style={StyleSheet.applyWidth(
                                                        { width: '100%' },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Cadre image */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            height: 200,
                                                            width: '100%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Image
                                                          resizeMode={'cover'}
                                                          source={imageSource(
                                                            `${listeMobileEtTabletteData?.couv_actu?.url}`
                                                          )}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              height: '100%',
                                                              width: '100%',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        />
                                                      </View>
                                                    </Touchable>
                                                    {/* Annotations */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                          justifyContent:
                                                            'space-between',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Circle Image Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            gap: 10,
                                                            maxWidth: '60%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* View 2 */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              borderRadius: 50,
                                                              height: 50,
                                                              overflow:
                                                                'hidden',
                                                              width: 50,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Image
                                                            resizeMode={'cover'}
                                                            {...GlobalStyles.ImageStyles(
                                                              theme
                                                            )['Image'].props}
                                                            source={imageSource(
                                                              `${listeMobileEtTabletteData?.image_auteur?.url}`
                                                            )}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .style,
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </View>

                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            { maxWidth: '65%' },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* Auteur */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )['auteur_affiche']
                                                              .props}
                                                            ellipsizeMode={
                                                              'tail'
                                                            }
                                                            numberOfLines={2}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'auteur_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              listeMobileEtTabletteData?.Auteur
                                                            }
                                                          </Text>
                                                          {/* Projet */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'sous_titre_carte_affiche'
                                                            ].props}
                                                            ellipsizeMode={
                                                              'tail'
                                                            }
                                                            numberOfLines={2}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'sous_titre_carte_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'pour '}
                                                            {
                                                              listeMobileEtTabletteData
                                                                ?._projets.Name
                                                            }
                                                          </Text>
                                                        </View>
                                                      </View>
                                                      {/* ligne likes */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flexDirection:
                                                              'row',
                                                            gap: 10,
                                                            justifyContent:
                                                              'space-between',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Action 1 Frame */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                              gap: 5,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            onPress={newCheckboxValue => {
                                                              const handler =
                                                                async () => {
                                                                  try {
                                                                    (
                                                                      await baseXanoMajLikesPUT.mutateAsync(
                                                                        {
                                                                          actu_id:
                                                                            listeMobileEtTabletteData?.id,
                                                                        }
                                                                      )
                                                                    )?.json;
                                                                    await refetchContenuClient();
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                };
                                                              handler();
                                                            }}
                                                            checkedIcon={
                                                              'AntDesign/heart'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 4'
                                                              ]
                                                            }
                                                            defaultValue={
                                                              listeMobileEtTabletteData?.client_likeur
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                          />
                                                          {/* Likes */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'likes_card_affiche'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'likes_card_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listeMobileEtTabletteData?.Likes >=
                                                            1000
                                                              ? `${
                                                                  listeMobileEtTabletteData?.Likes /
                                                                  1000
                                                                }K`
                                                              : listeMobileEtTabletteData?.Likes}
                                                          </Text>
                                                        </View>
                                                        {/* Action 3 Frame */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                              gap: 5,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* checkbox */}
                                                          <Checkbox
                                                            checkedIcon={
                                                              'AntDesign/eyeo'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 4'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/eyeo'
                                                            }
                                                          />
                                                          {/* Vues */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'likes_card_affiche'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'likes_card_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listeMobileEtTabletteData?.Vues >=
                                                            1000
                                                              ? `${
                                                                  listeMobileEtTabletteData?.Vues /
                                                                  1000
                                                                }K`
                                                              : listeMobileEtTabletteData?.Vues}
                                                          </Text>
                                                        </View>
                                                        {/* Flex Frame for Touchable */}
                                                        <>
                                                          {!(
                                                            listeMobileEtTabletteData?.autorisation_partage &&
                                                            !(
                                                              Platform.OS ===
                                                              'web'
                                                            )
                                                          ) ? null : (
                                                            <View>
                                                              <Touchable
                                                                onPress={() => {
                                                                  const handler =
                                                                    async () => {
                                                                      try {
                                                                        await openShareUtil(
                                                                          `${listeMobileEtTabletteData?.lien_partage}`
                                                                        );
                                                                      } catch (err) {
                                                                        console.error(
                                                                          err
                                                                        );
                                                                      }
                                                                    };
                                                                  handler();
                                                                }}
                                                              >
                                                                {/* Flex Frame for Icons */}
                                                                <View>
                                                                  {/* icone_partage */}
                                                                  <Icon
                                                                    color={
                                                                      palettes
                                                                        .App[
                                                                        'Flolend 2'
                                                                      ]
                                                                    }
                                                                    name={
                                                                      'Ionicons/share-outline'
                                                                    }
                                                                    size={
                                                                      (!(
                                                                        dimensions.width >=
                                                                        Breakpoints.Desktop
                                                                      )
                                                                        ? 18
                                                                        : dimensions.width >=
                                                                          Breakpoints.BigScreen
                                                                        ? 30
                                                                        : 25) ??
                                                                      18
                                                                    }
                                                                  />
                                                                </View>
                                                              </Touchable>
                                                            </View>
                                                          )}
                                                        </>
                                                      </View>
                                                    </View>
                                                    {/* Ligne titre */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        { width: '100%' },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Top Row Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flexGrow: 0,
                                                            flexShrink: 0,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Titre */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'titre_carte_affiche'
                                                          ].props}
                                                          numberOfLines={2}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'titre_carte_affiche'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listeMobileEtTabletteData?.Header_contenu
                                                          }
                                                        </Text>
                                                      </View>
                                                      {/* Second Row Frame */}
                                                      <View>
                                                        {/* Titre2 */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )['corps_contenu']
                                                            .props}
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={3}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['corps_contenu']
                                                              .style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listeMobileEtTabletteData?.Header2
                                                          }
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </>
                                              );
                                            }}
                                            horizontal={false}
                                            scrollEnabled={false}
                                            showsHorizontalScrollIndicator={
                                              false
                                            }
                                            showsVerticalScrollIndicator={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                gap: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 220,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 390,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      {/* Liste laptop or larger */}
                                      <>
                                        {!(
                                          dimensions.width >= Breakpoints.Laptop
                                        ) ? null : (
                                          <SimpleStyleFlatList
                                            data={
                                              fetchLAfficheData
                                                ?.actus_et_ressources
                                                ?.actus_a_l_affiche
                                            }
                                            inverted={false}
                                            keyExtractor={(
                                              listeLaptopOrLargerData,
                                              index
                                            ) =>
                                              listeLaptopOrLargerData?.id ??
                                              listeLaptopOrLargerData?.uuid ??
                                              index?.toString() ??
                                              JSON.stringify(
                                                listeLaptopOrLargerData
                                              )
                                            }
                                            keyboardShouldPersistTaps={'never'}
                                            listKey={'b2bjWOQG'}
                                            nestedScrollEnabled={false}
                                            onEndReachedThreshold={0.5}
                                            renderItem={({ item, index }) => {
                                              const listeLaptopOrLargerData =
                                                item;
                                              return (
                                                <>
                                                  {/* Record Frame */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        gap: 10,
                                                        width: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: '100%',
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: 350,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 420,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 500,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Touchable
                                                      onPress={() => {
                                                        const handler =
                                                          async () => {
                                                            try {
                                                              (
                                                                await baseXanoMajVuesPublicationPUT.mutateAsync(
                                                                  {
                                                                    id_actu:
                                                                      listeLaptopOrLargerData?.id,
                                                                  }
                                                                )
                                                              )?.json;
                                                              navigation.navigate(
                                                                'ActualiteScreen',
                                                                {
                                                                  id_actu_airtable:
                                                                    listeLaptopOrLargerData?.id,
                                                                }
                                                              );
                                                            } catch (err) {
                                                              console.error(
                                                                err
                                                              );
                                                            }
                                                          };
                                                        handler();
                                                      }}
                                                    >
                                                      {/* Cadre image */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            height: 200,
                                                            width: '100%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Image
                                                          resizeMode={'cover'}
                                                          source={imageSource(
                                                            `${listeLaptopOrLargerData?.couv_actu?.url}`
                                                          )}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              height: '100%',
                                                              width: '100%',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        />
                                                      </View>
                                                    </Touchable>
                                                    {/* Annotations */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexDirection: 'row',
                                                          justifyContent:
                                                            'space-between',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Circle Image Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            gap: 10,
                                                            maxWidth: '50%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <CircleImage
                                                          {...GlobalStyles.CircleImageStyles(
                                                            theme
                                                          )[
                                                            'circle_image_card_ressource'
                                                          ].props}
                                                          size={38}
                                                          source={imageSource(
                                                            `${listeLaptopOrLargerData?.image_auteur?.url}`
                                                          )}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.CircleImageStyles(
                                                              theme
                                                            )[
                                                              'circle_image_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        />
                                                        <View>
                                                          {/* Auteur */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )['auteur_affiche']
                                                              .props}
                                                            ellipsizeMode={
                                                              'tail'
                                                            }
                                                            numberOfLines={2}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'auteur_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              listeLaptopOrLargerData?.Auteur
                                                            }
                                                          </Text>
                                                          {/* Projet */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'sous_titre_carte_affiche'
                                                            ].props}
                                                            ellipsizeMode={
                                                              'tail'
                                                            }
                                                            numberOfLines={2}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'sous_titre_carte_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'pour '}
                                                            {
                                                              listeLaptopOrLargerData
                                                                ?._projets.Name
                                                            }
                                                          </Text>
                                                        </View>
                                                      </View>
                                                      {/* ligne likes */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flexDirection:
                                                              'row',
                                                            flexGrow: 0,
                                                            flexShrink: 0,
                                                            gap: 10,
                                                            justifyContent:
                                                              'space-evenly',
                                                            width: '35%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Action 1 Frame */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            onPress={newCheckboxValue => {
                                                              const handler =
                                                                async () => {
                                                                  try {
                                                                    (
                                                                      await baseXanoMajLikesPUT.mutateAsync(
                                                                        {
                                                                          actu_id:
                                                                            listeLaptopOrLargerData?.id,
                                                                        }
                                                                      )
                                                                    )?.json;
                                                                    await refetchContenuClient();
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                };
                                                              handler();
                                                            }}
                                                            checkedIcon={
                                                              'AntDesign/heart'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 4'
                                                              ]
                                                            }
                                                            defaultValue={
                                                              listeLaptopOrLargerData?.client_likeur
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                          />
                                                          {/* Likes */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'likes_card_affiche'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.TextStyles(
                                                                  theme
                                                                )[
                                                                  'likes_card_affiche'
                                                                ].style,
                                                                {
                                                                  fontSize: {
                                                                    minWidth:
                                                                      Breakpoints.BigScreen,
                                                                    value: 26,
                                                                  },
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              listeLaptopOrLargerData?.Likes
                                                            }
                                                          </Text>
                                                        </View>
                                                        {/* Action 3 Frame */}
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignItems:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                              paddingLeft: 5,
                                                              paddingRight: 5,
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {/* checkbox */}
                                                          <Checkbox
                                                            checkedIcon={
                                                              'AntDesign/eyeo'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 4'
                                                              ]
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/eyeo'
                                                            }
                                                          />
                                                          {/* Vues */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'likes_card_affiche'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'likes_card_affiche'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {
                                                              listeLaptopOrLargerData?.Vues
                                                            }
                                                          </Text>
                                                        </View>
                                                      </View>
                                                    </View>
                                                    {/* Ligne titre */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        { width: '100%' },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Top Row Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            flexGrow: 0,
                                                            flexShrink: 0,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Titre */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'titre_carte_affiche'
                                                          ].props}
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={2}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'titre_carte_affiche'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listeLaptopOrLargerData?.Header_contenu
                                                          }
                                                        </Text>
                                                      </View>
                                                      {/* Second Row Frame */}
                                                      <View>
                                                        {/* Titre2 */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )['corps_contenu']
                                                            .props}
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={3}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )['corps_contenu']
                                                              .style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listeLaptopOrLargerData?.Header2
                                                          }
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  </View>
                                                </>
                                              );
                                            }}
                                            horizontal={false}
                                            numColumns={1}
                                            scrollEnabled={false}
                                            showsHorizontalScrollIndicator={
                                              false
                                            }
                                            showsVerticalScrollIndicator={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'center',
                                                },
                                                flexDirection: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'row',
                                                },
                                                justifyContent: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 'space-between',
                                                },
                                                width: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: '100%',
                                                },
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </View>
                                {/* Publications et ressources */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'row',
                                      },
                                      justifyContent: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'space-between',
                                      },
                                      marginTop: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 15,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: '2%',
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Publications */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginTop: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 0,
                                        },
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 350,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 420,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 500,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Bandeau titre */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          marginBottom: 10,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Publications'}
                                      </Text>
                                      {/* Lien voir tout */}
                                      <Link
                                        accessible={true}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'ActualitesProjetScreen',
                                              {
                                                id_projet:
                                                  props.route?.params
                                                    ?.id_transfere ??
                                                  defaultProps.id_transfere,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        selectable={false}
                                        {...GlobalStyles.LinkStyles(theme)[
                                          'bouton_voir_tout'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.LinkStyles(theme)[
                                            'bouton_voir_tout'
                                          ].style,
                                          dimensions.width
                                        )}
                                        title={'Voir tout'}
                                      />
                                    </View>
                                    {/* Publications affichées */}
                                    <View>
                                      <SimpleStyleFlatList
                                        data={
                                          fetchLAfficheData?.actus_et_ressources
                                            ?.actus_non_affiche
                                        }
                                        horizontal={false}
                                        inverted={false}
                                        keyExtractor={(listData, index) =>
                                          listData?.id ??
                                          listData?.uuid ??
                                          index?.toString() ??
                                          JSON.stringify(listData)
                                        }
                                        keyboardShouldPersistTaps={'never'}
                                        listKey={'a1UQRebm'}
                                        nestedScrollEnabled={false}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item, index }) => {
                                          const listData = item;
                                          return (
                                            <>
                                              {/* Publication 1 */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    marginBottom: 10,
                                                    marginLeft: 12,
                                                    marginRight: 12,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Touchable
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          (
                                                            await baseXanoMajVuesPublicationPUT.mutateAsync(
                                                              {
                                                                id_actu:
                                                                  listData?.id,
                                                              }
                                                            )
                                                          )?.json;
                                                          navigation.navigate(
                                                            'ActualiteScreen',
                                                            {
                                                              id_actu_airtable:
                                                                listData?.id,
                                                            }
                                                          );
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                >
                                                  {/* Record Frame */}
                                                  <View
                                                    {...GlobalStyles.ViewStyles(
                                                      theme
                                                    )['encart_publications']
                                                      .props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.ViewStyles(
                                                        theme
                                                      )['encart_publications']
                                                        .style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* Left Side Frame */}
                                                    <View
                                                      {...GlobalStyles.ViewStyles(
                                                        theme
                                                      )[
                                                        'left_side_publicataion'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.ViewStyles(
                                                          theme
                                                        )[
                                                          'left_side_publicataion'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Top Row Frame */}
                                                      <View>
                                                        {/* Rubik Headline Style 18/24 Bold */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'titre_encart_publications'
                                                          ].props}
                                                          numberOfLines={2}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'titre_encart_publications'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listData?.Header_contenu
                                                          }
                                                        </Text>
                                                      </View>
                                                      {/* Second Row Frame */}
                                                      <View>
                                                        {/* Rubik Text Style 12/18 Regular */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'corps_encart_publications'
                                                          ].props}
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={2}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'corps_encart_publications'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {listData?.Header2}
                                                        </Text>
                                                      </View>
                                                      {/* Third Row Frame */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'baseline',
                                                            flexDirection:
                                                              'row',
                                                            paddingBottom: 6,
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Type contenu */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'type_encart_publications'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'type_encart_publications'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {listData?.Media}
                                                        </Text>
                                                        {/* Date */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'date_encart_publications'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'date_encart_publications'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {'Le '}
                                                          {ConvertionDate(
                                                            listData?.date_publication
                                                          )}
                                                        </Text>
                                                      </View>
                                                    </View>
                                                    {/* Right Side Frame */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          flexShrink: 0,
                                                          justifyContent:
                                                            'center',
                                                          paddingBottom: 12,
                                                          paddingTop: 12,
                                                          width: '30%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Flex for Image */}
                                                      <View
                                                        {...GlobalStyles.ViewStyles(
                                                          theme
                                                        )[
                                                          'image_liste_publications'
                                                        ].props}
                                                        style={StyleSheet.applyWidth(
                                                          StyleSheet.compose(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'image_liste_publications'
                                                            ].style,
                                                            {
                                                              height: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 125,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value: 100,
                                                                },
                                                              ],
                                                              width: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 125,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value: 100,
                                                                },
                                                              ],
                                                            }
                                                          ),
                                                          dimensions.width
                                                        )}
                                                      >
                                                        <Image
                                                          resizeMode={'cover'}
                                                          source={imageSource(
                                                            `${listData?.couv_actu?.url}`
                                                          )}
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              height: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 90,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value: '100%',
                                                                },
                                                              ],
                                                              width: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 102,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Laptop,
                                                                  value: '100%',
                                                                },
                                                              ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                        />
                                                      </View>
                                                    </View>
                                                  </View>
                                                </Touchable>
                                              </View>
                                            </>
                                          );
                                        }}
                                        showsHorizontalScrollIndicator={true}
                                        showsVerticalScrollIndicator={true}
                                        scrollEnabled={false}
                                      />
                                    </View>
                                  </View>
                                  {/* Ressources */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginTop: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 15,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 350,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 420,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 500,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Bandeau titre */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          marginBottom: 10,
                                          marginTop: {
                                            minWidth: Breakpoints.Laptop,
                                            value: 0,
                                          },
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre1_generique'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre1_generique'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Ressources'}
                                      </Text>
                                      {/* Lien voir tout */}
                                      <Link
                                        accessible={true}
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'RessourcesProjetScreen',
                                              {
                                                id_projet:
                                                  props.route?.params
                                                    ?.id_transfere ??
                                                  defaultProps.id_transfere,
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        selectable={false}
                                        {...GlobalStyles.LinkStyles(theme)[
                                          'bouton_voir_tout'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.LinkStyles(theme)[
                                            'bouton_voir_tout'
                                          ].style,
                                          dimensions.width
                                        )}
                                        title={'Voir tout'}
                                      />
                                    </View>
                                    {/* Vue cartes */}
                                    <View>
                                      {/* Liste de ressources */}
                                      <SimpleStyleFlatList
                                        data={
                                          fetchLAfficheData?.actus_et_ressources
                                            ?.ressources_projet
                                        }
                                        inverted={false}
                                        keyExtractor={(
                                          listeDeRessourcesData,
                                          index
                                        ) =>
                                          listeDeRessourcesData?.id ??
                                          listeDeRessourcesData?.uuid ??
                                          index?.toString() ??
                                          JSON.stringify(listeDeRessourcesData)
                                        }
                                        keyboardShouldPersistTaps={'never'}
                                        listKey={'EyiMwS1B'}
                                        nestedScrollEnabled={false}
                                        numColumns={1}
                                        onEndReachedThreshold={0.5}
                                        renderItem={({ item, index }) => {
                                          const listeDeRessourcesData = item;
                                          return (
                                            <Touchable
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    (
                                                      await baseXanoMajVuesRessourcePUT.mutateAsync(
                                                        {
                                                          id_ressource:
                                                            listeDeRessourcesData?.id,
                                                        }
                                                      )
                                                    )?.json;
                                                    await WebBrowser.openBrowserAsync(
                                                      `${listeDeRessourcesData?.lien_ressource}`
                                                    );
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                            >
                                              <Surface
                                                {...GlobalStyles.SurfaceStyles(
                                                  theme
                                                )['Surface'].props}
                                                elevation={3}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.SurfaceStyles(
                                                      theme
                                                    )['Surface'].style,
                                                    { borderRadius: 24 }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Course Card */}
                                                <View
                                                  {...GlobalStyles.ViewStyles(
                                                    theme
                                                  )['course_card_ressource']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )['course_card_ressource']
                                                      .style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {/* Image Frame */}
                                                  <View
                                                    {...GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'image_frame_carte_ressource'
                                                    ].props}
                                                    style={StyleSheet.applyWidth(
                                                      GlobalStyles.ViewStyles(
                                                        theme
                                                      )[
                                                        'image_frame_carte_ressource'
                                                      ].style,
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <ImageBackground
                                                      resizeMode={'cover'}
                                                      source={imageSource(
                                                        `${listeDeRessourcesData?.image_couv?.url}`
                                                      )}
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          height: '100%',
                                                          width: '100%',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Overlay */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            alignItems:
                                                              'center',
                                                            flexDirection:
                                                              'row',
                                                            justifyContent:
                                                              'space-between',
                                                            paddingBottom: '5%',
                                                            paddingLeft: '5%',
                                                            paddingRight: '5%',
                                                            paddingTop: '5%',
                                                          },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Rating */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'rating_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'rating_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            onPress={newCheckboxValue => {
                                                              const handler =
                                                                async () => {
                                                                  const checkboxValue =
                                                                    newCheckboxValue;
                                                                  try {
                                                                    (
                                                                      await baseXanoMajLikesRessourcesPUT.mutateAsync(
                                                                        {
                                                                          id_ressource:
                                                                            listeDeRessourcesData?.id,
                                                                        }
                                                                      )
                                                                    )?.json;
                                                                    await refetchActusEtRessourcesParProjet();
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                };
                                                              handler();
                                                            }}
                                                            checkedIcon={
                                                              'AntDesign/heart'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 4'
                                                              ]
                                                            }
                                                            defaultValue={
                                                              listeDeRessourcesData?.client_likeur
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 16
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'AntDesign/hearto'
                                                            }
                                                          />
                                                          {/* Likes */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listeDeRessourcesData?.nb_likes >=
                                                            1000
                                                              ? `${
                                                                  listeDeRessourcesData?.nb_likes /
                                                                  1000
                                                                }K`
                                                              : listeDeRessourcesData?.nb_likes}
                                                          </Text>

                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {'I'}
                                                          </Text>
                                                          <Icon
                                                            color={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            name={
                                                              'AntDesign/eyeo'
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 18
                                                            }
                                                          />
                                                          {/* Vues */}
                                                          <Text
                                                            accessible={true}
                                                            selectable={false}
                                                            {...GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'item_rating_card_ressource'
                                                            ].props}
                                                            style={StyleSheet.applyWidth(
                                                              GlobalStyles.TextStyles(
                                                                theme
                                                              )[
                                                                'item_rating_card_ressource'
                                                              ].style,
                                                              dimensions.width
                                                            )}
                                                          >
                                                            {listeDeRessourcesData?.nb_vues >=
                                                            1000
                                                              ? `${
                                                                  listeDeRessourcesData?.nb_vues /
                                                                  1000
                                                                }K`
                                                              : listeDeRessourcesData?.nb_vues}
                                                          </Text>
                                                        </View>
                                                        {/* Bookmark */}
                                                        <View
                                                          {...GlobalStyles.ViewStyles(
                                                            theme
                                                          )[
                                                            'bookmark_card_ressource'
                                                          ].props}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.ViewStyles(
                                                              theme
                                                            )[
                                                              'bookmark_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Checkbox
                                                            onPress={newCheckboxValue => {
                                                              const handler =
                                                                async () => {
                                                                  const checkboxValue =
                                                                    newCheckboxValue;
                                                                  try {
                                                                    (
                                                                      await baseXanoMajBookmarkedPUT.mutateAsync(
                                                                        {
                                                                          id_ressource:
                                                                            listeDeRessourcesData?.id,
                                                                        }
                                                                      )
                                                                    )?.json;
                                                                    await refetchActusEtRessourcesParProjet();
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                };
                                                              handler();
                                                            }}
                                                            checkedIcon={
                                                              'Ionicons/bookmark'
                                                            }
                                                            color={
                                                              palettes.App[
                                                                'Flolend 4'
                                                              ]
                                                            }
                                                            defaultValue={
                                                              listeDeRessourcesData?.bookmarked
                                                            }
                                                            size={
                                                              (!(
                                                                dimensions.width >=
                                                                Breakpoints.Desktop
                                                              )
                                                                ? 18
                                                                : dimensions.width >=
                                                                  Breakpoints.BigScreen
                                                                ? 30
                                                                : 25) ?? 24
                                                            }
                                                            uncheckedColor={
                                                              palettes.App[
                                                                'Flolend 2'
                                                              ]
                                                            }
                                                            uncheckedIcon={
                                                              'Ionicons/bookmark-outline'
                                                            }
                                                          />
                                                        </View>
                                                      </View>
                                                    </ImageBackground>
                                                  </View>
                                                  {/* Name */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { marginTop: '5%' },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['nom_card_ressource']
                                                        .props}
                                                      ellipsizeMode={'tail'}
                                                      numberOfLines={2}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'nom_card_ressource'
                                                          ].style,
                                                          { flex: null }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeDeRessourcesData?.titre_1
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Description */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      { marginTop: '5%' },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['corps_card_ressource']
                                                        .props}
                                                      ellipsizeMode={'tail'}
                                                      numberOfLines={3}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'corps_card_ressource'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeDeRessourcesData?.Titre_2
                                                      }
                                                    </Text>
                                                  </View>
                                                  {/* Footer */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                          'space-between',
                                                        marginTop: '5%',
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          flexDirection: 'row',
                                                          gap: 10,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Avatar */}
                                                      <View>
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              borderRadius: 50,
                                                              height: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                              overflow:
                                                                'hidden',
                                                              width: [
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Mobile,
                                                                  value: 40,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.Desktop,
                                                                  value: 45,
                                                                },
                                                                {
                                                                  minWidth:
                                                                    Breakpoints.BigScreen,
                                                                  value: 50,
                                                                },
                                                              ],
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Image
                                                            resizeMode={'cover'}
                                                            {...GlobalStyles.ImageStyles(
                                                              theme
                                                            )['Image'].props}
                                                            source={imageSource(
                                                              `${listeDeRessourcesData?.photo_auteur?.url}`
                                                            )}
                                                            style={StyleSheet.applyWidth(
                                                              StyleSheet.compose(
                                                                GlobalStyles.ImageStyles(
                                                                  theme
                                                                )['Image']
                                                                  .style,
                                                                {
                                                                  height:
                                                                    '100%',
                                                                  width: '100%',
                                                                }
                                                              ),
                                                              dimensions.width
                                                            )}
                                                          />
                                                        </View>
                                                      </View>
                                                      {/* Instructor */}
                                                      <View
                                                        style={StyleSheet.applyWidth(
                                                          { maxWidth: '80%' },
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {/* Name */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'auteur_card_ressource'
                                                          ].props}
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={1}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'auteur_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listeDeRessourcesData?.auteur
                                                          }
                                                        </Text>
                                                        {/* Profession */}
                                                        <Text
                                                          accessible={true}
                                                          selectable={false}
                                                          {...GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'profession_card_ressource'
                                                          ].props}
                                                          ellipsizeMode={'tail'}
                                                          numberOfLines={1}
                                                          style={StyleSheet.applyWidth(
                                                            GlobalStyles.TextStyles(
                                                              theme
                                                            )[
                                                              'profession_card_ressource'
                                                            ].style,
                                                            dimensions.width
                                                          )}
                                                        >
                                                          {
                                                            listeDeRessourcesData?.fonction_auteur
                                                          }
                                                        </Text>
                                                      </View>
                                                    </View>
                                                    {/* Details */}
                                                    <View>
                                                      {/* Duration */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'duree_card_ressource'
                                                        ].props}
                                                        ellipsizeMode={'tail'}
                                                        numberOfLines={1}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'duree_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          listeDeRessourcesData?.duree_min
                                                        }
                                                        {'m'}
                                                      </Text>
                                                      {/* Lessons */}
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        {...GlobalStyles.TextStyles(
                                                          theme
                                                        )['type_card_ressource']
                                                          .props}
                                                        ellipsizeMode={'tail'}
                                                        numberOfLines={1}
                                                        style={StyleSheet.applyWidth(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )[
                                                            'type_card_ressource'
                                                          ].style,
                                                          dimensions.width
                                                        )}
                                                      >
                                                        {
                                                          listeDeRessourcesData?.type_ressource
                                                        }
                                                      </Text>
                                                    </View>
                                                  </View>
                                                </View>
                                              </Surface>
                                            </Touchable>
                                          );
                                        }}
                                        horizontal={true}
                                        scrollEnabled={true}
                                        showsHorizontalScrollIndicator={false}
                                        showsVerticalScrollIndicator={false}
                                        style={StyleSheet.applyWidth(
                                          { gap: 20, paddingBottom: '2%' },
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  </View>
                                </View>
                              </View>
                            )}
                          </>
                        </>
                      );
                    }}
                  </BaseXanoApi.FetchActusEtRessourcesParProjetGET>
                </View>
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <FooterDesktopBlock />
                  )}
                </>
              </SimpleStyleScrollView>
            </>
          );
        }}
      </BaseXanoApi.FetchProjetParIdGET>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(ProjetScreen);
