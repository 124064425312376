import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import * as BaseXanoApi from './apis/BaseXanoApi.js';
import FooterMobileBlock from './components/FooterMobileBlock';
import * as GlobalVariables from './config/GlobalVariableContext';
import isEqualTo from './global-functions/isEqualTo';
import ActualiteScreen from './screens/ActualiteScreen';
import ActualitesClientScreen from './screens/ActualitesClientScreen';
import ActualitesProjetScreen from './screens/ActualitesProjetScreen';
import AlimentationParCarteScreen from './screens/AlimentationParCarteScreen';
import AlimenterMonLivretScreen from './screens/AlimenterMonLivretScreen';
import AnnulationApprovisionnementScreen from './screens/AnnulationApprovisionnementScreen';
import AnnulationSouscriptionScreen from './screens/AnnulationSouscriptionScreen';
import AssistanceScreen from './screens/AssistanceScreen';
import BanqueDocumentsScreen from './screens/BanqueDocumentsScreen';
import CGUScreen from './screens/CGUScreen';
import Categorisation1Screen from './screens/Categorisation1Screen';
import Categorisation2Screen from './screens/Categorisation2Screen';
import ChangementMotDePasseScreen from './screens/ChangementMotDePasseScreen';
import ConnexionScreen from './screens/ConnexionScreen';
import DemarrageClientIntermedieScreen from './screens/DemarrageClientIntermedieScreen';
import DetailsInvestissementsScreen from './screens/DetailsInvestissementsScreen';
import DossierCompletScreen from './screens/DossierCompletScreen';
import DossierInvestisseurScreen from './screens/DossierInvestisseurScreen';
import EcheancierClientScreen from './screens/EcheancierClientScreen';
import EcheancierProjetScreen from './screens/EcheancierProjetScreen';
import ErreurSouscriptionScreen from './screens/ErreurSouscriptionScreen';
import EspacePersonnelScreen from './screens/EspacePersonnelScreen';
import EventailProjetsScreen from './screens/EventailProjetsScreen';
import FiscaliteScreen from './screens/FiscaliteScreen';
import GererMaSouscriptionScreen from './screens/GererMaSouscriptionScreen';
import IBANClientScreen from './screens/IBANClientScreen';
import IndicateursDePerformanceScreen from './screens/IndicateursDePerformanceScreen';
import InformationsDuCompteScreen from './screens/InformationsDuCompteScreen';
import Inscription1Screen from './screens/Inscription1Screen';
import Inscription2Screen from './screens/Inscription2Screen';
import Inscription3Screen from './screens/Inscription3Screen';
import ModifierMesInfosScreen from './screens/ModifierMesInfosScreen';
import MonLivretFlolendScreen from './screens/MonLivretFlolendScreen';
import MoneyOut151ValidationSuppressionIBANScreen from './screens/MoneyOut151ValidationSuppressionIBANScreen';
import MoneyOut152ValidationLabelIBANModifieScreen from './screens/MoneyOut152ValidationLabelIBANModifieScreen';
import MoneyOut15GererMesIBANsScreen from './screens/MoneyOut15GererMesIBANsScreen';
import MoneyOut1RecupererFondsScreen from './screens/MoneyOut1RecupererFondsScreen';
import MoneyOut2UploadIBANScreen from './screens/MoneyOut2UploadIBANScreen';
import MoneyOut3IBANTransmisScreen from './screens/MoneyOut3IBANTransmisScreen';
import MoneyOut4RecapTransfertScreen from './screens/MoneyOut4RecapTransfertScreen';
import MoneyOut5ValidationVirementScreen from './screens/MoneyOut5ValidationVirementScreen';
import MotDePasseOublieScreen from './screens/MotDePasseOublieScreen';
import NotificationsScreen from './screens/NotificationsScreen';
import NousContacterScreen from './screens/NousContacterScreen';
import PaiementCarteAnnuleScreen from './screens/PaiementCarteAnnuleScreen';
import PaiementCarteEnSuspensScreen from './screens/PaiementCarteEnSuspensScreen';
import PaiementParCarteScreen from './screens/PaiementParCarteScreen';
import PolitiquesEtProceduresScreen from './screens/PolitiquesEtProceduresScreen';
import ProfilScreen from './screens/ProfilScreen';
import ProjetScreen from './screens/ProjetScreen';
import QuiSommesNousScreen from './screens/QuiSommesNousScreen';
import ReclamationScreen from './screens/ReclamationScreen';
import ReglementMixteScreen from './screens/ReglementMixteScreen';
import RessourcesClientScreen from './screens/RessourcesClientScreen';
import RessourcesProjetScreen from './screens/RessourcesProjetScreen';
import Simulation1Screen from './screens/Simulation1Screen';
import Simulation2Screen from './screens/Simulation2Screen';
import Simulation3Screen from './screens/Simulation3Screen';
import Souscription12RisquesScreen from './screens/Souscription12RisquesScreen';
import Souscription13ListeDattenteScreen from './screens/Souscription13ListeDattenteScreen';
import Souscription14ValidationAttenteScreen from './screens/Souscription14ValidationAttenteScreen';
import Souscription15ValidationPreSouscriptionScreen from './screens/Souscription15ValidationPreSouscriptionScreen';
import Souscription1Screen from './screens/Souscription1Screen';
import Souscription2Screen from './screens/Souscription2Screen';
import Souscription3Screen from './screens/Souscription3Screen';
import Souscription4Screen from './screens/Souscription4Screen';
import SupprimerMonCompte1Screen from './screens/SupprimerMonCompte1Screen';
import SupprimerMonCompte2Screen from './screens/SupprimerMonCompte2Screen';
import TestDeConnaissances1Screen from './screens/TestDeConnaissances1Screen';
import TestDeConnaissances2Screen from './screens/TestDeConnaissances2Screen';
import TestDeConnaissances3Screen from './screens/TestDeConnaissances3Screen';
import TestDeConnaissances3bisScreen from './screens/TestDeConnaissances3bisScreen';
import ValidationApprovisionnementScreen from './screens/ValidationApprovisionnementScreen';
import ValidationPaiementScreen from './screens/ValidationPaiementScreen';
import ValiderMonKYC1Screen from './screens/ValiderMonKYC1Screen';
import ValiderMonKYC2Screen from './screens/ValiderMonKYC2Screen';
import ValiderMonKYC3Screen from './screens/ValiderMonKYC3Screen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import useWindowDimensions from './utils/useWindowDimensions';
import waitUtil from './utils/wait';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function BottomTabNavigator() {
  const theme = useTheme();

  const dimensions = useWindowDimensions();

  const safeAreaInsets = useSafeAreaInsets();

  const tabBarOrDrawerIcons = {
    EventailProjetsScreen: 'MaterialCommunityIcons/wind-turbine',
    EspacePersonnelScreen: 'FontAwesome/leaf',
    AssistanceScreen: 'AntDesign/customerservice',
  };

  return (
    <Tab.Navigator
      initialRouteName="PageventailprojetsScreen"
      tabBar={({ state, descriptors }) => {
        const mapRoute = route => {
          const descriptor = descriptors[route.key];
          return {
            name: route.name,
            label: descriptor.options.tabBarLabel ?? route.name,
            icon: tabBarOrDrawerIcons[route.name] ?? '',
          };
        };
        const routes = state.routes.map(mapRoute);
        const currentRoute = mapRoute(
          state.routes.find((_, index) => index === state.index)
        );
        return (
          <View>
            <FooterMobileBlock routes={routes} currentRoute={currentRoute} />
          </View>
        );
      }}
      screenOptions={({ navigation }) => ({
        headerShown: false,
        headerTitleStyle: { fontFamily: 'Montserrat_400Regular' },
        tabBarActiveTintColor: palettes.App['Flolend 5'],
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: { fontFamily: 'Montserrat_400Regular' },
        tabBarStyle: {
          backgroundColor: palettes.App.background_flolend,
          borderTopColor: palettes.App.gris_fonce_flolend,
        },
      })}
    >
      <Tab.Screen
        name="EventailProjetsScreen"
        component={EventailProjetsScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="MaterialCommunityIcons/wind-turbine"
              size={25}
              color={focused ? palettes.App['Flolend 4'] : color}
            />
          ),
          tabBarLabel: 'Projets',
          title: 'Eventail projets',
        })}
      />
      <Tab.Screen
        name="EspacePersonnelScreen"
        component={EspacePersonnelScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="FontAwesome/leaf"
              size={25}
              color={focused ? palettes.App['Flolend 4'] : color}
            />
          ),
          tabBarLabel: 'Mon espace',
          title: 'Espace personnel',
        })}
      />
      <Tab.Screen
        name="AssistanceScreen"
        component={AssistanceScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="AntDesign/customerservice"
              size={25}
              color={focused ? palettes.App['Flolend 4'] : color}
            />
          ),
          tabBarLabel: 'Assistance',
          title: 'Assistance',
        })}
      />
    </Tab.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  const dimensions = useWindowDimensions();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#111218ff',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="ConnexionScreen"
        screenOptions={({ navigation }) => ({
          animationEnabled: true,
          cardOverlayEnabled: true,
          cardStyle: {
            flex: 1,
            backgroundColor: palettes.App.background_flolend,
          },
          gestureDirection: 'vertical',
          gestureEnabled: false,
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="TestDeConnaissances1Screen"
          component={TestDeConnaissances1Screen}
          options={({ navigation }) => ({
            title: 'Test de connaissances 1',
          })}
        />
        <Stack.Screen
          name="GererMaSouscriptionScreen"
          component={GererMaSouscriptionScreen}
          options={({ navigation }) => ({
            title: 'Gérer ma souscription',
          })}
        />
        <Stack.Screen
          name="Inscription1Screen"
          component={Inscription1Screen}
          options={({ navigation }) => ({
            title: 'Inscription 1',
          })}
        />
        <Stack.Screen
          name="Souscription4Screen"
          component={Souscription4Screen}
          options={({ navigation }) => ({
            title: 'Souscription 4',
          })}
        />
        <Stack.Screen
          name="TestDeConnaissances3Screen"
          component={TestDeConnaissances3Screen}
          options={({ navigation }) => ({
            title: 'Test de connaissances 3',
          })}
        />
        <Stack.Screen
          name="InformationsDuCompteScreen"
          component={InformationsDuCompteScreen}
          options={({ navigation }) => ({
            title: 'Informations du compte',
          })}
        />
        <Stack.Screen
          name="Souscription3Screen"
          component={Souscription3Screen}
          options={({ navigation }) => ({
            title: 'Souscription 3',
          })}
        />
        <Stack.Screen
          name="TestDeConnaissances2Screen"
          component={TestDeConnaissances2Screen}
          options={({ navigation }) => ({
            title: 'Test de connaissances 2',
          })}
        />
        <Stack.Screen
          name="Souscription1Screen"
          component={Souscription1Screen}
          options={({ navigation }) => ({
            title: 'Souscription 1',
          })}
        />
        <Stack.Screen
          name="ModifierMesInfosScreen"
          component={ModifierMesInfosScreen}
          options={({ navigation }) => ({
            title: 'Modifier mes infos',
          })}
        />
        <Stack.Screen
          name="Inscription2Screen"
          component={Inscription2Screen}
          options={({ navigation }) => ({
            title: 'Inscription 2',
          })}
        />
        <Stack.Screen
          name="ConnexionScreen"
          component={ConnexionScreen}
          options={({ navigation }) => ({
            title: 'Connexion',
          })}
        />
        <Stack.Screen
          name="ActualiteScreen"
          component={ActualiteScreen}
          options={({ navigation }) => ({
            title: 'Actualité',
          })}
        />
        <Stack.Screen
          name="ProjetScreen"
          component={ProjetScreen}
          options={({ navigation }) => ({
            title: 'Projet',
          })}
        />
        <Stack.Screen
          name="Souscription13ListeDattenteScreen"
          component={Souscription13ListeDattenteScreen}
          options={({ navigation }) => ({
            title: "Souscription 1.3 (liste d'attente)",
          })}
        />
        <Stack.Screen
          name="Souscription12RisquesScreen"
          component={Souscription12RisquesScreen}
          options={({ navigation }) => ({
            title: 'Souscription 1.2 (risques)',
          })}
        />
        <Stack.Screen
          name="ChangementMotDePasseScreen"
          component={ChangementMotDePasseScreen}
          options={({ navigation }) => ({
            title: 'Changement mot de passe',
          })}
        />
        <Stack.Screen
          name="Souscription14ValidationAttenteScreen"
          component={Souscription14ValidationAttenteScreen}
          options={({ navigation }) => ({
            title: 'Souscription 1.4 (validation attente)',
          })}
        />
        <Stack.Screen
          name="Simulation3Screen"
          component={Simulation3Screen}
          options={({ navigation }) => ({
            title: 'Simulation 3',
          })}
        />
        <Stack.Screen
          name="Inscription3Screen"
          component={Inscription3Screen}
          options={({ navigation }) => ({
            title: 'Inscription 3',
          })}
        />
        <Stack.Screen
          name="TestDeConnaissances3bisScreen"
          component={TestDeConnaissances3bisScreen}
          options={({ navigation }) => ({
            title: 'Test de connaissances 3bis',
          })}
        />
        <Stack.Screen
          name="ActualitesProjetScreen"
          component={ActualitesProjetScreen}
          options={({ navigation }) => ({
            title: 'Actualités projet',
          })}
        />
        <Stack.Screen
          name="RessourcesProjetScreen"
          component={RessourcesProjetScreen}
          options={({ navigation }) => ({
            title: 'Ressources projet',
          })}
        />
        <Stack.Screen
          name="RessourcesClientScreen"
          component={RessourcesClientScreen}
          options={({ navigation }) => ({
            title: 'Ressources client',
          })}
        />
        <Stack.Screen
          name="ActualitesClientScreen"
          component={ActualitesClientScreen}
          options={({ navigation }) => ({
            title: 'Actualités client',
          })}
        />
        <Stack.Screen
          name="ReclamationScreen"
          component={ReclamationScreen}
          options={({ navigation }) => ({
            title: 'Réclamation',
          })}
        />
        <Stack.Screen
          name="Souscription2Screen"
          component={Souscription2Screen}
          options={({ navigation }) => ({
            title: 'Souscription 2',
          })}
        />
        <Stack.Screen
          name="CGUScreen"
          component={CGUScreen}
          options={({ navigation }) => ({
            title: 'CGU',
          })}
        />
        <Stack.Screen
          name="PolitiquesEtProceduresScreen"
          component={PolitiquesEtProceduresScreen}
          options={({ navigation }) => ({
            title: 'Politiques et procédures',
          })}
        />
        <Stack.Screen
          name="QuiSommesNousScreen"
          component={QuiSommesNousScreen}
          options={({ navigation }) => ({
            title: 'Qui sommes nous',
          })}
        />
        <Stack.Screen
          name="BanqueDocumentsScreen"
          component={BanqueDocumentsScreen}
          options={({ navigation }) => ({
            title: 'Banque documents',
          })}
        />
        <Stack.Screen
          name="EcheancierClientScreen"
          component={EcheancierClientScreen}
          options={({ navigation }) => ({
            title: 'Echéancier client',
          })}
        />
        <Stack.Screen
          name="NousContacterScreen"
          component={NousContacterScreen}
          options={({ navigation }) => ({
            title: 'Nous contacter',
          })}
        />
        <Stack.Screen
          name="NotificationsScreen"
          component={NotificationsScreen}
          options={({ navigation }) => ({
            title: 'Notifications',
          })}
        />
        <Stack.Screen
          name="Simulation1Screen"
          component={Simulation1Screen}
          options={({ navigation }) => ({
            title: 'Simulation 1',
          })}
        />
        <Stack.Screen
          name="Simulation2Screen"
          component={Simulation2Screen}
          options={({ navigation }) => ({
            title: 'Simulation 2',
          })}
        />
        <Stack.Screen
          name="DetailsInvestissementsScreen"
          component={DetailsInvestissementsScreen}
          options={({ navigation }) => ({
            title: 'Détails investissements',
          })}
        />
        <Stack.Screen
          name="AnnulationSouscriptionScreen"
          component={AnnulationSouscriptionScreen}
          options={({ navigation }) => ({
            title: 'Annulation souscription',
          })}
        />
        <Stack.Screen
          name="DossierCompletScreen"
          component={DossierCompletScreen}
          options={({ navigation }) => ({
            title: 'Dossier complet',
          })}
        />
        <Stack.Screen
          name="DossierInvestisseurScreen"
          component={DossierInvestisseurScreen}
          options={({ navigation }) => ({
            title: 'Dossier investisseur',
          })}
        />
        <Stack.Screen
          name="MonLivretFlolendScreen"
          component={MonLivretFlolendScreen}
          options={({ navigation }) => ({
            title: 'Mon livret Flolend',
          })}
        />
        <Stack.Screen
          name="EcheancierProjetScreen"
          component={EcheancierProjetScreen}
          options={({ navigation }) => ({
            title: 'Echéancier projet',
          })}
        />
        <Stack.Screen
          name="ProfilScreen"
          component={ProfilScreen}
          options={({ navigation }) => ({
            title: 'Profil',
          })}
        />
        <Stack.Screen
          name="Categorisation1Screen"
          component={Categorisation1Screen}
          options={({ navigation }) => ({
            title: 'Catégorisation 1',
          })}
        />
        <Stack.Screen
          name="Categorisation2Screen"
          component={Categorisation2Screen}
          options={({ navigation }) => ({
            title: 'Catégorisation 2',
          })}
        />
        <Stack.Screen
          name="IndicateursDePerformanceScreen"
          component={IndicateursDePerformanceScreen}
          options={({ navigation }) => ({
            title: 'Indicateurs de performance',
          })}
        />
        <Stack.Screen
          name="Souscription15ValidationPreSouscriptionScreen"
          component={Souscription15ValidationPreSouscriptionScreen}
          options={({ navigation }) => ({
            title: 'Souscription 1.5 (validation pré-souscription)',
          })}
        />
        <Stack.Screen
          name="MotDePasseOublieScreen"
          component={MotDePasseOublieScreen}
          options={({ navigation }) => ({
            title: 'Mot de passe oublié',
          })}
        />
        <Stack.Screen
          name="ValiderMonKYC1Screen"
          component={ValiderMonKYC1Screen}
          options={({ navigation }) => ({
            title: 'Valider mon KYC 1',
          })}
        />
        <Stack.Screen
          name="ValiderMonKYC2Screen"
          component={ValiderMonKYC2Screen}
          options={({ navigation }) => ({
            title: 'Valider mon KYC 2',
          })}
        />
        <Stack.Screen
          name="ValiderMonKYC3Screen"
          component={ValiderMonKYC3Screen}
          options={({ navigation }) => ({
            title: 'Valider mon KYC 3',
          })}
        />
        <Stack.Screen
          name="AlimenterMonLivretScreen"
          component={AlimenterMonLivretScreen}
          options={({ navigation }) => ({
            title: 'Alimenter mon livret',
          })}
        />
        <Stack.Screen
          name="IBANClientScreen"
          component={IBANClientScreen}
          options={({ navigation }) => ({
            title: 'IBAN client',
          })}
        />
        <Stack.Screen
          name="AlimentationParCarteScreen"
          component={AlimentationParCarteScreen}
          options={({ navigation }) => ({
            title: 'Alimentation par carte',
          })}
        />
        <Stack.Screen
          name="PaiementParCarteScreen"
          component={PaiementParCarteScreen}
          options={({ navigation }) => ({
            title: 'Paiement par carte',
          })}
        />
        <Stack.Screen
          name="ValidationApprovisionnementScreen"
          component={ValidationApprovisionnementScreen}
          options={({ navigation }) => ({
            title: 'Validation approvisionnement',
          })}
        />
        <Stack.Screen
          name="AnnulationApprovisionnementScreen"
          component={AnnulationApprovisionnementScreen}
          options={({ navigation }) => ({
            title: 'Annulation approvisionnement',
          })}
        />
        <Stack.Screen
          name="ValidationPaiementScreen"
          component={ValidationPaiementScreen}
          options={({ navigation }) => ({
            title: 'Validation paiement',
          })}
        />
        <Stack.Screen
          name="PaiementCarteAnnuleScreen"
          component={PaiementCarteAnnuleScreen}
          options={({ navigation }) => ({
            title: 'Paiement carte annulé',
          })}
        />
        <Stack.Screen
          name="ReglementMixteScreen"
          component={ReglementMixteScreen}
          options={({ navigation }) => ({
            title: 'Règlement mixte',
          })}
        />
        <Stack.Screen
          name="MoneyOut1RecupererFondsScreen"
          component={MoneyOut1RecupererFondsScreen}
          options={({ navigation }) => ({
            title: 'Money_out 1 : récupérer fonds',
          })}
        />
        <Stack.Screen
          name="MoneyOut2UploadIBANScreen"
          component={MoneyOut2UploadIBANScreen}
          options={({ navigation }) => ({
            title: 'Money_out 2 : upload IBAN',
          })}
        />
        <Stack.Screen
          name="MoneyOut3IBANTransmisScreen"
          component={MoneyOut3IBANTransmisScreen}
          options={({ navigation }) => ({
            title: 'Money_out 3 : IBAN transmis',
          })}
        />
        <Stack.Screen
          name="MoneyOut4RecapTransfertScreen"
          component={MoneyOut4RecapTransfertScreen}
          options={({ navigation }) => ({
            title: 'Money_out 4 : récap transfert',
          })}
        />
        <Stack.Screen
          name="MoneyOut5ValidationVirementScreen"
          component={MoneyOut5ValidationVirementScreen}
          options={({ navigation }) => ({
            title: 'Money_out 5 : validation virement',
          })}
        />
        <Stack.Screen
          name="PaiementCarteEnSuspensScreen"
          component={PaiementCarteEnSuspensScreen}
          options={({ navigation }) => ({
            title: 'Paiement carte en suspens',
          })}
        />
        <Stack.Screen
          name="MoneyOut15GererMesIBANsScreen"
          component={MoneyOut15GererMesIBANsScreen}
          options={({ navigation }) => ({
            title: 'Money_out 1.5 : Gérer mes IBANs',
          })}
        />
        <Stack.Screen
          name="MoneyOut151ValidationSuppressionIBANScreen"
          component={MoneyOut151ValidationSuppressionIBANScreen}
          options={({ navigation }) => ({
            title: 'Money_out 1.51 : validation suppression IBAN',
          })}
        />
        <Stack.Screen
          name="MoneyOut152ValidationLabelIBANModifieScreen"
          component={MoneyOut152ValidationLabelIBANModifieScreen}
          options={({ navigation }) => ({
            title: 'Money_out 1.52 : validation label IBAN modifié',
          })}
        />
        <Stack.Screen
          name="DemarrageClientIntermedieScreen"
          component={DemarrageClientIntermedieScreen}
          options={({ navigation }) => ({
            title: 'Démarrage client intermédié',
          })}
        />
        <Stack.Screen
          name="ErreurSouscriptionScreen"
          component={ErreurSouscriptionScreen}
          options={({ navigation }) => ({
            title: 'Erreur souscription',
          })}
        />
        <Stack.Screen
          name="SupprimerMonCompte1Screen"
          component={SupprimerMonCompte1Screen}
          options={({ navigation }) => ({
            title: 'Supprimer mon compte 1',
          })}
        />
        <Stack.Screen
          name="FiscaliteScreen"
          component={FiscaliteScreen}
          options={({ navigation }) => ({
            title: 'Fiscalité',
          })}
        />
        <Stack.Screen
          name="SupprimerMonCompte2Screen"
          component={SupprimerMonCompte2Screen}
          options={({ navigation }) => ({
            title: 'Supprimer mon compte 2',
          })}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={({ navigation }) => ({
            title: 'Bottom Tab Navigator',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
