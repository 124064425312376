import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Picker,
  Pressable,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as packages_import from '../custom-files/packages_import';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import useWindowDimensions from '../utils/useWindowDimensions';

const ValiderMonKYC1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activation_chargement, setActivation_chargement] =
    React.useState(false);
  const [doc1_2captures, setDoc1_2captures] = React.useState(false);
  const [doc1_capture_unique, setDoc1_capture_unique] = React.useState(false);
  const [doc1_recto_et_verso_fichier, setDoc1_recto_et_verso_fichier] =
    React.useState(null);
  const [doc1_recto_fichier, setDoc1_recto_fichier] = React.useState(null);
  const [doc1_verso_fichier, setDoc1_verso_fichier] = React.useState(null);
  const [doc2_2captures, setDoc2_2captures] = React.useState(false);
  const [doc2_capture_unique, setDoc2_capture_unique] = React.useState(false);
  const [doc2_recto_et_verso_fichier, setDoc2_recto_et_verso_fichier] =
    React.useState(null);
  const [doc2_recto_fichier, setDoc2_recto_fichier] = React.useState(null);
  const [doc2_verso_fichier, setDoc2_verso_fichier] = React.useState(null);
  const [message_erreur_doc1, setMessage_erreur_doc1] = React.useState('');
  const [message_erreur_doc2, setMessage_erreur_doc2] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const [pickerValue2, setPickerValue2] = React.useState('');
  const [succes_doc1, setSucces_doc1] = React.useState(false);
  const [succes_doc2, setSucces_doc2] = React.useState(false);
  // Renvoie true si les docs pour upload existent, false sinon
  const check_documents = (doc1_recto_verso, doc2_recto_verso) => {
    // Vérifie si une valeur est non nulle
    function isNotNull(value) {
      return value !== null && value !== undefined;
    }

    // Renvoie vrai si les docs à uploader existent
    if (isNotNull(doc1_recto_verso) && isNotNull(doc2_recto_verso)) {
      return true;
    }

    // Par défaut, retourne faux si aucune condition n'est remplie
    return false;
  };

  // Ajuste les choix de sélection du type de document afin que l'utilisateur ne puisse choisir 2 fois le même document.
  const conditionnementPickers = (liste, picker) => {
    // Filtrer la liste pour exclure l'élément dont la valeur correspond à picker
    return liste.filter(item => item.value !== picker);
  };

  // Si le doc de KYC est sur 2 pages, prend les 2 pages en entrée et fournit un fichier pdf en sortie.
  const documents_merge = async (fileDataA, fileDataB, title) => {
    // Fonction pour convertir les données base64 en PDF ou image
    const base64ToPdfOrImage = async fileData => {
      const { value: base64Data } = fileData;
      const decodedData = packages_import.decode(base64Data.split(',')[1]);
      const mimeType = base64Data.split(',')[0].split(':')[1].split(';')[0];

      try {
        if (mimeType === 'application/pdf') {
          return await packages_import.PDFDocument.load(decodedData);
        } else {
          const base64String = base64Data.split(',')[1];
          if (!base64String) {
            throw new Error('Les données de base64 sont incorrectes ou vides.');
          }

          // Manipulation de l'image
          const imageResult = await packages_import.manipulateAsync(
            `data:${mimeType};base64,${base64String}`,
            [],
            {
              base64: true,
              format: mimeType.includes('png')
                ? packages_import.SaveFormat.PNG
                : packages_import.SaveFormat.JPEG,
            }
          );

          if (!imageResult || !imageResult.base64) {
            throw new Error("La conversion de l'image a échoué.");
          }

          const pdfDoc = await packages_import.PDFDocument.create();
          const page = pdfDoc.addPage([612, 792]); // Taille d'une page PDF (8.5 x 11 pouces)

          // Calculer les dimensions pour conserver les proportions
          const { width, height } = imageResult;
          const aspectRatio = width / height;
          let displayWidth = 612;
          let displayHeight = 612 / aspectRatio;

          if (displayHeight > 792) {
            displayHeight = 792;
            displayWidth = 792 * aspectRatio;
          }

          const embeddedImage =
            mimeType === 'image/png'
              ? await pdfDoc.embedPng(
                  `data:image/png;base64,${imageResult.base64}`
                )
              : await pdfDoc.embedJpg(
                  `data:image/jpeg;base64,${imageResult.base64}`
                );

          page.drawImage(embeddedImage, {
            x: (612 - displayWidth) / 2, // Centrer l'image horizontalement
            y: (792 - displayHeight) / 2, // Centrer l'image verticalement
            width: displayWidth,
            height: displayHeight,
          });

          return pdfDoc;
        }
      } catch (e) {
        throw new Error(
          `Format non reconnu, veuillez uploader un autre document.`
        );
      }
    };

    try {
      // Convertir les fichiers en objets PDF ou image
      const pdfA = await base64ToPdfOrImage(fileDataA);
      const pdfB = await base64ToPdfOrImage(fileDataB);

      // Créer un nouveau document PDF
      const mergedPdf = await packages_import.PDFDocument.create();

      // Copier les pages des documents A et B dans le document fusionné
      const pagesA = await mergedPdf.copyPages(pdfA, [0]);
      mergedPdf.addPage(pagesA[0]);

      const pagesB = await mergedPdf.copyPages(pdfB, [0]);
      mergedPdf.addPage(pagesB[0]);

      // Renommer le fichier PDF fusionné avec le titre fourni
      const mergedPdfBytes = await mergedPdf.save();
      const mergedPdfBase64 = packages_import.encode(mergedPdfBytes);
      const mergedFile = {
        name: `${title}.pdf`,
        value: `data:application/pdf;base64,${mergedPdfBase64}`,
      };

      return { success: true, fichier: mergedFile };
    } catch (error) {
      return { success: false, message: error.message };
    }
  };

  // Renvoie un objet avec success: true si le fichier est au bon format et à la bonne taille, et false sinon.
  const fichier_valide = async (fileObject, maxPages) => {
    const acceptedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    const fileExtension = fileObject.name.split('.').pop().toLowerCase();

    if (!acceptedExtensions.includes(fileExtension)) {
      return { success: false, message: "L'extension du fichier est invalide" };
    }

    const base64Data = fileObject.value.split(',')[1];
    const fileSizeInBytes = packages_import.decode(base64Data).byteLength;
    const fileSizeInKB = fileSizeInBytes / 1024;

    if (fileSizeInKB < 100 || fileSizeInKB > 4096) {
      return {
        success: false,
        message: 'Le fichier ne respecte pas les critères de poids.',
      };
    }

    if (fileExtension === 'pdf') {
      try {
        const pdfBytes = packages_import.decode(base64Data);
        const pdfDoc = await packages_import.PDFDocument.load(pdfBytes);
        const numPages = pdfDoc.getPageCount();

        if (numPages <= maxPages) {
          return { success: true, message: 'succès' };
        } else {
          return {
            success: false,
            message: 'Le fichier fait plus de 2 pages.',
          };
        }
      } catch (error) {
        console.error('Error processing PDF:', error);
        return { success: false, message: 'PDF illisible' };
      }
    } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
      try {
        const imageUri = `data:image/${fileExtension};base64,${base64Data}`;
        const imageInfo = await packages_import.Image.getSize(imageUri);

        // Assuming no page count limit for images
        return { success: true, message: 'succès' };
      } catch (error) {
        console.error('Error processing image:', error);
        return { success: false, message: 'Image invalide.' };
      }
    }

    return { success: false, message: 'Fichier illisible.' };
  };

  // Supprime le préfixe du buffer du doc
  const supprimer_prefixe_json = jsonData => {
    // Créer une copie du JSON pour éviter de modifier l'original directement
    let modifiedJson = { ...jsonData };

    // Liste des préfixes à supprimer
    const prefixes = [
      'data:application/pdf;base64,',
      'data:image/jpeg;base64,',
      'data:image/png;base64,',
      'data:image/jpg;base64,',
      'data:text/plain;base64,',
    ];

    // Supprimer le préfixe approprié de la clé "value"
    for (let prefix of prefixes) {
      if (modifiedJson.value.startsWith(prefix)) {
        modifiedJson.value = modifiedJson.value.substring(prefix.length);
        break; // Quitter la boucle dès qu'un préfixe est supprimé
      }
    }

    return modifiedJson;
  };

  // Renvoie TRUE si une information manque.
  const verif_completude = (info1, info2, info3, info4, info5) => {
    return (
      info1 === '' ||
      info2 === '' ||
      info3 === '' ||
      info4 === '' ||
      info5 === ''
    );
  };
  const baseXanoUploadKycPOST = BaseXanoApi.useUploadKycPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            marginLeft: [
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.Tablet, value: '10%' },
            ],
            marginRight: [
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.Tablet, value: '10%' },
            ],
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Desktop, value: '20%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Desktop, value: '20%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* En-tête */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'column',
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* entete */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginBottom: { minWidth: Breakpoints.Laptop, value: '2%' },
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '90%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                    dimensions.width
                  )}
                >
                  {'Valider mon identité'}
                </Text>
              </View>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('DossierInvestisseurScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <>
                        {dimensions.width >= Breakpoints.Laptop ? null : (
                          <Icon
                            color={palettes.App.gris_fonce_flolend}
                            name={'Ionicons/exit-outline'}
                            size={18}
                          />
                        )}
                      </>
                    </Touchable>
                  </View>
                )}
              </>
            </View>
          </View>
          {/* Description */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginTop: '2%',
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 24 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Laptop, value: 0 },
                  { minWidth: Breakpoints.Mobile, value: 24 },
                ],
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {
                "Nous vous demandons ici de bien vouloir nous transmettre vos pièces d'identité.\n\nCes documents ne sont pas enregistrés par Flolend mais sont transmis à notre prestataire de service de paiements, Lemonway, dans le cadre de notre dispositif de lutte contre le blanchiment et le financement du terrorisme et conformément à la réglementation.\n\nVos documents doivent être lisibles, en couleur et d'une taille comprise entre 100KB et 4 MB. Ils ne doivent pas faire plus de 2 pages. Les formats acceptés sont : jpg, jpeg, png & pdf."
              }
            </Text>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '0%' },
                paddingBottom: [
                  { minWidth: Breakpoints.Laptop, value: 36 },
                  { minWidth: Breakpoints.Mobile, value: 36 },
                ],
                paddingLeft: [
                  { minWidth: Breakpoints.Laptop, value: 24 },
                  { minWidth: Breakpoints.Mobile, value: 24 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Laptop, value: 24 },
                  { minWidth: Breakpoints.Mobile, value: 24 },
                ],
                paddingTop: [
                  { minWidth: Breakpoints.Laptop, value: 10 },
                  { minWidth: Breakpoints.Mobile, value: 10 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Vue upload */}
            <View>
              {/* Vue doc 1 */}
              <View>
                {/* Titre */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                      paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre1_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                      dimensions.width
                    )}
                  >
                    {"Document d'identité 1"}
                  </Text>
                </View>
                {/* Succès doc 1 */}
                <>
                  {!succes_doc1 ? null : (
                    <View>
                      {/* message success */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['message_erreur']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['message_erreur']
                              .style,
                            { color: palettes.App['Flolend 4'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Votre document a été enregistré.'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Inputs doc 1 */}
                <>
                  {succes_doc1 ? null : (
                    <View>
                      {/* Sélecteur type de doc */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Picker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={
                            theme.colors.background.brand
                          }
                          dropDownBorderColor={theme.colors.border.brand}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.text.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          mode={'native'}
                          onValueChange={newPickerValue => {
                            try {
                              setDoc1_recto_fichier(null);
                              setDoc1_verso_fichier(null);
                              setDoc1_recto_et_verso_fichier(null);
                              setPickerValue(newPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectedIconColor={theme.colors.text.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.PickerStyles(theme)['picker_kyc']
                            .props}
                          options={conditionnementPickers(
                            [
                              {
                                label: "Carte d'identité nationale valide",
                                value: 'carte_id',
                              },
                              { label: 'Passeport valide', value: 'passeport' },
                            ],
                            pickerValue2
                          )}
                          placeholder={'Sélectionnez le type de document'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.PickerStyles(theme)['picker_kyc']
                              .style,
                            dimensions.width
                          )}
                          value={pickerValue}
                        />
                      </View>
                      {/* Sélecteur nombre imports */}
                      <>
                        {!pickerValue ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: '2%',
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* Bouton vue unique select */}
                            <>
                              {!doc1_capture_unique ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre actif_kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre actif_kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur un même document'
                                  }
                                />
                              )}
                            </>
                            {/* Bouton vue unique unselect */}
                            <>
                              {doc1_capture_unique ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    try {
                                      setDoc1_capture_unique(true);
                                      setDoc1_2captures(false);
                                      setDoc1_recto_fichier(null);
                                      setDoc1_verso_fichier(null);
                                      setMessage_erreur_doc1('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre inactif kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre inactif kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur un même document'
                                  }
                                />
                              )}
                            </>
                            {/* Bouton 2 vues unselect */}
                            <>
                              {doc1_2captures ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    try {
                                      setDoc1_2captures(true);
                                      setDoc1_capture_unique(false);
                                      setDoc1_recto_et_verso_fichier(null);
                                      setMessage_erreur_doc1('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre inactif kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre inactif kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur 2 documents différents'
                                  }
                                />
                              )}
                            </>
                            {/* Bouton 2 vues select */}
                            <>
                              {!doc1_2captures ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre actif_kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre actif_kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur 2 documents différents'
                                  }
                                />
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* Vue imports */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: '2%' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 2 imports */}
                        <>
                          {!(doc1_2captures && pickerValue) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { gap: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Vue recto */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                {/* Vue bouton */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 50,
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Recto du document'}
                                    </Text>
                                  </View>
                                  {/* Vue 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '50%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!doc1_recto_fichier ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 4']}
                                          name={'FontAwesome/check-circle-o'}
                                          size={45}
                                        />
                                      )}
                                    </>
                                    <>
                                      {doc1_recto_fichier ? null : (
                                        <LinearGradient
                                          endX={100}
                                          endY={100}
                                          startX={0}
                                          startY={0}
                                          {...GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].props}
                                          color1={palettes.App['Flolend 2']}
                                          color2={palettes.App['Flolend 1']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinearGradientStyles(
                                                theme
                                              )['gradient_bouton'].style,
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                flex: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: null,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: null,
                                                  },
                                                ],
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '2%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 0,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '75%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '100%',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bouton upload doc 1 */}
                                          <Button
                                            accessible={true}
                                            iconPosition={'left'}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setMessage_erreur_doc1('');
                                                  const doc1_recto =
                                                    await selectFileUtil({
                                                      returnNameAndValue: true,
                                                      multiple: false,
                                                      outputBase64: true,
                                                    });
                                                  if (
                                                    doc1_verso_fichier === null
                                                  ) {
                                                    setDoc1_recto_fichier(
                                                      doc1_recto
                                                    );
                                                  } else {
                                                    const doc_fusion =
                                                      await documents_merge(
                                                        doc1_recto,
                                                        doc1_verso_fichier,
                                                        pickerValue
                                                      );
                                                    if (doc_fusion?.success) {
                                                      const fichier_est_valide =
                                                        await fichier_valide(
                                                          doc_fusion?.fichier,
                                                          2
                                                        );
                                                      if (
                                                        fichier_est_valide?.success
                                                      ) {
                                                        setDoc1_recto_fichier(
                                                          doc1_recto
                                                        );
                                                        setDoc1_recto_et_verso_fichier(
                                                          doc_fusion?.fichier
                                                        );
                                                      } else {
                                                        setMessage_erreur_doc1(
                                                          fichier_est_valide?.message
                                                        );
                                                        setDoc1_verso_fichier(
                                                          null
                                                        );
                                                      }
                                                    } else {
                                                      setMessage_erreur_doc1(
                                                        doc_fusion?.message
                                                      );
                                                      setDoc1_verso_fichier(
                                                        null
                                                      );
                                                    }
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['bouton_fond_blanc'].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['bouton_fond_blanc'].style,
                                                {
                                                  borderWidth: 0,
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 22,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 30,
                                                    },
                                                  ],
                                                  marginBottom: 0,
                                                  marginTop: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Charger'}
                                          />
                                        </LinearGradient>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Vue recap */}
                                <>
                                  {!doc1_recto_fichier ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          height: 50,
                                          justifyContent: 'space-between',
                                          marginTop: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexWrap: 'wrap', maxWidth: '50%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'corps_contenu'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {doc1_recto_fichier?.name}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '50%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setDoc1_recto_fichier(null);
                                              setDoc1_recto_et_verso_fichier(
                                                null
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            { maxHeight: '100%' },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={30}
                                          />
                                        </Pressable>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </View>
                              {/* Vue verso */}
                              <View>
                                {/* Vue bouton */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 50,
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Verso du document'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '50%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!doc1_verso_fichier ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 4']}
                                          name={'FontAwesome/check-circle-o'}
                                          size={45}
                                        />
                                      )}
                                    </>
                                    <>
                                      {doc1_verso_fichier ? null : (
                                        <LinearGradient
                                          endX={100}
                                          endY={100}
                                          startX={0}
                                          startY={0}
                                          {...GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].props}
                                          color1={palettes.App['Flolend 2']}
                                          color2={palettes.App['Flolend 1']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinearGradientStyles(
                                                theme
                                              )['gradient_bouton'].style,
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                flex: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: null,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: null,
                                                  },
                                                ],
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '2%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 0,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '75%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '100%',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bouton upload doc 1 */}
                                          <Button
                                            accessible={true}
                                            iconPosition={'left'}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setMessage_erreur_doc1('');
                                                  const doc1_verso =
                                                    await selectFileUtil({
                                                      returnNameAndValue: true,
                                                      multiple: false,
                                                      outputBase64: true,
                                                    });
                                                  if (
                                                    doc1_recto_fichier === null
                                                  ) {
                                                    setDoc1_verso_fichier(
                                                      doc1_verso
                                                    );
                                                  } else {
                                                    const fusion =
                                                      await documents_merge(
                                                        doc1_recto_fichier,
                                                        doc1_verso,
                                                        pickerValue
                                                      );
                                                    if (fusion?.success) {
                                                      const fichier_est_valide =
                                                        await fichier_valide(
                                                          fusion?.fichier,
                                                          2
                                                        );
                                                      if (
                                                        fichier_est_valide?.success
                                                      ) {
                                                        setDoc1_verso_fichier(
                                                          doc1_verso
                                                        );
                                                        setDoc1_recto_et_verso_fichier(
                                                          fusion?.fichier
                                                        );
                                                      } else {
                                                        setMessage_erreur_doc1(
                                                          fichier_est_valide?.message
                                                        );
                                                        setDoc1_recto_fichier(
                                                          null
                                                        );
                                                      }
                                                    } else {
                                                      setMessage_erreur_doc1(
                                                        fusion?.message
                                                      );
                                                      setDoc1_recto_fichier(
                                                        null
                                                      );
                                                    }
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['bouton_fond_blanc'].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['bouton_fond_blanc'].style,
                                                {
                                                  borderWidth: 0,
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 22,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 30,
                                                    },
                                                  ],
                                                  marginBottom: 0,
                                                  marginTop: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Charger'}
                                          />
                                        </LinearGradient>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Vue recap */}
                                <>
                                  {!doc1_verso_fichier ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          height: 50,
                                          justifyContent: 'space-between',
                                          marginTop: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexWrap: 'wrap', maxWidth: '50%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'corps_contenu'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {doc1_verso_fichier?.name}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '50%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setDoc1_verso_fichier(null);
                                              setDoc1_recto_et_verso_fichier(
                                                null
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={30}
                                          />
                                        </Pressable>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          )}
                        </>
                        {/* Vue import unique */}
                        <>
                          {!(doc1_capture_unique && pickerValue) ? null : (
                            <View>
                              {/* masterview */}
                              <View>
                                {/* Vue bouton */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 50,
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Scan du document'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '50%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!doc1_recto_et_verso_fichier ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 4']}
                                          name={'FontAwesome/check-circle-o'}
                                          size={45}
                                        />
                                      )}
                                    </>
                                    <>
                                      {doc1_recto_et_verso_fichier ? null : (
                                        <LinearGradient
                                          endX={100}
                                          endY={100}
                                          startX={0}
                                          startY={0}
                                          {...GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].props}
                                          color1={palettes.App['Flolend 2']}
                                          color2={palettes.App['Flolend 1']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinearGradientStyles(
                                                theme
                                              )['gradient_bouton'].style,
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                flex: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: null,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: null,
                                                  },
                                                ],
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '2%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 0,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '75%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '100%',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bouton upload doc 1 */}
                                          <Button
                                            accessible={true}
                                            iconPosition={'left'}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setMessage_erreur_doc1('');
                                                  const doc1_recto_et_verso =
                                                    await selectFileUtil({
                                                      returnNameAndValue: true,
                                                      multiple: false,
                                                      outputBase64: true,
                                                    });
                                                  const fichier_est_valide =
                                                    await fichier_valide(
                                                      doc1_recto_et_verso,
                                                      2
                                                    );
                                                  if (
                                                    fichier_est_valide?.success
                                                  ) {
                                                    setDoc1_recto_et_verso_fichier(
                                                      doc1_recto_et_verso
                                                    );
                                                  }
                                                  if (
                                                    !fichier_est_valide?.success
                                                  ) {
                                                    setMessage_erreur_doc1(
                                                      fichier_est_valide?.message
                                                    );
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['bouton_fond_blanc'].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['bouton_fond_blanc'].style,
                                                {
                                                  borderWidth: 0,
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 22,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 30,
                                                    },
                                                  ],
                                                  marginBottom: 0,
                                                  marginTop: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Charger'}
                                          />
                                        </LinearGradient>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Vue recap */}
                                <>
                                  {!doc1_recto_et_verso_fichier ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          height: 50,
                                          justifyContent: 'space-between',
                                          marginTop: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexWrap: 'wrap', maxWidth: '50%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'corps_contenu'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {doc1_recto_et_verso_fichier?.name}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '50%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setDoc1_recto_et_verso_fichier(
                                                null
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={30}
                                          />
                                        </Pressable>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          )}
                        </>
                      </View>
                      {/* Erreur doc 1 */}
                      <>
                        {!message_erreur_doc1 ? null : (
                          <View>
                            {/* Message d'erreur */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'message_erreur'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['message_erreur']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {message_erreur_doc1}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
              {/* Vue doc 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: '5%' },
                  dimensions.width
                )}
              >
                {/* Titre */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                      paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre1_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                      dimensions.width
                    )}
                  >
                    {"Document d'identité 2"}
                  </Text>
                </View>
                {/* Succès doc 2 */}
                <>
                  {!succes_doc2 ? null : (
                    <View>
                      {/* message success */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['message_erreur']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['message_erreur']
                              .style,
                            { color: palettes.App['Flolend 4'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Votre document a été enregistré.'}
                      </Text>
                    </View>
                  )}
                </>
                {/* Inputs doc 2 */}
                <>
                  {succes_doc2 ? null : (
                    <View>
                      {/* Sélecteur type de doc */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Picker
                          autoDismissKeyboard={true}
                          dropDownBackgroundColor={
                            theme.colors.background.brand
                          }
                          dropDownBorderColor={theme.colors.border.brand}
                          dropDownBorderRadius={8}
                          dropDownBorderWidth={1}
                          dropDownTextColor={theme.colors.text.strong}
                          iconSize={24}
                          leftIconMode={'inset'}
                          mode={'native'}
                          onValueChange={newPickerValue => {
                            try {
                              setDoc2_recto_fichier(null);
                              setDoc2_verso_fichier(null);
                              setDoc2_recto_et_verso_fichier(null);
                              setPickerValue2(newPickerValue);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                          selectedIconColor={theme.colors.text.strong}
                          selectedIconName={'Feather/check'}
                          selectedIconSize={20}
                          type={'solid'}
                          {...GlobalStyles.PickerStyles(theme)['picker_kyc']
                            .props}
                          options={conditionnementPickers(
                            [
                              {
                                label: "Carte d'identité nationale valide",
                                value: 'carte_id',
                              },
                              { label: 'Passeport valide', value: 'passeport' },
                              {
                                label:
                                  'Permis de conduire en cours de validité',
                                value: 'permis_conduire',
                              },
                            ],
                            pickerValue
                          )}
                          placeholder={'Sélectionnez le type de document'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.PickerStyles(theme)['picker_kyc']
                              .style,
                            dimensions.width
                          )}
                          value={pickerValue2}
                        />
                      </View>
                      {/* Sélecteur nombre imports */}
                      <>
                        {!pickerValue2 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: '2%',
                                paddingLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                paddingRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* Bouton vue unique select */}
                            <>
                              {!doc2_capture_unique ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre actif_kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre actif_kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur un même document'
                                  }
                                />
                              )}
                            </>
                            {/* Bouton vue unique unselect */}
                            <>
                              {doc2_capture_unique ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    try {
                                      setDoc2_capture_unique(true);
                                      setDoc2_2captures(false);
                                      setDoc2_recto_fichier(null);
                                      setDoc2_verso_fichier(null);
                                      setMessage_erreur_doc2('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre inactif kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre inactif kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur un même document'
                                  }
                                />
                              )}
                            </>
                            {/* Bouton 2 vues unselect */}
                            <>
                              {doc2_2captures ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    try {
                                      setDoc2_2captures(true);
                                      setDoc2_capture_unique(false);
                                      setDoc2_recto_et_verso_fichier(null);
                                      setMessage_erreur_doc2('');
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre inactif kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre inactif kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur 2 documents différents'
                                  }
                                />
                              )}
                            </>
                            {/* Bouton 2 vues select */}
                            <>
                              {!doc2_2captures ? null : (
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton filtre actif_kyc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ButtonStyles(theme)[
                                      'bouton filtre actif_kyc'
                                    ].style,
                                    dimensions.width
                                  )}
                                  title={
                                    'Recto et verso sont sur 2 documents différents'
                                  }
                                />
                              )}
                            </>
                          </View>
                        )}
                      </>
                      {/* Vue imports */}
                      <View
                        style={StyleSheet.applyWidth(
                          { marginTop: '2%' },
                          dimensions.width
                        )}
                      >
                        {/* Vue 2 imports */}
                        <>
                          {!(doc2_2captures && pickerValue2) ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                { gap: 10 },
                                dimensions.width
                              )}
                            >
                              {/* Vue recto */}
                              <View>
                                {/* Vue bouton */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 50,
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Recto du document'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '50%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!doc2_recto_fichier ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 4']}
                                          name={'FontAwesome/check-circle-o'}
                                          size={45}
                                        />
                                      )}
                                    </>
                                    <>
                                      {doc2_recto_fichier ? null : (
                                        <LinearGradient
                                          endX={100}
                                          endY={100}
                                          startX={0}
                                          startY={0}
                                          {...GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].props}
                                          color1={palettes.App['Flolend 2']}
                                          color2={palettes.App['Flolend 1']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinearGradientStyles(
                                                theme
                                              )['gradient_bouton'].style,
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                flex: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: null,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: null,
                                                  },
                                                ],
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '2%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 0,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '75%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '100%',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bouton upload doc 1 */}
                                          <Button
                                            accessible={true}
                                            iconPosition={'left'}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setMessage_erreur_doc2('');
                                                  const doc2_recto =
                                                    await selectFileUtil({
                                                      returnNameAndValue: true,
                                                      multiple: false,
                                                      outputBase64: true,
                                                    });
                                                  if (
                                                    doc2_verso_fichier === null
                                                  ) {
                                                    setDoc2_recto_fichier(
                                                      doc2_recto
                                                    );
                                                  } else {
                                                    const fusion =
                                                      await documents_merge(
                                                        doc2_recto,
                                                        doc2_verso_fichier,
                                                        pickerValue2
                                                      );
                                                    if (fusion?.success) {
                                                      const fichier_est_valide =
                                                        await fichier_valide(
                                                          fusion?.fichier,
                                                          2
                                                        );
                                                      if (
                                                        fichier_est_valide?.success
                                                      ) {
                                                        setDoc2_recto_fichier(
                                                          doc2_recto
                                                        );
                                                        setDoc2_recto_et_verso_fichier(
                                                          fusion?.fichier
                                                        );
                                                      } else {
                                                        setDoc2_verso_fichier(
                                                          null
                                                        );
                                                        setMessage_erreur_doc2(
                                                          fichier_est_valide?.message
                                                        );
                                                      }
                                                    } else {
                                                      setDoc2_verso_fichier(
                                                        null
                                                      );
                                                      setMessage_erreur_doc2(
                                                        fusion?.message
                                                      );
                                                    }
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['bouton_fond_blanc'].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['bouton_fond_blanc'].style,
                                                {
                                                  borderWidth: 0,
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 22,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 30,
                                                    },
                                                  ],
                                                  marginBottom: 0,
                                                  marginTop: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Charger'}
                                          />
                                        </LinearGradient>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Vue recap */}
                                <>
                                  {!doc2_recto_fichier ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          height: 50,
                                          justifyContent: 'space-between',
                                          marginTop: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexWrap: 'wrap', maxWidth: '50%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'corps_contenu'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {doc2_recto_fichier?.name}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '50%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setDoc2_recto_fichier(null);
                                              setDoc2_recto_et_verso_fichier(
                                                null
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={30}
                                          />
                                        </Pressable>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </View>
                              {/* Vue verso */}
                              <View>
                                {/* Vue bouton */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 50,
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Verso du document'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '50%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!doc2_verso_fichier ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 4']}
                                          name={'FontAwesome/check-circle-o'}
                                          size={45}
                                        />
                                      )}
                                    </>
                                    <>
                                      {doc2_verso_fichier ? null : (
                                        <LinearGradient
                                          endX={100}
                                          endY={100}
                                          startX={0}
                                          startY={0}
                                          {...GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].props}
                                          color1={palettes.App['Flolend 2']}
                                          color2={palettes.App['Flolend 1']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinearGradientStyles(
                                                theme
                                              )['gradient_bouton'].style,
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                flex: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: null,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: null,
                                                  },
                                                ],
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '2%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 0,
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '100%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '75%',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bouton upload doc 1 */}
                                          <Button
                                            accessible={true}
                                            iconPosition={'left'}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setMessage_erreur_doc2('');
                                                  const doc2_verso =
                                                    await selectFileUtil({
                                                      returnNameAndValue: true,
                                                      multiple: false,
                                                      outputBase64: true,
                                                    });
                                                  if (
                                                    doc2_recto_fichier === null
                                                  ) {
                                                    setDoc2_verso_fichier(
                                                      doc2_verso
                                                    );
                                                  } else {
                                                    const fusion =
                                                      await documents_merge(
                                                        doc2_recto_fichier,
                                                        doc2_verso,
                                                        pickerValue2
                                                      );
                                                    if (fusion?.success) {
                                                      const fichier_est_valide =
                                                        await fichier_valide(
                                                          fusion?.fichier,
                                                          2
                                                        );
                                                      if (
                                                        fichier_est_valide?.success
                                                      ) {
                                                        setDoc2_verso_fichier(
                                                          doc2_verso
                                                        );
                                                        setDoc2_recto_et_verso_fichier(
                                                          fusion?.fichier
                                                        );
                                                      } else {
                                                        setDoc2_recto_fichier(
                                                          null
                                                        );
                                                        setMessage_erreur_doc2(
                                                          fichier_est_valide?.message
                                                        );
                                                      }
                                                    } else {
                                                      setDoc2_recto_fichier(
                                                        null
                                                      );
                                                      setMessage_erreur_doc2(
                                                        fusion?.message
                                                      );
                                                    }
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['bouton_fond_blanc'].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['bouton_fond_blanc'].style,
                                                {
                                                  borderWidth: 0,
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 22,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 30,
                                                    },
                                                  ],
                                                  marginBottom: 0,
                                                  marginTop: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Charger'}
                                          />
                                        </LinearGradient>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Vue recap */}
                                <>
                                  {!doc2_verso_fichier ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          height: 50,
                                          justifyContent: 'space-between',
                                          marginTop: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexWrap: 'wrap', maxWidth: '50%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'corps_contenu'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {doc2_verso_fichier?.name}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '50%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setDoc2_verso_fichier(null);
                                              setDoc2_recto_et_verso_fichier(
                                                null
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={30}
                                          />
                                        </Pressable>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          )}
                        </>
                        {/* Vue import unique */}
                        <>
                          {!(doc2_capture_unique && pickerValue2) ? null : (
                            <View>
                              {/* masterview */}
                              <View>
                                {/* Vue bouton */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      height: 50,
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Scan du document'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'center',
                                        width: '50%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!doc2_recto_et_verso_fichier ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 4']}
                                          name={'FontAwesome/check-circle-o'}
                                          size={45}
                                        />
                                      )}
                                    </>
                                    <>
                                      {doc2_recto_et_verso_fichier ? null : (
                                        <LinearGradient
                                          endX={100}
                                          endY={100}
                                          startX={0}
                                          startY={0}
                                          {...GlobalStyles.LinearGradientStyles(
                                            theme
                                          )['gradient_bouton'].props}
                                          color1={palettes.App['Flolend 2']}
                                          color2={palettes.App['Flolend 1']}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.LinearGradientStyles(
                                                theme
                                              )['gradient_bouton'].style,
                                              {
                                                alignSelf: {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 'center',
                                                },
                                                flex: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: null,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: null,
                                                  },
                                                ],
                                                marginBottom: {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 0,
                                                },
                                                marginTop: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 0,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '2%',
                                                  },
                                                ],
                                                width: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: '100%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: '50%',
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: '75%',
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Bouton upload doc 1 */}
                                          <Button
                                            accessible={true}
                                            iconPosition={'left'}
                                            onPress={() => {
                                              const handler = async () => {
                                                try {
                                                  setMessage_erreur_doc2('');
                                                  const doc2_recto_et_verso =
                                                    await selectFileUtil({
                                                      returnNameAndValue: true,
                                                      multiple: false,
                                                      outputBase64: true,
                                                    });
                                                  const fichier_est_valide =
                                                    await fichier_valide(
                                                      doc2_recto_et_verso,
                                                      2
                                                    );
                                                  if (
                                                    fichier_est_valide?.success
                                                  ) {
                                                    setDoc2_recto_et_verso_fichier(
                                                      doc2_recto_et_verso
                                                    );
                                                  }
                                                  if (
                                                    !fichier_est_valide?.success
                                                  ) {
                                                    setMessage_erreur_doc2(
                                                      fichier_est_valide?.message
                                                    );
                                                  }
                                                } catch (err) {
                                                  console.error(err);
                                                }
                                              };
                                              handler();
                                            }}
                                            {...GlobalStyles.ButtonStyles(
                                              theme
                                            )['bouton_fond_blanc'].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ButtonStyles(
                                                  theme
                                                )['bouton_fond_blanc'].style,
                                                {
                                                  borderWidth: 0,
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 22,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 30,
                                                    },
                                                  ],
                                                  marginBottom: 0,
                                                  marginTop: 0,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                            title={'Charger'}
                                          />
                                        </LinearGradient>
                                      )}
                                    </>
                                  </View>
                                </View>
                                {/* Vue recap */}
                                <>
                                  {!doc2_recto_et_verso_fichier ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          height: 50,
                                          justifyContent: 'space-between',
                                          marginTop: '2%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { flexWrap: 'wrap', maxWidth: '50%' },
                                          dimensions.width
                                        )}
                                      >
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].props}
                                          ellipsizeMode={'tail'}
                                          numberOfLines={1}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'corps_contenu'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {doc2_recto_et_verso_fichier?.name}
                                        </Text>
                                      </View>
                                      {/* View 2 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            height: '100%',
                                            justifyContent: 'center',
                                            width: '50%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Pressable
                                          onPress={() => {
                                            try {
                                              setDoc2_recto_et_verso_fichier(
                                                null
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                        >
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={30}
                                          />
                                        </Pressable>
                                      </View>
                                    </View>
                                  )}
                                </>
                              </View>
                            </View>
                          )}
                        </>
                      </View>
                      {/* Erreur doc 2 */}
                      <>
                        {!message_erreur_doc2 ? null : (
                          <View>
                            {/* Message d'erreur */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'message_erreur'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['message_erreur']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {message_erreur_doc2}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                  )}
                </>
              </View>
            </View>
            {/* Vue bouton validation */}
            <>
              {!(
                check_documents(
                  doc1_recto_et_verso_fichier,
                  doc2_recto_et_verso_fichier
                ) && !activation_chargement
              ) ? null : (
                <View>
                  <LinearGradient
                    endX={100}
                    endY={100}
                    startX={0}
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'gradient_bouton'
                    ].props}
                    color1={palettes.App['Flolend 2']}
                    color2={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          flex: [
                            { minWidth: Breakpoints.Laptop, value: null },
                            { minWidth: Breakpoints.Mobile, value: null },
                          ],
                          marginBottom: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          marginTop: [
                            { minWidth: Breakpoints.Laptop, value: '2%' },
                            { minWidth: Breakpoints.Mobile, value: null },
                          ],
                          width: [
                            { minWidth: Breakpoints.Tablet, value: '50%' },
                            { minWidth: Breakpoints.Laptop, value: '75%' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Bouton upload */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setActivation_chargement(true);
                            const retour_api = (
                              await baseXanoUploadKycPOST.mutateAsync({
                                doc_1_recto_et_verso: supprimer_prefixe_json(
                                  doc1_recto_et_verso_fichier
                                ),
                                doc_2_recto_et_verso: supprimer_prefixe_json(
                                  doc2_recto_et_verso_fichier
                                ),
                                type_doc1: pickerValue,
                                type_doc2: pickerValue2,
                              })
                            )?.json;
                            if (retour_api?.kyc_complet) {
                              if (!retour_api?.dossier_complet) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('ValiderMonKYC2Screen');
                              }
                              if (retour_api?.dossier_complet) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('DossierCompletScreen');
                              }
                            } else {
                              if (retour_api?.kyc_en_attente) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('ValiderMonKYC3Screen');
                              } else {
                                if (retour_api?.retour_doc1?.success) {
                                  setSucces_doc1(true);
                                } else {
                                  setMessage_erreur_doc1(
                                    retour_api?.retour_doc1?.message
                                  );
                                }

                                if (retour_api?.retour_doc2?.success) {
                                  setSucces_doc2(true);
                                } else {
                                  setMessage_erreur_doc2(
                                    retour_api?.retour_doc2?.message
                                  );
                                }
                              }
                            }

                            setActivation_chargement(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                            .style,
                          {
                            borderWidth: 0,
                            fontSize: [
                              { minWidth: Breakpoints.Tablet, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 30 },
                            ],
                            marginBottom: 0,
                            marginTop: 0,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Valider'}
                    />
                  </LinearGradient>
                </View>
              )}
            </>
            {/* Chargement */}
            <>
              {!activation_chargement ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    animating={true}
                    color={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App.blanc_flolend,
                          fontFamily: 'PTSans_700Bold',
                          paddingLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Chargement en cours...'}
                  </Text>
                </View>
              )}
            </>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ValiderMonKYC1Screen);
