export default {
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Inter_700Bold: require('../assets/fonts/Inter_700Bold.ttf'),
  Montserrat_500Medium: require('../assets/fonts/Montserrat_500Medium.ttf'),
  Montserrat_700Bold: require('../assets/fonts/Montserrat_700Bold.ttf'),
  Montserrat_400Regular: require('../assets/fonts/Montserrat_400Regular.ttf'),
  Montserrat_600SemiBold: require('../assets/fonts/Montserrat_600SemiBold.ttf'),
  Montserrat_400Regular_Italic: require('../assets/fonts/Montserrat_400Regular_Italic.ttf'),
  Montserrat_300Light: require('../assets/fonts/Montserrat_300Light.ttf'),
  PTSans_400Regular: require('../assets/fonts/PTSans_400Regular.ttf'),
  PTSans_700Bold: require('../assets/fonts/PTSans_700Bold.ttf'),
  PTSans_700Bold_Italic: require('../assets/fonts/PTSans_700Bold_Italic.ttf'),
  PTSans_400Regular_Italic: require('../assets/fonts/PTSans_400Regular_Italic.ttf'),
  Rubik_700Bold: require('../assets/fonts/Rubik_700Bold.ttf'),
};
