import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const SupprimerMonCompte2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.background_flolend,
          },
          justifyContent: 'center',
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            color3={palettes.App['Flolend 4']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                { alignItems: 'center', width: '100%' }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.background.brand,
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      height: 100,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Flolend 2']}
                    name={'Ionicons/checkmark-circle'}
                    size={75}
                  />
                </View>
              </View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'PTSans_700Bold',
                    fontSize: [
                      { minWidth: Breakpoints.Tablet, value: 22 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Votre demande de suppression a bien été reçue.'}
              </Text>
              {/* msg 2 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'PTSans_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    lineHeight: 25,
                    marginBottom: 20,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  "Elle sera traitée sous les plus brefs délais. Dans l'attente, vous pouvez accéder à votre espace depuis la plateforme web Flolend."
                }
              </Text>
              {/* bouton retour dossier investisseur */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'auth_header',
                      value: '',
                    });
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('ConnexionScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: palettes.App['Flolend 4'],
                    borderRadius: 24,
                    borderWidth: 1,
                    color: theme.colors.background.brand,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    height: 46,
                    marginBottom: 30,
                    textAlign: 'center',
                    width: 350,
                  },
                  dimensions.width
                )}
                title={"Retour à l'accueil"}
              />
            </View>
          </LinearGradient>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(SupprimerMonCompte2Screen);
