import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { capacite_endettement: 1000, patrimoine: 10000 };

const Simulation2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activation_bouton, setActivation_bouton] = React.useState(false);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [checkboxValue2, setCheckboxValue2] = React.useState(false);
  // Renvoie le max
  const max = (a, b) => {
    return a > b ? a : b;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* En-tête */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 10 },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
            },
            dimensions.width
          )}
        >
          {/* entete */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '2%',
                marginLeft: [
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '80%' }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_pipe_simulation']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_pipe_simulation'].style,
                  dimensions.width
                )}
              >
                {'Simulation de la capacité à supporter des pertes'}
              </Text>
            </View>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DossierInvestisseurScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      color={palettes.App.gris_fonce_flolend}
                      name={'Ionicons/exit-outline'}
                      size={18}
                    />
                  </Touchable>
                </View>
              )}
            </>
          </View>
          {/* frise */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App.gris_encart_int_flolend,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                ],
                justifyContent: 'space-evenly',
                paddingBottom: 5,
                paddingTop: 5,
              },
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['frise_simul_titre_etape']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['frise_simul_titre_etape'].style,
                dimensions.width
              )}
            >
              {'Etape 2/3 : Résultats de la simulation'}
            </Text>
            <LinearProgress
              animationDuration={500}
              indeterminate={false}
              isAnimated={true}
              lineCap={'round'}
              showTrack={true}
              thickness={10}
              trackLineCap={'round'}
              color={palettes.App['Flolend 4']}
              style={StyleSheet.applyWidth(
                { alignSelf: 'center', width: '95%' },
                dimensions.width
              )}
              trackColor={palettes.App.gris_fonce_flolend}
              value={66.6}
            />
          </View>
        </View>
        {/* Masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Desktop, value: '20%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Desktop, value: '20%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 15 },
                { minWidth: Breakpoints.Mobile, value: 15 },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Vue résultats */}
          <View>
            {/* Résultats */}
            <View
              style={StyleSheet.applyWidth(
                {
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Label 5 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {'Votre capacité à supporter des pertes'}
              </Text>
              {/* Patrimoine net */}
              <View>
                {/* Label 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                    dimensions.width
                  )}
                >
                  {'Votre patrimoire net calculé est de :'}
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Flolend 2'],
                        fontFamily: 'PTSans_700Bold',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 32 },
                          { minWidth: Breakpoints.Laptop, value: 36 },
                          { minWidth: Breakpoints.Desktop, value: 40 },
                          { minWidth: Breakpoints.BigScreen, value: 44 },
                        ],
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {format_nombre(
                    max(
                      props.route?.params?.patrimoine ??
                        defaultProps.patrimoine,
                      0
                    ),
                    undefined
                  )}
                  {' €'}
                </Text>
              </View>
              {/* Divider 3 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
              {/* Capacité pertes */}
              <View>
                {/* Label 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                    dimensions.width
                  )}
                >
                  {
                    'Votre capacité à supporter des pertes est calculée comme suit, sur la base des informations transmises :'
                  }
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row', gap: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Flolend 2'],
                          fontFamily: 'PTSans_700Bold',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 32 },
                            { minWidth: Breakpoints.Laptop, value: 36 },
                            { minWidth: Breakpoints.Desktop, value: 40 },
                            { minWidth: Breakpoints.BigScreen, value: 44 },
                          ],
                          textAlign: 'justify',
                          whiteSpace: 'pre-line',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'10 %'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['question_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['question_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {'du patrimoine net calculé.'}
                  </Text>
                </View>
              </View>
              {/* Divider 4 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
              {/* Palier recommandé */}
              <View>
                {/* Label 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                    dimensions.width
                  )}
                >
                  {
                    'Suite à votre simulation, nous recommandons que vos investissements ne dépassent pas :'
                  }
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App['Flolend 2'],
                        fontFamily: 'PTSans_700Bold',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 32 },
                          { minWidth: Breakpoints.Laptop, value: 36 },
                          { minWidth: Breakpoints.Desktop, value: 40 },
                          { minWidth: Breakpoints.BigScreen, value: 44 },
                        ],
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {format_nombre(
                    max(
                      props.route?.params?.capacite_endettement ??
                        defaultProps.capacite_endettement,
                      0
                    ),
                    undefined
                  )}
                  {' €'}
                </Text>
              </View>
              {/* Divider 5 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
              {/* A cocher */}
              <View>
                {/* Déclaration */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: 'row',
                      gap: 5,
                      maxWidth: '90%',
                    },
                    dimensions.width
                  )}
                >
                  <Checkbox
                    onPress={newCheckboxValue => {
                      try {
                        const valueJYUbJG3a = newCheckboxValue;
                        setCheckboxValue(valueJYUbJG3a);
                        const resultat = valueJYUbJG3a;
                        if (resultat && checkboxValue2) {
                          setActivation_bouton(true);
                        }
                        if (!resultat || !checkboxValue2) {
                          setActivation_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Flolend 4']}
                    status={checkboxValue}
                    uncheckedColor={palettes.App.gris_fonce_flolend}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['question_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['question_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {
                      'Je déclare avoir pris connaissance des résultats de ma simulation et de ma capacité à supporter des pertes.'
                    }
                  </Text>
                </View>
                {/* Divider 6 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.DividerStyles(theme)['Divider'].style,
                      { marginBottom: 12, marginTop: 12 }
                    ),
                    dimensions.width
                  )}
                />
                {/* Déclaration 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      flexDirection: 'row',
                      gap: 5,
                      maxWidth: '90%',
                    },
                    dimensions.width
                  )}
                >
                  <Checkbox
                    onPress={newCheckboxValue => {
                      try {
                        const valueMqpOTWrS = newCheckboxValue;
                        setCheckboxValue2(valueMqpOTWrS);
                        const resultat = valueMqpOTWrS;
                        if (resultat && checkboxValue) {
                          setActivation_bouton(true);
                        }
                        if (!resultat || !checkboxValue) {
                          setActivation_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Flolend 4']}
                    status={checkboxValue2}
                    uncheckedColor={palettes.App.gris_fonce_flolend}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['question_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['question_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {
                      "J'autorise Flolend à enregistrer le résultat de cette simulation."
                    }
                  </Text>
                </View>
                {/* Divider 7 */}
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
              </View>
            </View>

            <LinearGradient
              endX={100}
              endY={100}
              startX={0}
              startY={0}
              {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                .props}
              color1={
                (activation_bouton
                  ? palettes.App['Flolend 1']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 1']
              }
              color2={
                (activation_bouton
                  ? palettes.App['Flolend 2']
                  : palettes.App.gris_fonce_flolend) ??
                palettes.App['Flolend 2']
              }
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                    .style,
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    alignSelf: {
                      minWidth: Breakpoints.Tablet,
                      value: 'center',
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                    flex: null,
                    height: [
                      { minWidth: Breakpoints.Desktop, value: 65 },
                      { minWidth: Breakpoints.Laptop, value: 60 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '0%' },
                    ],
                    marginTop: '2%',
                    overflow: { minWidth: Breakpoints.Laptop, value: 'hidden' },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '75%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {/* Bouton étape suivante */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (!activation_bouton) {
                      return;
                    }
                    navigation.navigate('Simulation3Screen', {
                      patrimoine_net:
                        props.route?.params?.patrimoine ??
                        defaultProps.patrimoine,
                      capacite_endettement:
                        props.route?.params?.capacite_endettement ??
                        defaultProps.capacite_endettement,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                    {
                      borderRadius: {
                        minWidth: Breakpoints.Laptop,
                        value: null,
                      },
                      fontSize: { minWidth: Breakpoints.BigScreen, value: 30 },
                      height: [
                        { minWidth: Breakpoints.Desktop, value: 60 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={'Etape suivante'}
              />
            </LinearGradient>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(Simulation2Screen);
