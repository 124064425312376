import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const EcheancierClientScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  // Trie une liste d'objets selon leur propriété "Date".
  const trierParDate = listeObjets => {
    // Utilisation de la méthode sort() avec une fonction de comparaison pour trier les objets
    listeObjets.sort(function (a, b) {
      // Comparaison des dates
      if (a.Date !== b.Date) {
        return a.Date - b.Date;
      } else {
        // Si les dates sont égales, vérifier le type d'événement
        if (
          a.type_evenement === 'Interets' &&
          b.type_evenement !== 'Interets'
        ) {
          return -1; // a avant b
        } else if (
          a.type_evenement !== 'Interets' &&
          b.type_evenement === 'Interets'
        ) {
          return 1; // b avant a
        } else {
          return 0; // Ordre inchangé
        }
      }
    });

    return listeObjets;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* header_ech_phone */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: [
                  { minWidth: Breakpoints.Mobile, value: '2%' },
                  { minWidth: Breakpoints.Laptop, value: '1%' },
                ],
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginTop: [
                  { minWidth: Breakpoints.BigScreen, value: '1%' },
                  { minWidth: Breakpoints.Laptop, value: '1%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 18
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 35
                      : 30
                  }
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['screen_heading'].style,
                  { fontSize: { minWidth: Breakpoints.Laptop, value: 16 } }
                ),
                dimensions.width
              )}
            >
              {'Mon échéancier'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            marginBottom: [
              { minWidth: Breakpoints.Mobile, value: '2%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            marginLeft: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '10%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            marginRight: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '10%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* header_ech_ordi */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                      .style,
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].props,
                      dimensions.width
                    )}
                    name={'AntDesign/arrowleft'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['texte_retour_ordi']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['texte_retour_ordi'].style,
                      dimensions.width
                    )}
                  >
                    {'Retour'}
                  </Text>
                </View>
              </Pressable>
            </View>
          )}
        </>
        {/* Fetch échéancier */}
        <BaseXanoApi.FetchÉchéancierDuClientGET>
          {({ loading, error, data, refetchÉchéancierDuClient }) => {
            const fetchChAncierData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Vue schéma */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                      padding: { minWidth: Breakpoints.Laptop, value: 20 },
                      width: { minWidth: Breakpoints.Laptop, value: '35%' },
                    },
                    dimensions.width
                  )}
                >
                  <SimpleStyleFlatList
                    data={trierParDate(fetchChAncierData)}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'rAHhV3pa'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* Vue générale */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue date */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '25%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '30%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Montant invest */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Investissement'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montant_echeancier'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'- '}
                                    {listData?.montant >= 10000
                                      ? `${format_nombre(
                                          listData?.montant / 1000,
                                          2
                                        )} K€`
                                      : `${format_nombre(
                                          listData?.montant,
                                          2
                                        )} €`}
                                  </Text>
                                )}
                              </>
                              {/* Montant intérêts */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Interets'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montant_echeancier'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'+ '}
                                    {listData?.montant >= 10000
                                      ? `${format_nombre(
                                          listData?.montant / 1000,
                                          2
                                        )} K€`
                                      : `${format_nombre(
                                          listData?.montant,
                                          2
                                        )} €`}
                                  </Text>
                                )}
                              </>
                              {/* Montant remboursement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Remboursement'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montant_echeancier'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'+ '}
                                    {listData?.montant >= 10000
                                      ? `${format_nombre(
                                          listData?.montant / 1000,
                                          2
                                        )} K€`
                                      : `${format_nombre(
                                          listData?.montant,
                                          2
                                        )} €`}
                                  </Text>
                                )}
                              </>
                              {/* Date */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'sous_elt_echeancier'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'sous_elt_echeancier'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {ConvertionDate(listData?.Date)}
                              </Text>
                            </View>
                            {/* Vue ligne */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flex: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 1,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Cercle remboursement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Remboursement'
                                ) ? null : (
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'cercle_remboursement'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'cercle_remboursement'
                                        ].style,
                                        {
                                          height: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Cercle investissement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Investissement'
                                ) ? null : (
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'cercle_investissement'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'cercle_investissement'
                                        ].style,
                                        {
                                          height: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Cercle interets */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Interets'
                                ) ? null : (
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'cercle_remboursement'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'cercle_remboursement'
                                        ].style,
                                        {
                                          height: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Ligne */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: palettes.App['Flolend 5'],
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 1,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 1,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 1,
                                      },
                                    ],
                                    width: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 4,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 5,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 7,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 9,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Vue événements */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  width: [
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '60%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '50%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Titre1 */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'montant_echeancier'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].style,
                                    {
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 24,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 28,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.Titre1}
                              </Text>
                              {/* Corps */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'sous_elt_echeancier'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'sous_elt_echeancier'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {listData?.Corps}
                              </Text>
                              <Spacer left={8} right={8} bottom={12} top={12} />
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                  />
                </View>
              </>
            );
          }}
        </BaseXanoApi.FetchÉchéancierDuClientGET>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(EcheancierClientScreen);
