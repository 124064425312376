import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  NumberInput,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const Simulation1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [InputActifsLiquides, setInputActifsLiquides] = React.useState('');
  const [InputEngFin, setInputEngFin] = React.useState('');
  const [InputRevenus, setInputRevenus] = React.useState('');
  const [bouton_actif, setBouton_actif] = React.useState(false);
  const [capacite, setCapacite] = React.useState(0);
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [patrimoine, setPatrimoine] = React.useState(0);
  // Calcule le montant max qu'il est recommandé au client d'investir.
  const Capacite_endettement = patrimoine_net => {
    const capacite = Math.max(0, 0.1 * patrimoine_net);
    return capacite;
  };

  // Calcule le patrimoine net.
  const Patrimoine_net = (revenus, actifsLiquides, engagements) => {
    // Vérifier si les paramètres sont des nombres réels
    if (
      typeof revenus !== 'number' ||
      typeof actifsLiquides !== 'number' ||
      typeof engagements !== 'number'
    ) {
      throw new Error('Les paramètres doivent être des nombres réels.');
    }

    // Calculer le solde en utilisant la formule donnée
    const solde = revenus + actifsLiquides - engagements;

    return solde;
  };

  // Renvoie TRUE si toutes les entrées sont complétées et FALSE à défaut.
  const completude_entrees = (param1, param2, param3, paramBool) => {
    // Vérifie si un des paramètres texte est vide ou si le booléen est faux
    if (param1 === '' || param2 === '' || param3 === '' || !paramBool) {
      return false;
    }

    // Si toutes les conditions sont remplies, retourne vrai
    return true;
  };

  // --Fonction mise hors service car illégal d'enregistrer ces valeurs (cf 2022/2114 - art. 5) -- Associe les valeurs récupérées de la base Airtable aux variables d'écran.
  const init_var_scr_4 = (revenus, actifs_liquides, engagements) => {
    //Revenus
    setInputRevenus(revenus);

    //Actifs liquides
    setInputActifsLiquides(actifs_liquides);

    //Engagements financiers
    setInputEngFin(engagements);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <>
        {!true ? null : (
          <SimpleStyleKeyboardAwareScrollView
            enableAutomaticScroll={false}
            enableOnAndroid={false}
            enableResetScrollToCoords={false}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
            viewIsInsideTabBar={false}
            extraScrollHeight={72}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
              },
              dimensions.width
            )}
          >
            {/* En-tête */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'column',
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: '2%' },
                    { minWidth: Breakpoints.Laptop, value: 10 },
                  ],
                },
                dimensions.width
              )}
            >
              {/* entete */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: [
                      { minWidth: Breakpoints.Mobile, value: '2%' },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                    marginRight: '5%',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { width: '80%' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_pipe_simulation']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_pipe_simulation']
                        .style,
                      dimensions.width
                    )}
                  >
                    {'Simulation de la capacité à supporter des pertes'}
                  </Text>
                </View>
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', justifyContent: 'center' },
                        dimensions.width
                      )}
                    >
                      <Touchable
                        onPress={() => {
                          try {
                            navigation.navigate('DossierInvestisseurScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                      >
                        <Icon
                          color={palettes.App.gris_fonce_flolend}
                          name={'Ionicons/exit-outline'}
                          size={18}
                        />
                      </Touchable>
                    </View>
                  )}
                </>
              </View>
              {/* Vue frise */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.flolend_gris_nuance_encart,
                      },
                    ],
                    justifyContent: 'space-evenly',
                    marginBottom: '2%',
                    paddingBottom: 5,
                    paddingTop: 5,
                  },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['frise_simul_titre_etape']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['frise_simul_titre_etape']
                      .style,
                    dimensions.width
                  )}
                >
                  {'Etape 1/3 : Données financières'}
                </Text>
                <LinearProgress
                  animationDuration={500}
                  indeterminate={false}
                  isAnimated={true}
                  lineCap={'round'}
                  showTrack={true}
                  thickness={10}
                  trackLineCap={'round'}
                  color={palettes.App['Flolend 4']}
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'center', width: '95%' },
                    dimensions.width
                  )}
                  trackColor={palettes.App.gris_fonce_flolend}
                  value={33.3}
                />
              </View>
            </View>
            {/* masterview */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                  justifyContent: [
                    { minWidth: Breakpoints.BigScreen, value: 'space-between' },
                    { minWidth: Breakpoints.Laptop, value: 'space-between' },
                  ],
                  marginLeft: [
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                  ],
                  padding: { minWidth: Breakpoints.Laptop, value: 30 },
                },
                dimensions.width
              )}
            >
              {/* Vue questionnaire */}
              <View>
                {/* Simulation */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginLeft: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '0%' },
                      ],
                      marginRight: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '0%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* Label 5 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre1_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Situation financière'}
                  </Text>
                  {/* Revenu annuel */}
                  <View
                    style={StyleSheet.applyWidth({ gap: 5 }, dimensions.width)}
                  >
                    {/* Label 3 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre_2_generique']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Quel est votre revenu annuel net ?'}
                    </Text>

                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['question_simulation']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['question_simulation']
                          .style,
                        dimensions.width
                      )}
                    >
                      {
                        'Revenu annuel régulier et exceptionnel net, après déduction des cotisations sociales. (montant en €)'
                      }
                    </Text>
                    <NumberInput
                      changeTextDelay={500}
                      onChangeText={newNumberInputValue => {
                        try {
                          setInputRevenus(newNumberInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onChangeTextDelayed={newNumberInputValue => {
                        try {
                          const resultat = completude_entrees(
                            InputEngFin,
                            InputActifsLiquides,
                            InputRevenus,
                            checkboxValue
                          );
                          if (resultat) {
                            setBouton_actif(true);
                          }
                          if (!resultat) {
                            setBouton_actif(false);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.NumberInputStyles(theme)[
                        'encart_input_simul'
                      ].props}
                      placeholder={'Vos revenus'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.NumberInputStyles(theme)[
                          'encart_input_simul'
                        ].style,
                        dimensions.width
                      )}
                      value={InputRevenus}
                    />
                  </View>
                  {/* Divider 3 */}
                  <Divider
                    color={theme.colors.border.brand}
                    {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DividerStyles(theme)['div simul'].style,
                      dimensions.width
                    )}
                  />
                  {/* Actifs liquides */}
                  <View
                    style={StyleSheet.applyWidth({ gap: 5 }, dimensions.width)}
                  >
                    {/* Label 3 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre_2_generique']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Quel est le montant total de vos actifs liquides ?'}
                    </Text>

                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['question_simulation']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['question_simulation']
                          .style,
                        dimensions.width
                      )}
                    >
                      {
                        "Investissements financiers et tous dépôts en espèces, à l'exclusion des biens immobiliers personnels et de rapport, et des fonds de pension. (montant en €)"
                      }
                    </Text>
                    <NumberInput
                      changeTextDelay={500}
                      onChangeText={newNumberInputValue => {
                        try {
                          setInputActifsLiquides(newNumberInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onChangeTextDelayed={newNumberInputValue => {
                        try {
                          const resultat = completude_entrees(
                            InputEngFin,
                            InputActifsLiquides,
                            InputRevenus,
                            checkboxValue
                          );
                          if (resultat) {
                            setBouton_actif(true);
                          }
                          if (!resultat) {
                            setBouton_actif(false);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.NumberInputStyles(theme)[
                        'encart_input_simul'
                      ].props}
                      placeholder={'Vos actifs liquides'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.NumberInputStyles(theme)[
                          'encart_input_simul'
                        ].style,
                        dimensions.width
                      )}
                      value={InputActifsLiquides}
                    />
                  </View>
                  {/* Divider 4 */}
                  <Divider
                    color={theme.colors.border.brand}
                    {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DividerStyles(theme)['div simul'].style,
                      dimensions.width
                    )}
                  />
                  {/* Engagements financiers */}
                  <View
                    style={StyleSheet.applyWidth({ gap: 5 }, dimensions.width)}
                  >
                    {/* Label 3 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre_2_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['titre_2_generique']
                            .style,
                          { flex: 5 }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Quel est le montant de vos engagements financiers annuels ?'
                      }
                    </Text>

                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['question_simulation']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['question_simulation']
                          .style,
                        dimensions.width
                      )}
                    >
                      {
                        'Incluant les engagements récurrents, actuels ou futurs, notamment pensions et prestations, loyers, prêts, paiements récurrents (énergie, abonnements), impôts, etc. (montant en €)'
                      }
                    </Text>
                    <NumberInput
                      changeTextDelay={500}
                      onChangeText={newNumberInputValue => {
                        try {
                          setInputEngFin(newNumberInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onChangeTextDelayed={newNumberInputValue => {
                        try {
                          const resultat = completude_entrees(
                            InputEngFin,
                            InputActifsLiquides,
                            InputRevenus,
                            checkboxValue
                          );
                          if (resultat) {
                            setBouton_actif(true);
                          }
                          if (!resultat) {
                            setBouton_actif(false);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.NumberInputStyles(theme)[
                        'encart_input_simul'
                      ].props}
                      placeholder={'Vos engagements financiers'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.NumberInputStyles(theme)[
                          'encart_input_simul'
                        ].style,
                        dimensions.width
                      )}
                      value={InputEngFin}
                    />
                  </View>
                  {/* Divider 2 */}
                  <Divider
                    color={theme.colors.border.brand}
                    {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DividerStyles(theme)['div simul'].style,
                      dimensions.width
                    )}
                  />
                  {/* Confirmation */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: 'row',
                        gap: 5,
                        maxWidth: [
                          { minWidth: Breakpoints.Mobile, value: 330 },
                          { minWidth: Breakpoints.Laptop, value: '100%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Checkbox
                      onPress={newCheckboxValue => {
                        try {
                          const valueJT22EKQz = newCheckboxValue;
                          setCheckboxValue(valueJT22EKQz);
                          const checkbox_nouveau = valueJT22EKQz;
                          const resultat = completude_entrees(
                            InputEngFin,
                            InputActifsLiquides,
                            InputRevenus,
                            checkbox_nouveau
                          );
                          if (resultat) {
                            setBouton_actif(true);
                          }
                          if (!resultat) {
                            setBouton_actif(false);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      color={palettes.App['Flolend 4']}
                      status={checkboxValue}
                      uncheckedColor={palettes.App.gris_fonce_flolend}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['question_simulation']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['question_simulation']
                          .style,
                        dimensions.width
                      )}
                    >
                      {"Je confirme l'exactitude des informations transmises."}
                    </Text>
                  </View>
                  {/* Divider 6 */}
                  <Divider
                    color={theme.colors.border.brand}
                    {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.DividerStyles(theme)['div simul'].style,
                      dimensions.width
                    )}
                  />
                </View>

                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={
                    (bouton_actif
                      ? palettes.App['Flolend 1']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 1']
                  }
                  color2={
                    (bouton_actif
                      ? palettes.App['Flolend 2']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 2']
                  }
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: { minWidth: Breakpoints.Tablet, value: null },
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        marginLeft: [
                          { minWidth: Breakpoints.Mobile, value: '5%' },
                          { minWidth: Breakpoints.Laptop, value: '0%' },
                        ],
                        marginRight: [
                          { minWidth: Breakpoints.Mobile, value: '5%' },
                          { minWidth: Breakpoints.Laptop, value: '0%' },
                        ],
                        overflow: {
                          minWidth: Breakpoints.Laptop,
                          value: 'hidden',
                        },
                        width: [
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                          { minWidth: Breakpoints.Tablet, value: '50%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Bouton étape suivante */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        if (!bouton_actif) {
                          return;
                        }

                        const valuerFCEd7dS = Patrimoine_net(
                          InputRevenus,
                          InputActifsLiquides,
                          InputEngFin
                        );
                        setPatrimoine(valuerFCEd7dS);
                        const patrimoine_net = valuerFCEd7dS;
                        const valueMrdTFPkf =
                          Capacite_endettement(patrimoine_net);
                        setCapacite(valueMrdTFPkf);
                        const capacite_endettement = valueMrdTFPkf;
                        navigation.navigate('Simulation2Screen', {
                          capacite_endettement: capacite_endettement,
                          patrimoine: patrimoine_net,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Etape suivante'}
                  />
                </LinearGradient>
              </View>
            </View>
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <FooterDesktopBlock />
              )}
            </>
          </SimpleStyleKeyboardAwareScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(Simulation1Screen);
