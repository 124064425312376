import React from 'react';
import { Icon, Pressable, withTheme } from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const FooterDesktopBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [taille_icones_big_screen, setTaille_icones_big_screen] =
    React.useState(45);
  const [taille_icones_desktop, setTaille_icones_desktop] = React.useState(40);
  const [taille_icones_laptop, setTaille_icones_laptop] = React.useState(35);
  // Formate la date du jour pour l'ajouter au lien menant vers Calendly.
  const date_calendly = () => {
    // Obtention de la date actuelle
    var dateActuelle = new Date();

    // Obtention de l'année et du mois de la date actuelle
    var annee = dateActuelle.getFullYear();
    var mois = dateActuelle.getMonth() + 1; // Les mois vont de 0 à 11, donc on ajoute 1

    // Formatage du mois avec un zéro initial si nécessaire
    var moisFormat = mois < 10 ? '0' + mois : mois;

    // Concaténation de l'année et du mois au format "yyyy-mm"
    var resultat = annee + '-' + moisFormat;

    return resultat;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
          justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
          marginBottom: [
            { minWidth: Breakpoints.Laptop, value: 30 },
            { minWidth: Breakpoints.Desktop, value: 50 },
          ],
          marginLeft: { minWidth: Breakpoints.Laptop, value: '5%' },
          marginRight: { minWidth: Breakpoints.Laptop, value: '5%' },
          marginTop: [
            { minWidth: Breakpoints.Laptop, value: 20 },
            { minWidth: Breakpoints.Desktop, value: 50 },
          ],
        },
        dimensions.width
      )}
    >
      {/* Vue en encart */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: [
              { minWidth: Breakpoints.Laptop, value: 'rgba(0, 0, 0, 0)' },
              {
                minWidth: Breakpoints.Tablet,
                value: palettes.App['Flolend 3'],
              },
            ],
            borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 1 },
            borderColor: {
              minWidth: Breakpoints.Laptop,
              value: palettes.App.gris_fonce_flolend,
            },
            borderTopWidth: { minWidth: Breakpoints.Laptop, value: 1 },
            flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            padding: { minWidth: Breakpoints.Laptop, value: '2%' },
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* Fetch doc gen */}
        <BaseXanoApi.FetchDocsSociauxGET>
          {({ loading, error, data, refetchDocsSociaux }) => {
            const fetchDocGenData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Colonne 1 */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: { minWidth: Breakpoints.Laptop, value: '30%' } },
                    dimensions.width
                  )}
                >
                  {/* Titre colonne */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_colonne_footer']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_colonne_footer']
                        .style,
                      dimensions.width
                    )}
                  >
                    {'Réseaux sociaux'}
                  </Text>
                  {/* Réseaux sociaux */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Laptop, value: '5%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${fetchDocGenData?.liens?.instagram}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue Instagram */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/instagram'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['elmt_footer']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'Instagram'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 2 */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${fetchDocGenData?.liens?.twitter}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue X */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'FontAwesome/twitter-square'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['elmt_footer']
                                .style,
                              dimensions.width
                            )}
                          >
                            {'X (Twitter)'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 3 */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${fetchDocGenData?.liens?.facebook}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue Facebook */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'FontAwesome/facebook-square'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Facebook'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 4 */}
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${fetchDocGenData?.liens?.linkedIn}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue LinkedIn */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'FontAwesome/linkedin-square'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'LinkedIn'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                </View>
                {/* Colonne 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: { minWidth: Breakpoints.Laptop, value: '30%' } },
                    dimensions.width
                  )}
                >
                  {/* Titre colonne 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_colonne_footer']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['titre_colonne_footer']
                          .style,
                        {
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 24,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Nous contacter'}
                  </Text>
                  {/* Options de contact */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginTop: {
                          minWidth: Breakpoints.Laptop,
                          value: '5%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `mailto:${fetchDocGenData?.liens?.mail_contact}`
                            );
                            /* hidden 'Open App Link' action */
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue mail */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'Entypo/mail'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: {
                                minWidth: Breakpoints.Laptop,
                                value: 'flex-start',
                              },
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'contact@flolend.com'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>

                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              `${
                                fetchDocGenData?.liens?.calendly_contact
                              }/30min?month=${date_calendly()}`
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue contact */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/customerservice'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Contacter un conseiller'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                </View>
                {/* Colonne 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    { width: { minWidth: Breakpoints.Laptop, value: '30%' } },
                    dimensions.width
                  )}
                >
                  {/* Titre colonne 3 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_colonne_footer']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['titre_colonne_footer']
                          .style,
                        {
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 24,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Ressources'}
                  </Text>
                  {/* Aides et ressources */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        gap: { minWidth: Breakpoints.Laptop, value: 10 },
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 20 },
                          { minWidth: Breakpoints.Laptop, value: '5%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await WebBrowser.openBrowserAsync(
                              'https://www.flolend.com/equipe'
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      {/* Vue qui sommes nous */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'MaterialCommunityIcons/account-group'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Qui sommes-nous ?'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 2 */}
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.navigate('IndicateursDePerformanceScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Indicateurs de performance */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'Ionicons/bar-chart'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Indicateurs de performance'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 3 */}
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.navigate('PolitiquesEtProceduresScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Politiques et procédures */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/book'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Politiques et procédures'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 4 */}
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.navigate('ReclamationScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Réclamations */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/exclamationcircleo'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Déposer une réclamation'}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                    {/* Pressable 5 */}
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.navigate('CGUScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* CGU */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          {/* icone */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/infocirlce'}
                            size={
                              dimensions.width >= Breakpoints.Laptop &&
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? taille_icones_laptop
                                : dimensions.width >= Breakpoints.BigScreen
                                ? taille_icones_big_screen
                                : taille_icones_desktop
                            }
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'flex-start',
                                },
                              ],
                              width: '75%',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['elmt_footer']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['elmt_footer']
                                  .style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {"Conditions générales d'utilisation"}
                          </Text>
                        </View>
                      </View>
                    </Pressable>
                  </View>
                </View>
              </>
            );
          }}
        </BaseXanoApi.FetchDocsSociauxGET>
      </View>
    </View>
  );
};

export default withTheme(FooterDesktopBlock);
