// Prend en entrée le montant que le client veut investir et la valeur d'un titre et renvoie la valeur que le client peut investir effectivement.
const arrondiProduits = (montant_investi, valeur1titre) => {
  //Si l'input est NaN, renvoie 0
  if (isNaN(montant_investi)) {
    return 0;
  }

  // Calcule l'arrondi à l'entier inférieur du montant que veut investir le client divisé par la valeur d'un titre
  const arrondi = Math.floor(montant_investi / valeur1titre);

  // Calcule le produit de l'arrondi et de la valeur d'un titre
  const produit = arrondi * valeur1titre;

  return produit;
};

export default arrondiProduits;
