import React from 'react';
import {
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Table,
  TableCell,
  TableRow,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const PolitiquesEtProceduresScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['header_classique'].style,
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Politiques et procédures'}
            </Text>
          </View>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '2%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '2%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
              minHeight: [
                { minWidth: Breakpoints.Laptop, value: '50%' },
                { minWidth: Breakpoints.BigScreen, value: '60%' },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Screen Heading 2 */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                    {
                      alignSelf: {
                        minWidth: Breakpoints.Laptop,
                        value: 'flex-start',
                      },
                      marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Politiques et procédures'}
              </Text>
            )}
          </>
          {/* Vue tableau documents */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 5 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: 5 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Fetch documents */}
            <BaseXanoApi.FetchPolitiquesGET>
              {({ loading, error, data, refetchPolitiques }) => {
                const fetchDocumentsData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <Table
                    borderColor={theme.colors.border.brand}
                    borderStyle={'solid'}
                    borderWidth={1}
                    cellHorizontalPadding={10}
                    cellVerticalPadding={10}
                    data={fetchDocumentsData}
                    drawBottomBorder={false}
                    drawEndBorder={false}
                    drawStartBorder={false}
                    keyExtractor={(tableData, index) =>
                      tableData?.id ??
                      tableData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(tableData)
                    }
                    listKey={'jf4QKZ7i'}
                    renderItem={({ item, index }) => {
                      const tableData = item;
                      return (
                        <>
                          {/* En-tête */}
                          <TableRow
                            drawBottomBorder={true}
                            drawEndBorder={false}
                            drawTopBorder={false}
                            borderColor={palettes.App.gris_fonce_flolend}
                            borderWidth={2}
                            drawStartBorder={false}
                            isTableHeader={true}
                            style={StyleSheet.applyWidth(
                              { backgroundColor: palettes.App['Flolend 1'] },
                              dimensions.width
                            )}
                          >
                            {/* Table Cell 2 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    backgroundColor: palettes.App['Flolend 1'],
                                    flex: 3,
                                    justifyContent: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_tableau'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_tableau'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Document'}
                              </Text>
                            </TableCell>
                            {/* Table Cell 4 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_tableau'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_tableau'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Action'}
                              </Text>
                            </TableCell>
                          </TableRow>
                          {/* Données */}
                          <TableRow
                            drawBottomBorder={true}
                            drawEndBorder={false}
                            drawTopBorder={false}
                            isTableHeader={false}
                            borderColor={palettes.App.gris_fonce_flolend}
                            borderWidth={1}
                            drawStartBorder={false}
                          >
                            {/* Table Cell 2 */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  { alignItems: 'center', flex: 3 }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* Document */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['elmt_table']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['elmt_table']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {tableData?.Nom}
                              </Text>
                            </TableCell>
                            {/* Vue donnée */}
                            <TableCell
                              drawBottomBorder={false}
                              drawStartBorder={false}
                              drawTopBorder={false}
                              {...GlobalStyles.TableCellStyles(theme)[
                                'Table Cell'
                              ].props}
                              drawEndBorder={false}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TableCellStyles(theme)[
                                    'Table Cell'
                                  ].style,
                                  {
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              <Touchable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        `${tableData?.Fichier?.url}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <Icon
                                  color={palettes.App['Flolend 2']}
                                  name={'FontAwesome/download'}
                                  size={
                                    (!(dimensions.width >= Breakpoints.Desktop)
                                      ? 18
                                      : dimensions.width >=
                                        Breakpoints.BigScreen
                                      ? 30
                                      : 25) ?? 24
                                  }
                                />
                              </Touchable>
                              <>
                                {Platform.OS === 'web' ? null : (
                                  <Spacer
                                    bottom={8}
                                    left={8}
                                    right={8}
                                    top={8}
                                  />
                                )}
                              </>
                              {/* Share */}
                              <>
                                {Platform.OS === 'web' ? null : (
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await openShareUtil(
                                            `${tableData?.Fichier?.url}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <Icon
                                      color={palettes.App['Flolend 2']}
                                      name={'MaterialIcons/mobile-screen-share'}
                                      size={18}
                                    />
                                  </Touchable>
                                )}
                              </>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    {...GlobalStyles.TableStyles(theme)['Table'].props}
                    drawTopBorder={false}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TableStyles(theme)['Table'].style,
                      dimensions.width
                    )}
                  />
                );
              }}
            </BaseXanoApi.FetchPolitiquesGET>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(PolitiquesEtProceduresScreen);
