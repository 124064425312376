import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ValiderMonKYC2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.background_flolend,
          },
          justifyContent: 'center',
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            color3={palettes.App['Flolend 4']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                { alignItems: 'center', width: '100%' }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.background.brand,
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      height: 100,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Flolend 2']}
                    name={'Ionicons/checkmark-circle'}
                    size={75}
                  />
                </View>
              </View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'PTSans_700Bold',
                    fontSize: [
                      { minWidth: Breakpoints.Tablet, value: 22 },
                      { minWidth: Breakpoints.Mobile, value: 20 },
                    ],
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Votre dossier KYC est complet.'}
              </Text>
              {/* msg 2 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'PTSans_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    lineHeight: 25,
                    marginBottom: 20,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  'Vos documents ont été validés par notre prestataire de services de paiement.'
                }
              </Text>
              {/* bouton retour dossier investisseur */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('DossierInvestisseurScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: palettes.App['Flolend 4'],
                    borderRadius: 24,
                    borderWidth: 1,
                    color: theme.colors.background.brand,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    height: 46,
                    marginBottom: 30,
                    textAlign: 'center',
                    width: '95%',
                  },
                  dimensions.width
                )}
                title={'Retour à mon dossier investisseur'}
              />
            </View>
          </LinearGradient>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            scrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'cover'}
              {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                .props}
              source={imageSource(Images['Image5'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                    .style,
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    flex: { minWidth: Breakpoints.Laptop, value: null },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    minHeight: { minWidth: Breakpoints.Laptop, value: 500 },
                  }
                ),
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.flolend_gris_nuance_encart,
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                    justifyContent: 'center',
                    marginRight: { minWidth: Breakpoints.Laptop, value: '5%' },
                    padding: { minWidth: Breakpoints.Laptop, value: '2%' },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '40%' },
                      { minWidth: Breakpoints.Desktop, value: '30%' },
                      { minWidth: Breakpoints.BigScreen, value: '25%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Logo */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.gris_encart_int_flolend,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                        ],
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        height: 100,
                        justifyContent: 'center',
                        width: 100,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon laptop */}
                    <>
                      {!(
                        dimensions.width >= Breakpoints.Laptop === true &&
                        dimensions.width >= Breakpoints.Desktop === false
                      ) ? null : (
                        <Icon
                          color={palettes.App['Flolend 2']}
                          name={'Ionicons/checkmark-circle'}
                          size={86}
                        />
                      )}
                    </>
                    {/* Icon desktop or larger */}
                    <>
                      {!(dimensions.width >= Breakpoints.Desktop) ? null : (
                        <Icon
                          color={palettes.App['Flolend 2']}
                          name={'Ionicons/checkmark-circle'}
                          size={100}
                        />
                      )}
                    </>
                  </View>
                </View>
                {/* msg */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors.background.brand,
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                      ],
                      fontFamily: 'PTSans_700Bold',
                      fontSize: [
                        { minWidth: Breakpoints.Tablet, value: 22 },
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                        { minWidth: Breakpoints.BigScreen, value: 26 },
                      ],
                      lineHeight: 25,
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Laptop, value: '2%' },
                      ],
                      opacity: 0.95,
                      paddingLeft: [
                        { minWidth: Breakpoints.Laptop, value: 0 },
                        { minWidth: Breakpoints.Mobile, value: 25 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 25 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Votre dossier KYC est complet.'}
                </Text>
                {/* msg 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors.background.brand,
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                      ],
                      fontFamily: 'PTSans_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.BigScreen, value: 22 },
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                      ],
                      lineHeight: 25,
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: '5%' },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Laptop, value: '2%' },
                        { minWidth: Breakpoints.Mobile, value: 10 },
                      ],
                      opacity: 0.95,
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: 25 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: 25 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Vos documents ont été validés par notre prestataire de services de paiement.'
                  }
                </Text>
                {/* bouton retour dossier investisseur */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('DossierInvestisseurScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: 'rgba(0, 0, 0, 0)',
                      borderColor: palettes.App['Flolend 4'],
                      borderRadius: 24,
                      borderWidth: 1,
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: theme.colors.background.brand,
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                      ],
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Tablet, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: 18 },
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.BigScreen, value: 26 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                      ],
                      marginBottom: [
                        { minWidth: Breakpoints.Mobile, value: 30 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      textAlign: 'center',
                      width: '95%',
                    },
                    dimensions.width
                  )}
                  title={'Retour à mon dossier investisseur'}
                />
              </View>
            </ImageBackground>
            <FooterDesktopBlock />
          </SimpleStyleScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ValiderMonKYC2Screen);
