import React from 'react';
import {
  Checkbox,
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as composant_html from '../custom-files/composant_html';
import ConvertionDate from '../global-functions/ConvertionDate';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_actu_airtable: 47 };

const ActualiteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  // Remplace les placeholders du code HTML par les éléments de la base de donnée.
  const placeholders_html = (
    htmlTemplate,
    image_auteur_url,
    couverture_url,
    auteur,
    date,
    titre1,
    titre2,
    style
  ) => {
    // Installer le haut de page et initier le contenu
    let updatedHtml = htmlTemplate.replace(
      '<body>',
      `<body>
<div class="header-draftbit">
  <div class="mobile-only">
    <img src="${couverture_url}" alt="couverture" class="couverture-draftbit">
  </div>

  <h1 class="titre_draftbit">${titre1}</h1>

  <div class="encart-auteur-draftbit">
    <img src="${image_auteur_url}" alt="Photo de l'auteur">
    <div class="encart_droite">
      <p class="author">Par ${auteur}</p>
      <p class="date">Publié le ${date}</p>
    </div>
  </div>

  <p class="intro-draftbit">${titre2}</p>
</div>

<div class="contenu-draftbit">`
    );

    // Terminer le contenu
    updatedHtml = updatedHtml.replace('</body>', `</div></body>`);

    // Ajouter la balise <style> avant la balise de fin de <head>
    updatedHtml = updatedHtml.replace('</head>', `${style}</head>`);

    return updatedHtml;
  };
  const baseXanoMajLikesPUT = BaseXanoApi.useMajLikesPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: [
            { minWidth: Breakpoints.Laptop, value: dimensions.height },
            { minWidth: Breakpoints.Mobile, value: dimensions.height },
          ],
          paddingBottom: { minWidth: Breakpoints.Laptop, value: 20 },
          paddingTop: [
            {
              minWidth: Breakpoints.BigScreen,
              value: Constants['hauteur_navbar_big_screen'],
            },
            {
              minWidth: Breakpoints.Desktop,
              value: Constants['hauteur_navbar_desktop'],
            },
            {
              minWidth: Breakpoints.Laptop,
              value: Constants['hauteur_navbar_laptop'],
            },
          ],
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Fetch actu */}
      <BaseXanoApi.FetchActualiteGET
        actualites_id={
          props.route?.params?.id_actu_airtable ?? defaultProps.id_actu_airtable
        }
      >
        {({ loading, error, data, refetchActualite }) => {
          const fetchActuData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* header_mobile */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    {...GlobalStyles.ViewStyles(theme)['header_classique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['header_classique'].style,
                      dimensions.width
                    )}
                  >
                    {/* Back btn */}
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            height: 44,
                            justifyContent: 'center',
                            width: 44,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={palettes.App.gris_fonce_flolend}
                          name={'AntDesign/arrowleft'}
                        />
                      </View>
                    </Touchable>
                    {/* Screen Heading */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['screen_heading']
                        .props}
                      ellipsizeMode={'tail'}
                      numberOfLines={2}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['screen_heading'].style,
                        dimensions.width
                      )}
                    >
                      {'Actualité - '}
                      {fetchActuData?.le_projet?.Name}
                    </Text>
                  </View>
                )}
              </>
              {/* Bouton retour ordi */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        left: { minWidth: Breakpoints.Laptop, value: 20 },
                        position: {
                          minWidth: Breakpoints.Laptop,
                          value: 'absolute',
                        },
                        top: [
                          {
                            minWidth: Breakpoints.BigScreen,
                            value: Constants['hauteur_navbar_big_screen'] + 10,
                          },
                          {
                            minWidth: Breakpoints.Desktop,
                            value: Constants['hauteur_navbar_desktop'] + 10,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: Constants['hauteur_navbar_laptop'] + 10,
                          },
                        ],
                        zIndex: { minWidth: Breakpoints.Laptop, value: 90 },
                      },
                      dimensions.width
                    )}
                  >
                    <Pressable
                      onPress={() => {
                        try {
                          navigation.goBack();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: {
                              minWidth: Breakpoints.Laptop,
                              value: 'center',
                            },
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.flolend_gris_nuance_encart,
                            },
                            borderColor: {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.gris_fonce_flolend,
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            borderWidth: {
                              minWidth: Breakpoints.Laptop,
                              value: 1,
                            },
                            flexDirection: {
                              minWidth: Breakpoints.Laptop,
                              value: 'row',
                            },
                            gap: [
                              { minWidth: Breakpoints.Laptop, value: 5 },
                              { minWidth: Breakpoints.Desktop, value: 7 },
                            ],
                            padding: {
                              minWidth: Breakpoints.Laptop,
                              value: 10,
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={StyleSheet.getWidthValue(
                            [
                              {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.gris_fonce_flolend,
                              },
                            ],
                            dimensions.width
                          )}
                          name={'AntDesign/arrowleft'}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: {
                                  minWidth: Breakpoints.Laptop,
                                  value: palettes.App.blanc_flolend,
                                },
                                fontFamily: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'PTSans_400Regular',
                                },
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 14 },
                                  { minWidth: Breakpoints.Desktop, value: 18 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 22,
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Retour'}
                        </Text>
                      </View>
                    </Pressable>
                  </View>
                )}
              </>
              {/* Vue likes */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    backgroundColor: palettes.App.flolend_gris_nuance_encart,
                    borderColor: palettes.App.gris_fonce_flolend,
                    borderRadius: 15,
                    borderWidth: 1,
                    bottom: !(Platform.OS === 'ios') ? 40 : 80,
                    flexDirection: 'row',
                    gap: 10,
                    justifyContent: 'space-evenly',
                    padding: 10,
                    position: 'absolute',
                    right: 20,
                    zIndex: 99,
                  },
                  dimensions.width
                )}
              >
                {/* Likes */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Mobile, value: 'center' },
                      ],
                      flexDirection: 'row',
                      paddingBottom: 6,
                      paddingTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon Frame */}
                  <View>
                    {/* Fetch likeur */}
                    <BaseXanoApi.FetchClientLikeurGET
                      actu_id={
                        props.route?.params?.id_actu_airtable ??
                        defaultProps.id_actu_airtable
                      }
                    >
                      {({ loading, error, data, refetchClientLikeur }) => {
                        const fetchLikeurData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <Checkbox
                            onPress={newCheckboxValue => {
                              const handler = async () => {
                                try {
                                  (
                                    await baseXanoMajLikesPUT.mutateAsync({
                                      actu_id:
                                        props.route?.params?.id_actu_airtable ??
                                        defaultProps.id_actu_airtable,
                                    })
                                  )?.json;
                                  await refetchActualite();
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            checkedIcon={'AntDesign/heart'}
                            color={palettes.App['Flolend 4']}
                            defaultValue={fetchLikeurData?.client_likeur}
                            size={
                              (!(dimensions.width >= Breakpoints.Desktop)
                                ? 18
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 30
                                : 25) ?? 18
                            }
                            uncheckedColor={palettes.App['Flolend 2']}
                            uncheckedIcon={'AntDesign/hearto'}
                          />
                        );
                      }}
                    </BaseXanoApi.FetchClientLikeurGET>
                  </View>
                  {/* Data Poiint */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App.gris_fonce_flolend,
                        fontFamily: 'PTSans_700Bold',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                          { minWidth: Breakpoints.BigScreen, value: 22 },
                        ],
                        lineHeight: 18,
                        marginLeft: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchActuData?.Likes}
                  </Text>
                </View>
                {/* Views */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      paddingBottom: 6,
                      paddingTop: 6,
                    },
                    dimensions.width
                  )}
                >
                  {/* Icon Frame */}
                  <View>
                    <Icon
                      color={palettes.App['Flolend 2']}
                      name={'AntDesign/eyeo'}
                      size={
                        !(dimensions.width >= Breakpoints.Desktop)
                          ? 18
                          : dimensions.width >= Breakpoints.BigScreen
                          ? 30
                          : 25
                      }
                    />
                  </View>
                  {/* Data Poiint */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App.gris_fonce_flolend,
                        fontFamily: 'PTSans_700Bold',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Tablet, value: 16 },
                          { minWidth: Breakpoints.Laptop, value: 18 },
                          { minWidth: Breakpoints.BigScreen, value: 22 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                        ],
                        lineHeight: 18,
                        marginLeft: 5,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchActuData?.Vues}
                  </Text>
                </View>
                {/* Share */}
                <>
                  {!(
                    fetchActuData?.autorisation_partage &&
                    !(Platform.OS === 'web')
                  ) ? null : (
                    <View>
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await openShareUtil(
                                `${fetchActuData?.lien_partage}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        {/* Flex Frame for Icons */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              paddingBottom: 6,
                              paddingLeft: 6,
                              paddingRight: 6,
                              paddingTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          <Icon
                            color={palettes.App['Flolend 2']}
                            name={'Ionicons/share-outline'}
                            size={
                              (!(dimensions.width >= Breakpoints.Desktop)
                                ? 18
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 30
                                : 25) ?? 18
                            }
                          />
                        </View>
                      </Touchable>
                    </View>
                  )}
                </>
              </View>

              <SimpleStyleScrollView
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                bounces={false}
                scrollEnabled={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
              >
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <Image
                      resizeMode={'cover'}
                      {...GlobalStyles.ImageStyles(theme)['Image'].props}
                      source={imageSource(`${fetchActuData?.couv_actu?.url}`)}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'].style,
                          {
                            height: [
                              { minWidth: Breakpoints.Laptop, value: 200 },
                              { minWidth: Breakpoints.Desktop, value: 300 },
                              { minWidth: Breakpoints.BigScreen, value: 350 },
                            ],
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  )}
                </>
                <Utils.CustomCodeErrorBoundary>
                  <composant_html.FormattedHtmlComponent
                    htmlTemplate={fetchActuData?.contenu}
                    imageAuteurUrl={fetchActuData?.image_auteur.url}
                    couvertureUrl={fetchActuData?.couv_actu.url}
                    auteur={fetchActuData?.Auteur}
                    date={fetchActuData?.date_publication}
                    titre1={fetchActuData?.Header_contenu}
                    titre2={fetchActuData?.Header2}
                    style={fetchActuData?.css}
                  />
                </Utils.CustomCodeErrorBoundary>
              </SimpleStyleScrollView>
            </>
          );
        }}
      </BaseXanoApi.FetchActualiteGET>
    </ScreenContainer>
  );
};

export default withTheme(ActualiteScreen);
