import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { categorie: 'averti' };

const Categorisation2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.background_flolend,
          },
          justifyContent: 'center',
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      {/* Linear Gradient mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            color3={palettes.App['Flolend 4']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                { alignItems: 'center', width: '100%' }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flex: 1,
                  justifyContent: 'center',
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.background.brand,
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      height: 100,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Flolend 2']}
                    name={'Ionicons/checkmark-circle'}
                    size={75}
                  />
                </View>
              </View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'PTSans_700Bold',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 20 },
                      { minWidth: Breakpoints.Tablet, value: 22 },
                    ],
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  'Les réponses que vous avez fournies nous permettent de vous catégoriser comme investisseur "'
                }
                {props.route?.params?.categorie ?? defaultProps.categorie}
                {'".'}
              </Text>
              {/* msg 2 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'PTSans_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    lineHeight: 25,
                    marginBottom: 20,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {(props.route?.params?.categorie ?? defaultProps.categorie) ===
                'averti'
                  ? 'Une fois vos documents validés, vous pourrez commencer à investir.'
                  : 'Complétez votre test de connaissances réglementaire pour pouvoir investir.'}
              </Text>
              {/* bouton retour dossier investisseur */}
              <>
                {!(
                  (props.route?.params?.categorie ?? defaultProps.categorie) ===
                  'averti'
                ) ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('DossierInvestisseurScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: palettes.App['Flolend 4'],
                        borderRadius: 24,
                        borderWidth: 1,
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Tablet, value: 20 },
                        ],
                        height: 46,
                        marginBottom: 30,
                        textAlign: 'center',
                        width: '95%',
                      },
                      dimensions.width
                    )}
                    title={'Retour à mon dossier investisseur'}
                  />
                )}
              </>
              {/* bouton test de connaissances */}
              <>
                {!(
                  (props.route?.params?.categorie ?? defaultProps.categorie) ===
                  'non-averti'
                ) ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('TestDeConnaissances1Screen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: palettes.App['Flolend 4'],
                        borderRadius: 24,
                        borderWidth: 1,
                        color: theme.colors.background.brand,
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Tablet, value: 20 },
                        ],
                        height: 46,
                        marginBottom: 30,
                        textAlign: 'center',
                        width: '95%',
                      },
                      dimensions.width
                    )}
                    title={'Débuter mon test de connaissances'}
                  />
                )}
              </>
            </View>
          </LinearGradient>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Scroll View desktop */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            scrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* encart global */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                  marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              {/* Fond */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App['Flolend 1'],
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                    gap: [
                      { minWidth: Breakpoints.Desktop, value: 20 },
                      { minWidth: Breakpoints.BigScreen, value: 30 },
                    ],
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    justifyContent: {
                      minWidth: Breakpoints.Desktop,
                      value: 'center',
                    },
                    opacity: { minWidth: Breakpoints.Laptop, value: 0.75 },
                    padding: { minWidth: Breakpoints.Desktop, value: '2%' },
                    position: {
                      minWidth: Breakpoints.Laptop,
                      value: 'absolute',
                    },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  },
                  dimensions.width
                )}
              />
              {/* Contenu */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    gap: { minWidth: Breakpoints.Laptop, value: 10 },
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                    width: { minWidth: Breakpoints.Laptop, value: '100%' },
                  },
                  dimensions.width
                )}
              >
                {/* Vue icone */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Encart rond */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: theme.colors.background.brand,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                        ],
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 50,
                        },
                        height: 100,
                        justifyContent: 'center',
                        width: 100,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App.blanc_flolend}
                      name={'Ionicons/checkmark-circle'}
                      size={100}
                    />
                  </View>
                </View>
                {/* Vue message */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: [
                        { minWidth: Breakpoints.BigScreen, value: 25 },
                        { minWidth: Breakpoints.Laptop, value: 5 },
                        { minWidth: Breakpoints.Desktop, value: 15 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* msg */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_ecran_message']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_ecran_message']
                        .style,
                      dimensions.width
                    )}
                  >
                    {
                      'Les réponses que vous avez fournies nous permettent de vous catégoriser comme investisseur "'
                    }
                    {props.route?.params?.categorie ?? defaultProps.categorie}
                    {'".'}
                  </Text>
                  {/* msg 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['corps_ecran_message']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['corps_ecran_message']
                        .style,
                      dimensions.width
                    )}
                  >
                    {(props.route?.params?.categorie ??
                      defaultProps.categorie) === 'averti'
                      ? 'Une fois vos documents validés, vous pourrez commencer à investir.'
                      : 'Complétez votre test de connaissances réglementaire pour pouvoir investir.'}
                  </Text>
                </View>
                {/* Vue bouton */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 60 },
                        { minWidth: Breakpoints.Desktop, value: 65 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                      marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
                      width: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* dossier invest */}
                  <>
                    {!(
                      (props.route?.params?.categorie ??
                        defaultProps.categorie) === 'averti'
                    ) ? null : (
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('DossierInvestisseurScreen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['bouton_classique']
                              .style,
                            {
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 15,
                              },
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.blanc_flolend,
                              },
                              fontSize: [
                                { minWidth: Breakpoints.Desktop, value: 22 },
                                { minWidth: Breakpoints.BigScreen, value: 26 },
                              ],
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value: null,
                              },
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Retour à mon dossier investisseur'}
                      />
                    )}
                  </>
                  {/* test connaissances */}
                  <>
                    {!(
                      (props.route?.params?.categorie ??
                        defaultProps.categorie) === 'non-averti'
                    ) ? null : (
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('TestDeConnaissances1Screen');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                          .props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['bouton_classique']
                              .style,
                            {
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 15,
                              },
                              color: {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.blanc_flolend,
                              },
                              fontSize: [
                                { minWidth: Breakpoints.Desktop, value: 22 },
                                { minWidth: Breakpoints.BigScreen, value: 26 },
                              ],
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value: null,
                              },
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Débuter mon test de connaissances'}
                      />
                    )}
                  </>
                </View>
              </View>
            </View>
            <FooterDesktopBlock />
          </SimpleStyleScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(Categorisation2Screen);
