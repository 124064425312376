import React, { useEffect, useState } from 'react';

function FormattedHtmlComponent({
  htmlTemplate,
  imageAuteurUrl,
  couvertureUrl,
  auteur,
  date,
  titre1,
  titre2,
  style,
}) {
  const [formattedHtml, setFormattedHtml] = useState('');

  useEffect(() => {
    function placeholders_html(
      htmlTemplate,
      imageAuteurUrl,
      couvertureUrl,
      auteur,
      date,
      titre1,
      titre2,
      style
    ) {
      // Installer le haut de page et initier le contenu
      let updatedHtml = htmlTemplate.replace(
        '<body>',
        `<body>
          <div class="header-draftbit">
            <div class="mobile-only">
              <img src="${couvertureUrl}" alt="couverture" class="couverture-draftbit">
            </div>
            <h1 class="titre_draftbit">${titre1}</h1>
            <div class="encart-auteur-draftbit">
              <img src="${imageAuteurUrl}" alt="Photo de l'auteur">
              <div class="encart_droite">
                <p class="author">Par ${auteur}</p>
                <p class="date">Publié le ${date}</p>
              </div>
            </div>
            <p class="intro-draftbit">${titre2}</p>
          </div>
          <div class="contenu-draftbit">`
      );

      // Terminer le contenu
      updatedHtml = updatedHtml.replace('</body>', `</div></body>`);

      // Ajouter la balise <style> avant la balise de fin de <head>
      updatedHtml = updatedHtml.replace(
        '</head>',
        `<style>${style}</style></head>`
      );

      return updatedHtml;
    }

    const html = placeholders_html(
      htmlTemplate,
      imageAuteurUrl,
      couvertureUrl,
      auteur,
      date,
      titre1,
      titre2,
      style
    );
    setFormattedHtml(html);
  }, [
    htmlTemplate,
    imageAuteurUrl,
    couvertureUrl,
    auteur,
    date,
    titre1,
    titre2,
    style,
  ]);

  return (
    <div
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={{ __html: formattedHtml }}
    />
  );
}

// Export nommé du composant
export { FormattedHtmlComponent };
