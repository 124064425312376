import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  Surface,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as MagicLinkXanoApi from '../apis/MagicLinkXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { arrivee_connexion: false, arrivee_inscription2: false };

const Inscription3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [activation_chargement, setActivation_chargement] =
    React.useState(false);
  const [affichage_bouton_mail, setAffichage_bouton_mail] =
    React.useState(false);
  const [affichage_message, setAffichage_message] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      if (
        !(
          props.route?.params?.arrivee_inscription2 ??
          defaultProps.arrivee_inscription2
        )
      ) {
        setAffichage_bouton_mail(true);
      }
      setAffichage_message(false);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App['Custom Color_22'],
          },
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      {/* Linear Gradient mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            color3={palettes.App['Flolend 4']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                { alignSelf: 'center', justifyContent: 'center', width: '100%' }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.background.brand,
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      height: 100,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Flolend 2']}
                    name={'Entypo/mail'}
                    size={75}
                  />
                </View>
              </View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Tablet, value: 20 },
                    ],
                    lineHeight: 25,
                    marginBottom: 20,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  "Nous vous avons fait parvenir un courriel pour vérifier votre adresse mail.\n\nCliquez sur le lien qu'il contient pour valider votre adresse mail et commencer à investir.\n\nDans l'attente, vous pouvez d'ores et déjà découvrir nos projets."
                }
              </Text>
              {/* Voir les projets/retour */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (
                      (props.route?.params?.arrivee_inscription2 ??
                        defaultProps.arrivee_inscription2) ||
                      (props.route?.params?.arrivee_connexion ??
                        defaultProps.arrivee_connexion)
                    ) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('BottomTabNavigator', {
                        screen: 'EventailProjetsScreen',
                      });
                    } else {
                      navigation.goBack();
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_classique'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_classique'].style,
                    {
                      borderColor: palettes.App['Flolend 4'],
                      color: palettes.App.blanc_flolend,
                      fontSize: { minWidth: Breakpoints.Tablet, value: 20 },
                      marginBottom: 30,
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '95%' },
                        { minWidth: Breakpoints.Tablet, value: '50%' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={`${
                  props.route?.params?.arrivee_inscription2 ??
                  defaultProps.arrivee_inscription2
                    ? 'Voir les projets'
                    : 'Retour'
                }`}
              />
              {/* Renvoyer le courriel */}
              <>
                {!affichage_bouton_mail ? null : (
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setActivation_chargement(true);
                          setAffichage_bouton_mail(false);
                          (
                            await MagicLinkXanoApi.generateMagicLinkGET(
                              Constants
                            )
                          )?.json;
                          setAffichage_message(true);
                          setActivation_chargement(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_classique']
                          .style,
                        {
                          backgroundColor: palettes.App['Flolend 1'],
                          borderColor: palettes.App['Flolend 1'],
                          color: palettes.App.blanc_flolend,
                          fontSize: { minWidth: Breakpoints.Tablet, value: 20 },
                          marginBottom: 30,
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '95%' },
                            { minWidth: Breakpoints.Tablet, value: '50%' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Renvoyer le mail'}
                  />
                )}
              </>
              {/* Chargement */}
              <>
                {!activation_chargement ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      hidesWhenStopped={true}
                      size={'small'}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      animating={true}
                      color={palettes.App['Flolend 4']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_700Bold',
                            paddingLeft: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Chargement en cours...'}
                    </Text>
                  </View>
                )}
              </>
              {/* message */}
              <>
                {!affichage_message ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['message_erreur'].style,
                        { color: palettes.App.blanc_flolend }
                      ),
                      dimensions.width
                    )}
                  >
                    {"L'email a été envoyé à votre adresse."}
                  </Text>
                )}
              </>
            </View>
          </LinearGradient>
        )}
      </>
      {/* Vue laptop or larger */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                paddingRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Vue vidéo */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '2%',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Fetch video */}
                  <BaseXanoApi.FetchArrierePlanDemarrageGET>
                    {({
                      loading,
                      error,
                      data,
                      refetchArrierePlanDemarrage,
                    }) => {
                      const fetchVideoData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              height: {
                                minWidth: Breakpoints.Laptop,
                                value: '100%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          <Surface
                            {...GlobalStyles.SurfaceStyles(theme)['Surface']
                              .props}
                            elevation={3}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .style,
                                {
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 24,
                                  },
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                  overflow: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'hidden',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <VideoPlayer
                              posterResizeMode={'cover'}
                              rate={1}
                              resizeMode={'cover'}
                              usePoster={false}
                              volume={0.5}
                              {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                                .props}
                              isLooping={true}
                              isMuted={true}
                              shouldPlay={true}
                              source={imageSource(`${fetchVideoData}`)}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.VideoPlayerStyles(theme)['Video']
                                    .style,
                                  {
                                    height: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                    width: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                              useNativeControls={false}
                            />
                          </Surface>
                        </View>
                      );
                    }}
                  </BaseXanoApi.FetchArrierePlanDemarrageGET>
                </View>
              )}
            </>
            {/* Vue encart message */}
            <View
              style={StyleSheet.applyWidth(
                {
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              {/* Encart message */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    backgroundColor: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.flolend_gris_nuance_encart,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: theme.colors.background.brand,
                      },
                    ],
                    borderRadius: 24,
                    gap: [
                      { minWidth: Breakpoints.Laptop, value: 10 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                      { minWidth: Breakpoints.BigScreen, value: 30 },
                    ],
                    justifyContent: [
                      { minWidth: Breakpoints.Mobile, value: 'center' },
                      { minWidth: Breakpoints.Laptop, value: 'space-around' },
                    ],
                    padding: [
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                      { minWidth: Breakpoints.BigScreen, value: '5%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Logo */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        justifyContent: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Icon laptop */}
                    <Icon
                      color={palettes.App['Flolend 4']}
                      name={'Feather/mail'}
                      size={
                        (!(dimensions.width >= Breakpoints.Desktop)
                          ? 65
                          : dimensions.width >= Breakpoints.BigScreen
                          ? 75
                          : 70) ?? 86
                      }
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: {
                            minWidth: Breakpoints.Laptop,
                            value: 'rgba(0, 0, 0, 0)',
                          },
                        },
                        dimensions.width
                      )}
                    />
                  </View>
                </View>
                {/* msg 1 */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App['Flolend 5'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                      ],
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 24 },
                        { minWidth: Breakpoints.Laptop, value: 16 },
                      ],
                      lineHeight: 25,
                      opacity: 0.95,
                      paddingLeft: {
                        minWidth: Breakpoints.BigScreen,
                        value: '5%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.BigScreen,
                        value: '5%',
                      },
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Nous vous avons fait parvenir un courriel pour vérifier votre adresse mail.'
                  }
                </Text>
                {/* msg 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App['Flolend 5'],
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                      ],
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 24 },
                        { minWidth: Breakpoints.Laptop, value: 16 },
                      ],
                      lineHeight: 25,
                      opacity: 0.95,
                      paddingLeft: {
                        minWidth: Breakpoints.BigScreen,
                        value: '5%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.BigScreen,
                        value: '5%',
                      },
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    "Cliquez sur le lien qu'il contient pour valider votre adresse mail et commencer à investir."
                  }
                </Text>
                {/* msg 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: [
                        {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                        {
                          minWidth: Breakpoints.Mobile,
                          value: palettes.App['Flolend 5'],
                        },
                      ],
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 24 },
                        { minWidth: Breakpoints.Laptop, value: 16 },
                      ],
                      lineHeight: 25,
                      opacity: 0.95,
                      paddingLeft: {
                        minWidth: Breakpoints.BigScreen,
                        value: '5%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.BigScreen,
                        value: '5%',
                      },
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {
                    "Dans l'attente, vous pouvez d'ores et déjà découvrir nos projets."
                  }
                </Text>
                {/* voir les projets/retour */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      if (
                        (props.route?.params?.arrivee_inscription2 ??
                          defaultProps.arrivee_inscription2) ||
                        (props.route?.params?.arrivee_connexion ??
                          defaultProps.arrivee_connexion)
                      ) {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('BottomTabNavigator', {
                          screen: 'EventailProjetsScreen',
                        });
                      } else {
                        navigation.goBack();
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['bouton_classique']
                        .style,
                      {
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 26 },
                        ],
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 55 },
                          { minWidth: Breakpoints.BigScreen, value: 60 },
                        ],
                        marginBottom: {
                          minWidth: Breakpoints.Laptop,
                          value: null,
                        },
                        width: '95%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={`${
                    props.route?.params?.arrivee_inscription2 ??
                    defaultProps.arrivee_inscription2
                      ? 'Voir les projets'
                      : 'Retour'
                  }`}
                />
                {/* Renvoyer le courriel */}
                <>
                  {!affichage_bouton_mail ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            setActivation_chargement(true);
                            setAffichage_bouton_mail(false);
                            (
                              await MagicLinkXanoApi.generateMagicLinkGET(
                                Constants
                              )
                            )?.json;
                            setAffichage_message(true);
                            setActivation_chargement(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_classique']
                            .style,
                          {
                            backgroundColor: palettes.App['Flolend 1'],
                            borderColor: palettes.App['Flolend 1'],
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.blanc_flolend,
                            },
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 26 },
                            ],
                            height: [
                              { minWidth: Breakpoints.Desktop, value: 55 },
                              { minWidth: Breakpoints.BigScreen, value: 60 },
                            ],
                            marginBottom: 30,
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '95%' },
                              { minWidth: Breakpoints.Tablet, value: '50%' },
                              { minWidth: Breakpoints.Laptop, value: '95%' },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Renvoyer le mail'}
                    />
                  )}
                </>
                {/* Chargement */}
                <>
                  {!activation_chargement ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        hidesWhenStopped={true}
                        size={'small'}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        animating={true}
                        color={palettes.App['Flolend 4']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'PTSans_700Bold',
                              paddingLeft: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Chargement en cours...'}
                      </Text>
                    </View>
                  )}
                </>
                {/* message */}
                <>
                  {!affichage_message ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['message_erreur']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['message_erreur']
                            .style,
                          { color: palettes.App['Flolend 4'] }
                        ),
                        dimensions.width
                      )}
                    >
                      {"L'email a été envoyé à votre adresse."}
                    </Text>
                  )}
                </>
              </View>
            </View>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(Inscription3Screen);
