import React from 'react';
import {
  Divider,
  Icon,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const NotificationsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  // Encadre la navigation vers l'écran Actualité
  const nav_actu = type => {
    if (typeof type !== 'string') {
      return false;
    }

    const typesValid = ['Nouveau contenu', 'Annonce'];
    return typesValid.includes(type);
  };

  // Encadre la navigation vers le web
  const nav_ressource = type => {
    if (typeof type !== 'string') {
      return false;
    }

    const typesValid = ['Nouvelle ressource', 'Bienvenue'];
    return typesValid.includes(type);
  };

  // Encadre la navigation vers l'Espace personnel
  const navigation_espace_perso = type => {
    if (typeof type !== 'string') {
      return false;
    }

    const typesValid = ['Versement souscription', 'Ma souscription'];
    return typesValid.includes(type);
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['header_classique'].style,
            { marginBottom: 10 }
          ),
          dimensions.width
        )}
      >
        {/* Back btn */}
        <Touchable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 44,
                justifyContent: 'center',
                width: 44,
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App.gris_fonce_flolend}
              name={'AntDesign/arrowleft'}
              size={18}
            />
          </View>
        </Touchable>
        {/* titre_page */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['screen_heading'].style,
            dimensions.width
          )}
        >
          {'Notifications'}
        </Text>
      </View>

      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* Fetch notifications */}
        <BaseXanoApi.FetchGetNotifsClientGET>
          {({ loading, error, data, refetchGetNotifsClient }) => {
            const fetchNotificationsData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <SimpleStyleFlatList
                data={fetchNotificationsData}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'JXnNLtAB'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <>
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              if (nav_actu(listData?.type_notif)) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('ActualiteScreen', {
                                  id_actu_airtable:
                                    listData?.liaison_actualites,
                                });
                              }
                              if (listData?.type_notif === 'Nouveau projet') {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('ProjetScreen', {
                                  id_transfere:
                                    listData?.liaison_projets &&
                                    (listData?.liaison_projets)[0].projets.id,
                                });
                              }
                              if (nav_ressource(listData?.type_notif)) {
                                await WebBrowser.openBrowserAsync(
                                  `${listData?.lien_ressource}`
                                );
                              }
                              if (
                                navigation_espace_perso(listData?.type_notif)
                              ) {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('BottomTabNavigator', {
                                  screen: 'EspacePersonnelScreen',
                                });
                              }
                              if (listData?.type_notif === 'Dossier invest') {
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('DossierInvestisseurScreen');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                      >
                        <Surface
                          {...GlobalStyles.SurfaceStyles(theme)['Surface']
                            .props}
                          elevation={3}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface']
                                .style,
                              {
                                alignItems: 'center',
                                backgroundColor:
                                  palettes.App.flolend_gris_nuance_encart,
                                borderRadius: 12,
                                flexDirection: 'row',
                                marginLeft: 20,
                                marginRight: 20,
                                minHeight: null,
                                paddingBottom: 20,
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <Image
                            resizeMode={'cover'}
                            source={imageSource(`${listData?.image_couv?.url}`)}
                            style={StyleSheet.applyWidth(
                              { borderRadius: 8, height: 64, width: 64 },
                              dimensions.width
                            )}
                          />
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1, marginLeft: 20 },
                              dimensions.width
                            )}
                          >
                            {/* Title */}
                            <Text
                              accessible={true}
                              selectable={false}
                              ellipsizeMode={'tail'}
                              numberOfLines={2}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'Montserrat_500Medium',
                                  fontSize: 16,
                                },
                                dimensions.width
                              )}
                            >
                              {listData?.Titre}
                            </Text>
                            {/* View 3 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '85%',
                                },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '55%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* type  */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'PTSans_400Regular',
                                      fontSize: 14,
                                      marginTop: 4,
                                      opacity: 0.6,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {listData?.type_notif}
                                  {'\n'}
                                  {ConvertionDate(listData?.date_publication)}
                                </Text>
                              </View>
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* point */}
                                <>
                                  {!listData?.nouveaute ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: palettes.App.blanc_flolend,
                                          fontFamily: 'PTSans_400Regular',
                                          fontSize: 14,
                                          marginTop: 4,
                                          opacity: 0.6,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {' • '}
                                    </Text>
                                  )}
                                </>
                              </View>
                              {/* View 3 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* statut */}
                                <>
                                  {!listData?.nouveaute ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: palettes.App['Flolend 4'],
                                          fontFamily: 'PTSans_400Regular',
                                          fontSize: 14,
                                          marginTop: 4,
                                          opacity: 1,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {'Nouveau'}
                                    </Text>
                                  )}
                                </>
                              </View>
                            </View>
                          </View>
                        </Surface>
                      </Touchable>
                      <Divider
                        {...GlobalStyles.DividerStyles(theme)['Divider'].props}
                        color={palettes.App.gris_fonce_flolend}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.DividerStyles(theme)['Divider'].style,
                            {
                              marginBottom: 10,
                              marginLeft: 40,
                              marginRight: 40,
                              marginTop: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            );
          }}
        </BaseXanoApi.FetchGetNotifsClientGET>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(NotificationsScreen);
