import React from 'react';
import {
  Button,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_souscription_airtable: 128 };

const Souscription2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [message_erreur, setMessage_erreur] = React.useState(false);
  const [mon_switch, setMon_switch] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Fetch souscription */}
      <BaseXanoApi.FetchSousParIdGET
        id_souscription={
          props.route?.params?.id_souscription_airtable ??
          defaultProps.id_souscription_airtable
        }
      >
        {({ loading, error, data, refetchSousParId }) => {
          const fetchSouscriptionData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Linear Gradient mobile */}
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <LinearGradient
                    endX={100}
                    endY={100}
                    startX={0}
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'gradient_bouton'
                    ].props}
                    color1={
                      (mon_switch
                        ? palettes.App['Flolend 2']
                        : palettes.App.gris_fonce_flolend) ??
                      palettes.App['Flolend 2']
                    }
                    color2={
                      (mon_switch
                        ? palettes.App['Flolend 4']
                        : palettes.App.gris_fonce_flolend) ??
                      palettes.App['Flolend 4']
                    }
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].style,
                        {
                          alignSelf: [
                            { minWidth: Breakpoints.Tablet, value: 'center' },
                            { minWidth: Breakpoints.Mobile, value: 'center' },
                          ],
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 15,
                          },
                          bottom: 20,
                          flex: { minWidth: Breakpoints.Tablet, value: null },
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 60 },
                            { minWidth: Breakpoints.Desktop, value: 65 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          marginTop: null,
                          maxHeight: {
                            minWidth: Breakpoints.BigScreen,
                            value: 70,
                          },
                          position: 'absolute',
                          width: [
                            { minWidth: Breakpoints.Tablet, value: '50%' },
                            { minWidth: Breakpoints.Mobile, value: '80%' },
                          ],
                          zIndex: 99,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Bouton investir actif */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          if (mon_switch) {
                            navigation.navigate('Souscription3Screen', {
                              id_tranfert_2_3:
                                fetchSouscriptionData?.liaison_projet,
                              id_souscription_2_3:
                                props.route?.params?.id_souscription_airtable ??
                                defaultProps.id_souscription_airtable,
                              nombre_titres: fetchSouscriptionData?.nb_titres,
                              bool_local:
                                fetchSouscriptionData?.projets?.levee_locale,
                              montant_sous_euros:
                                fetchSouscriptionData?.montant_souscrit_euros,
                              type_produit:
                                fetchSouscriptionData?.projets?.Type_produit,
                              categorie_client:
                                fetchSouscriptionData?.client_de_la_souscription
                                  ?.type_investisseur,
                              modalites_sous:
                                fetchSouscriptionData?.projets
                                  ?.modalites_souscription,
                              nom_du_projet:
                                fetchSouscriptionData?.projets?.Name,
                            });
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_inactif']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_inactif']
                            .style,
                          {
                            backgroundColor: 'rgba(0, 0, 0, 0)',
                            borderRadius: null,
                            height: '100%',
                            marginBottom: null,
                            width: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Investir'}
                    />
                  </LinearGradient>
                )}
              </>
              <SimpleStyleScrollView
                horizontal={false}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                bounces={false}
                scrollEnabled={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    justifyContent: [
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: 'space-between',
                      },
                      { minWidth: Breakpoints.Laptop, value: 'space-between' },
                    ],
                    paddingBottom: 75,
                    paddingTop: [
                      {
                        minWidth: Breakpoints.Laptop,
                        value: Constants['hauteur_navbar_laptop'],
                      },
                      {
                        minWidth: Breakpoints.Desktop,
                        value: Constants['hauteur_navbar_desktop'],
                      },
                      {
                        minWidth: Breakpoints.BigScreen,
                        value: Constants['hauteur_navbar_big_screen'],
                      },
                    ],
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '100%' },
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Vue générale */}
                <View>
                  {/* En-tête */}
                  <View
                    style={StyleSheet.applyWidth(
                      { flexDirection: 'column' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: '10%',
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: '10%',
                          },
                          marginTop: 10,
                          paddingLeft: [
                            { minWidth: Breakpoints.Laptop, value: 0 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '80%' },
                              { minWidth: Breakpoints.Laptop, value: '100%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'titre_pipe_invest'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['titre_pipe_invest']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Investir dans '}
                          {fetchSouscriptionData?.projets?.Name}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <>
                        {dimensions.width >= Breakpoints.Laptop ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', width: '20%' },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  if (navigation.canGoBack()) {
                                    navigation.popToTop();
                                  }
                                  navigation.replace('BottomTabNavigator', {
                                    screen: 'EspacePersonnelScreen',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Icon
                                color={palettes.App.gris_fonce_flolend}
                                name={'Ionicons/exit-outline'}
                                size={18}
                              />
                            </Touchable>
                          </View>
                        )}
                      </>
                    </View>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: '10%',
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: '10%',
                          },
                          paddingLeft: [
                            { minWidth: Breakpoints.Laptop, value: 0 },
                            { minWidth: Breakpoints.Mobile, value: 16 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'sous_titre_pipe_invest'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'sous_titre_pipe_invest'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Etape 2/4 : Revue de la documentation'}
                      </Text>
                    </View>
                  </View>
                  {/* Bandeau progression */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: palettes.App.gris_encart_int_flolend,
                        height: 50,
                        justifyContent: 'space-evenly',
                        marginBottom: [
                          { minWidth: Breakpoints.Laptop, value: 10 },
                          { minWidth: Breakpoints.Mobile, value: 10 },
                        ],
                        marginTop: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '90%' },
                            { minWidth: Breakpoints.Laptop, value: '80%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignContent: 'center',
                            alignItems: 'center',
                            width: '25%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)[
                                'frise_pipe_invest_unselected'
                              ].style,
                              {
                                fontSize: [
                                  { minWidth: Breakpoints.Desktop, value: 18 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 22,
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Montant de la souscription'}
                        </Text>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '25%' },
                          dimensions.width
                        )}
                      >
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['frise_pipe_invest']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Revue de la documentation'}
                        </Text>
                      </View>
                      {/* View 3 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '25%' },
                          dimensions.width
                        )}
                      >
                        {/* Text 3 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'frise_pipe_invest_unselected'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'Signature'}
                        </Text>
                      </View>
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '25%' },
                          dimensions.width
                        )}
                      >
                        {/* Text 4 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'frise_pipe_invest_unselected'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'Paiement'}
                        </Text>
                      </View>
                    </View>
                    <LinearProgress
                      animationDuration={500}
                      color={theme.colors.branding.primary}
                      indeterminate={false}
                      isAnimated={true}
                      lineCap={'round'}
                      showTrack={true}
                      thickness={10}
                      trackColor={theme.colors.border.brand}
                      trackLineCap={'round'}
                      {...GlobalStyles.LinearProgressStyles(theme)[
                        'barre_pipe_invest'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.LinearProgressStyles(theme)[
                          'barre_pipe_invest'
                        ].style,
                        dimensions.width
                      )}
                      value={50}
                    />
                  </View>
                  {/* vue globale */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.gris_encart_int_flolend,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 24,
                        },
                        marginLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '20%',
                        },
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '20%',
                        },
                        minHeight: {
                          minWidth: Breakpoints.Desktop,
                          value: 500,
                        },
                        padding: { minWidth: Breakpoints.Laptop, value: 30 },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Vue infos */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          paddingRight: {
                            minWidth: Breakpoints.Laptop,
                            value: '1%',
                          },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Principe */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingLeft: [
                              { minWidth: Breakpoints.Mobile, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 0 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Mobile, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 0 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* Heading */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['explications']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['explications']
                              .style,
                            dimensions.width
                          )}
                        >
                          {
                            "Il s'agit à présent de passer en revue la documentation du projet pour en connaître tous les détails."
                          }
                        </Text>
                      </View>
                      {/* Fetch doc */}
                      <BaseXanoApi.FetchDocumentsPublicsParProjetGET
                        projet_id={fetchSouscriptionData?.liaison_projet}
                      >
                        {({
                          loading,
                          error,
                          data,
                          refetchDocumentsPublicsParProjet,
                        }) => {
                          const fetchDocData = data?.json;
                          if (loading) {
                            return <ActivityIndicator />;
                          }

                          if (
                            error ||
                            data?.status < 200 ||
                            data?.status >= 300
                          ) {
                            return <ActivityIndicator />;
                          }

                          return (
                            <>
                              {/* Fiche d'informations clés */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 20,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0,
                                      },
                                    ],
                                    paddingRight: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 20,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Section Header */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      marginBottom: 10,
                                      marginTop: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Heading */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'titre1_generique'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "A propos de la fiche d'informations clés sur l'investissement"
                                    }
                                  </Text>
                                </View>
                                {/* Content */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderColor: palettes.App['Flolend 1'],
                                      borderRadius: 12,
                                      borderWidth: 1,
                                      paddingBottom: 20,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                      paddingTop: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'explications'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'explications'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "Ce document permet de mieux apprécier le projet et de vérifier qu'il correspond à vos objectifs de placement. Il rassemble les informations importantes à savoir sur le projet et le porteur de projet, rédigées dans un langage intelligible."
                                    }
                                  </Text>
                                </View>
                                {/* Téléchargement */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { marginBottom: 8, marginTop: 10 },
                                    dimensions.width
                                  )}
                                >
                                  <SimpleStyleFlatList
                                    data={fetchDocData}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={'fUH3eksB'}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* View 2 */}
                                          <>
                                            {!listData?.test_fiche_info_cle ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    justifyContent:
                                                      'space-around',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View 3 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                      justifyContent:
                                                        'space-between',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        width: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '80%',
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              palettes.App
                                                                .blanc_flolend,
                                                            fontFamily:
                                                              'PTSans_400Regular',
                                                            fontSize: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 14,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: 14,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Desktop,
                                                                value: 18,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 22,
                                                              },
                                                            ],
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {listData?.Nom_du_doc}
                                                    </Text>
                                                  </View>
                                                  {/* Vue boutons */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        gap: 10,
                                                        justifyContent: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 'center',
                                                        },
                                                        width: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '20%',
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* View 2 */}
                                                    <>
                                                      {Platform.OS ===
                                                      'web' ? null : (
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignSelf:
                                                                'center',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Pressable
                                                            onPress={() => {
                                                              const handler =
                                                                async () => {
                                                                  try {
                                                                    await openShareUtil(
                                                                      `${listData?.document?.url}`
                                                                    );
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                };
                                                              handler();
                                                            }}
                                                          >
                                                            <Icon
                                                              color={
                                                                palettes.App[
                                                                  'Flolend 2'
                                                                ]
                                                              }
                                                              name={
                                                                'MaterialIcons/mobile-screen-share'
                                                              }
                                                              size={
                                                                !(
                                                                  dimensions.width >=
                                                                  Breakpoints.Desktop
                                                                )
                                                                  ? 18
                                                                  : dimensions.width >=
                                                                    Breakpoints.BigScreen
                                                                  ? 30
                                                                  : 25
                                                              }
                                                            />
                                                          </Pressable>
                                                        </View>
                                                      )}
                                                    </>
                                                    {/* View 3 */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        { alignSelf: 'center' },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Pressable 2 */}
                                                      <Pressable
                                                        onPress={() => {
                                                          const handler =
                                                            async () => {
                                                              try {
                                                                await WebBrowser.openBrowserAsync(
                                                                  `${listData?.document?.url}`
                                                                );
                                                              } catch (err) {
                                                                console.error(
                                                                  err
                                                                );
                                                              }
                                                            };
                                                          handler();
                                                        }}
                                                      >
                                                        <Icon
                                                          color={
                                                            palettes.App[
                                                              'Flolend 2'
                                                            ]
                                                          }
                                                          name={
                                                            'FontAwesome/download'
                                                          }
                                                          size={
                                                            !(
                                                              dimensions.width >=
                                                              Breakpoints.Desktop
                                                            )
                                                              ? 18
                                                              : dimensions.width >=
                                                                Breakpoints.BigScreen
                                                              ? 30
                                                              : 25
                                                          }
                                                        />
                                                      </Pressable>
                                                    </View>
                                                  </View>
                                                </View>
                                                {/* Divider 7 */}
                                                <Divider
                                                  color={
                                                    palettes.App
                                                      .gris_fonce_flolend
                                                  }
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: 1,
                                                      marginBottom: 12,
                                                      marginTop: 12,
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            )}
                                          </>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                    scrollEnabled={false}
                                  />
                                </View>
                              </View>
                              {/* Autres documents */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    justifyContent: 'center',
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 20,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0,
                                      },
                                    ],
                                    paddingRight: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 20,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Section Header */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      justifyContent: 'space-between',
                                      marginBottom: 10,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Heading */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'titre1_generique'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'titre1_generique'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Autres documents'}
                                  </Text>
                                </View>
                                {/* Téléchargement */}
                                <View>
                                  <SimpleStyleFlatList
                                    data={fetchDocData}
                                    horizontal={false}
                                    inverted={false}
                                    keyExtractor={(listData, index) =>
                                      listData?.id ??
                                      listData?.uuid ??
                                      index?.toString() ??
                                      JSON.stringify(listData)
                                    }
                                    keyboardShouldPersistTaps={'never'}
                                    listKey={'YmHOYR0A'}
                                    nestedScrollEnabled={false}
                                    numColumns={1}
                                    onEndReachedThreshold={0.5}
                                    renderItem={({ item, index }) => {
                                      const listData = item;
                                      return (
                                        <>
                                          {/* View 2 */}
                                          <>
                                            {listData?.test_fiche_info_cle ? null : (
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    justifyContent:
                                                      'space-around',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* View 3 */}
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      flexDirection: 'row',
                                                      justifyContent:
                                                        'space-between',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        width: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '80%',
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'].props}
                                                      style={StyleSheet.applyWidth(
                                                        StyleSheet.compose(
                                                          GlobalStyles.TextStyles(
                                                            theme
                                                          )['Text'].style,
                                                          {
                                                            color:
                                                              palettes.App
                                                                .blanc_flolend,
                                                            fontFamily:
                                                              'PTSans_400Regular',
                                                            fontSize: [
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Mobile,
                                                                value: 14,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Laptop,
                                                                value: 14,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.Desktop,
                                                                value: 18,
                                                              },
                                                              {
                                                                minWidth:
                                                                  Breakpoints.BigScreen,
                                                                value: 22,
                                                              },
                                                            ],
                                                          }
                                                        ),
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {listData?.Nom_du_doc}
                                                    </Text>
                                                  </View>
                                                  {/* Vue boutons */}
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                        gap: 10,
                                                        justifyContent: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: 'center',
                                                        },
                                                        width: {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '20%',
                                                        },
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    {/* View 2 */}
                                                    <>
                                                      {Platform.OS ===
                                                      'web' ? null : (
                                                        <View
                                                          style={StyleSheet.applyWidth(
                                                            {
                                                              alignSelf:
                                                                'center',
                                                              flexDirection:
                                                                'row',
                                                            },
                                                            dimensions.width
                                                          )}
                                                        >
                                                          <Pressable
                                                            onPress={() => {
                                                              const handler =
                                                                async () => {
                                                                  try {
                                                                    await openShareUtil(
                                                                      `${listData?.document?.url}`
                                                                    );
                                                                  } catch (err) {
                                                                    console.error(
                                                                      err
                                                                    );
                                                                  }
                                                                };
                                                              handler();
                                                            }}
                                                          >
                                                            <Icon
                                                              color={
                                                                palettes.App[
                                                                  'Flolend 2'
                                                                ]
                                                              }
                                                              name={
                                                                'MaterialIcons/mobile-screen-share'
                                                              }
                                                              size={
                                                                !(
                                                                  dimensions.width >=
                                                                  Breakpoints.Desktop
                                                                )
                                                                  ? 18
                                                                  : dimensions.width >=
                                                                    Breakpoints.BigScreen
                                                                  ? 30
                                                                  : 25
                                                              }
                                                            />
                                                          </Pressable>
                                                        </View>
                                                      )}
                                                    </>
                                                    {/* View 3 */}
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignSelf: 'center',
                                                          flexDirection: 'row',
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {/* Pressable 2 */}
                                                      <Pressable
                                                        onPress={() => {
                                                          const handler =
                                                            async () => {
                                                              try {
                                                                await WebBrowser.openBrowserAsync(
                                                                  `${listData?.document?.url}`
                                                                );
                                                              } catch (err) {
                                                                console.error(
                                                                  err
                                                                );
                                                              }
                                                            };
                                                          handler();
                                                        }}
                                                      >
                                                        <Icon
                                                          color={
                                                            palettes.App[
                                                              'Flolend 2'
                                                            ]
                                                          }
                                                          name={
                                                            'FontAwesome/download'
                                                          }
                                                          size={
                                                            !(
                                                              dimensions.width >=
                                                              Breakpoints.Desktop
                                                            )
                                                              ? 18
                                                              : dimensions.width >=
                                                                Breakpoints.BigScreen
                                                              ? 30
                                                              : 25
                                                          }
                                                        />
                                                      </Pressable>
                                                    </View>
                                                  </View>
                                                </View>
                                                {/* Divider 7 */}
                                                <Divider
                                                  color={
                                                    palettes.App
                                                      .gris_fonce_flolend
                                                  }
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: 1,
                                                      marginBottom: 12,
                                                      marginTop: 12,
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            )}
                                          </>
                                        </>
                                      );
                                    }}
                                    showsHorizontalScrollIndicator={true}
                                    showsVerticalScrollIndicator={true}
                                  />
                                </View>
                              </View>
                            </>
                          );
                        }}
                      </BaseXanoApi.FetchDocumentsPublicsParProjetGET>
                      {/* Confirmation */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            marginTop: 10,
                            paddingLeft: [
                              { minWidth: Breakpoints.Mobile, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 0 },
                            ],
                            paddingRight: [
                              { minWidth: Breakpoints.Mobile, value: 20 },
                              { minWidth: Breakpoints.Laptop, value: 0 },
                            ],
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'flex-start', width: '80%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 18,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 22,
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              "J'ai pris connaissance de la documentation du projet."
                            }
                          </Text>
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '20%' },
                            dimensions.width
                          )}
                        >
                          <Switch
                            onValueChange={newSwitchValue => {
                              try {
                                setMon_switch(newSwitchValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            activeThumbColor={palettes.App.blanc_flolend}
                            activeTrackColor={palettes.App['Flolend 4']}
                            inactiveThumbColor={palettes.App.blanc_flolend}
                            inactiveTrackColor={palettes.App.gris_fonce_flolend}
                            value={mon_switch}
                          />
                        </View>
                      </View>
                      {/* Linear Gradient ordi */}
                      <>
                        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                          <LinearGradient
                            endX={100}
                            endY={100}
                            startX={0}
                            startY={0}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'gradient_bouton'
                            ].props}
                            color1={
                              (mon_switch
                                ? palettes.App['Flolend 2']
                                : palettes.App.gris_fonce_flolend) ??
                              palettes.App['Flolend 2']
                            }
                            color2={
                              (mon_switch
                                ? palettes.App['Flolend 4']
                                : palettes.App.gris_fonce_flolend) ??
                              palettes.App['Flolend 4']
                            }
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'gradient_bouton'
                                ].style,
                                {
                                  alignSelf: {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                  borderRadius: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 15,
                                  },
                                  flex: [
                                    {
                                      minWidth: Breakpoints.Tablet,
                                      value: null,
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: null,
                                    },
                                  ],
                                  height: [
                                    { minWidth: Breakpoints.Laptop, value: 60 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 65,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 70,
                                    },
                                  ],
                                  marginBottom: [
                                    { minWidth: Breakpoints.Mobile, value: 30 },
                                    { minWidth: Breakpoints.Laptop, value: 10 },
                                  ],
                                  marginLeft: [
                                    { minWidth: Breakpoints.Mobile, value: 20 },
                                    { minWidth: Breakpoints.Laptop, value: 0 },
                                  ],
                                  marginRight: [
                                    { minWidth: Breakpoints.Mobile, value: 20 },
                                    { minWidth: Breakpoints.Laptop, value: 0 },
                                  ],
                                  marginTop: 35,
                                  maxHeight: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 70,
                                  },
                                  overflow: 'hidden',
                                  width: {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* Bouton investir actif */}
                            <Button
                              accessible={true}
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  if (mon_switch) {
                                    navigation.navigate('Souscription3Screen', {
                                      id_tranfert_2_3:
                                        fetchSouscriptionData?.liaison_projet,
                                      id_souscription_2_3:
                                        props.route?.params
                                          ?.id_souscription_airtable ??
                                        defaultProps.id_souscription_airtable,
                                      nombre_titres:
                                        fetchSouscriptionData?.nb_titres,
                                      bool_local:
                                        fetchSouscriptionData?.projets
                                          ?.levee_locale,
                                      montant_sous_euros:
                                        fetchSouscriptionData?.montant_souscrit_euros,
                                      type_produit:
                                        fetchSouscriptionData?.projets
                                          ?.Type_produit,
                                      categorie_client:
                                        fetchSouscriptionData
                                          ?.client_de_la_souscription
                                          ?.type_investisseur,
                                      modalites_sous:
                                        fetchSouscriptionData?.projets
                                          ?.modalites_souscription,
                                      nom_du_projet:
                                        fetchSouscriptionData?.projets?.Name,
                                    });
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)[
                                'bouton_inactif'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)[
                                    'bouton_inactif'
                                  ].style,
                                  {
                                    backgroundColor: 'rgba(0, 0, 0, 0)',
                                    borderRadius: null,
                                    height: '100%',
                                    marginBottom: null,
                                    width: '100%',
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Investir'}
                            />
                          </LinearGradient>
                        )}
                      </>
                      {/* Message d'erreur */}
                      <>
                        {!message_erreur ? null : (
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['message_erreur']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['message_erreur']
                                  .style,
                                {
                                  fontSize: [
                                    { minWidth: Breakpoints.Laptop, value: 20 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 24,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 28,
                                    },
                                  ],
                                  marginLeft: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0,
                                  },
                                  marginRight: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 0,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'Veuillez confirmer avoir pris connaissance de la documentation en actionnant le bouton ci-dessus.'
                            }
                          </Text>
                        )}
                      </>
                    </View>
                  </View>
                </View>
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <FooterDesktopBlock />
                  )}
                </>
              </SimpleStyleScrollView>
            </>
          );
        }}
      </BaseXanoApi.FetchSousParIdGET>
    </ScreenContainer>
  );
};

export default withTheme(Souscription2Screen);
