import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_souscription: 4, souscription_fractionnee: true };

const Souscription14ValidationAttenteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.background_flolend,
          },
          justifyContent: 'center',
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            endX={175}
            endY={150}
            startX={75}
            startY={100}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                { width: '100%' }
              ),
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', flex: 1, justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Logo */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors.background.brand,
                      borderBottomLeftRadius: 15,
                      borderBottomRightRadius: 15,
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      height: 100,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App['Flolend 2']}
                    name={'Ionicons/checkmark-circle'}
                    size={75}
                  />
                </View>
              </View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: 18,
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {props.route?.params?.souscription_fractionnee ??
                defaultProps.souscription_fractionnee
                  ? "Le montant de votre souscription excédant le montant disponible sur le projet a bien été mis en liste d'attente."
                  : 'Félicitations ! Votre réservation est bien enregistrée.'}
              </Text>
              {/* msg 2 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.brand,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: 14,
                    lineHeight: 25,
                    marginBottom: 30,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {props.route?.params?.souscription_fractionnee ??
                defaultProps.souscription_fractionnee
                  ? 'Le montant restant vous a été attribué.'
                  : "Nous vous tiendrons informés de l'évolution de votre souscription en liste d'attente."}
              </Text>
              {/* bouton */}
              <Button
                accessible={true}
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (
                      props.route?.params?.souscription_fractionnee ??
                      defaultProps.souscription_fractionnee
                    ) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('Souscription2Screen', {
                        id_souscription_airtable:
                          props.route?.params?.id_souscription ??
                          defaultProps.id_souscription,
                      });
                    } else {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('BottomTabNavigator', {
                        screen: 'EspacePersonnelScreen',
                      });
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['bouton_classique'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['bouton_classique'].style,
                    {
                      color: theme.colors.background.brand,
                      marginBottom: 30,
                      width: [
                        { minWidth: Breakpoints.Mobile, value: '95%' },
                        { minWidth: Breakpoints.Tablet, value: '50%' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                title={`${
                  props.route?.params?.souscription_fractionnee ??
                  defaultProps.souscription_fractionnee
                    ? 'Poursuivre ma souscription'
                    : 'Retour aux projets'
                }`}
              />
            </View>
          </LinearGradient>
        )}
      </>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <ImageBackground
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageBackgroundStyles(theme)[
                    'Image Background'
                  ].props}
                  source={imageSource(Images['Image4'])}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageBackgroundStyles(theme)[
                        'Image Background'
                      ].style,
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'flex-end',
                        },
                        flex: { minWidth: Breakpoints.Laptop, value: null },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        minHeight: [
                          { minWidth: Breakpoints.Laptop, value: 500 },
                          { minWidth: Breakpoints.Desktop, value: 600 },
                          { minWidth: Breakpoints.BigScreen, value: 700 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.gris_encart_int_flolend,
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 24,
                        },
                        justifyContent: 'center',
                        marginRight: {
                          minWidth: Breakpoints.Laptop,
                          value: '5%',
                        },
                        padding: { minWidth: Breakpoints.Laptop, value: '2%' },
                        width: [
                          { minWidth: Breakpoints.Laptop, value: '40%' },
                          { minWidth: Breakpoints.BigScreen, value: '25%' },
                          { minWidth: Breakpoints.Desktop, value: '30%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* Logo */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors.background.brand,
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: 'rgba(0, 0, 0, 0)',
                              },
                            ],
                            borderBottomLeftRadius: 15,
                            borderBottomRightRadius: 15,
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                            height: 100,
                            justifyContent: 'center',
                            width: 100,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App['Flolend 2']}
                          name={'Ionicons/checkmark-circle'}
                          size={100}
                        />
                      </View>
                    </View>
                    {/* msg */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors.background.brand,
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.blanc_flolend,
                            },
                          ],
                          fontFamily: 'Montserrat_500Medium',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 18 },
                            { minWidth: Breakpoints.Desktop, value: 22 },
                            { minWidth: Breakpoints.BigScreen, value: 26 },
                          ],
                          lineHeight: 25,
                          marginTop: 10,
                          opacity: 0.95,
                          paddingLeft: 25,
                          paddingRight: 25,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {props.route?.params?.souscription_fractionnee ??
                      defaultProps.souscription_fractionnee
                        ? "Le montant de votre souscription excédent le montant disponible sur le projet a bien été mis en liste d'attente."
                        : 'Félicitations ! Votre réservation est bien enregistrée.'}
                    </Text>
                    {/* msg 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors.background.brand,
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.blanc_flolend,
                            },
                          ],
                          fontFamily: 'Montserrat_500Medium',
                          fontSize: [
                            { minWidth: Breakpoints.Mobile, value: 14 },
                            { minWidth: Breakpoints.Desktop, value: 18 },
                            { minWidth: Breakpoints.BigScreen, value: 22 },
                          ],
                          lineHeight: 25,
                          marginBottom: 30,
                          marginTop: 10,
                          opacity: 0.95,
                          paddingLeft: 25,
                          paddingRight: 25,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      {props.route?.params?.souscription_fractionnee ??
                      defaultProps.souscription_fractionnee
                        ? 'Le montant restant vous a été attribué.'
                        : "Nous vous tiendrons informés de l'évolution de votre souscription en liste d'attente."}
                    </Text>
                    {/* bouton */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          if (
                            props.route?.params?.souscription_fractionnee ??
                            defaultProps.souscription_fractionnee
                          ) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('Souscription2Screen', {
                              id_souscription_airtable:
                                props.route?.params?.id_souscription ??
                                defaultProps.id_souscription,
                            });
                          } else {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('BottomTabNavigator', {
                              screen: 'EspacePersonnelScreen',
                            });
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_classique']
                            .style,
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.blanc_flolend,
                            },
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 26 },
                            ],
                            height: [
                              { minWidth: Breakpoints.Desktop, value: 60 },
                              { minWidth: Breakpoints.BigScreen, value: 70 },
                            ],
                            marginBottom: 30,
                            width: '95%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={`${
                        props.route?.params?.souscription_fractionnee ??
                        defaultProps.souscription_fractionnee
                          ? 'Poursuivre ma souscription'
                          : 'Retour aux projets'
                      }`}
                    />
                  </View>
                </ImageBackground>
              )}
            </>
            <FooterDesktopBlock />
          </SimpleStyleScrollView>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(Souscription14ValidationAttenteScreen);
