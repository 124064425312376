import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const AnnulationApprovisionnementScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.background_flolend,
          },
          justifyContent: 'center',
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }
              ),
              dimensions.width
            )}
          >
            {/* Logo */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors.background.brand,
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    height: 100,
                    justifyContent: 'center',
                    width: 100,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Flolend 1']}
                  name={'Entypo/cross'}
                  size={75}
                />
              </View>
            </View>

            <View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App.Communial_White,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: 18,
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Transaction annulée'}
              </Text>
              {/* msg 2 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App.Communial_White,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: 14,
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  "Votre livret n'a pas été alimenté et vous n'avez pas été débité."
                }
              </Text>
            </View>
            {/* bouton */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('MonLivretFlolendScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_classique'].style,
                  {
                    color: theme.colors.background.brand,
                    marginTop: 20,
                    width: 350,
                  }
                ),
                dimensions.width
              )}
              title={'Retour à mon livret'}
            />
          </LinearGradient>
        )}
      </>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            scrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* vue horizontale ordinateur */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                  marginTop: { minWidth: Breakpoints.Laptop, value: 30 },
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                },
                dimensions.width
              )}
            >
              {/* encart global */}
              <View
                style={StyleSheet.applyWidth(
                  { width: { minWidth: Breakpoints.Laptop, value: '50%' } },
                  dimensions.width
                )}
              >
                {/* Fond */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App['Flolend 1'],
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                      gap: [
                        { minWidth: Breakpoints.Desktop, value: 20 },
                        { minWidth: Breakpoints.BigScreen, value: 30 },
                      ],
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                      justifyContent: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                      opacity: { minWidth: Breakpoints.Laptop, value: 0.75 },
                      padding: { minWidth: Breakpoints.Desktop, value: '2%' },
                      position: {
                        minWidth: Breakpoints.Laptop,
                        value: 'absolute',
                      },
                      width: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                />
                {/* Contenu */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: { minWidth: Breakpoints.Laptop, value: 10 },
                      height: { minWidth: Breakpoints.Laptop, value: '100%' },
                      padding: { minWidth: Breakpoints.Laptop, value: '5%' },
                      width: { minWidth: Breakpoints.Laptop, value: '100%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Vue icone */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* Encart rond */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: [
                            {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.background_flolend,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: theme.colors.background.brand,
                            },
                          ],
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 50,
                          },
                          height: 100,
                          justifyContent: 'center',
                          width: 100,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App.blanc_flolend}
                        name={'Entypo/cross'}
                        size={100}
                      />
                    </View>
                  </View>
                  {/* Vue message */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        gap: [
                          { minWidth: Breakpoints.Laptop, value: 5 },
                          { minWidth: Breakpoints.Desktop, value: 15 },
                          { minWidth: Breakpoints.BigScreen, value: 25 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {/* msg */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre_ecran_message']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre_ecran_message']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Transaction annulée'}
                    </Text>
                    {/* msg 2 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['corps_ecran_message']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['corps_ecran_message']
                          .style,
                        dimensions.width
                      )}
                    >
                      {
                        "Votre livret n'a pas été alimenté et vous n'avez pas été débité."
                      }
                    </Text>
                  </View>
                  {/* Vue bouton */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 60 },
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                        ],
                        marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* bouton 2 */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          navigation.navigate('MonLivretFlolendScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_classique']
                            .style,
                          {
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.blanc_flolend,
                            },
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 26 },
                            ],
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: null,
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Retour aux projets'}
                    />
                  </View>
                </View>
              </View>
            </View>
            <FooterDesktopBlock />
          </SimpleStyleScrollView>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(AnnulationApprovisionnementScreen);
