import React from 'react';
import {
  AccordionGroup,
  Button,
  Icon,
  LinearGradient,
  LinearProgress,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as packages_import from '../custom-files/packages_import';
import format_nombre from '../global-functions/format_nombre';
import nombreEnLettres from '../global-functions/nombreEnLettres';
import reset_navbar from '../global-functions/reset_navbar';
import supprimer_prefixe_json from '../global-functions/supprimer_prefixe_json';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  bool_local: true,
  categorie_client: 'non-averti',
  id_souscription_2_3: 84,
  id_tranfert_2_3: 4,
  modalites_sous:
    "Jean Monnet en tant qu'administrateur solidaire, statuant le 29/08/2023 a décidé que la Société procéderait à un emprunt sous la forme d'obligations simples dont les caractéristiques sont les suivantes :  (i) Emission d'un montant nominal maximal de deux millions cinq cent mille euros (2 500 000 €) représenté par un nombre maximal de deux cent cinquante mille (250 000) actions simples d'une valeur nominale de dix euros (10 €) ;  (ii) Emission pouvant être réduite à un million sept cent cinquante mille euros (1 750 000 €) ;  (iii) Obligations simples émises au pair à échéance dix-huit (18) mois et au taux d'intérêt annuel fixe de huit virgule cinq pour cent (8,5%) ;  (iv) Souscription ouverte le 17/10/2023 et close le 17/11/2023, la souscription pouvant toutefois être close par anticipation, dès lors que l'émission aura été intégralement souscrite ;  (v) Emission réalisée dans le cadre d'une offre au public de titres financiers selon les conditions du règlement UE 2020/1503 du Parlement européen et du Conseil du 7 octobre 2020 sur les prestataires européens de financement participatif pour les entrepreneurs ;  (vi) Souscription reçue par la société Flolend, société par action simplifiée au capital de 10 000 euros immatriculée au registre du commerce et des sociétés de Paris sous le numéro XX dont le siège social se situe au 28 bis rue du Cardinal Lemoine, 75005 Paris, FRANCE ;  (vii) Obligations simples devant être libérées à leur souscription par versement d'espèces.",
  montant_sous_euros: 100,
  nom_du_projet: 'Eclipse Totlae du soleil sais ',
  nombre_titres: 10,
  type_produit: 'Obligations',
};

const Souscription3Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [affichage_erreur_justif, setAffichage_erreur_justif] =
    React.useState(false);
  const [bouton_actif, setBouton_actif] = React.useState(false);
  const [chargement_en_cours, setChargement_en_cours] = React.useState(false);
  const [justificatif_domicile, setJustificatif_domicile] = React.useState({});
  const [justificatif_uploaded, setJustificatif_uploaded] =
    React.useState(false);
  const [message_erreur, setMessage_erreur] = React.useState(false);
  const [message_erreur_chargement, setMessage_erreur_chargement] =
    React.useState('');
  const [message_erreur_navigation, setMessage_erreur_navigation] =
    React.useState('');
  const [nom_justif, setNom_justif] = React.useState('');
  const [switchValue, setSwitchValue] = React.useState(false);
  const [switchValue2, setSwitchValue2] = React.useState(false);
  const [switchValue3, setSwitchValue3] = React.useState(false);
  // Prend 4 booléens en entrée et revoie leur produit.
  const areAllTrue = (param1, param2, param3, param4) => {
    return param1 && param2 && param3 && param4;
  };

  // Renvoie un objet avec success: true si le fichier est au bon format et à la bonne taille, et false sinon.
  const fichier_valide = async (fileObject, maxPages) => {
    if (fileObject === undefined) {
      return { success: false, message: '' };
    }

    const acceptedExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
    const fileExtension = fileObject.name.split('.').pop().toLowerCase();

    if (!acceptedExtensions.includes(fileExtension)) {
      return { success: false, message: "L'extension du fichier est invalide" };
    }

    const base64Data = fileObject.value.split(',')[1];
    const fileSizeInBytes = packages_import.decode(base64Data).byteLength;
    const fileSizeInKB = fileSizeInBytes / 1024;

    if (fileSizeInKB > 4096) {
      return {
        success: false,
        message:
          'Le fichier est trop lourd : importez un justificatif de 4MB au maximum.',
      };
    }

    if (fileExtension === 'pdf') {
      try {
        const pdfBytes = packages_import.decode(base64Data);
        const pdfDoc = await packages_import.PDFDocument.load(pdfBytes);
        const numPages = pdfDoc.getPageCount();

        if (numPages <= maxPages) {
          return { success: true, message: 'succès' };
        } else {
          return {
            success: false,
            message: `Le fichier est trop long, veuillez importer un justificatif de ${maxPages} pages ou moins.`,
          };
        }
      } catch (error) {
        console.error('Error processing PDF:', error);
        return { success: false, message: 'PDF illisible' };
      }
    } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
      try {
        const imageUri = `data:image/${fileExtension};base64,${base64Data}`;
        const imageInfo = await packages_import.Image.getSize(imageUri);

        // Assuming no page count limit for images
        return { success: true, message: 'succès' };
      } catch (error) {
        console.error('Error processing image:', error);
        return { success: false, message: 'Image invalide.' };
      }
    }

    return { success: false, message: 'Fichier illisible.' };
  };
  const baseXanoUploadJustifDomicilePOST =
    BaseXanoApi.useUploadJustifDomicilePOST();
  const baseXanoBulletinPdfPOST = BaseXanoApi.useBulletinPdfPOST();
  const baseXanoSignatureBulletinPATCH =
    BaseXanoApi.useSignatureBulletinPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            marginBottom: { minWidth: Breakpoints.Laptop, value: 0 },
            marginTop: [
              { minWidth: Breakpoints.Mobile, value: '2%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* En-tête */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              marginBottom: 10,
              paddingTop: { minWidth: Breakpoints.Laptop, value: 10 },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '80%' },
                    { minWidth: Breakpoints.Laptop, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_pipe_invest'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_pipe_invest'].style,
                  dimensions.width
                )}
              >
                {'Investir dans '}
                {props.route?.params?.nom_du_projet ??
                  defaultProps.nom_du_projet}
              </Text>
            </View>
            {/* View 2 */}
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '20%' },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace('BottomTabNavigator', {
                          screen: 'EspacePersonnelScreen',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      color={palettes.App.gris_fonce_flolend}
                      name={'Ionicons/exit-outline'}
                      size={18}
                    />
                  </Touchable>
                </View>
              )}
            </>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                .props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                    .style,
                  { textAlign: 'left' }
                ),
                dimensions.width
              )}
            >
              {'Etape 3/4 : Signature du bulletin de souscription'}
            </Text>
          </View>
          {/* Bandeau progression */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App.gris_encart_int_flolend,
                height: 50,
                justifyContent: 'space-evenly',
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: { minWidth: Breakpoints.Laptop, value: '80%' },
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '25%',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Montant de la souscription'}
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Revue de la documentation'}
                </Text>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['frise_pipe_invest'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['frise_pipe_invest'].style,
                    dimensions.width
                  )}
                >
                  {'Signature'}
                </Text>
              </View>
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 4 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Paiement'}
                </Text>
              </View>
            </View>
            <LinearProgress
              animationDuration={500}
              color={theme.colors.branding.primary}
              indeterminate={false}
              isAnimated={true}
              lineCap={'round'}
              showTrack={true}
              thickness={10}
              trackColor={theme.colors.border.brand}
              trackLineCap={'round'}
              {...GlobalStyles.LinearProgressStyles(theme)['barre_pipe_invest']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.LinearProgressStyles(theme)['barre_pipe_invest']
                  .style,
                dimensions.width
              )}
              value={72}
            />
          </View>
        </View>
        {/* vue d'ensemble */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.gris_encart_int_flolend,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              gap: { minWidth: Breakpoints.Laptop, value: 20 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Bulletin */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: 10 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Principe */}
            <View
              style={StyleSheet.applyWidth(
                {
                  gap: 5,
                  marginBottom: 12,
                  marginLeft: { minWidth: Breakpoints.Desktop, value: 0 },
                  marginRight: { minWidth: Breakpoints.Desktop, value: 0 },
                },
                dimensions.width
              )}
            >
              {/* Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['explications'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['explications'].style,
                  dimensions.width
                )}
              >
                {
                  'Dans cette rubrique, vous pouvez signer électroniquement votre bulletin de souscription pour acquérir vos titres.'
                }
              </Text>
              {/* Délai pour non-averti */}
              <>
                {!(
                  (props.route?.params?.categorie_client ??
                    defaultProps.categorie_client) === 'non-averti'
                ) ? null : (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['explications'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['explications'].style,
                      dimensions.width
                    )}
                  >
                    {
                      'Veuillez noter que vous disposez de 4 jours pour annuler sans frais votre souscription suite à la signature du bulletin ci-dessous.'
                    }
                  </Text>
                )}
              </>
            </View>

            <AccordionGroup
              caretSize={24}
              iconSize={24}
              {...GlobalStyles.AccordionGroupStyles(theme)[
                'accordion_indicateurs'
              ].props}
              expanded={true}
              label={'Modalités de souscription'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.AccordionGroupStyles(theme)[
                    'accordion_indicateurs'
                  ].style,
                  {
                    fontFamily: 'Montserrat_600SemiBold',
                    paddingBottom: null,
                    paddingLeft: null,
                    paddingRight: null,
                    paddingTop: null,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Content */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: palettes.App.blanc_flolend,
                    borderRadius: 12,
                    borderWidth: 1,
                    marginTop: 5,
                    paddingBottom: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 20,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['explications'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['explications'].style,
                    dimensions.width
                  )}
                >
                  {props.route?.params?.modalites_sous ??
                    defaultProps.modalites_sous}
                </Text>
              </View>
            </AccordionGroup>
          </View>
          {/* Vue levée locale */}
          <>
            {!(
              props.route?.params?.bool_local ?? defaultProps.bool_local
            ) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 10 },
                  dimensions.width
                )}
              >
                {/* Titre */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                    dimensions.width
                  )}
                >
                  {'Votre justificatif de domicile'}
                </Text>

                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['explications'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['explications'].style,
                      { marginTop: 5 }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'Nous vous prions de bien vouloir téléverser un justificatif de domicile de moins de 3 mois attestant de votre éligibilité à cette collecte.'
                  }
                </Text>
                {/* Bandeau téléchargement */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 5 },
                        { minWidth: Breakpoints.Laptop, value: 10 },
                      ],
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* encart général */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* vue gauche */}
                    <View
                      style={StyleSheet.applyWidth(
                        { justifyContent: 'center', width: '60%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['corps_contenu']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                          dimensions.width
                        )}
                      >
                        {'Justificatif de domicile'}
                      </Text>
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['explications']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['explications'].style,
                          dimensions.width
                        )}
                      >
                        {'Formats acceptés : pdf, jpeg, jpg, png'}
                      </Text>
                    </View>
                    {/* vue droite */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '40%',
                        },
                        dimensions.width
                      )}
                    >
                      <>
                        {!chargement_en_cours ? null : (
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            size={'small'}
                            {...GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].props}
                            color={palettes.App['Flolend 4']}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].style,
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      {/* icone validation */}
                      <>
                        {!justificatif_uploaded ? null : (
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'MaterialCommunityIcons/check-circle-outline'}
                            size={
                              !(dimensions.width >= Breakpoints.Desktop)
                                ? 25
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 35
                                : 30
                            }
                          />
                        )}
                      </>
                      {/* Pressable charger */}
                      <>
                        {!(
                          !justificatif_uploaded && !chargement_en_cours
                        ) ? null : (
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  setChargement_en_cours(true);
                                  setAffichage_erreur_justif(false);
                                  const justificatif_domicile =
                                    await selectFileUtil({
                                      returnNameAndValue: true,
                                      multiple: false,
                                      outputBase64: true,
                                    });
                                  const fichier_est_valide =
                                    await fichier_valide(
                                      justificatif_domicile,
                                      5
                                    );
                                  if (fichier_est_valide?.success) {
                                    const valuewwznrNEp = true;
                                    setJustificatif_uploaded(valuewwznrNEp);
                                    const resultat = valuewwznrNEp;
                                    setJustificatif_domicile(
                                      justificatif_domicile
                                    );
                                    setNom_justif(justificatif_domicile?.name);
                                    const retour_bouton = areAllTrue(
                                      switchValue,
                                      switchValue2,
                                      switchValue3,
                                      resultat
                                    );
                                    setBouton_actif(retour_bouton);
                                    /* hidden 'API Request' action */
                                    /* hidden 'If/Else' action */
                                  } else {
                                    setMessage_erreur_chargement(
                                      fichier_est_valide?.message
                                    );
                                    setAffichage_erreur_justif(true);
                                  }

                                  setChargement_en_cours(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              {
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '50%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor: palettes.App['Flolend 1'],
                                  borderColor: palettes.App['Flolend 4'],
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  padding: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'corps_contenu'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'corps_contenu'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Charger'}
                              </Text>
                            </View>
                          </Pressable>
                        )}
                      </>
                    </View>
                  </View>
                  {/* Message d'erreur */}
                  <>
                    {!affichage_erreur_justif ? null : (
                      <View>
                        {/* message */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['message_erreur']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['message_erreur']
                                .style,
                              { marginLeft: 0, marginRight: 0 }
                            ),
                            dimensions.width
                          )}
                        >
                          {message_erreur_chargement}
                        </Text>
                      </View>
                    )}
                  </>
                  {/* encart chargé */}
                  <>
                    {!justificatif_uploaded ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* vue gauche */}
                        <View
                          style={StyleSheet.applyWidth(
                            { justifyContent: 'center', width: '60%' },
                            dimensions.width
                          )}
                        >
                          {/* nom_doc */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['corps_contenu']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['corps_contenu']
                                .style,
                              dimensions.width
                            )}
                          >
                            {nom_justif}
                          </Text>
                        </View>
                        {/* vue droite */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '40%',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Pressable annuler */}
                          <Pressable
                            onPress={() => {
                              try {
                                setJustificatif_uploaded(false);
                                setBouton_actif(false);
                                setJustificatif_domicile(null);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            {/* icone validation */}
                            <Icon
                              color={palettes.App.gris_fonce_flolend}
                              name={'Entypo/cross'}
                              size={
                                !(dimensions.width >= Breakpoints.Desktop)
                                  ? 25
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 35
                                  : 30
                              }
                            />
                          </Pressable>
                        </View>
                      </View>
                    )}
                  </>
                </View>
              </View>
            )}
          </>
          {/* Vue votre souscription */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '0%' },
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            {/* Section Header 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              {/* Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {'Votre souscription'}
              </Text>
            </View>
            {/* Fetch Client */}
            <BaseXanoApi.FetchAuthGET>
              {({ loading, error, data, refetchAuth }) => {
                const fetchClientData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['explications'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['explications'].style,
                      dimensions.width
                    )}
                  >
                    {'Je soussigné '}
                    {fetchClientData?.Prenom} {fetchClientData?.Nom}
                    {' demeurant au '}
                    {fetchClientData?.Adresse}
                  </Text>
                );
              }}
            </BaseXanoApi.FetchAuthGET>
            {/* Vue nb titres */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 10,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '80%' },
                  dimensions.width
                )}
              >
                {/* Texte titres */}
                <>
                  {(props.route?.params?.type_produit ??
                    defaultProps.type_produit) === 'Prêts rémunérés' ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 14 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Je déclare souscrire à '}
                      {props.route?.params?.nombre_titres ??
                        defaultProps.nombre_titres}{' '}
                      {(
                        props.route?.params?.type_produit ??
                        defaultProps.type_produit
                      )?.toLowerCase()}
                      {'.'}
                    </Text>
                  )}
                </>
                {/* Texte prêts */}
                <>
                  {!(
                    (props.route?.params?.type_produit ??
                      defaultProps.type_produit) === 'Prêts rémunérés'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                              { minWidth: Breakpoints.Mobile, value: 14 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                            ],
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Je déclare souscrire à un prêt rémunéré de '}
                      {nombreEnLettres(
                        props.route?.params?.montant_sous_euros ??
                          defaultProps.montant_sous_euros
                      )}
                      {' euros ('}
                      {format_nombre(
                        props.route?.params?.montant_sous_euros ??
                          defaultProps.montant_sous_euros,
                        2
                      )}
                      {' €).'}
                    </Text>
                  )}
                </>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '20%' },
                  dimensions.width
                )}
              >
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSwitchValue(newSwitchValue);
                      if (
                        props.route?.params?.bool_local ??
                        defaultProps.bool_local
                      ) {
                        const retour_fonction = areAllTrue(
                          newSwitchValue,
                          switchValue2,
                          switchValue3,
                          justificatif_uploaded
                        );
                        setBouton_actif(retour_fonction);
                      } else {
                        const retour_2 = areAllTrue(
                          newSwitchValue,
                          switchValue2,
                          switchValue3,
                          true
                        );
                        setBouton_actif(retour_2);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  activeThumbColor={palettes.App.blanc_flolend}
                  activeTrackColor={palettes.App['Flolend 4']}
                  inactiveThumbColor={palettes.App.blanc_flolend}
                  inactiveTrackColor={palettes.App.gris_fonce_flolend}
                  value={switchValue}
                />
              </View>
            </View>
            <Spacer left={8} right={8} bottom={5} top={5} />
            {/* Vue engagement */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '80%' },
                  dimensions.width
                )}
              >
                {/* Texte titres */}
                <>
                  {(props.route?.params?.type_produit ??
                    defaultProps.type_produit) === 'Prêts rémunérés' ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 14 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Je m'engage à verser le montant associé à ma souscription de "
                      }
                      {props.route?.params?.nombre_titres ??
                        defaultProps.nombre_titres}{' '}
                      {(
                        props.route?.params?.type_produit ??
                        defaultProps.type_produit
                      )?.toLowerCase()}
                      {" par versement en numéraire d'une somme de "}
                      {nombreEnLettres(
                        props.route?.params?.montant_sous_euros ??
                          defaultProps.montant_sous_euros
                      )}
                      {' euros ('}
                      {format_nombre(
                        props.route?.params?.montant_sous_euros ??
                          defaultProps.montant_sous_euros,
                        2
                      )}
                      {
                        " €) sur le compte ouvert par la société Flolend au nom de la Société dans les livres de Lemonway, selon la procédure de paiement accessible sur l'application Flolend."
                      }
                    </Text>
                  )}
                </>
                {/* Texte prêts */}
                <>
                  {!(
                    (props.route?.params?.type_produit ??
                      defaultProps.type_produit) === 'Prêts rémunérés'
                  ) ? null : (
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 14 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Je m'engage à verser le montant souscrit en prêt rémunéré par versement en numéraire d'une somme de "
                      }
                      {nombreEnLettres(
                        props.route?.params?.montant_sous_euros ??
                          defaultProps.montant_sous_euros
                      )}
                      {' euros ('}
                      {format_nombre(
                        props.route?.params?.montant_sous_euros ??
                          defaultProps.montant_sous_euros,
                        2
                      )}
                      {
                        " €) sur le compte ouvert par la société Flolend au nom de la Société dans les livres de Lemonway, selon la procédure de paiement accessible sur l'application Flolend."
                      }
                    </Text>
                  )}
                </>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '20%' },
                  dimensions.width
                )}
              >
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSwitchValue2(newSwitchValue);
                      if (
                        props.route?.params?.bool_local ??
                        defaultProps.bool_local
                      ) {
                        const retour_fonction = areAllTrue(
                          switchValue,
                          newSwitchValue,
                          switchValue3,
                          justificatif_uploaded
                        );
                        setBouton_actif(retour_fonction);
                      } else {
                        const retour2 = areAllTrue(
                          switchValue,
                          newSwitchValue,
                          switchValue3,
                          true
                        );
                        setBouton_actif(retour2);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  activeThumbColor={palettes.App.blanc_flolend}
                  activeTrackColor={palettes.App['Flolend 4']}
                  inactiveThumbColor={palettes.App.blanc_flolend}
                  inactiveTrackColor={palettes.App.gris_fonce_flolend}
                  value={switchValue2}
                />
              </View>
            </View>
            {/* Spacer 2 */}
            <Spacer left={8} right={8} bottom={5} top={5} />
            {/* Vue fic */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'center',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '80%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: palettes.App.blanc_flolend,
                        fontFamily: 'PTSans_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Laptop, value: 14 },
                          { minWidth: Breakpoints.Desktop, value: 18 },
                          { minWidth: Breakpoints.BigScreen, value: 22 },
                        ],
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "J'atteste avoir pris connaissance de la Fiche d'Informations Clés sur l'Investissement préalablement à la signature de la présente souscription."
                  }
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '20%' },
                  dimensions.width
                )}
              >
                <Switch
                  onValueChange={newSwitchValue => {
                    try {
                      setSwitchValue3(newSwitchValue);
                      if (
                        props.route?.params?.bool_local ??
                        defaultProps.bool_local
                      ) {
                        const retour_fonction = areAllTrue(
                          switchValue,
                          switchValue2,
                          newSwitchValue,
                          justificatif_uploaded
                        );
                        setBouton_actif(retour_fonction);
                      } else {
                        const retour2 = areAllTrue(
                          switchValue,
                          switchValue2,
                          newSwitchValue,
                          true
                        );
                        setBouton_actif(retour2);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  activeThumbColor={palettes.App.blanc_flolend}
                  activeTrackColor={palettes.App['Flolend 4']}
                  inactiveThumbColor={palettes.App.blanc_flolend}
                  inactiveTrackColor={palettes.App.gris_fonce_flolend}
                  value={switchValue3}
                />
              </View>
            </View>
          </View>
          {/* Chargement en cours */}
          <>
            {!affichage_chargement ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 10,
                    marginTop: 20,
                    paddingLeft: 20,
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  {...GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].props}
                  color={palettes.App['Flolend 4']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].style,
                    dimensions.width
                  )}
                />
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                    dimensions.width
                  )}
                >
                  {'Chargement en cours, veuillez patienter.'}
                </Text>
              </View>
            )}
          </>
          <>
            {affichage_chargement ? null : (
              <LinearGradient
                endX={100}
                endY={100}
                startX={0}
                startY={0}
                {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                  .props}
                color1={
                  (bouton_actif
                    ? palettes.App['Flolend 1']
                    : palettes.App.gris_fonce_flolend) ??
                  palettes.App['Flolend 1']
                }
                color2={
                  (bouton_actif
                    ? palettes.App['Flolend 2']
                    : palettes.App.gris_fonce_flolend) ??
                  palettes.App['Flolend 2']
                }
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                      .style,
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      alignSelf: [
                        { minWidth: Breakpoints.Laptop, value: 'center' },
                        { minWidth: Breakpoints.Tablet, value: 'center' },
                      ],
                      borderRadius: 24,
                      flex: [
                        { minWidth: Breakpoints.Laptop, value: null },
                        { minWidth: Breakpoints.Tablet, value: null },
                      ],
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 60 },
                        { minWidth: Breakpoints.Desktop, value: 65 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                      marginBottom: 30,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 35,
                      overflow: {
                        minWidth: Breakpoints.Laptop,
                        value: 'hidden',
                      },
                      width: [
                        { minWidth: Breakpoints.Laptop, value: '30%' },
                        { minWidth: Breakpoints.Tablet, value: '50%' },
                      ],
                    }
                  ),
                  dimensions.width
                )}
              >
                {/* Bouton signature */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        if (!bouton_actif) {
                          return;
                        }
                        setAffichage_chargement(true);
                        if (
                          props.route?.params?.bool_local ??
                          defaultProps.bool_local
                        ) {
                          const retour_justif = (
                            await baseXanoUploadJustifDomicilePOST.mutateAsync({
                              id_souscription:
                                props.route?.params?.id_souscription_2_3 ??
                                defaultProps.id_souscription_2_3,
                              justif: supprimer_prefixe_json(
                                justificatif_domicile
                              ),
                            })
                          )?.json;
                          if (!retour_justif?.success) {
                            setMessage_erreur(true);
                          }
                          if (!retour_justif?.success) {
                            setMessage_erreur_navigation(
                              "Erreur à l'upload de votre justificatif : veuillez réessayer."
                            );
                          }
                        } else {
                        }

                        const retour_signature = (
                          await baseXanoSignatureBulletinPATCH.mutateAsync({
                            id_sous:
                              props.route?.params?.id_souscription_2_3 ??
                              defaultProps.id_souscription_2_3,
                          })
                        )?.json;
                        if (retour_signature?.success) {
                          navigation.navigate('Souscription4Screen', {
                            id_souscription_3_4:
                              props.route?.params?.id_souscription_2_3 ??
                              defaultProps.id_souscription_2_3,
                          });
                          (
                            await baseXanoBulletinPdfPOST.mutateAsync({
                              id_souscription:
                                props.route?.params?.id_souscription_2_3 ??
                                defaultProps.id_souscription_2_3,
                            })
                          )?.json;
                        } else {
                          setMessage_erreur_navigation(
                            'Erreur à la création de votre souscription. Veuillez réessayer.'
                          );
                          setMessage_erreur(true);
                        }

                        setAffichage_chargement(false);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .style,
                      {
                        borderRadius: 24,
                        borderWidth: {
                          minWidth: Breakpoints.Laptop,
                          value: null,
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 30 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Signature'}
                />
              </LinearGradient>
            )}
          </>
          {/* Message d'erreur */}
          <>
            {!message_erreur ? null : (
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['message_erreur'].style,
                  dimensions.width
                )}
              >
                {message_erreur_navigation}
              </Text>
            )}
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(Souscription3Screen);
