import React from 'react';
import {
  AccordionGroup,
  Icon,
  IconButton,
  Picker,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Table,
  TableCell,
  TableRow,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const IndicateursDePerformanceScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [multiSelectPickerValue, setMultiSelectPickerValue] = React.useState(
    []
  );
  // Convertit un nombre en pourcentage s'il est compris dans l'intervalle ]0;1[
  const convertToPercentage = number => {
    // Vérifie si le nombre est compris dans l'intervalle ]0;1[
    if (number > 0 && number < 1) {
      // Formate le nombre en pourcentage avec deux décimales
      const percentage = (number * 100).toFixed(2) + '%';
      return percentage;
    } else {
      // Formate le nombre avec un espace tous les 3 chiffres
      const formattedNumber = number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return formattedNumber;
    }
  };

  // Filtre l'appel d'API (result1) en fonction du picker (result2).
  const filtrerObjet = (Picker, Result1) => {
    if (Picker.length === 0) {
      return Result1;
    }

    const valuesToFilter = Result1.filter(item => Picker.includes(item.annee));

    return valuesToFilter;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: 10,
                paddingLeft: 20,
              },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 18
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 30
                      : 25
                  }
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Indicateurs de performance'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            justifyContent: 'space-between',
            paddingBottom: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 0 },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue tableau documents */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: 20,
              marginRight: 20,
              marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
            },
            dimensions.width
          )}
        >
          {/* Fetch indicateurs */}
          <BaseXanoApi.FetchIndicateursPerformanceGET
            handlers={{
              onData: fetchIndicateursData => {
                try {
                  setMultiSelectPickerValue(
                    [].concat([
                      (() => {
                        const e = fetchIndicateursData?.picker;
                        return e[e.length - 1];
                      })()?.value,
                    ])
                  );
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchIndicateursPerformance }) => {
              const fetchIndicateursData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-between',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Général */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          width: { minWidth: Breakpoints.Laptop, value: '45%' },
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Dernière mise à jour : '}
                        {ConvertionDate(fetchIndicateursData?.date_maj)}
                      </Text>
                    </View>
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBackgroundColor={theme.colors.background.brand}
                      dropDownBorderColor={theme.colors.border.brand}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.text.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newPickerValue => {
                        try {
                          setMultiSelectPickerValue(newPickerValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      selectedIconColor={theme.colors.text.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      {...GlobalStyles.PickerStyles(theme)[
                        'picker_test_connaissances'
                      ].props}
                      label={'Année'}
                      options={fetchIndicateursData?.picker}
                      placeholder={''}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.PickerStyles(theme)[
                          'picker_test_connaissances'
                        ].style,
                        dimensions.width
                      )}
                      value={multiSelectPickerValue}
                    />
                  </View>
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  {/* Vue mobile et tablette */}
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: {
                              minWidth: Breakpoints.Laptop,
                              value: 'row',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Accordion obligations */}
                        <AccordionGroup
                          caretSize={24}
                          iconSize={24}
                          {...GlobalStyles.AccordionGroupStyles(theme)[
                            'accordion_indicateurs'
                          ].props}
                          expanded={false}
                          icon={'MaterialCommunityIcons/numeric-1-circle'}
                          label={'Obligations'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.AccordionGroupStyles(theme)[
                              'accordion_indicateurs'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <Table
                            borderColor={theme.colors.border.brand}
                            borderStyle={'solid'}
                            borderWidth={1}
                            cellHorizontalPadding={10}
                            cellVerticalPadding={10}
                            data={filtrerObjet(
                              multiSelectPickerValue,
                              fetchIndicateursData?.result1
                            )}
                            drawBottomBorder={false}
                            drawEndBorder={false}
                            drawStartBorder={false}
                            keyExtractor={(tableData, index) =>
                              tableData?.id ??
                              tableData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(tableData)
                            }
                            listKey={'eD8lHNez'}
                            renderItem={({ item, index }) => {
                              const tableData = item;
                              return (
                                <>
                                  {/* En-tête */}
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawTopBorder={false}
                                    borderColor={
                                      palettes.App.gris_fonce_flolend
                                    }
                                    borderWidth={2}
                                    drawStartBorder={false}
                                    isTableHeader={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 1'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Indicateur_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Indicateur'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      borderColor={palettes.App['Flolend 4']}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Cumule_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Cumulé à partir de 2024'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 3 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Annee_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Projet financé en '}
                                        {multiSelectPickerValue}
                                      </Text>
                                    </TableCell>
                                  </TableRow>
                                  {/* Données */}
                                  <>
                                    {!(
                                      tableData?.type === 'obligations'
                                    ) ? null : (
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Catégorie indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.categorie_fpf}
                                          </Text>
                                          {/* nom_indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.nom}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          borderColor={
                                            palettes.App['Flolend 4']
                                          }
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur cumulée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.cumul
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur picker */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.valeur
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsVerticalScrollIndicator={true}
                            {...GlobalStyles.TableStyles(theme)['Table'].props}
                            drawTopBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableStyles(theme)['Table'].style,
                                { flex: null }
                              ),
                              dimensions.width
                            )}
                          />
                        </AccordionGroup>
                        {/* Accordion prêts */}
                        <AccordionGroup
                          caretSize={24}
                          iconSize={24}
                          {...GlobalStyles.AccordionGroupStyles(theme)[
                            'accordion_indicateurs'
                          ].props}
                          icon={'MaterialCommunityIcons/numeric-2-circle'}
                          label={'Prêts'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.AccordionGroupStyles(theme)[
                              'accordion_indicateurs'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <Table
                            borderColor={theme.colors.border.brand}
                            borderStyle={'solid'}
                            borderWidth={1}
                            cellHorizontalPadding={10}
                            cellVerticalPadding={10}
                            data={filtrerObjet(
                              multiSelectPickerValue,
                              fetchIndicateursData?.result1
                            )}
                            drawBottomBorder={false}
                            drawEndBorder={false}
                            drawStartBorder={false}
                            keyExtractor={(tableData, index) =>
                              tableData?.id ??
                              tableData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(tableData)
                            }
                            listKey={'2G9Lbnga'}
                            renderItem={({ item, index }) => {
                              const tableData = item;
                              return (
                                <>
                                  {/* En-tête */}
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawTopBorder={false}
                                    borderColor={
                                      palettes.App.gris_fonce_flolend
                                    }
                                    borderWidth={2}
                                    drawStartBorder={false}
                                    isTableHeader={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 1'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Indicateur_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Indicateur'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      borderColor={palettes.App['Flolend 4']}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Cumule_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Cumulé à partir de 2024'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 3 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Annee_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Projet financé en '}
                                        {multiSelectPickerValue}
                                      </Text>
                                    </TableCell>
                                  </TableRow>
                                  {/* Données */}
                                  <>
                                    {!(tableData?.type === 'prets') ? null : (
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Catégorie indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.categorie_fpf}
                                          </Text>
                                          {/* nom_indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.nom}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          borderColor={
                                            palettes.App['Flolend 4']
                                          }
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur cumulée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.cumul
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur picker */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.valeur
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsVerticalScrollIndicator={true}
                            {...GlobalStyles.TableStyles(theme)['Table'].props}
                            drawTopBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableStyles(theme)['Table'].style,
                                { flex: null }
                              ),
                              dimensions.width
                            )}
                          />
                        </AccordionGroup>
                        {/* Accordion actions */}
                        <AccordionGroup
                          caretSize={24}
                          iconSize={24}
                          {...GlobalStyles.AccordionGroupStyles(theme)[
                            'accordion_indicateurs'
                          ].props}
                          icon={'MaterialCommunityIcons/numeric-3-circle'}
                          label={'Actions'}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.AccordionGroupStyles(theme)[
                              'accordion_indicateurs'
                            ].style,
                            dimensions.width
                          )}
                        >
                          <Table
                            borderColor={theme.colors.border.brand}
                            borderStyle={'solid'}
                            borderWidth={1}
                            cellHorizontalPadding={10}
                            cellVerticalPadding={10}
                            data={filtrerObjet(
                              multiSelectPickerValue,
                              fetchIndicateursData?.result1
                            )}
                            drawBottomBorder={false}
                            drawEndBorder={false}
                            drawStartBorder={false}
                            keyExtractor={(tableData, index) =>
                              tableData?.id ??
                              tableData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(tableData)
                            }
                            listKey={'PhIdirLx'}
                            renderItem={({ item, index }) => {
                              const tableData = item;
                              return (
                                <>
                                  {/* En-tête */}
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawTopBorder={false}
                                    borderColor={
                                      palettes.App.gris_fonce_flolend
                                    }
                                    borderWidth={2}
                                    drawStartBorder={false}
                                    isTableHeader={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 1'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Indicateur_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Indicateur'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      borderColor={palettes.App['Flolend 4']}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Cumule_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Cumulé à partir de 2024'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 3 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Annee_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Projet financé en '}
                                        {multiSelectPickerValue}
                                      </Text>
                                    </TableCell>
                                  </TableRow>
                                  {/* Données */}
                                  <>
                                    {!(tableData?.type === 'actions') ? null : (
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Catégorie indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.categorie_fpf}
                                          </Text>
                                          {/* nom_indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.nom}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          borderColor={
                                            palettes.App['Flolend 4']
                                          }
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur cumulée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.cumul
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur picker */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.valeur
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsVerticalScrollIndicator={true}
                            {...GlobalStyles.TableStyles(theme)['Table'].props}
                            drawTopBorder={false}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TableStyles(theme)['Table'].style,
                                { flex: null }
                              ),
                              dimensions.width
                            )}
                          />
                        </AccordionGroup>
                      </View>
                    )}
                  </>
                  {/* Vue desktop */}
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: {
                              minWidth: Breakpoints.Laptop,
                              value: 'row',
                            },
                            justifyContent: {
                              minWidth: Breakpoints.Laptop,
                              value: 'space-between',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '32%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Accordion obligations */}
                          <AccordionGroup
                            caretSize={24}
                            iconSize={24}
                            {...GlobalStyles.AccordionGroupStyles(theme)[
                              'accordion_indicateurs'
                            ].props}
                            expanded={false}
                            icon={'MaterialCommunityIcons/numeric-1-circle'}
                            label={'Obligations'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.AccordionGroupStyles(theme)[
                                'accordion_indicateurs'
                              ].style,
                              dimensions.width
                            )}
                          />
                          <Table
                            borderColor={theme.colors.border.brand}
                            borderStyle={'solid'}
                            borderWidth={1}
                            cellHorizontalPadding={10}
                            cellVerticalPadding={10}
                            data={filtrerObjet(
                              multiSelectPickerValue,
                              fetchIndicateursData?.result1
                            )}
                            drawBottomBorder={false}
                            drawEndBorder={false}
                            drawStartBorder={false}
                            keyExtractor={(tableData, index) =>
                              tableData?.id ??
                              tableData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(tableData)
                            }
                            listKey={'aFKdqwPB'}
                            renderItem={({ item, index }) => {
                              const tableData = item;
                              return (
                                <>
                                  {/* En-tête */}
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawTopBorder={false}
                                    borderColor={
                                      palettes.App.gris_fonce_flolend
                                    }
                                    borderWidth={2}
                                    drawStartBorder={false}
                                    isTableHeader={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 1'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Indicateur_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Indicateur'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      borderColor={palettes.App['Flolend 4']}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Cumule_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Cumulé à partir de 2024'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 3 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Annee_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Projets financés en '}
                                        {multiSelectPickerValue}
                                      </Text>
                                    </TableCell>
                                  </TableRow>
                                  {/* Données */}
                                  <>
                                    {!(
                                      tableData?.type === 'obligations'
                                    ) ? null : (
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Catégorie indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.categorie_fpf}
                                          </Text>
                                          {/* nom_indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.nom}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          borderColor={
                                            palettes.App['Flolend 4']
                                          }
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur cumulée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.cumul
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur picker */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.valeur
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsVerticalScrollIndicator={true}
                            {...GlobalStyles.TableStyles(theme)['Table'].props}
                            drawTopBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableStyles(theme)['Table'].style,
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '32%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Accordion prêts */}
                          <AccordionGroup
                            caretSize={24}
                            iconSize={24}
                            {...GlobalStyles.AccordionGroupStyles(theme)[
                              'accordion_indicateurs'
                            ].props}
                            icon={'MaterialCommunityIcons/numeric-2-circle'}
                            label={'Prêts'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.AccordionGroupStyles(theme)[
                                'accordion_indicateurs'
                              ].style,
                              dimensions.width
                            )}
                          />
                          <Table
                            borderColor={theme.colors.border.brand}
                            borderStyle={'solid'}
                            borderWidth={1}
                            cellHorizontalPadding={10}
                            cellVerticalPadding={10}
                            data={filtrerObjet(
                              multiSelectPickerValue,
                              fetchIndicateursData?.result1
                            )}
                            drawBottomBorder={false}
                            drawEndBorder={false}
                            drawStartBorder={false}
                            keyExtractor={(tableData, index) =>
                              tableData?.id ??
                              tableData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(tableData)
                            }
                            listKey={'rQLVXEjX'}
                            renderItem={({ item, index }) => {
                              const tableData = item;
                              return (
                                <>
                                  {/* En-tête */}
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawTopBorder={false}
                                    borderColor={
                                      palettes.App.gris_fonce_flolend
                                    }
                                    borderWidth={1}
                                    drawStartBorder={false}
                                    isTableHeader={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 1'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Indicateur_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Indicateur'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      borderColor={palettes.App['Flolend 4']}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Cumule_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Cumulé à partir de 2024'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 3 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Annee_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Projets financés en '}
                                        {multiSelectPickerValue}
                                      </Text>
                                    </TableCell>
                                  </TableRow>
                                  {/* Données */}
                                  <>
                                    {!(tableData?.type === 'prets') ? null : (
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Catégorie indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.categorie_fpf}
                                          </Text>
                                          {/* nom_indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.nom}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          borderColor={
                                            palettes.App['Flolend 4']
                                          }
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur cumulée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.cumul
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur picker */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.valeur
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsVerticalScrollIndicator={true}
                            {...GlobalStyles.TableStyles(theme)['Table'].props}
                            drawTopBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableStyles(theme)['Table'].style,
                              dimensions.width
                            )}
                          />
                        </View>
                        {/* View 3 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              width: {
                                minWidth: Breakpoints.Laptop,
                                value: '32%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Accordion actions */}
                          <AccordionGroup
                            caretSize={24}
                            iconSize={24}
                            {...GlobalStyles.AccordionGroupStyles(theme)[
                              'accordion_indicateurs'
                            ].props}
                            icon={'MaterialCommunityIcons/numeric-3-circle'}
                            label={'Actions'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.AccordionGroupStyles(theme)[
                                'accordion_indicateurs'
                              ].style,
                              dimensions.width
                            )}
                          />
                          <Table
                            borderColor={theme.colors.border.brand}
                            borderStyle={'solid'}
                            borderWidth={1}
                            cellHorizontalPadding={10}
                            cellVerticalPadding={10}
                            data={filtrerObjet(
                              multiSelectPickerValue,
                              fetchIndicateursData?.result1
                            )}
                            drawBottomBorder={false}
                            drawEndBorder={false}
                            drawStartBorder={false}
                            keyExtractor={(tableData, index) =>
                              tableData?.id ??
                              tableData?.uuid ??
                              index?.toString() ??
                              JSON.stringify(tableData)
                            }
                            listKey={'0g3epL35'}
                            renderItem={({ item, index }) => {
                              const tableData = item;
                              return (
                                <>
                                  {/* En-tête */}
                                  <TableRow
                                    drawBottomBorder={true}
                                    drawEndBorder={false}
                                    drawTopBorder={false}
                                    borderColor={
                                      palettes.App.gris_fonce_flolend
                                    }
                                    borderWidth={2}
                                    drawStartBorder={false}
                                    isTableHeader={true}
                                    style={StyleSheet.applyWidth(
                                      {
                                        backgroundColor:
                                          palettes.App['Flolend 1'],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Indicateur_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Indicateur'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 2 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      borderColor={palettes.App['Flolend 4']}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            backgroundColor:
                                              palettes.App['Flolend 1'],
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Cumule_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Cumulé à partir de 2024'}
                                      </Text>
                                    </TableCell>
                                    {/* Table Cell 3 */}
                                    <TableCell
                                      drawBottomBorder={false}
                                      drawStartBorder={false}
                                      drawTopBorder={false}
                                      {...GlobalStyles.TableCellStyles(theme)[
                                        'Table Cell'
                                      ].props}
                                      drawEndBorder={false}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TableCellStyles(theme)[
                                            'Table Cell'
                                          ].style,
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {/* Annee_titre */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'titre_tableau'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'titre_tableau'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {'Projets financés en '}
                                        {multiSelectPickerValue}
                                      </Text>
                                    </TableCell>
                                  </TableRow>
                                  {/* Données */}
                                  <>
                                    {!(tableData?.type === 'actions') ? null : (
                                      <TableRow
                                        drawBottomBorder={true}
                                        drawEndBorder={false}
                                        drawTopBorder={false}
                                        isTableHeader={false}
                                        borderColor={
                                          palettes.App.gris_fonce_flolend
                                        }
                                        borderWidth={1}
                                        drawStartBorder={false}
                                      >
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'flex-start',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Catégorie indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'sous_elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'sous_elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.categorie_fpf}
                                          </Text>
                                          {/* nom_indicateur */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {tableData?.nom}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 2 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          borderColor={
                                            palettes.App['Flolend 4']
                                          }
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur cumulée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.cumul
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                        {/* Table Cell 3 */}
                                        <TableCell
                                          drawBottomBorder={false}
                                          drawStartBorder={false}
                                          drawTopBorder={false}
                                          {...GlobalStyles.TableCellStyles(
                                            theme
                                          )['Table Cell'].props}
                                          drawEndBorder={false}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TableCellStyles(
                                                theme
                                              )['Table Cell'].style,
                                              {
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {/* Valeur picker */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'elmt_table'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'elmt_table'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {convertToPercentage(
                                              tableData?.valeur
                                            )}{' '}
                                            {tableData?.dimension}
                                          </Text>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </>
                                </>
                              );
                            }}
                            showsVerticalScrollIndicator={true}
                            {...GlobalStyles.TableStyles(theme)['Table'].props}
                            drawTopBorder={false}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TableStyles(theme)['Table'].style,
                              dimensions.width
                            )}
                          />
                        </View>
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </BaseXanoApi.FetchIndicateursPerformanceGET>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(IndicateursDePerformanceScreen);
