import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  code_iban: 'FR76300067583456098',
  id_iban_lemonway: 3,
  label_iban: 'mon_iban',
  statut_iban: 'invalide',
};

const MoneyOut15GererMesIBANsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [suppression_activee, setSuppression_activee] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  // Ajoute un espace tous les 4 caractères dans la chaine de caractères fournie en entrée
  const espaces_iban = str => {
    return str.replace(/(.{4})/g, '$1 ');
  };
  const baseXanoMajLabelIbanPATCH = BaseXanoApi.useMajLabelIbanPATCH();
  const baseXanoSuppressionIbanPUT = BaseXanoApi.useSuppressionIbanPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
      setTextInputValue(
        props.route?.params?.label_iban ?? defaultProps.label_iban
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* mon_header */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['header_classique'].style,
                  { marginBottom: 10 }
                ),
                dimensions.width
              )}
            >
              {/* Back btn */}
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      height: 44,
                      justifyContent: 'center',
                      width: 44,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={palettes.App.gris_fonce_flolend}
                    name={'AntDesign/arrowleft'}
                    size={18}
                  />
                </View>
              </Touchable>
              {/* Screen Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['screen_heading'].style,
                  dimensions.width
                )}
              >
                {'Gérer mes IBANs'}
              </Text>
            </View>
          )}
        </>
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.Tablet, value: '10%' },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 20 },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Compte client */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                gap: 10,
                marginLeft: 20,
                marginRight: 20,
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* IBAN */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: palettes.App['Flolend 2'],
                  borderRadius: 12,
                  height: 220,
                  justifyContent: 'space-between',
                  paddingBottom: 24,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 24,
                },
                dimensions.width
              )}
            >
              {/* Bandeau supérieur */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['livret_flolend_titre']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['livret_flolend_titre']
                      .style,
                    dimensions.width
                  )}
                >
                  {props.route?.params?.label_iban ?? defaultProps.label_iban}
                </Text>
                <Icon
                  color={theme.colors.background.brand}
                  name={'Ionicons/person-circle'}
                  size={30}
                />
              </View>
              {/* Bandeau inférieur */}
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['livret_flolend_solde']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['livret_flolend_solde']
                        .style,
                      {
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 16 },
                          { minWidth: Breakpoints.Desktop, value: 20 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {espaces_iban(
                    props.route?.params?.code_iban ?? defaultProps.code_iban
                  )}
                </Text>
              </View>
            </View>
          </View>
          {/* Changer l'intitulé de l'IBAN */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Encart titre */}
            <View
              style={StyleSheet.applyWidth(
                { gap: 10, paddingLeft: 20, paddingRight: 20 },
                dimensions.width
              )}
            >
              {/* Titre */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {"Modifier l'intitulé de l'IBAN"}
              </Text>
              {/* Notes */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['explications'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['explications'].style,
                  dimensions.width
                )}
              >
                {
                  "Entrez dans l'encart ci-dessous le nouvel intitulé que vous souhaitez donner à votre IBAN."
                }
              </Text>
            </View>
            {/* Encart nouveau label */}
            <View
              style={StyleSheet.applyWidth(
                {
                  justifyContent: 'center',
                  paddingBottom: 32,
                  paddingLeft: 24,
                  paddingRight: 24,
                  paddingTop: 32,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 10,
                    justifyContent: 'space-around',
                  },
                  dimensions.width
                )}
              >
                <TextInput
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newTextInputValue => {
                    try {
                      setTextInputValue(newTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['input_chgmnt_mdp']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['input_chgmnt_mdp']
                        .style,
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 0 },
                          { minWidth: Breakpoints.Laptop, value: '0%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                  value={textInputValue}
                />
                {/* Linear Gradient 2 */}
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={palettes.App['Flolend 1']}
                  color2={palettes.App['Flolend 2']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: [
                          { minWidth: Breakpoints.Laptop, value: null },
                          { minWidth: Breakpoints.BigScreen, value: null },
                        ],
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        marginTop: null,
                        overflow: {
                          minWidth: Breakpoints.Laptop,
                          value: 'hidden',
                        },
                        width: [
                          { minWidth: Breakpoints.Desktop, value: '60%' },
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const retour_api = (
                            await baseXanoMajLabelIbanPATCH.mutateAsync({
                              id_iban_lemonway:
                                props.route?.params?.id_iban_lemonway ??
                                defaultProps.id_iban_lemonway,
                              nouveau_label: textInputValue,
                            })
                          )?.json;
                          if (retour_api?.success) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('MoneyOut1RecupererFondsScreen');
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: null,
                          },
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Valider'}
                  />
                </LinearGradient>
              </View>
            </View>
          </View>
          {/* Charger ma preuve d'IBAN */}
          <>
            {!(
              (props.route?.params?.statut_iban ?? defaultProps.statut_iban) ===
              'invalide'
            ) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { gap: 10, marginLeft: 20, marginRight: 20 },
                  dimensions.width
                )}
              >
                {/* Titre */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                    dimensions.width
                  )}
                >
                  {"Charger ma preuve d'IBAN"}
                </Text>
                {/* Notes */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['explications'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['explications'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Pour valider votre IBAN, vous devez charger votre relevé d'identité bancaire ou toute autre preuve d'IBAN aux formats pdf, jpg, jpeg ou png."
                  }
                </Text>

                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={palettes.App['Flolend 1']}
                  color2={palettes.App['Flolend 2']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: [
                          { minWidth: Breakpoints.Laptop, value: null },
                          { minWidth: Breakpoints.BigScreen, value: null },
                        ],
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        marginTop: null,
                        overflow: {
                          minWidth: Breakpoints.Laptop,
                          value: 'hidden',
                        },
                        width: [
                          { minWidth: Breakpoints.Desktop, value: '60%' },
                          { minWidth: Breakpoints.Laptop, value: '50%' },
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('MoneyOut2UploadIBANScreen', {
                          label_iban: textInputValue,
                          code_iban:
                            props.route?.params?.code_iban ??
                            defaultProps.code_iban,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: null,
                          },
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                    title={"Ajouter une preuve d'IBAN"}
                  />
                </LinearGradient>
              </View>
            )}
          </>
          {/* Supprimer mon IBAN */}
          <>
            {
              /* no custom function named limite_ibans_atteinte */ undefined ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { gap: 10, padding: 20 },
                    dimensions.width
                  )}
                >
                  {/* Titre */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre1_generique']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                      dimensions.width
                    )}
                  >
                    {'Supprimer mon IBAN'}
                  </Text>

                  <LinearGradient
                    endX={100}
                    endY={100}
                    startX={0}
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'gradient_bouton'
                    ].props}
                    color1={
                      (suppression_activee
                        ? palettes.App.Transparent
                        : palettes.App['Flolend 1']) ??
                      palettes.App['Flolend 1']
                    }
                    color2={
                      (suppression_activee
                        ? palettes.App.Transparent
                        : palettes.App['Flolend 2']) ??
                      palettes.App['Flolend 2']
                    }
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Laptop,
                            value: 'center',
                          },
                          borderColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.Transparent,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: suppression_activee
                                ? palettes.App.blanc_flolend
                                : palettes.App.Transparent,
                            },
                          ],
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 15,
                          },
                          borderWidth: [
                            { minWidth: Breakpoints.Mobile, value: 1 },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: suppression_activee ? 1 : 0,
                            },
                          ],
                          flex: [
                            { minWidth: Breakpoints.BigScreen, value: null },
                            { minWidth: Breakpoints.Laptop, value: null },
                          ],
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 65 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                            { minWidth: Breakpoints.Laptop, value: 60 },
                          ],
                          marginTop: null,
                          overflow: {
                            minWidth: Breakpoints.Laptop,
                            value: 'hidden',
                          },
                          width: [
                            { minWidth: Breakpoints.Desktop, value: '60%' },
                            { minWidth: Breakpoints.Laptop, value: '50%' },
                            { minWidth: Breakpoints.BigScreen, value: '50%' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setSuppression_activee(!suppression_activee);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        dimensions.width
                      )}
                      title={`${
                        suppression_activee ? 'Annuler' : 'Supprimer mon IBAN'
                      }`}
                    />
                  </LinearGradient>
                  {/* Confirmation */}
                  <>
                    {!suppression_activee ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            marginTop: 15,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '50%' },
                            dimensions.width
                          )}
                        >
                          {/* message */}
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['corps_contenu']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['corps_contenu']
                                  .style,
                                { textAlign: 'auto' }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Confirmez-vous la suppression de votre IBAN ?'}
                          </Text>
                        </View>
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const retour_api = (
                                  await baseXanoSuppressionIbanPUT.mutateAsync({
                                    id_iban_lemonway:
                                      props.route?.params?.id_iban_lemonway ??
                                      defaultProps.id_iban_lemonway,
                                  })
                                )?.json;
                                if (retour_api?.success) {
                                  if (navigation.canGoBack()) {
                                    navigation.popToTop();
                                  }
                                  navigation.replace(
                                    'MoneyOut151ValidationSuppressionIBANScreen'
                                  );
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)[
                            'bouton_fond_blanc'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)[
                                'bouton_fond_blanc'
                              ].style,
                              {
                                borderColor: palettes.App.Internal_Hot_Red,
                                borderRadius: [
                                  { minWidth: Breakpoints.Laptop, value: null },
                                  { minWidth: Breakpoints.Mobile, value: 24 },
                                ],
                                borderWidth: 1,
                                height: [
                                  { minWidth: Breakpoints.Desktop, value: 60 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 70,
                                  },
                                ],
                                width: [
                                  { minWidth: Breakpoints.Mobile, value: 100 },
                                  { minWidth: Breakpoints.Laptop, value: 150 },
                                  { minWidth: Breakpoints.Desktop, value: 170 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 190,
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Valider'}
                        />
                      </View>
                    )}
                  </>
                </View>
              )
            }
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(MoneyOut15GererMesIBANsScreen);
