import React from 'react';
import {
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const DossierInvestisseurScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      {/* simple_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { justifyContent: 'space-between', paddingRight: 20 }
              ),
              dimensions.width
            )}
          >
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Mon dossier investisseur'}
            </Text>
            {/* Sortie */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'EspacePersonnelScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'Ionicons/exit-outline'}
                  size={18}
                />
              </View>
            </Touchable>
          </View>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-between',
              },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '15%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              marginTop: '2%',
            },
            dimensions.width
          )}
        >
          {/* Le dossier */}
          <View
            style={StyleSheet.applyWidth(
              {
                gap: [
                  { minWidth: Breakpoints.Laptop, value: 10 },
                  { minWidth: Breakpoints.Mobile, value: 10 },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* Fetch dossier invest */}
            <BaseXanoApi.FetchDossierInvestGET>
              {({ loading, error, data, refetchDossierInvest }) => {
                const fetchDossierInvestData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Le dossier investisseur */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'flex-start',
                          backgroundColor: palettes.App['Flolend 1'],
                          borderRadius: 12,
                          marginTop: { minWidth: Breakpoints.Laptop, value: 0 },
                          opacity: 1,
                          paddingBottom: 20,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'Montserrat_600SemiBold',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 18 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 26 },
                              { minWidth: Breakpoints.Tablet, value: 20 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Le dossier investisseur'}
                      </Text>
                      {/* Sub title */}
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 16 },
                              { minWidth: Breakpoints.Desktop, value: 20 },
                              { minWidth: Breakpoints.BigScreen, value: 24 },
                              { minWidth: Breakpoints.Tablet, value: 18 },
                            ],
                            marginBottom: 5,
                            marginTop: 5,
                            textAlign: 'justify',
                            whiteSpace: 'pre-line',
                          },
                          dimensions.width
                        )}
                      >
                        {'Complétez votre dossier pour commencer à investir'}
                      </Text>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Vue états */}
                        <View>
                          {/* Statut du dossier */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                ],
                                flexDirection: 'row',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Statut */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App.blanc_flolend,
                                    fontFamily: 'PTSans_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 16,
                                      },
                                    ],
                                    marginBottom: 5,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Statut : '}
                            </Text>
                            {/* complet */}
                            <>
                              {!fetchDossierInvestData?.dossier_invest_complet ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App['Flolend 4'],
                                        fontFamily: 'PTSans_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 16,
                                          },
                                        ],
                                        marginBottom: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'vous pouvez investir !'}
                                </Text>
                              )}
                            </>
                            {/* verif_manuelle */}
                            <>
                              {!(
                                !fetchDossierInvestData?.dossier_invest_complet &&
                                fetchDossierInvestData?.kyc_attente_verif_manuelle
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App.DarkOrange,
                                        fontFamily: 'PTSans_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 16,
                                          },
                                        ],
                                        marginBottom: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'en cours de vérification'}
                                </Text>
                              )}
                            </>
                            {/* incomplet */}
                            <>
                              {!(
                                !fetchDossierInvestData?.dossier_invest_complet &&
                                !fetchDossierInvestData?.kyc_attente_verif_manuelle
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App.Internal_Hot_Red,
                                        fontFamily: 'PTSans_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 16,
                                          },
                                        ],
                                        marginBottom: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'dossier incomplet'}
                                </Text>
                              )}
                            </>
                          </View>
                          {/* Catégorie du client */}
                          <>
                            {!fetchDossierInvestData?.dossier_invest_complet ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: [
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 'center',
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 'center',
                                      },
                                    ],
                                    flexDirection: 'row',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Catégorie */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App.blanc_flolend,
                                        fontFamily: 'PTSans_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 14,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 18,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 16,
                                          },
                                        ],
                                        marginBottom: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {'Catégorie UE 2020/1503 : '}
                                  {fetchDossierInvestData?.type_investisseur}
                                </Text>
                              </View>
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                    {/* Touchable test de catégorisation */}
                    <>
                      {fetchDossierInvestData?.completion_categorisation ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('Categorisation1Screen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'encart_option_dossier_investisseur'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)[
                                'encart_option_dossier_investisseur'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                  justifyContent: 'center',
                                  marginLeft: [
                                    { minWidth: Breakpoints.Mobile, value: 15 },
                                    { minWidth: Breakpoints.Laptop, value: 0 },
                                  ],
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'AntDesign/bars'}
                                size={
                                  (!(dimensions.width >= Breakpoints.Desktop)
                                    ? 40
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 50
                                    : 45) ?? 40
                                }
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  marginLeft: [
                                    { minWidth: Breakpoints.Mobile, value: 15 },
                                    { minWidth: Breakpoints.Laptop, value: 0 },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_option_doss_invest'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_option_doss_invest'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Compléter mon dossier'}
                              </Text>
                              {/* Incomplet */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    flexDirection: 'row',
                                    marginTop: 5,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'element_option_dossier_invest'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'element_option_dossier_invest'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Incomplet'}
                                </Text>
                                <Icon
                                  color={palettes.App.Internal_Hot_Red}
                                  name={'AntDesign/close'}
                                  size={
                                    !(dimensions.width >= Breakpoints.Desktop)
                                      ? 18
                                      : dimensions.width >=
                                        Breakpoints.BigScreen
                                      ? 30
                                      : 25
                                  }
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 10 },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'AntDesign/right'}
                              size={
                                !(dimensions.width >= Breakpoints.Desktop)
                                  ? 25
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 35
                                  : 30
                              }
                              style={StyleSheet.applyWidth(
                                { position: 'absolute', right: 15 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* Touchable test de connaissances */}
                    <>
                      {!(
                        fetchDossierInvestData?.type_investisseur ===
                          'non-averti' &&
                        fetchDossierInvestData?.completion_categorisation
                      ) ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('TestDeConnaissances1Screen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'encart_option_dossier_investisseur'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)[
                                'encart_option_dossier_investisseur'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                  justifyContent: 'center',
                                  marginLeft: 15,
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'AntDesign/bars'}
                                size={
                                  (!(dimensions.width >= Breakpoints.Desktop)
                                    ? 40
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 50
                                    : 45) ?? 40
                                }
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginLeft: 15 },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_option_doss_invest'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_option_doss_invest'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Test de connaissances'}
                              </Text>
                              {/* Réalisé */}
                              <>
                                {!fetchDossierInvestData?.completion_test ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row', marginTop: 5 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'element_option_dossier_invest'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'element_option_dossier_invest'
                                          ].style,
                                          {
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 22,
                                              },
                                            ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Réalisé'}
                                    </Text>
                                    <Icon
                                      color={palettes.App['Flolend 4']}
                                      name={'Feather/check'}
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 18
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 30
                                          : 25
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 10 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                              {/* Incomplet */}
                              <>
                                {fetchDossierInvestData?.completion_test ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        flexDirection: 'row',
                                        marginTop: 5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'element_option_dossier_invest'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'element_option_dossier_invest'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Incomplet'}
                                    </Text>
                                    <Icon
                                      color={palettes.App.Internal_Hot_Red}
                                      name={'AntDesign/close'}
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 18
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 30
                                          : 25
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 10 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'AntDesign/right'}
                              size={
                                !(dimensions.width >= Breakpoints.Desktop)
                                  ? 25
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 35
                                  : 30
                              }
                              style={StyleSheet.applyWidth(
                                { position: 'absolute', right: 15 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* Touchable simulation */}
                    <>
                      {!(
                        fetchDossierInvestData?.type_investisseur ===
                          'non-averti' &&
                        fetchDossierInvestData?.completion_categorisation
                      ) ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('Simulation1Screen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'encart_option_dossier_investisseur'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)[
                                'encart_option_dossier_investisseur'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                  justifyContent: 'center',
                                  marginLeft: 15,
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'AntDesign/barchart'}
                                size={
                                  (!(dimensions.width >= Breakpoints.Desktop)
                                    ? 40
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 50
                                    : 45) ?? 40
                                }
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginLeft: 15 },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_option_doss_invest'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_option_doss_invest'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Simulation'}
                              </Text>
                              {/* Réalisé */}
                              <>
                                {!fetchDossierInvestData?.completion_simulation ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flexDirection: 'row', marginTop: 5 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'element_option_dossier_invest'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'element_option_dossier_invest'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Réalisé'}
                                    </Text>
                                    <Icon
                                      color={palettes.App['Flolend 4']}
                                      name={'Feather/check'}
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 18
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 30
                                          : 25
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 10 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                              {/* Incomplet */}
                              <>
                                {fetchDossierInvestData?.completion_simulation ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: {
                                          minWidth: Breakpoints.Laptop,
                                          value: 'center',
                                        },
                                        flexDirection: 'row',
                                        marginTop: 5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'element_option_dossier_invest'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'element_option_dossier_invest'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Incomplet'}
                                    </Text>
                                    <Icon
                                      color={palettes.App.Internal_Hot_Red}
                                      name={'AntDesign/close'}
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 18
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 30
                                          : 25
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 10 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                )}
                              </>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'AntDesign/right'}
                              size={
                                !(dimensions.width >= Breakpoints.Desktop)
                                  ? 25
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 35
                                  : 30
                              }
                              style={StyleSheet.applyWidth(
                                { position: 'absolute', right: 15 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                    {/* Touchable KYC */}
                    <>
                      {!(
                        fetchDossierInvestData?.completion_categorisation &&
                        fetchDossierInvestData?.statut_kyc_wallet_lemonway !==
                          6 &&
                        !fetchDossierInvestData?.kyc_attente_verif_manuelle
                      ) ? null : (
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('ValiderMonKYC1Screen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            {...GlobalStyles.ViewStyles(theme)[
                              'encart_option_dossier_investisseur'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ViewStyles(theme)[
                                'encart_option_dossier_investisseur'
                              ].style,
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                  justifyContent: 'center',
                                  marginLeft: 15,
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: 80,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'AntDesign/idcard'}
                                size={
                                  (!(dimensions.width >= Breakpoints.Desktop)
                                    ? 40
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 50
                                    : 45) ?? 40
                                }
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                { marginLeft: 15 },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_option_doss_invest'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_option_doss_invest'
                                    ].style,
                                    {
                                      fontSize: {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 29,
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'KYC'}
                              </Text>
                              {/* Incomplet */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 'center',
                                    },
                                    flexDirection: 'row',
                                    marginTop: 5,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'element_option_dossier_invest'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'element_option_dossier_invest'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Incomplet'}
                                </Text>
                                <Icon
                                  color={palettes.App.Internal_Hot_Red}
                                  name={'AntDesign/close'}
                                  size={
                                    !(dimensions.width >= Breakpoints.Desktop)
                                      ? 18
                                      : dimensions.width >=
                                        Breakpoints.BigScreen
                                      ? 30
                                      : 25
                                  }
                                  style={StyleSheet.applyWidth(
                                    { marginLeft: 10 },
                                    dimensions.width
                                  )}
                                />
                              </View>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'AntDesign/right'}
                              size={
                                !(dimensions.width >= Breakpoints.Desktop)
                                  ? 25
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 35
                                  : 30
                              }
                              style={StyleSheet.applyWidth(
                                { position: 'absolute', right: 15 },
                                dimensions.width
                              )}
                            />
                          </View>
                        </Touchable>
                      )}
                    </>
                  </>
                );
              }}
            </BaseXanoApi.FetchDossierInvestGET>
          </View>
          {/* A propos */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexWrap: { minWidth: Breakpoints.Laptop, value: 'wrap' },
                gap: [
                  { minWidth: Breakpoints.Mobile, value: 5 },
                  { minWidth: Breakpoints.Laptop, value: 10 },
                ],
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: '2%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                paddingLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
                paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* Title */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                dimensions.width
              )}
            >
              {'A propos du dossier investisseur'}
            </Text>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {
                "Compléter votre dossier investisseur est un pré requis indispensable pour pouvoir investir sur Flolend tel que l'impose la réglementation européenne UE 2020/1503 encadrant notre activité.\n\nCe dossier est structuré en trois éléments. \n\nD'une part, un test de connaissances à déterminer vos objectifs et préférences d'investissement ainsi votre expérience en la matière. Il vous aide à mieux comprendre les risques liés au financement participatif et encadre votre capacité de financement.\n\nD'autre part, une simulation de votre capacité à supporter des pertes vous oriente sur les montants à investir sur Flolend.\n\nEnfin, le questionnaire KYC nous permet de vérifier votre identité dans le cadre de notre engagement envers la lutte contre le blanchiment de capitaux et le financement du terrorisme."
              }
            </Text>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(DossierInvestisseurScreen);
