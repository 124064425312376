import React from 'react';
import {
  Button,
  Divider,
  Icon,
  LinearProgress,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, ImageBackground, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Conversion_k_euros from '../global-functions/Conversion_k_euros';
import Convertion_pourcentage from '../global-functions/Convertion_pourcentage';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const EventailProjetsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Statut_filtre, setStatut_filtre] = React.useState('En cours');
  // Renvoie True si l'objet fourni en entrée est un array vide
  const array_vide = objet_entree => {
    if (Array.isArray(objet_entree) && objet_entree.length === 0) {
      return true;
    }
    return false;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Vue générale mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            scrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: [
                  { minWidth: Breakpoints.BigScreen, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                ],
              },
              dimensions.width
            )}
          >
            {/* Vue générale */}
            <View>
              {/* En-tête */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    marginBottom: 16,
                    marginLeft: 16,
                    marginTop: [
                      { minWidth: Breakpoints.Mobile, value: 32 },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <View>
                  {/* Titre */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                      dimensions.width
                    )}
                  >
                    {'Nos projets'}
                  </Text>
                  {/* Sous-titre En cours */}
                  <>
                    {!(Statut_filtre === 'En cours') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'sous_titre_eventail'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)[
                              'sous_titre_eventail'
                            ].style,
                            {
                              fontSize: {
                                minWidth: Breakpoints.BigScreen,
                                value: 28,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Les projets qui lèvent des fonds'}
                      </Text>
                    )}
                  </>
                  {/* Sous-titre Financé */}
                  <>
                    {!(Statut_filtre === 'Financé') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'sous_titre_eventail'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['sous_titre_eventail']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Les projets financés mais pas encore remboursés'}
                      </Text>
                    )}
                  </>
                  {/* Sous-titre A venir */}
                  <>
                    {!(Statut_filtre === 'A venir') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'sous_titre_eventail'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)[
                              'sous_titre_eventail'
                            ].style,
                            {
                              fontSize: {
                                minWidth: Breakpoints.BigScreen,
                                value: 28,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Les projets bientôt ouverts au financement'}
                      </Text>
                    )}
                  </>
                  {/* Sous-titre Pré-collecte */}
                  <>
                    {!(Statut_filtre === 'Pré-collecte') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'sous_titre_eventail'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['sous_titre_eventail']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Les projets en pré-collecte - inscriptions ouvertes'}
                      </Text>
                    )}
                  </>
                  {/* Sous-titre Remboursé */}
                  <>
                    {!(Statut_filtre === 'Remboursé') ? null : (
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'sous_titre_eventail'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['sous_titre_eventail']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Les projets financés et remboursés'}
                      </Text>
                    )}
                  </>
                </View>
              </View>
              {/* filtre basique */}
              <SimpleStyleScrollView
                bounces={true}
                keyboardShouldPersistTaps={'never'}
                nestedScrollEnabled={false}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={StyleSheet.applyWidth(
                  {
                    maxHeight: 45,
                    maxWidth: dimensions.width,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* En cours (actif) */}
                <>
                  {!(Statut_filtre === 'En cours') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('En cours');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_actif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_actif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'En cours'}
                    />
                  )}
                </>
                {/* En cours (inactif) */}
                <>
                  {!(Statut_filtre !== 'En cours') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('En cours');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_inactif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_inactif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'En cours'}
                    />
                  )}
                </>
                {/* Pré-collecte (actif) */}
                <>
                  {!(Statut_filtre === 'Pré-collecte') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_actif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_actif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'Pré-collecte'}
                    />
                  )}
                </>
                {/* Pré-collecte (inactif) */}
                <>
                  {!(Statut_filtre !== 'Pré-collecte') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('Pré-collecte');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_inactif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_inactif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'Pré-collecte'}
                    />
                  )}
                </>
                {/* Financé (actif) */}
                <>
                  {!(Statut_filtre === 'Financé') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('Financé');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_actif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_actif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'Financé'}
                    />
                  )}
                </>
                {/* Financé (inactif) */}
                <>
                  {!(Statut_filtre !== 'Financé') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('Financé');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_inactif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_inactif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'Financé'}
                    />
                  )}
                </>
                {/* A venir (actif) */}
                <>
                  {!(Statut_filtre === 'A venir') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('A venir');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_actif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_actif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'A venir'}
                    />
                  )}
                </>
                {/* A venir (inactif) */}
                <>
                  {!(Statut_filtre !== 'A venir') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('A venir');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_inactif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)[
                          'bouton_filtre_inactif_eventail'
                        ].style,
                        dimensions.width
                      )}
                      title={'A venir'}
                    />
                  )}
                </>
                {/* Remboursé (actif) */}
                <>
                  {!(Statut_filtre === 'Remboursé') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('Remboursé');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_actif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)[
                            'bouton_filtre_actif_eventail'
                          ].style,
                          { marginRight: 5 }
                        ),
                        dimensions.width
                      )}
                      title={'Remboursé'}
                    />
                  )}
                </>
                {/* Remboursé (inactif) */}
                <>
                  {!(Statut_filtre !== 'Remboursé') ? null : (
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setStatut_filtre('Remboursé');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)[
                        'bouton_filtre_inactif_eventail'
                      ].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)[
                            'bouton_filtre_inactif_eventail'
                          ].style,
                          { marginRight: 5 }
                        ),
                        dimensions.width
                      )}
                      title={'Remboursé'}
                    />
                  )}
                </>
              </SimpleStyleScrollView>
              {/* Fetch projets */}
              <BaseXanoApi.FetchProjetParStatutGET
                method={'GET'}
                statut={Statut_filtre}
              >
                {({ loading, error, data, refetchProjetParStatut }) => {
                  const fetchProjetsData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Liste projets */}
                      <SimpleStyleFlatList
                        data={fetchProjetsData}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(listeProjetsData, index) =>
                          listeProjetsData?.id ??
                          listeProjetsData?.uuid ??
                          index?.toString() ??
                          JSON.stringify(listeProjetsData)
                        }
                        keyboardShouldPersistTaps={'never'}
                        listKey={'25aUTsvW'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listeProjetsData = item;
                          return (
                            <>
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={3}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      backgroundColor:
                                        palettes.App.flolend_gris_nuance_encart,
                                      borderBottomWidth: 1,
                                      borderColor:
                                        palettes.App.gris_fonce_flolend,
                                      borderLeftWidth: 1,
                                      borderRadius: 8,
                                      borderRightWidth: 1,
                                      borderTopWidth: 1,
                                      minHeight: null,
                                      overflow: 'hidden',
                                      width: [
                                        {
                                          minWidth: Breakpoints.Tablet,
                                          value: 350,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 400,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 450,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 500,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate('ProjetScreen', {
                                        id_transfere: listeProjetsData?.id,
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { height: 240 },
                                      dimensions.width
                                    )}
                                  >
                                    <ImageBackground
                                      resizeMode={'cover'}
                                      source={imageSource(
                                        `${listeProjetsData?.Image_couverture.url}`
                                      )}
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderRadius: 6,
                                          height: '100%',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Vue statut */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'flex-end',
                                            marginTop: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              backgroundColor:
                                                palettes.App['Flolend 2'],
                                              borderBottomLeftRadius: 8,
                                              borderTopLeftRadius: 8,
                                              flexDirection: 'row',
                                              paddingBottom: 4,
                                              paddingLeft: 8,
                                              paddingRight: 8,
                                              paddingTop: 4,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <Icon
                                                size={24}
                                                color={
                                                  palettes.App.blanc_flolend
                                                }
                                                name={'Entypo/gauge'}
                                              />
                                            )}
                                          </>
                                          {/* Icon 2 */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.Laptop
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App.blanc_flolend
                                                }
                                                name={'Entypo/gauge'}
                                                size={35}
                                              />
                                            )}
                                          </>
                                          <Spacer
                                            bottom={8}
                                            top={8}
                                            left={4}
                                            right={4}
                                          />
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            ellipsizeMode={'tail'}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App.blanc_flolend,
                                                fontFamily:
                                                  'Montserrat_600SemiBold',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 20,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 24,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 28,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                            textBreakStrategy={'highQuality'}
                                          >
                                            {Statut_filtre}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Vue levée locale */}
                                      <>
                                        {!listeProjetsData?.levee_locale ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'flex-end',
                                                marginTop: 5,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  backgroundColor:
                                                    palettes.App['Flolend 1'],
                                                  borderBottomLeftRadius: 8,
                                                  borderTopLeftRadius: 8,
                                                  flexDirection: 'row',
                                                  paddingBottom: 4,
                                                  paddingLeft: 8,
                                                  paddingRight: 8,
                                                  paddingTop: 4,
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <Icon
                                                size={24}
                                                color={
                                                  theme.colors.background.brand
                                                }
                                                name={
                                                  'MaterialCommunityIcons/target-variant'
                                                }
                                              />
                                              <Spacer
                                                bottom={8}
                                                top={8}
                                                left={4}
                                                right={4}
                                              />
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                ellipsizeMode={'tail'}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      theme.colors.background
                                                        .brand,
                                                    fontFamily:
                                                      'Montserrat_600SemiBold',
                                                    fontSize: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                                textBreakStrategy={
                                                  'highQuality'
                                                }
                                              >
                                                {'Levée locale'}
                                              </Text>
                                            </View>
                                          </View>
                                        )}
                                      </>
                                    </ImageBackground>
                                  </View>
                                  {/* Zone inférieure */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        paddingBottom: 16,
                                        paddingLeft: 16,
                                        paddingRight: 16,
                                        paddingTop: 16,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      {/* Nom projet */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: palettes.App.blanc_flolend,
                                            fontFamily:
                                              'Montserrat_600SemiBold',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 20,
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 22,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 26,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 30,
                                              },
                                            ],
                                          },
                                          dimensions.width
                                        )}
                                        textBreakStrategy={'highQuality'}
                                      >
                                        {listeProjetsData?.Name}
                                      </Text>
                                      <Spacer
                                        left={8}
                                        right={8}
                                        bottom={4}
                                        top={4}
                                      />
                                      {/* View 3 */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <LinearProgress
                                          animationDuration={500}
                                          indeterminate={false}
                                          isAnimated={true}
                                          lineCap={'round'}
                                          showTrack={true}
                                          trackLineCap={'round'}
                                          color={palettes.App['Flolend 4']}
                                          style={StyleSheet.applyWidth(
                                            { width: 250 },
                                            dimensions.width
                                          )}
                                          thickness={15}
                                          trackColor={
                                            palettes.App.gris_fonce_flolend
                                          }
                                          value={Convertion_pourcentage(
                                            listeProjetsData?.Part_collectee
                                          )}
                                        />
                                        <View>
                                          {/* Part collectée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ].style,
                                                {
                                                  color:
                                                    palettes.App.blanc_flolend,
                                                  fontFamily:
                                                    'PTSans_400Regular',
                                                  fontSize: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 12,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 14,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Laptop,
                                                      value: 16,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Desktop,
                                                      value: 20,
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.BigScreen,
                                                      value: 24,
                                                    },
                                                  ],
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {Convertion_pourcentage(
                                              listeProjetsData?.Part_collectee
                                            )}
                                            {' % \ncollectés'}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Description */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={2}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color:
                                              palettes.App.gris_fonce_flolend,
                                            fontFamily: 'PTSans_400Regular',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 16,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 20,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 24,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 28,
                                              },
                                            ],
                                            lineHeight: 24,
                                            marginTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {listeProjetsData?.Description_projet}
                                      </Text>
                                      <Divider
                                        color={palettes.App.gris_fonce_flolend}
                                        style={StyleSheet.applyWidth(
                                          {
                                            height: 1,
                                            marginBottom: 12,
                                            marginTop: 12,
                                          },
                                          dimensions.width
                                        )}
                                      />
                                      {/* Bandeau indicateurs */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'stretch',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            justifyContent: 'space-around',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Montant */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <Icon
                                                size={24}
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Feather/target'}
                                              />
                                            )}
                                          </>
                                          {/* Icon 2 */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.Laptop
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Feather/target'}
                                                size={35}
                                              />
                                            )}
                                          </>
                                          <Spacer left={2} right={2} />
                                          {/* Montant à collecter */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App
                                                    .gris_fonce_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 26,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {format_nombre(
                                              Conversion_k_euros(
                                                listeProjetsData?.Montant_a_collecter_euros
                                              ),
                                              0
                                            )}
                                            {' k€'}
                                          </Text>
                                        </View>
                                        {/* Durée */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Feather/clock'}
                                                size={24}
                                              />
                                            )}
                                          </>
                                          {/* Icon 2 */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.Laptop
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={'Feather/clock'}
                                                size={35}
                                              />
                                            )}
                                          </>
                                          <Spacer left={2} right={2} />
                                          {/* Durée */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App
                                                    .gris_fonce_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 26,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listeProjetsData?.Duree}
                                          </Text>
                                        </View>
                                        {/* Rendement */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <Icon
                                                size={24}
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={
                                                  'MaterialCommunityIcons/cash-multiple'
                                                }
                                              />
                                            )}
                                          </>
                                          {/* Icon 2 */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.Laptop
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={
                                                  'MaterialCommunityIcons/cash-multiple'
                                                }
                                                size={35}
                                              />
                                            )}
                                          </>
                                          <Spacer left={2} right={2} />
                                          {/* Retour */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App
                                                    .gris_fonce_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 26,
                                                  },
                                                ],
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {listeProjetsData?.Taux}
                                            {' %/an'}
                                          </Text>
                                        </View>
                                        {/* Produit */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <>
                                            {dimensions.width >=
                                            Breakpoints.Laptop ? null : (
                                              <Icon
                                                size={24}
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={
                                                  'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                }
                                              />
                                            )}
                                          </>
                                          {/* Icon 2 */}
                                          <>
                                            {!(
                                              dimensions.width >=
                                              Breakpoints.Laptop
                                            ) ? null : (
                                              <Icon
                                                color={
                                                  palettes.App['Flolend 2']
                                                }
                                                name={
                                                  'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                }
                                                size={35}
                                              />
                                            )}
                                          </>
                                          <Spacer left={2} right={2} />
                                          {/* Produit */}
                                          <>
                                            {listeProjetsData?.Type_produit ===
                                            'Prêts rémunérés' ? null : (
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App
                                                        .gris_fonce_flolend,
                                                    fontFamily:
                                                      'PTSans_400Regular',
                                                    fontSize: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 14,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 16,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 18,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 22,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 26,
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {listeProjetsData?.Type_produit}
                                              </Text>
                                            )}
                                          </>
                                          {/* prêts */}
                                          <>
                                            {!(
                                              listeProjetsData?.Type_produit ===
                                              'Prêts rémunérés'
                                            ) ? null : (
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    color:
                                                      palettes.App
                                                        .gris_fonce_flolend,
                                                    fontFamily:
                                                      'PTSans_400Regular',
                                                    fontSize: [
                                                      {
                                                        minWidth:
                                                          Breakpoints.Mobile,
                                                        value: 14,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Tablet,
                                                        value: 16,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Laptop,
                                                        value: 18,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.Desktop,
                                                        value: 22,
                                                      },
                                                      {
                                                        minWidth:
                                                          Breakpoints.BigScreen,
                                                        value: 26,
                                                      },
                                                    ],
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {'Prêt'}
                                              </Text>
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                </Touchable>
                              </Surface>
                              <Spacer left={8} right={8} bottom={12} top={12} />
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: {
                              minWidth: Breakpoints.Tablet,
                              value: 'row',
                            },
                            flexWrap: {
                              minWidth: Breakpoints.Tablet,
                              value: 'wrap',
                            },
                            gap: { minWidth: Breakpoints.Tablet, value: 20 },
                            paddingBottom: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                            paddingTop: 16,
                          },
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
              </BaseXanoApi.FetchProjetParStatutGET>
            </View>
          </SimpleStyleScrollView>
        )}
      </>
      {/* Vue générale laptop or larger */}
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            scrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: [
                  { minWidth: Breakpoints.BigScreen, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  gap: { minWidth: Breakpoints.Laptop, value: 20 },
                  marginTop: [
                    { minWidth: Breakpoints.Laptop, value: 20 },
                    { minWidth: Breakpoints.Desktop, value: 10 },
                  ],
                  paddingLeft: [
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                    { minWidth: Breakpoints.Desktop, value: '5%' },
                  ],
                  paddingRight: [
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                    { minWidth: Breakpoints.Desktop, value: '5%' },
                  ],
                },
                dimensions.width
              )}
            >
              {/* Titre */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: { minWidth: Breakpoints.Desktop, value: 20 } },
                  dimensions.width
                )}
              >
                {/* Titre */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                    dimensions.width
                  )}
                >
                  {'Nos projets'}
                </Text>
              </View>
              {/* Projets en cours */}
              <View>
                {/* Fetch projets en cours */}
                <BaseXanoApi.FetchProjetParStatutGET
                  method={'GET'}
                  statut={'En cours'}
                >
                  {({ loading, error, data, refetchProjetParStatut }) => {
                    const fetchProjetsEnCoursData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Donnée */}
                        <>
                          {array_vide(fetchProjetsEnCoursData) ? null : (
                            <View>
                              {/* Titre */}
                              <View>
                                {/* Sous-titre En cours */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_2_generique'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Les projets qui lèvent des fonds'}
                                </Text>
                              </View>
                              {/* Liste projets */}
                              <SimpleStyleFlatList
                                data={fetchProjetsEnCoursData}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listeProjetsData, index) =>
                                  listeProjetsData?.id ??
                                  listeProjetsData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeProjetsData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'nLbYwItk'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeProjetsData = item;
                                  return (
                                    <>
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'encart_projet_eventail'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'encart_projet_eventail'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'ProjetScreen',
                                                {
                                                  id_transfere:
                                                    listeProjetsData?.id,
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                              width: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Zone supérieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_sup_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_sup_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            <ImageBackground
                                              resizeMode={'cover'}
                                              source={imageSource(
                                                `${listeProjetsData?.Image_couverture.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 6,
                                                  height: '100%',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Vue statut */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    marginTop: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ],
                                                      borderBottomLeftRadius: 8,
                                                      borderTopLeftRadius: 8,
                                                      flexDirection: 'row',
                                                      paddingBottom: 4,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    color={
                                                      palettes.App.blanc_flolend
                                                    }
                                                    name={'Entypo/gauge'}
                                                    size={
                                                      !(
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                      )
                                                        ? 18
                                                        : !(
                                                            dimensions.width >=
                                                            Breakpoints.Desktop
                                                          )
                                                        ? 25
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 35
                                                        : 30
                                                    }
                                                  />
                                                  <Spacer
                                                    bottom={8}
                                                    top={8}
                                                    left={4}
                                                    right={4}
                                                  />
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    ellipsizeMode={'tail'}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value:
                                                              palettes.App
                                                                .blanc_flolend,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              theme.colors
                                                                .background
                                                                .brand,
                                                          },
                                                        ],
                                                        fontFamily:
                                                          'Montserrat_600SemiBold',
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 20,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Tablet,
                                                            value: 18,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 24,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                    textBreakStrategy={
                                                      'highQuality'
                                                    }
                                                  >
                                                    {'En cours'}
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Vue levée locale 2 */}
                                              <>
                                                {!listeProjetsData?.levee_locale ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'flex-end',
                                                        marginTop: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            palettes.App[
                                                              'Flolend 1'
                                                            ],
                                                          borderBottomLeftRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          flexDirection: 'row',
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                          paddingTop: 4,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        color={
                                                          theme.colors
                                                            .background.brand
                                                        }
                                                        name={
                                                          'MaterialCommunityIcons/target-variant'
                                                        }
                                                      />
                                                      <Spacer
                                                        bottom={8}
                                                        top={8}
                                                        left={4}
                                                        right={4}
                                                      />
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        ellipsizeMode={'tail'}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .background
                                                                .brand,
                                                            fontFamily:
                                                              'Montserrat_600SemiBold',
                                                            fontSize: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        textBreakStrategy={
                                                          'highQuality'
                                                        }
                                                      >
                                                        {'Levée locale'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </ImageBackground>
                                          </View>
                                          {/* Zone inférieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_inf_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_inf_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Titre */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  paddingTop: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 16,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Nom projet */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )[
                                                  'titre_encart_projet_eventail'
                                                ].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'titre_encart_projet_eventail'
                                                  ].style,
                                                  dimensions.width
                                                )}
                                                textBreakStrategy={
                                                  'highQuality'
                                                }
                                              >
                                                {listeProjetsData?.Name}
                                              </Text>
                                            </View>
                                            {/* Progression */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <LinearProgress
                                                animationDuration={500}
                                                color={
                                                  theme.colors.branding.primary
                                                }
                                                indeterminate={false}
                                                isAnimated={true}
                                                lineCap={'round'}
                                                showTrack={true}
                                                thickness={10}
                                                trackColor={
                                                  theme.colors.border.brand
                                                }
                                                trackLineCap={'round'}
                                                {...GlobalStyles.LinearProgressStyles(
                                                  theme
                                                )['barre_encart_projet'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinearProgressStyles(
                                                    theme
                                                  )['barre_encart_projet']
                                                    .style,
                                                  dimensions.width
                                                )}
                                                value={Convertion_pourcentage(
                                                  listeProjetsData?.Part_collectee
                                                )}
                                              />
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )[
                                                  'vue_part_collectee_encart_projet'
                                                ].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'vue_part_collectee_encart_projet'
                                                    ].style,
                                                    {
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: '25%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '25%',
                                                        },
                                                      ],
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Part collectée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'part_collectee_encart_projet'
                                                  ].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'part_collectee_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {Convertion_pourcentage(
                                                    listeProjetsData?.Part_collectee
                                                  )}
                                                  {' % \ncollectés'}
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Description */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['description_encart_eventail']
                                                .props}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={2}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'description_encart_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listeProjetsData?.Description_projet
                                              }
                                            </Text>
                                            {/* Bandeau indicateurs */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['bandeau_indic_eventail'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'bandeau_indic_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Montant */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/target'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Montant à collecter */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {format_nombre(
                                                    Conversion_k_euros(
                                                      listeProjetsData?.Montant_a_collecter_euros
                                                    ),
                                                    0
                                                  )}
                                                  {' k€'}
                                                </Text>
                                              </View>
                                              {/* Durée */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 2 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/clock'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Durée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Duree}
                                                </Text>
                                              </View>
                                              {/* Rendement */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 3 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/cash-multiple'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Retour */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Taux}
                                                  {' %/an'}
                                                </Text>
                                              </View>
                                              {/* Produit */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 4 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Produit */}
                                                <>
                                                  {listeProjetsData?.Type_produit ===
                                                  'Prêts rémunérés' ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeProjetsData?.Type_produit
                                                      }
                                                    </Text>
                                                  )}
                                                </>
                                                {/* prêts */}
                                                <>
                                                  {!(
                                                    listeProjetsData?.Type_produit ===
                                                    'Prêts rémunérés'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Prêt'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </Surface>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: {
                                      minWidth: Breakpoints.Laptop,
                                      value:
                                        palettes.App.flolend_gris_nuance_encart,
                                    },
                                    borderRadius: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 25,
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'row',
                                    },
                                    flexWrap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'wrap',
                                    },
                                    gap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 20,
                                    },
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                      </>
                    );
                  }}
                </BaseXanoApi.FetchProjetParStatutGET>
              </View>
              {/* Projets en précollecte */}
              <View>
                {/* Fetch projets en pré-collecte */}
                <BaseXanoApi.FetchProjetParStatutGET
                  method={'GET'}
                  statut={'Pré-collecte'}
                >
                  {({ loading, error, data, refetchProjetParStatut }) => {
                    const fetchProjetsEnPrCollecteData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Donnée */}
                        <>
                          {array_vide(fetchProjetsEnPrCollecteData) ? null : (
                            <View>
                              {/* Titre */}
                              <View>
                                {/* Sous-titre Pré-collecte */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_2_generique'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'Les projets en pré-collecte - inscriptions ouvertes'
                                  }
                                </Text>
                              </View>
                              {/* Liste projets */}
                              <SimpleStyleFlatList
                                data={fetchProjetsEnPrCollecteData}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listeProjetsData, index) =>
                                  listeProjetsData?.id ??
                                  listeProjetsData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeProjetsData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'3W55C1jG'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeProjetsData = item;
                                  return (
                                    <>
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'encart_projet_eventail'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'encart_projet_eventail'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'ProjetScreen',
                                                {
                                                  id_transfere:
                                                    listeProjetsData?.id,
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                              width: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Zone supérieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_sup_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_sup_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            <ImageBackground
                                              resizeMode={'cover'}
                                              source={imageSource(
                                                `${listeProjetsData?.Image_couverture.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 6,
                                                  height: '100%',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Vue statut */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    marginTop: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ],
                                                      borderBottomLeftRadius: 8,
                                                      borderTopLeftRadius: 8,
                                                      flexDirection: 'row',
                                                      paddingBottom: 4,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    color={
                                                      theme.colors.background
                                                        .brand
                                                    }
                                                    name={'Entypo/gauge'}
                                                    size={
                                                      !(
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                      )
                                                        ? 18
                                                        : !(
                                                            dimensions.width >=
                                                            Breakpoints.Desktop
                                                          )
                                                        ? 25
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 35
                                                        : 30
                                                    }
                                                  />
                                                  <Spacer
                                                    bottom={8}
                                                    top={8}
                                                    left={4}
                                                    right={4}
                                                  />
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    ellipsizeMode={'tail'}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors
                                                            .background.brand,
                                                        fontFamily:
                                                          'Montserrat_600SemiBold',
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Tablet,
                                                            value: 18,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 24,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 20,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                    textBreakStrategy={
                                                      'highQuality'
                                                    }
                                                  >
                                                    {'En cours'}
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Vue levée locale */}
                                              <>
                                                {!listeProjetsData?.levee_locale ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'flex-end',
                                                        marginTop: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            palettes.App[
                                                              'Flolend 1'
                                                            ],
                                                          borderBottomLeftRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          flexDirection: 'row',
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                          paddingTop: 4,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        color={
                                                          theme.colors
                                                            .background.brand
                                                        }
                                                        name={
                                                          'MaterialCommunityIcons/target-variant'
                                                        }
                                                      />
                                                      <Spacer
                                                        bottom={8}
                                                        top={8}
                                                        left={4}
                                                        right={4}
                                                      />
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        ellipsizeMode={'tail'}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .background
                                                                .brand,
                                                            fontFamily:
                                                              'Montserrat_600SemiBold',
                                                            fontSize: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        textBreakStrategy={
                                                          'highQuality'
                                                        }
                                                      >
                                                        {'Levée locale'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </ImageBackground>
                                          </View>
                                          {/* Zone inférieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_inf_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_inf_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Titre */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  paddingTop: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 16,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Nom projet */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )[
                                                  'titre_encart_projet_eventail'
                                                ].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'titre_encart_projet_eventail'
                                                  ].style,
                                                  dimensions.width
                                                )}
                                                textBreakStrategy={
                                                  'highQuality'
                                                }
                                              >
                                                {listeProjetsData?.Name}
                                              </Text>
                                            </View>
                                            {/* Progression */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <LinearProgress
                                                animationDuration={500}
                                                color={
                                                  theme.colors.branding.primary
                                                }
                                                indeterminate={false}
                                                isAnimated={true}
                                                lineCap={'round'}
                                                showTrack={true}
                                                thickness={10}
                                                trackColor={
                                                  theme.colors.border.brand
                                                }
                                                trackLineCap={'round'}
                                                {...GlobalStyles.LinearProgressStyles(
                                                  theme
                                                )['barre_encart_projet'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinearProgressStyles(
                                                    theme
                                                  )['barre_encart_projet']
                                                    .style,
                                                  dimensions.width
                                                )}
                                                value={Convertion_pourcentage(
                                                  listeProjetsData?.Part_collectee
                                                )}
                                              />
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )[
                                                  'vue_part_collectee_encart_projet'
                                                ].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'vue_part_collectee_encart_projet'
                                                    ].style,
                                                    {
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '25%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: '25%',
                                                        },
                                                      ],
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Part collectée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'part_collectee_encart_projet'
                                                  ].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'part_collectee_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {Convertion_pourcentage(
                                                    listeProjetsData?.Part_collectee
                                                  )}
                                                  {' % \ncollectés'}
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Description */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['description_encart_eventail']
                                                .props}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={2}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'description_encart_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listeProjetsData?.Description_projet
                                              }
                                            </Text>
                                            {/* Bandeau indicateurs */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['bandeau_indic_eventail'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'bandeau_indic_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Montant */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/target'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Montant à collecter */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {format_nombre(
                                                    Conversion_k_euros(
                                                      listeProjetsData?.Montant_a_collecter_euros
                                                    ),
                                                    0
                                                  )}
                                                  {' k€'}
                                                </Text>
                                              </View>
                                              {/* Durée */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 2 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/clock'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Durée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Duree}
                                                </Text>
                                              </View>
                                              {/* Rendement */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 3 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/cash-multiple'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Retour */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Taux}
                                                  {' %/an'}
                                                </Text>
                                              </View>
                                              {/* Produit */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 4 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Produit */}
                                                <>
                                                  {listeProjetsData?.Type_produit ===
                                                  'Prêts rémunérés' ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeProjetsData?.Type_produit
                                                      }
                                                    </Text>
                                                  )}
                                                </>
                                                {/* prêts */}
                                                <>
                                                  {!(
                                                    listeProjetsData?.Type_produit ===
                                                    'Prêts rémunérés'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Prêt'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </Surface>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: {
                                      minWidth: Breakpoints.Laptop,
                                      value:
                                        palettes.App.flolend_gris_nuance_encart,
                                    },
                                    borderRadius: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 25,
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'row',
                                    },
                                    flexWrap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'wrap',
                                    },
                                    gap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 20,
                                    },
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                      </>
                    );
                  }}
                </BaseXanoApi.FetchProjetParStatutGET>
              </View>
              {/* Projets à venir */}
              <View>
                {/* Fetch projets à venir */}
                <BaseXanoApi.FetchProjetParStatutGET
                  method={'GET'}
                  statut={'A venir'}
                >
                  {({ loading, error, data, refetchProjetParStatut }) => {
                    const fetchProjetsVenirData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Donnée */}
                        <>
                          {array_vide(fetchProjetsVenirData) ? null : (
                            <View>
                              {/* Titre */}
                              <View>
                                {/* Sous-titre A venir */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_2_generique'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Les projets bientôt ouverts au financement'}
                                </Text>
                              </View>
                              {/* Liste projets */}
                              <SimpleStyleFlatList
                                data={fetchProjetsVenirData}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listeProjetsData, index) =>
                                  listeProjetsData?.id ??
                                  listeProjetsData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeProjetsData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'IY4NtWBz'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeProjetsData = item;
                                  return (
                                    <>
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'encart_projet_eventail'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'encart_projet_eventail'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'ProjetScreen',
                                                {
                                                  id_transfere:
                                                    listeProjetsData?.id,
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                              width: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Zone supérieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_sup_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_sup_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            <ImageBackground
                                              resizeMode={'cover'}
                                              source={imageSource(
                                                `${listeProjetsData?.Image_couverture.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 6,
                                                  height: '100%',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Vue statut */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    marginTop: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ],
                                                      borderBottomLeftRadius: 8,
                                                      borderTopLeftRadius: 8,
                                                      flexDirection: 'row',
                                                      paddingBottom: 4,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    color={
                                                      theme.colors.background
                                                        .brand
                                                    }
                                                    name={'Entypo/gauge'}
                                                    size={
                                                      !(
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                      )
                                                        ? 18
                                                        : !(
                                                            dimensions.width >=
                                                            Breakpoints.Desktop
                                                          )
                                                        ? 25
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 35
                                                        : 30
                                                    }
                                                  />
                                                  <Spacer
                                                    bottom={8}
                                                    top={8}
                                                    left={4}
                                                    right={4}
                                                  />
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    ellipsizeMode={'tail'}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors
                                                            .background.brand,
                                                        fontFamily:
                                                          'Montserrat_600SemiBold',
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Tablet,
                                                            value: 18,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 24,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 20,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                    textBreakStrategy={
                                                      'highQuality'
                                                    }
                                                  >
                                                    {'A venir'}
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Vue levée locale */}
                                              <>
                                                {!listeProjetsData?.levee_locale ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'flex-end',
                                                        marginTop: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            palettes.App[
                                                              'Flolend 1'
                                                            ],
                                                          borderBottomLeftRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          flexDirection: 'row',
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                          paddingTop: 4,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        color={
                                                          theme.colors
                                                            .background.brand
                                                        }
                                                        name={
                                                          'MaterialCommunityIcons/target-variant'
                                                        }
                                                      />
                                                      <Spacer
                                                        bottom={8}
                                                        top={8}
                                                        left={4}
                                                        right={4}
                                                      />
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        ellipsizeMode={'tail'}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .background
                                                                .brand,
                                                            fontFamily:
                                                              'Montserrat_600SemiBold',
                                                            fontSize: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        textBreakStrategy={
                                                          'highQuality'
                                                        }
                                                      >
                                                        {'Levée locale'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </ImageBackground>
                                          </View>
                                          {/* Zone inférieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_inf_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_inf_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Titre */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  paddingTop: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 16,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Nom projet */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )[
                                                  'titre_encart_projet_eventail'
                                                ].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'titre_encart_projet_eventail'
                                                  ].style,
                                                  dimensions.width
                                                )}
                                                textBreakStrategy={
                                                  'highQuality'
                                                }
                                              >
                                                {listeProjetsData?.Name}
                                              </Text>
                                            </View>
                                            {/* Progression */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <LinearProgress
                                                animationDuration={500}
                                                color={
                                                  theme.colors.branding.primary
                                                }
                                                indeterminate={false}
                                                isAnimated={true}
                                                lineCap={'round'}
                                                showTrack={true}
                                                thickness={10}
                                                trackColor={
                                                  theme.colors.border.brand
                                                }
                                                trackLineCap={'round'}
                                                {...GlobalStyles.LinearProgressStyles(
                                                  theme
                                                )['barre_encart_projet'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinearProgressStyles(
                                                    theme
                                                  )['barre_encart_projet']
                                                    .style,
                                                  dimensions.width
                                                )}
                                                value={
                                                  listeProjetsData?.Part_collectee
                                                }
                                              />
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )[
                                                  'vue_part_collectee_encart_projet'
                                                ].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'vue_part_collectee_encart_projet'
                                                    ].style,
                                                    {
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: '25%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '25%',
                                                        },
                                                      ],
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Part collectée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'part_collectee_encart_projet'
                                                  ].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'part_collectee_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {Convertion_pourcentage(
                                                    listeProjetsData?.Part_collectee
                                                  )}
                                                  {' % \ncollectés'}
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Description */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['description_encart_eventail']
                                                .props}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={2}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'description_encart_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listeProjetsData?.Description_projet
                                              }
                                            </Text>
                                            {/* Bandeau indicateurs */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['bandeau_indic_eventail'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'bandeau_indic_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Montant */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/target'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Montant à collecter */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {format_nombre(
                                                    Conversion_k_euros(
                                                      listeProjetsData?.Montant_a_collecter_euros
                                                    ),
                                                    0
                                                  )}
                                                  {' k€'}
                                                </Text>
                                              </View>
                                              {/* Durée */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 2 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/clock'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Durée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Duree}
                                                </Text>
                                              </View>
                                              {/* Rendement */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 3 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/cash-multiple'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Retour */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Taux}
                                                  {' %/an'}
                                                </Text>
                                              </View>
                                              {/* Produit */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 4 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Produit */}
                                                <>
                                                  {listeProjetsData?.Type_produit ===
                                                  'Prêts rémunérés' ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeProjetsData?.Type_produit
                                                      }
                                                    </Text>
                                                  )}
                                                </>
                                                {/* prêts */}
                                                <>
                                                  {!(
                                                    listeProjetsData?.Type_produit ===
                                                    'Prêts rémunérés'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Prêt'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </Surface>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: {
                                      minWidth: Breakpoints.Laptop,
                                      value:
                                        palettes.App.flolend_gris_nuance_encart,
                                    },
                                    borderRadius: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 25,
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'row',
                                    },
                                    flexWrap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'wrap',
                                    },
                                    gap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 20,
                                    },
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                      </>
                    );
                  }}
                </BaseXanoApi.FetchProjetParStatutGET>
              </View>
              {/* Projets financés */}
              <View>
                {/* Fetch projets financés */}
                <BaseXanoApi.FetchProjetParStatutGET
                  method={'GET'}
                  statut={'Financé'}
                >
                  {({ loading, error, data, refetchProjetParStatut }) => {
                    const fetchProjetsFinancSData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Donnée */}
                        <>
                          {array_vide(fetchProjetsFinancSData) ? null : (
                            <View>
                              {/* Titre */}
                              <View>
                                {/* Sous-titre Financé */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_2_generique'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'Les projets financés mais pas encore remboursés'
                                  }
                                </Text>
                              </View>
                              {/* Liste projets */}
                              <SimpleStyleFlatList
                                data={fetchProjetsFinancSData}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listeProjetsData, index) =>
                                  listeProjetsData?.id ??
                                  listeProjetsData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeProjetsData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'RVeriZbx'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeProjetsData = item;
                                  return (
                                    <>
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'encart_projet_eventail'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'encart_projet_eventail'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'ProjetScreen',
                                                {
                                                  id_transfere:
                                                    listeProjetsData?.id,
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                              width: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Zone supérieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_sup_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_sup_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            <ImageBackground
                                              resizeMode={'cover'}
                                              source={imageSource(
                                                `${listeProjetsData?.Image_couverture.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 6,
                                                  height: '100%',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Vue statut */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    marginTop: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ],
                                                      borderBottomLeftRadius: 8,
                                                      borderTopLeftRadius: 8,
                                                      flexDirection: 'row',
                                                      paddingBottom: 4,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    color={
                                                      theme.colors.background
                                                        .brand
                                                    }
                                                    name={'Entypo/gauge'}
                                                    size={
                                                      !(
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                      )
                                                        ? 18
                                                        : !(
                                                            dimensions.width >=
                                                            Breakpoints.Desktop
                                                          )
                                                        ? 25
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 35
                                                        : 30
                                                    }
                                                  />
                                                  <Spacer
                                                    bottom={8}
                                                    top={8}
                                                    left={4}
                                                    right={4}
                                                  />
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    ellipsizeMode={'tail'}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors
                                                            .background.brand,
                                                        fontFamily:
                                                          'Montserrat_600SemiBold',
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Tablet,
                                                            value: 18,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 24,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 20,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                    textBreakStrategy={
                                                      'highQuality'
                                                    }
                                                  >
                                                    {'Financé'}
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Vue levée locale */}
                                              <>
                                                {!listeProjetsData?.levee_locale ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'flex-end',
                                                        marginTop: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            palettes.App[
                                                              'Flolend 1'
                                                            ],
                                                          borderBottomLeftRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          flexDirection: 'row',
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                          paddingTop: 4,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        color={
                                                          theme.colors
                                                            .background.brand
                                                        }
                                                        name={
                                                          'MaterialCommunityIcons/target-variant'
                                                        }
                                                      />
                                                      <Spacer
                                                        bottom={8}
                                                        top={8}
                                                        left={4}
                                                        right={4}
                                                      />
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        ellipsizeMode={'tail'}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .background
                                                                .brand,
                                                            fontFamily:
                                                              'Montserrat_600SemiBold',
                                                            fontSize: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        textBreakStrategy={
                                                          'highQuality'
                                                        }
                                                      >
                                                        {'Levée locale'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </ImageBackground>
                                          </View>
                                          {/* Zone inférieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_inf_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_inf_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Titre */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  paddingTop: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 16,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Nom projet */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )[
                                                  'titre_encart_projet_eventail'
                                                ].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'titre_encart_projet_eventail'
                                                  ].style,
                                                  dimensions.width
                                                )}
                                                textBreakStrategy={
                                                  'highQuality'
                                                }
                                              >
                                                {listeProjetsData?.Name}
                                              </Text>
                                            </View>
                                            {/* Progression */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <LinearProgress
                                                animationDuration={500}
                                                color={
                                                  theme.colors.branding.primary
                                                }
                                                indeterminate={false}
                                                isAnimated={true}
                                                lineCap={'round'}
                                                showTrack={true}
                                                thickness={10}
                                                trackColor={
                                                  theme.colors.border.brand
                                                }
                                                trackLineCap={'round'}
                                                {...GlobalStyles.LinearProgressStyles(
                                                  theme
                                                )['barre_encart_projet'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinearProgressStyles(
                                                    theme
                                                  )['barre_encart_projet']
                                                    .style,
                                                  dimensions.width
                                                )}
                                                value={
                                                  listeProjetsData?.Part_collectee
                                                }
                                              />
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )[
                                                  'vue_part_collectee_encart_projet'
                                                ].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'vue_part_collectee_encart_projet'
                                                    ].style,
                                                    {
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: '25%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '25%',
                                                        },
                                                      ],
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Part collectée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'part_collectee_encart_projet'
                                                  ].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'part_collectee_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {Convertion_pourcentage(
                                                    listeProjetsData?.Part_collectee
                                                  )}
                                                  {' % \ncollectés'}
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Description */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['description_encart_eventail']
                                                .props}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={2}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'description_encart_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listeProjetsData?.Description_projet
                                              }
                                            </Text>
                                            {/* Bandeau indicateurs */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['bandeau_indic_eventail'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'bandeau_indic_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Montant */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/target'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Montant à collecter */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {format_nombre(
                                                    Conversion_k_euros(
                                                      listeProjetsData?.Montant_a_collecter_euros
                                                    ),
                                                    0
                                                  )}
                                                  {' k€'}
                                                </Text>
                                              </View>
                                              {/* Durée */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 2 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/clock'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Durée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Duree}
                                                </Text>
                                              </View>
                                              {/* Rendement */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 3 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/cash-multiple'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Retour */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Taux}
                                                  {' %/an'}
                                                </Text>
                                              </View>
                                              {/* Produit */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 4 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Produit */}
                                                <>
                                                  {listeProjetsData?.Type_produit ===
                                                  'Prêts rémunérés' ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeProjetsData?.Type_produit
                                                      }
                                                    </Text>
                                                  )}
                                                </>
                                                {/* prêts */}
                                                <>
                                                  {!(
                                                    listeProjetsData?.Type_produit ===
                                                    'Prêts rémunérés'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Prêt'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </Surface>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: {
                                      minWidth: Breakpoints.Laptop,
                                      value:
                                        palettes.App.flolend_gris_nuance_encart,
                                    },
                                    borderRadius: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 25,
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'row',
                                    },
                                    flexWrap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'wrap',
                                    },
                                    gap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 20,
                                    },
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                      </>
                    );
                  }}
                </BaseXanoApi.FetchProjetParStatutGET>
              </View>
              {/* Projets remboursé */}
              <View>
                {/* Fetch projets remboursés */}
                <BaseXanoApi.FetchProjetParStatutGET
                  method={'GET'}
                  statut={'Remboursé'}
                >
                  {({ loading, error, data, refetchProjetParStatut }) => {
                    const fetchProjetsRemboursSData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <>
                        {/* Donnée */}
                        <>
                          {array_vide(fetchProjetsRemboursSData) ? null : (
                            <View>
                              {/* Titre */}
                              <View>
                                {/* Sous-titre Remboursé */}
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'titre_2_generique'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'titre_2_generique'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Les projets financés et remboursés'}
                                </Text>
                              </View>
                              {/* Liste projets */}
                              <SimpleStyleFlatList
                                data={fetchProjetsRemboursSData}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(listeProjetsData, index) =>
                                  listeProjetsData?.id ??
                                  listeProjetsData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeProjetsData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'hCdIGFAv'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeProjetsData = item;
                                  return (
                                    <>
                                      <Surface
                                        {...GlobalStyles.SurfaceStyles(theme)[
                                          'encart_projet_eventail'
                                        ].props}
                                        elevation={3}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.SurfaceStyles(theme)[
                                            'encart_projet_eventail'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            try {
                                              navigation.navigate(
                                                'ProjetScreen',
                                                {
                                                  id_transfere:
                                                    listeProjetsData?.id,
                                                }
                                              );
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          }}
                                          style={StyleSheet.applyWidth(
                                            {
                                              height: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                              width: {
                                                minWidth: Breakpoints.Laptop,
                                                value: '100%',
                                              },
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Zone supérieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_sup_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_sup_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            <ImageBackground
                                              resizeMode={'cover'}
                                              source={imageSource(
                                                `${listeProjetsData?.Image_couverture.url}`
                                              )}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 6,
                                                  height: '100%',
                                                  width: '100%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Vue statut */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-end',
                                                    marginTop: 16,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <View
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      alignItems: 'center',
                                                      backgroundColor:
                                                        palettes.App[
                                                          'Flolend 2'
                                                        ],
                                                      borderBottomLeftRadius: 8,
                                                      borderTopLeftRadius: 8,
                                                      flexDirection: 'row',
                                                      paddingBottom: 4,
                                                      paddingLeft: 8,
                                                      paddingRight: 8,
                                                      paddingTop: 4,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  <Icon
                                                    color={
                                                      theme.colors.background
                                                        .brand
                                                    }
                                                    name={'Entypo/gauge'}
                                                    size={
                                                      !(
                                                        dimensions.width >=
                                                        Breakpoints.Laptop
                                                      )
                                                        ? 18
                                                        : !(
                                                            dimensions.width >=
                                                            Breakpoints.Desktop
                                                          )
                                                        ? 25
                                                        : dimensions.width >=
                                                          Breakpoints.BigScreen
                                                        ? 35
                                                        : 30
                                                    }
                                                  />
                                                  <Spacer
                                                    bottom={8}
                                                    top={8}
                                                    left={4}
                                                    right={4}
                                                  />
                                                  <Text
                                                    accessible={true}
                                                    selectable={false}
                                                    ellipsizeMode={'tail'}
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        color:
                                                          theme.colors
                                                            .background.brand,
                                                        fontFamily:
                                                          'Montserrat_600SemiBold',
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Tablet,
                                                            value: 18,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Laptop,
                                                            value: 16,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.BigScreen,
                                                            value: 24,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 20,
                                                          },
                                                        ],
                                                      },
                                                      dimensions.width
                                                    )}
                                                    textBreakStrategy={
                                                      'highQuality'
                                                    }
                                                  >
                                                    {'Remboursé'}
                                                  </Text>
                                                </View>
                                              </View>
                                              {/* Vue levée locale */}
                                              <>
                                                {!listeProjetsData?.levee_locale ? null : (
                                                  <View
                                                    style={StyleSheet.applyWidth(
                                                      {
                                                        alignItems: 'flex-end',
                                                        marginTop: 5,
                                                      },
                                                      dimensions.width
                                                    )}
                                                  >
                                                    <View
                                                      style={StyleSheet.applyWidth(
                                                        {
                                                          alignItems: 'center',
                                                          backgroundColor:
                                                            palettes.App[
                                                              'Flolend 1'
                                                            ],
                                                          borderBottomLeftRadius: 8,
                                                          borderTopLeftRadius: 8,
                                                          flexDirection: 'row',
                                                          paddingBottom: 4,
                                                          paddingLeft: 8,
                                                          paddingRight: 8,
                                                          paddingTop: 4,
                                                        },
                                                        dimensions.width
                                                      )}
                                                    >
                                                      <Icon
                                                        size={24}
                                                        color={
                                                          theme.colors
                                                            .background.brand
                                                        }
                                                        name={
                                                          'MaterialCommunityIcons/target-variant'
                                                        }
                                                      />
                                                      <Spacer
                                                        bottom={8}
                                                        top={8}
                                                        left={4}
                                                        right={4}
                                                      />
                                                      <Text
                                                        accessible={true}
                                                        selectable={false}
                                                        ellipsizeMode={'tail'}
                                                        style={StyleSheet.applyWidth(
                                                          {
                                                            color:
                                                              theme.colors
                                                                .background
                                                                .brand,
                                                            fontFamily:
                                                              'Montserrat_600SemiBold',
                                                            fontSize: 16,
                                                          },
                                                          dimensions.width
                                                        )}
                                                        textBreakStrategy={
                                                          'highQuality'
                                                        }
                                                      >
                                                        {'Levée locale'}
                                                      </Text>
                                                    </View>
                                                  </View>
                                                )}
                                              </>
                                            </ImageBackground>
                                          </View>
                                          {/* Zone inférieure */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'zone_inf_encart_projet'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'zone_inf_encart_projet'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Titre */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  paddingTop: {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 16,
                                                  },
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Nom projet */}
                                              <Text
                                                accessible={true}
                                                selectable={false}
                                                {...GlobalStyles.TextStyles(
                                                  theme
                                                )[
                                                  'titre_encart_projet_eventail'
                                                ].props}
                                                ellipsizeMode={'tail'}
                                                numberOfLines={2}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'titre_encart_projet_eventail'
                                                  ].style,
                                                  dimensions.width
                                                )}
                                                textBreakStrategy={
                                                  'highQuality'
                                                }
                                              >
                                                {listeProjetsData?.Name}
                                              </Text>
                                            </View>
                                            {/* Progression */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                  justifyContent:
                                                    'space-between',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              <LinearProgress
                                                animationDuration={500}
                                                color={
                                                  theme.colors.branding.primary
                                                }
                                                indeterminate={false}
                                                isAnimated={true}
                                                lineCap={'round'}
                                                showTrack={true}
                                                thickness={10}
                                                trackColor={
                                                  theme.colors.border.brand
                                                }
                                                trackLineCap={'round'}
                                                {...GlobalStyles.LinearProgressStyles(
                                                  theme
                                                )['barre_encart_projet'].props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.LinearProgressStyles(
                                                    theme
                                                  )['barre_encart_projet']
                                                    .style,
                                                  dimensions.width
                                                )}
                                                value={
                                                  listeProjetsData?.Part_collectee
                                                }
                                              />
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )[
                                                  'vue_part_collectee_encart_projet'
                                                ].props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'vue_part_collectee_encart_projet'
                                                    ].style,
                                                    {
                                                      width: [
                                                        {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: '25%',
                                                        },
                                                        {
                                                          minWidth:
                                                            Breakpoints.Laptop,
                                                          value: '25%',
                                                        },
                                                      ],
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Part collectée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )[
                                                    'part_collectee_encart_projet'
                                                  ].props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'part_collectee_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {Convertion_pourcentage(
                                                    listeProjetsData?.Part_collectee
                                                  )}
                                                  {' % \ncollectés'}
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Description */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['description_encart_eventail']
                                                .props}
                                              ellipsizeMode={'tail'}
                                              numberOfLines={2}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'description_encart_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {
                                                listeProjetsData?.Description_projet
                                              }
                                            </Text>
                                            {/* Bandeau indicateurs */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['bandeau_indic_eventail'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'bandeau_indic_eventail'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Montant */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/target'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Montant à collecter */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {format_nombre(
                                                    Conversion_k_euros(
                                                      listeProjetsData?.Montant_a_collecter_euros
                                                    ),
                                                    0
                                                  )}
                                                  {' k€'}
                                                </Text>
                                              </View>
                                              {/* Durée */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 2 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={'Feather/clock'}
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Durée */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Duree}
                                                </Text>
                                              </View>
                                              {/* Rendement */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 3 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/cash-multiple'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Retour */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['indicateurs_encart_projet']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'indicateurs_encart_projet'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listeProjetsData?.Taux}
                                                  {' %/an'}
                                                </Text>
                                              </View>
                                              {/* Produit */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Icon 4 */}
                                                <Icon
                                                  color={
                                                    palettes.App['Flolend 2']
                                                  }
                                                  name={
                                                    'MaterialCommunityIcons/newspaper-variant-multiple-outline'
                                                  }
                                                  size={
                                                    !(
                                                      dimensions.width >=
                                                      Breakpoints.Laptop
                                                    )
                                                      ? 18
                                                      : !(
                                                          dimensions.width >=
                                                          Breakpoints.Desktop
                                                        )
                                                      ? 25
                                                      : dimensions.width >=
                                                        Breakpoints.BigScreen
                                                      ? 35
                                                      : 30
                                                  }
                                                />
                                                <Spacer left={2} right={2} />
                                                {/* Produit */}
                                                <>
                                                  {listeProjetsData?.Type_produit ===
                                                  'Prêts rémunérés' ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {
                                                        listeProjetsData?.Type_produit
                                                      }
                                                    </Text>
                                                  )}
                                                </>
                                                {/* prêts */}
                                                <>
                                                  {!(
                                                    listeProjetsData?.Type_produit ===
                                                    'Prêts rémunérés'
                                                  ) ? null : (
                                                    <Text
                                                      accessible={true}
                                                      selectable={false}
                                                      {...GlobalStyles.TextStyles(
                                                        theme
                                                      )[
                                                        'indicateurs_encart_projet'
                                                      ].props}
                                                      style={StyleSheet.applyWidth(
                                                        GlobalStyles.TextStyles(
                                                          theme
                                                        )[
                                                          'indicateurs_encart_projet'
                                                        ].style,
                                                        dimensions.width
                                                      )}
                                                    >
                                                      {'Prêt'}
                                                    </Text>
                                                  )}
                                                </>
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </Surface>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: {
                                      minWidth: Breakpoints.Laptop,
                                      value:
                                        palettes.App.flolend_gris_nuance_encart,
                                    },
                                    borderRadius: {
                                      minWidth: Breakpoints.Laptop,
                                      value: 25,
                                    },
                                    flexDirection: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'row',
                                    },
                                    flexWrap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 'wrap',
                                    },
                                    gap: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 20,
                                    },
                                    paddingBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                    paddingTop: 16,
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          )}
                        </>
                      </>
                    );
                  }}
                </BaseXanoApi.FetchProjetParStatutGET>
              </View>
            </View>
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <FooterDesktopBlock />
              )}
            </>
          </SimpleStyleScrollView>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(EventailProjetsScreen);
