import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { retour_api_test: null };

const TestDeConnaissances3bisScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [checkboxValue3, setCheckboxValue3] = React.useState(false);
  // Fait un POST dans la table Souscriptions et renvoie l'id de la ligne créée.
  const post_sous = async (id_client, id_projet, montant) => {
    const apiKey =
      'patTnyhlx9q1uOzB6.9947d51407877f20352b6cc565b385c99655f28c39342eb0545d725554af6cb1';
    const baseId = 'apprRcKudN6J0jkZj';
    const tableName = 'Souscriptions';

    const apiUrl = `https://api.airtable.com/v0/${baseId}/${tableName}`;
    const headers = new Headers({
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    });

    const recordData = {
      fields: {
        Client: [id_client],
        Projet: id_projet,
        montant_souscrit_euros: montant,
      },
      typecast: true,
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(recordData),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.id) {
          return data.id;
        } else {
          console.error("L'ID de la ligne n'a pas été renvoyé par Airtable.");
          return null;
        }
      } else {
        throw new Error(
          'Erreur lors de la création du record dans Airtable : ' +
            response.status
        );
      }
    } catch (error) {
      console.error(
        'Erreur lors de la création du record dans Airtable :',
        error
      );
      return null;
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Linear Gradient mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
              .props}
            color1={
              (checkboxValue3
                ? palettes.App['Flolend 1']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 1']
            }
            color2={
              (checkboxValue3
                ? palettes.App['Flolend 2']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 2']
            }
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                  .style,
                {
                  alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                  alignSelf: [
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                  borderRadius: [
                    { minWidth: Breakpoints.BigScreen, value: 15 },
                    { minWidth: Breakpoints.Laptop, value: 15 },
                  ],
                  bottom: 20,
                  flex: null,
                  height: [
                    { minWidth: Breakpoints.Desktop, value: 65 },
                    { minWidth: Breakpoints.BigScreen, value: 70 },
                    { minWidth: Breakpoints.Tablet, value: null },
                    { minWidth: Breakpoints.Laptop, value: 60 },
                  ],
                  marginLeft: '5%',
                  marginRight: '5%',
                  overflow: 'hidden',
                  position: 'absolute',
                  width: [
                    { minWidth: Breakpoints.BigScreen, value: '50%' },
                    { minWidth: Breakpoints.Desktop, value: '60%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                    { minWidth: Breakpoints.Laptop, value: '75%' },
                    { minWidth: Breakpoints.Mobile, value: '80%' },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {/* Bouton étape suivante activé */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  if (!checkboxValue3) {
                    return;
                  }
                  if (
                    !(
                      props.route?.params?.retour_api_test ??
                      defaultProps.retour_api_test
                    )?.simulation_validee
                  ) {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('Simulation1Screen');
                  }
                  if (
                    !(
                      props.route?.params?.retour_api_test ??
                      defaultProps.retour_api_test
                    )?.simulation_validee
                  ) {
                    return;
                  }
                  if (
                    (
                      props.route?.params?.retour_api_test ??
                      defaultProps.retour_api_test
                    )?.dossier_complet
                  ) {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('DossierCompletScreen');
                  } else {
                    if (
                      !(
                        props.route?.params?.retour_api_test ??
                        defaultProps.retour_api_test
                      )?.verif_manuelle_wallet
                    ) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('ValiderMonKYC1Screen');
                    }
                    if (
                      (
                        props.route?.params?.retour_api_test ??
                        defaultProps.retour_api_test
                      )?.verif_manuelle_wallet
                    ) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('DossierInvestisseurScreen');
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                  { fontSize: { minWidth: Breakpoints.BigScreen, value: 24 } }
                ),
                dimensions.width
              )}
              title={"Je comprends et j'accepte les risques."}
            />
          </LinearGradient>
        )}
      </>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        style={StyleSheet.applyWidth(
          {
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Masterview */}
        <View>
          {/* En-tête */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'column' },
              dimensions.width
            )}
          >
            {/* entete */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginLeft: [
                    { minWidth: Breakpoints.BigScreen, value: '2%' },
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                  ],
                  marginRight: '5%',
                  marginTop: '2%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { width: '80%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'titre_pipe_test_connaissances'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'titre_pipe_test_connaissances'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Test de connaissances'}
                </Text>
              </View>
              <>
                {dimensions.width >= Breakpoints.Laptop ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '20%',
                      },
                      dimensions.width
                    )}
                  >
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('DossierInvestisseurScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Ionicons/exit-outline'}
                        size={18}
                      />
                    </Touchable>
                  </View>
                )}
              </>
            </View>
            {/* frise */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.flolend_gris_nuance_encart,
                    },
                    {
                      minWidth: Breakpoints.Mobile,
                      value: palettes.App.gris_encart_int_flolend,
                    },
                  ],
                  justifyContent: 'space-evenly',
                  marginTop: '2%',
                  paddingBottom: 5,
                  paddingTop: 5,
                },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['frise_test_titre_etape']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['frise_test_titre_etape']
                    .style,
                  dimensions.width
                )}
              >
                {'Etape 3/3 : Compréhension des risques'}
              </Text>
              <LinearProgress
                animationDuration={500}
                indeterminate={false}
                isAnimated={true}
                lineCap={'round'}
                showTrack={true}
                thickness={10}
                trackLineCap={'round'}
                color={palettes.App['Flolend 4']}
                style={StyleSheet.applyWidth(
                  { alignSelf: 'center', width: '95%' },
                  dimensions.width
                )}
                trackColor={palettes.App.gris_fonce_flolend}
                value={100}
              />
            </View>
          </View>
          {/* Vue générale */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Desktop, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
              },
              dimensions.width
            )}
          >
            {/* Avertissement */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 15 }, dimensions.width)}
            >
              {/* Label 5 */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {'Information à propos des risques'}
              </Text>
              {/* Explication */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['explications'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['explications'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Au vu de vos réponses, les services proposés par Flolend peuvent être inadaptés à votre profil, et nous vous recommandons d'étudier les risques encourus lors d'un investissement en financement participatif."
                  }
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['explications'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['explications'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Un investissement dans un projet de financement participatif comporte le risque de perte totale du capital investi. Il nécessite de surcroît l'immobilisation de votre épargne."
                  }
                </Text>
              </View>
              {/* Divider 3 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
              {/* Validation */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: {
                      minWidth: Breakpoints.BigScreen,
                      value: 'center',
                    },
                    flexDirection: 'row',
                    gap: 5,
                    maxWidth: '90%',
                  },
                  dimensions.width
                )}
              >
                <Checkbox
                  onPress={newCheckboxValue => {
                    try {
                      setCheckboxValue3(newCheckboxValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  color={palettes.App['Flolend 4']}
                  status={checkboxValue3}
                  uncheckedColor={palettes.App.gris_fonce_flolend}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['question_simulation']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['question_simulation'].style,
                    dimensions.width
                  )}
                >
                  {
                    'Je comprends les risques associés à un produit de financement participatif.'
                  }
                </Text>
              </View>
              {/* Divider 6 */}
              <Divider
                color={theme.colors.border.brand}
                {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.DividerStyles(theme)['div simul'].style,
                  dimensions.width
                )}
              />
            </View>
            {/* Linear Gradient ordi */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={
                    (checkboxValue3
                      ? palettes.App['Flolend 1']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 1']
                  }
                  color2={
                    (checkboxValue3
                      ? palettes.App['Flolend 2']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 2']
                  }
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignItems: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        borderRadius: [
                          { minWidth: Breakpoints.BigScreen, value: 15 },
                          { minWidth: Breakpoints.Laptop, value: 15 },
                        ],
                        flex: null,
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                          { minWidth: Breakpoints.Tablet, value: null },
                          { minWidth: Breakpoints.Laptop, value: 60 },
                        ],
                        overflow: 'hidden',
                        width: [
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                          { minWidth: Breakpoints.Desktop, value: '60%' },
                          { minWidth: Breakpoints.Mobile, value: '100%' },
                          { minWidth: Breakpoints.Tablet, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Bouton étape suivante activé */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        if (!checkboxValue3) {
                          return;
                        }
                        if (
                          !(
                            props.route?.params?.retour_api_test ??
                            defaultProps.retour_api_test
                          )?.simulation_validee
                        ) {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('Simulation1Screen');
                        }
                        if (
                          !(
                            props.route?.params?.retour_api_test ??
                            defaultProps.retour_api_test
                          )?.simulation_validee
                        ) {
                          return;
                        }
                        if (
                          (
                            props.route?.params?.retour_api_test ??
                            defaultProps.retour_api_test
                          )?.dossier_complet
                        ) {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('DossierCompletScreen');
                        } else {
                          if (
                            !(
                              props.route?.params?.retour_api_test ??
                              defaultProps.retour_api_test
                            )?.verif_manuelle_wallet
                          ) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('ValiderMonKYC1Screen');
                          }
                          if (
                            (
                              props.route?.params?.retour_api_test ??
                              defaultProps.retour_api_test
                            )?.verif_manuelle_wallet
                          ) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('DossierInvestisseurScreen');
                          }
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 24,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                    title={"Je comprends et j'accepte les risques."}
                  />
                </LinearGradient>
              )}
            </>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(TestDeConnaissances3bisScreen);
