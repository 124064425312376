import React from 'react';
import {
  Button,
  CheckboxRow,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  Picker,
  RadioButtonGroup,
  RadioButtonRow,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const TestDeConnaissances2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_bouton, setAffichage_bouton] = React.useState(false);
  const [checkPdts1, setCheckPdts1] = React.useState(false);
  const [checkPdts10, setCheckPdts10] = React.useState(false);
  const [checkPdts2, setCheckPdts2] = React.useState(false);
  const [checkPdts3, setCheckPdts3] = React.useState(false);
  const [checkPdts4, setCheckPdts4] = React.useState(false);
  const [checkPdts5, setCheckPdts5] = React.useState(false);
  const [checkPdts6, setCheckPdts6] = React.useState(false);
  const [checkPdts7, setCheckPdts7] = React.useState(false);
  const [checkPdts8, setCheckPdts8] = React.useState(false);
  const [checkPdts9, setCheckPdts9] = React.useState(false);
  const [options_dernier_invest, setOptions_dernier_invest] = React.useState([
    { label: 'Moins de 2 ans', value: 'Moins de 2 ans' },
    { label: 'Entre 2 et 5 ans', value: 'Entre 2 et 5 ans' },
    { label: 'Plus de 5 ans', value: 'Plus de 5 ans' },
    {
      label: 'Aucun investissement préalable',
      value: 'Aucun investissement préalable',
    },
  ]);
  const [options_dernier_invest_sans_q10, setOptions_dernier_invest_sans_q10] =
    React.useState([
      { label: 'Moins de 2 ans', value: 'Moins de 2 ans' },
      { label: 'Entre 2 et 5 ans', value: 'Entre 2 et 5 ans' },
      { label: 'Plus de 5 ans', value: 'Plus de 5 ans' },
    ]);
  const [options_fin_part, setOptions_fin_part] = React.useState([
    { label: 'Moins de 1 000 €', value: 'Moins de 1 000 €' },
    { label: 'Entre 1 000 € et 5 000 €', value: 'Entre 1 000 € et 5 000 €' },
    { label: 'Entre 5 000 € et 20 000 €', value: 'Entre 5 000 € et 20 000 €' },
    { label: 'Plus de 20 000 €', value: 'Plus de 20 000 €' },
  ]);
  const [picker_dernier_invest, setPicker_dernier_invest] = React.useState('');
  const [picker_montant_fin_part, setPicker_montant_fin_part] =
    React.useState('');
  const [radioButtonPPE, setRadioButtonPPE] = React.useState('');
  const [radioButtonUSpers, setRadioButtonUSpers] = React.useState('');
  const [textInputPPE, setTextInputPPE] = React.useState('');
  const [checkboxRowValue, setCheckboxRowValue] = React.useState(undefined);
  // Renvoie vrai si l'array fourni en entrée est vide.
  const array_vide = array => {
    return array.length === 0;
  };

  // Renvoie VRAI si l'utilisateur ne coche rien.
  const completude_pdts = (
    check_pdts1,
    check_pdts2,
    check_pdts3,
    check_pdts4,
    check_pdts5,
    check_pdts6,
    check_pdts7,
    check_pdts8,
    check_pdts9,
    check_pdts10
  ) => {
    return (
      check_pdts1 === false &&
      check_pdts2 === false &&
      check_pdts3 === false &&
      check_pdts4 === false &&
      check_pdts5 === false &&
      check_pdts6 === false &&
      check_pdts7 === false &&
      check_pdts8 === false &&
      check_pdts9 === false &&
      check_pdts10 === false
    );
  };

  // Associe les données récupérées dans Airtable par le FETCH aux variables d'écran.
  const init_variables_scr2 = (
    personne_US,
    personne_politique,
    details_PPE,
    produits_souscrits,
    dernier_invest,
    montant_fin_part
  ) => {
    function trouverCorrespondances(entetes, obs_ds_airtable) {
      // Initialiser la liste de correspondances
      let correspondances = [];

      for (let i = 0; i < entetes.length; i++) {
        // Vérifier si l'élément de entetes est présent dans obs_ds_airtable
        correspondances.push(
          obs_ds_airtable.includes(entetes[i]) ? entetes[i] : ''
        );
      }

      // Retourner la liste de correspondances
      return correspondances;
    }

    function testerListeVide(Liste) {
      // Vérifier si la liste est définie et n'est pas vide
      if (!Liste || Liste.length === 0) {
        console.error('La liste est invalide ou vide.');
        return;
      }

      // Initialiser une nouvelle liste pour stocker les résultats
      let resultats = [];

      // Parcourir chaque élément de la liste d'entrée
      for (let i = 0; i < Liste.length; i++) {
        // Tester si l'élément actuel est une chaîne de caractères vide
        let estVide = Liste[i] === '';

        // Inverser la valeur de estVide
        estVide = !estVide;

        // Ajouter le résultat à la liste des résultats
        resultats.push(estVide);
      }

      // Renvoyer la liste des résultats
      return resultats;
    }

    //US person
    setRadioButtonUSpers(personne_US);

    //PPE
    setRadioButtonPPE(personne_politique);

    //Détails PPE
    setTextInputPPE(details_PPE);

    //Produits déjà souscrits
    const pdts_sous = trouverCorrespondances(
      [
        'Actions cotées',
        'Actions non cotées',
        'Obligations cotées',
        'Obligations non cotées',
        "Titres non cotés via un fonds d'investissement (ex: FCPR, FCPI, FIP)",
        'Produits dérivés et complexes (options, certificats, warrants)',
        'Parts de sociétés civiles immobilières (SCI)',
        'OPCVM (SICAV, FCP, ...)',
        'Autres (Assurance vie, Comptes sur livret ...)',
        'Aucun',
      ],
      produits_souscrits
    );
    const check_pdts = testerListeVide(pdts_sous);

    setCheckPdts1(check_pdts[0]);
    setCheckPdts2(check_pdts[1]);
    setCheckPdts3(check_pdts[2]);
    setCheckPdts4(check_pdts[3]);
    setCheckPdts5(check_pdts[4]);
    setCheckPdts6(check_pdts[5]);
    setCheckPdts7(check_pdts[6]);
    setCheckPdts8(check_pdts[7]);
    setCheckPdts9(check_pdts[8]);
    setCheckPdts10(check_pdts[9]);

    //Dernier investissement
    setPicker_dernier_invest(dernier_invest);

    //Montant investi en financement participatif
    setPicker_montant_fin_part(montant_fin_part);
  };

  // Renvoie True si une information n'est pas renseignée
  const verif_completude = (info1, info2, info3) => {
    return (
      info1 === '' ||
      info2 === '' ||
      info3 === '' ||
      info1 === null ||
      info2 === null ||
      info3 === null
    );
  };

  // Renvoie faux si PPE1 vaut oui mais PPE2 n'est pas renseigné.
  const verifierPPE = (PPE1, PPE2) => {
    if (PPE1 === 'Oui' && (PPE2 === '' || PPE2 === null)) {
      return false;
    }
    return true;
  };
  const baseXanoDossInvestMajEcr2PATCH =
    BaseXanoApi.useDossInvestMajEcr2PATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const dossier_invest = (await BaseXanoApi.dossierInvestGET(Constants))
          ?.json;
        init_variables_scr2(
          dossier_invest?.US_pers,
          dossier_invest?.PPE1,
          dossier_invest?.PPE2,
          dossier_invest?.Pdts_investis,
          dossier_invest?.dernier_invest,
          dossier_invest?.montant_investi_fin_part
        );
        if (dossier_invest?.PPE1 === 'Oui' && dossier_invest?.PPE2 === '') {
          return;
        }
        if (array_vide(dossier_invest?.Pdts_investis)) {
          return;
        }
        const check = verif_completude(
          dossier_invest?.US_pers,
          dossier_invest?.montant_investi_fin_part,
          dossier_invest?.dernier_invest
        );
        setAffichage_bouton(!check);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: [
            { minWidth: Breakpoints.Mobile, value: dimensions.height },
            { minWidth: Breakpoints.Laptop, value: dimensions.height },
          ],
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: 'space-between',
            marginBottom: [
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* En-tête */}
        <View
          style={StyleSheet.applyWidth(
            { flexDirection: 'column' },
            dimensions.width
          )}
        >
          {/* entete */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '2%',
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '2%' },
                ],
                marginTop: '2%',
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth({ width: '80%' }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)[
                  'titre_pipe_test_connaissances'
                ].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)[
                    'titre_pipe_test_connaissances'
                  ].style,
                  dimensions.width
                )}
              >
                {'Test de connaissances'}
              </Text>
            </View>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('DossierInvestisseurScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      color={palettes.App.gris_fonce_flolend}
                      name={'Ionicons/exit-outline'}
                      size={18}
                    />
                  </Touchable>
                </View>
              )}
            </>
          </View>
          {/* frise */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App.gris_encart_int_flolend,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                ],
                justifyContent: 'space-evenly',
                paddingBottom: 5,
                paddingTop: 5,
              },
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['frise_test_titre_etape']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['frise_test_titre_etape'].style,
                dimensions.width
              )}
            >
              {'Etape 2/3 : Conformité et expérience'}
            </Text>
            <LinearProgress
              animationDuration={500}
              indeterminate={false}
              isAnimated={true}
              lineCap={'round'}
              showTrack={true}
              thickness={10}
              trackLineCap={'round'}
              color={palettes.App['Flolend 4']}
              style={StyleSheet.applyWidth(
                { alignSelf: 'center', width: '95%' },
                dimensions.width
              )}
              trackColor={palettes.App.gris_fonce_flolend}
              value={66.6}
            />
          </View>
        </View>
        {/* Connaissance et expérience */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'stretch' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Desktop, value: '20%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Desktop, value: '20%' },
              ],
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: 15 },
                { minWidth: Breakpoints.Laptop, value: 15 },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* US person */}
          <View>
            {/* Label 3 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                dimensions.width
              )}
            >
              {'Êtes-vous une US person ?'}
            </Text>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {
                '« US person » au sens de la réglementation dite FATCA (Foreign Account Tax Compliance Act) applicable depuis le 1er juillet 2014. Du point de vue de l’administration fiscale américaine, cette notion de US person est large puisqu’elle vise notamment toute personne physique qui est citoyenne ou résidente américaine ou ayant leur lieu de naissance aux Etats-Unis. Sont également concernées les sociétés créées aux Etats-Unis ou relevant du droit américain, ainsi que les structures patrimoniales (trust notamment) dirigées ou détenues à au moins 25% par des citoyens américains.'
              }
            </Text>

            <View
              style={StyleSheet.applyWidth({ marginTop: 4 }, dimensions.width)}
            >
              <RadioButtonGroup
                onValueChange={newRadioButtonGroupValue => {
                  try {
                    const valueOacRNcwb = newRadioButtonGroupValue;
                    setRadioButtonUSpers(valueOacRNcwb);
                    const sortie = valueOacRNcwb;
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const produits_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const infos_incompletes = verif_completude(
                      sortie,
                      picker_dernier_invest,
                      picker_montant_fin_part
                    );
                    if (
                      !produits_incomplets &&
                      !infos_incompletes &&
                      PPE_valide
                    ) {
                      setAffichage_bouton(true);
                    }
                    if (
                      produits_incomplets ||
                      infos_incompletes ||
                      !PPE_valide
                    ) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                direction={'horizontal'}
                style={StyleSheet.applyWidth(
                  {
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '25%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                      { minWidth: Breakpoints.Laptop, value: '60%' },
                    ],
                  },
                  dimensions.width
                )}
                value={radioButtonUSpers}
              >
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Oui'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Oui'}
                />
                {/* Radio Button Row 2 */}
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Non'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Non'}
                />
              </RadioButtonGroup>
            </View>
            {/* Divider 4 */}
            <Divider
              color={theme.colors.border.brand}
              {...GlobalStyles.DividerStyles(theme)['div simul'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.DividerStyles(theme)['div simul'].style,
                dimensions.width
              )}
            />
          </View>
          {/* Personne politiquement exposée */}
          <View>
            {/* Label 3 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                dimensions.width
              )}
            >
              {'Êtes-vous une personne politiquement exposée ?'}
            </Text>

            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {
                '« Personnes Politiquement Exposées » : s’entend des personnes françaises ou étrangères qui exercent ou ont cessé d’exercer depuis moins d’un an une fonction publique importante (politique, administrative, juridictionnelle, etc.) au niveau national dans leur pays ainsi que les membres directs de leur famille (conjoints, ascendants, descendants, concubins notoires, etc.) ou des personnes connues pour leur être étroitement associées.'
              }
            </Text>

            <View
              style={StyleSheet.applyWidth({ marginTop: 4 }, dimensions.width)}
            >
              <RadioButtonGroup
                onValueChange={newRadioButtonGroupValue => {
                  try {
                    const valueXnAWbpab = newRadioButtonGroupValue;
                    setRadioButtonPPE(valueXnAWbpab);
                    const sortie = valueXnAWbpab;
                    const PPE_valide = verifierPPE(sortie, textInputPPE);
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                }}
                direction={'horizontal'}
                style={StyleSheet.applyWidth(
                  {
                    marginRight: [
                      { minWidth: Breakpoints.Mobile, value: '25%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                      { minWidth: Breakpoints.Laptop, value: '60%' },
                    ],
                  },
                  dimensions.width
                )}
                value={radioButtonPPE}
              >
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Oui'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Oui'}
                />
                {/* Radio Button Row 2 */}
                <RadioButtonRow
                  color={theme.colors.branding.primary}
                  unselectedColor={theme.colors.branding.primary}
                  {...GlobalStyles.RadioButtonRowStyles(theme)[
                    'questionnaire_radio_button'
                  ].props}
                  label={'Non'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.RadioButtonRowStyles(theme)[
                      'questionnaire_radio_button'
                    ].style,
                    dimensions.width
                  )}
                  value={'Non'}
                />
              </RadioButtonGroup>
            </View>
            {/* entree */}
            <>
              {!(radioButtonPPE === 'Oui') ? null : (
                <View
                  {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                        .style,
                      { backgroundColor: palettes.App.gris_encart_int_flolend }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Text Input 2 */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newTextInput2Value => {
                      try {
                        const value5O1cxWpn = newTextInput2Value;
                        setTextInputPPE(value5O1cxWpn);
                        const nouveau_texte = value5O1cxWpn;
                        const PPE_valide = verifierPPE(
                          radioButtonPPE,
                          nouveau_texte
                        );
                        const pdts_incomplets = verif_completude(
                          radioButtonUSpers,
                          picker_dernier_invest,
                          picker_montant_fin_part
                        );
                        const items_incomplets = completude_pdts(
                          checkPdts1,
                          checkPdts2,
                          checkPdts3,
                          checkPdts4,
                          checkPdts5,
                          checkPdts6,
                          checkPdts7,
                          checkPdts8,
                          checkPdts9,
                          checkPdts10
                        );
                        if (
                          !pdts_incomplets &&
                          !items_incomplets &&
                          PPE_valide
                        ) {
                          setAffichage_bouton(true);
                        }
                        if (
                          pdts_incomplets ||
                          items_incomplets ||
                          !PPE_valide
                        ) {
                          setAffichage_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    onChangeTextDelayed={newTextInput2Value => {
                      try {
                        if (
                          newTextInput2Value === '' &&
                          radioButtonPPE === 'Oui'
                        ) {
                          return;
                        }
                        const check1 = completude_pdts(
                          checkPdts1,
                          checkPdts2,
                          checkPdts3,
                          checkPdts4,
                          checkPdts5,
                          checkPdts6,
                          checkPdts7,
                          checkPdts8,
                          checkPdts9,
                          checkPdts10
                        );
                        const check2 = verif_completude(
                          picker_dernier_invest,
                          picker_montant_fin_part,
                          radioButtonUSpers
                        );
                        if (!check1 && !check2) {
                          setAffichage_bouton(true);
                        }
                        if (check1 || check2) {
                          setAffichage_bouton(false);
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    {...GlobalStyles.TextInputStyles(theme)[
                      'encart_infos_client'
                    ].props}
                    placeholder={'Précisez'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                        .style,
                      dimensions.width
                    )}
                    value={textInputPPE}
                  />
                </View>
              )}
            </>
            {/* Divider 7 */}
            <Divider
              color={theme.colors.border.brand}
              {...GlobalStyles.DividerStyles(theme)['div simul'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.DividerStyles(theme)['div simul'].style,
                dimensions.width
              )}
            />
          </View>
          {/* Produits déjà souscrits */}
          <View>
            {/* Label 3 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                dimensions.width
              )}
            >
              {'Dans quels produits financiers avez-vous déjà investi ?'}
            </Text>
            <CheckboxRow
              onPress={newCheckboxRowValue => {
                const checkboxRowValue = newCheckboxRowValue;
                try {
                  const valueE7HY5FRY = !checkPdts1;
                  setCheckPdts1(valueE7HY5FRY);
                  const sortie = valueE7HY5FRY;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    const items_incomplets = completude_pdts(
                      sortie,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Actions cotées'}
              status={checkPdts1}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 2 */}
            <CheckboxRow
              onPress={newCheckboxRow2Value => {
                try {
                  const valueqCea8suI = !checkPdts2;
                  setCheckPdts2(valueqCea8suI);
                  const sortie = valueqCea8suI;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      sortie,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Actions non cotées'}
              status={checkPdts2}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 3 */}
            <CheckboxRow
              onPress={newCheckboxRow3Value => {
                try {
                  const valueCdImTbjH = !checkPdts3;
                  setCheckPdts3(valueCdImTbjH);
                  const sortie = valueCdImTbjH;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      sortie,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Obligations cotées'}
              status={checkPdts3}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 4 */}
            <CheckboxRow
              onPress={newCheckboxRow4Value => {
                try {
                  const valueNfZvaE0F = !checkPdts4;
                  setCheckPdts4(valueNfZvaE0F);
                  const sortie = valueNfZvaE0F;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      sortie,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Obligations non cotées'}
              status={checkPdts4}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 5 */}
            <CheckboxRow
              onPress={newCheckboxRow5Value => {
                try {
                  const valueeCJQuUbF = !checkPdts5;
                  setCheckPdts5(valueeCJQuUbF);
                  const sortie = valueeCJQuUbF;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      sortie,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={
                "Titres non cotés via un fonds d'investissement (ex: FCPR, FCPI, FIP)"
              }
              status={checkPdts5}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 6 */}
            <CheckboxRow
              onPress={newCheckboxRow6Value => {
                try {
                  const valueJaxFDM8e = !checkPdts6;
                  setCheckPdts6(valueJaxFDM8e);
                  const sortie = valueJaxFDM8e;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      sortie,
                      checkPdts7,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!items_incomplets && !pdts_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={
                'Produits dérivés et complexes (options, certificats, warrants)'
              }
              status={checkPdts6}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 7 */}
            <CheckboxRow
              onPress={newCheckboxRow7Value => {
                try {
                  const valuesTqBLqxF = !checkPdts7;
                  setCheckPdts7(valuesTqBLqxF);
                  const sortie = valuesTqBLqxF;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      sortie,
                      checkPdts8,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Parts de sociétés civiles immobilières (SCI)'}
              status={checkPdts7}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 8 */}
            <CheckboxRow
              onPress={newCheckboxRow8Value => {
                try {
                  const valuepqc6Q0aS = !checkPdts8;
                  setCheckPdts8(valuepqc6Q0aS);
                  const sortie = valuepqc6Q0aS;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      sortie,
                      checkPdts9,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'OPCVM (SICAV, FCP, ...)'}
              status={checkPdts8}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 9 */}
            <CheckboxRow
              onPress={newCheckboxRow9Value => {
                try {
                  const valueS5YbVK6k = !checkPdts9;
                  setCheckPdts9(valueS5YbVK6k);
                  const sortie = valueS5YbVK6k;
                  if (checkPdts10) {
                    setCheckPdts10(false);
                    setPicker_dernier_invest('');
                    setPicker_montant_fin_part('');
                    setAffichage_bouton(false);
                  } else {
                    const PPE_valide = verifierPPE(
                      radioButtonPPE,
                      textInputPPE
                    );
                    const pdts_incomplets = completude_pdts(
                      checkPdts1,
                      checkPdts2,
                      checkPdts3,
                      checkPdts4,
                      checkPdts5,
                      checkPdts6,
                      checkPdts7,
                      checkPdts8,
                      sortie,
                      checkPdts10
                    );
                    const items_incomplets = verif_completude(
                      picker_dernier_invest,
                      picker_montant_fin_part,
                      radioButtonUSpers
                    );
                    if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                      setAffichage_bouton(true);
                    }
                    if (pdts_incomplets || items_incomplets || !PPE_valide) {
                      setAffichage_bouton(false);
                    }
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Autre (Assurance vie, Comptes sur livret ...)'}
              status={checkPdts9}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Checkbox Row 10 */}
            <CheckboxRow
              onPress={newCheckboxRow10Value => {
                try {
                  const valueof6jFGqJ = !checkPdts10;
                  setCheckPdts10(valueof6jFGqJ);
                  const sortie = valueof6jFGqJ;
                  if (!sortie) {
                    setAffichage_bouton(false);
                  }
                  if (!sortie) {
                    return;
                  }
                  setCheckPdts1(false);
                  setCheckPdts2(false);
                  setCheckPdts3(false);
                  setCheckPdts4(false);
                  setCheckPdts5(false);
                  setCheckPdts6(false);
                  setCheckPdts7(false);
                  setCheckPdts8(false);
                  setCheckPdts9(false);

                  const valuebVAOVhCp =
                    options_dernier_invest[options_dernier_invest.length - 1]
                      ?.value;
                  setPicker_dernier_invest(valuebVAOVhCp);
                  const var_pick_inv = valuebVAOVhCp;
                  const value9G0JNAa5 = options_fin_part[0]?.value;
                  setPicker_montant_fin_part(value9G0JNAa5);
                  const var_pick_fin = value9G0JNAa5;
                  const PPE_valide = verifierPPE(radioButtonPPE, textInputPPE);
                  const check = verif_completude(
                    radioButtonUSpers,
                    var_pick_inv,
                    var_pick_fin
                  );
                  if (!check && PPE_valide) {
                    setAffichage_bouton(true);
                  }
                  if (check || !PPE_valide) {
                    setAffichage_bouton(false);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.CheckboxRowStyles(theme)[
                'question_test_connaissances'
              ].props}
              label={'Aucun'}
              status={checkPdts10}
              style={StyleSheet.applyWidth(
                GlobalStyles.CheckboxRowStyles(theme)[
                  'question_test_connaissances'
                ].style,
                dimensions.width
              )}
            />
            {/* Divider 5 */}
            <>
              {checkPdts10 ? null : (
                <Divider
                  color={theme.colors.border.brand}
                  {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.DividerStyles(theme)['div simul'].style,
                    dimensions.width
                  )}
                />
              )}
            </>
          </View>
          {/* Dernier investissement */}
          <>
            {checkPdts10 ? null : (
              <View>
                {/* Label 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                    dimensions.width
                  )}
                >
                  {'À quand remonte votre dernier investissement ?'}
                </Text>
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.brand}
                  dropDownBorderColor={theme.colors.border.brand}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      const valuenY6XuTDY = newPickerValue;
                      setPicker_dernier_invest(valuenY6XuTDY);
                      const sortie = valuenY6XuTDY;
                      const PPE_valide = verifierPPE(
                        radioButtonPPE,
                        textInputPPE
                      );
                      const pdts_incomplets = completude_pdts(
                        checkPdts1,
                        checkPdts2,
                        checkPdts3,
                        checkPdts4,
                        checkPdts5,
                        checkPdts6,
                        checkPdts7,
                        checkPdts8,
                        checkPdts9,
                        checkPdts10
                      );
                      const items_incomplets = verif_completude(
                        sortie,
                        picker_montant_fin_part,
                        radioButtonUSpers
                      );
                      if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                        setAffichage_bouton(true);
                      }
                      if (pdts_incomplets || items_incomplets || !PPE_valide) {
                        setAffichage_bouton(false);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)[
                    'picker_test_connaissances'
                  ].props}
                  options={
                    checkPdts10
                      ? options_dernier_invest
                      : options_dernier_invest_sans_q10
                  }
                  placeholder={'Sélectionnez une option'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)[
                      'picker_test_connaissances'
                    ].style,
                    dimensions.width
                  )}
                  value={picker_dernier_invest}
                />
                {/* Divider 9 */}
                <>
                  {checkPdts10 ? null : (
                    <Divider
                      color={theme.colors.border.brand}
                      {...GlobalStyles.DividerStyles(theme)['div simul'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.DividerStyles(theme)['div simul'].style,
                        dimensions.width
                      )}
                    />
                  )}
                </>
              </View>
            )}
          </>
          {/* Montant investi */}
          <>
            {checkPdts10 ? null : (
              <View>
                {/* Label 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['titre_2_generique'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['titre_2_generique'].style,
                    dimensions.width
                  )}
                >
                  {
                    'Quel montant avez-vous déjà investi dans des projets de financement participatif ?'
                  }
                </Text>
                <Picker
                  autoDismissKeyboard={true}
                  dropDownBackgroundColor={theme.colors.background.brand}
                  dropDownBorderColor={theme.colors.border.brand}
                  dropDownBorderRadius={8}
                  dropDownBorderWidth={1}
                  dropDownTextColor={theme.colors.text.strong}
                  iconSize={24}
                  leftIconMode={'inset'}
                  mode={'native'}
                  onValueChange={newPickerValue => {
                    try {
                      const valuep3eh98Rv = newPickerValue;
                      setPicker_montant_fin_part(valuep3eh98Rv);
                      const sortie = valuep3eh98Rv;
                      const PPE_valide = verifierPPE(
                        radioButtonPPE,
                        textInputPPE
                      );
                      const pdts_incomplets = completude_pdts(
                        checkPdts1,
                        checkPdts2,
                        checkPdts3,
                        checkPdts4,
                        checkPdts5,
                        checkPdts6,
                        checkPdts7,
                        checkPdts8,
                        checkPdts9,
                        checkPdts10
                      );
                      const items_incomplets = verif_completude(
                        sortie,
                        picker_dernier_invest,
                        radioButtonUSpers
                      );
                      if (!pdts_incomplets && !items_incomplets && PPE_valide) {
                        setAffichage_bouton(true);
                      }
                      if (pdts_incomplets || items_incomplets || !PPE_valide) {
                        setAffichage_bouton(false);
                      }
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  selectedIconColor={theme.colors.text.strong}
                  selectedIconName={'Feather/check'}
                  selectedIconSize={20}
                  type={'solid'}
                  {...GlobalStyles.PickerStyles(theme)[
                    'picker_test_connaissances'
                  ].props}
                  options={options_fin_part}
                  placeholder={'Sélectionnez une option'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.PickerStyles(theme)[
                      'picker_test_connaissances'
                    ].style,
                    dimensions.width
                  )}
                  value={picker_montant_fin_part}
                />
              </View>
            )}
          </>
        </View>

        <LinearGradient
          endX={100}
          endY={100}
          startX={0}
          startY={0}
          {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton'].props}
          color1={
            (affichage_bouton
              ? palettes.App['Flolend 1']
              : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 1']
          }
          color2={
            (affichage_bouton
              ? palettes.App['Flolend 2']
              : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 2']
          }
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.LinearGradientStyles(theme)['gradient_bouton'].style,
              {
                alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                flex: null,
                height: [
                  { minWidth: Breakpoints.Laptop, value: 60 },
                  { minWidth: Breakpoints.Tablet, value: null },
                  { minWidth: Breakpoints.Desktop, value: 65 },
                  { minWidth: Breakpoints.BigScreen, value: 70 },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'center',
                },
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: '2%',
                overflow: { minWidth: Breakpoints.Laptop, value: 'hidden' },
                width: { minWidth: Breakpoints.Tablet, value: '50%' },
              }
            ),
            dimensions.width
          )}
        >
          {/* Bouton étape suivante */}
          <Button
            accessible={true}
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  if (!affichage_bouton) {
                    return;
                  }
                  const retour_api = (
                    await baseXanoDossInvestMajEcr2PATCH.mutateAsync({
                      US_pers: radioButtonUSpers,
                      check_pdts1: checkPdts1,
                      check_pdts10: checkPdts10,
                      check_pdts2: checkPdts2,
                      check_pdts3: checkPdts3,
                      check_pdts4: checkPdts4,
                      check_pdts5: checkPdts5,
                      check_pdts6: checkPdts6,
                      check_pdts7: checkPdts7,
                      check_pdts8: checkPdts8,
                      check_pdts9: checkPdts9,
                      dernier_invest: picker_dernier_invest,
                      montant_fin_part: picker_montant_fin_part,
                      ppe1: radioButtonPPE,
                      ppe2: textInputPPE,
                    })
                  )?.json;
                  navigation.navigate('TestDeConnaissances3Screen');
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
            {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                {
                  borderRadius: {
                    minWidth: Breakpoints.BigScreen,
                    value: null,
                  },
                  fontSize: [
                    { minWidth: Breakpoints.Desktop, value: 20 },
                    { minWidth: Breakpoints.BigScreen, value: 24 },
                  ],
                  height: [
                    { minWidth: Breakpoints.Desktop, value: 60 },
                    { minWidth: Breakpoints.BigScreen, value: 70 },
                  ],
                }
              ),
              dimensions.width
            )}
            title={'Etape suivante'}
          />
        </LinearGradient>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(TestDeConnaissances2Screen);
