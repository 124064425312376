// Convertit une date ISO en une date au format JJ mois raccourci AAAA
const ConvertionDate = dateISO => {
  const date = new Date(dateISO);

  const jour = date.getDate(); // Jour du mois
  const mois = date.getMonth(); // Mois (0-11)
  const annee = date.getFullYear(); // Année complète

  // Tableau des mois en français
  const moisFrancais = [
    'janv.',
    'févr.',
    'mars',
    'avr.',
    'mai',
    'juin',
    'juil.',
    'août',
    'sept.',
    'oct.',
    'nov.',
    'déc.',
  ];

  return `${jour} ${moisFrancais[mois]} ${annee}`;
};

export default ConvertionDate;
