import React from 'react';
import {
  Divider,
  Icon,
  IconButton,
  Pressable,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import ConvertionDate from '../global-functions/ConvertionDate';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const NavbarBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  // Encadre la navigation vers l'écran Actualité
  const nav_actu = type => {
    if (typeof type !== 'string') {
      return false;
    }

    const typesValid = ['Nouveau contenu', 'Annonce'];
    return typesValid.includes(type);
  };

  // Encadre la navigation vers le web
  const nav_ressource = type => {
    if (typeof type !== 'string') {
      return false;
    }

    const typesValid = ['Nouvelle ressource', 'Bienvenue'];
    return typesValid.includes(type);
  };

  // Encadre la navigation vers l'Espace personnel
  const navigation_espace_perso = type => {
    if (typeof type !== 'string') {
      return false;
    }

    const typesValid = ['Versement souscription', 'Ma souscription'];
    return typesValid.includes(type);
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
          top: { minWidth: Breakpoints.Laptop, value: 0 },
          width: { minWidth: Breakpoints.Laptop, value: '100%' },
          zIndex: { minWidth: Breakpoints.Laptop, value: 99 },
        },
        dimensions.width
      )}
    >
      {/* top header */}
      <BlurView
        {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
        intensity={75}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
            {
              alignItems: [
                { minWidth: Breakpoints.Mobile, value: 'center' },
                { minWidth: Breakpoints.Laptop, value: 'stretch' },
              ],
              borderBottomWidth: { minWidth: Breakpoints.Laptop, value: 1 },
              borderColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.gris_fonce_flolend,
              },
              flexDirection: 'row',
              height: [
                {
                  minWidth: Breakpoints.Desktop,
                  value: Constants['hauteur_navbar_desktop'],
                },
                {
                  minWidth: Breakpoints.BigScreen,
                  value: Constants['hauteur_navbar_big_screen'],
                },
                {
                  minWidth: Breakpoints.Laptop,
                  value: Constants['hauteur_navbar_laptop'],
                },
              ],
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-between',
              },
              position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
              top: { minWidth: Breakpoints.Laptop, value: 0 },
              width: { minWidth: Breakpoints.Laptop, value: '100%' },
              zIndex: { minWidth: Breakpoints.Laptop, value: 50 },
            }
          ),
          dimensions.width
        )}
        tint={'dark'}
      >
        {/* Vue gauche */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-between',
              },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
              width: { minWidth: Breakpoints.Laptop, value: '45%' },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                height: [
                  { minWidth: Breakpoints.Mobile, value: '80%' },
                  { minWidth: Breakpoints.Laptop, value: '100%' },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                width: [
                  { minWidth: Breakpoints.Mobile, value: 50 },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
              },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    await WebBrowser.openBrowserAsync(
                      'https://www.flolend.com'
                    );
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Laptop, value: '80%' } },
                dimensions.width
              )}
            >
              {/* logo */}
              <Image
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                resizeMode={'center'}
                source={imageSource(Images['LogoFlolendMargin'])}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { height: { minWidth: Breakpoints.Laptop, value: '100%' } }
                  ),
                  dimensions.width
                )}
              />
            </Pressable>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                width: { minWidth: Breakpoints.Laptop, value: '90%' },
              },
              dimensions.width
            )}
          >
            {/* Projets */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'EventailProjetsScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Montserrat_600SemiBold',
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 26 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Les projets'}
                </Text>
              </View>
            </Pressable>
            {/* Mon espace */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'EspacePersonnelScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Montserrat_600SemiBold',
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 26 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Mon espace'}
                </Text>
              </View>
            </Pressable>
            {/* Assistance */}
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'AssistanceScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: { minWidth: Breakpoints.Laptop, value: '100%' } },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '100%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.blanc_flolend,
                        },
                        fontFamily: {
                          minWidth: Breakpoints.Laptop,
                          value: 'Montserrat_600SemiBold',
                        },
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 26 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Assistance'}
                </Text>
              </View>
            </Pressable>
          </View>
        </View>
        {/* Vue droite */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-between',
              },
              marginRight: { minWidth: Breakpoints.Laptop, value: '2%' },
              width: [
                { minWidth: Breakpoints.Laptop, value: '12%' },
                { minWidth: Breakpoints.Desktop, value: '9%' },
                { minWidth: Breakpoints.BigScreen, value: '8%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Fetch client */}
          <BaseXanoApi.FetchAuthGET>
            {({ loading, error, data, refetchAuth }) => {
              const fetchClientData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Notifications */}
                  <Touchable
                    onPress={() => {
                      try {
                        setGlobalVariableValue({
                          key: 'display_notifs',
                          value: true,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {/* Vue cloche */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          height: 48,
                          justifyContent: 'center',
                          width: 48,
                        },
                        dimensions.width
                      )}
                    >
                      <Icon
                        color={palettes.App.gris_fonce_flolend}
                        name={'Ionicons/notifications'}
                        size={
                          dimensions.width >= Breakpoints.Laptop &&
                          !(dimensions.width >= Breakpoints.Desktop)
                            ? 30
                            : dimensions.width >= Breakpoints.Desktop &&
                              !(dimensions.width >= Breakpoints.BigScreen)
                            ? 35
                            : 45
                        }
                      />
                      {/* Notifs à lire */}
                      <>
                        {!(
                          fetchClientData?.statut_notif === 'A_lire'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['Flolend 4'],
                                borderBottomWidth: 3,
                                borderColor: palettes.App['Custom #ffffff'],
                                borderLeftWidth: 3,
                                borderRadius: 7,
                                borderRightWidth: 3,
                                borderTopWidth: 3,
                                height: [
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 25,
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value:
                                      dimensions.width >= Breakpoints.Laptop &&
                                      !(dimensions.width >= Breakpoints.Desktop)
                                        ? 14
                                        : dimensions.width >=
                                          Breakpoints.BigScreen
                                        ? 20
                                        : 18,
                                  },
                                ],
                                position: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'absolute',
                                },
                                right: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '25%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '18%',
                                  },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: '12%',
                                  },
                                ],
                                top: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '25%',
                                  },
                                  {
                                    minWidth: Breakpoints.Desktop,
                                    value: '18%',
                                  },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: '12%',
                                  },
                                ],
                                width: [
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 25,
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value:
                                      dimensions.width >= Breakpoints.Laptop &&
                                      !(dimensions.width >= Breakpoints.Desktop)
                                        ? 14
                                        : dimensions.width >=
                                          Breakpoints.BigScreen
                                        ? 20
                                        : 18,
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                    </View>
                  </Touchable>
                </>
              );
            }}
          </BaseXanoApi.FetchAuthGET>
          {/* Profil */}
          <Touchable
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'display_profil',
                  value: !Constants['display_notifs'],
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              { height: { minWidth: Breakpoints.Laptop, value: '80%' } },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  height: { minWidth: Breakpoints.Laptop, value: '100%' },
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'center',
                  },
                },
                dimensions.width
              )}
            >
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: 'rgba(0, 0, 0, 0)',
                    },
                    borderColor: palettes.App['Flolend 4'],
                    borderRadius: 45,
                    borderWidth: 1,
                    justifyContent: 'center',
                    minHeight: { minWidth: Breakpoints.BigScreen, value: 51 },
                    overflow: 'hidden',
                    padding: { minWidth: Breakpoints.Laptop, value: 2 },
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Flolend 2']}
                  name={'MaterialIcons/person'}
                  size={
                    dimensions.width >= Breakpoints.Laptop &&
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 40
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 50
                      : 45
                  }
                  style={StyleSheet.applyWidth(
                    { backgroundColor: 'rgba(0, 0, 0, 0)' },
                    dimensions.width
                  )}
                />
              </Surface>
            </View>
          </Touchable>
        </View>
      </BlurView>
      {/* notification banner */}
      <>
        {!Constants['display_notifs'] ? null : (
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={3}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                {
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.background_flolend,
                  },
                  height: {
                    minWidth: Breakpoints.Laptop,
                    value: dimensions.height,
                  },
                  position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
                  right: { minWidth: Breakpoints.Laptop, value: 0 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '40%' },
                    { minWidth: Breakpoints.Desktop, value: '30%' },
                  ],
                  zIndex: { minWidth: Breakpoints.Laptop, value: 70 },
                }
              ),
              dimensions.width
            )}
          >
            {/* Header */}
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['header_classique'].style,
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '5%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'space-between',
                    },
                    marginBottom: 10,
                    paddingRight: { minWidth: Breakpoints.Laptop, value: 20 },
                  }
                ),
                dimensions.width
              )}
            >
              {/* titre_page */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App['Flolend 5'],
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.blanc_flolend,
                      },
                    ],
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 16 },
                      { minWidth: Breakpoints.Laptop, value: 16 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                      { minWidth: Breakpoints.BigScreen, value: 24 },
                    ],
                    marginLeft: 10,
                  },
                  dimensions.width
                )}
              >
                {'Notifications'}
              </Text>
              <IconButton
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'display_notifs',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                color={palettes.App.gris_fonce_flolend}
                icon={'Entypo/cross'}
                size={
                  (dimensions.width >= Breakpoints.BigScreen
                    ? 50
                    : dimensions.width >= Breakpoints.Desktop
                    ? 45
                    : 40) ?? 40
                }
              />
            </View>

            <SimpleStyleScrollView
              bounces={true}
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            >
              {/* Fetch notifications */}
              <BaseXanoApi.FetchGetNotifsClientGET>
                {({ loading, error, data, refetchGetNotifsClient }) => {
                  const fetchNotificationsData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <SimpleStyleFlatList
                      data={fetchNotificationsData}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'aVIibg3Q'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            <Touchable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    if (
                                      listData?.type_notif === 'Nouveau projet'
                                    ) {
                                      if (navigation.canGoBack()) {
                                        navigation.popToTop();
                                      }
                                      navigation.replace('ProjetScreen', {
                                        id_transfere:
                                          listData?.liaison_projets &&
                                          (listData?.liaison_projets)[0].projets
                                            .id,
                                      });
                                    }
                                    if (nav_actu(listData?.type_notif)) {
                                      if (navigation.canGoBack()) {
                                        navigation.popToTop();
                                      }
                                      navigation.replace('ActualiteScreen', {
                                        id_actu_airtable:
                                          listData?.liaison_actualites,
                                      });
                                    }
                                    if (
                                      navigation_espace_perso(
                                        listData?.type_notif
                                      )
                                    ) {
                                      if (navigation.canGoBack()) {
                                        navigation.popToTop();
                                      }
                                      navigation.replace('BottomTabNavigator', {
                                        screen: 'EspacePersonnelScreen',
                                      });
                                    }
                                    if (
                                      listData?.type_notif === 'Dossier invest'
                                    ) {
                                      if (navigation.canGoBack()) {
                                        navigation.popToTop();
                                      }
                                      navigation.replace(
                                        'DossierInvestisseurScreen'
                                      );
                                    }
                                    if (nav_ressource(listData?.type_notif)) {
                                      await WebBrowser.openBrowserAsync(
                                        `${listData?.lien_ressource}`
                                      );
                                    }
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                            >
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={3}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      alignItems: 'center',
                                      backgroundColor: [
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value:
                                            palettes.App
                                              .flolend_gris_nuance_encart,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: theme.colors.background.brand,
                                        },
                                      ],
                                      borderRadius: 12,
                                      flexDirection: 'row',
                                      marginLeft: 20,
                                      marginRight: 20,
                                      minHeight: null,
                                      paddingBottom: 20,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                      paddingTop: 20,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  source={imageSource(
                                    `${listData?.image_couv?.url}`
                                  )}
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderRadius: 8,
                                      height: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 80,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 100,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 64,
                                        },
                                      ],
                                      width: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 80,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 64,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 100,
                                        },
                                      ],
                                    },
                                    dimensions.width
                                  )}
                                />
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1, marginLeft: 20 },
                                    dimensions.width
                                  )}
                                >
                                  {/* Title */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    ellipsizeMode={'tail'}
                                    numberOfLines={2}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: palettes.App.blanc_flolend,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: palettes.App['Flolend 5'],
                                          },
                                        ],
                                        fontFamily: 'Montserrat_500Medium',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 24,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.Titre}
                                  </Text>
                                  {/* View 3 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: '85%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          width: '55%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* type  */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value:
                                                  palettes.App.blanc_flolend,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value:
                                                  palettes.App['Flolend 5'],
                                              },
                                            ],
                                            fontFamily: 'PTSans_400Regular',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 14,
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 14,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 22,
                                              },
                                            ],
                                            marginTop: 4,
                                            opacity: 0.6,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.type_notif}
                                        {'\n'}
                                        {ConvertionDate(
                                          listData?.date_publication
                                        )}
                                      </Text>
                                    </View>
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* point */}
                                      <>
                                        {!listData?.nouveaute ? null : (
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value:
                                                      palettes.App
                                                        .blanc_flolend,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value:
                                                      palettes.App['Flolend 5'],
                                                  },
                                                ],
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 22,
                                                  },
                                                ],
                                                marginTop: 4,
                                                opacity: 0.6,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {' • '}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                    {/* View 3 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* statut */}
                                      <>
                                        {!listData?.nouveaute ? null : (
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            style={StyleSheet.applyWidth(
                                              {
                                                color:
                                                  palettes.App['Flolend 4'],
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 22,
                                                  },
                                                ],
                                                marginTop: 4,
                                                opacity: 1,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {'Nouveau'}
                                          </Text>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </View>
                              </Surface>
                            </Touchable>
                            <Divider
                              {...GlobalStyles.DividerStyles(theme)['Divider']
                                .props}
                              color={palettes.App.gris_fonce_flolend}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.DividerStyles(theme)['Divider']
                                    .style,
                                  {
                                    marginBottom: 10,
                                    marginLeft: 40,
                                    marginRight: 40,
                                    marginTop: 10,
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  );
                }}
              </BaseXanoApi.FetchGetNotifsClientGET>
            </SimpleStyleScrollView>
          </Surface>
        )}
      </>
      {/* profile banner */}
      <>
        {!Constants['display_profil'] ? null : (
          <Surface
            {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
            elevation={3}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                {
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.background_flolend,
                  },
                  borderBottomLeftRadius: {
                    minWidth: Breakpoints.Laptop,
                    value: 24,
                  },
                  borderBottomRightRadius: {
                    minWidth: Breakpoints.Laptop,
                    value: 24,
                  },
                  borderTopLeftRadius: {
                    minWidth: Breakpoints.Laptop,
                    value: 24,
                  },
                  paddingBottom: { minWidth: Breakpoints.Laptop, value: '2%' },
                  paddingTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                  position: { minWidth: Breakpoints.Laptop, value: 'absolute' },
                  right: { minWidth: Breakpoints.Laptop, value: 0 },
                  width: [
                    { minWidth: Breakpoints.Laptop, value: '30%' },
                    { minWidth: Breakpoints.Desktop, value: '20%' },
                  ],
                  zIndex: { minWidth: Breakpoints.Laptop, value: 80 },
                }
              ),
              dimensions.width
            )}
          >
            {/* Header 2 */}
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['header_classique'].style,
                  {
                    height: { minWidth: Breakpoints.Laptop, value: '5%' },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: null },
                    position: {
                      minWidth: Breakpoints.Laptop,
                      value: 'absolute',
                    },
                    right: { minWidth: Breakpoints.Laptop, value: '1%' },
                    top: { minWidth: Breakpoints.Laptop, value: '5%' },
                    zIndex: { minWidth: Breakpoints.Laptop, value: 99 },
                  }
                ),
                dimensions.width
              )}
            >
              <IconButton
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'display_profil',
                      value: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                color={palettes.App.gris_fonce_flolend}
                icon={'Entypo/cross'}
                size={40}
              />
            </View>
            {/* Nom et portefeuille */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              {/* Fetch client */}
              <BaseXanoApi.FetchAuthGET>
                {({ loading, error, data, refetchAuth }) => {
                  const fetchClientData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      <Text
                        accessible={true}
                        selectable={false}
                        style={StyleSheet.applyWidth(
                          {
                            color: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: palettes.App['Custom #ffffff'],
                              },
                              {
                                minWidth: Breakpoints.Laptop,
                                value: palettes.App.blanc_flolend,
                              },
                            ],
                            fontFamily: 'Montserrat_700Bold',
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 20 },
                              { minWidth: Breakpoints.Mobile, value: 21 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            marginTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        {fetchClientData?.Prenom} {fetchClientData?.Nom}
                      </Text>
                      {/* Encart portefeuille */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 3,
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          color={palettes.App['Flolend 2']}
                          name={'Entypo/wallet'}
                          size={30}
                        />
                        <Text
                          accessible={true}
                          selectable={false}
                          style={StyleSheet.applyWidth(
                            {
                              color: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: palettes.App['Custom #ffffff'],
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: palettes.App.blanc_flolend,
                                },
                              ],
                              fontFamily: 'Montserrat_700Bold',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 15 },
                                { minWidth: Breakpoints.Laptop, value: 14 },
                                { minWidth: Breakpoints.Desktop, value: 12 },
                                { minWidth: Breakpoints.BigScreen, value: 16 },
                              ],
                              paddingLeft: 8,
                              paddingRight: 5,
                              textTransform: 'capitalize',
                            },
                            dimensions.width
                          )}
                        >
                          {format_nombre(
                            fetchClientData?.verification_email
                              ? conversion_solde_wallet(
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.balance
                                )
                              : 0,
                            2
                          )}
                          {' €'}
                        </Text>
                      </View>
                      {/* Top options */}
                      <Surface
                        elevation={0}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: 'rgba(0, 0, 0, 0)',
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            justifyContent: 'center',
                            marginLeft: 16,
                            marginRight: 16,
                            marginTop: 16,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Informations compte */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('InformationsDuCompteScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.gris_fonce_flolend,
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors.border.brand,
                                  },
                                ],
                                borderTopLeftRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderTopRightRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: 12,
                                paddingLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingRight: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'AntDesign/profile'}
                                size={30}
                              />
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: palettes.App.blanc_flolend,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 5'],
                                      },
                                    ],
                                    fontFamily: 'Montserrat_500Medium',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 16,
                                      },
                                    ],
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 10,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Informations du compte'}
                              </Text>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'Entypo/chevron-right'}
                              size={30}
                            />
                          </View>
                        </Touchable>
                        {/* Mon livret Flolend */}
                        <Touchable
                          onPress={() => {
                            try {
                              if (fetchClientData?.verification_email) {
                                navigation.navigate('MonLivretFlolendScreen');
                              }
                              if (!fetchClientData?.verification_email) {
                                navigation.navigate('Inscription3Screen');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderBottomLeftRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderBottomRightRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderBottomWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: palettes.App.gris_fonce_flolend,
                                },
                                borderLeftWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                borderRightWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: 12,
                                paddingLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingRight: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'Ionicons/wallet-sharp'}
                                size={30}
                              />
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: palettes.App.blanc_flolend,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 5'],
                                      },
                                    ],
                                    fontFamily: 'Montserrat_500Medium',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 16,
                                      },
                                    ],
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 10,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Mon livret Flolend'}
                              </Text>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'Entypo/chevron-right'}
                              size={30}
                            />
                          </View>
                        </Touchable>
                      </Surface>
                      {/* Bottom option */}
                      <Surface
                        elevation={0}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: {
                              minWidth: Breakpoints.Laptop,
                              value: 'rgba(0, 0, 0, 0)',
                            },
                            borderRadius: [
                              { minWidth: Breakpoints.Laptop, value: 15 },
                              { minWidth: Breakpoints.Mobile, value: 10 },
                            ],
                            justifyContent: 'center',
                            marginLeft: 16,
                            marginRight: 16,
                            marginTop: 25,
                            minHeight: 40,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Mon dossier investisseur */}
                        <Touchable
                          onPress={() => {
                            try {
                              if (fetchClientData?.verification_email) {
                                navigation.navigate(
                                  'DossierInvestisseurScreen'
                                );
                              }
                              if (!fetchClientData?.verification_email) {
                                navigation.navigate('Inscription3Screen');
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderColor: [
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: palettes.App.gris_fonce_flolend,
                                  },
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: theme.colors.border.brand,
                                  },
                                ],
                                borderTopLeftRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderTopRightRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: 12,
                                paddingLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingRight: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'MaterialCommunityIcons/ticket-account'}
                                size={30}
                              />
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: palettes.App.blanc_flolend,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 5'],
                                      },
                                    ],
                                    fontFamily: 'Montserrat_500Medium',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 16,
                                      },
                                    ],
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 10,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Mon dossier investisseur'}
                              </Text>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'Entypo/chevron-right'}
                              size={30}
                            />
                          </View>
                        </Touchable>
                        {/* Mot de passe */}
                        <Touchable
                          onPress={() => {
                            try {
                              navigation.navigate('ChangementMotDePasseScreen');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderBottomLeftRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderBottomRightRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 15,
                                },
                                borderBottomWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                borderColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value: palettes.App.gris_fonce_flolend,
                                },
                                borderLeftWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                borderRightWidth: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 1,
                                },
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingBottom: 12,
                                paddingLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingRight: [
                                  { minWidth: Breakpoints.Mobile, value: 16 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                paddingTop: 12,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'center', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              <Icon
                                color={palettes.App['Flolend 2']}
                                name={'Entypo/key'}
                                size={30}
                              />
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: palettes.App.blanc_flolend,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App['Flolend 5'],
                                      },
                                    ],
                                    fontFamily: 'Montserrat_500Medium',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 16,
                                      },
                                    ],
                                    paddingLeft: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 10,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Mot de passe'}
                              </Text>
                            </View>
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'Entypo/chevron-right'}
                              size={30}
                            />
                          </View>
                        </Touchable>
                      </Surface>
                    </>
                  );
                }}
              </BaseXanoApi.FetchAuthGET>
            </View>
            {/* Footer sign out */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              <Touchable
                onPress={() => {
                  try {
                    setGlobalVariableValue({
                      key: 'auth_header',
                      value: null,
                    });
                    navigation.navigate('ConnexionScreen', {
                      email: '',
                      mdp: '',
                      arrivee_inscription: false,
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                {/* Button Wrapper */}
                <View
                  style={StyleSheet.applyWidth(
                    { justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Sign Out Text */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.blanc_flolend,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App['Flolend 5'],
                          },
                        ],
                        fontFamily: 'Montserrat_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Laptop, value: 13 },
                          { minWidth: Breakpoints.Desktop, value: 11 },
                          { minWidth: Breakpoints.BigScreen, value: 15 },
                        ],
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Me déconnecter'}
                  </Text>
                </View>
              </Touchable>
            </View>
          </Surface>
        )}
      </>
    </View>
  );
};

export default withTheme(NavbarBlock);
