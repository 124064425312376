import React from 'react';
import {
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const NousContacterScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  // Renvoie le mois et l'année du moment de la requête au bon format pour l'url de Calendly
  const moisEtAnneeFormat = () => {
    // Obtention de la date actuelle
    var dateActuelle = new Date();

    // Obtention de l'année et du mois de la date actuelle
    var annee = dateActuelle.getFullYear();
    var mois = dateActuelle.getMonth() + 1; // Les mois vont de 0 à 11, donc on ajoute 1

    // Formatage du mois avec un zéro initial si nécessaire
    var moisFormat = mois < 10 ? '0' + mois : mois;

    // Concaténation de l'année et du mois au format "yyyy-mm"
    var resultat = annee + '-' + moisFormat;

    return resultat;
  };

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['header_classique'].style,
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Nous contacter'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: dimensions.height },
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'] + 20,
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue options de contact */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: '20%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: '20%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Fetch doc gen */}
          <BaseXanoApi.FetchDocsSociauxGET>
            {({ loading, error, data, refetchDocsSociaux }) => {
              const fetchDocGenData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Email */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App.flolend_gris_nuance_encart,
                        borderRadius: 15,
                        gap: 10,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre1_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre1_generique']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'E-mail'}
                    </Text>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App.gris_encart_int_flolend,
                          borderRadius: 15,
                          flexDirection: 'row',
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '25%' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={palettes.App['Flolend 2']}
                          name={'Entypo/email'}
                        />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-start', width: '75%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App.blanc_flolend,
                                fontFamily: 'PTSans_400Regular',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchDocGenData?.liens?.mail_contact}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* Courrier */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App.flolend_gris_nuance_encart,
                        borderRadius: 15,
                        gap: 10,
                        marginTop: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre1_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre1_generique']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Courrier'}
                    </Text>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App.gris_encart_int_flolend,
                          borderRadius: 15,
                          flexDirection: 'row',
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* View 4 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '25%' },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={palettes.App['Flolend 2']}
                          name={'Entypo/mail'}
                        />
                      </View>
                      {/* View 5 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'flex-start', width: '75%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App.blanc_flolend,
                                fontFamily: 'PTSans_400Regular',
                                fontSize: 16,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {fetchDocGenData?.liens?.adresse_societe}
                        </Text>
                      </View>
                    </View>
                  </View>
                  {/* Réseaux sociaux */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App.flolend_gris_nuance_encart,
                        borderRadius: 15,
                        gap: 10,
                        marginTop: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre1_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre1_generique']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Réseaux sociaux'}
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App.gris_encart_int_flolend,
                          borderRadius: 15,
                          padding: 10,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Vue Instagram */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          <IconButton
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchDocGenData?.liens?.instagram}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            size={32}
                            color={palettes.App['Flolend 2']}
                            icon={'Entypo/instagram'}
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '75%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Instagram'}
                          </Text>
                        </View>
                      </View>
                      {/* Vue X */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          <IconButton
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchDocGenData?.liens?.twitter}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            size={32}
                            color={palettes.App['Flolend 2']}
                            icon={'FontAwesome/twitter-square'}
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '75%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'X (Twitter)'}
                          </Text>
                        </View>
                      </View>
                      {/* Vue Facebook */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          <IconButton
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchDocGenData?.liens?.facebook}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            size={32}
                            color={palettes.App['Flolend 2']}
                            icon={'AntDesign/facebook-square'}
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '75%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Facebook'}
                          </Text>
                        </View>
                      </View>
                      {/* Vue LinkedIn */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* View 4 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '25%' },
                            dimensions.width
                          )}
                        >
                          <IconButton
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchDocGenData?.liens?.linkedIn}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            size={32}
                            color={palettes.App['Flolend 2']}
                            icon={'AntDesign/linkedin-square'}
                          />
                        </View>
                        {/* View 5 */}
                        <View
                          style={StyleSheet.applyWidth(
                            { width: '75%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: 16,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'LinkedIn'}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* Calendly */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App.flolend_gris_nuance_encart,
                        borderRadius: 15,
                        gap: 10,
                        height: 1000,
                        marginTop: 20,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['titre1_generique']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['titre1_generique']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Entretien'}
                    </Text>
                    <WebView
                      allowFileAccessFromFileURLs={false}
                      allowUniversalAccessFromFileURLs={false}
                      cacheEnabled={true}
                      incognito={false}
                      javaScriptCanOpenWindowsAutomatically={false}
                      javaScriptEnabled={true}
                      mediaPlaybackRequiresUserAction={false}
                      startInLoadingState={false}
                      {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      source={imageSource(
                        `${
                          fetchDocGenData?.liens?.calendly_contact
                        }/30min?month=${moisEtAnneeFormat()}`
                      )}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.WebViewStyles(theme)['Web View'].style,
                          {
                            borderColor: palettes.App.Transparent,
                            borderRadius: 15,
                            maxHeight: '100%',
                            maxWidth: '100%',
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  </View>
                </>
              );
            }}
          </BaseXanoApi.FetchDocsSociauxGET>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(NousContacterScreen);
