import React from 'react';
import {
  Icon,
  LinearGradient,
  ScreenContainer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ProfilScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [searchBarValue, setSearchBarValue] = React.useState('');
  const [selectedTab, setSelectedTab] = React.useState('tab1');
  const [starRatingValue, setStarRatingValue] = React.useState(0);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasBottomSafeArea={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={true}
      hasTopSafeArea={false}
    >
      <LinearGradient
        {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].props}
        color1={palettes.App['Flolend 1']}
        color2={palettes.App['Flolend 2']}
        endX={100}
        endY={100}
        startX={0}
        startY={0}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.LinearGradientStyles(theme)['Linear Gradient'].style,
            {
              alignItems: 'center',
              height: dimensions.height,
              paddingLeft: '5%',
              paddingRight: '5%',
              width: dimensions.width,
            }
          ),
          dimensions.width
        )}
      >
        {/* Retour */}
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'flex-start', marginTop: 35 },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('BottomTabNavigator', {
                  screen: 'EspacePersonnelScreen',
                });
                /* hidden 'Navigate Back' action */
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center' },
                dimensions.width
              )}
            >
              <Icon
                color={palettes.App.gris_fonce_flolend}
                name={'Ionicons/arrow-back-outline'}
                size={30}
              />
            </View>
          </Touchable>
        </View>

        <Surface
          elevation={3}
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: palettes.App.background_flolend,
              borderColor: palettes.App['Flolend 4'],
              borderRadius: 70,
              borderWidth: 1,
              height: 100,
              justifyContent: 'center',
              marginTop: 75,
              minHeight: 40,
              overflow: 'hidden',
              width: 100,
            },
            dimensions.width
          )}
        >
          <Icon
            color={palettes.App['Flolend 2']}
            name={'Ionicons/person'}
            size={75}
          />
        </Surface>
        {/* profil client */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          {/* Fetch client */}
          <BaseXanoApi.FetchAuthGET>
            {({ loading, error, data, refetchAuth }) => {
              const fetchClientData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App.blanc_flolend,
                        fontFamily: 'Montserrat_700Bold',
                        fontSize: 21,
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    {fetchClientData?.Prenom} {fetchClientData?.Nom}
                  </Text>
                  {/* Encart portefeuille */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginTop: 3,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App.blanc_flolend}
                      name={'Entypo/wallet'}
                      size={15}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      style={StyleSheet.applyWidth(
                        {
                          color: palettes.App.blanc_flolend,
                          fontFamily: 'Montserrat_700Bold',
                          fontSize: 15,
                          paddingLeft: 8,
                          paddingRight: 5,
                          textTransform: 'capitalize',
                        },
                        dimensions.width
                      )}
                    >
                      {format_nombre(
                        conversion_solde_wallet(
                          fetchClientData?.wallet_lemonway?.compte_client
                            ?.balance
                        ),
                        2
                      )}
                      {' €'}
                    </Text>
                  </View>
                </>
              );
            }}
          </BaseXanoApi.FetchAuthGET>
        </View>
        {/* Top options */}
        <Surface
          elevation={0}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.flolend_gris_nuance_encart,
              borderBottomWidth: 1,
              borderColor: palettes.App.gris_fonce_flolend,
              borderLeftWidth: 1,
              borderRadius: 15,
              borderRightWidth: 1,
              borderTopWidth: 1,
              justifyContent: 'center',
              marginTop: 20,
              minHeight: 40,
              overflow: 'hidden',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Informations compte */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('InformationsDuCompteScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.gris_fonce_flolend,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={palettes.App['Flolend 2']}
                  name={'AntDesign/profile'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: 15,
                      paddingLeft: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Informations du compte'}
                </Text>
              </View>
              <Icon
                size={24}
                color={palettes.App['Flolend 2']}
                name={'Entypo/chevron-right'}
              />
            </View>
          </Touchable>
          {/* Mon livret Flolend */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MonLivretFlolendScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={palettes.App['Flolend 2']}
                  name={'Ionicons/wallet-sharp'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: 15,
                      paddingLeft: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Mon livret Flolend'}
                </Text>
              </View>
              <Icon
                size={24}
                color={palettes.App['Flolend 2']}
                name={'Entypo/chevron-right'}
              />
            </View>
          </Touchable>
        </Surface>
        {/* Bottom option */}
        <Surface
          elevation={0}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: palettes.App.flolend_gris_nuance_encart,
              borderBottomWidth: 1,
              borderColor: palettes.App.gris_fonce_flolend,
              borderLeftWidth: 1,
              borderRadius: 10,
              borderRightWidth: 1,
              borderTopWidth: 1,
              justifyContent: 'center',
              marginTop: 15,
              minHeight: 40,
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Mon dossier investisseur */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('DossierInvestisseurScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App.gris_fonce_flolend,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={palettes.App['Flolend 2']}
                  name={'MaterialCommunityIcons/ticket-account'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: 15,
                      paddingLeft: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Mon dossier investisseur'}
                </Text>
              </View>
              <Icon
                size={24}
                color={palettes.App['Flolend 2']}
                name={'Entypo/chevron-right'}
              />
            </View>
          </Touchable>
          {/* Mot de passe */}
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('ChangementMotDePasseScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: 12,
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', flexDirection: 'row' },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={palettes.App['Flolend 2']}
                  name={'Entypo/key'}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  style={StyleSheet.applyWidth(
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'Montserrat_500Medium',
                      fontSize: 15,
                      paddingLeft: 16,
                    },
                    dimensions.width
                  )}
                >
                  {'Mot de passe'}
                </Text>
              </View>
              <Icon
                size={24}
                color={palettes.App['Flolend 2']}
                name={'Entypo/chevron-right'}
              />
            </View>
          </Touchable>
        </Surface>
        {/* Footer sign out */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
        >
          <Touchable
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'auth_header',
                  value: null,
                });
                navigation.navigate('ConnexionScreen', {
                  email: '',
                  mdp: '',
                  arrivee_inscription: false,
                });
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Button Wrapper */}
            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'center' },
                dimensions.width
              )}
            >
              {/* Sign Out Text */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App.blanc_flolend,
                    fontFamily: 'Montserrat_400Regular',
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Me déconnecter'}
              </Text>
            </View>
          </Touchable>
        </View>
      </LinearGradient>
    </ScreenContainer>
  );
};

export default withTheme(ProfilScreen);
