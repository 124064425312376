// Supprime le préfixe du buffer du doc
const supprimer_prefixe_json = jsonData => {
  // Créer une copie du JSON pour éviter de modifier l'original directement
  let modifiedJson = { ...jsonData };

  // Liste des préfixes à supprimer
  const prefixes = [
    'data:application/pdf;base64,',
    'data:image/jpeg;base64,',
    'data:image/png;base64,',
    'data:image/jpg;base64,',
    'data:text/plain;base64,',
  ];

  // Supprimer le préfixe approprié de la clé "value"
  for (let prefix of prefixes) {
    if (modifiedJson.value.startsWith(prefix)) {
      modifiedJson.value = modifiedJson.value.substring(prefix.length);
      break; // Quitter la boucle dès qu'un préfixe est supprimé
    }
  }

  return modifiedJson;
};

export default supprimer_prefixe_json;
