import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  id_transfert_1_1bis: 2,
  montant_a_investir: 1055,
  montant_max_investissable: 150000,
  nom_projet: 'Nom projet',
  statut_projet: 'pré-collecte',
};

const Souscription12RisquesScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [checkboxValue3, setCheckboxValue3] = React.useState(false);
  // Fait un POST dans la table Souscriptions et renvoie l'id de la ligne créée.
  const post_sous = async (id_client, id_projet, montant) => {
    const apiKey =
      'patTnyhlx9q1uOzB6.9947d51407877f20352b6cc565b385c99655f28c39342eb0545d725554af6cb1';
    const baseId = 'apprRcKudN6J0jkZj';
    const tableName = 'Souscriptions';

    const apiUrl = `https://api.airtable.com/v0/${baseId}/${tableName}`;
    const headers = new Headers({
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    });

    const recordData = {
      fields: {
        Client: [id_client],
        Projet: id_projet,
        montant_souscrit_euros: montant,
        statut: 'bulletin à signer',
      },
      typecast: true,
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(recordData),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.id) {
          return data.id;
        } else {
          console.error("L'ID de la ligne n'a pas été renvoyé par Airtable.");
          return null;
        }
      } else {
        throw new Error(
          'Erreur lors de la création du record dans Airtable : ' +
            response.status
        );
      }
    } catch (error) {
      console.error(
        'Erreur lors de la création du record dans Airtable :',
        error
      );
      return null;
    }
  };
  const baseXanoCreationSousPOST = BaseXanoApi.useCreationSousPOST();
  const baseXanoCreationPreSouscriptionPOST =
    BaseXanoApi.useCreationPreSouscriptionPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Chargement en cours 2 */}
      <>
        {!(
          !(dimensions.width >= Breakpoints.Laptop) && affichage_chargement
        ) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                bottom: 20,
                flexDirection: 'row',
                gap: 10,
                marginTop: 20,
                paddingLeft: 20,
                position: 'absolute',
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'small'}
              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                'Activity Indicator'
              ].props}
              color={palettes.App['Flolend 4']}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].style,
                dimensions.width
              )}
            />
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                dimensions.width
              )}
            >
              {'Chargement en cours, veuillez patienter.'}
            </Text>
          </View>
        )}
      </>
      {/* Linear Gradient laptop 2 */}
      <>
        {!(
          !(dimensions.width >= Breakpoints.Laptop) && !affichage_chargement
        ) ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startY={0}
            color1={
              (checkboxValue3
                ? palettes.App['Flolend 1']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 1']
            }
            color2={
              (checkboxValue3
                ? palettes.App['Flolend 4']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 4']
            }
            startX={40}
            style={StyleSheet.applyWidth(
              {
                alignSelf: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                borderRadius: [
                  { minWidth: Breakpoints.Laptop, value: 15 },
                  { minWidth: Breakpoints.Mobile, value: 24 },
                ],
                bottom: 20,
                flex: [
                  { minWidth: Breakpoints.Laptop, value: null },
                  { minWidth: Breakpoints.Tablet, value: null },
                  { minWidth: Breakpoints.Mobile, value: 1 },
                ],
                height: [
                  { minWidth: Breakpoints.Laptop, value: 60 },
                  { minWidth: Breakpoints.Desktop, value: 65 },
                  { minWidth: Breakpoints.BigScreen, value: 70 },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'center',
                },
                overflow: [
                  { minWidth: Breakpoints.Laptop, value: 'hidden' },
                  { minWidth: Breakpoints.Mobile, value: 'hidden' },
                ],
                position: 'absolute',
                width: [
                  { minWidth: Breakpoints.Laptop, value: '50%' },
                  { minWidth: Breakpoints.Tablet, value: '50%' },
                  { minWidth: Breakpoints.Desktop, value: '40%' },
                  { minWidth: Breakpoints.Mobile, value: '80%' },
                ],
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            {/* Bouton étape suivante activé */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (!checkboxValue3) {
                      return;
                    }
                    setAffichage_chargement(true);
                    if (
                      (props.route?.params?.statut_projet ??
                        defaultProps.statut_projet) === 'Pré-collecte'
                    ) {
                      (
                        await baseXanoCreationPreSouscriptionPOST.mutateAsync({
                          id_projet:
                            props.route?.params?.id_transfert_1_1bis ??
                            defaultProps.id_transfert_1_1bis,
                          montant_souscrit_euros:
                            props.route?.params?.montant_a_investir ??
                            defaultProps.montant_a_investir,
                        })
                      )?.json;
                      navigation.navigate(
                        'Souscription15ValidationPreSouscriptionScreen'
                      );
                    } else {
                      if (
                        (props.route?.params?.montant_a_investir ??
                          defaultProps.montant_a_investir) >
                        (props.route?.params?.montant_max_investissable ??
                          defaultProps.montant_max_investissable)
                      ) {
                        navigation.navigate(
                          'Souscription13ListeDattenteScreen',
                          {
                            montant_sous:
                              props.route?.params?.montant_a_investir ??
                              defaultProps.montant_a_investir,
                            nom_projet:
                              props.route?.params?.nom_projet ??
                              defaultProps.nom_projet,
                            montant_restant_projet:
                              props.route?.params?.montant_max_investissable ??
                              defaultProps.montant_max_investissable,
                            id_transfer_1_1ter:
                              props.route?.params?.id_transfert_1_1bis ??
                              defaultProps.id_transfert_1_1bis,
                          }
                        );
                      } else {
                        const id_souscription = (
                          await baseXanoCreationSousPOST.mutateAsync({
                            id_projet:
                              props.route?.params?.id_transfert_1_1bis ??
                              defaultProps.id_transfert_1_1bis,
                            montant_souscrit:
                              props.route?.params?.montant_a_investir ??
                              defaultProps.montant_a_investir,
                          })
                        )?.json;
                        navigation.navigate('Souscription2Screen', {
                          id_souscription_airtable: id_souscription?.id,
                        });
                      }
                    }

                    setAffichage_chargement(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_classique'].style,
                  {
                    borderColor: palettes.App.Transparent,
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 0 },
                    borderWidth: 0,
                    color: theme.colors.background.brand,
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 22 },
                      { minWidth: Breakpoints.BigScreen, value: 26 },
                    ],
                    height: '100%',
                    marginBottom: 0,
                    width: '100%',
                  }
                ),
                dimensions.width
              )}
              title={"Je comprends et j'accepte les risques."}
            />
          </LinearGradient>
        )}
      </>
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        viewIsInsideTabBar={false}
        extraScrollHeight={72}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingBottom: [
              { minWidth: Breakpoints.Desktop, value: 10 },
              { minWidth: Breakpoints.Laptop, value: 10 },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
            width: { minWidth: Breakpoints.Laptop, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* En-tête */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 10 },
                { minWidth: Breakpoints.Mobile, value: 10 },
              ],
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                paddingLeft: [
                  { minWidth: Breakpoints.Laptop, value: 0 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '80%' },
                    { minWidth: Breakpoints.Laptop, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_pipe_invest'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_pipe_invest'].style,
                  dimensions.width
                )}
              >
                {'Investir dans '}
                {props.route?.params?.nom_projet ?? defaultProps.nom_projet}
              </Text>
            </View>
            {/* View 2 */}
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '20%' },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ProjetScreen', {
                          id_transfere:
                            props.route?.params?.id_transfert_1_1bis ??
                            defaultProps.id_transfert_1_1bis,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      color={palettes.App.gris_fonce_flolend}
                      name={'Ionicons/exit-outline'}
                      size={18}
                    />
                  </Touchable>
                </View>
              )}
            </>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest'].style,
                dimensions.width
              )}
            >
              {'Etape 1/4 : Montant de la souscription'}
            </Text>
          </View>
          {/* Bandeau progression */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App.gris_encart_int_flolend,
                height: 50,
                justifyContent: 'space-evenly',
                marginTop: [
                  { minWidth: Breakpoints.Laptop, value: 20 },
                  { minWidth: Breakpoints.Mobile, value: 10 },
                ],
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: '80%' },
                  ],
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '25%',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['frise_pipe_invest'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['frise_pipe_invest'].style,
                    dimensions.width
                  )}
                >
                  {'Montant de la souscription'}
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Revue de la documentation'}
                </Text>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Signature'}
                </Text>
              </View>
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 4 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Paiement'}
                </Text>
              </View>
            </View>
            <LinearProgress
              animationDuration={500}
              color={theme.colors.branding.primary}
              indeterminate={false}
              isAnimated={true}
              lineCap={'round'}
              showTrack={true}
              thickness={10}
              trackColor={theme.colors.border.brand}
              trackLineCap={'round'}
              {...GlobalStyles.LinearProgressStyles(theme)['barre_pipe_invest']
                .props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinearProgressStyles(theme)['barre_pipe_invest']
                    .style,
                  { width: { minWidth: Breakpoints.Laptop, value: '80%' } }
                ),
                dimensions.width
              )}
              value={25}
            />
          </View>
        </View>
        {/* Rappel des risques */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: [
                {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.gris_encart_int_flolend,
                },
                {
                  minWidth: Breakpoints.Desktop,
                  value: palettes.App.gris_encart_int_flolend,
                },
              ],
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: [
                { minWidth: Breakpoints.Laptop, value: '20%' },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
              marginRight: [
                { minWidth: Breakpoints.Laptop, value: '20%' },
                { minWidth: Breakpoints.Mobile, value: 20 },
              ],
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: 5 },
                { minWidth: Breakpoints.Laptop, value: '2%' },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Label 5 */}
          <Text
            accessible={true}
            selectable={false}
            {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['titre1_generique'].style,
              dimensions.width
            )}
          >
            {'Rappel des risques'}
          </Text>
          {/* Explication */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginTop: [
                  { minWidth: Breakpoints.Laptop, value: 10 },
                  { minWidth: Breakpoints.Mobile, value: 5 },
                ],
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App.blanc_flolend,
                    fontFamily: 'PTSans_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 14 },
                      { minWidth: Breakpoints.Desktop, value: 18 },
                      { minWidth: Breakpoints.BigScreen, value: 22 },
                    ],
                    textAlign: 'justify',
                    whiteSpace: 'pre-line',
                  }
                ),
                dimensions.width
              )}
            >
              {
                "Le montant que vous prévoyez d'investir correspond à plus de 1 000 € ou 5% de votre patrimoine net."
              }
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: palettes.App.blanc_flolend,
                    fontFamily: 'PTSans_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 14 },
                      { minWidth: Breakpoints.Desktop, value: 18 },
                      { minWidth: Breakpoints.BigScreen, value: 22 },
                    ],
                    marginTop: 10,
                    textAlign: 'justify',
                    whiteSpace: 'pre-line',
                  }
                ),
                dimensions.width
              )}
            >
              {
                "Un investissement dans un projet de financement participatif comporte le risque de perte totale du capital investi. Il nécessite de surcroît l'immobilisation de votre épargne."
              }
            </Text>
          </View>
          {/* Divider 3 */}
          <Divider
            {...GlobalStyles.DividerStyles(theme)['Divider'].props}
            color={palettes.App.gris_fonce_flolend}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.DividerStyles(theme)['Divider'].style,
                { marginBottom: 12, marginTop: 12 }
              ),
              dimensions.width
            )}
          />
          {/* Validation */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: [
                  { minWidth: Breakpoints.Laptop, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                flexDirection: 'row',
                maxWidth: '100%',
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth({ width: '80%' }, dimensions.width)}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      color: palettes.App.blanc_flolend,
                      fontFamily: 'PTSans_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Laptop, value: 14 },
                        { minWidth: Breakpoints.Desktop, value: 18 },
                        { minWidth: Breakpoints.BigScreen, value: 22 },
                      ],
                      textAlign: 'justify',
                      whiteSpace: 'pre-line',
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  "Je comprends l'investissement ainsi que les risques qui y sont associés et je souhaite poursuivre mon investissement."
                }
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '20%',
                },
                dimensions.width
              )}
            >
              {/* Checkbox hors iOS */}
              <>
                {Platform.OS === 'ios' ? null : (
                  <Checkbox
                    onPress={newCheckboxHorsIOSValue => {
                      try {
                        setCheckboxValue3(newCheckboxHorsIOSValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    color={palettes.App['Flolend 4']}
                    status={checkboxValue3}
                    uncheckedColor={palettes.App.gris_fonce_flolend}
                  />
                )}
              </>
              {/* Switch iOS */}
              <>
                {!(Platform.OS === 'ios') ? null : (
                  <Switch
                    onValueChange={newSwitchIOSValue => {
                      try {
                        setCheckboxValue3(newSwitchIOSValue);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    activeTrackColor={palettes.App['Flolend 4']}
                    inactiveTrackColor={palettes.App.gris_fonce_flolend}
                    value={checkboxValue3}
                  />
                )}
              </>
            </View>
          </View>
          {/* Divider 6 */}
          <Divider
            {...GlobalStyles.DividerStyles(theme)['Divider'].props}
            color={palettes.App.gris_fonce_flolend}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.DividerStyles(theme)['Divider'].style,
                { marginBottom: 12, marginTop: 12 }
              ),
              dimensions.width
            )}
          />
          {/* Linear Gradient laptop */}
          <>
            {!(
              dimensions.width >= Breakpoints.Laptop && !affichage_chargement
            ) ? null : (
              <LinearGradient
                endX={100}
                endY={100}
                startY={0}
                color1={
                  (checkboxValue3
                    ? palettes.App['Flolend 1']
                    : palettes.App.gris_fonce_flolend) ??
                  palettes.App['Flolend 1']
                }
                color2={
                  (checkboxValue3
                    ? palettes.App['Flolend 4']
                    : palettes.App.gris_fonce_flolend) ??
                  palettes.App['Flolend 4']
                }
                startX={40}
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: [
                      { minWidth: Breakpoints.Laptop, value: 'center' },
                      { minWidth: Breakpoints.Tablet, value: 'center' },
                    ],
                    borderRadius: [
                      { minWidth: Breakpoints.Laptop, value: 15 },
                      { minWidth: Breakpoints.Mobile, value: 24 },
                    ],
                    flex: [
                      { minWidth: Breakpoints.Laptop, value: null },
                      { minWidth: Breakpoints.Tablet, value: null },
                      { minWidth: Breakpoints.Mobile, value: 1 },
                    ],
                    height: [
                      { minWidth: Breakpoints.Laptop, value: 60 },
                      { minWidth: Breakpoints.Desktop, value: 65 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                    ],
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    marginLeft: '5%',
                    marginRight: '5%',
                    overflow: { minWidth: Breakpoints.Laptop, value: 'hidden' },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Tablet, value: '50%' },
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Bouton étape suivante activé */}
                <Button
                  accessible={true}
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        if (!checkboxValue3) {
                          return;
                        }
                        setAffichage_chargement(true);
                        if (
                          (props.route?.params?.statut_projet ??
                            defaultProps.statut_projet) === 'Pré-collecte'
                        ) {
                          (
                            await baseXanoCreationPreSouscriptionPOST.mutateAsync(
                              {
                                id_projet:
                                  props.route?.params?.id_transfert_1_1bis ??
                                  defaultProps.id_transfert_1_1bis,
                                montant_souscrit_euros:
                                  props.route?.params?.montant_a_investir ??
                                  defaultProps.montant_a_investir,
                              }
                            )
                          )?.json;
                          navigation.navigate(
                            'Souscription15ValidationPreSouscriptionScreen'
                          );
                        } else {
                          if (
                            (props.route?.params?.montant_a_investir ??
                              defaultProps.montant_a_investir) >
                            (props.route?.params?.montant_max_investissable ??
                              defaultProps.montant_max_investissable)
                          ) {
                            navigation.navigate(
                              'Souscription13ListeDattenteScreen',
                              {
                                montant_sous:
                                  props.route?.params?.montant_a_investir ??
                                  defaultProps.montant_a_investir,
                                nom_projet:
                                  props.route?.params?.nom_projet ??
                                  defaultProps.nom_projet,
                                montant_restant_projet:
                                  props.route?.params
                                    ?.montant_max_investissable ??
                                  defaultProps.montant_max_investissable,
                                id_transfer_1_1ter:
                                  props.route?.params?.id_transfert_1_1bis ??
                                  defaultProps.id_transfert_1_1bis,
                              }
                            );
                          } else {
                            const id_souscription = (
                              await baseXanoCreationSousPOST.mutateAsync({
                                id_projet:
                                  props.route?.params?.id_transfert_1_1bis ??
                                  defaultProps.id_transfert_1_1bis,
                                montant_souscrit:
                                  props.route?.params?.montant_a_investir ??
                                  defaultProps.montant_a_investir,
                              })
                            )?.json;
                            navigation.navigate('Souscription2Screen', {
                              id_souscription_airtable: id_souscription?.id,
                            });
                          }
                        }

                        setAffichage_chargement(false);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                    .props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['bouton_classique']
                        .style,
                      {
                        borderColor: palettes.App.Transparent,
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 0,
                        },
                        borderWidth: 0,
                        color: theme.colors.background.brand,
                        fontSize: [
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 26 },
                        ],
                        height: '100%',
                        marginBottom: 0,
                        width: '100%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={"Je comprends et j'accepte les risques."}
                />
              </LinearGradient>
            )}
          </>
          {/* Chargement en cours */}
          <>
            {!(
              dimensions.width >= Breakpoints.Laptop && affichage_chargement
            ) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 10,
                    marginTop: 20,
                    paddingLeft: 20,
                  },
                  dimensions.width
                )}
              >
                <ActivityIndicator
                  animating={true}
                  hidesWhenStopped={true}
                  size={'small'}
                  {...GlobalStyles.ActivityIndicatorStyles(theme)[
                    'Activity Indicator'
                  ].props}
                  color={palettes.App['Flolend 4']}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].style,
                    dimensions.width
                  )}
                />
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                    dimensions.width
                  )}
                >
                  {'Chargement en cours, veuillez patienter.'}
                </Text>
              </View>
            )}
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(Souscription12RisquesScreen);
