export default {
  Avatar: require('../assets/images/Avatar.jpeg'),
  Photovoltaic21389921280: require('../assets/images/Photovoltaic21389921280.jpg'),
  Avatar2: require('../assets/images/Avatar2.png'),
  Logo: require('../assets/images/Logo.png'),
  Notifications: require('../assets/images/Notifications.png'),
  Dashboard: require('../assets/images/Dashboard.png'),
  ILikeFood1: require('../assets/images/ILikeFood1.png'),
  Newsbit: require('../assets/images/Newsbit.png'),
  JoelMottLaK153ghdigUnsplash: require('../assets/images/JoelMottLaK153ghdigUnsplash.jpg'),
  DelfinaCocciardiNDPBKD03MUnsplash: require('../assets/images/DelfinaCocciardiNDPBKD03MUnsplash.jpg'),
  ModernStarbucks1: require('../assets/images/ModernStarbucks1.jpeg'),
  Landscape: require('../assets/images/Landscape.png'),
  Profile: require('../assets/images/Profile.jpg'),
  Image3: require('../assets/images/Image3.jpg'),
  Image5: require('../assets/images/Image5.jpeg'),
  WindEnergy73421771280: require('../assets/images/WindEnergy73421771280.jpg'),
  _2c933e096b808cb57df8aab19ba06f93: require('../assets/images/2c933e096b808cb57df8aab19ba06f93.jpeg'),
  H2air: require('../assets/images/H2air.jpg'),
  Image4: require('../assets/images/Image4.jpg'),
  Image6: require('../assets/images/Image6.jpg'),
  Image2: require('../assets/images/Image2.jpg'),
  Location: require('../assets/images/Location.png'),
  Payoneer: require('../assets/images/Payoneer.png'),
  TransferWise: require('../assets/images/TransferWise.png'),
  Cash: require('../assets/images/Cash.png'),
  Gps: require('../assets/images/Gps.png'),
  MedDelivered: require('../assets/images/MedDelivered.png'),
  Background: require('../assets/images/Background.png'),
  CharlieGreen3JmfENcL24MUnsplash: require('../assets/images/CharlieGreen3JmfENcL24MUnsplash.jpg'),
  FloBack: require('../assets/images/FloBack.png'),
  GoLive: require('../assets/images/GoLive.png'),
  GoLiveSG: require('../assets/images/GoLiveSG.png'),
  GoliveSI: require('../assets/images/GoliveSI.png'),
  Rect3400: require('../assets/images/Rect3400.png'),
  _6645d011fb4873d0d2d087e3victorflolendp500: require('../assets/images/6645d011fb4873d0d2d087e3VictorFlolendP500.png'),
  _6645cf92d583579cf9e710d4tiagoflolendp500: require('../assets/images/6645cf92d583579cf9e710d4TiagoFlolendP500.png'),
  LogoFlolendMargin: require('../assets/images/LogoFlolendMargin.png'),
  LogoTexteNoirMilieu: require('../assets/images/LogoTexteNoirMilieu.png'),
  LogoTexteBlancMilieu: require('../assets/images/LogoTexteBlancMilieu.png'),
  LogoWireframeLight: require('../assets/images/LogoWireframeLight.png'),
};
