import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const IBANClientScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [client_sans_viban, setClient_sans_viban] = React.useState(false);
  const [display_masterview, setDisplay_masterview] = React.useState(false);
  const [erreur_creation, setErreur_creation] = React.useState(false);
  // Met en forme l'IBAN
  const forme_iban = inputString => {
    // Vérifier si l'entrée est définie et de type chaîne, sinon retourner une chaîne vide
    if (typeof inputString !== 'string') {
      return '';
    }

    // Utiliser la méthode match pour diviser la chaîne en segments de 4 caractères
    const segments = inputString.match(/.{1,4}/g);

    // Joindre les segments avec un espace
    const formattedString = segments ? segments.join(' ') : '';

    return formattedString;
  };
  const baseXanoCreationVibanPOST = BaseXanoApi.useCreationVibanPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const retour_api = (await BaseXanoApi.authGET(Constants))?.json;
        if (retour_api?.wallet_lemonway?.compte_client?.iban_virtuel_existe) {
          setDisplay_masterview(true);
        } else {
          setClient_sans_viban(true);
          const retour_iban = (await baseXanoCreationVibanPOST.mutateAsync())
            ?.json;
          if (!retour_iban) {
            await waitUtil({ milliseconds: 11000 });
          }
          setClient_sans_viban(false);
          if (retour_iban?.success) {
            setDisplay_masterview(true);
          } else {
            setErreur_creation(true);
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { marginBottom: 20 }
              ),
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 18
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 30
                      : 25
                  }
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Recharge par virement'}
            </Text>
          </View>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: dimensions.height },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Masterview */}
        <>
          {!display_masterview ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                  flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '10%' },
                  ],
                  marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                  padding: { minWidth: Breakpoints.Laptop, value: 30 },
                },
                dimensions.width
              )}
            >
              {/* Fetch client */}
              <BaseXanoApi.FetchAuthGET>
                {({ loading, error, data, refetchAuth }) => {
                  const fetchClientData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Vue gauche (laptop) */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingRight: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '50%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Livret */}
                        <View>
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                backgroundColor: palettes.App['Flolend 1'],
                                borderRadius: 12,
                                height: 220,
                                justifyContent: 'space-between',
                                paddingBottom: 24,
                                paddingLeft: 24,
                                paddingRight: 24,
                                paddingTop: 24,
                              },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.blanc_flolend,
                                    fontFamily: 'Montserrat_500Medium',
                                    fontSize: 16,
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Livret Flolend'}
                              </Text>
                              <Image
                                resizeMode={'contain'}
                                source={imageSource(
                                  Images['LogoWireframeLight']
                                )}
                                style={StyleSheet.applyWidth(
                                  { height: 40, width: 40 },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  gap: 5,
                                  marginTop: 6,
                                },
                                dimensions.width
                              )}
                            >
                              {/* IBAN */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.blanc_flolend,
                                    fontFamily: 'Montserrat_700Bold',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                    ],
                                    lineHeight: 26,
                                    marginLeft: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {forme_iban(
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.iban_actif?.iban
                                )}
                              </Text>
                              {/* BIC */}
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.blanc_flolend,
                                    fontFamily: 'Montserrat_700Bold',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 14,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                    ],
                                    lineHeight: 26,
                                    marginLeft: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.iban_actif?.swift
                                }
                              </Text>
                            </View>

                            <View>
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.blanc_flolend,
                                    fontFamily: 'Montserrat_500Medium',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'Solde'}
                              </Text>

                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-start',
                                    flexDirection: 'row',
                                    marginTop: 6,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  style={StyleSheet.applyWidth(
                                    {
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'Montserrat_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 24,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 28,
                                        },
                                      ],
                                      lineHeight: 26,
                                      marginLeft: 4,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {format_nombre(
                                    conversion_solde_wallet(
                                      fetchClientData?.wallet_lemonway
                                        ?.compte_client?.balance
                                    ),
                                    2
                                  )}
                                  {' €'}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                      {/* Vue droite (laptop) */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            paddingLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                            width: {
                              minWidth: Breakpoints.Laptop,
                              value: '50%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Explications */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: '2%' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['explications']
                              .props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)['explications']
                                .style,
                              dimensions.width
                            )}
                          >
                            {
                              "Pour alimenter votre livret Flolend, réalisez un virement depuis votre interface bancaire à l'aide de l'IBAN affiché ici. Vous retrouverez ce virement dans votre livret, intégré à votre espace personnel."
                            }
                          </Text>
                        </View>
                        {/* Téléchargement */}
                        <View
                          style={StyleSheet.applyWidth(
                            { marginTop: '2%' },
                            dimensions.width
                          )}
                        >
                          {/* View 3 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              {/* Nom */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'PTSans_400Regular',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 18,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 22,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'IBAN de mon livret Flolend'}
                              </Text>
                            </View>
                            {/* Vue boutons */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  flexDirection: 'row',
                                  justifyContent: 'space-around',
                                  width: '20%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* Télécharger */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { justifyContent: 'center' },
                                  dimensions.width
                                )}
                              >
                                <Pressable
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        await WebBrowser.openBrowserAsync(
                                          `${fetchClientData?.iban?.pdf?.url}`
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                >
                                  <Icon
                                    color={palettes.App['Flolend 2']}
                                    name={'FontAwesome/download'}
                                    size={
                                      !(dimensions.width >= Breakpoints.Desktop)
                                        ? 18
                                        : dimensions.width >=
                                          Breakpoints.BigScreen
                                        ? 30
                                        : 25
                                    }
                                  />
                                </Pressable>
                              </View>
                              {/* Partager */}
                              <>
                                {Platform.OS === 'web' ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { justifyContent: 'center' },
                                      dimensions.width
                                    )}
                                  >
                                    <Pressable
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            await openShareUtil(
                                              `${fetchClientData?.iban?.pdf?.url}`
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                    >
                                      <Icon
                                        color={palettes.App['Flolend 2']}
                                        name={
                                          'MaterialIcons/mobile-screen-share'
                                        }
                                        size={
                                          !(
                                            dimensions.width >=
                                            Breakpoints.Desktop
                                          )
                                            ? 18
                                            : dimensions.width >=
                                              Breakpoints.BigScreen
                                            ? 30
                                            : 25
                                        }
                                      />
                                    </Pressable>
                                  </View>
                                )}
                              </>
                            </View>
                          </View>
                        </View>
                        {/* QR code */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'center',
                              height: 300,
                              marginTop: 20,
                              width: 300,
                            },
                            dimensions.width
                          )}
                        >
                          <Image
                            {...GlobalStyles.ImageStyles(theme)['Image'].props}
                            resizeMode={'center'}
                            source={imageSource(
                              `${fetchClientData?.iban?.QR_code?.url}`
                            )}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'].style,
                                {
                                  borderRadius: 24,
                                  height: '100%',
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                          />
                        </View>
                      </View>
                    </>
                  );
                }}
              </BaseXanoApi.FetchAuthGET>
            </View>
          )}
        </>
        {/* Vue création */}
        <>
          {!client_sans_viban ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <ActivityIndicator
                animating={true}
                hidesWhenStopped={true}
                {...GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].props}
                color={palettes.App['Flolend 4']}
                size={'large'}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].style,
                    { height: 100, width: 100 }
                  ),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre1_generique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre1_generique'].style,
                  dimensions.width
                )}
              >
                {'Création de votre IBAN en cours'}
              </Text>
            </View>
          )}
        </>
        {/* Vue erreur création */}
        <>
          {!erreur_creation ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  paddingLeft: '5%',
                  paddingRight: '5%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <Icon
                color={palettes.App.blanc_flolend}
                name={'MaterialIcons/error'}
                size={80}
              />
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['message_erreur'].style,
                  dimensions.width
                )}
              >
                {
                  "Erreur à la création de l'IBAN - veuillez réessayer ultérieurement."
                }
              </Text>
            </View>
          )}
        </>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(IBANClientScreen);
