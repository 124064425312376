import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import openShareUtil from '../utils/openShare';
import useWindowDimensions from '../utils/useWindowDimensions';

const ReclamationScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: [
            { minWidth: Breakpoints.Laptop, value: dimensions.height },
            { minWidth: Breakpoints.Mobile, value: dimensions.height },
          ],
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', paddingLeft: 20 },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Déposer une réclamation'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue horizontale pour laptop */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              gap: { minWidth: Breakpoints.Laptop, value: 20 },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-between',
              },
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 15 },
            },
            dimensions.width
          )}
        >
          {/* Vue contenu */}
          <View
            style={StyleSheet.applyWidth(
              { width: { minWidth: Breakpoints.Laptop, value: '50%' } },
              dimensions.width
            )}
          >
            {/* titre */}
            <View
              style={StyleSheet.applyWidth(
                { marginBottom: 15 },
                dimensions.width
              )}
            >
              {/* Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                  dimensions.width
                )}
              >
                {'Comment déposer une réclamation ?'}
              </Text>
            </View>
            {/* Message */}
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: { minWidth: Breakpoints.Laptop, value: '0%' } },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['explications'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['explications'].style,
                  dimensions.width
                )}
              >
                {
                  'Toute l’équipe Flolend s’efforce de répondre à vos attentes en vous proposant la meilleure qualité de service possible. Des incidents peuvent toutefois se produire, c’est la raison pour laquelle nous avons mis en place une procédure de traitement des réclamations qui doit :\n\n- vous permettre de nous faire part de vos réclamations concernant d’éventuels incidents en lien avec votre utilisation de notre plateforme,\n\n- nous permettre de traiter ces réclamations de façon efficace, égale et harmonisée.\n\nFlolend applique sa procédure de traitement des réclamations à tout appel, e-mail ou courrier de votre part faisant état d’un mécontentement, à l’exception de vos demandes d’information, d’avis, de clarification, de service ou de prestation.\n\nVous pouvez adresser votre réclamation aux coordonnées suivantes :\n- par email : reclamations@flolend.com\n- par courrier postal: 28bis rue du Cardinal Lemoine, 75005 Paris FRANCE\n\nVotre réclamation doit être formulée en langue française et comprendre l’ensemble des éléments nous permettant d’y apporter une solution rapide. Aussi doit-elle être rédigée à partir du documents PDF téléchargeable ci-dessous.'
                }
              </Text>
            </View>
            {/* Fetch réclamation */}
            <BaseXanoApi.FetchReclamationGET>
              {({ loading, error, data, refetchReclamation }) => {
                const fetchRClamationData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Vue téléchargement procédure */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: '0%',
                          },
                          marginTop: '2%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Vue sous-titre */}
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '65%' },
                          dimensions.width
                        )}
                      >
                        {/* Nom */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['elmt_table']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['elmt_table'].style,
                            dimensions.width
                          )}
                        >
                          {'Procédure de traitement des réclamations clients'}
                        </Text>
                      </View>
                      {/* Vue boutons */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            gap: 30,
                            justifyContent: [
                              { minWidth: Breakpoints.Mobile, value: 'center' },
                              { minWidth: Breakpoints.Tablet, value: 'center' },
                            ],
                            width: [
                              { minWidth: Breakpoints.Mobile, value: '35%' },
                              { minWidth: Breakpoints.Tablet, value: '20%' },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {/* Télécharger */}
                        <View>
                          <Pressable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  await WebBrowser.openBrowserAsync(
                                    `${fetchRClamationData?.procedure_reclamation}`
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                          >
                            <Icon
                              color={palettes.App['Flolend 2']}
                              name={'FontAwesome/download'}
                              size={
                                !(dimensions.width >= Breakpoints.Desktop)
                                  ? 18
                                  : dimensions.width >= Breakpoints.BigScreen
                                  ? 30
                                  : 25
                              }
                            />
                          </Pressable>
                        </View>
                        {/* Partager */}
                        <>
                          {Platform.OS === 'web' ? null : (
                            <View>
                              {/* Pressable 2 */}
                              <Pressable
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await openShareUtil(
                                        `${fetchRClamationData?.procedure_reclamation}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                              >
                                <Icon
                                  color={palettes.App['Flolend 2']}
                                  name={'MaterialIcons/mobile-screen-share'}
                                  size={
                                    !(dimensions.width >= Breakpoints.Desktop)
                                      ? 18
                                      : dimensions.width >=
                                        Breakpoints.BigScreen
                                      ? 30
                                      : 25
                                  }
                                />
                              </Pressable>
                            </View>
                          )}
                        </>
                      </View>
                    </View>

                    <LinearGradient
                      endX={100}
                      endY={100}
                      startX={0}
                      startY={0}
                      {...GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].props}
                      color1={palettes.App['Flolend 1']}
                      color2={palettes.App['Flolend 2']}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.LinearGradientStyles(theme)[
                            'gradient_bouton'
                          ].style,
                          {
                            alignSelf: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            flex: [
                              { minWidth: Breakpoints.BigScreen, value: null },
                              { minWidth: Breakpoints.Laptop, value: null },
                            ],
                            height: [
                              { minWidth: Breakpoints.BigScreen, value: 80 },
                              { minWidth: Breakpoints.Laptop, value: 60 },
                              { minWidth: Breakpoints.Desktop, value: 70 },
                            ],
                            overflow: {
                              minWidth: Breakpoints.Laptop,
                              value: 'hidden',
                            },
                            width: [
                              { minWidth: Breakpoints.Laptop, value: '75%' },
                              { minWidth: Breakpoints.BigScreen, value: '50%' },
                              { minWidth: Breakpoints.Desktop, value: '65%' },
                              { minWidth: Breakpoints.Tablet, value: '50%' },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              await WebBrowser.openBrowserAsync(
                                `${fetchRClamationData?.formulaire_reclamation}`
                              );
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)[
                          'bouton_fond_blanc'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)[
                              'bouton_fond_blanc'
                            ].style,
                            {
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: null,
                              },
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Télécharger le formulaire standard'}
                      />
                    </LinearGradient>
                  </>
                );
              }}
            </BaseXanoApi.FetchReclamationGET>
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <Spacer bottom={8} left={8} right={8} top={8} />
              )}
            </>
          </View>
          {/* Image */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <Surface
                {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                elevation={3}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                    {
                      borderRadius: [
                        { minWidth: Breakpoints.BigScreen, value: 24 },
                        { minWidth: Breakpoints.Laptop, value: 24 },
                      ],
                      overflow: [
                        { minWidth: Breakpoints.BigScreen, value: 'hidden' },
                        { minWidth: Breakpoints.Laptop, value: 'hidden' },
                      ],
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    }
                  ),
                  dimensions.width
                )}
              >
                <Surface
                  {...GlobalStyles.SurfaceStyles(theme)['Surface'].props}
                  elevation={3}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SurfaceStyles(theme)['Surface'].style,
                      {
                        height: { minWidth: Breakpoints.Laptop, value: '100%' },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <Image
                    resizeMode={'cover'}
                    {...GlobalStyles.ImageStyles(theme)['Image'].props}
                    source={imageSource(Images['WindEnergy73421771280'])}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'].style,
                        {
                          height: [
                            { minWidth: Breakpoints.BigScreen, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                          width: [
                            { minWidth: Breakpoints.BigScreen, value: '100%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  />
                </Surface>
              </Surface>
            )}
          </>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(ReclamationScreen);
