import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const loginClientPOST = async (
  Constants,
  { mail_du_client, mdp },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/auth/login`;
  const options = {
    body: JSON.stringify({ Identifiant: mail_du_client, Password: mdp }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useLoginClientPOST = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Client', args],
    () => loginClientPOST(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Clients']),
    }
  );
};

export const FetchLoginClientPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  mail_du_client,
  mdp,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginClientPOST(
    { mail_du_client, mdp },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLoginClient: refetch });
};

export const signupPOST = async (
  Constants,
  { signupEmail, signupPassword },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/auth/signup`;
  const options = {
    body: JSON.stringify({
      Identifiant: signupEmail,
      Password: signupPassword,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSignupPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => signupPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Client', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Client');
        queryClient.invalidateQueries('Clients');
      },
    }
  );
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  signupEmail,
  signupPassword,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSignupPOST(
    { signupEmail, signupPassword },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignup: refetch });
};

export const actualiteGET = async (
  Constants,
  { actualites_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (actualites_id !== undefined) {
    paramsDict['actualites_id'] = actualites_id;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/actualite${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useActualiteGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Actu', args],
    () => actualiteGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Actus']),
    }
  );
};

export const FetchActualiteGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  actualites_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActualiteGET(
    { actualites_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchActualite: refetch });
};

export const actusEtRessourcesParProjetGET = async (
  Constants,
  {
    id_projet,
    nombre_actus,
    nombre_actus_affiche,
    nombre_actus_hors_affiche,
    nombre_ressources,
  },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_projet !== undefined) {
    paramsDict['id_projet'] = id_projet;
  }
  if (nombre_actus !== undefined) {
    paramsDict['nombre_actus'] = nombre_actus;
  }
  if (nombre_actus_affiche !== undefined) {
    paramsDict['nombre_actus_affiche'] = nombre_actus_affiche;
  }
  if (nombre_actus_hors_affiche !== undefined) {
    paramsDict['nombre_actus_hors_affiche'] = nombre_actus_hors_affiche;
  }
  if (nombre_ressources !== undefined) {
    paramsDict['nombre_ressources'] = nombre_ressources;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/actualites/actus_et_ressources_par_projet${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useActusEtRessourcesParProjetGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Actu', args],
    () => actusEtRessourcesParProjetGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Actus']),
    }
  );
};

export const FetchActusEtRessourcesParProjetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
  nombre_actus,
  nombre_actus_affiche,
  nombre_actus_hors_affiche,
  nombre_ressources,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActusEtRessourcesParProjetGET(
    {
      id_projet,
      nombre_actus,
      nombre_actus_affiche,
      nombre_actus_hors_affiche,
      nombre_ressources,
    },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchActusEtRessourcesParProjet: refetch,
  });
};

export const ajoutHubspotPOST = async (
  Constants,
  { email, nom },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/post_to_hubspot`;
  const options = {
    body: JSON.stringify({ email_client: email, name: nom }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAjoutHubspotPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => ajoutHubspotPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('contact_hubspot', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('contact_hubspot');
        queryClient.invalidateQueries('contact_hubspots');
      },
    }
  );
};

export const FetchAjoutHubspotPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  email,
  nom,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAjoutHubspotPOST(
    { email, nom },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAjoutHubspot: refetch });
};

export const arrierePlanDemarrageGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/arriere_plan_accueil`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useArrierePlanDemarrageGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['doc_gen', args],
    () => arrierePlanDemarrageGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['doc_gens']),
    }
  );
};

export const FetchArrierePlanDemarrageGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useArrierePlanDemarrageGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchArrierePlanDemarrage: refetch,
  });
};

export const authGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/auth/me`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAuthGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['Client', args], () => authGET(Constants, args, handlers), {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    onSuccess: () => queryClient.invalidateQueries(['Clients']),
  });
};

export const FetchAuthGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAuthGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAuth: refetch });
};

export const bulletinPdfPOST = async (
  Constants,
  { id_souscription },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documents/creation_bulletin_pdf`;
  const options = {
    body: JSON.stringify({ id_souscription: id_souscription }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useBulletinPdfPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => bulletinPdfPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Documents', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Document');
        queryClient.invalidateQueries('Documents');
      },
    }
  );
};

export const FetchBulletinPdfPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_souscription,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBulletinPdfPOST(
    { id_souscription },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBulletinPdf: refetch });
};

export const categorisationPATCH = async (
  Constants,
  { quest1, quest2, quest3, quest4, quest5 },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/categorisation`;
  const options = {
    body: JSON.stringify({
      question1: quest1,
      question2: quest2,
      question3: quest3,
      question4: quest4,
      question5: quest5,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCategorisationPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      categorisationPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const cguEtRgpdGET = async (
  Constants,
  { bool_inclure_rgpd },
  handlers = {}
) => {
  const paramsDict = {};
  if (bool_inclure_rgpd !== undefined) {
    paramsDict['bool_inclure_RGPD'] = bool_inclure_rgpd;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/cgu_et_charte${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCguEtRgpdGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['doc_gens', args],
    () => cguEtRgpdGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchCguEtRgpdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  bool_inclure_rgpd,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCguEtRgpdGET(
    { bool_inclure_rgpd },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCguEtRgpd: refetch });
};

export const chgmtMdpPUT = async (
  Constants,
  { entree_1, entree_2 },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/clients/changement_mdp`;
  const options = {
    body: JSON.stringify({ entree_mdp_1: entree_1, entree_mdp_2: entree_2 }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useChgmtMdpPUT = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => chgmtMdpPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Client', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Client');
        queryClient.invalidateQueries('Clients');
      },
    }
  );
};

export const clientLikeurGET = async (
  Constants,
  { actu_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (actu_id !== undefined) {
    paramsDict['actualites_id'] = actu_id;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/client_likeur${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useClientLikeurGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Actu', args],
    () => clientLikeurGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Actus']),
    }
  );
};

export const FetchClientLikeurGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  actu_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useClientLikeurGET(
    { actu_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchClientLikeur: refetch });
};

export const clientLikeurRessourceGET = async (
  Constants,
  { ressource_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (ressource_id !== undefined) {
    paramsDict['ressources_id'] = ressource_id;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/client_likeur_ressources${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useClientLikeurRessourceGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Ressource', args],
    () => clientLikeurRessourceGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Ressources']),
    }
  );
};

export const FetchClientLikeurRessourceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  ressource_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useClientLikeurRessourceGET(
    { ressource_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchClientLikeurRessource: refetch,
  });
};

export const conditionsSousCbGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/metriques_carte`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useConditionsSousCbGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['doc_gen', args],
    () => conditionsSousCbGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['doc_gens']),
    }
  );
};

export const FetchConditionsSousCbGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useConditionsSousCbGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchConditionsSousCb: refetch });
};

export const contenuClientGET = async (
  Constants,
  { nombre_retours_aff, nombre_retours_hors_aff, nombre_retours_ressources },
  handlers = {}
) => {
  const paramsDict = {};
  if (nombre_retours_aff !== undefined) {
    paramsDict['nombre_retours_actus_affiche'] = nombre_retours_aff;
  }
  if (nombre_retours_hors_aff !== undefined) {
    paramsDict['nombre_retours_actus_hors_aff'] = nombre_retours_hors_aff;
  }
  if (nombre_retours_ressources !== undefined) {
    paramsDict['nombre_retours_ressources'] = nombre_retours_ressources;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/auth/contenu${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useContenuClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['contenus', args],
    () => contenuClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchContenuClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  nombre_retours_aff,
  nombre_retours_hors_aff,
  nombre_retours_ressources,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useContenuClientGET(
    { nombre_retours_aff, nombre_retours_hors_aff, nombre_retours_ressources },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchContenuClient: refetch });
};

export const creationBulletinPOST = async (
  Constants,
  { id_projet },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documents/creation_bulletin_souscription`;
  const options = {
    body: JSON.stringify({ liaison_projets: id_projet }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreationBulletinPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      creationBulletinPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Documents', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Document');
        queryClient.invalidateQueries('Documents');
      },
    }
  );
};

export const FetchCreationBulletinPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreationBulletinPOST(
    { id_projet },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreationBulletin: refetch });
};

export const creationPreSouscriptionPOST = async (
  Constants,
  { id_projet, montant_souscrit_euros },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/creation_pre_souscription`;
  const options = {
    body: JSON.stringify({
      montant_souscrit_euros: montant_souscrit_euros,
      liaison_projet: id_projet,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreationPreSouscriptionPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      creationPreSouscriptionPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Souscription', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Souscription');
        queryClient.invalidateQueries('Souscriptions');
      },
    }
  );
};

export const FetchCreationPreSouscriptionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
  montant_souscrit_euros,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreationPreSouscriptionPOST(
    { id_projet, montant_souscrit_euros },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreationPreSouscription: refetch,
  });
};

export const creationSousPOST = async (
  Constants,
  { id_projet, montant_souscrit },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/creation`;
  const options = {
    body: JSON.stringify({
      montant_souscrit_euros: montant_souscrit,
      liaison_projet: id_projet,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreationSousPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => creationSousPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Souscription', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Souscription');
        queryClient.invalidateQueries('Souscriptions');
      },
    }
  );
};

export const FetchCreationSousPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
  montant_souscrit,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreationSousPOST(
    { id_projet, montant_souscrit },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreationSous: refetch });
};

export const creationSousAttentePOST = async (
  Constants,
  { id_projet, montant },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/creation_en_attente`;
  const options = {
    body: JSON.stringify({
      montant_souscrit_euros: montant,
      liaison_projet: id_projet,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreationSousAttentePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      creationSousAttentePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Souscription', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Souscription');
        queryClient.invalidateQueries('Souscriptions');
      },
    }
  );
};

export const FetchCreationSousAttentePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
  montant,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreationSousAttentePOST(
    { id_projet, montant },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreationSousAttente: refetch,
  });
};

export const creationVibanPOST = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/creation_viban`;
  const options = {
    body: JSON.stringify({}),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreationVibanPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => creationVibanPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('VIBAN', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('VIBAN');
        queryClient.invalidateQueries('VIBANS');
      },
    }
  );
};

export const FetchCreationVibanPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreationVibanPOST(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreationViban: refetch });
};

export const docsDuClientGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documents/docs_du_client`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDocsDuClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Documents', args],
    () => docsDuClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchDocsDuClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDocsDuClientGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDocsDuClient: refetch });
};

export const docsSociauxGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/social`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDocsSociauxGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Documents', args],
    () => docsSociauxGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchDocsSociauxGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDocsSociauxGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDocsSociaux: refetch });
};

export const documentsPublicsParProjetGET = async (
  Constants,
  { projet_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (projet_id !== undefined) {
    paramsDict['projet_id'] = projet_id;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/Documents_publics_par_projet${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDocumentsPublicsParProjetGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Documents', args],
    () => documentsPublicsParProjetGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchDocumentsPublicsParProjetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  projet_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDocumentsPublicsParProjetGET(
    { projet_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchDocumentsPublicsParProjet: refetch,
  });
};

export const dossInvestMajEcr1PATCH = async (
  Constants,
  { besoin_sommes, enveloppe, horizon, niv_risq_perte },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/maj_ecran1`;
  const options = {
    body: JSON.stringify({
      Enveloppe_totale: enveloppe,
      Horizon_detention: horizon,
      Besoin_sommes: besoin_sommes,
      Niveau_risque_perte_capital: niv_risq_perte,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDossInvestMajEcr1PATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      dossInvestMajEcr1PATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const dossInvestMajEcr2PATCH = async (
  Constants,
  {
    US_pers,
    check_pdts1,
    check_pdts10,
    check_pdts2,
    check_pdts3,
    check_pdts4,
    check_pdts5,
    check_pdts6,
    check_pdts7,
    check_pdts8,
    check_pdts9,
    dernier_invest,
    montant_fin_part,
    ppe1,
    ppe2,
  },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/maj_ecran2`;
  const options = {
    body: JSON.stringify({
      US_pers: US_pers,
      PPE1: ppe1,
      PPE2: ppe2,
      check_pdts1: check_pdts1,
      check_pdts2: check_pdts2,
      check_pdts3: check_pdts3,
      check_pdts4: check_pdts4,
      check_pdts5: check_pdts5,
      check_pdts6: check_pdts6,
      check_pdts7: check_pdts7,
      check_pdts8: check_pdts8,
      check_pdts9: check_pdts9,
      check_pdts10: check_pdts10,
      dernier_invest: dernier_invest,
      montant_fin_part: montant_fin_part,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDossInvestMajEcr2PATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      dossInvestMajEcr2PATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const dossInvestMajEcr3PATCH = async (
  Constants,
  {
    def_action,
    def_capital_pret,
    def_creancier,
    def_obligation,
    illiquidite,
    perte_totale,
    prime_risque,
  },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/maj_ecran3`;
  const options = {
    body: JSON.stringify({
      def_action: def_action,
      def_obligation: def_obligation,
      question_perte_totale: perte_totale,
      question_risque_illiquidite: illiquidite,
      prime_risque: prime_risque,
      def_creancier: def_creancier,
      def_capital_pret: def_capital_pret,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDossInvestMajEcr3PATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      dossInvestMajEcr3PATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const dossInvestMajSimulPATCH = async (
  Constants,
  { simul_capac_endettement, simul_pat_net },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/maj_simul`;
  const options = {
    body: JSON.stringify({
      simul_pat_net: simul_pat_net,
      simul_capacite_endettement: simul_capac_endettement,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDossInvestMajSimulPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      dossInvestMajSimulPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const dossInvestValidSimulPATCH = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/validation_simulation`;
  const options = {
    body: JSON.stringify({}),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDossInvestValidSimulPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      dossInvestValidSimulPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const dossierInvestGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDossierInvestGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['dossier_investisseur', args],
    () => dossierInvestGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['dossier_investisseurs']),
    }
  );
};

export const FetchDossierInvestGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDossierInvestGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDossierInvest: refetch });
};

export const echeancierProjetGET = async (
  Constants,
  { id_projet },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_projet !== undefined) {
    paramsDict['id_projet'] = id_projet;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/echeancier_projet${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useEcheancierProjetGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Echeanciers', args],
    () => echeancierProjetGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchEcheancierProjetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useEcheancierProjetGET(
    { id_projet },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchEcheancierProjet: refetch });
};

export const editInfosClientPATCH = async (
  Constants,
  {
    civilite,
    code_com_naissance,
    code_postal,
    commune,
    date_naissance,
    dep_naiss,
    nationalites,
    nom,
    nom_rue,
    num_residence,
    pays_residence,
    prenom,
    telephone,
  },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/clients/modifier_infos`;
  const options = {
    body: JSON.stringify({
      nom: nom,
      prenom: prenom,
      telephone: telephone,
      civilite: civilite,
      date_naissance: date_naissance,
      nom_rue: nom_rue,
      numero_residence: num_residence,
      code_postal: code_postal,
      commune_residence: commune,
      pays_residence: pays_residence,
      nationalites: nationalites,
      departement_naissance: dep_naiss,
      code_com_naiss: code_com_naissance,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useEditInfosClientPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      editInfosClientPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Client', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Client');
        queryClient.invalidateQueries('Clients');
      },
    }
  );
};

export const exportEcheancierGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/echeanciers_clients/export`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useExportEcheancierGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Echeancier', args],
    () => exportEcheancierGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Echeanciers']),
    }
  );
};

export const FetchExportEcheancierGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useExportEcheancierGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchExportEcheancier: refetch });
};

export const finOnbaordingCgpPATCH = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/clients/fin_onboarding_cgp`;
  const options = {
    body: JSON.stringify({}),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useFinOnbaordingCgpPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      finOnbaordingCgpPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Client', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Client');
        queryClient.invalidateQueries('Clients');
      },
    }
  );
};

export const formulaireDispenseGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/demande_dispense`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useFormulaireDispenseGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Document', args],
    () => formulaireDispenseGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Documents']),
    }
  );
};

export const FetchFormulaireDispenseGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFormulaireDispenseGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFormulaireDispense: refetch });
};

export const getNotifsClientGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/notifications/notifications_du_client`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetNotifsClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Notifications', args],
    () => getNotifsClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchGetNotifsClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetNotifsClientGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetNotifsClient: refetch });
};

export const indicateursPerformanceGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/indicateurs_performance`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useIndicateursPerformanceGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['indicateurs_performances', args],
    () => indicateursPerformanceGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchIndicateursPerformanceGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useIndicateursPerformanceGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchIndicateursPerformance: refetch,
  });
};

export const investissementsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/investissements`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useInvestissementsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Investissements', args],
    () => investissementsGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchInvestissementsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useInvestissementsGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchInvestissements: refetch });
};

export const majBookmarkedPUT = async (
  Constants,
  { id_ressource },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/maj_bookmarked`;
  const options = {
    body: JSON.stringify({ ressources_id: id_ressource }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajBookmarkedPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => majBookmarkedPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ressource', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ressource');
        queryClient.invalidateQueries('Ressources');
      },
    }
  );
};

export const majEcheancierClientPUT = async (
  Constants,
  { montant, taux },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/edit_echeancier_client`;
  const options = {
    body: JSON.stringify({ Taux: taux, Montant_simul: montant }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajEcheancierClientPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      majEcheancierClientPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Client', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Client');
        queryClient.invalidateQueries('Clients');
      },
    }
  );
};

export const majLabelIbanPATCH = async (
  Constants,
  { id_iban_lemonway, nouveau_label },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/maj_label_iban`;
  const options = {
    body: JSON.stringify({
      id_iban_lemonway: id_iban_lemonway,
      nouveau_label: nouveau_label,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajLabelIbanPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => majLabelIbanPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('money_out', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('money_out');
        queryClient.invalidateQueries('money_outs');
      },
    }
  );
};

export const majLikesPUT = async (Constants, { actu_id }, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/maj_likes`;
  const options = {
    body: JSON.stringify({ actualites_id: actu_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajLikesPUT = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => majLikesPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Actu', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Actu');
        queryClient.invalidateQueries('Actus');
      },
    }
  );
};

export const majLikesRessourcesPUT = async (
  Constants,
  { id_ressource },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/maj_likes_ressources`;
  const options = {
    body: JSON.stringify({ ressources_id: id_ressource }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajLikesRessourcesPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      majLikesRessourcesPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ressource', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ressource');
        queryClient.invalidateQueries('Ressources');
      },
    }
  );
};

export const majRacineWebkitGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/racine_webkit`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajRacineWebkitGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['paiement', args],
    () => majRacineWebkitGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['paiements']),
    }
  );
};

export const FetchMajRacineWebkitGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMajRacineWebkitGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMajRacineWebkit: refetch });
};

export const majVuesPublicationPUT = async (
  Constants,
  { id_actu },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/maj_vues_publication`;
  const options = {
    body: JSON.stringify({ id_publication: id_actu }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajVuesPublicationPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      majVuesPublicationPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Actu', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Actu');
        queryClient.invalidateQueries('Actus');
      },
    }
  );
};

export const majVuesRessourcePUT = async (
  Constants,
  { id_ressource },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/maj_vues_ressource`;
  const options = {
    body: JSON.stringify({ ressources_id: id_ressource }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMajVuesRessourcePUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      majVuesRessourcePUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Ressource', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Ressource');
        queryClient.invalidateQueries('Ressources');
      },
    }
  );
};

export const maxAlimCbGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documentation_generale/max_cb_alim_wallet`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMaxAlimCbGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['doc_gen', args],
    () => maxAlimCbGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['doc_gens']),
    }
  );
};

export const FetchMaxAlimCbGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMaxAlimCbGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMaxAlimCb: refetch });
};

export const moneyOutClientPOST = async (
  Constants,
  { id_iban_sortie, montant_virement_euros },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/money_out_client`;
  const options = {
    body: JSON.stringify({
      id_iban_sortie: id_iban_sortie,
      montant_euros: montant_virement_euros,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMoneyOutClientPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      moneyOutClientPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('money_out', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('money_out');
        queryClient.invalidateQueries('money_outs');
      },
    }
  );
};

export const FetchMoneyOutClientPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_iban_sortie,
  montant_virement_euros,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useMoneyOutClientPOST(
    { id_iban_sortie, montant_virement_euros },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMoneyOutClient: refetch });
};

export const paiementPATCH = async (Constants, { id_sous }, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/souscription_payee`;
  const options = {
    body: JSON.stringify({ souscriptions_id: id_sous }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePaiementPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => paiementPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Souscription', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Souscription');
        queryClient.invalidateQueries('Souscriptions');
      },
    }
  );
};

export const paiement$tokenPaiementPOST = async (
  Constants,
  { montant },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/token_money_in`;
  const options = {
    body: JSON.stringify({ montant_transaction: montant }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePaiement$tokenPaiementPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      paiement$tokenPaiementPOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('paiement', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('paiement');
        queryClient.invalidateQueries('paiements');
      },
    }
  );
};

export const FetchPaiement$tokenPaiementPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  montant,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePaiement$tokenPaiementPOST(
    { montant },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPaiement$tokenPaiement: refetch,
  });
};

export const politiquesGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/politiques`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePolitiquesGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['doc_gens', args],
    () => politiquesGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPolitiquesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePolitiquesGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPolitiques: refetch });
};

export const projetParIdGET = async (
  Constants,
  { id_projet_transfere },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_projet_transfere !== undefined) {
    paramsDict['projets_id'] = id_projet_transfere;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/Projet_par_id${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useProjetParIdGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Projet', args],
    () => projetParIdGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Projets']),
    }
  );
};

export const FetchProjetParIdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet_transfere,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useProjetParIdGET(
    { id_projet_transfere },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchProjetParId: refetch });
};

export const projetParStatutGET = async (
  Constants,
  { statut },
  handlers = {}
) => {
  const paramsDict = {};
  if (statut !== undefined) {
    paramsDict['Statut_input'] = statut;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/projets_par_statut${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useProjetParStatutGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Projets', args],
    () => projetParStatutGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchProjetParStatutGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  statut,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useProjetParStatutGET(
    { statut },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchProjetParStatut: refetch });
};

export const reclamationGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documents/formulaire_reclamation`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useReclamationGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['doc_gen', args],
    () => reclamationGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['doc_gens']),
    }
  );
};

export const FetchReclamationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useReclamationGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchReclamation: refetch });
};

export const ressourceClientBookmarkedGET = async (
  Constants,
  { ressources_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (ressources_id !== undefined) {
    paramsDict['ressources_id'] = ressources_id;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/client_bookmarked${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRessourceClientBookmarkedGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Ressource', args],
    () => ressourceClientBookmarkedGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Ressources']),
    }
  );
};

export const FetchRessourceClientBookmarkedGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  ressources_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRessourceClientBookmarkedGET(
    { ressources_id },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchRessourceClientBookmarked: refetch,
  });
};

export const ressourcesDuClientGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/ressources/ressources_du_client`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRessourcesDuClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Ressources', args],
    () => ressourcesDuClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchRessourcesDuClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRessourcesDuClientGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRessourcesDuClient: refetch });
};

export const ressourcesParIdGET = async (
  Constants,
  { id_ressource },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_ressource !== undefined) {
    paramsDict['ressources_id'] = id_ressource;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/ressources${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRessourcesParIdGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Ressource', args],
    () => ressourcesParIdGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Ressources']),
    }
  );
};

export const FetchRessourcesParIdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_ressource,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRessourcesParIdGET(
    { id_ressource },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRessourcesParId: refetch });
};

export const ressourcesParProjetGET = async (
  Constants,
  { id_projet },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_projet !== undefined) {
    paramsDict['id_projet'] = id_projet;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/ressources_par_projet${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRessourcesParProjetGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Ressources', args],
    () => ressourcesParProjetGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchRessourcesParProjetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRessourcesParProjetGET(
    { id_projet },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchRessourcesParProjet: refetch,
  });
};

export const risquesParProjetGET = async (
  Constants,
  { id_projet },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_projet !== undefined) {
    paramsDict['id_projet'] = id_projet;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/Risques_du_projet${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRisquesParProjetGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Risques', args],
    () => risquesParProjetGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchRisquesParProjetGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRisquesParProjetGET(
    { id_projet },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRisquesParProjet: refetch });
};

export const signatureBulletinPATCH = async (
  Constants,
  { id_sous },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/signature_bulletin`;
  const options = {
    body: JSON.stringify({ souscriptions_id: id_sous }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSignatureBulletinPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      signatureBulletinPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Souscription', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Souscription');
        queryClient.invalidateQueries('Souscriptions');
      },
    }
  );
};

export const sousAnnulPATCH = async (
  Constants,
  { id_souscription },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/annulation`;
  const options = {
    body: JSON.stringify({ souscriptions_id: id_souscription }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSousAnnulPATCH = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => sousAnnulPATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Souscription', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Souscription');
        queryClient.invalidateQueries('Souscriptions');
      },
    }
  );
};

export const sousParIdGET = async (
  Constants,
  { id_souscription },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_souscription !== undefined) {
    paramsDict['souscriptions_id'] = id_souscription;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/par_id${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSousParIdGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Souscription', args],
    () => sousParIdGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Souscriptions']),
    }
  );
};

export const FetchSousParIdGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_souscription,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSousParIdGET(
    { id_souscription },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSousParId: refetch });
};

export const sousValideeGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/validees`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSousValideeGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Souscriptions', args],
    () => sousValideeGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchSousValideeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSousValideeGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSousValidee: refetch });
};

export const souscriptionsClientGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/en_cours_de_souscription`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSouscriptionsClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Souscriptions', args],
    () => souscriptionsClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchSouscriptionsClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSouscriptionsClientGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchSouscriptionsClient: refetch,
  });
};

export const statutTransactionGET = async (
  Constants,
  { idSouscription, idTransaction },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['id_transaction'] =
    idTransaction !== undefined ? idTransaction : '';
  paramsDict['id_souscription'] =
    idSouscription !== undefined ? idSouscription : '';
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/statut_transaction${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useStatutTransactionGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['paiement', args],
    () => statutTransactionGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['paiements']),
    }
  );
};

export const FetchStatutTransactionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  idSouscription,
  idTransaction,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStatutTransactionGET(
    { idSouscription, idTransaction },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStatutTransaction: refetch });
};

export const suppComptePATCH = async (Constants, _args, handlers = {}) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/auth/fermeture`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSuppComptePATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => suppComptePATCH(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Client', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Client');
        queryClient.invalidateQueries('Clients');
      },
    }
  );
};

export const suppressionIbanPUT = async (
  Constants,
  { id_iban_lemonway },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/suppression_iban`;
  const options = {
    body: JSON.stringify({ id_iban_lemonway: id_iban_lemonway }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PUT',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSuppressionIbanPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      suppressionIbanPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('money_out', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('money_out');
        queryClient.invalidateQueries('money_outs');
      },
    }
  );
};

export const testDepassementLimiteIndivGET = async (
  Constants,
  { id_projet, montant_souscription },
  handlers = {}
) => {
  const paramsDict = {};
  if (id_projet !== undefined) {
    paramsDict['id_projet'] = id_projet;
  }
  if (montant_souscription !== undefined) {
    paramsDict['montant_souscription'] = montant_souscription;
  }
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/souscriptions/test_limite_invest${renderQueryString(
    paramsDict,
    'brackets'
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTestDepassementLimiteIndivGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Souscription', args],
    () => testDepassementLimiteIndivGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['Souscriptions']),
    }
  );
};

export const FetchTestDepassementLimiteIndivGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_projet,
  montant_souscription,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTestDepassementLimiteIndivGET(
    { id_projet, montant_souscription },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchTestDepassementLimiteIndiv: refetch,
  });
};

export const testValidationDossierInvestisseurPATCH = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/dossier_investisseur/validation`;
  const options = {
    body: JSON.stringify({}),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'PATCH',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTestValidationDossierInvestisseurPATCH = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      testValidationDossierInvestisseurPATCH(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData(
            'dossier_investisseur',
            previousValue
          );
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('dossier_investisseur');
        queryClient.invalidateQueries('dossier_investisseurs');
      },
    }
  );
};

export const transactionsClientGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/transactions_client`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTransactionsClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Echeanciers', args],
    () => transactionsClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchTransactionsClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTransactionsClientGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTransactionsClient: refetch });
};

export const uploadIbanSortiePOST = async (
  Constants,
  { code_iban, fichier, label },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/upload_iban_sortie`;
  const options = {
    body: JSON.stringify({ doc_iban: fichier, iban: code_iban, label: label }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUploadIbanSortiePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      uploadIbanSortiePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('money_out', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('money_out');
        queryClient.invalidateQueries('money_outs');
      },
    }
  );
};

export const FetchUploadIbanSortiePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  code_iban,
  fichier,
  label,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadIbanSortiePOST(
    { code_iban, fichier, label },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUploadIbanSortie: refetch });
};

export const uploadJustifDomicilePOST = async (
  Constants,
  { id_souscription, justif },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/documents/justif_domicile`;
  const options = {
    body: JSON.stringify({
      id_souscription: id_souscription,
      justif_client: justif,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUploadJustifDomicilePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      uploadJustifDomicilePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Documents', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Document');
        queryClient.invalidateQueries('Documents');
      },
    }
  );
};

export const FetchUploadJustifDomicilePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  id_souscription,
  justif,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadJustifDomicilePOST(
    { id_souscription, justif },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchUploadJustifDomicile: refetch,
  });
};

export const uploadKycPOST = async (
  Constants,
  { doc_1_recto_et_verso, doc_2_recto_et_verso, type_doc1, type_doc2 },
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/lemonway/upload_docs_kyc`;
  const options = {
    body: JSON.stringify({
      doc1_recto_et_verso: doc_1_recto_et_verso,
      doc2_recto_et_verso: doc_2_recto_et_verso,
      type_doc_1: type_doc1,
      type_doc_2: type_doc2,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useUploadKycPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => uploadKycPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('KYC', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('KYC');
        queryClient.invalidateQueries('KYCS');
      },
    }
  );
};

export const FetchUploadKycPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  doc_1_recto_et_verso,
  doc_2_recto_et_verso,
  type_doc1,
  type_doc2,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadKycPOST(
    { doc_1_recto_et_verso, doc_2_recto_et_verso, type_doc1, type_doc2 },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUploadKyc: refetch });
};

export const échéancierDuClientGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-:Developpement/echeanciers_clients/toutes_echeances`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['auth_header'],
      'Content-Type': 'application/json',
      'X-Data-Source': Constants['datasource'],
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useÉchéancierDuClientGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Echeanciers', args],
    () => échéancierDuClientGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchÉchéancierDuClientGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useÉchéancierDuClientGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchÉchéancierDuClient: refetch });
};
