// Convertit une date au format UTC timestamp (employé par Lemonway) en une date lisible par l'utilisateur.
const conversion_date_utc = utcTimestamp => {
  // Créer un objet Date à partir du timestamp Unix (en millisecondes)
  const date = new Date(utcTimestamp * 1000);

  // Définir les options pour le format de date souhaité
  const options = { day: 'numeric', month: 'short', year: 'numeric' };

  // Convertir la date en chaîne de caractères avec les options spécifiées
  const formattedDate = date.toLocaleDateString('fr-FR', options);

  return formattedDate;
};

export default conversion_date_utc;
