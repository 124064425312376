import React from 'react';
import {
  Button,
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_sous: 89 };

const GererMaSouscriptionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activation_chargement, setActivation_chargement] =
    React.useState(false);
  const [affichage_erreur, setAffichage_erreur] = React.useState(false);
  const [selection_annulation, setSelection_annulation] = React.useState(false);
  // Renvoie true si la souscription est annulable et son statut est différent de "Règlement en suspens" - empêche l'annulation des souscriptions au statut "Règlement en suspens".
  const annulable_hors_suspens = (bool_annulable, statut) => {
    return statut !== 'Règlement en suspens' && bool_annulable;
  };

  // Conditionne l'apparition du message pour les soucriptions annulables
  const conditionnement_annulable = (bool_annulable, statut_souscription) => {
    const statutsValides = [
      'Souscription à régler',
      'Souscription validée mais annulable',
    ];
    return statutsValides.includes(statut_souscription) && bool_annulable;
  };

  // Conditionne l'affichage de la section "Poursuivre".
  const verifierStatutAffichagePoursuivre = Statut => {
    return Statut === 'Bulletin à signer' || Statut === 'Souscription à régler';
  };
  const baseXanoSousAnnulPATCH = BaseXanoApi.useSousAnnulPATCH();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          justifyContent: {
            minWidth: Breakpoints.BigScreen,
            value: 'space-between',
          },
          maxHeight: [
            { minWidth: Breakpoints.Mobile, value: dimensions.height },
            { minWidth: Breakpoints.Laptop, value: dimensions.height },
          ],
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                marginBottom: 10,
                paddingLeft: 20,
              },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 18
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 30
                      : 25
                  }
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Gérer ma souscription'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue générale */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              gap: { minWidth: Breakpoints.Laptop, value: 20 },
              justifyContent: {
                minWidth: Breakpoints.Laptop,
                value: 'space-around',
              },
              marginLeft: [
                { minWidth: Breakpoints.Tablet, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Tablet, value: '10%' },
                { minWidth: Breakpoints.Mobile, value: '5%' },
              ],
              marginTop: [
                { minWidth: Breakpoints.Laptop, value: 10 },
                { minWidth: Breakpoints.Desktop, value: 20 },
                { minWidth: Breakpoints.BigScreen, value: 30 },
              ],
              padding: { minWidth: Breakpoints.Laptop, value: 20 },
              width: [
                { minWidth: Breakpoints.Laptop, value: '60%' },
                { minWidth: Breakpoints.Desktop, value: '40%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* Fetch souscriptions */}
          <BaseXanoApi.FetchSousParIdGET
            id_souscription={
              props.route?.params?.id_sous ?? defaultProps.id_sous
            }
          >
            {({ loading, error, data, refetchSousParId }) => {
              const fetchSouscriptionsData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Lien page projet */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Header 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: [
                            { minWidth: Breakpoints.Mobile, value: 10 },
                            { minWidth: Breakpoints.Laptop, value: '2%' },
                          ],
                          paddingLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          paddingRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Heading */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Votre projet'}
                      </Text>
                    </View>

                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('ProjetScreen', {
                            id_transfere: fetchSouscriptionsData?.projets?.id,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      style={StyleSheet.applyWidth(
                        {
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <Surface
                        elevation={3}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App.gris_encart_int_flolend,
                            borderRadius: 12,
                            flexDirection: [
                              { minWidth: Breakpoints.Desktop, value: 'row' },
                              { minWidth: Breakpoints.Mobile, value: 'row' },
                            ],
                            justifyContent: 'space-between',
                            overflow: 'hidden',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { padding: 10, width: '70%' },
                            dimensions.width
                          )}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { flex: 1, width: '100%' },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              ellipsizeMode={'tail'}
                              numberOfLines={1}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App.gris_fonce_flolend,
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 14 },
                                    { minWidth: Breakpoints.Tablet, value: 16 },
                                    { minWidth: Breakpoints.Laptop, value: 18 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 26,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {fetchSouscriptionsData?.projets?.porteur?.Nom}
                            </Text>

                            <Text
                              accessible={true}
                              selectable={false}
                              ellipsizeMode={'tail'}
                              numberOfLines={2}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App.blanc_flolend,
                                  fontFamily: 'Montserrat_600SemiBold',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 20 },
                                    { minWidth: Breakpoints.Tablet, value: 22 },
                                    { minWidth: Breakpoints.Laptop, value: 24 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 28,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 32,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {fetchSouscriptionsData?.projets?.Name}
                            </Text>
                            <Spacer left={8} right={8} bottom={3} top={3} />
                            <Text
                              accessible={true}
                              selectable={false}
                              ellipsizeMode={'tail'}
                              numberOfLines={1}
                              style={StyleSheet.applyWidth(
                                {
                                  color: palettes.App['Flolend 2'],
                                  fontFamily: 'PTSans_400Regular',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 18 },
                                    { minWidth: Breakpoints.Tablet, value: 20 },
                                    { minWidth: Breakpoints.Laptop, value: 22 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 26,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 30,
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {format_nombre(
                                fetchSouscriptionsData?.montant_souscrit_euros,
                                2
                              )}
                              {' €'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <>
                              {dimensions.width >= Breakpoints.Laptop ? null : (
                                <Icon
                                  color={palettes.App.gris_fonce_flolend}
                                  name={
                                    'MaterialCommunityIcons/clock-time-two-outline'
                                  }
                                  size={18}
                                />
                              )}
                            </>
                            {/* Icon 2 */}
                            <>
                              {!(
                                dimensions.width >= Breakpoints.Laptop
                              ) ? null : (
                                <Icon
                                  color={palettes.App.gris_fonce_flolend}
                                  name={
                                    'MaterialCommunityIcons/clock-time-two-outline'
                                  }
                                  size={29}
                                />
                              )}
                            </>
                            {/* Limite à signer */}
                            <>
                              {!(
                                fetchSouscriptionsData?.statut ===
                                'Bulletin à signer'
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'delai_gerer_souscription'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'delai_gerer_souscription'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'Bulletin à signer avant le '}
                                  {ConvertionDate(
                                    fetchSouscriptionsData?.limite_signature
                                  )}
                                </Text>
                              )}
                            </>
                            {/* Limite à régler */}
                            <>
                              {!(
                                fetchSouscriptionsData?.statut ===
                                  'Souscription à régler' &&
                                !fetchSouscriptionsData?.annulable
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'delai_gerer_souscription'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'delai_gerer_souscription'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'A régler avant le '}
                                  {ConvertionDate(
                                    fetchSouscriptionsData?.limite_reglement
                                  )}
                                </Text>
                              )}
                            </>
                            {/* Limite annulable */}
                            <>
                              {!conditionnement_annulable(
                                fetchSouscriptionsData?.annulable,
                                fetchSouscriptionsData?.statut
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'delai_gerer_souscription'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'delai_gerer_souscription'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {"Annulable jusqu'au "}
                                  {ConvertionDate(
                                    fetchSouscriptionsData?.limite_annulation
                                  )}
                                </Text>
                              )}
                            </>
                            {/* Limite liste attente */}
                            <>
                              {!(
                                fetchSouscriptionsData?.statut ===
                                "Liste d'attente"
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'delai_gerer_souscription'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'delai_gerer_souscription'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {"En attente jusqu'au "}
                                  {ConvertionDate(
                                    fetchSouscriptionsData?.projets
                                      ?.date_fin_liste_attente
                                  )}
                                </Text>
                              )}
                            </>
                            {/* Règlement en suspens */}
                            <>
                              {!(
                                fetchSouscriptionsData?.statut ===
                                'Règlement en suspens'
                              ) ? null : (
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)[
                                    'delai_gerer_souscription'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)[
                                      'delai_gerer_souscription'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {'En attente de réception des fonds'}
                                </Text>
                              )}
                            </>
                          </View>
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              height: '100%',
                              justifyContent: 'space-between',
                              width: '30%',
                            },
                            dimensions.width
                          )}
                        >
                          <Image
                            resizeMode={'cover'}
                            source={imageSource(
                              `${fetchSouscriptionsData?.projets?.Image_couverture?.url}`
                            )}
                            style={StyleSheet.applyWidth(
                              { height: '100%', width: '100%' },
                              dimensions.width
                            )}
                          />
                        </View>
                      </Surface>
                    </Touchable>
                  </View>
                  {/* Gestion souscription */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Laptop,
                          value: 'center',
                        },
                        marginTop: 20,
                        paddingLeft: { minWidth: Breakpoints.Laptop, value: 0 },
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 0,
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '100%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Vue poursuivre */}
                    <>
                      {!verifierStatutAffichagePoursuivre(
                        fetchSouscriptionsData?.statut
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: {
                                minWidth: Breakpoints.Laptop,
                                value: '2%',
                              },
                            },
                            dimensions.width
                          )}
                        >
                          {/* Titre poursuivre */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: [
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: '2%' },
                                ],
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 0,
                                },
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Heading signature */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Poursuivre ma souscription'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '0%',
                                },
                                width: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Touchable poursuivre */}
                            <Touchable
                              onPress={() => {
                                try {
                                  if (
                                    fetchSouscriptionsData?.statut ===
                                    'Souscription à régler'
                                  ) {
                                    if (navigation.canGoBack()) {
                                      navigation.popToTop();
                                    }
                                    navigation.replace('Souscription4Screen', {
                                      id_souscription_3_4:
                                        props.route?.params?.id_sous ??
                                        defaultProps.id_sous,
                                    });
                                  } else {
                                    if (navigation.canGoBack()) {
                                      navigation.popToTop();
                                    }
                                    navigation.replace('Souscription2Screen', {
                                      id_souscription_airtable:
                                        props.route?.params?.id_sous ??
                                        defaultProps.id_sous,
                                    });
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={3}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      backgroundColor:
                                        palettes.App.gris_encart_int_flolend,
                                      borderRadius: 15,
                                      bottom: 2,
                                      overflow: 'hidden',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        justifyContent: 'center',
                                        minHeight: 75,
                                        padding: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 10,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 10,
                                          },
                                        ],
                                        width: '75%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Bulletin à signer */}
                                    <>
                                      {!(
                                        fetchSouscriptionsData?.statut ===
                                        'Bulletin à signer'
                                      ) ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color:
                                                  palettes.App.blanc_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 26,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {
                                            'Signez votre bulletin de souscription'
                                          }
                                        </Text>
                                      )}
                                    </>
                                    {/* A régler */}
                                    <>
                                      {!(
                                        fetchSouscriptionsData?.statut ===
                                        'Souscription à régler'
                                      ) ? null : (
                                        <Text
                                          accessible={true}
                                          selectable={false}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ].style,
                                              {
                                                color:
                                                  palettes.App.blanc_flolend,
                                                fontFamily: 'PTSans_400Regular',
                                                fontSize: [
                                                  {
                                                    minWidth:
                                                      Breakpoints.Mobile,
                                                    value: 14,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Tablet,
                                                    value: 16,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Laptop,
                                                    value: 18,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.Desktop,
                                                    value: 22,
                                                  },
                                                  {
                                                    minWidth:
                                                      Breakpoints.BigScreen,
                                                    value: 26,
                                                  },
                                                ],
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'Réglez votre souscription'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'center', width: '25%' },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {dimensions.width >=
                                      Breakpoints.Laptop ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 2']}
                                          name={'AntDesign/rightsquareo'}
                                          size={40}
                                        />
                                      )}
                                    </>
                                    {/* Icon 2 */}
                                    <>
                                      {!(
                                        dimensions.width >= Breakpoints.Laptop
                                      ) ? null : (
                                        <Icon
                                          color={palettes.App['Flolend 2']}
                                          name={'AntDesign/rightsquareo'}
                                          size={51}
                                        />
                                      )}
                                    </>
                                  </View>
                                </View>
                              </Surface>
                            </Touchable>
                          </View>
                        </View>
                      )}
                    </>
                    {/* Vue annulation */}
                    <>
                      {!annulable_hors_suspens(
                        fetchSouscriptionsData?.annulable,
                        fetchSouscriptionsData?.statut
                      ) ? null : (
                        <View>
                          {/* Titre */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginBottom: [
                                  { minWidth: Breakpoints.Mobile, value: 10 },
                                  { minWidth: Breakpoints.Laptop, value: '2%' },
                                ],
                                marginTop: 10,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Annuler titre */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'titre1_generique'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  {
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 20,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 28,
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Annuler ma souscription'}
                            </Text>
                          </View>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                                width: [
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: '50%',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                ],
                              },
                              dimensions.width
                            )}
                          >
                            {/* Touchable annulation */}
                            <Touchable
                              onPress={() => {
                                try {
                                  setSelection_annulation(true);
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Vue annulation */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: palettes.App.Icon_Dark_Gray,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: selection_annulation
                                          ? palettes.App.Internal_Hot_Red
                                          : palettes.App.Icon_Dark_Gray,
                                      },
                                    ],
                                    borderRadius: 12,
                                    borderWidth: 1,
                                    justifyContent: 'center',
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { width: '75%' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            color: palettes.App.blanc_flolend,
                                            fontFamily: 'PTSans_400Regular',
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 14,
                                              },
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 16,
                                              },
                                              {
                                                minWidth: Breakpoints.Laptop,
                                                value: 18,
                                              },
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 22,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 26,
                                              },
                                            ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Annulez votre souscription sans frais'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'center', width: '25%' },
                                      dimensions.width
                                    )}
                                  >
                                    <Icon
                                      color={palettes.App.gris_fonce_flolend}
                                      name={'Entypo/cross'}
                                      size={
                                        (!(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 40
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 50
                                          : 45) ?? 40
                                      }
                                    />
                                  </View>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                          {/* Confirmation */}
                          <>
                            {!selection_annulation ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    marginTop: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 10,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: '2%',
                                      },
                                    ],
                                    paddingBottom: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 20,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 0,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                <Text
                                  accessible={true}
                                  selectable={false}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        color: palettes.App.blanc_flolend,
                                        fontFamily: 'Montserrat_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 18,
                                          },
                                          {
                                            minWidth: Breakpoints.Tablet,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 22,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 26,
                                          },
                                        ],
                                        marginBottom: 5,
                                        marginLeft: 20,
                                        marginRight: 20,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {"Confirmez-vous l'annulation ?"}
                                </Text>
                                {/* Chargement */}
                                <>
                                  {!activation_chargement ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          flexDirection: 'row',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <ActivityIndicator
                                        hidesWhenStopped={true}
                                        size={'small'}
                                        {...GlobalStyles.ActivityIndicatorStyles(
                                          theme
                                        )['Activity Indicator'].props}
                                        animating={true}
                                        color={palettes.App['Flolend 4']}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ActivityIndicatorStyles(
                                            theme
                                          )['Activity Indicator'].style,
                                          dimensions.width
                                        )}
                                      />
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            {
                                              color: palettes.App.blanc_flolend,
                                              fontFamily: 'PTSans_700Bold',
                                              paddingLeft: 10,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Chargement en cours...'}
                                      </Text>
                                    </View>
                                  )}
                                </>
                                <>
                                  {activation_chargement ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: {
                                            minWidth: Breakpoints.Tablet,
                                            value: 'center',
                                          },
                                          width: [
                                            {
                                              minWidth: Breakpoints.Tablet,
                                              value: '50%',
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value: '100%',
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Bouton annulation */}
                                      <Button
                                        accessible={true}
                                        iconPosition={'left'}
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              setActivation_chargement(true);
                                              const retour_api = (
                                                await baseXanoSousAnnulPATCH.mutateAsync(
                                                  {
                                                    id_souscription:
                                                      props.route?.params
                                                        ?.id_sous ??
                                                      defaultProps.id_sous,
                                                  }
                                                )
                                              )?.json;
                                              if (!retour_api?.success) {
                                                setAffichage_erreur(true);
                                              }
                                              if (retour_api?.success) {
                                                if (navigation.canGoBack()) {
                                                  navigation.popToTop();
                                                }
                                                navigation.replace(
                                                  'AnnulationSouscriptionScreen'
                                                );
                                              }
                                              setActivation_chargement(false);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        {...GlobalStyles.ButtonStyles(theme)[
                                          'bouton_fond_blanc'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ButtonStyles(theme)[
                                              'bouton_fond_blanc'
                                            ].style,
                                            {
                                              borderColor:
                                                palettes.App.Internal_Hot_Red,
                                              borderWidth: 1,
                                              color:
                                                palettes.App.Internal_Hot_Red,
                                              fontSize: [
                                                {
                                                  minWidth: Breakpoints.Tablet,
                                                  value: 20,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 22,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 30,
                                                },
                                              ],
                                              height: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 40,
                                                },
                                                {
                                                  minWidth: Breakpoints.Desktop,
                                                  value: 45,
                                                },
                                                {
                                                  minWidth:
                                                    Breakpoints.BigScreen,
                                                  value: 50,
                                                },
                                              ],
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                        title={'Annuler ma souscription'}
                                      />
                                    </View>
                                  )}
                                </>
                                {/* Message d'erreur */}
                                <>
                                  {!affichage_erreur ? null : (
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'message_erreur'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'message_erreur'
                                          ].style,
                                          {
                                            fontSize: [
                                              {
                                                minWidth: Breakpoints.Desktop,
                                                value: 20,
                                              },
                                              {
                                                minWidth: Breakpoints.BigScreen,
                                                value: 24,
                                              },
                                            ],
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {
                                        "Une erreur s'est produite lors de l'annulation. Veuillez contacter l'équipe Flolend."
                                      }
                                    </Text>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </View>
                </>
              );
            }}
          </BaseXanoApi.FetchSousParIdGET>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(GererMaSouscriptionScreen);
