// Convertit un nombre entier en une chaîne de caractères écrite en lettres.
const nombreEnLettres = n => {
  const unites = [
    '',
    'un',
    'deux',
    'trois',
    'quatre',
    'cinq',
    'six',
    'sept',
    'huit',
    'neuf',
  ];
  const dizaines = [
    '',
    'dix',
    'vingt',
    'trente',
    'quarante',
    'cinquante',
    'soixante',
    'soixante-dix',
    'quatre-vingts',
    'quatre-vingt-dix',
  ];
  const speciaux = [
    '',
    'onze',
    'douze',
    'treize',
    'quatorze',
    'quinze',
    'seize',
    'dix-sept',
    'dix-huit',
    'dix-neuf',
  ];

  function convertirJusquA999(n) {
    if (n < 10) {
      return unites[n];
    } else if (n < 20) {
      return speciaux[n - 10];
    } else if (n < 100) {
      const dizaine = Math.floor(n / 10);
      const unite = n % 10;
      return dizaines[dizaine] + (unite !== 0 ? '-' + unites[unite] : '');
    } else {
      const centaine = Math.floor(n / 100);
      const reste = n % 100;
      const centaineTexte =
        (centaine > 1 ? unites[centaine] + ' ' : '') + 'cent';
      return (
        centaineTexte + (reste !== 0 ? ' ' + convertirJusquA999(reste) : '')
      );
    }
  }

  if (n < 1000) {
    return convertirJusquA999(n);
  } else if (n < 1000000) {
    const milliers = Math.floor(n / 1000);
    const reste = n % 1000;
    return (
      convertirJusquA999(milliers) +
      ' mille' +
      (reste !== 0 ? ' ' + convertirJusquA999(reste) : '')
    );
  } else {
    const millions = Math.floor(n / 1000000);
    const reste = n % 1000000;
    return (
      convertirJusquA999(millions) +
      ' million' +
      (millions > 1 ? 's' : '') +
      (reste !== 0 ? ' ' + convertirJusquA999(reste) : '')
    );
  }
};

export default nombreEnLettres;
