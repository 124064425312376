import React from 'react';
import {
  AccordionGroup,
  Icon,
  MultiSelectPicker,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const RessourcesClientScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [projets_client, setProjets_client] = React.useState([]);
  // Filtre les ressources par projets sélectionnés.
  const FiltrageRessources = (listeRessources, listeProjets) => {
    // Si la liste B est vide, renvoyer la liste A sans filtrage
    if (listeProjets.length === 0) {
      return listeRessources;
    }

    return listeRessources.filter(objet => {
      if (objet && typeof objet.id !== 'undefined') {
        return listeProjets.includes(objet.id);
      }
      return false;
    });
  };
  const baseXanoMajVuesRessourcePUT = BaseXanoApi.useMajVuesRessourcePUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', paddingLeft: 20 },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Mes ressources'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Retour */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                      .style,
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].props,
                      dimensions.width
                    )}
                    name={'AntDesign/arrowleft'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['texte_retour_ordi']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['texte_retour_ordi'].style,
                      dimensions.width
                    )}
                  >
                    {'Retour'}
                  </Text>
                </View>
              </Pressable>
            </View>
          )}
        </>
        {/* Vue globale mobile et tablette */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <View
              style={StyleSheet.applyWidth(
                { marginLeft: '5%', marginRight: '5%' },
                dimensions.width
              )}
            >
              {/* Fetch ressources bookmarked */}
              <BaseXanoApi.FetchRessourcesDuClientGET>
                {({ loading, error, data, refetchRessourcesDuClient }) => {
                  const fetchRessourcesBookmarkedData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      <MultiSelectPicker
                        autoDismissKeyboard={true}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        iconSize={24}
                        leftIconMode={'inset'}
                        onValueChange={newMultiSelectPickerValue => {
                          try {
                            setProjets_client(newMultiSelectPickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        selectedIconColor={theme.colors.text.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        dropDownBackgroundColor={
                          palettes.App.gris_encart_int_flolend
                        }
                        dropDownBorderColor={palettes.App.gris_fonce_flolend}
                        dropDownTextColor={palettes.App.blanc_flolend}
                        options={fetchRessourcesBookmarkedData?.picker}
                        placeholder={'Sélectionnez vos projets'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App.gris_encart_int_flolend,
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            marginBottom: 5,
                          },
                          dimensions.width
                        )}
                        value={projets_client}
                      />
                      {/* Mes ressources enregistrées */}
                      <AccordionGroup
                        caretSize={24}
                        iconSize={24}
                        {...GlobalStyles.AccordionGroupStyles(theme)[
                          'accordion_indicateurs'
                        ].props}
                        icon={'FontAwesome/bookmark'}
                        label={'Mes ressources enregistrées'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.AccordionGroupStyles(theme)[
                            'accordion_indicateurs'
                          ].style,
                          dimensions.width
                        )}
                      >
                        <SimpleStyleFlatList
                          data={FiltrageRessources(
                            fetchRessourcesBookmarkedData?.ressources_enregistrees,
                            projets_client
                          )}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'Qa6KesRa'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* List View Frame */}
                                <View>
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await baseXanoMajVuesRessourcePUT.mutateAsync(
                                              { id_ressource: listData?.id }
                                            )
                                          )?.json;
                                          await WebBrowser.openBrowserAsync(
                                            `${listData?.lien_ressource}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    {/* Record Frame */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'encart_publications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {/* Left Side Frame */}
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'left_side_publicataion'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)[
                                            'left_side_publicataion'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Top Row Frame */}
                                        <View>
                                          {/* Rubik Headline Style 18/24 Bold */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_encart_publications'
                                            ].props}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.titre_1}
                                          </Text>
                                        </View>
                                        {/* Second Row Frame */}
                                        <View>
                                          {/* Rubik Text Style 12/18 Regular */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'corps_encart_publications'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'corps_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Titre_2}
                                          </Text>
                                        </View>
                                        {/* Third Row Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'baseline',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Data Point */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'type_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'type_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.type_ressource}
                                          </Text>
                                          {/* Data Poiint */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'date_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'date_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Par '}
                                            {listData?.auteur}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Right Side Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: [
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 'center',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'center',
                                              },
                                            ],
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            justifyContent: 'center',
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                            width: '30%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Flex for Image */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'image_liste_publications'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ViewStyles(theme)[
                                              'image_liste_publications'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Image
                                            resizeMode={'cover'}
                                            source={imageSource(
                                              `${listData?.image_couv?.url}`
                                            )}
                                            style={StyleSheet.applyWidth(
                                              { height: '100%', width: '100%' },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </Touchable>
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          scrollEnabled={true}
                        />
                      </AccordionGroup>
                      {/* Mes autres ressources */}
                      <AccordionGroup
                        caretSize={24}
                        iconSize={24}
                        {...GlobalStyles.AccordionGroupStyles(theme)[
                          'accordion_indicateurs'
                        ].props}
                        expanded={true}
                        icon={'MaterialIcons/article'}
                        label={'Mes autres ressources'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.AccordionGroupStyles(theme)[
                            'accordion_indicateurs'
                          ].style,
                          dimensions.width
                        )}
                      >
                        <SimpleStyleFlatList
                          data={FiltrageRessources(
                            fetchRessourcesBookmarkedData?.autres_ressources,
                            projets_client
                          )}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'yfqKBgyb'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* List View Frame */}
                                <View>
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await baseXanoMajVuesRessourcePUT.mutateAsync(
                                              { id_ressource: listData?.id }
                                            )
                                          )?.json;
                                          await WebBrowser.openBrowserAsync(
                                            `${listData?.lien_ressource}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    {/* Record Frame */}
                                    <View
                                      {...GlobalStyles.ViewStyles(theme)[
                                        'encart_publications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.ViewStyles(theme)[
                                          'encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {/* Left Side Frame */}
                                      <View
                                        {...GlobalStyles.ViewStyles(theme)[
                                          'left_side_publicataion'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.ViewStyles(theme)[
                                            'left_side_publicataion'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {/* Top Row Frame */}
                                        <View>
                                          {/* Rubik Headline Style 18/24 Bold */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'titre_encart_publications'
                                            ].props}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'titre_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.titre_1}
                                          </Text>
                                        </View>
                                        {/* Second Row Frame */}
                                        <View>
                                          {/* Rubik Text Style 12/18 Regular */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'corps_encart_publications'
                                            ].props}
                                            ellipsizeMode={'tail'}
                                            numberOfLines={2}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'corps_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.Titre_2}
                                          </Text>
                                        </View>
                                        {/* Third Row Frame */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'baseline',
                                              flexDirection: 'row',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Data Point */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'type_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'type_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.type_ressource}
                                          </Text>
                                          {/* Data Poiint */}
                                          <Text
                                            accessible={true}
                                            selectable={false}
                                            {...GlobalStyles.TextStyles(theme)[
                                              'date_encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.TextStyles(theme)[
                                                'date_encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {'Par '}
                                            {listData?.auteur}
                                          </Text>
                                        </View>
                                      </View>
                                      {/* Right Side Frame */}
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: [
                                              {
                                                minWidth: Breakpoints.Tablet,
                                                value: 'center',
                                              },
                                              {
                                                minWidth: Breakpoints.Mobile,
                                                value: 'center',
                                              },
                                            ],
                                            flexGrow: 0,
                                            flexShrink: 0,
                                            justifyContent: 'center',
                                            paddingBottom: 12,
                                            paddingTop: 12,
                                            width: '30%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Flex for Image */}
                                        <View
                                          {...GlobalStyles.ViewStyles(theme)[
                                            'image_liste_publications'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.ViewStyles(theme)[
                                              'image_liste_publications'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          <Image
                                            resizeMode={'cover'}
                                            source={imageSource(
                                              `${listData?.image_couv?.url}`
                                            )}
                                            style={StyleSheet.applyWidth(
                                              { height: '100%', width: '100%' },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </Touchable>
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={true}
                          showsVerticalScrollIndicator={true}
                          scrollEnabled={true}
                        />
                      </AccordionGroup>
                    </>
                  );
                }}
              </BaseXanoApi.FetchRessourcesDuClientGET>
            </View>
          )}
        </>
        {/* Vue globale laptop et larger */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  marginLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
                  marginRight: { minWidth: Breakpoints.Laptop, value: '20%' },
                },
                dimensions.width
              )}
            >
              {/* Fetch ressources bookmarked */}
              <BaseXanoApi.FetchRessourcesDuClientGET>
                {({ loading, error, data, refetchRessourcesDuClient }) => {
                  const fetchRessourcesBookmarkedData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      <MultiSelectPicker
                        autoDismissKeyboard={true}
                        dropDownBorderRadius={8}
                        dropDownBorderWidth={1}
                        iconSize={24}
                        leftIconMode={'inset'}
                        onValueChange={newMultiSelectPickerValue => {
                          try {
                            setProjets_client(newMultiSelectPickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        selectedIconColor={theme.colors.text.strong}
                        selectedIconName={'Feather/check'}
                        selectedIconSize={20}
                        type={'solid'}
                        dropDownBackgroundColor={
                          palettes.App.gris_encart_int_flolend
                        }
                        dropDownBorderColor={palettes.App.gris_fonce_flolend}
                        dropDownTextColor={palettes.App.blanc_flolend}
                        options={fetchRessourcesBookmarkedData?.picker}
                        placeholder={'Sélectionnez vos projets'}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor:
                              palettes.App.gris_encart_int_flolend,
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 16 },
                              { minWidth: Breakpoints.Laptop, value: 14 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                              { minWidth: Breakpoints.BigScreen, value: 22 },
                            ],
                            marginBottom: 5,
                          },
                          dimensions.width
                        )}
                        value={projets_client}
                      />
                      {/* Ressources enregistrées */}
                      <>
                        {!(
                          fetchRessourcesBookmarkedData?.ressources_enregistrees
                            ?.length !== 0
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 24,
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '2%',
                                },
                                padding: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 30,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue liste */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '80%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Mes ressources enregistrées'}
                              </Text>
                              {/* Liste ressources enregistrées */}
                              <SimpleStyleFlatList
                                data={FiltrageRessources(
                                  fetchRessourcesBookmarkedData?.ressources_enregistrees,
                                  projets_client
                                )}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(
                                  listeRessourcesEnregistrEsData,
                                  index
                                ) =>
                                  listeRessourcesEnregistrEsData?.id ??
                                  listeRessourcesEnregistrEsData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeRessourcesEnregistrEsData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'aB9xki1m'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeRessourcesEnregistrEsData = item;
                                  return (
                                    <>
                                      {/* List View Frame */}
                                      <View>
                                        <Touchable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                (
                                                  await baseXanoMajVuesRessourcePUT.mutateAsync(
                                                    {
                                                      id_ressource:
                                                        listeRessourcesEnregistrEsData?.id,
                                                    }
                                                  )
                                                )?.json;
                                                await WebBrowser.openBrowserAsync(
                                                  `${listeRessourcesEnregistrEsData?.lien_ressource}`
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                        >
                                          {/* Record Frame */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Left Side Frame */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['left_side_publicataion'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'left_side_publicataion'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Top Row Frame */}
                                              <View>
                                                {/* Rubik Headline Style 18/24 Bold */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre_encart_publications']
                                                    .props}
                                                  numberOfLines={2}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'titre_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeRessourcesEnregistrEsData?.titre_1
                                                  }
                                                </Text>
                                              </View>
                                              {/* Second Row Frame */}
                                              <View>
                                                {/* Rubik Text Style 12/18 Regular */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['corps_encart_publications']
                                                    .props}
                                                  ellipsizeMode={'tail'}
                                                  numberOfLines={2}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'corps_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeRessourcesEnregistrEsData?.Titre_2
                                                  }
                                                </Text>
                                              </View>
                                              {/* Third Row Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'baseline',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Data Point */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['type_encart_publications']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'type_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeRessourcesEnregistrEsData?.type_ressource
                                                  }
                                                </Text>
                                                {/* Data Poiint */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['date_encart_publications']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'date_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Par '}
                                                  {
                                                    listeRessourcesEnregistrEsData?.auteur
                                                  }
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Right Side Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 'center',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 'center',
                                                    },
                                                  ],
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 12,
                                                  paddingTop: 12,
                                                  width: '30%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Flex for Image */}
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['image_liste_publications']
                                                  .props}
                                                style={StyleSheet.applyWidth(
                                                  GlobalStyles.ViewStyles(
                                                    theme
                                                  )['image_liste_publications']
                                                    .style,
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  source={imageSource(
                                                    `${listeRessourcesEnregistrEsData?.image_couv?.url}`
                                                  )}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: '100%',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </View>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                scrollEnabled={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        )}
                      </>
                      {/* Autres ressources */}
                      <>
                        {!(
                          fetchRessourcesBookmarkedData?.autres_ressources
                            ?.length !== 0
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                backgroundColor: {
                                  minWidth: Breakpoints.Laptop,
                                  value:
                                    palettes.App.flolend_gris_nuance_encart,
                                },
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 24,
                                },
                                marginTop: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '2%',
                                },
                                padding: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 30,
                                },
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue liste */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '80%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre1_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre1_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Mes autres ressources'}
                              </Text>
                              {/* Liste autres ressources */}
                              <SimpleStyleFlatList
                                data={FiltrageRessources(
                                  fetchRessourcesBookmarkedData?.autres_ressources,
                                  projets_client
                                )}
                                horizontal={false}
                                inverted={false}
                                keyExtractor={(
                                  listeAutresRessourcesData,
                                  index
                                ) =>
                                  listeAutresRessourcesData?.id ??
                                  listeAutresRessourcesData?.uuid ??
                                  index?.toString() ??
                                  JSON.stringify(listeAutresRessourcesData)
                                }
                                keyboardShouldPersistTaps={'never'}
                                listKey={'WnQQ8d5z'}
                                nestedScrollEnabled={false}
                                numColumns={1}
                                onEndReachedThreshold={0.5}
                                renderItem={({ item, index }) => {
                                  const listeAutresRessourcesData = item;
                                  return (
                                    <>
                                      {/* List View Frame */}
                                      <View>
                                        <Touchable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                (
                                                  await baseXanoMajVuesRessourcePUT.mutateAsync(
                                                    {
                                                      id_ressource:
                                                        listeAutresRessourcesData?.id,
                                                    }
                                                  )
                                                )?.json;
                                                await WebBrowser.openBrowserAsync(
                                                  `${listeAutresRessourcesData?.lien_ressource}`
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                        >
                                          {/* Record Frame */}
                                          <View
                                            {...GlobalStyles.ViewStyles(theme)[
                                              'encart_publications'
                                            ].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ViewStyles(theme)[
                                                'encart_publications'
                                              ].style,
                                              dimensions.width
                                            )}
                                          >
                                            {/* Left Side Frame */}
                                            <View
                                              {...GlobalStyles.ViewStyles(
                                                theme
                                              )['left_side_publicataion'].props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.ViewStyles(theme)[
                                                  'left_side_publicataion'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {/* Top Row Frame */}
                                              <View>
                                                {/* Rubik Headline Style 18/24 Bold */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['titre_encart_publications']
                                                    .props}
                                                  numberOfLines={2}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'titre_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeAutresRessourcesData?.titre_1
                                                  }
                                                </Text>
                                              </View>
                                              {/* Second Row Frame */}
                                              <View>
                                                {/* Rubik Text Style 12/18 Regular */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['corps_encart_publications']
                                                    .props}
                                                  ellipsizeMode={'tail'}
                                                  numberOfLines={2}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'corps_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeAutresRessourcesData?.Titre_2
                                                  }
                                                </Text>
                                              </View>
                                              {/* Third Row Frame */}
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'baseline',
                                                    flexDirection: 'row',
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                {/* Data Point */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['type_encart_publications']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'type_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {
                                                    listeAutresRessourcesData?.type_ressource
                                                  }
                                                </Text>
                                                {/* Data Poiint */}
                                                <Text
                                                  accessible={true}
                                                  selectable={false}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['date_encart_publications']
                                                    .props}
                                                  style={StyleSheet.applyWidth(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )[
                                                      'date_encart_publications'
                                                    ].style,
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Par '}
                                                  {
                                                    listeAutresRessourcesData?.auteur
                                                  }
                                                </Text>
                                              </View>
                                            </View>
                                            {/* Right Side Frame */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                {
                                                  alignItems: [
                                                    {
                                                      minWidth:
                                                        Breakpoints.Tablet,
                                                      value: 'center',
                                                    },
                                                    {
                                                      minWidth:
                                                        Breakpoints.Mobile,
                                                      value: 'center',
                                                    },
                                                  ],
                                                  flexGrow: 0,
                                                  flexShrink: 0,
                                                  justifyContent: 'center',
                                                  paddingBottom: 12,
                                                  paddingTop: 12,
                                                  width: '30%',
                                                },
                                                dimensions.width
                                              )}
                                            >
                                              {/* Flex for Image */}
                                              <View
                                                {...GlobalStyles.ViewStyles(
                                                  theme
                                                )['image_liste_publications']
                                                  .props}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ViewStyles(
                                                      theme
                                                    )[
                                                      'image_liste_publications'
                                                    ].style,
                                                    {
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                <Image
                                                  resizeMode={'cover'}
                                                  source={imageSource(
                                                    `${listeAutresRessourcesData?.image_couv?.url}`
                                                  )}
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      height: '100%',
                                                      width: '100%',
                                                    },
                                                    dimensions.width
                                                  )}
                                                />
                                              </View>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </View>
                                    </>
                                  );
                                }}
                                showsHorizontalScrollIndicator={true}
                                showsVerticalScrollIndicator={true}
                                scrollEnabled={true}
                                style={StyleSheet.applyWidth(
                                  {
                                    height: {
                                      minWidth: Breakpoints.Laptop,
                                      value: '100%',
                                    },
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                          </View>
                        )}
                      </>
                    </>
                  );
                }}
              </BaseXanoApi.FetchRessourcesDuClientGET>
            </View>
          )}
        </>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(RessourcesClientScreen);
