import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_projet: 1 };

const RessourcesProjetScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const baseXanoMajVuesRessourcePUT = BaseXanoApi.useMajVuesRessourcePUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', paddingLeft: 20 },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Les ressources associées au projet'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            maxHeight: dimensions.height,
            paddingBottom: 20,
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        <View>
          {/* Retour */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['header_classique'].style,
                  dimensions.width
                )}
              >
                <Pressable
                  onPress={() => {
                    try {
                      navigation.goBack();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                        .style,
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={24}
                      {...StyleSheet.applyWidth(
                        GlobalStyles.IconStyles(theme)['icone_retour_ordi']
                          .props,
                        dimensions.width
                      )}
                      name={'AntDesign/arrowleft'}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.IconStyles(theme)['icone_retour_ordi']
                          .style,
                        dimensions.width
                      )}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['texte_retour_ordi']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['texte_retour_ordi']
                          .style,
                        dimensions.width
                      )}
                    >
                      {'Retour'}
                    </Text>
                  </View>
                </Pressable>
              </View>
            )}
          </>
          {/* Ressources projet */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                marginLeft: [
                  { minWidth: Breakpoints.Laptop, value: '20%' },
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Laptop, value: '20%' },
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                ],
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
              },
              dimensions.width
            )}
          >
            {/* Screen Heading 2 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['screen_heading'].style,
                    dimensions.width
                  )}
                >
                  {'Les ressources associées au projet'}
                </Text>
              )}
            </>
            {/* Fetch ressources */}
            <BaseXanoApi.FetchRessourcesParProjetGET
              id_projet={
                props.route?.params?.id_projet ?? defaultProps.id_projet
              }
            >
              {({ loading, error, data, refetchRessourcesParProjet }) => {
                const fetchRessourcesData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <SimpleStyleFlatList
                    data={fetchRessourcesData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'iHcDr684'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* List View Frame */}
                          <View>
                            <Touchable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    (
                                      await baseXanoMajVuesRessourcePUT.mutateAsync(
                                        { id_ressource: listData?.id }
                                      )
                                    )?.json;
                                    await WebBrowser.openBrowserAsync(
                                      `${listData?.lien_ressource}`
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                            >
                              {/* Record Frame */}
                              <View
                                {...GlobalStyles.ViewStyles(theme)[
                                  'encart_publications'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.ViewStyles(theme)[
                                    'encart_publications'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {/* Left Side Frame */}
                                <View
                                  {...GlobalStyles.ViewStyles(theme)[
                                    'left_side_publicataion'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.ViewStyles(theme)[
                                      'left_side_publicataion'
                                    ].style,
                                    dimensions.width
                                  )}
                                >
                                  {/* Top Row Frame */}
                                  <View>
                                    {/* Rubik Headline Style 18/24 Bold */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'titre_encart_publications'
                                      ].props}
                                      numberOfLines={2}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'titre_encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.titre_1}
                                    </Text>
                                  </View>
                                  {/* Second Row Frame */}
                                  <View>
                                    {/* Rubik Text Style 12/18 Regular */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'corps_encart_publications'
                                      ].props}
                                      ellipsizeMode={'tail'}
                                      numberOfLines={2}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'corps_encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.Titre_2}
                                    </Text>
                                  </View>
                                  {/* Third Row Frame */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'baseline',
                                        flexDirection: 'row',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Data Point */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'type_encart_publications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'type_encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.type_ressource}
                                    </Text>
                                    {/* Data Poiint */}
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'date_encart_publications'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'date_encart_publications'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {'Par '}
                                      {listData?.auteur}
                                    </Text>
                                  </View>
                                </View>
                                {/* Right Side Frame */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexGrow: 0,
                                      flexShrink: 0,
                                      justifyContent: 'center',
                                      paddingBottom: 12,
                                      paddingTop: 12,
                                      width: '30%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Flex for Image */}
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'image_liste_publications'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.ViewStyles(theme)[
                                        'image_liste_publications'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      resizeMode={'cover'}
                                      source={imageSource(
                                        `${listData?.image_couv?.url}`
                                      )}
                                      style={StyleSheet.applyWidth(
                                        { height: '100%', width: '100%' },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  />
                );
              }}
            </BaseXanoApi.FetchRessourcesParProjetGET>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(RessourcesProjetScreen);
