import React from 'react';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as fenetre_web from '../custom-files/fenetre_web';
import * as webview_webkit from '../custom-files/webview_webkit';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  arrivee_pipe_invest: false,
  id_souscription: 1,
  id_transaction: 1,
  token_money_in: '361626233Ys5ILWhHdS2wV5Ak799LQpCmVv',
};

const PaiementParCarteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const baseXanoPaiementPATCH = BaseXanoApi.usePaiementPATCH();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={true}>
      <>
        {Platform.OS === 'web' ? null : (
          <WebView
            allowFileAccessFromFileURLs={false}
            allowUniversalAccessFromFileURLs={false}
            cacheEnabled={true}
            incognito={false}
            javaScriptEnabled={true}
            mediaPlaybackRequiresUserAction={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
            startInLoadingState={false}
            {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
            javaScriptCanOpenWindowsAutomatically={false}
            source={imageSource(
              `${Constants['webkit_racine']}${
                props.route?.params?.token_money_in ??
                defaultProps.token_money_in
              }&lang=fr`
            )}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.WebViewStyles(theme)['Web View'].style,
                { height: '100%', width: '100%' }
              ),
              dimensions.width
            )}
          />
        )}
      </>
      {/* Fetch statut transaction */}
      <BaseXanoApi.FetchStatutTransactionGET
        handlers={{
          onData: fetchStatutTransactionData => {
            const handler = async () => {
              try {
                if (!fetchStatutTransactionData?.paiement_fini) {
                  return;
                }
                if (
                  props.route?.params?.arrivee_pipe_invest ??
                  defaultProps.arrivee_pipe_invest
                ) {
                  if (
                    fetchStatutTransactionData?.statut_transaction === 'success'
                  ) {
                    const retour_api = (
                      await baseXanoPaiementPATCH.mutateAsync({
                        id_sous:
                          props.route?.params?.id_souscription ??
                          defaultProps.id_souscription,
                      })
                    )?.json;
                    if (retour_api?.succes_transaction) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('ValidationPaiementScreen', {
                        id_souscription:
                          props.route?.params?.id_souscription ??
                          defaultProps.id_souscription,
                      });
                    } else {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('ErreurSouscriptionScreen');
                    }
                  } else {
                    if (
                      fetchStatutTransactionData?.statut_transaction !==
                      'pending'
                    ) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('PaiementCarteAnnuleScreen');
                    }
                    if (
                      fetchStatutTransactionData?.statut_transaction ===
                      'pending'
                    ) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('PaiementCarteEnSuspensScreen', {
                        pipe_souscription: true,
                      });
                    }
                  }
                } else {
                  if (
                    fetchStatutTransactionData?.statut_transaction === 'success'
                  ) {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('ValidationApprovisionnementScreen');
                  }
                  if (
                    fetchStatutTransactionData?.statut_transaction !==
                    ('success' || 'pending')
                  ) {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('AnnulationApprovisionnementScreen');
                  }
                  if (
                    fetchStatutTransactionData?.statut_transaction === 'pending'
                  ) {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('PaiementCarteEnSuspensScreen', {
                      pipe_souscription: false,
                    });
                  }
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          },
        }}
        idSouscription={
          props.route?.params?.id_souscription ?? defaultProps.id_souscription
        }
        idTransaction={
          props.route?.params?.id_transaction ?? defaultProps.id_transaction
        }
        refetchInterval={3000}
      >
        {({ loading, error, data, refetchStatutTransaction }) => {
          const fetchStatutTransactionData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return null;
        }}
      </BaseXanoApi.FetchStatutTransactionGET>
      {/* Custom Code 2 */}
      <>
        {!(Platform.OS === 'web') ? null : (
          <Utils.CustomCodeErrorBoundary>
            <fenetre_web.Composant
              racine_webkit={Constants['webkit_racine']}
              token_webkit={
                props.route?.params?.token_money_in ??
                defaultProps.token_money_in
              }
            />
          </Utils.CustomCodeErrorBoundary>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(PaiementParCarteScreen);
