import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  LinearProgress,
  NumberInput,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import arrondiProduits from '../global-functions/arrondiProduits';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_transfert: 5 };

const Souscription1Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_bouton, setAffichage_bouton] = React.useState(false);
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [check1, setCheck1] = React.useState(false);
  const [check2, setCheck2] = React.useState(false);
  const [check3, setCheck3] = React.useState(false);
  const [check4, setCheck4] = React.useState(false);
  const [investisseur_averti, setInvestisseur_averti] = React.useState(false);
  const [limite_alerte_invest, setLimite_alerte_invest] = React.useState(0);
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [simulation_projet, setSimulation_projet] = React.useState(0);
  // Renvoie true si le bouton doit être bloqué.
  const bloquer_bouton = (
    montant_invest,
    montant_min_invest,
    montant_max_invest
  ) => {
    // Convertir le montant_invest en nombre si c'est une chaîne de caractères
    let montant = parseFloat(montant_invest);

    // Vérifier si le montant est NaN (Not a Number)
    if (isNaN(montant)) {
      return true;
    }

    // Vérifier si le montant est égal à 0
    if (montant === 0) {
      return true;
    }

    // Vérifier si le montant est strictement supérieur à montant_max_invest
    if (montant > montant_max_invest) {
      return true;
    }

    // Vérifier si le montant est strictement inférieur à montant_min_invest
    if (montant < montant_min_invest) {
      return true;
    }

    // Si aucune des conditions n'est remplie, retourner false
    return false;
  };

  // Calcule le nombre de titres souscrits
  const nbTitres = (montant_invest, valeurtitre) => {
    //Si l'input est NaN, renvoie 0
    if (isNaN(montant_invest)) {
      return 0;
    }

    // Calcule l'arrondi à l'entier inférieur du montant que veut investir le client divisé par la valeur d'un titre
    const arrondi = Math.floor(montant_invest / valeurtitre);

    return arrondi;
  };

  // Poste la souscription dans la base Airtable.
  const post_sous = async (id_client, id_projet, montant) => {
    const apiKey =
      'patTnyhlx9q1uOzB6.9947d51407877f20352b6cc565b385c99655f28c39342eb0545d725554af6cb1';
    const baseId = 'apprRcKudN6J0jkZj';
    const tableName = 'Souscriptions';

    const apiUrl = `https://api.airtable.com/v0/${baseId}/${tableName}`;
    const headers = new Headers({
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    });

    const recordData = {
      fields: {
        Client: [id_client],
        Projet: id_projet,
        montant_souscrit_euros: montant,
        statut: 'Bulletin à signer',
      },
      typecast: true,
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(recordData),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.id) {
          return data.id;
        } else {
          console.error("L'ID de la ligne n'a pas été renvoyé par Airtable.");
          return null;
        }
      } else {
        throw new Error(
          'Erreur lors de la création du record dans Airtable : ' +
            response.status
        );
      }
    } catch (error) {
      console.error(
        'Erreur lors de la création du record dans Airtable :',
        error
      );
      return null;
    }
  };
  const baseXanoCreationSousPOST = BaseXanoApi.useCreationSousPOST();
  const baseXanoCreationPreSouscriptionPOST =
    BaseXanoApi.useCreationPreSouscriptionPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const retour_api_cb = (await BaseXanoApi.conditionsSousCbGET(Constants))
          ?.json;
        setGlobalVariableValue({
          key: 'max_sous_cb',
          value: retour_api_cb?.max_cb_souscription_euros,
        });
        setGlobalVariableValue({
          key: 'min_sous_cb',
          value: retour_api_cb?.min_cb_souscription_euros,
        });
        const retour_api = (await BaseXanoApi.dossierInvestGET(Constants))
          ?.json;
        setLimite_alerte_invest(retour_api?.palier_alerte_montant_invest);
        setInvestisseur_averti(retour_api?.type_investisseur === 'averti');
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Fetch projet */}
      <BaseXanoApi.FetchProjetParIdGET
        id_projet_transfere={
          props.route?.params?.id_transfert ?? defaultProps.id_transfert
        }
      >
        {({ loading, error, data, refetchProjetParId }) => {
          const fetchProjetData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <SimpleStyleScrollView
              horizontal={false}
              keyboardShouldPersistTaps={'never'}
              nestedScrollEnabled={false}
              bounces={false}
              scrollEnabled={true}
              showsHorizontalScrollIndicator={false}
              showsVerticalScrollIndicator={false}
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'stretch',
                  height: '100%',
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-between',
                  },
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: '2%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                  marginTop: [
                    { minWidth: Breakpoints.Mobile, value: '2%' },
                    { minWidth: Breakpoints.Laptop, value: '0%' },
                  ],
                  paddingTop: [
                    {
                      minWidth: Breakpoints.Laptop,
                      value: Constants['hauteur_navbar_laptop'],
                    },
                    {
                      minWidth: Breakpoints.Desktop,
                      value: Constants['hauteur_navbar_desktop'],
                    },
                    {
                      minWidth: Breakpoints.BigScreen,
                      value: Constants['hauteur_navbar_big_screen'],
                    },
                  ],
                },
                dimensions.width
              )}
            >
              {/* masterview */}
              <View>
                {/* En-tête */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'stretch',
                      flexDirection: 'column',
                      marginLeft: [
                        { minWidth: Breakpoints.Laptop, value: '10%' },
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                      ],
                      marginRight: [
                        { minWidth: Breakpoints.Laptop, value: '10%' },
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                      ],
                      marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Mobile, value: '80%' },
                            { minWidth: Breakpoints.Laptop, value: '100%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre_pipe_invest']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre_pipe_invest']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Investir dans '}
                        {fetchProjetData?.Name}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <>
                      {dimensions.width >= Breakpoints.Laptop ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', width: '20%' },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                navigation.navigate('ProjetScreen', {
                                  id_transfere:
                                    props.route?.params?.id_transfert ??
                                    defaultProps.id_transfert,
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <Icon
                              color={palettes.App.gris_fonce_flolend}
                              name={'Ionicons/exit-outline'}
                              size={18}
                            />
                          </Touchable>
                        </View>
                      )}
                    </>
                  </View>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                        .style,
                      dimensions.width
                    )}
                  >
                    {'Etape 1/4 : Montant de la souscription'}
                  </Text>
                </View>
                {/* Bandeau progression 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: palettes.App.flolend_gris_nuance_encart,
                      height: 50,
                      justifyContent: 'space-evenly',
                      marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
                      marginTop: '2%',
                    },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        width: [
                          { minWidth: Breakpoints.Laptop, value: '80%' },
                          { minWidth: Breakpoints.Mobile, value: '90%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          width: '25%',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['frise_pipe_invest']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['frise_pipe_invest']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Montant de la souscription'}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'frise_pipe_invest_unselected'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Revue de la documentation'}
                      </Text>
                    </View>
                    {/* View 3 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'frise_pipe_invest_unselected'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Signature'}
                      </Text>
                    </View>
                    {/* View 4 */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '25%' },
                        dimensions.width
                      )}
                    >
                      {/* Text 4 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'frise_pipe_invest_unselected'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)[
                            'frise_pipe_invest_unselected'
                          ].style,
                          dimensions.width
                        )}
                      >
                        {'Paiement'}
                      </Text>
                    </View>
                  </View>
                  <LinearProgress
                    animationDuration={500}
                    color={theme.colors.branding.primary}
                    indeterminate={false}
                    isAnimated={true}
                    lineCap={'round'}
                    showTrack={true}
                    thickness={10}
                    trackColor={theme.colors.border.brand}
                    trackLineCap={'round'}
                    {...GlobalStyles.LinearProgressStyles(theme)[
                      'barre_pipe_invest'
                    ].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.LinearProgressStyles(theme)[
                        'barre_pipe_invest'
                      ].style,
                      dimensions.width
                    )}
                    value={25}
                  />
                </View>
                {/* Vue générale */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.flolend_gris_nuance_encart,
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                      marginLeft: [
                        { minWidth: Breakpoints.Laptop, value: '10%' },
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                      ],
                      marginRight: [
                        { minWidth: Breakpoints.Laptop, value: '10%' },
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                      ],
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: '2%' },
                        { minWidth: Breakpoints.Laptop, value: '0%' },
                      ],
                      padding: { minWidth: Breakpoints.Laptop, value: 30 },
                    },
                    dimensions.width
                  )}
                >
                  {/* Principe */}
                  <View>
                    {/* Heading */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['explications'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['explications'].style,
                        dimensions.width
                      )}
                    >
                      {
                        'Nous vous prions de bien vouloir entrer le montant que vous souhaitez investir et prendre connaissance des modalités de règlement.'
                      }
                    </Text>
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: {
                          minWidth: Breakpoints.Laptop,
                          value: 'row',
                        },
                        justifyContent: {
                          minWidth: Breakpoints.Laptop,
                          value: 'space-between',
                        },
                        marginTop: {
                          minWidth: Breakpoints.Laptop,
                          value: '2%',
                        },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Montant investi */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginTop: [
                            { minWidth: Breakpoints.Mobile, value: '5%' },
                            { minWidth: Breakpoints.Laptop, value: '0%' },
                          ],
                          paddingRight: {
                            minWidth: Breakpoints.Laptop,
                            value: '2%',
                          },
                          width: { minWidth: Breakpoints.Laptop, value: '50%' },
                        },
                        dimensions.width
                      )}
                    >
                      {/* En-tête */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: {
                              minWidth: Breakpoints.Laptop,
                              value: '2%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        {/* Heading */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['titre1_generique']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['titre1_generique']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Mon investissement'}
                        </Text>
                      </View>
                      {/* Consignes */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            marginBottom: 5,
                            marginTop: 5,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Montant minimum */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App.blanc_flolend,
                                fontFamily: 'PTSans_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                  { minWidth: Breakpoints.Laptop, value: 16 },
                                  { minWidth: Breakpoints.Desktop, value: 20 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Minimum : '}
                          {format_nombre(
                            fetchProjetData?.montant_min_invest_euros,
                            2
                          )}
                          {' €'}
                        </Text>
                        {/* Montant maximum */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: palettes.App.blanc_flolend,
                                fontFamily: 'PTSans_400Regular',
                                fontSize: [
                                  { minWidth: Breakpoints.Mobile, value: 14 },
                                  { minWidth: Breakpoints.Laptop, value: 16 },
                                  { minWidth: Breakpoints.Desktop, value: 20 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 24,
                                  },
                                ],
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Maximum : '}
                          {format_nombre(
                            fetchProjetData?.montant_max_investissable,
                            2
                          )}
                          {' €'}
                        </Text>
                      </View>
                      {/* Entrée montant */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            height: [
                              { minWidth: Breakpoints.Mobile, value: 46 },
                              { minWidth: Breakpoints.Desktop, value: 55 },
                              { minWidth: Breakpoints.BigScreen, value: 60 },
                            ],
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            { height: '100%', paddingRight: 5, width: '60%' },
                            dimensions.width
                          )}
                        >
                          {/* Montant investi */}
                          <NumberInput
                            changeTextDelay={500}
                            onChangeText={newMontantInvestiValue => {
                              const numberInputValue = newMontantInvestiValue;
                              try {
                                const value2PpzIgMw = newMontantInvestiValue;
                                setNumberInputValue(value2PpzIgMw);
                                const montant_de_l_invest = value2PpzIgMw;
                                const bouton_a_bloquer = bloquer_bouton(
                                  arrondiProduits(
                                    montant_de_l_invest,
                                    fetchProjetData?.valeur_un_titre_euros
                                  ),
                                  fetchProjetData?.montant_min_invest_euros,
                                  fetchProjetData?.montant_max_invest_euros
                                );
                                if (bouton_a_bloquer) {
                                  setAffichage_bouton(false);
                                } else {
                                  setAffichage_bouton(true);
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            {...GlobalStyles.NumberInputStyles(theme)[
                              'encart_input_simul'
                            ].props}
                            maxLength={8}
                            placeholder={'Je veux investir... (€)'}
                            selectionColor={palettes.App['Flolend 4']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.NumberInputStyles(theme)[
                                  'encart_input_simul'
                                ].style,
                                {
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 20,
                                    },
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: 24,
                                    },
                                  ],
                                  height: '100%',
                                  width: '100%',
                                }
                              ),
                              dimensions.width
                            )}
                            value={numberInputValue}
                          />
                        </View>

                        <View
                          style={StyleSheet.applyWidth(
                            { height: '100%', paddingLeft: 5, width: '40%' },
                            dimensions.width
                          )}
                        >
                          <LinearGradient
                            endX={100}
                            endY={100}
                            startX={0}
                            startY={0}
                            {...GlobalStyles.LinearGradientStyles(theme)[
                              'gradient_bouton'
                            ].props}
                            color1={palettes.App['Flolend 1']}
                            color2={palettes.App['Flolend 2']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinearGradientStyles(theme)[
                                  'gradient_bouton'
                                ].style,
                                {
                                  borderRadius: 15,
                                  flex: null,
                                  height: '100%',
                                  marginTop: 0,
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {/* bouton_echeancier */}
                            <Button
                              accessible={true}
                              iconPosition={'left'}
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'EcheancierProjetScreen',
                                    {
                                      montant_simul_euros: arrondiProduits(
                                        numberInputValue,
                                        fetchProjetData?.valeur_un_titre_euros
                                      ),
                                      id_projet:
                                        props.route?.params?.id_transfert ??
                                        defaultProps.id_transfert,
                                    }
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                              {...GlobalStyles.ButtonStyles(theme)[
                                'bouton_fond_blanc'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)[
                                    'bouton_fond_blanc'
                                  ].style,
                                  {
                                    fontFamily: 'PTSans_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 17,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 21,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 25,
                                      },
                                    ],
                                    marginBottom: 0,
                                    marginTop: 0,
                                  }
                                ),
                                dimensions.width
                              )}
                              title={'Simulation'}
                            />
                          </LinearGradient>
                        </View>
                      </View>
                      {/* Montant investi (hors prêts) */}
                      <>
                        {fetchProjetData?.Type_produit ===
                        'Prêts rémunérés' ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { marginTop: 10 },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { gap: 2.5 },
                                dimensions.width
                              )}
                            >
                              {/* Valeur 1 titre */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'PTSans_400Regular',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 24,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {"Valeur d'un titre : "}
                                {fetchProjetData?.valeur_un_titre_euros}
                                {' €'}
                              </Text>
                              {/* Nombre de titres */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App.blanc_flolend,
                                      fontFamily: 'PTSans_400Regular',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 14,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 16,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 20,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 24,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Titres souscrits : '}
                                {nbTitres(
                                  numberInputValue,
                                  fetchProjetData?.valeur_un_titre_euros
                                )}
                                {' titres'}
                              </Text>
                              {/* Investissement */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      color: palettes.App['Flolend 2'],
                                      fontFamily: 'PTSans_700Bold',
                                      fontSize: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 15,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 17,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 21,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 25,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {'Mon investissement : '}
                                {format_nombre(
                                  arrondiProduits(
                                    numberInputValue,
                                    fetchProjetData?.valeur_un_titre_euros
                                  ),
                                  2
                                )}
                                {' €'}
                              </Text>
                            </View>
                          </View>
                        )}
                      </>
                      {/* Montant investi (prêts) */}
                      <>
                        {!(
                          fetchProjetData?.Type_produit === 'Prêts rémunérés'
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'center',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'flex-start',
                                  },
                                ],
                                flexDirection: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'row',
                                  },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: 'column',
                                  },
                                ],
                                gap: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 10,
                                },
                                justifyContent: 'space-between',
                                marginTop: '2%',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Investissement */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.App['Flolend 2'],
                                    fontFamily: 'PTSans_700Bold',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 17,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 15,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 21,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 25,
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Mon investissement : '}
                              {format_nombre(
                                arrondiProduits(
                                  numberInputValue,
                                  fetchProjetData?.valeur_un_titre_euros
                                ),
                                2
                              )}
                              {' €'}
                            </Text>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Modalités de règlement */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginTop: [
                            { minWidth: Breakpoints.Laptop, value: '0%' },
                            { minWidth: Breakpoints.Mobile, value: 20 },
                          ],
                          paddingLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: '2%',
                          },
                          width: { minWidth: Breakpoints.Laptop, value: '50%' },
                        },
                        dimensions.width
                      )}
                    >
                      {/* Section Header */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '2%',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Heading */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['titre1_generique']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['titre1_generique']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Modalités de règlement'}
                        </Text>
                      </View>
                      {/* Content */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderColor: palettes.App['Flolend 1'],
                            borderRadius: 12,
                            borderWidth: 1,
                            paddingBottom: '5%',
                            paddingLeft: '5%',
                            paddingRight: '5%',
                            paddingTop: '5%',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['explications']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['explications']
                              .style,
                            dimensions.width
                          )}
                        >
                          {
                            "Vous pouvez investir même si les fonds ne sont pas disponibles sur votre livret ! C'est la date et l'heure de signature qui font foi. Vous avez ensuite 5 jours ouvrés pour effectuer votre paiement. En attendant, votre place sur le projet sera réservée."
                          }
                        </Text>
                      </View>
                      {/* Règlement CB */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'PTSans_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 16 },
                                { minWidth: Breakpoints.Desktop, value: 20 },
                                { minWidth: Breakpoints.BigScreen, value: 24 },
                              ],
                              marginTop: '2%',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Règlement par carte bancaire : de '}
                        {Constants['min_sous_cb']}
                        {' € à '}
                        {Constants['max_sous_cb']}
                        {' €.'}
                      </Text>
                      {/* Règlement virement */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'PTSans_400Regular',
                              fontSize: [
                                { minWidth: Breakpoints.Mobile, value: 14 },
                                { minWidth: Breakpoints.Laptop, value: 16 },
                                { minWidth: Breakpoints.Desktop, value: 20 },
                                { minWidth: Breakpoints.BigScreen, value: 24 },
                              ],
                              marginTop: '2%',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Règlement par virement : tous montants.'}
                      </Text>
                    </View>
                  </View>
                  {/* Chargement en cours 2 */}
                  <>
                    {!affichage_chargement ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: 10,
                            marginTop: 20,
                            paddingLeft: 20,
                          },
                          dimensions.width
                        )}
                      >
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          color={palettes.App['Flolend 4']}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            dimensions.width
                          )}
                        />
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)['corps_contenu']
                            .props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['corps_contenu']
                              .style,
                            dimensions.width
                          )}
                        >
                          {'Chargement en cours, veuillez patienter.'}
                        </Text>
                      </View>
                    )}
                  </>
                  <>
                    {affichage_chargement ? null : (
                      <LinearGradient
                        endX={100}
                        endY={100}
                        startX={0}
                        startY={0}
                        {...GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].props}
                        color1={
                          (affichage_bouton
                            ? palettes.App['Flolend 2']
                            : palettes.App.gris_fonce_flolend) ??
                          palettes.App['Flolend 2']
                        }
                        color2={
                          (affichage_bouton
                            ? palettes.App['Flolend 4']
                            : palettes.App.gris_fonce_flolend) ??
                          palettes.App['Flolend 4']
                        }
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinearGradientStyles(theme)[
                              'gradient_bouton'
                            ].style,
                            {
                              alignSelf: [
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'center',
                                },
                                {
                                  minWidth: Breakpoints.Tablet,
                                  value: 'center',
                                },
                              ],
                              borderRadius: {
                                minWidth: Breakpoints.Laptop,
                                value: 15,
                              },
                              flex: [
                                { minWidth: Breakpoints.Laptop, value: null },
                                { minWidth: Breakpoints.Tablet, value: null },
                                { minWidth: Breakpoints.Mobile, value: null },
                              ],
                              height: [
                                { minWidth: Breakpoints.Desktop, value: 60 },
                                { minWidth: Breakpoints.BigScreen, value: 70 },
                              ],
                              marginTop: [
                                { minWidth: Breakpoints.Mobile, value: '5%' },
                                { minWidth: Breakpoints.Laptop, value: '2%' },
                              ],
                              width: [
                                { minWidth: Breakpoints.Laptop, value: '50%' },
                                { minWidth: Breakpoints.Tablet, value: '50%' },
                                { minWidth: Breakpoints.Desktop, value: '40%' },
                                {
                                  minWidth: Breakpoints.BigScreen,
                                  value: '30%',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* Bouton investir */}
                        <Button
                          accessible={true}
                          iconPosition={'left'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                if (!affichage_bouton) {
                                  return;
                                }
                                setMessage_erreur('');
                                setAffichage_chargement(true);
                                const depassement = (
                                  await BaseXanoApi.testDepassementLimiteIndivGET(
                                    Constants,
                                    {
                                      id_projet:
                                        props.route?.params?.id_transfert ??
                                        defaultProps.id_transfert,
                                      montant_souscription: arrondiProduits(
                                        numberInputValue,
                                        fetchProjetData?.valeur_un_titre_euros
                                      ),
                                    }
                                  )
                                )?.json;
                                if (depassement) {
                                  setMessage_erreur(
                                    'Veuillez entrer un montant à investir entre le minimum et le maximum par participant sur le projet.'
                                  );
                                } else {
                                  if (
                                    arrondiProduits(
                                      numberInputValue,
                                      fetchProjetData?.valeur_un_titre_euros
                                    ) <= limite_alerte_invest ||
                                    investisseur_averti
                                  ) {
                                    if (
                                      fetchProjetData?.Statut === 'Pré-collecte'
                                    ) {
                                      (
                                        await baseXanoCreationPreSouscriptionPOST.mutateAsync(
                                          {
                                            id_projet:
                                              props.route?.params
                                                ?.id_transfert ??
                                              defaultProps.id_transfert,
                                            montant_souscrit_euros:
                                              arrondiProduits(
                                                numberInputValue,
                                                fetchProjetData?.valeur_un_titre_euros
                                              ),
                                          }
                                        )
                                      )?.json;
                                      navigation.navigate(
                                        'Souscription15ValidationPreSouscriptionScreen'
                                      );
                                    } else {
                                      if (
                                        arrondiProduits(
                                          numberInputValue,
                                          fetchProjetData?.valeur_un_titre_euros
                                        ) >
                                        fetchProjetData?.montant_max_investissable
                                      ) {
                                        navigation.navigate(
                                          'Souscription13ListeDattenteScreen',
                                          {
                                            montant_sous: arrondiProduits(
                                              numberInputValue,
                                              fetchProjetData?.valeur_un_titre_euros
                                            ),
                                            nom_projet: fetchProjetData?.Name,
                                            montant_restant_projet:
                                              fetchProjetData?.montant_max_investissable,
                                            id_transfer_1_1ter:
                                              props.route?.params
                                                ?.id_transfert ??
                                              defaultProps.id_transfert,
                                          }
                                        );
                                      } else {
                                        const retour_api = (
                                          await baseXanoCreationSousPOST.mutateAsync(
                                            {
                                              id_projet:
                                                props.route?.params
                                                  ?.id_transfert ??
                                                defaultProps.id_transfert,
                                              montant_souscrit: arrondiProduits(
                                                numberInputValue,
                                                fetchProjetData?.valeur_un_titre_euros
                                              ),
                                            }
                                          )
                                        )?.json;
                                        navigation.navigate(
                                          'Souscription2Screen',
                                          {
                                            id_souscription_airtable:
                                              retour_api?.id,
                                          }
                                        );
                                      }
                                    }
                                  } else {
                                    navigation.navigate(
                                      'Souscription12RisquesScreen',
                                      {
                                        montant_a_investir: arrondiProduits(
                                          numberInputValue,
                                          fetchProjetData?.valeur_un_titre_euros
                                        ),
                                        id_transfert_1_1bis:
                                          props.route?.params?.id_transfert ??
                                          defaultProps.id_transfert,
                                        nom_projet: fetchProjetData?.Name,
                                        statut_projet: fetchProjetData?.Statut,
                                        montant_max_investissable:
                                          fetchProjetData?.montant_max_investissable,
                                      }
                                    );
                                  }
                                }

                                setAffichage_chargement(false);
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          {...GlobalStyles.ButtonStyles(theme)[
                            'bouton_fond_blanc'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)[
                                'bouton_fond_blanc'
                              ].style,
                              {
                                borderColor: null,
                                borderWidth: 0,
                                fontSize: [
                                  { minWidth: Breakpoints.Laptop, value: 20 },
                                  { minWidth: Breakpoints.Desktop, value: 24 },
                                  {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 28,
                                  },
                                ],
                                marginBottom: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                marginTop: 0,
                              }
                            ),
                            dimensions.width
                          )}
                          title={'Investir'}
                        />
                      </LinearGradient>
                    )}
                  </>
                  {/* Message erreur */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['message_erreur'].style,
                      dimensions.width
                    )}
                  >
                    {message_erreur}
                  </Text>
                </View>
              </View>
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <FooterDesktopBlock />
                )}
              </>
            </SimpleStyleScrollView>
          );
        }}
      </BaseXanoApi.FetchProjetParIdGET>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(Souscription1Screen);
