import React from 'react';
import {
  Divider,
  Icon,
  ScreenContainer,
  SimpleStyleScrollView,
  Surface,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AssistanceScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
              paddingLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              paddingRight: { minWidth: Breakpoints.Laptop, value: '10%' },
            },
            dimensions.width
          )}
        >
          {/* Header Wrapper */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: [
                  { minWidth: Breakpoints.Mobile, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '5%' },
                ],
                justifyContent: 'center',
                marginBottom: { minWidth: Breakpoints.Laptop, value: '2%' },
                marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
              },
              dimensions.width
            )}
          >
            {/* Titre */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                dimensions.width
              )}
            >
              {'Conseil et assistance'}
            </Text>
          </View>

          <SimpleStyleScrollView
            bounces={true}
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            showsHorizontalScrollIndicator={true}
            showsVerticalScrollIndicator={true}
          >
            {/* vue horizontale ordinateur */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: [
                    { minWidth: Breakpoints.Desktop, value: 'row' },
                    { minWidth: Breakpoints.Laptop, value: 'row' },
                  ],
                  justifyContent: {
                    minWidth: Breakpoints.Laptop,
                    value: 'space-evenly',
                  },
                  width: { minWidth: Breakpoints.Laptop, value: '100%' },
                },
                dimensions.width
              )}
            >
              {/* Vue liste */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: [
                      { minWidth: Breakpoints.Mobile, value: 24 },
                      { minWidth: Breakpoints.Laptop, value: 0 },
                    ],
                    marginRight: [
                      { minWidth: Breakpoints.Laptop, value: 0 },
                      { minWidth: Breakpoints.Mobile, value: 24 },
                    ],
                    paddingRight: { minWidth: Breakpoints.Laptop, value: '1%' },
                    width: [
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                      { minWidth: Breakpoints.BigScreen, value: '35%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Qui sommes nous */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('QuiSommesNousScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Row Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 60,
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone d'encart */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialCommunityIcons/account-group'}
                        size={
                          !(dimensions.width >= Breakpoints.Laptop)
                            ? 18
                            : !(dimensions.width >= Breakpoints.Desktop)
                            ? 25
                            : dimensions.width >= Breakpoints.BigScreen
                            ? 35
                            : 30
                        }
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['option_assistance']
                          .props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['option_assistance']
                            .style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Qui sommes-nous ?'}
                      </Text>
                    </View>
                    {/* Right Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone chevron */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialIcons/chevron-right'}
                        size={
                          (dimensions.width >= Breakpoints.Desktop
                            ? dimensions.width >= Breakpoints.BigScreen
                              ? 30
                              : 25
                            : 18) ?? 24
                        }
                      />
                    </View>
                  </View>
                  <Divider
                    color={palettes.App.gris_fonce_flolend}
                    height={1}
                    style={StyleSheet.applyWidth(
                      { height: 1 },
                      dimensions.width
                    )}
                  />
                </Touchable>
                {/* Contacter un conseiller */}
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('NousContacterScreen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Row Wrapper */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            height: 60,
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Left Aligned */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              flexDirection: 'row',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icone d'encart */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/customerservice'}
                            size={
                              !(dimensions.width >= Breakpoints.Laptop)
                                ? 18
                                : !(dimensions.width >= Breakpoints.Desktop)
                                ? 25
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 35
                                : 30
                            }
                          />
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'option_assistance'
                            ].props}
                            ellipsizeMode={'tail'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)[
                                  'option_assistance'
                                ].style,
                                {
                                  fontSize: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 26,
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                            textBreakStrategy={'highQuality'}
                          >
                            {'Contacter un conseiller'}
                          </Text>
                        </View>
                        {/* Right Aligned */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* Icone chevron */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'MaterialIcons/chevron-right'}
                            size={
                              (dimensions.width >= Breakpoints.Desktop
                                ? dimensions.width >= Breakpoints.BigScreen
                                  ? 30
                                  : 25
                                : 18) ?? 24
                            }
                          />
                        </View>
                      </View>
                      <Divider
                        color={palettes.App.gris_fonce_flolend}
                        height={1}
                        style={StyleSheet.applyWidth(
                          { height: 1 },
                          dimensions.width
                        )}
                      />
                    </Touchable>
                  )}
                </>
                {/* Touchable Indicateurs */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('IndicateursDePerformanceScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Row Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 60,
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone d'encart */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'Ionicons/bar-chart'}
                        size={
                          !(dimensions.width >= Breakpoints.Laptop)
                            ? 18
                            : !(dimensions.width >= Breakpoints.Desktop)
                            ? 25
                            : dimensions.width >= Breakpoints.BigScreen
                            ? 35
                            : 30
                        }
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['option_assistance']
                          .props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['option_assistance']
                            .style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Indicateurs de performance'}
                      </Text>
                    </View>
                    {/* Row Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone chevron */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialIcons/chevron-right'}
                        size={
                          (dimensions.width >= Breakpoints.Desktop
                            ? dimensions.width >= Breakpoints.BigScreen
                              ? 30
                              : 25
                            : 18) ?? 24
                        }
                      />
                    </View>
                  </View>
                  <Divider
                    color={palettes.App.gris_fonce_flolend}
                    height={1}
                    style={StyleSheet.applyWidth(
                      { height: 1 },
                      dimensions.width
                    )}
                  />
                </Touchable>
                {/* Politiques et procédures */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('PolitiquesEtProceduresScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Row Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 60,
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone d'encart */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'AntDesign/book'}
                        size={
                          !(dimensions.width >= Breakpoints.Laptop)
                            ? 18
                            : !(dimensions.width >= Breakpoints.Desktop)
                            ? 25
                            : dimensions.width >= Breakpoints.BigScreen
                            ? 35
                            : 30
                        }
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['option_assistance']
                          .props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['option_assistance']
                            .style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Politiques et procédures'}
                      </Text>
                    </View>
                    {/* Right Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone chevron */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialIcons/chevron-right'}
                        size={
                          (dimensions.width >= Breakpoints.Desktop
                            ? dimensions.width >= Breakpoints.BigScreen
                              ? 30
                              : 25
                            : 18) ?? 24
                        }
                      />
                    </View>
                  </View>
                  <Divider
                    color={palettes.App.gris_fonce_flolend}
                    height={1}
                    style={StyleSheet.applyWidth(
                      { height: 1 },
                      dimensions.width
                    )}
                  />
                </Touchable>
                {/* Réclamation */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('ReclamationScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Row Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 60,
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone d'encart */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'AntDesign/exclamationcircleo'}
                        size={
                          !(dimensions.width >= Breakpoints.Laptop)
                            ? 18
                            : !(dimensions.width >= Breakpoints.Desktop)
                            ? 25
                            : dimensions.width >= Breakpoints.BigScreen
                            ? 35
                            : 30
                        }
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['option_assistance']
                          .props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['option_assistance']
                            .style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Déposer une réclamation'}
                      </Text>
                    </View>
                    {/* Right Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone chevron */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialIcons/chevron-right'}
                        size={
                          (dimensions.width >= Breakpoints.Desktop
                            ? dimensions.width >= Breakpoints.BigScreen
                              ? 30
                              : 25
                            : 18) ?? 24
                        }
                      />
                    </View>
                  </View>
                  <Divider
                    color={palettes.App.gris_fonce_flolend}
                    height={1}
                    style={StyleSheet.applyWidth(
                      { height: 1 },
                      dimensions.width
                    )}
                  />
                </Touchable>
                {/* Fiscalité */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('FiscaliteScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Row Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 60,
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone d'encart */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialCommunityIcons/bank-transfer-in'}
                        size={
                          !(dimensions.width >= Breakpoints.Laptop)
                            ? 18
                            : !(dimensions.width >= Breakpoints.Desktop)
                            ? 25
                            : dimensions.width >= Breakpoints.BigScreen
                            ? 35
                            : 30
                        }
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['option_assistance']
                          .props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['option_assistance']
                            .style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {'Fiscalité'}
                      </Text>
                    </View>
                    {/* Right Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone chevron */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialIcons/chevron-right'}
                        size={
                          (dimensions.width >= Breakpoints.Desktop
                            ? dimensions.width >= Breakpoints.BigScreen
                              ? 30
                              : 25
                            : 18) ?? 24
                        }
                      />
                    </View>
                  </View>
                  <Divider
                    color={palettes.App.gris_fonce_flolend}
                    height={1}
                    style={StyleSheet.applyWidth(
                      { height: 1 },
                      dimensions.width
                    )}
                  />
                </Touchable>
                {/* CGU */}
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('CGUScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Row Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        flexDirection: 'row',
                        height: 60,
                        justifyContent: 'space-between',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Left Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flex: 1, flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone d'encart */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'AntDesign/infocirlceo'}
                        size={
                          !(dimensions.width >= Breakpoints.Laptop)
                            ? 18
                            : !(dimensions.width >= Breakpoints.Desktop)
                            ? 25
                            : dimensions.width >= Breakpoints.BigScreen
                            ? 35
                            : 30
                        }
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['option_assistance']
                          .props}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['option_assistance']
                            .style,
                          dimensions.width
                        )}
                        textBreakStrategy={'highQuality'}
                      >
                        {"Conditions générales d'utilisation"}
                      </Text>
                    </View>
                    {/* Right Aligned */}
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      {/* Icone chevron */}
                      <Icon
                        color={palettes.App['Flolend 4']}
                        name={'MaterialIcons/chevron-right'}
                        size={
                          (dimensions.width >= Breakpoints.Desktop
                            ? dimensions.width >= Breakpoints.BigScreen
                              ? 30
                              : 25
                            : 18) ?? 24
                        }
                      />
                    </View>
                  </View>
                  <>
                    {!(Platform.OS === 'ios') ? null : (
                      <Divider
                        color={palettes.App.gris_fonce_flolend}
                        height={1}
                        style={StyleSheet.applyWidth(
                          { height: 1 },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </Touchable>
                {/* Supprimer compte */}
                <>
                  {!(Platform.OS === 'ios') ? null : (
                    <Touchable
                      onPress={() => {
                        try {
                          navigation.navigate('SupprimerMonCompte1Screen');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      {/* Row Wrapper */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            height: 60,
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Left Aligned */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flex: 1,
                              flexDirection: 'row',
                            },
                            dimensions.width
                          )}
                        >
                          {/* Icone d'encart */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'AntDesign/deleteuser'}
                            size={
                              !(dimensions.width >= Breakpoints.Laptop)
                                ? 18
                                : !(dimensions.width >= Breakpoints.Desktop)
                                ? 25
                                : dimensions.width >= Breakpoints.BigScreen
                                ? 35
                                : 30
                            }
                          />
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'option_assistance'
                            ].props}
                            ellipsizeMode={'tail'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'option_assistance'
                              ].style,
                              dimensions.width
                            )}
                            textBreakStrategy={'highQuality'}
                          >
                            {'Supprimer mon compte'}
                          </Text>
                        </View>
                        {/* Right Aligned */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'row' },
                            dimensions.width
                          )}
                        >
                          {/* Icone chevron */}
                          <Icon
                            color={palettes.App['Flolend 4']}
                            name={'MaterialIcons/chevron-right'}
                            size={
                              (dimensions.width >= Breakpoints.Desktop
                                ? dimensions.width >= Breakpoints.BigScreen
                                  ? 30
                                  : 25
                                : 18) ?? 24
                            }
                          />
                        </View>
                      </View>
                    </Touchable>
                  )}
                </>
              </View>
              {/* vue droite */}
              <>
                {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        paddingLeft: {
                          minWidth: Breakpoints.Laptop,
                          value: '1%',
                        },
                        width: { minWidth: Breakpoints.Laptop, value: '50%' },
                      },
                      dimensions.width
                    )}
                  >
                    {/* Fetch video */}
                    <BaseXanoApi.FetchArrierePlanDemarrageGET>
                      {({
                        loading,
                        error,
                        data,
                        refetchArrierePlanDemarrage,
                      }) => {
                        const fetchVideoData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <>
                            {/* Vue vidéo */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              <Surface
                                {...GlobalStyles.SurfaceStyles(theme)['Surface']
                                  .props}
                                elevation={3}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.SurfaceStyles(theme)['Surface']
                                      .style,
                                    {
                                      borderRadius: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 24,
                                      },
                                      height: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                      minHeight: {
                                        minWidth: Breakpoints.Laptop,
                                        value: null,
                                      },
                                      overflow: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'hidden',
                                      },
                                      width: {
                                        minWidth: Breakpoints.Laptop,
                                        value: '100%',
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                <VideoPlayer
                                  posterResizeMode={'cover'}
                                  rate={1}
                                  resizeMode={'cover'}
                                  volume={0.5}
                                  {...GlobalStyles.VideoPlayerStyles(theme)[
                                    'Video'
                                  ].props}
                                  isLooping={true}
                                  isMuted={true}
                                  shouldPlay={true}
                                  source={imageSource(`${fetchVideoData}`)}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.VideoPlayerStyles(theme)[
                                        'Video'
                                      ].style,
                                      {
                                        height: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '100%',
                                        },
                                        width: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '100%',
                                        },
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  useNativeControls={false}
                                  usePoster={false}
                                />
                              </Surface>
                            </View>
                          </>
                        );
                      }}
                    </BaseXanoApi.FetchArrierePlanDemarrageGET>
                  </View>
                )}
              </>
            </View>
          </SimpleStyleScrollView>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AssistanceScreen);
