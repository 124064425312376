import React from 'react';
import {
  Button,
  Icon,
  Picker,
  ScreenContainer,
  SimpleStyleScrollView,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const FiscaliteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [residence_FR, setResidence_FR] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      {/* mon_header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', paddingLeft: 20 },
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </View>
            </Touchable>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Fiscalité'}
            </Text>
          </View>
        )}
      </>
      {/* Scroll Content Frame */}
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: dimensions.height },
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'] + 20,
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'] + 20,
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'] + 10,
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Fetch doc gen */}
        <BaseXanoApi.FetchFormulaireDispenseGET>
          {({ loading, error, data, refetchFormulaireDispense }) => {
            const fetchDocGenData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* A propos */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: 5,
                      paddingBottom: [
                        { minWidth: Breakpoints.Mobile, value: 20 },
                        { minWidth: Breakpoints.Laptop, value: 0 },
                      ],
                      paddingLeft: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '15%' },
                      ],
                      paddingRight: [
                        { minWidth: Breakpoints.Mobile, value: '5%' },
                        { minWidth: Breakpoints.Laptop, value: '15%' },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                      dimensions.width
                    )}
                  >
                    {'Puis-je être exempté du prélèvement forfaitaire ?'}
                  </Text>
                  {/* Encart explicatif */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App.flolend_gris_nuance_encart,
                        borderRadius: 24,
                        gap: 15,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Contexte */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App.gris_encart_int_flolend,
                          borderRadius: 24,
                          padding: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['corps_contenu']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                          dimensions.width
                        )}
                      >
                        {
                          "Par son activité, Flolend est tenue de prélever pour le compte de la Direction générale des finances publiques l'impôt sur le revenu (IR) et la contribution sociale généralisé (CSG) sur les plus values générées par vos investissements sur l'application."
                        }
                      </Text>
                    </View>
                    {/* Contexte 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App.gris_encart_int_flolend,
                          borderRadius: 24,
                          padding: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['corps_contenu']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                          dimensions.width
                        )}
                      >
                        {
                          "Selon votre situation, vous pouvez être dispensé de ce prélèvement. Vos revenus resteront toutefois auto-transmis aux services des impôts français et pourront être soumis à l'impôt sur les revenus après déclaration en fonction de la situation de votre foyer. Il s'agit donc ici d'une dispense de prélèvement à la source et non d'une exonération."
                        }
                      </Text>
                    </View>
                  </View>
                  {/* Fetch client */}
                  <BaseXanoApi.FetchAuthGET>
                    {({ loading, error, data, refetchAuth }) => {
                      const fetchClientData = data?.json;
                      if (loading) {
                        return <ActivityIndicator />;
                      }

                      if (error || data?.status < 200 || data?.status >= 300) {
                        return <ActivityIndicator />;
                      }

                      return (
                        <>
                          {/* Situation */}
                          <View
                            style={StyleSheet.applyWidth(
                              { gap: 5, marginBottom: 5, marginTop: 5 },
                              dimensions.width
                            )}
                          >
                            {/* Résidence */}
                            <View>
                              {/* Label */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'titre_2_generique'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'titre_2_generique'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {'Etes-vous résident fiscal Français ?'}
                              </Text>
                              {/* Picker residence */}
                              <Picker
                                autoDismissKeyboard={true}
                                dropDownBackgroundColor={
                                  theme.colors.background.brand
                                }
                                dropDownBorderColor={theme.colors.border.brand}
                                dropDownBorderRadius={8}
                                dropDownBorderWidth={1}
                                dropDownTextColor={theme.colors.text.strong}
                                iconSize={24}
                                leftIconMode={'inset'}
                                mode={'native'}
                                onValueChange={newPickerResidenceValue => {
                                  try {
                                    const valueN5Ce1PRA =
                                      newPickerResidenceValue;
                                    setResidence_FR(valueN5Ce1PRA);
                                    const retour = valueN5Ce1PRA;
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                selectedIconColor={theme.colors.text.strong}
                                selectedIconName={'Feather/check'}
                                selectedIconSize={20}
                                type={'solid'}
                                {...GlobalStyles.PickerStyles(theme)[
                                  'picker_test_connaissances'
                                ].props}
                                options={[
                                  { label: 'Oui', value: true },
                                  { label: 'Non', value: false },
                                ]}
                                placeholder={'Sélectionnez une option'}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.PickerStyles(theme)[
                                    'picker_test_connaissances'
                                  ].style,
                                  dimensions.width
                                )}
                                value={residence_FR}
                              />
                            </View>
                          </View>
                          {/* Dispense - cas général */}
                          <>
                            {!(residence_FR === true) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App.flolend_gris_nuance_encart,
                                    borderRadius: 24,
                                    gap: 15,
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* interets */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App.gris_encart_int_flolend,
                                      borderRadius: 24,
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "Vous pouvez être dispensé du prélèvement forfaitaire obligatoire non libératoire de 12,8 %, acquitté au titre de l'impôt sur le revenu au moment du versement des intérêts, si votre revenu fiscal de référence pour l'année N-2 était inférieur à 25 000 € pour une personne seule ou 50 000 € pour un couple (mariage ou PACS)."
                                    }
                                  </Text>
                                </View>
                                {/* dividendes */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App.gris_encart_int_flolend,
                                      borderRadius: 24,
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "Vous pouvez être dispensé du prélèvement forfaitaire obligatoire non libératoire de 12,8 %, acquitté au titre de l'impôt sur le revenu au moment du versement des dividendes si votre revenu fiscal de référence de l’avant-dernière année était inférieur à 50 000 € (personne seule) ou 75 000 € si vous vivez en couple (mariage ou PACS)."
                                    }
                                  </Text>
                                </View>
                                {/* delai */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App.gris_encart_int_flolend,
                                      borderRadius: 24,
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "La demande de dispense du prélèvement forfaitaire obligatoire non libératoire de 12,8 % (acquitté au titre de l'impôt sur le revenu au moment du versement des revenus) doit être formulée avant le 30/11 de l'année précédant celle du paiement de ces revenus.\n\nAinsi, pour bénéficier de la dispense de prélèvement forfaitaire obligatoire de 12,8 % opéré sur les revenus versés au cours de l'année 2024, la demande de dispense devait être formulée avant le 30/11/2023."
                                    }
                                  </Text>
                                </View>
                                {/* formulaire */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App.gris_encart_int_flolend,
                                      borderRadius: 24,
                                      gap: 5,
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      "Pour demander votre dispense, veuillez compléter le formulaire téléchargeable ci-dessous et le renvoyer à l'adresse contact@flolend.com."
                                    }
                                  </Text>
                                  <Button
                                    accessible={true}
                                    iconPosition={'left'}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await WebBrowser.openBrowserAsync(
                                            `${fetchDocGenData?.url_formulaire}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    {...GlobalStyles.ButtonStyles(theme)[
                                      'Button'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ].style,
                                        {
                                          backgroundColor:
                                            palettes.App['Flolend 1'],
                                          color: palettes.App.blanc_flolend,
                                          fontFamily: 'Montserrat_700Bold',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'Formulaire'}
                                  />
                                </View>
                                {/* message */}
                                <>
                                  {!!(
                                    fetchClientData?.pays_residence === 'FRA'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App
                                              .gris_encart_int_flolend,
                                          borderRadius: 24,
                                          padding: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 3 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'Attention, vous êtes à ce jour enregistré comme résident fiscal étranger. Veuillez contacter nos services à contact@flolend.com pour mettre à jour votre situation.'
                                        }
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                          {/* Dispense - résidence fiscale étranger */}
                          <>
                            {!(residence_FR === false) ? null : (
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor:
                                      palettes.App.flolend_gris_nuance_encart,
                                    borderRadius: 24,
                                    gap: 15,
                                    padding: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* dispense */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App.gris_encart_int_flolend,
                                      borderRadius: 24,
                                      padding: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Text 3 */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'corps_contenu'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'corps_contenu'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {
                                      'Dans ce cas, vous serez exempté à la fois des prélèvements sociaux CSG/CRDS et de l’impôt sur le revenu. Il vous faudra déclarer ces revenus dans votre pays de résidence. Notez que pour effectuer un changement de résidence fiscale, vous devez faire une demande au service client de Flolend à contact.flolend.com.'
                                    }
                                  </Text>
                                </View>
                                {/* message */}
                                <>
                                  {!(
                                    fetchClientData?.pays_residence === 'FRA'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          backgroundColor:
                                            palettes.App
                                              .gris_encart_int_flolend,
                                          borderRadius: 24,
                                          padding: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Text 3 */}
                                      <Text
                                        accessible={true}
                                        selectable={false}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'corps_contenu'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.TextStyles(theme)[
                                            'corps_contenu'
                                          ].style,
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          'Attention, vous êtes à ce jour enregistré comme résident fiscal français. Veuillez contacter nos services à contact@flolend.com pour mettre à jour votre situation.'
                                        }
                                      </Text>
                                    </View>
                                  )}
                                </>
                              </View>
                            )}
                          </>
                        </>
                      );
                    }}
                  </BaseXanoApi.FetchAuthGET>
                </View>
              </>
            );
          }}
        </BaseXanoApi.FetchFormulaireDispenseGET>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(FiscaliteScreen);
