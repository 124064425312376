import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Platform, ActivityIndicator } from 'react-native';
import { WebView } from 'react-native-webview';

const WebScreen = ({ navigation, route, auth_header }) => {
  const [loading, setLoading] = useState(true);

  //////////////////// VALEURS REELLES, SI EFFECTIVES COMMENCER LES TESTS DEPUIS "ALIMENTATION PAR CARTE" \\\\\\\\\\\\\\\\\\\\
  // const { id_transaction, id_souscription, arrivee_pipe_invest } = route.params;
  ////////////////////\\\\\\\\\\\\\\\\\\\\

  ////////////////////// VALEURS DE TESTS \\\\\\\\\\\\\\\\\\\\
  const id_transaction = 13;
  const id_souscription = 1;
  const arrivee_pipe_invest = false;
  ////////////////////\\\\\\\\\\\\\\\\\\\\

  if (
    id_transaction === undefined ||
    id_souscription === undefined ||
    arrivee_pipe_invest === undefined ||
    auth_header === undefined
  ) {
    navigation.navigate('PaiementCarteAnnuleScreen');
    return null; // Stop rendering this component
  }

  const handleRedirection = (arrivee_pipe_invest, statut_transaction) => {
    try {
      if (arrivee_pipe_invest) {
        switch (statut_transaction) {
          case 'success':
            navigation.navigate('ValidationPaiementScreen');
            break;
          case 'pending':
            navigation.navigate('PaiementCarteEnSuspensScreen');
            break;
          default:
            navigation.navigate('PaiementCarteAnnuleScreen');
            break;
        }
      } else {
        switch (statut_transaction) {
          case 'success':
            navigation.navigate('ValidationApprovisionnementScreen');
            break;
          case 'pending':
            navigation.navigate('PaiementCarteEnSuspensScreen');
            break;
          default:
            navigation.navigate('AnnulationApprovisionnementScreen');
            break;
        }
      }
    } catch (error) {
      console.error('Error in handleRedirection:', error);
    }
  };

  const startPollingProcess = () => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(
          `https://x74p-9may-besb.p7.xano.io/api:vchAW8R-/lemonway/statut_transaction?id_transaction=${id_transaction}&id_souscription=${id_souscription}`,
          {
            method: 'GET',
            headers: {
              Authorization: auth_header,
              'Content-Type': 'application/json',
            },
          }
        );
        const data = await response.json();
        const statut_transaction = data.statut_transaction;
        if (data.paiement_fini) {
          clearInterval(intervalId); // Arrêt du polling pour le webhook
          handleRedirection(arrivee_pipe_invest, statut_transaction);
        }
      } catch (error) {
        console.error('Error polling for webhook confirmation:', error);
      }
    }, 2000); // Polling toutes les 2 secondes

    return () => clearInterval(intervalId); // Nettoyage de l'intervalle lors du démontage
  };

  useEffect(() => {
    startPollingProcess(); // Démarrage du processus de polling pour les plateformes web et mobile.
  }, [navigation]);

  return (
    <View style={styles.container}>
      {loading && <ActivityIndicator size="large" color="#0000ff" />}
      {Platform.OS === 'web' ? (
        <iframe
          id="webFrame"
          src="https://data.flolend.com/mdp_oublie"
          style={{ width: '100vw', height: '100vh', border: 'none' }}
        ></iframe>
      ) : (
        <WebView
          source={{ uri: 'https://data.flolend.com/mdp_oublie' }}
          style={styles.webView}
          onLoadEnd={() => setLoading(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  webView: {
    flex: 1,
  },
});

export { WebScreen };
