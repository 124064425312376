import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  NumberInput,
  Pressable,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const AlimentationParCarteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activation_chargement, setActivation_chargement] =
    React.useState(false);
  const [bouton_actif, setBouton_actif] = React.useState(false);
  const [display_error, setDisplay_error] = React.useState(false);
  const [max_carte_euros, setMax_carte_euros] = React.useState(1000);
  const [message_erreur, setMessage_erreur] = React.useState('');
  const [numberInputValue, setNumberInputValue] = React.useState('');
  // Arrondit l'input de l'utilisateur au centime près. Renvoie la valeur en euros.
  const arrondi_input = input_euros => {
    // Calcule l'arrondi au centime inférieur du montant que veut virer l'utilisateur
    return (arrondi = Math.floor(input_euros * 100) / 100);
  };

  // Renvoie le montant en euros fourni en entrée en centimes d'euros
  const retour_centimes = montant_euros => {
    // Calcule l'arrondi au centime inférieur du montant que veut virer l'utilisateur
    const montant_centimes = montant_euros * 100;

    return montant_centimes;
  };

  // Fait la somme des paramètres fournis en entrée
  const somme = (param1, param2) => {
    return param1 + param2;
  };

  // Renvoie faux si le paramètre vaut NaN ou "", et vrai à défaut.
  const validation_encart = parametre => {
    if (parametre === '' || isNaN(parametre)) {
      return false;
    }
    return true;
  };
  const baseXanoPaiement$tokenPaiementPOST =
    BaseXanoApi.usePaiement$tokenPaiementPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        reset_navbar(Variables, setGlobalVariableValue);
        const ressource = (await BaseXanoApi.maxAlimCbGET(Constants))?.json;
        setMax_carte_euros(ressource?.max);
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            justifyContent: {
              minWidth: Breakpoints.Laptop,
              value: 'space-between',
            },
            maxHeight: {
              minWidth: Breakpoints.Laptop,
              value: dimensions.height,
            },
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* masterview */}
        <View
          style={StyleSheet.applyWidth(
            {
              gap: { minWidth: Breakpoints.Laptop, value: 20 },
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: '5%' },
                { minWidth: Breakpoints.Laptop, value: '10%' },
              ],
            },
            dimensions.width
          )}
        >
          {/* mon_header */}
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                { paddingLeft: null }
              ),
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                  .props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                    .style,
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  {...StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icone_retour_ordi'].props,
                    dimensions.width
                  )}
                  name={'AntDesign/arrowleft'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.IconStyles(theme)['icone_retour_ordi'].style,
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['texte_retour_ordi'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['texte_retour_ordi'].style,
                    dimensions.width
                  )}
                >
                  {'Recharge par carte'}
                </Text>
              </View>
            </Pressable>
          </View>
          {/* vue */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
                gap: 10,
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
              },
              dimensions.width
            )}
          >
            {/* Livret */}
            <View
              style={StyleSheet.applyWidth(
                {
                  marginLeft: { minWidth: Breakpoints.Laptop, value: '0%' },
                  marginRight: { minWidth: Breakpoints.Laptop, value: '0%' },
                  paddingRight: { minWidth: Breakpoints.Laptop, value: '1%' },
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              {/* Fetch client */}
              <BaseXanoApi.FetchAuthGET>
                {({ loading, error, data, refetchAuth }) => {
                  const fetchClientData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: palettes.App['Flolend 1'],
                          borderRadius: 12,
                          height: 220,
                          justifyContent: 'space-between',
                          paddingBottom: 24,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 24,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'livret_flolend_titre'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'livret_flolend_titre'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'Livret Flolend'}
                        </Text>
                        <Image
                          resizeMode={'contain'}
                          source={imageSource(Images['LogoWireframeLight'])}
                          style={StyleSheet.applyWidth(
                            { height: 40, width: 40 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View>
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'livret_flolend_solde_titre'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'livret_flolend_solde_titre'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'Solde'}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              gap: 10,
                              marginTop: 6,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'livret_flolend_solde'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'livret_flolend_solde'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {format_nombre(
                              conversion_solde_wallet(
                                fetchClientData?.wallet_lemonway?.compte_client
                                  ?.balance
                              ),
                              2
                            )}
                            {' €'}
                          </Text>
                          <>
                            {!validation_encart(numberInputValue) ? null : (
                              <Icon
                                color={palettes.App.blanc_flolend}
                                name={'AntDesign/arrowright'}
                                size={
                                  (!(dimensions.width >= Breakpoints.Desktop)
                                    ? 18
                                    : dimensions.width >= Breakpoints.BigScreen
                                    ? 30
                                    : 25) ?? 18
                                }
                              />
                            )}
                          </>
                          {/* Text 2 */}
                          <>
                            {!validation_encart(numberInputValue) ? null : (
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'texte_simple'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['texte_simple']
                                    .style,
                                  dimensions.width
                                )}
                              >
                                {format_nombre(
                                  somme(
                                    conversion_solde_wallet(
                                      fetchClientData?.wallet_lemonway
                                        ?.compte_client?.balance
                                    ),
                                    arrondi_input(numberInputValue)
                                  ),
                                  2
                                )}
                                {' €'}
                              </Text>
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  );
                }}
              </BaseXanoApi.FetchAuthGET>
            </View>
            {/* Vue de droite */}
            <View
              style={StyleSheet.applyWidth(
                {
                  gap: 10,
                  paddingLeft: { minWidth: Breakpoints.Laptop, value: '1%' },
                  width: { minWidth: Breakpoints.Laptop, value: '50%' },
                },
                dimensions.width
              )}
            >
              {/* Explications */}
              <View>
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['explications'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['explications'].style,
                    dimensions.width
                  )}
                >
                  {
                    "Pour alimenter votre livret Flolend, entrez le montant que vous souhaitez verser dans l'encart ci-dessous. Pour les versements supérieurs à "
                  }
                  {format_nombre(max_carte_euros, 0)}
                  {' €, veuillez alimenter votre compte par virement.'}
                </Text>
              </View>
              {/* Entrée montant */}
              <View
                style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}
              >
                {/* Montant approvisionnement */}
                <NumberInput
                  changeTextDelay={500}
                  onChangeText={newMontantApprovisionnementValue => {
                    const numberInputValue = newMontantApprovisionnementValue;
                    try {
                      setNumberInputValue(newMontantApprovisionnementValue);
                      /* hidden 'Log to Console' action */
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  webShowOutline={true}
                  {...GlobalStyles.NumberInputStyles(theme)[
                    'encart_input_simul'
                  ].props}
                  placeholder={'Je veux recharger mon livret avec... (€)'}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.NumberInputStyles(theme)['encart_input_simul']
                      .style,
                    dimensions.width
                  )}
                  value={numberInputValue}
                />
                {/* Récapitulatif */}
                <View
                  style={StyleSheet.applyWidth({ gap: 10 }, dimensions.width)}
                >
                  {/* intro_recap */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['texte_simple'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['texte_simple'].style,
                        {
                          color: validation_encart(numberInputValue)
                            ? palettes.App.blanc_flolend
                            : palettes.App.Transparent,
                          fontSize: {
                            minWidth: Breakpoints.BigScreen,
                            value: 22,
                          },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Je souhaite recharger mon livret Flolend avec :'}
                  </Text>
                  {/* montant_recap */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.blanc_flolend,
                            },
                            {
                              minWidth: Breakpoints.Mobile,
                              value: validation_encart(numberInputValue)
                                ? palettes.App.blanc_flolend
                                : palettes.App.Transparent,
                            },
                          ],
                          fontFamily: 'PTSans_700Bold',
                          fontSize: [
                            { minWidth: Breakpoints.BigScreen, value: 28 },
                            { minWidth: Breakpoints.Desktop, value: 24 },
                            { minWidth: Breakpoints.Mobile, value: 20 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {format_nombre(arrondi_input(numberInputValue), 2)}
                    {' euros'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          {/* Navigation laptop or larger */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: 'center',
                    width: { minWidth: Breakpoints.Laptop, value: '50%' },
                  },
                  dimensions.width
                )}
              >
                <>
                  {activation_chargement ? null : (
                    <LinearGradient
                      endX={100}
                      endY={100}
                      startX={0}
                      startY={0}
                      {...GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].props}
                      color1={
                        (!validation_encart(numberInputValue)
                          ? palettes.App.gris_fonce_flolend
                          : palettes.App['Flolend 2']) ??
                        palettes.App['Flolend 2']
                      }
                      color2={
                        (!validation_encart(numberInputValue)
                          ? palettes.App.gris_fonce_flolend
                          : palettes.App['Flolend 4']) ??
                        palettes.App['Flolend 4']
                      }
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.LinearGradientStyles(theme)[
                            'gradient_bouton'
                          ].style,
                          {
                            alignSelf: {
                              minWidth: Breakpoints.Tablet,
                              value: 'center',
                            },
                            borderRadius: {
                              minWidth: Breakpoints.Laptop,
                              value: 15,
                            },
                            flex: [
                              { minWidth: Breakpoints.Tablet, value: null },
                              { minWidth: Breakpoints.Mobile, value: null },
                            ],
                            height: [
                              { minWidth: Breakpoints.BigScreen, value: 70 },
                              { minWidth: Breakpoints.Desktop, value: 65 },
                              { minWidth: Breakpoints.Laptop, value: 60 },
                            ],
                            justifyContent: 'center',
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: 0,
                            },
                            marginTop: null,
                            width: [
                              { minWidth: Breakpoints.Laptop, value: '75%' },
                              { minWidth: Breakpoints.Desktop, value: '60%' },
                              { minWidth: Breakpoints.BigScreen, value: '50%' },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Bouton continuer actif */}
                      <Button
                        accessible={true}
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              if (!validation_encart(numberInputValue)) {
                                return;
                              }
                              setActivation_chargement(true);
                              setDisplay_error(false);
                              if (
                                arrondi_input(numberInputValue) >
                                max_carte_euros
                              ) {
                                setMessage_erreur(
                                  `Seuls les virements sont autorisés pour les approvisionnements de plus de ${format_nombre(
                                    max_carte_euros,
                                    0
                                  )} €`
                                );
                                setDisplay_error(true);
                              } else {
                                const retour_api = (
                                  await baseXanoPaiement$tokenPaiementPOST.mutateAsync(
                                    {
                                      montant: retour_centimes(
                                        arrondi_input(numberInputValue)
                                      ),
                                    }
                                  )
                                )?.json;
                                if (retour_api?.success) {
                                  navigation.navigate(
                                    'PaiementParCarteScreen',
                                    {
                                      token_money_in:
                                        retour_api?.token_paiement,
                                      arrivee_pipe_invest: false,
                                      id_transaction:
                                        retour_api?.id_transaction,
                                    }
                                  );
                                }
                                if (!retour_api?.success) {
                                  setMessage_erreur(retour_api?.message_erreur);
                                }
                                if (!retour_api?.success) {
                                  setDisplay_error(true);
                                }
                              }

                              setActivation_chargement(false);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        {...GlobalStyles.ButtonStyles(theme)[
                          'bouton_fond_blanc'
                        ].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)[
                              'bouton_fond_blanc'
                            ].style,
                            {
                              borderWidth: 0,
                              fontSize: [
                                { minWidth: Breakpoints.Laptop, value: 22 },
                                { minWidth: Breakpoints.BigScreen, value: 30 },
                              ],
                              marginBottom: 0,
                              marginTop: 0,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Continuer'}
                      />
                    </LinearGradient>
                  )}
                </>
                {/* Chargement */}
                <>
                  {!activation_chargement ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        hidesWhenStopped={true}
                        size={'small'}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        animating={true}
                        color={palettes.App['Flolend 4']}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App.blanc_flolend,
                              fontFamily: 'PTSans_700Bold',
                              paddingLeft: 10,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Chargement en cours...'}
                      </Text>
                    </View>
                  )}
                </>
              </View>
            )}
          </>
          {/* Message_erreur */}
          <>
            {!display_error ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { marginLeft: '5%', marginRight: '5%', marginTop: '2%' },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['message_erreur'].style,
                    dimensions.width
                  )}
                >
                  {message_erreur}
                </Text>
              </View>
            )}
          </>
        </View>
        {/* Navigation mobile */}
        <>
          {dimensions.width >= Breakpoints.Laptop ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: 'center',
                  bottom: '2%',
                  position: 'absolute',
                  width: '90%',
                },
                dimensions.width
              )}
            >
              <>
                {activation_chargement ? null : (
                  <LinearGradient
                    endX={100}
                    endY={100}
                    startX={0}
                    startY={0}
                    {...GlobalStyles.LinearGradientStyles(theme)[
                      'gradient_bouton'
                    ].props}
                    color1={
                      (validation_encart(numberInputValue)
                        ? palettes.App['Flolend 2']
                        : palettes.App.gris_fonce_flolend) ??
                      palettes.App['Flolend 2']
                    }
                    color2={
                      (validation_encart(numberInputValue)
                        ? palettes.App['Flolend 4']
                        : palettes.App.gris_fonce_flolend) ??
                      palettes.App['Flolend 4']
                    }
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.LinearGradientStyles(theme)[
                          'gradient_bouton'
                        ].style,
                        {
                          alignSelf: {
                            minWidth: Breakpoints.Tablet,
                            value: 'center',
                          },
                          flex: [
                            { minWidth: Breakpoints.Tablet, value: null },
                            { minWidth: Breakpoints.Mobile, value: null },
                          ],
                          justifyContent: 'center',
                          marginLeft: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          marginRight: {
                            minWidth: Breakpoints.Laptop,
                            value: 0,
                          },
                          marginTop: null,
                          width: { minWidth: Breakpoints.Tablet, value: '50%' },
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Bouton continuer actif */}
                    <Button
                      accessible={true}
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (!validation_encart(numberInputValue)) {
                              return;
                            }
                            setActivation_chargement(true);
                            setDisplay_error(false);
                            if (
                              arrondi_input(numberInputValue) > max_carte_euros
                            ) {
                              setMessage_erreur(
                                `Seuls les virements sont autorisés pour les approvisionnements de plus de ${format_nombre(
                                  max_carte_euros,
                                  0
                                )} €`
                              );
                              setDisplay_error(true);
                            } else {
                              const retour_api = (
                                await baseXanoPaiement$tokenPaiementPOST.mutateAsync(
                                  {
                                    montant: retour_centimes(
                                      arrondi_input(numberInputValue)
                                    ),
                                  }
                                )
                              )?.json;
                              if (retour_api?.success) {
                                navigation.navigate('PaiementParCarteScreen', {
                                  token_money_in: retour_api?.token_paiement,
                                  arrivee_pipe_invest: false,
                                  id_transaction: retour_api?.id_transaction,
                                });
                              }
                              if (!retour_api?.success) {
                                setMessage_erreur(retour_api?.message_erreur);
                              }
                              if (!retour_api?.success) {
                                setDisplay_error(true);
                              }
                            }

                            setActivation_chargement(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                        .props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                            .style,
                          {
                            borderWidth: 0,
                            fontSize: [
                              { minWidth: Breakpoints.Laptop, value: 22 },
                              { minWidth: Breakpoints.BigScreen, value: 30 },
                            ],
                            marginBottom: 0,
                            marginTop: 0,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Continuer'}
                    />
                  </LinearGradient>
                )}
              </>
              {/* Chargement */}
              <>
                {!activation_chargement ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <ActivityIndicator
                      hidesWhenStopped={true}
                      size={'small'}
                      {...GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].props}
                      animating={true}
                      color={palettes.App['Flolend 4']}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].style,
                        dimensions.width
                      )}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color: palettes.App.blanc_flolend,
                            fontFamily: 'PTSans_700Bold',
                            paddingLeft: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Chargement en cours...'}
                    </Text>
                  </View>
                )}
              </>
            </View>
          )}
        </>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AlimentationParCarteScreen);
