import React from 'react';
import { Icon, Pressable, SimpleStyleFlatList, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  currentRoute: { icon: 'Entypo/home', name: 'RouteOne', label: 'Route 1' },
  routes: [
    { icon: 'Entypo/home', name: 'RouteOne', label: 'Route 1' },
    { icon: 'Entypo/home', name: 'RouteTwo', label: 'Route 2' },
    { icon: 'Entypo/home', name: 'RouteThree', label: 'Route 3' },
  ],
};

const FooterMobileBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const [etat_footer, setEtat_footer] = React.useState(1);

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          borderColor: [
            { minWidth: Breakpoints.BigScreen, value: theme.colors.text.light },
            {
              minWidth: Breakpoints.Mobile,
              value: palettes.App.gris_fonce_flolend,
            },
          ],
          borderTopWidth: [
            { minWidth: Breakpoints.BigScreen, value: 1 },
            { minWidth: Breakpoints.Mobile, value: 1 },
          ],
          bottom: 0,
          padding: 15,
        },
        dimensions.width
      )}
    >
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <SimpleStyleFlatList
            data={props.routes ?? defaultProps.routes}
            horizontal={false}
            inverted={false}
            keyExtractor={(listData, index) =>
              listData?.id ??
              listData?.uuid ??
              index?.toString() ??
              JSON.stringify(listData)
            }
            keyboardShouldPersistTaps={'never'}
            listKey={'0zqYvLPu'}
            nestedScrollEnabled={false}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item, index }) => {
              const listData = item;
              return (
                <Pressable
                  onPress={() => {
                    try {
                      navigation.navigate(listData?.name);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Vue bouton */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={
                        listData?.name ===
                        (props.currentRoute?.name ??
                          {
                            icon: 'Entypo/home',
                            name: 'RouteOne',
                            label: 'Route 1',
                          }.name)
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend
                      }
                      name={listData?.icon}
                      size={30}
                    />
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      selectionColor={
                        (props.currentRoute?.name ??
                          {
                            icon: 'Entypo/home',
                            name: 'RouteOne',
                            label: 'Route 1',
                          }.name) === listData?.name
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend
                      }
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            color:
                              (props.currentRoute?.name ??
                                {
                                  icon: 'Entypo/home',
                                  name: 'RouteOne',
                                  label: 'Route 1',
                                }.name) === listData?.name
                                ? palettes.App['Flolend 4']
                                : palettes.App.gris_fonce_flolend,
                            fontFamily: 'Montserrat_500Medium',
                            fontSize: [
                              { minWidth: Breakpoints.Mobile, value: 10 },
                              { minWidth: Breakpoints.Tablet, value: 12 },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {listData?.label}
                    </Text>
                  </View>
                </Pressable>
              );
            }}
            scrollEnabled={false}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'space-around' },
              dimensions.width
            )}
          />
        )}
      </>
    </View>
  );
};

export default withTheme(FooterMobileBlock);
