import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const ErreurSouscriptionScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.background_flolend,
          },
          justifyContent: 'center',
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
              .props}
            color1={palettes.App['Flolend 1']}
            color2={palettes.App['Flolend 2']}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['Linear Gradient']
                  .style,
                {
                  alignItems: 'center',
                  height: '100%',
                  justifyContent: 'center',
                  width: '100%',
                }
              ),
              dimensions.width
            )}
          >
            {/* Logo */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors.background.brand,
                    borderBottomLeftRadius: 15,
                    borderBottomRightRadius: 15,
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    height: 100,
                    justifyContent: 'center',
                    width: 100,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={palettes.App['Flolend 1']}
                  name={'Entypo/cross'}
                  size={75}
                />
              </View>
            </View>

            <View>
              {/* msg */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App.Communial_White,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: 18,
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'Erreur de souscription'}
              </Text>
              {/* msg 2 */}
              <Text
                accessible={true}
                selectable={false}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App.Communial_White,
                    fontFamily: 'Montserrat_500Medium',
                    fontSize: 14,
                    lineHeight: 25,
                    marginTop: 10,
                    opacity: 0.95,
                    paddingLeft: 25,
                    paddingRight: 25,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {
                  "Une erreur à eu lieu lors de l'enregistrement de votre souscription. Vos fonds ont été placés sur votre livret Flolend dans l'attente de la résolution du problème.\n\nNos équipes techniques ont été prévenues et vont solutionner le problème sous les plus brefs délais.\n\nA la résolution du problème, vous pourrez finaliser votre souscription depuis votre espace personnel. Nous vous prions de bien vouloir nous excuser pour la gêne occasionnée."
                }
              </Text>
            </View>
            {/* bouton */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                try {
                  navigation.navigate('BottomTabNavigator', {
                    screen: 'EspacePersonnelScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_classique'].style,
                  {
                    color: theme.colors.background.brand,
                    marginTop: 20,
                    width: 350,
                  }
                ),
                dimensions.width
              )}
              title={'Mon espace personnel'}
            />
          </LinearGradient>
        )}
      </>
      <>
        {!(dimensions.width >= Breakpoints.Laptop) ? null : (
          <SimpleStyleScrollView
            horizontal={false}
            keyboardShouldPersistTaps={'never'}
            nestedScrollEnabled={false}
            bounces={false}
            scrollEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              {
                height: { minWidth: Breakpoints.Laptop, value: '100%' },
                justifyContent: {
                  minWidth: Breakpoints.Laptop,
                  value: 'space-between',
                },
                paddingTop: [
                  {
                    minWidth: Breakpoints.BigScreen,
                    value: Constants['hauteur_navbar_big_screen'],
                  },
                  {
                    minWidth: Breakpoints.Desktop,
                    value: Constants['hauteur_navbar_desktop'],
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: Constants['hauteur_navbar_laptop'],
                  },
                ],
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            <ImageBackground
              resizeMode={'cover'}
              {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                .props}
              source={imageSource(Images['Image3'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageBackgroundStyles(theme)['Image Background']
                    .style,
                  {
                    alignItems: {
                      minWidth: Breakpoints.Laptop,
                      value: 'flex-end',
                    },
                    flex: { minWidth: Breakpoints.Laptop, value: null },
                    justifyContent: {
                      minWidth: Breakpoints.Laptop,
                      value: 'center',
                    },
                    minHeight: [
                      { minWidth: Breakpoints.Laptop, value: 800 },
                      { minWidth: Breakpoints.Desktop, value: 900 },
                      { minWidth: Breakpoints.BigScreen, value: 1000 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.flolend_gris_nuance_encart,
                    },
                    borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                    gap: [
                      { minWidth: Breakpoints.Laptop, value: 10 },
                      { minWidth: Breakpoints.Desktop, value: 20 },
                      { minWidth: Breakpoints.BigScreen, value: 30 },
                    ],
                    justifyContent: [
                      { minWidth: Breakpoints.Desktop, value: 'center' },
                      { minWidth: Breakpoints.Laptop, value: 'space-around' },
                    ],
                    marginRight: { minWidth: Breakpoints.Laptop, value: '5%' },
                    minHeight: { minWidth: Breakpoints.Desktop, value: 400 },
                    padding: [
                      { minWidth: Breakpoints.Laptop, value: '5%' },
                      { minWidth: Breakpoints.Desktop, value: '2%' },
                    ],
                    paddingBottom: {
                      minWidth: Breakpoints.Laptop,
                      value: '2%',
                    },
                    paddingLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
                    paddingRight: { minWidth: Breakpoints.Laptop, value: '2%' },
                    paddingTop: { minWidth: Breakpoints.Laptop, value: '2%' },
                    width: [
                      { minWidth: Breakpoints.Desktop, value: '40%' },
                      { minWidth: Breakpoints.BigScreen, value: '35%' },
                      { minWidth: Breakpoints.Laptop, value: '50%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                {/* Vue icone */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: {
                          minWidth: Breakpoints.Laptop,
                          value: palettes.App.gris_encart_int_flolend,
                        },
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        height: 100,
                        justifyContent: 'center',
                        width: 100,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={palettes.App.blanc_flolend}
                      name={'Entypo/cross'}
                      size={100}
                    />
                  </View>
                </View>
                {/* Vue message */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      gap: [
                        { minWidth: Breakpoints.Laptop, value: 5 },
                        { minWidth: Breakpoints.Desktop, value: 15 },
                        { minWidth: Breakpoints.BigScreen, value: 25 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {/* msg */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.blanc_flolend,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Communial_White,
                          },
                        ],
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 18 },
                          { minWidth: Breakpoints.Laptop, value: 22 },
                          { minWidth: Breakpoints.Desktop, value: 26 },
                          { minWidth: Breakpoints.BigScreen, value: 30 },
                        ],
                        lineHeight: 25,
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          { minWidth: Breakpoints.Laptop, value: 0 },
                        ],
                        opacity: 0.95,
                        paddingLeft: [
                          { minWidth: Breakpoints.Mobile, value: 25 },
                          { minWidth: Breakpoints.Laptop, value: 0 },
                        ],
                        paddingRight: [
                          { minWidth: Breakpoints.Mobile, value: 25 },
                          { minWidth: Breakpoints.Laptop, value: 0 },
                        ],
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {'Erreur de souscription'}
                  </Text>
                  {/* msg 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    style={StyleSheet.applyWidth(
                      {
                        color: [
                          {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.blanc_flolend,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Communial_White,
                          },
                        ],
                        fontFamily: 'Montserrat_500Medium',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 14 },
                          { minWidth: Breakpoints.Laptop, value: 18 },
                          { minWidth: Breakpoints.Desktop, value: 22 },
                          { minWidth: Breakpoints.BigScreen, value: 26 },
                        ],
                        lineHeight: 25,
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          { minWidth: Breakpoints.Laptop, value: 0 },
                        ],
                        opacity: 0.95,
                        paddingLeft: [
                          { minWidth: Breakpoints.Mobile, value: 25 },
                          { minWidth: Breakpoints.Laptop, value: 0 },
                        ],
                        paddingRight: {
                          minWidth: Breakpoints.Laptop,
                          value: 0,
                        },
                        textAlign: 'center',
                      },
                      dimensions.width
                    )}
                  >
                    {
                      "Une erreur à eu lieu lors de l'enregistrement de votre souscription. Vos fonds ont été placés sur votre livret Flolend dans l'attente de la résolution du problème.\n\nNos équipes techniques ont été prévenues et vont solutionner le problème sous les plus brefs délais.\n\nA la résolution du problème, vous pourrez finaliser votre souscription depuis votre espace personnel. Nous vous prions de bien vouloir nous excuser pour la gêne occasionnée."
                    }
                  </Text>
                </View>
                {/* Vue bouton */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* bouton 2 */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      try {
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'EspacePersonnelScreen',
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_classique']
                          .style,
                        {
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: 15,
                          },
                          color: {
                            minWidth: Breakpoints.Laptop,
                            value: palettes.App.blanc_flolend,
                          },
                          fontSize: [
                            { minWidth: Breakpoints.Desktop, value: 22 },
                            { minWidth: Breakpoints.BigScreen, value: 26 },
                          ],
                          height: [
                            { minWidth: Breakpoints.Laptop, value: 60 },
                            { minWidth: Breakpoints.Desktop, value: 65 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                          marginTop: 20,
                          width: 350,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Mon espace personnel'}
                  />
                </View>
              </View>
            </ImageBackground>
            <FooterDesktopBlock />
          </SimpleStyleScrollView>
        )}
      </>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(ErreurSouscriptionScreen);
