import React from 'react';
import {
  Button,
  Circle,
  Icon,
  LinearGradient,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import ConvertionDate from '../global-functions/ConvertionDate';
import conversion_date_utc from '../global-functions/conversion_date_utc';
import conversion_solde_wallet from '../global-functions/conversion_solde_wallet';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as DateUtils from '../utils/DateUtils';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const MonLivretFlolendScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [simulation_projet, setSimulation_projet] = React.useState(0);
  // Conditionne les signes des montants associés aux transactions
  const statut_moins = statut => {
    const statutsNegatifs = ['money_out', 'Investissement'];
    return statutsNegatifs.includes(statut);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: palettes.App.background_flolend },
        dimensions.width
      )}
    >
      {/* mon_header */}
      <View
        {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.ViewStyles(theme)['header_classique'].style,
          dimensions.width
        )}
      >
        {/* Back btn */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'EspacePersonnelScreen',
              });
              /* hidden 'Navigate Back' action */
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 44,
                justifyContent: 'center',
                width: 44,
              },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App.gris_fonce_flolend}
              name={'AntDesign/arrowleft'}
              size={18}
            />
          </View>
        </Touchable>
        {/* Screen Heading */}
        <Text
          accessible={true}
          selectable={false}
          {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
          style={StyleSheet.applyWidth(
            GlobalStyles.TextStyles(theme)['screen_heading'].style,
            dimensions.width
          )}
        >
          {'Mon livret Flolend'}
        </Text>
      </View>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            maxHeight: dimensions.height,
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* Vue générale */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.flolend_gris_nuance_encart,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              gap: { minWidth: Breakpoints.Laptop, value: 15 },
              height: '100%',
              marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
              paddingLeft: { minWidth: Breakpoints.Laptop, value: '20%' },
              paddingRight: { minWidth: Breakpoints.Laptop, value: '20%' },
            },
            dimensions.width
          )}
        >
          {/* Livret */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.gris_encart_int_flolend,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: '5%' },
                  { minWidth: Breakpoints.Laptop, value: '0%' },
                ],
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Fetch client */}
            <BaseXanoApi.FetchAuthGET>
              {({ loading, error, data, refetchAuth }) => {
                const fetchClientData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    <Touchable>
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: palettes.App['Flolend 1'],
                            borderRadius: 12,
                            height: 220,
                            justifyContent: 'space-between',
                            paddingBottom: 24,
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingTop: 24,
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'livret_flolend_titre'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'livret_flolend_titre'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Livret Flolend'}
                          </Text>
                          <Image
                            resizeMode={'contain'}
                            source={imageSource(Images['LogoWireframeLight'])}
                            style={StyleSheet.applyWidth(
                              { height: 30, width: 30 },
                              dimensions.width
                            )}
                          />
                        </View>

                        <View>
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)[
                              'livret_flolend_solde_titre'
                            ].props}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.TextStyles(theme)[
                                'livret_flolend_solde_titre'
                              ].style,
                              dimensions.width
                            )}
                          >
                            {'Solde'}
                          </Text>

                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                flexDirection: 'row',
                                marginTop: 6,
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'livret_flolend_solde'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'livret_flolend_solde'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {format_nombre(
                                conversion_solde_wallet(
                                  fetchClientData?.wallet_lemonway
                                    ?.compte_client?.balance
                                ),
                                2
                              )}
                              {' €'}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </Touchable>
                    {/* Virements */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'center',
                          marginTop: {
                            minWidth: Breakpoints.Laptop,
                            value: 30,
                          },
                          padding: [
                            { minWidth: Breakpoints.Mobile, value: 24 },
                            { minWidth: Breakpoints.Laptop, value: 0 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Vue boutons (KYC complet) */}
                      <>
                        {!(
                          fetchClientData?.wallet_lemonway?.compte_client
                            ?.status === 6
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { flexDirection: 'row' },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'AlimenterMonLivretScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Recharger */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginRight: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Flolend 4'],
                                      borderRadius: 8,
                                      opacity: 0.85,
                                      paddingBottom: 12,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={palettes.App.blanc_flolend}
                                    name={'Feather/arrow-up'}
                                    size={26}
                                  />
                                </View>
                                <Spacer left={4} right={4} />
                                <View>
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['Flolend 4'],
                                        fontFamily: 'PTSans_700Bold',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 24,
                                          },
                                        ],
                                        opacity: 0.85,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Recharger'}
                                  </Text>
                                </View>
                              </View>
                            </Touchable>

                            <Pressable
                              onPress={() => {
                                try {
                                  navigation.navigate(
                                    'MoneyOut1RecupererFondsScreen'
                                  );
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Retirer */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginLeft: 8,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      backgroundColor:
                                        palettes.App['Flolend 2'],
                                      borderRadius: 8,
                                      paddingBottom: 12,
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      paddingTop: 12,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <Icon
                                    color={palettes.App.blanc_flolend}
                                    name={'Feather/arrow-down'}
                                    size={26}
                                  />
                                </View>
                                <Spacer left={4} right={4} />
                                <View>
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: palettes.App['Flolend 2'],
                                        fontFamily: 'PTSans_700Bold',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 24,
                                          },
                                        ],
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {'Retirer'}
                                  </Text>
                                </View>
                              </View>
                            </Pressable>
                          </View>
                        )}
                      </>
                      {/* Vue KYC incomplet */}
                      <>
                        {fetchClientData?.wallet_lemonway?.compte_client
                          ?.status === 6 ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                gap: [
                                  { minWidth: Breakpoints.Mobile, value: 5 },
                                  { minWidth: Breakpoints.Laptop, value: 10 },
                                ],
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <View>
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'corps_contenu'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'corps_contenu'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {
                                  'Pour virer des fonds vers et depuis votre livret Flolend, complétez votre KYC depuis votre dossier investisseur.'
                                }
                              </Text>
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                },
                                dimensions.width
                              )}
                            >
                              <LinearGradient
                                endX={100}
                                endY={100}
                                startX={0}
                                startY={0}
                                {...GlobalStyles.LinearGradientStyles(theme)[
                                  'gradient_bouton_bleu'
                                ].props}
                                color1={palettes.App['Flolend 1']}
                                color2={palettes.App['Flolend 2']}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinearGradientStyles(theme)[
                                      'gradient_bouton_bleu'
                                    ].style,
                                    {
                                      borderRadius: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 15,
                                      },
                                      flex: null,
                                      height: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 60,
                                        },
                                        {
                                          minWidth: Breakpoints.BigScreen,
                                          value: 70,
                                        },
                                      ],
                                      justifyContent: 'center',
                                      marginTop: 0,
                                      overflow: {
                                        minWidth: Breakpoints.Laptop,
                                        value: 'hidden',
                                      },
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {/* bouton_dossier */}
                                <Button
                                  accessible={true}
                                  iconPosition={'left'}
                                  onPress={() => {
                                    try {
                                      navigation.navigate(
                                        'DossierInvestisseurScreen'
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)[
                                    'bouton_fond_blanc'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)[
                                        'bouton_fond_blanc'
                                      ].style,
                                      {
                                        borderRadius: {
                                          minWidth: Breakpoints.Laptop,
                                          value: null,
                                        },
                                        fontFamily: 'PTSans_400Regular',
                                        fontSize: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 16,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 20,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 24,
                                          },
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 28,
                                          },
                                        ],
                                        height: null,
                                        marginBottom: 0,
                                        marginTop: 0,
                                        width: null,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Mon dossier investisseur'}
                                />
                              </LinearGradient>
                            </View>
                          </View>
                        )}
                      </>
                    </View>
                  </>
                );
              }}
            </BaseXanoApi.FetchAuthGET>
          </View>
          {/* Transactions */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App.flolend_gris_nuance_encart,
                  },
                  {
                    minWidth: Breakpoints.Laptop,
                    value: palettes.App.gris_encart_int_flolend,
                  },
                ],
                borderColor: [
                  {
                    minWidth: Breakpoints.Mobile,
                    value: palettes.App.gris_fonce_flolend,
                  },
                  { minWidth: Breakpoints.Laptop, value: 'rgba(0, 0, 0, 0)' },
                ],
                borderLeftWidth: 1,
                borderRadius: { minWidth: Breakpoints.Laptop, value: 48 },
                borderRightWidth: 1,
                borderTopLeftRadius: 48,
                borderTopRightRadius: 48,
                borderTopWidth: 1,
                flex: 1,
                height: '100%',
                paddingBottom: 36,
                paddingLeft: 32,
                paddingRight: 32,
                paddingTop: 36,
                width: { minWidth: Breakpoints.Laptop, value: '100%' },
              },
              dimensions.width
            )}
          >
            {/* Fetch transactions */}
            <BaseXanoApi.FetchTransactionsClientGET>
              {({ loading, error, data, refetchTransactionsClient }) => {
                const fetchTransactionsData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <View>
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['titre1_generique']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['titre1_generique']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Transactions'}
                      </Text>
                    </View>
                    <Spacer bottom={12} top={12} />
                    {/* Vue sans invest */}
                    <>
                      {!(
                        fetchTransactionsData?.transactions?.length === 0
                      ) ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { gap: 16 },
                            dimensions.width
                          )}
                        >
                          {/* Intro */}
                          <View>
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['explications']
                                .props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)['explications']
                                  .style,
                                dimensions.width
                              )}
                            >
                              {
                                'Vous retrouverez ci-dessous toutes les transactions vers et depuis votre livret Flolend.'
                              }
                            </Text>
                          </View>
                          {/* Exemple 1 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue de gauche */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  gap: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors.border.brand}
                                size={60}
                              >
                                <Circle
                                  bgColor={theme.colors.background.brand}
                                  size={56}
                                >
                                  {/* icones_par_evenement */}
                                  <View>
                                    {/* money_in */}
                                    <Icon
                                      color={palettes.App.gris_fonce_flolend}
                                      name={
                                        'MaterialCommunityIcons/wallet-plus-outline'
                                      }
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 25
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 35
                                          : 30
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 4 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </Circle>
                              </Circle>
                              {/* encart_central_par_evenement */}
                              <View>
                                {/* money_in */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                >
                                  {/* compte */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'livret_ss_titre_transaction'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'livret_ss_titre_transaction'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Livret Flolend'}
                                  </Text>
                                  {/* evenement */}
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'livret_titre_transaction'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'livret_titre_transaction'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Approvisionnement'}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* Mentions de droite */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.gris_fonce_flolend,
                                    fontFamily: 'PTSans_700Bold',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'100,00 €'}
                              </Text>

                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.gris_fonce_flolend,
                                    fontFamily: 'PTSans_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 20,
                                      },
                                    ],
                                    marginTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {ConvertionDate(new Date())}
                              </Text>
                            </View>
                          </View>
                          {/* Exemple 2 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue de gauche */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  gap: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors.border.brand}
                                size={60}
                              >
                                <Circle
                                  bgColor={theme.colors.background.brand}
                                  size={56}
                                >
                                  {/* icones_par_evenement */}
                                  <View>
                                    {/* Interets */}
                                    <Icon
                                      color={palettes.App.gris_fonce_flolend}
                                      name={
                                        'MaterialCommunityIcons/account-plus-outline'
                                      }
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 25
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 35
                                          : 30
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 4 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </Circle>
                              </Circle>
                              {/* encart_central_par_evenement */}
                              <View>
                                {/* Interets */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'livret_ss_titre_transaction'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'livret_ss_titre_transaction'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Nom du projet'}
                                  </Text>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'livret_titre_transaction'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'livret_titre_transaction'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {"Perception d'intérêts"}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* Mentions de droite */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.gris_fonce_flolend,
                                    fontFamily: 'PTSans_700Bold',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'8,00 €'}
                              </Text>

                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.gris_fonce_flolend,
                                    fontFamily: 'PTSans_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 20,
                                      },
                                    ],
                                    marginTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {ConvertionDate(
                                  DateUtils.addYears(new Date(), 1)
                                )}
                              </Text>
                            </View>
                          </View>
                          {/* Exemple 3 */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue de gauche */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  gap: 10,
                                },
                                dimensions.width
                              )}
                            >
                              <Circle
                                bgColor={theme.colors.border.brand}
                                size={60}
                              >
                                <Circle
                                  bgColor={theme.colors.background.brand}
                                  size={56}
                                >
                                  {/* icones_par_evenement */}
                                  <View>
                                    {/* money_out */}
                                    <Icon
                                      color={palettes.App.gris_fonce_flolend}
                                      name={'MaterialCommunityIcons/bank-plus'}
                                      size={
                                        !(
                                          dimensions.width >=
                                          Breakpoints.Desktop
                                        )
                                          ? 25
                                          : dimensions.width >=
                                            Breakpoints.BigScreen
                                          ? 35
                                          : 30
                                      }
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 4 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>
                                </Circle>
                              </Circle>
                              {/* encart_central_par_evenement */}
                              <View>
                                {/* money_out */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                >
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'livret_ss_titre_transaction'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'livret_ss_titre_transaction'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Livret Flolend'}
                                  </Text>

                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'livret_titre_transaction'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'livret_titre_transaction'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'Retrait'}
                                  </Text>
                                </View>
                              </View>
                            </View>
                            {/* Mentions de droite */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.gris_fonce_flolend,
                                    fontFamily: 'PTSans_700Bold',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 18,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 22,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              >
                                {'100,00 €'}
                              </Text>

                              <Text
                                accessible={true}
                                selectable={false}
                                style={StyleSheet.applyWidth(
                                  {
                                    color: palettes.App.gris_fonce_flolend,
                                    fontFamily: 'PTSans_400Regular',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 12,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 20,
                                      },
                                    ],
                                    marginTop: 4,
                                  },
                                  dimensions.width
                                )}
                              >
                                {ConvertionDate(
                                  DateUtils.addYears(new Date(), 2)
                                )}
                              </Text>
                            </View>
                          </View>
                        </View>
                      )}
                    </>
                    <>
                      {!(
                        fetchTransactionsData?.transactions?.length > 0
                      ) ? null : (
                        <SimpleStyleFlatList
                          data={fetchTransactionsData?.transactions}
                          horizontal={false}
                          inverted={false}
                          keyExtractor={(listData, index) =>
                            listData?.id ??
                            listData?.uuid ??
                            index?.toString() ??
                            JSON.stringify(listData)
                          }
                          keyboardShouldPersistTaps={'never'}
                          listKey={'zP3WVim4'}
                          nestedScrollEnabled={false}
                          numColumns={1}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* Vue unique */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Vue de gauche */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        gap: 10,
                                        maxWidth: {
                                          minWidth: Breakpoints.Laptop,
                                          value: '70%',
                                        },
                                        width: '70%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* icones_par_evenement */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'center',
                                          backgroundColor:
                                            palettes.App.blanc_flolend,
                                          borderColor:
                                            palettes.App.gris_fonce_flolend,
                                          borderRadius: 50,
                                          borderWidth: 1,
                                          height: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 50,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 55,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 60,
                                            },
                                          ],
                                          justifyContent: 'center',
                                          width: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 50,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 55,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 60,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* money_in */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'money_in'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={
                                              'MaterialCommunityIcons/wallet-plus-outline'
                                            }
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 25
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 35
                                                : 30
                                            }
                                          />
                                        )}
                                      </>
                                      {/* money_out */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'money_out'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={
                                              'MaterialCommunityIcons/bank-plus'
                                            }
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 25
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 35
                                                : 30
                                            }
                                          />
                                        )}
                                      </>
                                      {/* Investissement */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'Investissement'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Feather/user-minus'}
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 25
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 35
                                                : 30
                                            }
                                          />
                                        )}
                                      </>
                                      {/* Interets */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'Interets'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={
                                              'MaterialCommunityIcons/account-plus-outline'
                                            }
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 25
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 35
                                                : 30
                                            }
                                          />
                                        )}
                                      </>
                                      {/* Remboursement */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'Remboursement'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={
                                              'MaterialCommunityIcons/account-plus-outline'
                                            }
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 25
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 35
                                                : 30
                                            }
                                          />
                                        )}
                                      </>
                                      {/* Annulation */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'annulation'
                                        ) ? null : (
                                          <Icon
                                            color={
                                              palettes.App.gris_fonce_flolend
                                            }
                                            name={'Entypo/cross'}
                                            size={
                                              !(
                                                dimensions.width >=
                                                Breakpoints.Desktop
                                              )
                                                ? 25
                                                : dimensions.width >=
                                                  Breakpoints.BigScreen
                                                ? 35
                                                : 30
                                            }
                                          />
                                        )}
                                      </>
                                    </View>
                                    {/* encart_central_par_evenement */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { maxWidth: '75%' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* money_in */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'money_in'
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            {/* compte */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_ss_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_ss_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Compte externe'}
                                            </Text>
                                            {/* evenement */}
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Approvisionnement'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* money_out */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'money_out'
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_ss_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_ss_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Livret Flolend'}
                                            </Text>

                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Retrait'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* Investissement */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'Investissement'
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_ss_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_ss_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Projet '}
                                              {listData?.nom_projet}
                                            </Text>

                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Souscription'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* Interets */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'Interets'
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_ss_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_ss_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Projet '}
                                              {listData?.nom_projet}
                                            </Text>

                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {"Perception d'intérêts"}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* Remboursement */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'Remboursement'
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_ss_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_ss_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Projet '}
                                              {listData?.nom_projet}
                                            </Text>

                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Remboursement'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                      {/* Annulation */}
                                      <>
                                        {!(
                                          listData?.type_evenement ===
                                          'annulation'
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1 },
                                              dimensions.width
                                            )}
                                          >
                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_ss_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_ss_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Projet '}
                                              {listData?.nom_projet}
                                            </Text>

                                            <Text
                                              accessible={true}
                                              selectable={false}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['livret_titre_transaction']
                                                .props}
                                              style={StyleSheet.applyWidth(
                                                GlobalStyles.TextStyles(theme)[
                                                  'livret_titre_transaction'
                                                ].style,
                                                dimensions.width
                                              )}
                                            >
                                              {'Annulation'}
                                            </Text>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                  {/* Mentions de droite */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'flex-end' },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App.gris_fonce_flolend,
                                          fontFamily: 'PTSans_700Bold',
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 18,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 22,
                                            },
                                          ],
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {statut_moins(listData?.type_evenement)
                                        ? '-'
                                        : '+'}
                                      {listData?.montant >= 100000
                                        ? format_nombre(listData?.montant, 0)
                                        : format_nombre(listData?.montant, 2)}
                                      {' €'}
                                    </Text>

                                    <Text
                                      accessible={true}
                                      selectable={false}
                                      style={StyleSheet.applyWidth(
                                        {
                                          color:
                                            palettes.App.gris_fonce_flolend,
                                          fontFamily: 'PTSans_400Regular',
                                          fontSize: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 12,
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 16,
                                            },
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value: 20,
                                            },
                                          ],
                                          marginTop: 4,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {conversion_date_utc(listData?.date)}
                                    </Text>
                                  </View>
                                </View>
                              </>
                            );
                          }}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            { flex: 1, gap: 16 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                );
              }}
            </BaseXanoApi.FetchTransactionsClientGET>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(MonLivretFlolendScreen);
