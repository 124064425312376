import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const TextStyles = theme =>
  StyleSheet.create({
    Text: { style: {}, props: {} },
    'Text 2': { style: { color: theme.colors.text.strong }, props: {} },
    auteur_affiche: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 13 },
          { minWidth: Breakpoints.Desktop, value: 17 },
          { minWidth: Breakpoints.BigScreen, value: 21 },
          { minWidth: Breakpoints.Laptop, value: 13 },
        ],
        lineHeight: 19,
        marginBottom: { minWidth: Breakpoints.Laptop, value: '1%' },
      },
      props: {},
    },
    auteur_card_ressource: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
      },
      props: {},
    },
    band_proj_date: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 13 },
          { minWidth: Breakpoints.Laptop, value: 13 },
          { minWidth: Breakpoints.Desktop, value: 17 },
          { minWidth: Breakpoints.BigScreen, value: 21 },
        ],
      },
      props: {},
    },
    band_proj_montant: {
      style: {
        color: palettes.App['Flolend 2'],
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
      },
      props: {},
    },
    band_proj_nom: {
      style: {
        alignSelf: 'center',
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
      },
      props: {},
    },
    band_proj_type: {
      style: {
        alignSelf: 'center',
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 15 },
          { minWidth: Breakpoints.Laptop, value: 15 },
          { minWidth: Breakpoints.Desktop, value: 19 },
          { minWidth: Breakpoints.BigScreen, value: 23 },
        ],
      },
      props: {},
    },
    contenu_carte_risques: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Mobile, value: 14 },
        ],
        textAlign: 'left',
      },
      props: {},
    },
    corps_card_ressource: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
        lineHeight: 24,
      },
      props: {},
    },
    corps_contenu: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    corps_ecran_message: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: palettes.App.Communial_White },
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
        ],
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        lineHeight: 25,
        marginTop: [
          { minWidth: Breakpoints.Laptop, value: 0 },
          { minWidth: Breakpoints.Mobile, value: 10 },
        ],
        paddingLeft: [
          { minWidth: Breakpoints.Mobile, value: 25 },
          { minWidth: Breakpoints.Laptop, value: 0 },
        ],
        paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
        textAlign: 'center',
      },
      props: {},
    },
    corps_encart_publications: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        lineHeight: [
          { minWidth: Breakpoints.Mobile, value: 18 },
          { minWidth: Breakpoints.Desktop, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
        ],
      },
      props: {},
    },
    date_encart_publications: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Mobile, value: 14 },
        ],
        lineHeight: 18,
        marginLeft: '2%',
      },
      props: {},
    },
    delai_gerer_souscription: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Tablet, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
        marginLeft: 6,
      },
      props: {},
    },
    description_encart_eventail: {
      style: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.gris_fonce_flolend,
        },
        fontFamily: {
          minWidth: Breakpoints.Laptop,
          value: 'PTSans_400Regular',
        },
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 18 },
        ],
        lineHeight: { minWidth: Breakpoints.Laptop, value: 20 },
      },
      props: {},
    },
    duree_card_ressource: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
      },
      props: {},
    },
    element_option_dossier_invest: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
        ],
      },
      props: {},
    },
    elmt_footer: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: palettes.App['Flolend 5'] },
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
        ],
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
      },
      props: {},
    },
    elmt_table: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        textAlign: [
          { minWidth: Breakpoints.Mobile, value: 'auto' },
          { minWidth: Breakpoints.Laptop, value: 'left' },
        ],
      },
      props: {},
    },
    emphase_metrique: {
      style: {
        color: palettes.App['Flolend 5'],
        fontFamily: 'PTSans_700Bold',
        fontSize: 38,
        marginTop: 6,
      },
      props: {},
    },
    'entree_table_infos compte': {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Mobile, value: 16 },
        ],
        textAlign: 'center',
      },
      props: {},
    },
    entree_tableau: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: [
          { minWidth: Breakpoints.Desktop, value: 'PTSans_400Regular' },
          { minWidth: Breakpoints.Laptop, value: 'PTSans_400Regular' },
          { minWidth: Breakpoints.Mobile, value: 'PTSans_400Regular' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
      },
      props: {},
    },
    explication_select_option_souscription: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular_Italic',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
        marginBottom: 5,
      },
      props: {},
    },
    explications: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: palettes.App.blanc_flolend },
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
        ],
        fontFamily: 'PTSans_400Regular_Italic',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    frise_pipe_invest: {
      style: {
        alignSelf: 'center',
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Mobile, value: 10 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
        textAlign: 'center',
      },
      props: {},
    },
    frise_pipe_invest_unselected: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 10 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
        textAlign: 'center',
      },
      props: {},
    },
    frise_simul_titre_etape: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
      },
      props: {},
    },
    frise_test_titre_etape: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        paddingBottom: 5,
        paddingLeft: 16,
        paddingRight: 16,
      },
      props: {},
    },
    indicateurs_encart_projet: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: theme.colors.text.medium },
          {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.gris_fonce_flolend,
          },
        ],
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Tablet, value: 18 },
        ],
        lineHeight: 24,
        marginTop: [
          { minWidth: Breakpoints.BigScreen, value: 0 },
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Laptop, value: 0 },
        ],
      },
      props: {},
    },
    intro_contenu: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular_Italic',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Tablet, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 20 },
        ],
        lineHeight: 24,
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    item_rating_card_ressource: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 24 },
          { minWidth: Breakpoints.BigScreen, value: 28 },
        ],
        marginLeft: 2,
      },
      props: {},
    },
    item_tableau: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
      },
      props: {},
    },
    likes_card_affiche: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        lineHeight: 18,
        marginLeft: { minWidth: Breakpoints.Laptop, value: 5 },
      },
      props: {},
    },
    livret_flolend_solde: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: 24,
        lineHeight: 26,
        marginLeft: 4,
      },
      props: {},
    },
    livret_flolend_solde_titre: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
      },
      props: {},
    },
    livret_flolend_titre: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 27 },
        ],
      },
      props: {},
    },
    livret_ss_titre_transaction: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
      },
      props: {},
    },
    livret_titre_transaction: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
        marginTop: 4,
      },
      props: {},
    },
    message_erreur: {
      style: {
        color: palettes.App.Internal_Hot_Red,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginLeft: 20,
        marginRight: 20,
        marginTop: 5,
      },
      props: {},
    },
    metrique_apercu_gen: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 50 },
          { minWidth: Breakpoints.Laptop, value: 42 },
          { minWidth: Breakpoints.Mobile, value: 38 },
          { minWidth: Breakpoints.Desktop, value: 46 },
        ],
      },
      props: {},
    },
    metrique_clef_encart_projet: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
      },
      props: {},
    },
    montant_echeancier: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'PTSans_400Regular' },
          { minWidth: Breakpoints.Laptop, value: 'PTSans_700Bold' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
        ],
        textAlign: [
          { minWidth: Breakpoints.Mobile, value: 'left' },
          { minWidth: Breakpoints.Laptop, value: 'left' },
        ],
      },
      props: {},
    },
    montants_options_souscription: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
      },
      props: {},
    },
    montants_simul_ecr_projet: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
      },
      props: {},
    },
    nom_card_ressource: {
      style: {
        color: palettes.App.blanc_flolend,
        flex: 1,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
          { minWidth: Breakpoints.Desktop, value: 22 },
        ],
        lineHeight: 24,
      },
      props: {},
    },
    note_carte_esg: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        textAlign: 'center',
      },
      props: {},
    },
    option_assistance: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginLeft: 12,
      },
      props: {},
    },
    part_collectee_encart_projet: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: palettes.App['Flolend 5'] },
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
        ],
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 10 },
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 14 },
        ],
      },
      props: {},
    },
    profession_card_ressource: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
      },
      props: {},
    },
    question_profil_invest: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Tablet, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
        marginBottom: [
          { minWidth: Breakpoints.Mobile, value: '1%' },
          { minWidth: Breakpoints.Laptop, value: '1%' },
        ],
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    question_simulation: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    screen_heading: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
        marginLeft: 10,
      },
      props: {},
    },
    selecteur_off: {
      style: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.blanc_flolend,
        },
        fontFamily: {
          minWidth: Breakpoints.Laptop,
          value: 'Montserrat_500Medium',
        },
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
      },
      props: {},
    },
    selecteur_on: {
      style: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.blanc_flolend,
        },
        fontFamily: {
          minWidth: Breakpoints.Laptop,
          value: 'Montserrat_700Bold',
        },
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        textDecorationColor: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App['Flolend 2'],
        },
        textDecorationLine: {
          minWidth: Breakpoints.Laptop,
          value: 'underline',
        },
      },
      props: {},
    },
    sous_elmt_table: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 10 },
          { minWidth: Breakpoints.Desktop, value: 14 },
        ],
        marginTop: 5,
      },
      props: {},
    },
    sous_elt_echeancier: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: [
          { minWidth: Breakpoints.Mobile, value: 'PTSans_400Regular' },
          { minWidth: Breakpoints.Laptop, value: 'PTSans_700Bold' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Laptop, value: 10 },
          { minWidth: Breakpoints.BigScreen, value: 18 },
          { minWidth: Breakpoints.Desktop, value: 14 },
        ],
        marginTop: 5,
      },
      props: {},
    },
    sous_titre_carte_affiche: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
        lineHeight: 18,
      },
      props: {},
    },
    sous_titre_eventail: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 20 },
          { minWidth: Breakpoints.Desktop, value: 24 },
          { minWidth: Breakpoints.BigScreen, value: 28 },
          { minWidth: Breakpoints.Tablet, value: 18 },
        ],
        marginTop: 5,
      },
      props: {},
    },
    sous_titre_pipe_invest: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 26 },
          { minWidth: Breakpoints.Desktop, value: 22 },
          { minWidth: Breakpoints.Mobile, value: 18 },
        ],
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    statut_sous_en_cours: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        marginLeft: 6,
      },
      props: {},
    },
    texte_retour_ordi: {
      style: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.blanc_flolend,
        },
        fontFamily: { minWidth: Breakpoints.Laptop, value: 'PTSans_700Bold' },
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
      },
      props: {},
    },
    texte_simple: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Mobile, value: 14 },
        ],
      },
      props: {},
    },
    titre1_contenu: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 16,
      },
      props: {},
    },
    titre1_generique: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
        marginBottom: 0,
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    titre2_contenu: {
      style: {
        color: palettes.App['Flolend 1'],
        fontFamily: 'Montserrat_500Medium',
        fontSize: 15,
        marginBottom: 10,
      },
      props: {},
    },
    titre_2_generique: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Tablet, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 16 },
        ],
        marginBottom: [
          { minWidth: Breakpoints.Mobile, value: '1%' },
          { minWidth: Breakpoints.Laptop, value: '1%' },
        ],
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    titre_carte_affiche: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 13 },
          { minWidth: Breakpoints.Desktop, value: 17 },
          { minWidth: Breakpoints.BigScreen, value: 21 },
          { minWidth: Breakpoints.Laptop, value: 13 },
        ],
        lineHeight: 19,
        marginBottom: { minWidth: Breakpoints.Laptop, value: '1%' },
      },
      props: {},
    },
    titre_carte_esg: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginBottom: 8,
      },
      props: {},
    },
    titre_cartes_risques: {
      style: {
        color: [
          { minWidth: Breakpoints.Mobile, value: palettes.App.blanc_flolend },
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
        ],
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Mobile, value: 14 },
        ],
        textAlign: 'left',
      },
      props: {},
    },
    titre_colonne_footer: {
      style: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.blanc_flolend,
        },
        fontFamily: {
          minWidth: Breakpoints.Laptop,
          value: 'Montserrat_600SemiBold',
        },
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
      },
      props: {},
    },
    titre_ecran_message: {
      style: {
        color: [
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
          { minWidth: Breakpoints.Mobile, value: palettes.App.Communial_White },
        ],
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 18 },
          { minWidth: Breakpoints.Desktop, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
          { minWidth: Breakpoints.Mobile, value: 18 },
        ],
        lineHeight: 25,
        marginTop: [
          { minWidth: Breakpoints.Laptop, value: 0 },
          { minWidth: Breakpoints.Mobile, value: 10 },
        ],
        paddingLeft: [
          { minWidth: Breakpoints.Mobile, value: 25 },
          { minWidth: Breakpoints.Laptop, value: 0 },
        ],
        paddingRight: [
          { minWidth: Breakpoints.Mobile, value: 25 },
          { minWidth: Breakpoints.Laptop, value: 0 },
        ],
        textAlign: 'center',
      },
      props: {},
    },
    titre_encart_metrique_clef_projet: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
      },
      props: {},
    },
    titre_encart_projet_eventail: {
      style: {
        color: [
          { minWidth: Breakpoints.Laptop, value: palettes.App.blanc_flolend },
          { minWidth: Breakpoints.Mobile, value: palettes.App['Flolend 5'] },
        ],
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
          { minWidth: Breakpoints.Tablet, value: 20 },
          { minWidth: Breakpoints.Laptop, value: 18 },
          { minWidth: Breakpoints.Desktop, value: 22 },
        ],
      },
      props: {},
    },
    titre_encart_publications: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 15 },
          { minWidth: Breakpoints.Desktop, value: 19 },
          { minWidth: Breakpoints.BigScreen, value: 23 },
          { minWidth: Breakpoints.Laptop, value: 15 },
        ],
        lineHeight: 21,
      },
      props: {},
    },
    titre_magistral: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 26 },
          { minWidth: Breakpoints.Laptop, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 26 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
          { minWidth: Breakpoints.Tablet, value: 28 },
        ],
        marginBottom: 0,
        paddingLeft: { minWidth: Breakpoints.Laptop, value: 0 },
        paddingRight: { minWidth: Breakpoints.Laptop, value: 0 },
        textAlign: 'left',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    titre_option_doss_invest: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 21 },
          { minWidth: Breakpoints.BigScreen, value: 25 },
          { minWidth: Breakpoints.Mobile, value: 17 },
          { minWidth: Breakpoints.Laptop, value: 17 },
          { minWidth: Breakpoints.Tablet, value: 19 },
        ],
      },
      props: {},
    },
    titre_pipe_invest: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 26 },
          { minWidth: Breakpoints.Desktop, value: 26 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
          { minWidth: Breakpoints.Laptop, value: 22 },
        ],
      },
      props: {},
    },
    titre_pipe_simulation: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 26 },
          { minWidth: Breakpoints.Desktop, value: 26 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
          { minWidth: Breakpoints.Laptop, value: 22 },
        ],
      },
      props: {},
    },
    titre_pipe_test_connaissances: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 26 },
          { minWidth: Breakpoints.Desktop, value: 26 },
          { minWidth: Breakpoints.Laptop, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
        ],
      },
      props: {},
    },
    titre_select_option_sous: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Mobile, value: 13 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        lineHeight: 18,
        marginBottom: 5,
        opacity: 1,
        textAlign: 'justify',
        whiteSpace: 'pre-line',
      },
      props: {},
    },
    titre_tableau: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 20 },
          { minWidth: Breakpoints.Laptop, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
        ],
        textAlign: 'center',
      },
      props: {},
    },
    type_card_ressource: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
      },
      props: {},
    },
    type_encart_publications: {
      style: {
        color: palettes.App['Flolend 2'],
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        lineHeight: 18,
      },
      props: {},
    },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: {}, props: {} },
    'Image 2': { style: { height: 100, width: 100 }, props: {} },
    'Image 3': { style: { height: 100, width: 100 }, props: {} },
    band_proj_images: {
      style: {
        height: [
          { minWidth: Breakpoints.Mobile, value: 210 },
          { minWidth: Breakpoints.Laptop, value: 175 },
          { minWidth: Breakpoints.Desktop, value: 240 },
          { minWidth: Breakpoints.BigScreen, value: 275 },
        ],
        width: [
          { minWidth: Breakpoints.Mobile, value: 150 },
          { minWidth: Breakpoints.Laptop, value: 135 },
          { minWidth: Breakpoints.Desktop, value: 190 },
          { minWidth: Breakpoints.BigScreen, value: 220 },
        ],
      },
      props: {},
    },
  });

export const NumberInputStyles = theme =>
  StyleSheet.create({
    'Number Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    encart_input_nombre: {
      style: {
        borderColor: palettes.App.gris_fonce_flolend,
        borderRadius: 8,
        borderWidth: 1,
        color: palettes.App['Flolend 5'],
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
        padding: 10,
        width: '100%',
      },
      props: { placeholderTextColor: palettes.App.gris_fonce_flolend },
    },
    encart_input_simul: {
      style: {
        borderColor: palettes.App.gris_fonce_flolend,
        borderRadius: 8,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Laptop, value: 16 },
        ],
        padding: 10,
      },
      props: { placeholderTextColor: palettes.App.gris_fonce_flolend },
    },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    'Action button': {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 12,
        fontFamily: 'Inter_500Medium',
        fontSize: 16,
        height: 48,
        marginTop: 25,
        textAlign: 'center',
      },
      props: {},
    },
    Button: {
      style: {
        borderRadius: 8,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
    'action btn tablet': {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 12,
        fontFamily: 'Inter_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.Laptop, value: 20 },
          { minWidth: Breakpoints.Tablet, value: 20 },
        ],
        height: [
          { minWidth: Breakpoints.Mobile, value: 48 },
          { minWidth: Breakpoints.Desktop, value: 60 },
          { minWidth: Breakpoints.BigScreen, value: 80 },
          { minWidth: Breakpoints.Laptop, value: 60 },
          { minWidth: Breakpoints.Tablet, value: 60 },
        ],
        marginTop: 10,
        textAlign: 'center',
      },
      props: {},
    },
    'bouton filtre actif_kyc': {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App['Flolend 4'],
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginLeft: 2,
        marginRight: 2,
        paddingBottom: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 5,
        textAlign: 'center',
        textTransform: 'none',
        width: '49%',
      },
      props: {},
    },
    'bouton filtre inactif kyc': {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App.gris_fonce_flolend,
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        marginLeft: 2,
        marginRight: 2,
        paddingBottom: 5,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 5,
        textAlign: 'center',
        textTransform: 'none',
        width: '49%',
      },
      props: {},
    },
    bouton_classique: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App['Flolend 4'],
        borderRadius: 24,
        borderWidth: 1,
        color: palettes.App['Flolend 5'],
        fontFamily: 'Montserrat_500Medium',
        fontSize: 18,
        height: 46,
        marginBottom: 4,
        textAlign: 'center',
      },
      props: {},
    },
    bouton_filtre_actif: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App['Flolend 4'],
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginLeft: 2,
        marginRight: 2,
        paddingLeft: 24,
        paddingRight: 24,
        textAlign: 'center',
        textTransform: 'none',
        width: '20%',
      },
      props: {},
    },
    bouton_filtre_actif_eventail: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App['Flolend 4'],
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginLeft: 2,
        marginRight: 2,
        minWidth: 125,
        paddingLeft: 24,
        paddingRight: 24,
        textAlign: 'center',
        textTransform: 'none',
      },
      props: {},
    },
    bouton_filtre_inactif: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App.gris_fonce_flolend,
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        marginLeft: 2,
        marginRight: 2,
        paddingLeft: 24,
        paddingRight: 24,
        textAlign: 'center',
        textTransform: 'none',
        width: '20%',
      },
      props: {},
    },
    bouton_filtre_inactif_eventail: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: palettes.App.gris_fonce_flolend,
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Tablet, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 14 },
        ],
        marginLeft: 2,
        marginRight: 2,
        minWidth: 125,
        paddingLeft: 24,
        paddingRight: 24,
        textAlign: 'center',
        textTransform: 'none',
      },
      props: {},
    },
    bouton_fond_blanc: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        color: palettes.App.blanc_flolend,
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
          { minWidth: Breakpoints.Mobile, value: 18 },
          { minWidth: Breakpoints.Tablet, value: 20 },
          { minWidth: Breakpoints.Desktop, value: 26 },
        ],
        height: '100%',
        opacity: 1,
        textAlign: 'center',
        width: '100%',
      },
      props: {},
    },
    bouton_inactif: {
      style: {
        backgroundColor: palettes.App.Internal_Light_Gray,
        borderRadius: 24,
        color: theme.colors.background.brand,
        fontFamily: 'Montserrat_500Medium',
        fontSize: 18,
        height: 46,
        marginBottom: 30,
        textAlign: 'center',
      },
      props: {},
    },
  });

export const DividerStyles = theme =>
  StyleSheet.create({
    Divider: { style: { height: 1 }, props: {} },
    'div infos compte': {
      style: { height: 1, marginBottom: 5, marginTop: 5 },
      props: {},
    },
    'div simul': {
      style: { height: 1, marginBottom: 12, marginTop: 12 },
      props: { color: palettes.App.gris_fonce_flolend },
    },
  });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { style: { flex: 1 }, props: {} } });

export const LinearGradientStyles = theme =>
  StyleSheet.create({
    'Linear Gradient': { style: { flex: 1 }, props: {} },
    gradient_bouton: {
      style: { borderRadius: 48, flex: 1, height: 46, marginTop: 10 },
      props: {},
    },
    'gradient_bouton 2': {
      style: {
        alignSelf: 'center',
        borderRadius: [
          { minWidth: Breakpoints.Mobile, value: 48 },
          { minWidth: Breakpoints.Laptop, value: 15 },
        ],
        height: [
          { minWidth: Breakpoints.Laptop, value: 60 },
          { minWidth: Breakpoints.BigScreen, value: 70 },
          { minWidth: Breakpoints.Desktop, value: 65 },
          { minWidth: Breakpoints.Mobile, value: 46 },
        ],
        overflow: [
          { minWidth: Breakpoints.Laptop, value: 'hidden' },
          { minWidth: Breakpoints.Mobile, value: 'hidden' },
        ],
        width: [
          { minWidth: Breakpoints.Mobile, value: '100%' },
          { minWidth: Breakpoints.Laptop, value: '75%' },
          { minWidth: Breakpoints.Desktop, value: '60%' },
          { minWidth: Breakpoints.BigScreen, value: '50%' },
        ],
      },
      props: {
        color1: palettes.App['Flolend 1'],
        color2: palettes.App['Flolend 2'],
      },
    },
    gradient_bouton_bleu: {
      style: { borderRadius: 48, flex: 1, height: 46, marginTop: 10 },
      props: {},
    },
  });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { style: { flex: 1 }, props: {} } });

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const TableStyles = theme =>
  StyleSheet.create({ Table: { style: { flex: 1 }, props: {} } });

export const TableCellStyles = theme =>
  StyleSheet.create({
    'Table Cell': { style: { flex: 1, flexDirection: 'row' }, props: {} },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    bandeau_indic_eventail: {
      style: {
        alignItems: 'stretch',
        borderColor: {
          minWidth: Breakpoints.Laptop,
          value: theme.colors.border.brand,
        },
        borderTopWidth: { minWidth: Breakpoints.Laptop, value: 1 },
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        paddingBottom: { minWidth: Breakpoints.Laptop, value: 10 },
        paddingTop: { minWidth: Breakpoints.Laptop, value: 10 },
      },
      props: {},
    },
    bookmark_card_ressource: {
      style: {
        alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
        backgroundColor: [
          {
            minWidth: Breakpoints.Mobile,
            value: palettes.App.gris_encart_int_flolend,
          },
          {
            minWidth: Breakpoints.Laptop,
            value: palettes.Brand.Strong_Inverse,
          },
        ],
        borderRadius: [
          { minWidth: Breakpoints.Laptop, value: 100 },
          { minWidth: Breakpoints.Mobile, value: 50 },
        ],
        height: [
          { minWidth: Breakpoints.Laptop, value: 45 },
          { minWidth: Breakpoints.Desktop, value: 55 },
          { minWidth: Breakpoints.BigScreen, value: 65 },
        ],
        justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        padding: 10,
        width: [
          { minWidth: Breakpoints.Laptop, value: 45 },
          { minWidth: Breakpoints.BigScreen, value: 65 },
          { minWidth: Breakpoints.Desktop, value: 55 },
        ],
      },
      props: {},
    },
    bouton_retour_gen_ordi: {
      style: {
        alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
        backgroundColor: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.flolend_gris_nuance_encart,
        },
        borderColor: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.gris_fonce_flolend,
        },
        borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
        borderWidth: { minWidth: Breakpoints.Laptop, value: 1 },
        flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
        gap: [
          { minWidth: Breakpoints.Laptop, value: 5 },
          { minWidth: Breakpoints.Desktop, value: 7 },
        ],
        padding: { minWidth: Breakpoints.Laptop, value: 10 },
      },
      props: {},
    },
    carte_esg: {
      style: {
        alignItems: 'center',
        backgroundColor: [
          {
            minWidth: Breakpoints.Mobile,
            value: palettes.App.flolend_gris_nuance_encart,
          },
          {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.gris_encart_int_flolend,
          },
        ],
        borderColor: [
          { minWidth: Breakpoints.Mobile, value: 'rgba(0, 0, 0, 0)' },
          { minWidth: Breakpoints.Laptop, value: 'rgba(0, 0, 0, 0)' },
        ],
        borderRadius: 8,
        borderWidth: { minWidth: Breakpoints.Laptop, value: 0 },
        flexDirection: 'column',
        height: [
          { minWidth: Breakpoints.Mobile, value: 200 },
          { minWidth: Breakpoints.BigScreen, value: 300 },
          { minWidth: Breakpoints.Laptop, value: 230 },
          { minWidth: Breakpoints.Desktop, value: 265 },
        ],
        justifyContent: 'space-around',
        padding: { minWidth: Breakpoints.Laptop, value: 10 },
        paddingBottom: '1%',
        paddingLeft: '1%',
        paddingRight: '1%',
        paddingTop: '1%',
        width: [
          { minWidth: Breakpoints.Mobile, value: 150 },
          { minWidth: Breakpoints.BigScreen, value: 250 },
          { minWidth: Breakpoints.Laptop, value: 175 },
          { minWidth: Breakpoints.Desktop, value: 200 },
        ],
      },
      props: {},
    },
    cercle_investissement: {
      style: {
        backgroundColor: [
          { minWidth: Breakpoints.Mobile, value: palettes.App['Flolend 2'] },
          { minWidth: Breakpoints.Laptop, value: palettes.App['Flolend 2'] },
        ],
        borderRadius: 100,
        height: 15,
        width: 15,
      },
      props: {},
    },
    cercle_remboursement: {
      style: {
        backgroundColor: palettes.App['Flolend 4'],
        borderRadius: 100,
        height: 15,
        width: 15,
      },
      props: {},
    },
    course_card_ressource: {
      style: {
        backgroundColor: palettes.App.gris_encart_int_flolend,
        borderRadius: 24,
        height: [
          { minWidth: Breakpoints.Desktop, value: 540 },
          { minWidth: Breakpoints.Mobile, value: 420 },
          { minWidth: Breakpoints.BigScreen, value: 660 },
          { minWidth: Breakpoints.Laptop, value: 430 },
        ],
        justifyContent: 'space-between',
        paddingBottom: '5%',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: '5%',
        width: [
          { minWidth: Breakpoints.BigScreen, value: 500 },
          { minWidth: Breakpoints.Mobile, value: 320 },
          { minWidth: Breakpoints.Laptop, value: 350 },
          { minWidth: Breakpoints.Desktop, value: 420 },
        ],
      },
      props: {},
    },
    details_encart_caroussel_projet: {
      style: {
        alignContent: 'center',
        alignItems: 'center',
        gap: 2.5,
        padding: 15,
        width: '100%',
      },
      props: {},
    },
    encart_option_dossier_investisseur: {
      style: {
        alignItems: 'center',
        borderColor: [
          { minWidth: Breakpoints.Laptop, value: palettes.App['Flolend 2'] },
          { minWidth: Breakpoints.Mobile, value: palettes.App['Flolend 2'] },
        ],
        borderRadius: [
          { minWidth: Breakpoints.Mobile, value: 24 },
          { minWidth: Breakpoints.Laptop, value: 24 },
        ],
        borderWidth: [
          { minWidth: Breakpoints.Laptop, value: 1 },
          { minWidth: Breakpoints.Mobile, value: 1 },
        ],
        flexDirection: 'row',
        height: [
          { minWidth: Breakpoints.Laptop, value: 75 },
          { minWidth: Breakpoints.BigScreen, value: 85 },
          { minWidth: Breakpoints.Desktop, value: 80 },
          { minWidth: Breakpoints.Mobile, value: 75 },
        ],
      },
      props: {},
    },
    encart_publications: {
      style: {
        borderBottomWidth: 1,
        borderColor: palettes.App.gris_fonce_flolend,
        flexDirection: 'row',
        height: [
          { minWidth: Breakpoints.Desktop, value: 180 },
          { minWidth: Breakpoints.BigScreen, value: 200 },
          { minWidth: Breakpoints.Laptop, value: 140 },
          { minWidth: Breakpoints.Mobile, value: 120 },
        ],
      },
      props: {},
    },
    encart_question_profil_invest: {
      style: {
        backgroundColor: palettes.App.gris_encart_int_flolend,
        borderRadius: 24,
        marginBottom: [
          { minWidth: Breakpoints.Mobile, value: 10 },
          { minWidth: Breakpoints.Laptop, value: '2%' },
        ],
        minHeight: { minWidth: Breakpoints.Laptop, value: 120 },
        padding: [
          { minWidth: Breakpoints.Mobile, value: 20 },
          { minWidth: Breakpoints.Laptop, value: 20 },
        ],
      },
      props: {},
    },
    'header action': {
      style: {
        alignItems: 'center',
        height: 48,
        justifyContent: 'center',
        width: 48,
      },
      props: {},
    },
    header_classique: {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        paddingLeft: 20,
        paddingTop: [
          { minWidth: Breakpoints.Laptop, value: 10 },
          { minWidth: Breakpoints.Desktop, value: 15 },
          { minWidth: Breakpoints.BigScreen, value: 20 },
        ],
      },
      props: {},
    },
    image_frame_carte_ressource: {
      style: { borderRadius: 16, height: '50%', overflow: 'hidden' },
      props: {},
    },
    image_liste_publications: {
      style: {
        borderColor: theme.colors.background.brand,
        borderRadius: 12,
        flexGrow: 0,
        flexShrink: 0,
        height: [
          { minWidth: Breakpoints.Mobile, value: 90 },
          { minWidth: Breakpoints.Laptop, value: 100 },
          { minWidth: Breakpoints.Desktop, value: 125 },
          { minWidth: Breakpoints.BigScreen, value: 150 },
        ],
        overflow: 'hidden',
        width: [
          { minWidth: Breakpoints.Mobile, value: 96 },
          { minWidth: Breakpoints.Laptop, value: 100 },
          { minWidth: Breakpoints.Desktop, value: 125 },
          { minWidth: Breakpoints.BigScreen, value: 150 },
        ],
      },
      props: {},
    },
    left_side_publicataion: {
      style: { justifyContent: 'space-between', padding: '3%', width: '70%' },
      props: {},
    },
    rating_card_ressource: {
      style: {
        alignItems: 'center',
        backgroundColor: palettes.App.gris_encart_int_flolend,
        borderRadius: 50,
        flexDirection: 'row',
        height: [
          { minWidth: Breakpoints.Laptop, value: 45 },
          { minWidth: Breakpoints.BigScreen, value: 65 },
          { minWidth: Breakpoints.Desktop, value: 55 },
        ],
        justifyContent: 'space-around',
        minWidth: [
          { minWidth: Breakpoints.Mobile, value: '50%' },
          { minWidth: Breakpoints.Laptop, value: '40%' },
          { minWidth: Breakpoints.BigScreen, value: '40%' },
        ],
        padding: 10,
      },
      props: {},
    },
    'screen header view': {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        height: 50,
        justifyContent: 'space-between',
        paddingLeft: 16,
        paddingRight: 16,
      },
      props: {},
    },
    vue_entree_inscription2: {
      style: {
        borderColor: palettes.App.gris_fonce_flolend,
        borderRadius: 8,
        borderWidth: 1,
        marginTop: 4,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
      },
      props: {},
    },
    vue_part_collectee_encart_projet: {
      style: {
        paddingLeft: { minWidth: Breakpoints.Laptop, value: '5%' },
        width: { minWidth: Breakpoints.Laptop, value: '20%' },
      },
      props: {},
    },
    vue_roue_caroussel_projet: {
      style: {
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 10,
        justifyContent: 'space-evenly',
      },
      props: {},
    },
    zone_inf_encart_projet: {
      style: {
        height: { minWidth: Breakpoints.Laptop, value: '50%' },
        justifyContent: {
          minWidth: Breakpoints.Laptop,
          value: 'space-between',
        },
        paddingLeft: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 10 },
        ],
        paddingRight: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 10 },
        ],
      },
      props: {},
    },
    zone_sup_encart_projet: {
      style: { height: { minWidth: Breakpoints.Laptop, value: '50%' } },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    encart_ecran_iban: {
      style: {
        borderColor: palettes.App.Peoplebit_Light_Gray,
        borderRadius: 15,
        borderWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        padding: 10,
      },
      props: { placeholderTextColor: palettes.App.Peoplebit_Light_Gray },
    },
    encart_infos_client: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
      },
      props: { placeholderTextColor: palettes.App.gris_fonce_flolend },
    },
    encart_input_inscription2: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: [
          { minWidth: Breakpoints.Tablet, value: 'PTSans_400Regular' },
          { minWidth: Breakpoints.Mobile, value: 'System' },
        ],
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.Tablet, value: 18 },
        ],
        fontWeight: { minWidth: Breakpoints.Mobile, value: '400' },
      },
      props: { placeholderTextColor: palettes.App.gris_fonce_flolend },
    },
    input_chgmnt_mdp: {
      style: {
        alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
        backgroundColor: palettes.App.gris_encart_int_flolend,
        borderBottomWidth: 1,
        borderColor: palettes.App.gris_fonce_flolend,
        borderLeftWidth: 1,
        borderRadius: 24,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Tablet, value: 18 },
          { minWidth: Breakpoints.Laptop, value: 16 },
          { minWidth: Breakpoints.Desktop, value: 20 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
        height: [
          { minWidth: Breakpoints.Mobile, value: 48 },
          { minWidth: Breakpoints.Laptop, value: '10%' },
        ],
        marginTop: [
          { minWidth: Breakpoints.Mobile, value: 20 },
          { minWidth: Breakpoints.Laptop, value: '4%' },
        ],
        paddingBottom: 8,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 8,
        width: { minWidth: Breakpoints.Laptop, value: '75%' },
      },
      props: { placeholderTextColor: palettes.App.gris_fonce_flolend },
    },
  });

export const SwitchRowStyles = theme =>
  StyleSheet.create({
    'Switch Row': { style: { marginLeft: 0, marginRight: 0 }, props: {} },
  });

export const CheckboxRowStyles = theme =>
  StyleSheet.create({
    'Checkbox Row': { style: { minHeight: 50 }, props: {} },
    question_test_connaissances: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        minHeight: 50,
      },
      props: {
        color: palettes.App['Flolend 4'],
        uncheckedColor: palettes.App.gris_fonce_flolend,
      },
    },
  });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { style: { height: 215 }, props: {} } });

export const SwiperStyles = theme =>
  StyleSheet.create({
    Swiper: { style: { height: 300, width: '100%' }, props: {} },
  });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    Surface: { style: { minHeight: 40 }, props: {} },
    encart_projet_eventail: {
      style: {
        backgroundColor: [
          {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.flolend_gris_nuance_encart,
          },
          { minWidth: Breakpoints.Mobile, value: palettes.Brand.Surface },
        ],
        borderBottomWidth: 1,
        borderColor: [
          {
            minWidth: Breakpoints.Laptop,
            value: palettes.App.gris_fonce_flolend,
          },
          { minWidth: Breakpoints.Mobile, value: theme.colors.border.brand },
        ],
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        height: [
          { minWidth: Breakpoints.Laptop, value: 380 },
          { minWidth: Breakpoints.BigScreen, value: 500 },
          { minWidth: Breakpoints.Desktop, value: 400 },
        ],
        minHeight: 40,
        overflow: 'hidden',
        width: [
          { minWidth: Breakpoints.BigScreen, value: 420 },
          { minWidth: Breakpoints.Desktop, value: 350 },
          { minWidth: Breakpoints.Laptop, value: 310 },
        ],
      },
      props: {},
    },
    surface_esg: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderRadius: 8,
        bottom: 2,
        height: [
          { minWidth: Breakpoints.Laptop, value: 230 },
          { minWidth: Breakpoints.BigScreen, value: 300 },
          { minWidth: Breakpoints.Desktop, value: 265 },
        ],
        minHeight: 40,
        top: 2,
        width: [
          { minWidth: Breakpoints.BigScreen, value: 250 },
          { minWidth: Breakpoints.Desktop, value: 200 },
          { minWidth: Breakpoints.Laptop, value: 175 },
        ],
      },
      props: {},
    },
  });

export const H2Styles = theme =>
  StyleSheet.create({
    H2: {
      style: {
        color: theme.colors.text.strong,
        fontSize: 24,
        fontWeight: 'bold',
      },
      props: {},
    },
  });

export const AccordionGroupStyles = theme =>
  StyleSheet.create({
    Accordion: {
      style: {
        fontSize: 16,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    accordion_indicateurs: {
      style: {
        color: palettes.App['Flolend 5'],
        fontFamily: 'Montserrat_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Tablet, value: 18 },
          { minWidth: Breakpoints.Desktop, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 26 },
        ],
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {
        caretColor: palettes.App.blanc_flolend,
        closedColor: palettes.App.blanc_flolend,
        openColor: palettes.App.blanc_flolend,
      },
    },
  });

export const IconStyles = theme =>
  StyleSheet.create({
    icone_message: { style: {}, props: { color: palettes.App['Flolend 4'] } },
    icone_retour_ordi: {
      style: {},
      props: {
        color: {
          minWidth: Breakpoints.Laptop,
          value: palettes.App.gris_fonce_flolend,
        },
        size: [
          { minWidth: Breakpoints.Laptop, value: 20 },
          { minWidth: Breakpoints.Desktop, value: 25 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
        ],
      },
    },
  });

export const WebViewStyles = theme =>
  StyleSheet.create({
    'HTML View': { style: { flex: 1 }, props: {} },
    'Web View': { style: { flex: 1 }, props: {} },
  });

export const LinearProgressStyles = theme =>
  StyleSheet.create({
    barre_encart_projet: {
      style: {
        width: [
          { minWidth: Breakpoints.Laptop, value: '75%' },
          { minWidth: Breakpoints.Desktop, value: '75%' },
        ],
      },
      props: {
        color: palettes.App['Flolend 4'],
        thickness: 15,
        trackColor: palettes.App.gris_fonce_flolend,
      },
    },
    barre_pipe_invest: {
      style: {
        alignSelf: 'center',
        width: [
          { minWidth: Breakpoints.Laptop, value: '80%' },
          { minWidth: Breakpoints.Mobile, value: '95%' },
        ],
      },
      props: {
        color: palettes.App['Flolend 4'],
        trackColor: palettes.App.gris_fonce_flolend,
      },
    },
  });

export const BlurViewStyles = theme =>
  StyleSheet.create({
    'Blur View': {
      style: { flexBasis: 0, flexGrow: 1, flexShrink: 1 },
      props: {},
    },
  });

export const PickerStyles = theme =>
  StyleSheet.create({
    picker_kyc: {
      style: {
        backgroundColor: palettes.App.gris_encart_int_flolend,
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        marginTop: 4,
      },
      props: {
        dropDownBackgroundColor: palettes.App.gris_encart_int_flolend,
        dropDownBorderColor: palettes.App.gris_fonce_flolend,
        dropDownTextColor: palettes.App.blanc_flolend,
      },
    },
    picker_test_connaissances: {
      style: {
        backgroundColor: palettes.App.gris_encart_int_flolend,
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        marginTop: 4,
      },
      props: {
        dropDownBackgroundColor: palettes.App.gris_encart_int_flolend,
        dropDownBorderColor: palettes.App.gris_fonce_flolend,
        dropDownTextColor: palettes.App.blanc_flolend,
      },
    },
  });

export const BottomSheetStyles = theme =>
  StyleSheet.create({
    'Bottom Sheet': {
      style: {
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 10,
      },
      props: {},
    },
  });

export const CircleStyles = theme =>
  StyleSheet.create({
    Circle: {
      style: {
        alignItems: 'center',
        backgroundColor: theme.colors.branding.primary,
        justifyContent: 'center',
      },
      props: {},
    },
  });

export const SimpleStyleScrollViewStyles = theme =>
  StyleSheet.create({
    scroll_ensemble: {
      style: {
        height: '100%',
        marginBottom: '2%',
        paddingTop: [
          { minWidth: Breakpoints.Laptop, value: 110 },
          { minWidth: Breakpoints.Desktop, value: 160 },
          { minWidth: Breakpoints.BigScreen, value: 210 },
        ],
      },
      props: {},
    },
  });

export const TouchableStyles = theme =>
  StyleSheet.create({
    cartes_affiche: {
      style: {
        height: [
          { minWidth: Breakpoints.Mobile, value: 400 },
          { minWidth: Breakpoints.Laptop, value: 430 },
          { minWidth: Breakpoints.Desktop, value: 485 },
          { minWidth: Breakpoints.BigScreen, value: 540 },
        ],
        width: [
          { minWidth: Breakpoints.Tablet, value: 350 },
          { minWidth: Breakpoints.Laptop, value: 375 },
          { minWidth: Breakpoints.Desktop, value: 425 },
          { minWidth: Breakpoints.BigScreen, value: 475 },
          { minWidth: Breakpoints.Mobile, value: 350 },
        ],
      },
      props: {},
    },
  });

export const CheckboxStyles = theme =>
  StyleSheet.create({
    checkbox_card_affiche: {
      style: {},
      props: {
        color: palettes.App['Flolend 4'],
        size: 18,
        uncheckedColor: palettes.App['Flolend 2'],
      },
    },
  });

export const CircleImageStyles = theme =>
  StyleSheet.create({
    circle_image_card_ressource: {
      style: {
        height: [
          { minWidth: Breakpoints.Laptop, value: 40 },
          { minWidth: Breakpoints.Desktop, value: 45 },
          { minWidth: Breakpoints.BigScreen, value: 50 },
        ],
        width: [
          { minWidth: Breakpoints.Laptop, value: 40 },
          { minWidth: Breakpoints.Desktop, value: 45 },
          { minWidth: Breakpoints.BigScreen, value: 50 },
        ],
      },
      props: {},
    },
  });

export const CircularProgressStyles = theme =>
  StyleSheet.create({
    cercle_esg: {
      style: {
        alignSelf: 'center',
        width: [
          { minWidth: Breakpoints.Mobile, value: '80%' },
          { minWidth: Breakpoints.Laptop, value: '65%' },
        ],
      },
      props: {
        color: palettes.App['Flolend 4'],
        thickness: 10,
        trackColor: palettes.App.gris_fonce_flolend,
      },
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    bouton_voir_tout: {
      style: {
        color: palettes.App.gris_fonce_flolend,
        fontFamily: 'PTSans_700Bold',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 14 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
      },
      props: {},
    },
  });

export const SwitchStyles = theme =>
  StyleSheet.create({
    switch_tabs: {
      style: {
        height: { minWidth: Breakpoints.Laptop, value: 15 },
        width: { minWidth: Breakpoints.Laptop, value: 30 },
      },
      props: {
        activeThumbColor: palettes.App['Flolend 2'],
        activeTrackColor: palettes.App.gris_fonce_flolend,
        inactiveThumbColor: palettes.App['Flolend 2'],
        inactiveTrackColor: palettes.App.gris_fonce_flolend,
      },
    },
  });

export const RadioButtonGroupStyles = theme =>
  StyleSheet.create({
    groupe_question_profil_invest: {
      style: {
        marginRight: [
          { minWidth: Breakpoints.Desktop, value: '75%' },
          { minWidth: Breakpoints.Mobile, value: '25%' },
          { minWidth: Breakpoints.Laptop, value: '66%' },
          { minWidth: Breakpoints.Tablet, value: 200 },
        ],
      },
      props: {},
    },
  });

export const RadioButtonRowStyles = theme =>
  StyleSheet.create({
    questionnaire_radio_button: {
      style: {
        color: palettes.App.blanc_flolend,
        fontFamily: 'PTSans_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 16 },
          { minWidth: Breakpoints.Laptop, value: 14 },
          { minWidth: Breakpoints.Tablet, value: 18 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginRight: [
          { minWidth: Breakpoints.Tablet, value: 25 },
          { minWidth: Breakpoints.Laptop, value: 0 },
        ],
      },
      props: {
        color: palettes.App['Flolend 4'],
        direction: 'row',
        unselectedColor: palettes.App.gris_fonce_flolend,
      },
    },
  });
