import React, { useEffect } from 'react';

/**
 * Composant React qui affiche une URL en plein écran dans un iframe
 * en utilisant les paramètres racine_webkit et token_webkit.
 * @param {string} racine_webkit - La racine de l'URL
 * @param {string} token_webkit - Le token à ajouter à la fin de l'URL
 */
export const Composant = ({ racine_webkit, token_webkit }) => {
  // Concatène les paramètres pour former l'URL complète
  const url = `${racine_webkit}${token_webkit}&lang=fr`;

  useEffect(() => {
    console.log('URL chargée :', url);
  }, [url]);

  return (
    <iframe
      src={url}
      allow="fullscreen"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none',
      }}
      title="Affichage en plein écran"
    />
  );
};
