import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  ScreenContainer,
  Surface,
  TextInput,
  Touchable,
  VideoPlayer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import * as MagicLinkXanoApi from '../apis/MagicLinkXanoApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const MotDePasseOublieScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [bool_erreur, setBool_erreur] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [test_envoi_email, setTest_envoi_email] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.ViewStyles(theme)['header_classique'].style,
              dimensions.width
            )}
          >
            {/* Back */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  height: 48,
                  justifyContent: 'center',
                  width: 48,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={18}
                />
              </Touchable>
            </View>
            {/* Screen Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Mot de passe oublié'}
            </Text>
          </View>
        )}
      </>
      {/* Linear Gradient mobile */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            {...GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
              .props}
            color1={
              (email === ''
                ? palettes.App.gris_fonce_flolend
                : palettes.App['Flolend 2']) ?? palettes.App['Flolend 2']
            }
            color2={
              (email === ''
                ? palettes.App.gris_fonce_flolend
                : palettes.App['Flolend 1']) ?? palettes.App['Flolend 1']
            }
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.LinearGradientStyles(theme)['gradient_bouton']
                  .style,
                {
                  alignSelf: [
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                    { minWidth: Breakpoints.Tablet, value: 'center' },
                  ],
                  borderRadius: { minWidth: Breakpoints.Laptop, value: 15 },
                  bottom: 20,
                  flex: null,
                  height: [
                    { minWidth: Breakpoints.BigScreen, value: 70 },
                    { minWidth: Breakpoints.Desktop, value: 65 },
                    { minWidth: Breakpoints.Laptop, value: 60 },
                  ],
                  marginTop: null,
                  position: 'absolute',
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '80%' },
                    { minWidth: Breakpoints.Tablet, value: '50%' },
                    { minWidth: Breakpoints.Laptop, value: '75%' },
                    { minWidth: Breakpoints.Desktop, value: '60%' },
                    { minWidth: Breakpoints.BigScreen, value: '50%' },
                  ],
                }
              ),
              dimensions.width
            )}
          >
            {/* Confirmer actif */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (email === '') {
                      return;
                    }
                    const retour_api = (
                      await MagicLinkXanoApi.generateMagicLinkMdpGET(
                        Constants,
                        { email: email }
                      )
                    )?.json;
                    if (retour_api?.message.success) {
                      setTest_envoi_email(true);
                      setBool_erreur(false);
                    } else {
                      setBool_erreur(true);
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc'].style,
                  {
                    borderColor: null,
                    borderRadius: { minWidth: Breakpoints.Laptop, value: null },
                    borderWidth: 0,
                    fontSize: [
                      { minWidth: Breakpoints.Laptop, value: 22 },
                      { minWidth: Breakpoints.BigScreen, value: 30 },
                    ],
                    height: [
                      { minWidth: Breakpoints.Desktop, value: 60 },
                      { minWidth: Breakpoints.BigScreen, value: 70 },
                    ],
                    marginBottom: 0,
                    marginTop: 0,
                  }
                ),
                dimensions.width
              )}
              title={'Confirmer'}
            />
          </LinearGradient>
        )}
      </>
      {/* vue générale */}
      <View
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.Laptop, value: '100%' },
            justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
          },
          dimensions.width
        )}
      >
        {/* vue de contenu */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: { minWidth: Breakpoints.Laptop, value: 'row' },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
            },
            dimensions.width
          )}
        >
          {/* Vue vidéo */}
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    paddingRight: { minWidth: Breakpoints.Laptop, value: '2%' },
                    width: { minWidth: Breakpoints.Laptop, value: '50%' },
                  },
                  dimensions.width
                )}
              >
                {/* Fetch video */}
                <BaseXanoApi.FetchArrierePlanDemarrageGET>
                  {({ loading, error, data, refetchArrierePlanDemarrage }) => {
                    const fetchVideoData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            height: {
                              minWidth: Breakpoints.Laptop,
                              value: '100%',
                            },
                          },
                          dimensions.width
                        )}
                      >
                        <Surface
                          elevation={0}
                          {...GlobalStyles.SurfaceStyles(theme)['Surface']
                            .props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SurfaceStyles(theme)['Surface']
                                .style,
                              {
                                borderRadius: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 24,
                                },
                                height: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '100%',
                                },
                                overflow: {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'hidden',
                                },
                                width: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '100%',
                                },
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          <VideoPlayer
                            posterResizeMode={'cover'}
                            rate={1}
                            resizeMode={'cover'}
                            usePoster={false}
                            volume={0.5}
                            {...GlobalStyles.VideoPlayerStyles(theme)['Video']
                              .props}
                            isLooping={true}
                            isMuted={true}
                            shouldPlay={true}
                            source={imageSource(`${fetchVideoData}`)}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.VideoPlayerStyles(theme)['Video']
                                  .style,
                                {
                                  height: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                  width: {
                                    minWidth: Breakpoints.Laptop,
                                    value: '100%',
                                  },
                                }
                              ),
                              dimensions.width
                            )}
                            useNativeControls={false}
                          />
                        </Surface>
                      </View>
                    );
                  }}
                </BaseXanoApi.FetchArrierePlanDemarrageGET>
              </View>
            )}
          </>
          {/* Vue formulaire */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: {
                  minWidth: Breakpoints.Laptop,
                  value: palettes.App.flolend_gris_nuance_encart,
                },
                borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                gap: [
                  { minWidth: Breakpoints.Mobile, value: 10 },
                  { minWidth: Breakpoints.Desktop, value: 50 },
                  { minWidth: Breakpoints.BigScreen, value: 70 },
                ],
                justifyContent: 'space-evenly',
                padding: { minWidth: Breakpoints.Laptop, value: 30 },
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 20,
                width: { minWidth: Breakpoints.Laptop, value: '50%' },
              },
              dimensions.width
            )}
          >
            {/* Heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['titre_magistral'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['titre_magistral'].style,
                dimensions.width
              )}
            >
              {'Changement de votre mot de passe'}
            </Text>
            {/* sub heading */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['explications'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['explications'].style,
                dimensions.width
              )}
            >
              {
                'Veuillez entrer votre adresse email. Nous allons vous envoyer un lien par email pour changer votre mot de passe.'
              }
            </Text>
            {/* Encart mail */}
            <View
              {...GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                .props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['vue_entree_inscription2']
                    .style,
                  {
                    backgroundColor: {
                      minWidth: Breakpoints.Laptop,
                      value: palettes.App.gris_encart_int_flolend,
                    },
                  }
                ),
                dimensions.width
              )}
            >
              {/* Adresse email */}
              <TextInput
                autoCapitalize={'none'}
                autoCorrect={true}
                changeTextDelay={500}
                onChangeText={newAdresseEmailValue => {
                  try {
                    setEmail(newAdresseEmailValue);
                  } catch (err) {
                    console.error(err);
                  }
                }}
                webShowOutline={true}
                {...GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                  .props}
                editable={true}
                placeholder={'adresse email'}
                placeholderTextColor={palettes.App.gris_fonce_flolend}
                secureTextEntry={false}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextInputStyles(theme)['encart_infos_client']
                    .style,
                  dimensions.width
                )}
                value={email}
              />
            </View>
            {/* Linear Gradient ordi */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'gradient_bouton'
                  ].props}
                  color1={
                    (email === ''
                      ? palettes.App.gris_fonce_flolend
                      : palettes.App['Flolend 2']) ?? palettes.App['Flolend 2']
                  }
                  color2={
                    (email === ''
                      ? palettes.App.gris_fonce_flolend
                      : palettes.App['Flolend 1']) ?? palettes.App['Flolend 1']
                  }
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'gradient_bouton'
                      ].style,
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        borderRadius: {
                          minWidth: Breakpoints.Laptop,
                          value: 15,
                        },
                        flex: null,
                        height: [
                          { minWidth: Breakpoints.Laptop, value: 60 },
                          { minWidth: Breakpoints.Desktop, value: 65 },
                          { minWidth: Breakpoints.BigScreen, value: 70 },
                        ],
                        marginTop: null,
                        width: [
                          { minWidth: Breakpoints.Tablet, value: '50%' },
                          { minWidth: Breakpoints.Desktop, value: '60%' },
                          { minWidth: Breakpoints.BigScreen, value: '50%' },
                          { minWidth: Breakpoints.Laptop, value: '75%' },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Confirmer actif */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          if (email === '') {
                            return;
                          }
                          const retour_api = (
                            await MagicLinkXanoApi.generateMagicLinkMdpGET(
                              Constants,
                              { email: email }
                            )
                          )?.json;
                          if (retour_api?.message.success) {
                            setTest_envoi_email(true);
                            setBool_erreur(false);
                          } else {
                            setBool_erreur(true);
                          }
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_fond_blanc']
                          .style,
                        {
                          borderColor: null,
                          borderRadius: {
                            minWidth: Breakpoints.Laptop,
                            value: null,
                          },
                          borderWidth: 0,
                          fontSize: [
                            { minWidth: Breakpoints.Laptop, value: 22 },
                            { minWidth: Breakpoints.BigScreen, value: 30 },
                          ],
                          height: [
                            { minWidth: Breakpoints.Desktop, value: 60 },
                            { minWidth: Breakpoints.BigScreen, value: 70 },
                          ],
                          marginBottom: 0,
                          marginTop: 0,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Confirmer'}
                  />
                </LinearGradient>
              )}
            </>
            {/* Message succès */}
            <>
              {!test_envoi_email ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['message_erreur'].style,
                      {
                        color: palettes.App['Flolend 4'],
                        fontSize: {
                          minWidth: Breakpoints.BigScreen,
                          value: 22,
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {'Lien envoyé'}
                </Text>
              )}
            </>
            {/* Message erreur */}
            <>
              {!bool_erreur ? null : (
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['message_erreur'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['message_erreur'].style,
                      {
                        fontSize: {
                          minWidth: Breakpoints.BigScreen,
                          value: 22,
                        },
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "Une erreur s'est produite, veuillez vérifier votre adresse email puis réessayer."
                  }
                </Text>
              )}
            </>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(MotDePasseOublieScreen);
