// Met au format les nombres.
const format_nombre = (nombre, decimales) => {
  // Vérifier si le nombre est un nombre
  if (isNaN(nombre)) {
    return 'Entrée invalide';
  }

  // Arrondir le nombre au nombre de décimales requis
  let nombreArrondi = parseFloat(nombre).toFixed(decimales);

  // Séparer la partie entière et la partie décimale
  let [partieEntiere, partieDecimale] = nombreArrondi.split('.');

  // Formater la partie entière avec des espaces tous les 3 chiffres
  partieEntiere = partieEntiere.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Retourner le nombre formaté
  return partieEntiere + (partieDecimale ? ',' + partieDecimale : '');
};

export default format_nombre;
