import React from 'react';
import {
  Button,
  Icon,
  LinearGradient,
  LinearProgress,
  ScreenContainer,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  id_transfer_1_1ter: 2,
  montant_restant_projet: 7000,
  montant_sous: 10000,
  nom_projet: 'Eclipse',
};

const Souscription13ListeDattenteScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [affichage_chargement, setAffichage_chargement] = React.useState(false);
  const [bouton, setBouton] = React.useState(false);
  const [select_annuler, setSelect_annuler] = React.useState(false);
  const [select_liste_attente, setSelect_liste_attente] = React.useState(false);
  const [select_titres_restants, setSelect_titres_restants] =
    React.useState(false);
  // Met à jour la base de Souscriptions lors du paiement de la souscription par le client (MAJ statut et date).
  const Souscription_payee = id_sous_airtable => {
    // Obtenez la date et l'heure actuelles au format ISO
    const dateSignature = new Date().toISOString();

    // Remplacez 'VOTRE_API_KEY' par votre clé d'API Airtable
    const apiKey =
      'patTnyhlx9q1uOzB6.9947d51407877f20352b6cc565b385c99655f28c39342eb0545d725554af6cb1';

    // Remplacez 'VOTRE_ID_BASE_AIRTABLE' par l'ID réel de votre base de données Airtable
    const baseId = 'apprRcKudN6J0jkZj';

    // Remplacez 'NomDeVotreTable' par le nom réel de votre table dans Airtable
    const tableName = 'Souscriptions';

    // URL de l'API Airtable
    const apiUrl = `https://api.airtable.com/v0/${baseId}/${tableName}/${id_sous_airtable}`;

    // En-têtes de la requête PATCH
    const headers = {
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    };

    // Corps de la requête PATCH
    const body = JSON.stringify({
      fields: {
        statut: 'Souscription validée',
        date_reglement: dateSignature,
      },
    });

    // Effectuez la requête PATCH
    fetch(apiUrl, {
      method: 'PATCH',
      headers: headers,
      body: body,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(
            `La requête a échoué avec le statut ${response.status}`
          );
        }
        console.log(
          `Souscription réglée pour le record ID ${id_sous_airtable}`
        );
      })
      .catch(error => console.error(error));
  };

  // Calcule la souscription placée en liste d'attente.
  const placement_liste_attente = (
    montant_souscrit,
    montant_pouvant_e_souscrit
  ) => {
    const souscription_en_attente =
      montant_souscrit - montant_pouvant_e_souscrit;
    return souscription_en_attente;
  };

  // Poste une souscription en liste d'attente.
  const post_sous_attente = async (id_client, id_projet, montant) => {
    const apiKey =
      'patTnyhlx9q1uOzB6.9947d51407877f20352b6cc565b385c99655f28c39342eb0545d725554af6cb1';
    const baseId = 'apprRcKudN6J0jkZj';
    const tableName = 'Souscriptions';

    const apiUrl = `https://api.airtable.com/v0/${baseId}/${tableName}`;
    const headers = new Headers({
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    });

    const recordData = {
      fields: {
        Client: [id_client],
        Projet: id_projet,
        montant_souscrit_euros: montant,
        statut: "Liste d'attente",
      },
      typecast: true,
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(recordData),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.id) {
          return data.id;
        } else {
          console.error("L'ID de la ligne n'a pas été renvoyé par Airtable.");
          return null;
        }
      } else {
        throw new Error(
          'Erreur lors de la création du record dans Airtable : ' +
            response.status
        );
      }
    } catch (error) {
      console.error(
        'Erreur lors de la création du record dans Airtable :',
        error
      );
      return null;
    }
  };

  // Poste la souscription dans la base Airtable.
  const post_sous_classique = async (id_client, id_projet, montant) => {
    const apiKey =
      'patTnyhlx9q1uOzB6.9947d51407877f20352b6cc565b385c99655f28c39342eb0545d725554af6cb1';
    const baseId = 'apprRcKudN6J0jkZj';
    const tableName = 'Souscriptions';

    const apiUrl = `https://api.airtable.com/v0/${baseId}/${tableName}`;
    const headers = new Headers({
      Authorization: `Bearer ${apiKey}`,
      'Content-Type': 'application/json',
    });

    const recordData = {
      fields: {
        Client: [id_client],
        Projet: id_projet,
        montant_souscrit_euros: montant,
        statut: 'Bulletin à signer',
      },
      typecast: true,
    };

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(recordData),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      if (response.ok) {
        const data = await response.json();
        if (data.id) {
          return data.id;
        } else {
          console.error("L'ID de la ligne n'a pas été renvoyé par Airtable.");
          return null;
        }
      } else {
        throw new Error(
          'Erreur lors de la création du record dans Airtable : ' +
            response.status
        );
      }
    } catch (error) {
      console.error(
        'Erreur lors de la création du record dans Airtable :',
        error
      );
      return null;
    }
  };
  const baseXanoCreationSousAttentePOST =
    BaseXanoApi.useCreationSousAttentePOST();
  const baseXanoCreationSousPOST = BaseXanoApi.useCreationSousPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: { minWidth: Breakpoints.Laptop, value: dimensions.height },
        },
        dimensions.width
      )}
    >
      {/* Chargement en cours mobile */}
      <>
        {!(
          !(dimensions.width >= Breakpoints.Laptop) && affichage_chargement
        ) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                bottom: 20,
                flexDirection: 'row',
                gap: 10,
                paddingLeft: 20,
                position: 'absolute',
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            <ActivityIndicator
              animating={true}
              hidesWhenStopped={true}
              size={'small'}
              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                'Activity Indicator'
              ].props}
              color={palettes.App['Flolend 4']}
              style={StyleSheet.applyWidth(
                GlobalStyles.ActivityIndicatorStyles(theme)[
                  'Activity Indicator'
                ].style,
                dimensions.width
              )}
            />
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                dimensions.width
              )}
            >
              {'Chargement en cours, veuillez patienter.'}
            </Text>
          </View>
        )}
      </>
      {/* Linear Gradient mobile */}
      <>
        {!(
          !(dimensions.width >= Breakpoints.Laptop) && !affichage_chargement
        ) ? null : (
          <LinearGradient
            endX={100}
            endY={100}
            startX={0}
            startY={0}
            color1={
              (bouton
                ? palettes.App['Flolend 2']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 2']
            }
            color2={
              (bouton
                ? palettes.App['Flolend 4']
                : palettes.App.gris_fonce_flolend) ?? palettes.App['Flolend 4']
            }
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
                alignSelf: [
                  { minWidth: Breakpoints.Tablet, value: 'center' },
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                ],
                borderRadius: [
                  { minWidth: Breakpoints.Laptop, value: 15 },
                  { minWidth: Breakpoints.Mobile, value: 46 },
                ],
                bottom: 20,
                flex: [
                  { minWidth: Breakpoints.BigScreen, value: null },
                  { minWidth: Breakpoints.Laptop, value: null },
                  { minWidth: Breakpoints.Tablet, value: null },
                ],
                height: [
                  { minWidth: Breakpoints.Laptop, value: 60 },
                  { minWidth: Breakpoints.BigScreen, value: 70 },
                ],
                justifyContent: {
                  minWidth: Breakpoints.Tablet,
                  value: 'center',
                },
                overflow: [
                  { minWidth: Breakpoints.Laptop, value: 'hidden' },
                  { minWidth: Breakpoints.Mobile, value: 'hidden' },
                ],
                position: 'absolute',
                width: [
                  { minWidth: Breakpoints.Tablet, value: '50%' },
                  { minWidth: Breakpoints.Mobile, value: '80%' },
                ],
                zIndex: 99,
              },
              dimensions.width
            )}
          >
            {/* Bouton investir actif */}
            <Button
              accessible={true}
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    if (!bouton) {
                      return;
                    }
                    setAffichage_chargement(true);
                    if (select_annuler) {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('BottomTabNavigator', {
                        screen: 'EventailProjetsScreen',
                      });
                    } else {
                      if (select_titres_restants) {
                        const retour_sous_cree = (
                          await baseXanoCreationSousPOST.mutateAsync({
                            id_projet:
                              props.route?.params?.id_transfer_1_1ter ??
                              defaultProps.id_transfer_1_1ter,
                            montant_souscrit:
                              props.route?.params?.montant_restant_projet ??
                              defaultProps.montant_restant_projet,
                          })
                        )?.json;
                        if (
                          (props.route?.params?.montant_sous ??
                            defaultProps.montant_sous) -
                            (props.route?.params?.montant_restant_projet ??
                              defaultProps.montant_restant_projet) >=
                          Constants['min_sous_cb']
                        ) {
                          (
                            await baseXanoCreationSousAttentePOST.mutateAsync({
                              id_projet:
                                props.route?.params?.id_transfer_1_1ter ??
                                defaultProps.id_transfer_1_1ter,
                              montant:
                                (props.route?.params?.montant_sous ??
                                  defaultProps.montant_sous) -
                                (props.route?.params?.montant_restant_projet ??
                                  defaultProps.montant_restant_projet),
                            })
                          )?.json;
                        }
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace(
                          'Souscription14ValidationAttenteScreen',
                          {
                            id_souscription: retour_sous_cree?.id,
                            souscription_fractionnee: true,
                          }
                        );
                      } else {
                        (
                          await baseXanoCreationSousAttentePOST.mutateAsync({
                            id_projet:
                              props.route?.params?.id_transfer_1_1ter ??
                              defaultProps.id_transfer_1_1ter,
                            montant:
                              props.route?.params?.montant_sous ??
                              defaultProps.montant_sous,
                          })
                        )?.json;
                        if (navigation.canGoBack()) {
                          navigation.popToTop();
                        }
                        navigation.replace(
                          'Souscription14ValidationAttenteScreen',
                          {
                            id_souscription: null,
                            souscription_fractionnee: false,
                          }
                        );
                      }
                    }

                    setAffichage_chargement(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['bouton_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['bouton_classique'].style,
                  {
                    borderColor: palettes.App.Transparent,
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 22 },
                      { minWidth: Breakpoints.BigScreen, value: 26 },
                    ],
                    height: '100%',
                    marginBottom: null,
                    width: '100%',
                  }
                ),
                dimensions.width
              )}
              title={'Continuer'}
            />
          </LinearGradient>
        )}
      </>
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        style={StyleSheet.applyWidth(
          {
            height: { minWidth: Breakpoints.BigScreen, value: '100%' },
            justifyContent: {
              minWidth: Breakpoints.BigScreen,
              value: 'space-between',
            },
            paddingTop: [
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
            ],
            width: { minWidth: Breakpoints.BigScreen, value: '100%' },
          },
          dimensions.width
        )}
      >
        {/* En-tête */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'column',
              marginBottom: [
                { minWidth: Breakpoints.Laptop, value: 10 },
                { minWidth: Breakpoints.Mobile, value: 10 },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginTop: 10,
                paddingLeft: [
                  { minWidth: Breakpoints.Laptop, value: 0 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '80%' },
                    { minWidth: Breakpoints.Laptop, value: '100%' },
                  ],
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['titre_pipe_invest'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['titre_pipe_invest'].style,
                  dimensions.width
                )}
              >
                {'Investir dans '}
                {props.route?.params?.nom_projet ?? defaultProps.nom_projet}
              </Text>
            </View>
            {/* View 2 */}
            <>
              {dimensions.width >= Breakpoints.Laptop ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', width: '20%' },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('ProjetScreen', {
                          id_transfere:
                            props.route?.params?.id_transfer_1_1ter ??
                            defaultProps.id_transfer_1_1ter,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Icon
                      size={24}
                      color={palettes.App.gris_fonce_flolend}
                      name={'Ionicons/exit-outline'}
                    />
                  </Touchable>
                </View>
              )}
            </>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
              },
              dimensions.width
            )}
          >
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                .props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['sous_titre_pipe_invest']
                    .style,
                  {
                    fontSize: { minWidth: Breakpoints.BigScreen, value: 26 },
                    paddingLeft: [
                      { minWidth: Breakpoints.Laptop, value: 0 },
                      { minWidth: Breakpoints.Mobile, value: 16 },
                    ],
                  }
                ),
                dimensions.width
              )}
            >
              {'Etape 1/4 : Montant de la souscription'}
            </Text>
          </View>
          {/* Bandeau progression */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: palettes.App.gris_encart_int_flolend,
                height: 50,
                justifyContent: 'space-evenly',
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: [
                    { minWidth: Breakpoints.Mobile, value: '90%' },
                    { minWidth: Breakpoints.Laptop, value: '80%' },
                  ],
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'center',
                    width: '25%',
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)['frise_pipe_invest'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['frise_pipe_invest'].style,
                    dimensions.width
                  )}
                >
                  {'Montant de la souscription'}
                </Text>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 2 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Revue de la documentation'}
                </Text>
              </View>
              {/* View 3 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 3 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Signature'}
                </Text>
              </View>
              {/* View 4 */}
              <View
                style={StyleSheet.applyWidth(
                  { width: '25%' },
                  dimensions.width
                )}
              >
                {/* Text 4 */}
                <Text
                  accessible={true}
                  selectable={false}
                  {...GlobalStyles.TextStyles(theme)[
                    'frise_pipe_invest_unselected'
                  ].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)[
                      'frise_pipe_invest_unselected'
                    ].style,
                    dimensions.width
                  )}
                >
                  {'Paiement'}
                </Text>
              </View>
            </View>
            <LinearProgress
              animationDuration={500}
              color={theme.colors.branding.primary}
              indeterminate={false}
              isAnimated={true}
              lineCap={'round'}
              showTrack={true}
              thickness={10}
              trackColor={theme.colors.border.brand}
              trackLineCap={'round'}
              {...GlobalStyles.LinearProgressStyles(theme)['barre_pipe_invest']
                .props}
              style={StyleSheet.applyWidth(
                GlobalStyles.LinearProgressStyles(theme)['barre_pipe_invest']
                  .style,
                dimensions.width
              )}
              value={25}
            />
          </View>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
              alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
              backgroundColor: {
                minWidth: Breakpoints.Laptop,
                value: palettes.App.gris_encart_int_flolend,
              },
              borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
              marginLeft: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '10%' },
              marginTop: { minWidth: Breakpoints.Laptop, value: '2%' },
              padding: { minWidth: Breakpoints.Laptop, value: 30 },
            },
            dimensions.width
          )}
        >
          {/* Mode de paiement */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: { minWidth: Breakpoints.Laptop, value: 'center' },
                marginTop: [
                  { minWidth: Breakpoints.Mobile, value: 10 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 0 },
                ],
              },
              dimensions.width
            )}
          >
            {/* Principe */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignSelf: {
                    minWidth: Breakpoints.Laptop,
                    value: 'flex-start',
                  },
                  marginBottom: 10,
                },
                dimensions.width
              )}
            >
              {/* Heading */}
              <Text
                accessible={true}
                selectable={false}
                {...GlobalStyles.TextStyles(theme)['explications'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['explications'].style,
                  dimensions.width
                )}
              >
                {
                  'Le montant de votre souscription excède le montant des titres encore disponibles à la vente.\n\nVous disposez de plusieurs options :'
                }
              </Text>
            </View>
            {/* Touchable restant + attente unselected */}
            <>
              {!(
                (props.route?.params?.montant_restant_projet ??
                  defaultProps.montant_restant_projet) >=
                Constants['min_sous_cb']
              ) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setSelect_titres_restants(true);
                      setSelect_annuler(false);
                      setSelect_liste_attente(false);
                      setBouton(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { width: { minWidth: Breakpoints.Laptop, value: '75%' } },
                    dimensions.width
                  )}
                >
                  {/* Vue restant + attente */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: [
                          {
                            minWidth: Breakpoints.Mobile,
                            value: palettes.App.Icon_Dark_Gray,
                          },
                          {
                            minWidth: Breakpoints.Laptop,
                            value: select_titres_restants
                              ? palettes.App['Flolend 4']
                              : palettes.App.Icon_Dark_Gray,
                          },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: select_titres_restants
                              ? palettes.App['Flolend 4']
                              : palettes.App.gris_fonce_flolend,
                          },
                        ],
                        borderRadius: 12,
                        borderWidth: 1,
                        paddingBottom: [
                          { minWidth: Breakpoints.Mobile, value: 5 },
                          { minWidth: Breakpoints.Laptop, value: '1%' },
                        ],
                        paddingLeft: [
                          { minWidth: Breakpoints.Mobile, value: 5 },
                          { minWidth: Breakpoints.Laptop, value: '2%' },
                        ],
                        paddingRight: [
                          { minWidth: Breakpoints.Mobile, value: 5 },
                          { minWidth: Breakpoints.Laptop, value: '2%' },
                        ],
                        paddingTop: [
                          { minWidth: Breakpoints.Mobile, value: 5 },
                          { minWidth: Breakpoints.Laptop, value: '1%' },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center', flexDirection: 'row' },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '75%' },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'titre_select_option_sous'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'titre_select_option_sous'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {'Souscrire au montant restant'}
                        </Text>
                        {/* Text 2 */}
                        <Text
                          accessible={true}
                          selectable={false}
                          {...GlobalStyles.TextStyles(theme)[
                            'explication_select_option_souscription'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)[
                              'explication_select_option_souscription'
                            ].style,
                            dimensions.width
                          )}
                        >
                          {(props.route?.params?.montant_sous ??
                            defaultProps.montant_sous) -
                            (props.route?.params?.montant_restant_projet ??
                              defaultProps.montant_restant_projet) <
                          Constants['min_sous_cb']
                            ? 'Souscrivez au montant restant sur la souscription.'
                            : "L'excédent de votre souscription sera placé en liste d'attente."}
                        </Text>
                        <>
                          {!(
                            (props.route?.params?.montant_sous ??
                              defaultProps.montant_sous) -
                              (props.route?.params?.montant_restant_projet ??
                                defaultProps.montant_restant_projet) >=
                            Constants['min_sous_cb']
                          ) ? null : (
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'montants_options_souscription'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'montants_options_souscription'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Montant souscrit : '}
                              {format_nombre(
                                props.route?.params?.montant_restant_projet ??
                                  defaultProps.montant_restant_projet,
                                2
                              )}
                              {" €\nMontant en liste d'attente : "}
                              {format_nombre(
                                (props.route?.params?.montant_sous ??
                                  defaultProps.montant_sous) -
                                  (props.route?.params
                                    ?.montant_restant_projet ??
                                    defaultProps.montant_restant_projet),
                                2
                              )}
                              {' €'}
                            </Text>
                          )}
                        </>
                        {/* Text 3 */}
                        <>
                          {(props.route?.params?.montant_sous ??
                            defaultProps.montant_sous) -
                            (props.route?.params?.montant_restant_projet ??
                              defaultProps.montant_restant_projet) >=
                          Constants['min_sous_cb'] ? null : (
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)[
                                'montants_options_souscription'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.TextStyles(theme)[
                                  'montants_options_souscription'
                                ].style,
                                dimensions.width
                              )}
                            >
                              {'Montant souscrit : '}
                              {format_nombre(
                                props.route?.params?.montant_restant_projet ??
                                  defaultProps.montant_restant_projet,
                                2
                              )}
                              {' €'}
                            </Text>
                          )}
                        </>
                      </View>
                      {/* View 2 */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', width: '25%' },
                          dimensions.width
                        )}
                      >
                        {/* Icone */}
                        <Icon
                          color={
                            (select_titres_restants
                              ? palettes.App['Flolend 4']
                              : palettes.App.gris_fonce_flolend) ??
                            palettes.App.Icon_Dark_Gray
                          }
                          name={'Foundation/graph-pie'}
                          size={
                            (!(dimensions.width >= Breakpoints.Laptop)
                              ? 35
                              : !(dimensions.width >= Breakpoints.Desktop)
                              ? 45
                              : dimensions.width >= Breakpoints.BigScreen
                              ? 60
                              : 55) ?? 40
                          }
                        />
                      </View>
                    </View>
                  </View>
                </Touchable>
              )}
            </>
            <Spacer bottom={8} left={8} right={8} top={8} />
            {/* Touchable tout en attente unselected */}
            <Touchable
              onPress={() => {
                try {
                  setSelect_titres_restants(false);
                  setSelect_liste_attente(true);
                  setSelect_annuler(false);
                  setBouton(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { width: { minWidth: Breakpoints.Laptop, value: '75%' } },
                dimensions.width
              )}
            >
              {/* Vue generale */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Icon_Dark_Gray,
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: select_liste_attente
                          ? palettes.App['Flolend 4']
                          : palettes.App.Icon_Dark_Gray,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: select_liste_attente
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend,
                      },
                    ],
                    borderRadius: 12,
                    borderWidth: 1,
                    paddingBottom: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                    paddingTop: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '75%' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)[
                        'titre_select_option_sous'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)[
                          'titre_select_option_sous'
                        ].style,
                        dimensions.width
                      )}
                    >
                      {'Conserver ma souscription actuelle'}
                    </Text>
                    {/* Text 4 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)[
                        'explication_select_option_souscription'
                      ].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'explication_select_option_souscription'
                          ].style,
                          {
                            fontSize: {
                              minWidth: Breakpoints.BigScreen,
                              value: 24,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Elle sera placée en liste d'attente - si le montant auquel vous souhaitez souscrire se libère, vous pourrez valider votre souscription."
                      }
                    </Text>

                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)[
                        'montants_options_souscription'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)[
                          'montants_options_souscription'
                        ].style,
                        dimensions.width
                      )}
                    >
                      {"Montant en liste d'attente : "}
                      {format_nombre(
                        props.route?.params?.montant_sous ??
                          defaultProps.montant_sous,
                        undefined
                      )}
                      {' €'}
                    </Text>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', width: '25%' },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={
                        (select_liste_attente
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend) ??
                        palettes.App.Icon_Dark_Gray
                      }
                      name={'Feather/watch'}
                      size={
                        (!(dimensions.width >= Breakpoints.Laptop)
                          ? 35
                          : !(dimensions.width >= Breakpoints.Desktop)
                          ? 45
                          : dimensions.width >= Breakpoints.BigScreen
                          ? 60
                          : 55) ?? 60
                      }
                    />
                  </View>
                </View>
              </View>
            </Touchable>
            {/* Spacer 2 */}
            <Spacer bottom={8} left={8} right={8} top={8} />
            {/* Touchable annuler unselected */}
            <Touchable
              onPress={() => {
                try {
                  setSelect_titres_restants(false);
                  setSelect_liste_attente(false);
                  setSelect_annuler(true);
                  setBouton(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { width: { minWidth: Breakpoints.Laptop, value: '75%' } },
                dimensions.width
              )}
            >
              {/* Vue générale */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    borderColor: [
                      {
                        minWidth: Breakpoints.Mobile,
                        value: palettes.App.Icon_Dark_Gray,
                      },
                      {
                        minWidth: Breakpoints.Laptop,
                        value: select_annuler
                          ? palettes.App['Flolend 4']
                          : palettes.App.Icon_Dark_Gray,
                      },
                      {
                        minWidth: Breakpoints.Mobile,
                        value: select_annuler
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend,
                      },
                    ],
                    borderRadius: 12,
                    borderWidth: 1,
                    paddingBottom: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '2%' },
                    ],
                    paddingTop: [
                      { minWidth: Breakpoints.Mobile, value: 5 },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flexDirection: 'row' },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { width: '75%' },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)[
                        'titre_select_option_sous'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)[
                          'titre_select_option_sous'
                        ].style,
                        dimensions.width
                      )}
                    >
                      {'Annuler ma souscription'}
                    </Text>
                    {/* Text 6 */}
                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)[
                        'explication_select_option_souscription'
                      ].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)[
                            'explication_select_option_souscription'
                          ].style,
                          {
                            fontSize: {
                              minWidth: Breakpoints.BigScreen,
                              value: 24,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {'Vous renoncez à souscrire aux titres.'}
                    </Text>

                    <Text
                      accessible={true}
                      selectable={false}
                      {...GlobalStyles.TextStyles(theme)[
                        'montants_options_souscription'
                      ].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)[
                          'montants_options_souscription'
                        ].style,
                        dimensions.width
                      )}
                    >
                      {'Montant annulé : '}
                      {format_nombre(
                        props.route?.params?.montant_sous ??
                          defaultProps.montant_sous,
                        undefined
                      )}
                      {' €'}
                    </Text>
                  </View>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', width: '25%' },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={
                        (select_annuler
                          ? palettes.App['Flolend 4']
                          : palettes.App.gris_fonce_flolend) ??
                        palettes.App.Icon_Dark_Gray
                      }
                      name={'Entypo/circle-with-cross'}
                      size={
                        (!(dimensions.width >= Breakpoints.Laptop)
                          ? 35
                          : !(dimensions.width >= Breakpoints.Desktop)
                          ? 45
                          : dimensions.width >= Breakpoints.BigScreen
                          ? 60
                          : 55) ?? 60
                      }
                    />
                  </View>
                </View>
              </View>
            </Touchable>
            {/* Linear Gradient ordinateur */}
            <>
              {!(
                dimensions.width >= Breakpoints.Laptop && !affichage_chargement
              ) ? null : (
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  color1={
                    (bouton
                      ? palettes.App['Flolend 2']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 2']
                  }
                  color2={
                    (bouton
                      ? palettes.App['Flolend 4']
                      : palettes.App.gris_fonce_flolend) ??
                    palettes.App['Flolend 4']
                  }
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      alignSelf: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      borderRadius: [
                        { minWidth: Breakpoints.Laptop, value: 15 },
                        { minWidth: Breakpoints.Mobile, value: 15 },
                      ],
                      flex: [
                        { minWidth: Breakpoints.BigScreen, value: null },
                        { minWidth: Breakpoints.Laptop, value: null },
                        { minWidth: Breakpoints.Tablet, value: null },
                      ],
                      height: [
                        { minWidth: Breakpoints.Laptop, value: 60 },
                        { minWidth: Breakpoints.BigScreen, value: 70 },
                      ],
                      justifyContent: {
                        minWidth: Breakpoints.Tablet,
                        value: 'center',
                      },
                      marginBottom: 30,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 35,
                      overflow: [
                        { minWidth: Breakpoints.Laptop, value: 'hidden' },
                        { minWidth: Breakpoints.Mobile, value: 'hidden' },
                      ],
                      width: { minWidth: Breakpoints.Tablet, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  {/* Bouton investir actif */}
                  <Button
                    accessible={true}
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          if (!bouton) {
                            return;
                          }
                          setAffichage_chargement(true);
                          if (select_annuler) {
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('BottomTabNavigator', {
                              screen: 'EventailProjetsScreen',
                            });
                          } else {
                            if (select_titres_restants) {
                              const retour_sous_cree = (
                                await baseXanoCreationSousPOST.mutateAsync({
                                  id_projet:
                                    props.route?.params?.id_transfer_1_1ter ??
                                    defaultProps.id_transfer_1_1ter,
                                  montant_souscrit:
                                    props.route?.params
                                      ?.montant_restant_projet ??
                                    defaultProps.montant_restant_projet,
                                })
                              )?.json;
                              if (
                                (props.route?.params?.montant_sous ??
                                  defaultProps.montant_sous) -
                                  (props.route?.params
                                    ?.montant_restant_projet ??
                                    defaultProps.montant_restant_projet) >=
                                Constants['min_sous_cb']
                              ) {
                                (
                                  await baseXanoCreationSousAttentePOST.mutateAsync(
                                    {
                                      id_projet:
                                        props.route?.params
                                          ?.id_transfer_1_1ter ??
                                        defaultProps.id_transfer_1_1ter,
                                      montant:
                                        (props.route?.params?.montant_sous ??
                                          defaultProps.montant_sous) -
                                        (props.route?.params
                                          ?.montant_restant_projet ??
                                          defaultProps.montant_restant_projet),
                                    }
                                  )
                                )?.json;
                              }
                              if (navigation.canGoBack()) {
                                navigation.popToTop();
                              }
                              navigation.replace(
                                'Souscription14ValidationAttenteScreen',
                                {
                                  id_souscription: retour_sous_cree?.id,
                                  souscription_fractionnee: true,
                                }
                              );
                            } else {
                              (
                                await baseXanoCreationSousAttentePOST.mutateAsync(
                                  {
                                    id_projet:
                                      props.route?.params?.id_transfer_1_1ter ??
                                      defaultProps.id_transfer_1_1ter,
                                    montant:
                                      props.route?.params?.montant_sous ??
                                      defaultProps.montant_sous,
                                  }
                                )
                              )?.json;
                              if (navigation.canGoBack()) {
                                navigation.popToTop();
                              }
                              navigation.replace(
                                'Souscription14ValidationAttenteScreen',
                                {
                                  id_souscription: null,
                                  souscription_fractionnee: false,
                                }
                              );
                            }
                          }

                          setAffichage_chargement(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['bouton_classique']
                      .props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['bouton_classique']
                          .style,
                        {
                          borderColor: palettes.App.Transparent,
                          fontSize: [
                            { minWidth: Breakpoints.Desktop, value: 22 },
                            { minWidth: Breakpoints.BigScreen, value: 26 },
                          ],
                          height: '100%',
                          marginBottom: null,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'Continuer'}
                  />
                </LinearGradient>
              )}
            </>
            {/* Chargement en cours ordi */}
            <>
              {!(
                dimensions.width >= Breakpoints.Laptop && affichage_chargement
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: 10,
                      marginTop: 20,
                      paddingLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  <ActivityIndicator
                    animating={true}
                    hidesWhenStopped={true}
                    size={'small'}
                    {...GlobalStyles.ActivityIndicatorStyles(theme)[
                      'Activity Indicator'
                    ].props}
                    color={palettes.App['Flolend 4']}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.ActivityIndicatorStyles(theme)[
                        'Activity Indicator'
                      ].style,
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['corps_contenu'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                      dimensions.width
                    )}
                  >
                    {'Chargement en cours, veuillez patienter.'}
                  </Text>
                </View>
              )}
            </>
          </View>
        </View>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(Souscription13ListeDattenteScreen);
