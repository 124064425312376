import React from 'react';
import {
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  Spacer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as BaseXanoApi from '../apis/BaseXanoApi.js';
import FooterDesktopBlock from '../components/FooterDesktopBlock';
import NavbarBlock from '../components/NavbarBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import ConvertionDate from '../global-functions/ConvertionDate';
import format_nombre from '../global-functions/format_nombre';
import reset_navbar from '../global-functions/reset_navbar';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { id_projet: 5, montant_simul_euros: 200 };

const EcheancierProjetScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  // Calcule les métriques affichées en bas de l'échéancier du projet.
  const calculerInteretsEtROI = (reponse, montant) => {
    if (typeof reponse !== 'object' || !Array.isArray(reponse.echeancier)) {
      throw new TypeError(
        "L'objet de réponse est invalide ou ne contient pas d'échéancier."
      );
    }

    let totalInterets = 0;
    let investissementInitial = null;

    // Parcourir chaque élément de l'échéancier
    reponse.echeancier.forEach(element => {
      if (element.type_evenement === 'Interets') {
        totalInterets += montant * element.part_nominal_viree;
      } else if (element.type_evenement === 'Investissement') {
        investissementInitial = montant * element.part_nominal_viree;
      }
    });

    if (investissementInitial === null) {
      throw new Error(
        'Aucun événement de type "Investissement" trouvé dans l\'échéancier.'
      );
    }

    const ROI = totalInterets / investissementInitial;

    return {
      total_interets: totalInterets,
      ROI: ROI * 100,
    };
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      reset_navbar(Variables, setGlobalVariableValue);
      console.log(
        props.route?.params?.id_projet ?? defaultProps.id_projet,
        props.route?.params?.montant_simul_euros ??
          defaultProps.montant_simul_euros
      );
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: palettes.App.background_flolend,
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* header_ech_tel */}
      <>
        {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['header_classique'].style,
                {
                  marginBottom: [
                    { minWidth: Breakpoints.Mobile, value: '2%' },
                    { minWidth: Breakpoints.BigScreen, value: '1%' },
                    { minWidth: Breakpoints.Laptop, value: '1%' },
                  ],
                  marginLeft: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '2%' },
                  ],
                  marginRight: [
                    { minWidth: Breakpoints.Mobile, value: '5%' },
                    { minWidth: Breakpoints.Laptop, value: '2%' },
                  ],
                  marginTop: [
                    { minWidth: Breakpoints.BigScreen, value: '1%' },
                    { minWidth: Breakpoints.Laptop, value: '1%' },
                  ],
                  paddingLeft: null,
                }
              ),
              dimensions.width
            )}
          >
            {/* Back btn */}
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    height: 44,
                    justifyContent: 'center',
                    width: 44,
                  },
                  dimensions.width
                )}
              >
                {/* Icon 3 */}
                <Icon
                  color={palettes.App.gris_fonce_flolend}
                  name={'AntDesign/arrowleft'}
                  size={
                    !(dimensions.width >= Breakpoints.Desktop)
                      ? 18
                      : dimensions.width >= Breakpoints.BigScreen
                      ? 30
                      : 25
                  }
                />
              </View>
            </Touchable>
            {/* Titre */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_heading'].props}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextStyles(theme)['screen_heading'].style,
                dimensions.width
              )}
            >
              {'Echéancier du projet'}
            </Text>
          </View>
        )}
      </>
      <SimpleStyleScrollView
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        bounces={false}
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            justifyContent: [
              { minWidth: Breakpoints.BigScreen, value: 'space-between' },
              { minWidth: Breakpoints.Laptop, value: 'space-between' },
            ],
            marginBottom: [
              { minWidth: Breakpoints.BigScreen, value: '0%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.Mobile, value: '2%' },
            ],
            marginLeft: [
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.BigScreen, value: '0%' },
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            marginRight: [
              { minWidth: Breakpoints.BigScreen, value: '0%' },
              { minWidth: Breakpoints.Mobile, value: '5%' },
              { minWidth: Breakpoints.Laptop, value: '0%' },
              { minWidth: Breakpoints.Tablet, value: '15%' },
            ],
            paddingTop: [
              {
                minWidth: Breakpoints.BigScreen,
                value: Constants['hauteur_navbar_big_screen'],
              },
              {
                minWidth: Breakpoints.Desktop,
                value: Constants['hauteur_navbar_desktop'],
              },
              {
                minWidth: Breakpoints.Laptop,
                value: Constants['hauteur_navbar_laptop'],
              },
            ],
          },
          dimensions.width
        )}
      >
        {/* header_ech_ordi */}
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <View
              {...GlobalStyles.ViewStyles(theme)['header_classique'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ViewStyles(theme)['header_classique'].style,
                  {
                    marginBottom: [
                      { minWidth: Breakpoints.Mobile, value: '2%' },
                      { minWidth: Breakpoints.BigScreen, value: '1%' },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                    marginLeft: { minWidth: Breakpoints.Laptop, value: '2%' },
                    marginRight: { minWidth: Breakpoints.Laptop, value: '2%' },
                    marginTop: [
                      { minWidth: Breakpoints.BigScreen, value: '1%' },
                      { minWidth: Breakpoints.Laptop, value: '1%' },
                    ],
                    paddingLeft: null,
                  }
                ),
                dimensions.width
              )}
            >
              <Pressable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  {...GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                    .props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.ViewStyles(theme)['bouton_retour_gen_ordi']
                      .style,
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    {...StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].props,
                      dimensions.width
                    )}
                    name={'AntDesign/arrowleft'}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.IconStyles(theme)['icone_retour_ordi'].style,
                      dimensions.width
                    )}
                  />
                  <Text
                    accessible={true}
                    selectable={false}
                    {...GlobalStyles.TextStyles(theme)['texte_retour_ordi']
                      .props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['texte_retour_ordi'].style,
                      dimensions.width
                    )}
                  >
                    {'Retour'}
                  </Text>
                </View>
              </Pressable>
            </View>
          )}
        </>
        {/* Fetch echeancier */}
        <BaseXanoApi.FetchEcheancierProjetGET
          id_projet={props.route?.params?.id_projet ?? defaultProps.id_projet}
        >
          {({ loading, error, data, refetchEcheancierProjet }) => {
            const fetchEcheancierData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Vue schéma */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignSelf: {
                        minWidth: Breakpoints.Laptop,
                        value: 'center',
                      },
                      backgroundColor: {
                        minWidth: Breakpoints.Laptop,
                        value: palettes.App.gris_encart_int_flolend,
                      },
                      borderRadius: { minWidth: Breakpoints.Laptop, value: 24 },
                      padding: { minWidth: Breakpoints.Laptop, value: 20 },
                      width: { minWidth: Breakpoints.Laptop, value: '50%' },
                    },
                    dimensions.width
                  )}
                >
                  <SimpleStyleFlatList
                    data={fetchEcheancierData?.echeancier}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) =>
                      listData?.id ??
                      listData?.uuid ??
                      index?.toString() ??
                      JSON.stringify(listData)
                    }
                    keyboardShouldPersistTaps={'never'}
                    listKey={'KD83SGy0'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* Vue générale */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              },
                              dimensions.width
                            )}
                          >
                            {/* Vue montants */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  paddingLeft: [
                                    { minWidth: Breakpoints.Tablet, value: 65 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 50,
                                    },
                                    { minWidth: Breakpoints.Laptop, value: 0 },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '25%',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '30%',
                                    },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '25%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Montant investissement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Investissement'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montant_echeancier'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'- '}
                                    {format_nombre(
                                      props.route?.params
                                        ?.montant_simul_euros ??
                                        defaultProps.montant_simul_euros,
                                      2
                                    )}
                                    {' €'}
                                  </Text>
                                )}
                              </>
                              {/* Montant intérêts */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Interets'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montant_echeancier'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'+ '}
                                    {format_nombre(
                                      listData?.part_nominal_viree *
                                        (props.route?.params
                                          ?.montant_simul_euros ??
                                          defaultProps.montant_simul_euros),
                                      2
                                    )}
                                    {'€'}
                                  </Text>
                                )}
                              </>
                              {/* Montant remboursement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Remboursement'
                                ) ? null : (
                                  <Text
                                    accessible={true}
                                    selectable={false}
                                    {...GlobalStyles.TextStyles(theme)[
                                      'montant_echeancier'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      GlobalStyles.TextStyles(theme)[
                                        'montant_echeancier'
                                      ].style,
                                      dimensions.width
                                    )}
                                  >
                                    {'+ '}
                                    {format_nombre(
                                      props.route?.params
                                        ?.montant_simul_euros ??
                                        defaultProps.montant_simul_euros,
                                      2
                                    )}
                                    {' €'}
                                  </Text>
                                )}
                              </>
                              {/* Date */}
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'sous_elt_echeancier'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'sous_elt_echeancier'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {ConvertionDate(listData?.Date)}
                              </Text>
                            </View>
                            {/* Vue ligne */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flex: {
                                    minWidth: Breakpoints.BigScreen,
                                    value: 1,
                                  },
                                },
                                dimensions.width
                              )}
                            >
                              {/* Cercle remboursement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Remboursement'
                                ) ? null : (
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'cercle_remboursement'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'cercle_remboursement'
                                        ].style,
                                        {
                                          height: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Cercle investissement */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Investissement'
                                ) ? null : (
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'cercle_investissement'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'cercle_investissement'
                                        ].style,
                                        {
                                          height: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Cercle interets */}
                              <>
                                {!(
                                  listData?.type_evenement === 'Interets'
                                ) ? null : (
                                  <View
                                    {...GlobalStyles.ViewStyles(theme)[
                                      'cercle_investissement'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ViewStyles(theme)[
                                          'cercle_investissement'
                                        ].style,
                                        {
                                          height: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                          width: [
                                            {
                                              minWidth: Breakpoints.BigScreen,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_big_screen'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_desktop'
                                                ],
                                            },
                                            {
                                              minWidth: Breakpoints.Laptop,
                                              value:
                                                Constants[
                                                  'diametre_cercle_echeancier_laptop'
                                                ],
                                            },
                                          ],
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  />
                                )}
                              </>
                              {/* Ligne */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    backgroundColor: palettes.App['Flolend 5'],
                                    flex: [
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 1,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 1,
                                      },
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 1,
                                      },
                                    ],
                                    width: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 4,
                                      },
                                      {
                                        minWidth: Breakpoints.Tablet,
                                        value: 6,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 5,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 7,
                                      },
                                      {
                                        minWidth: Breakpoints.BigScreen,
                                        value: 9,
                                      },
                                    ],
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Vue commentaire */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-start', width: '55%' },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'montant_echeancier'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'montant_echeancier'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {listData?.Titre1}
                              </Text>

                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'sous_elt_echeancier'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'sous_elt_echeancier'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {listData?.Corps}
                              </Text>
                              <Spacer left={8} right={8} bottom={12} top={12} />
                            </View>
                          </View>
                        </>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  />
                  {/* Dispense - résidence fiscale étranger */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor:
                          palettes.App.flolend_gris_nuance_encart,
                        borderRadius: 24,
                        gap: 15,
                        padding: 10,
                      },
                      dimensions.width
                    )}
                  >
                    {/* message */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: palettes.App.gris_encart_int_flolend,
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: palettes.App.flolend_gris_nuance_encart,
                            },
                          ],
                          borderRadius: 24,
                          padding: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 3 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['corps_contenu']
                          .props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['corps_contenu'].style,
                          dimensions.width
                        )}
                      >
                        {'Plus value-finale : '}
                        {format_nombre(
                          calculerInteretsEtROI(
                            fetchEcheancierData,
                            props.route?.params?.montant_simul_euros ??
                              defaultProps.montant_simul_euros
                          )?.total_interets,
                          2
                        )}
                        {' €\nRetour sur investissement : '}
                        {
                          calculerInteretsEtROI(
                            fetchEcheancierData,
                            props.route?.params?.montant_simul_euros ??
                              defaultProps.montant_simul_euros
                          )?.ROI
                        }
                        {' % en cumulé'}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
            );
          }}
        </BaseXanoApi.FetchEcheancierProjetGET>
        <>
          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
            <FooterDesktopBlock />
          )}
        </>
      </SimpleStyleScrollView>
      <>{!(dimensions.width >= Breakpoints.Laptop) ? null : <NavbarBlock />}</>
    </ScreenContainer>
  );
};

export default withTheme(EcheancierProjetScreen);
